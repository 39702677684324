export const pingCheck = url => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response, xhr.status);
      } else {
        reject(xhr.statusText, xhr.status);
      }
    };
    xhr.onerror = () => {
      reject(xhr.statusText, xhr.status);
    };
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.send();
  });
};
