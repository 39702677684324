import React from 'react';

export const truncateAccText = str => {
  return str.length >= 21
    ? str.substring(0, 6) + '...' + str.substring(str.length - 9)
    : str;
};
export const truncatePackText = str => {
  return str.length >= 16
    ? str.substring(0, 6) + '...' + str.substring(str.length - 5)
    : str;
};
export const refactorBatteryText = str => {
  return str.replace(/(?<=^.{5}).+(?=.{6}$)/, '...');
};
export const refactorPackText = str => {
  return str.replace(/(?<=^.{4}).+(?=.{6}$)/, '...');
};
export const refactormidscreenText = str => {
  return str.replace(/(?<=^.{3}).+(?=.{3}$)/, '...');
};

export const highlightSearchTerm = (text, searchQuery) => {
  if (!text || text.length <= 0) {
    return text;
  }
  const sanitizedText = searchQuery.replace(/[^A-Za-z0-9_.]/g, '');
  const regExpression = new RegExp(sanitizedText, 'gi');
  const matches = sanitizedText ? text.match(regExpression) : [];

  if (!matches || matches.length === 0) {
    return text;
  }

  const parts = text.split(regExpression);

  return parts.reduce((result, part, index) => {
    result.push(part);
    if (matches[index]) {
      result.push(
        <mark style={{ padding: 0, background: 'yellow' }} key={index}>
          {matches[index]}
        </mark>
      );
    }
    return result;
  }, []);
};
