import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { InfoCircle } from '../../assets/images';
import './styles.scss';
function Configuration({ createMessage }) {
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    message: '',
  });
  const config_form = useRef(null);
  const handleOnChange = e => {
    setdata({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleCreateMessage = async () => {
    if (
      !data.startDate ||
      !data.startTime ||
      !data.endDate ||
      !data.endTime ||
      !data.message ||
      data.message.length > 200
    ) {
      config_form.current.reportValidity();
    } else {
      setloading(true);
      await createMessage(data);
      setdata({
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        message: '',
      });
      setloading(false);
    }
  };
  return (
    <div className="broadcast_config">
      <h6 className="mb-4">Message broadcasting Configuration</h6>

      <Form className="w-100 gap-2" ref={config_form}>
        <span className="broadcast_input">
          <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label>Start date</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={data.startDate}
              isValid={data.startDate && data.startDate < data.endDate}
              isInvalid={data.startDate && data.startDate > data.endDate}
              onChange={handleOnChange}
              min={new Date().toISOString().split('T')[0]}
              required={true}
              disabled={loading}
            />
            <Form.Control.Feedback type="invalid">
              Please select a date before the end date.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="time"
              name="startTime"
              value={data.startTime}
              onChange={handleOnChange}
              isValid={
                data.startDate === data.endDate
                  ? data.startTime && data.startTime < data.endTime
                  : true
              }
              isInvalid={
                data.startDate === data.endDate
                  ? data.startTime && data.startTime > data.endTime
                  : false
              }
              required={true}
              disabled={loading}
            />
            <Form.Control.Feedback type="invalid">
              Please select a time before the end time.
            </Form.Control.Feedback>
          </Form.Group>
        </span>
        <span className="broadcast_input">
          <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label>End date</Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              isValid={data.endDate && data.endDate > data.startDate}
              isInvalid={data.endDate && data.endDate < data.startDate}
              value={data.endDate}
              onChange={handleOnChange}
              required={true}
              disabled={loading}
              min={data.startDate}
            />
            <Form.Control.Feedback type="invalid">
              Please select a date after the start date.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2 " controlId="formBasicEmail">
            <Form.Label>End Time</Form.Label>
            <Form.Control
              type="time"
              name="endTime"
              onChange={handleOnChange}
              value={data.endTime}
              isValid={
                data.startDate === data.endDate
                  ? data.endTime && data.endTime > data.startTime
                  : true
              }
              isInvalid={
                data.startDate === data.endDate
                  ? data.endTime && data.endTime < data.startTime
                  : false
              }
              required={true}
              disabled={loading}
            />
            <Form.Control.Feedback type="invalid">
              Please select a time after the start time.
            </Form.Control.Feedback>
          </Form.Group>
        </span>
        <span className="w-100">
          <Form.Group className="mb-2" controlId="message">
            <Form.Label className="d-flex  justify-content-between">
              <span>Message</span>
              <span>
                {data.message ? data.message.length : '0'}/200 <InfoCircle />
              </span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              cols={20}
              xs={6}
              name="message"
              required={true}
              value={data.message}
              isValid={data.message && data.message.length <= 201}
              isInvalid={data.message && data.message.length > 200}
              onChange={handleOnChange}
              disabled={loading}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a message with less than 200 characters.
            </Form.Control.Feedback>
          </Form.Group>
        </span>
        <span className="broadcast_input">
          <Button
            variant="btn btn-secondary rounded-0"
            type="button"
            className="w-100"
            onClick={() =>
              setdata({
                startDate: '',
                startTime: '',
                endDate: '',
                endTime: '',
                message: '',
              })
            }>
            Clear
          </Button>
          <Button
            variant="btn btn-primary rounded-0"
            type="button"
            className="w-100"
            onClick={handleCreateMessage}
            disabled={loading}>
            Create
          </Button>
        </span>
      </Form>
    </div>
  );
}

export default Configuration;
