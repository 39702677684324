export const FETCH_EVENT_LIST = 'FETCH_EVENT_LIST';
export const FETCH_EVENT_LIST_SUCCESS = 'FETCH_EVENT_LIST_SUCCESS';
export const FETCH_EVENT_LIST_FAILURE = 'FETCH_EVENT_LIST_FAILURE';
export const CLEAR_EVENT_LIST = 'CLEAR_EVENT_LIST';

export const SAVE_EVENT_DATA = 'SAVE_EVENT_DATA';
export const SAVE_EVENT_DATA_SUCCESS = 'SAVE_EVENT_DATA_SUCCESS';
export const SAVE_EVENT_DATA_FAILURE = 'SAVE_EVENT_DATA_FAILURE';
export const CLEAR_SAVE_EVENT_RESPONCE = 'CLEAR_SAVE_EVENT_RESPONCE';

export const FETCH_VEVENT_LIST = 'FETCH_VEVENT_LIST';
export const FETCH_VEVENT_LIST_SUCCESS = 'FETCH_VEVENT_LIST_SUCCESS';
export const FETCH_VEVENT_LIST_FAILURE = 'FETCH_VEVENT_LIST_FAILURE';

export const FETCH_ALL_EVENT_LIST = 'FETCH_ALL_EVENT_LIST';
export const FETCH_ALL_EVENT_LIST_SUCCESS = 'FETCH_ALL_EVENT_LIST_SUCCESS';
export const FETCH_ALL_EVENT_LIST_FAILURE = 'FETCH_ALL_EVENT_LIST_FAILURE';
export const SET_ALL_EVENT_LIST_DATA = 'SET_ALL_EVENT_LIST_DATA';

export const UNDO_EVENT_DATA = 'UNDO_EVENT_DATA';
export const UNDO_EVENT_DATA_SUCCESS = 'UNDO_EVENT_DATA_SUCCESS';
export const UNDO_EVENT_DATA_FAILURE = 'UNDO_EVENT_DATA_FAILURE';
export const CLEAR_UNDO_EVENT_RESPONCE = 'CLEAR_UNDO_EVENT_RESPONCE';
