export const hasSearchValueProduct = (data, searchValue) => {
  searchValue = searchValue.toLowerCase();
  if (searchValue === '') {
    return true;
  }
  if (
    (data.dn && data.dn.toLowerCase().includes(searchValue)) ||
    (data.ip && data.ip.toLowerCase().includes(searchValue)) ||
    (data.rcm && data.rcm.includes(searchValue)) ||
    (data.swv && data.swv.includes(searchValue)) ||
    (data.bwvr && data.bwvr.includes(searchValue))
  ) {
    return true;
  }

  const anValues = data.an.split('|');
  const latestAnValue = anValues[anValues.length - 1];

  const an = latestAnValue.slice(0, 15);
  const psn = latestAnValue.slice(15);
  const bpuParts = data.bpu.split('');
  const sn = bpuParts.slice(15).join('');
  const bpu = bpuParts.slice(0, 15).join('');
  if (
    an.toLowerCase().includes(searchValue) ||
    psn.toLowerCase().includes(searchValue) ||
    sn.toLowerCase().includes(searchValue) ||
    bpu.toLowerCase().includes(searchValue)
  ) {
    return true;
  }
};

export const hasSearchValueVehicle = (data, searchValue) => {
  searchValue = searchValue.toLowerCase();
  if (searchValue === '') {
    return true;
  } else if (data.dvn && data.dvn.toLowerCase().includes(searchValue)) {
    return true;
  }
};

export const searchSideMenuItemV3 = (data, queryString) => {
  const search = currentData => {
    if (typeof currentData === 'string') {
      return currentData.toLowerCase().includes(queryString);
    } else if (Array.isArray(currentData)) {
      return currentData.some(item => search(item));
    } else if (currentData && typeof currentData === 'object') {
      if (
        currentData.dvn &&
        currentData.dvn.toLowerCase().includes(queryString)
      ) {
        return true;
      }
      return Object.values(currentData).some(value => search(value));
    }
    return false;
  };

  queryString = queryString.toLowerCase();
  return queryString === '' ? true : search(data);
};

export const searchQueryProductLevel = (item, queryString) => {
  queryString = queryString.toLowerCase();
  const containsQueryString = data => {
    if (queryString === '') {
      return true;
    }

    if (Array.isArray(data)) {
      return data.some(containsQueryString);
    }

    if (typeof data === 'object' && data !== null) {
      if ('vdat' in data) {
        return containsQueryString(data.vdat);
      }

      return Object.entries(data).some(([key, value]) => {
        return key !== 'pdat' && containsQueryString(value);
      });
    }

    return typeof data === 'string' && data.includes(queryString);
  };

  return containsQueryString(item);
};

export const searchItemInVehicleProduct = (item, queryString) => {
  const query = queryString.toLowerCase();
  if (item.dvn && item.dvn.toLowerCase().includes(query)) return true;

  for (const data of item.vdat) {
    if (
      (data.ip && data.ip.toLowerCase().includes(query)) ||
      (data.rcm && data.rcm.toLowerCase().includes(query)) ||
      (data.swv && data.swv.toLowerCase().includes(query)) ||
      (data.dn && data.dn.toLowerCase().includes(query)) ||
      (data.bwvr && data.bwvr.includes(query))
    ) {
      return true;
    }

    const bpuParts = data.bpu.split('');
    const sn = bpuParts.slice(15).join('');
    const bpu = bpuParts.slice(0, 15).join('');
    if (sn.toLowerCase().includes(query)) {
      return true;
    }

    if (bpu.toLowerCase().includes(query)) {
      return true;
    }

    const anValues = data.an.split('|');
    const latestAnValue = anValues[anValues.length - 1];

    const an = latestAnValue.slice(0, 15);
    const psn = latestAnValue.slice(15);

    if (an.toLowerCase().includes(query) || psn.toLowerCase().includes(query)) {
      return true;
    }
  }

  return false;
};
