import { createSelector } from 'reselect';

const saveResponce = () => state => state.get('fleetSetupData');

// save site
const loadingsavesiteSelector = () =>
  createSelector(saveResponce(), state => state.get('loadingSaveSite'));

const savesiteresponceSelector = () =>
  createSelector(saveResponce(), state => state.get('saveSiteResponce'));

// save fleet
const loadingsavefleetSelector = () =>
  createSelector(saveResponce(), state => state.get('loadingSaveFleet'));

const savefleetresponceSelector = () =>
  createSelector(saveResponce(), state => state.get('saveFleetResponce'));

// save productSetup
const loadingproductSetupSelector = () =>
  createSelector(saveResponce(), state => state.get('loadingproductSetup'));

const saveProductSetupResponceSelector = () =>
  createSelector(saveResponce(), state =>
    state.get('saveProductSetupResponce')
  );

const loadingproductInfoSetupSelector = () =>
  createSelector(saveResponce(), state => state.get('loadingproductInfoSetup'));

const saveProductInfoSetupResponceSelector = () =>
  createSelector(saveResponce(), state =>
    state.get('saveProductInfoSetupResponce')
  );

// save Vehicle Setup
const loadingVehicleSetupSelector = () =>
  createSelector(saveResponce(), state => state.get('loadingVehicleSetup'));

const saveVehicleSetupResponceSelector = () =>
  createSelector(saveResponce(), state =>
    state.get('saveVehicleSetupResponce')
  );

// get Vehicle setup values
const loadingVehicleSetupValuesSelector = () =>
  createSelector(saveResponce(), state =>
    state.get('loadingVehicleSetupValues')
  );

const vehicleSetupValuesSelector = () =>
  createSelector(saveResponce(), state => state.get('vehicleSetupValues'));

// get setup site list

const loadingSetupSiteListSelector = () =>
  createSelector(saveResponce(), state => state.get('loadingSetupSiteList'));

const setupSiteListSelector = () =>
  createSelector(saveResponce(), state => state.get('setupSiteList'));

// get setup site list

const loadingSetupFleetListSelector = () =>
  createSelector(saveResponce(), state => state.get('loadingSetupFleetList'));

const setupFleetListSelector = () =>
  createSelector(saveResponce(), state => state.get('setupFleetList'));

// get setup prdt setup values

const loadingPrdtSetupValuesSelector = () =>
  createSelector(saveResponce(), state => state.get('loadingPrdtSetupValues'));

const prdtSetupValuesSelector = () =>
  createSelector(saveResponce(), state => state.get('prdtSetupValues'));

const loadingPrdtInfoSetupValuesSelector = () =>
  createSelector(saveResponce(), state =>
    state.get('loadingPrdtInfoSetupValues')
  );

const prdtInfoSetupValuesSelector = () =>
  createSelector(saveResponce(), state => state.get('prdtInfoSetupValues'));

// sidemenu list
const loadingSetupSidemenuListSelector = () =>
  createSelector(saveResponce(), state =>
    state.get('loadingSetupSidemenuList')
  );

const setupSidemenuListSelector = () =>
  createSelector(saveResponce(), state => state.get('setupSidemenuList'));

// get used fleet name list
const loadingUsedFleetNameListSelector = () =>
  createSelector(saveResponce(), state =>
    state.get('loadingUsedFleetNameList')
  );

const usedFleetNameListSelector = () =>
  createSelector(saveResponce(), state => state.get('usedFleetNameList'));

// Validate Fleet data path
const loadingFleetDataPathValidateSelector = () =>
  createSelector(saveResponce(), state =>
    state.get('loadingfleetdatapathvalidation')
  );

const fleetDataPathValidateSelector = () =>
  createSelector(saveResponce(), state =>
    state.get('saveFleetDataPathResponse')
  );

// Load Fleet data - Connectivity key path
const loadingFleetDataSelector = () =>
  createSelector(saveResponce(), state => state.get('loadingfleetdata'));
const fleetDataSelector = () =>
  createSelector(saveResponce(), state => state.get('saveFleetDataResponse'));

export {
  fleetDataPathValidateSelector,
  fleetDataSelector,
  loadingFleetDataPathValidateSelector,
  loadingFleetDataSelector,
  loadingPrdtInfoSetupValuesSelector,
  loadingPrdtSetupValuesSelector,
  loadingSetupFleetListSelector,
  loadingSetupSidemenuListSelector,
  loadingSetupSiteListSelector,
  loadingUsedFleetNameListSelector,
  loadingVehicleSetupSelector,
  loadingVehicleSetupValuesSelector,
  loadingproductInfoSetupSelector,
  loadingproductSetupSelector,
  loadingsavefleetSelector,
  loadingsavesiteSelector,
  prdtInfoSetupValuesSelector,
  prdtSetupValuesSelector,
  saveProductInfoSetupResponceSelector,
  saveProductSetupResponceSelector,
  saveVehicleSetupResponceSelector,
  savefleetresponceSelector,
  savesiteresponceSelector,
  setupFleetListSelector,
  setupSidemenuListSelector,
  setupSiteListSelector,
  usedFleetNameListSelector,
  vehicleSetupValuesSelector,
};
