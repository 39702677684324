import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  BusIcon,
  FleetIcon,
  PackIcon,
  ProductBattery,
  VehicleIcon,
} from '../../assets/images';
import './styles.scss';

class MinimizedSideMenu extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      itemsToShow: 1,
      expanded: false,
      filterExpand: true,
    };
  }

  render() {
    return (
      <div className="containerminimizedsidemenu">
        <table>
          <tr>
            {sessionStorage.getItem('selectedFleet') !== null && (
              <button
                className={
                  this.props.selectedView === 'Fleet'
                    ? 'labelstyle active'
                    : 'labelstyle'
                }
                onClick={() => {
                  this.props.setSize(340);
                  this.props.setShowSidebar(true);
                  this.props.selectedView !== 'Fleet' &&
                    this.props.onFleetListClick(
                      sessionStorage.getItem('selectedFleet'),
                      sessionStorage.getItem('selectedSite'),
                      sessionStorage.getItem('selectedFleetType')
                    );
                }}>
                <div className="fleetlabel">
                  {this.props.selectedFleetType === 'eBus' ? (
                    <BusIcon className="fleeticon" />
                  ) : (
                    <FleetIcon className="fleeticon" />
                  )}

                  {sessionStorage.getItem('selectedFleet')}
                </div>
              </button>
            )}
          </tr>
          <tr>
            {sessionStorage.getItem('selectedVehicle') !== null &&
              this.props.selectedView !== 'Fleet' && (
                <button
                  className={
                    this.props.selectedView === 'Vehicle' &&
                    (sessionStorage.getItem('selectedProductDname') === null ||
                      sessionStorage.getItem('selectedProductDname') === 'null')
                      ? 'labelstyle active'
                      : 'labelstyle'
                  }
                  // disabled={this.props.selectedView === "Pack" ? true : false}
                  onClick={() => {
                    sessionStorage.setItem('unmountedESSclicked', 'false');
                    this.props.setSize(340);

                    this.props.setShowSidebar(true);
                    (this.props.selectedView !== 'Vehicle' ||
                      (sessionStorage.getItem('selectedProductDname') !==
                        null &&
                        sessionStorage.getItem('selectedProductDname') !==
                          'null')) &&
                      this.props.onVehicleListClick(
                        sessionStorage.getItem('selectedVehicle')
                      );
                  }}>
                  <div className="vehiclelabel">
                    {this.props.selectedFleetType === 'eBus' ? (
                      <BusIcon className="vehicleicon" />
                    ) : (
                      <VehicleIcon className="vehicleicon" />
                    )}
                    {sessionStorage.getItem('selectedVehicle')}
                  </div>
                </button>
              )}
          </tr>
          <tr>
            {sessionStorage.getItem('selectedProductDname') !== null &&
              this.props.selectedView === 'Product' && (
                <button
                  className={
                    this.props.selectedView === 'Product'
                      ? 'labelstyle active'
                      : 'labelstyle'
                  }
                  onClick={() => {
                    this.props.setSize(340);

                    this.props.setShowSidebar(true);
                    this.props.selectedView !== 'Product' &&
                      this.props.onVehicleProductListClick(
                        sessionStorage.getItem('selectedVehicle'),
                        sessionStorage.getItem('selectedVehicleProduct'),
                        sessionStorage.getItem('selectedProductIP'),
                        sessionStorage.getItem('selectedProductDname')
                      );
                  }}>
                  <div className="productlabel">
                    <ProductBattery className="producticon" />
                    {sessionStorage.getItem('selectedProductDname')}
                  </div>
                </button>
              )}
          </tr>
          <tr>
            {sessionStorage.getItem('selectedProductDname') !== null &&
              sessionStorage.getItem('selectedProductDname') !== 'null' &&
              (this.props.selectedView === 'Pack' ||
                this.props.selectedView === 'Vehicle') && (
                <button
                  className={
                    this.props.selectedView === 'Product' ||
                    this.props.selectedView === 'Vehicle'
                      ? 'labelstyle active'
                      : 'labelstyle'
                  }
                  onClick={() => {
                    this.props.setSize(340);

                    this.props.setShowSidebar(true);
                    this.props.selectedView === 'Pack' &&
                      this.props.onVehicleListClick(
                        sessionStorage.getItem('selectedVehicle'),
                        sessionStorage.getItem('selectedVehicleProduct'),
                        sessionStorage.getItem('selectedProductDname')
                      );
                  }}>
                  <div className="productlabel">
                    <ProductBattery className="producticon" />
                    {sessionStorage.getItem('selectedProductDname')}
                  </div>
                </button>
              )}
          </tr>
          <tr>
            {sessionStorage.getItem('selectedPackDname') !== null &&
              this.props.selectedView === 'Pack' && (
                <button
                  className={
                    this.props.selectedView === 'Pack'
                      ? 'labelstyle active'
                      : 'labelstyle'
                  }
                  onClick={() => {
                    this.props.setSize(340);

                    this.props.setShowSidebar(true);
                  }}>
                  <div className="productlabel">
                    <PackIcon className="producticon" />
                    {sessionStorage.getItem('selectedPackDname')}
                  </div>
                </button>
              )}
          </tr>
        </table>
      </div>
    );
  }
}

export default withRouter(MinimizedSideMenu);
