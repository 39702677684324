import { defineMessages } from 'react-intl';

export default defineMessages({
  mainSignalText: {
    id: 'app.components.GraphOverlay.mainSignalText',
    defaultMessage: 'Main signal',
  },
  addOverlaysText: {
    id: 'app.components.GraphOverlay.addOverlaysText',
    defaultMessage: 'Add overlay signal',
  },
  xAxisSignalText: {
    id: 'app.components.GraphOverlay.xAxisSignalText',
    defaultMessage: 'X- axis signal',
  },
  yAxisSignalText: {
    id: 'app.components.GraphOverlay.yAxisSignalText',
    defaultMessage: 'Y- axis signal',
  },
  zAxisSignalText: {
    id: 'app.components.GraphOverlay.zAxisSignalText',
    defaultMessage: 'Z- axis signal',
  },
});
