import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {
  BusIcon,
  CaretDown,
  CaretUpIcon,
  FleetIcon,
  SubwayIcon,
  VehicleProductIcon,
} from '../../assets/images';
import history from '../../history';
import './styles.scss';

const FleetList = ({
  FleetList,
  toggleFleetList,
  itemsToShow,
  fleetExpanded,
  onFleetListClick,
  selectedFleet,
  selectedFleetType,
  selectedView,
  selectedSite,
}) => {
  let status = null;
  if (FleetList !== null) {
    if (FleetList.length !== 0)
      if (selectedFleet !== 'null') {
        status = FleetList.filter(item => item.nm === selectedFleet);
        if (status.length !== 0) status = status[0].fc;
      } else {
        status = FleetList[0].fc;
      }
  }

  const getDisplayName = selectedFleet => {
    // this is to check if the selected fleet is in the fleet list ie from url fleet name is taken
    if (FleetList.filter(item => item.nm === selectedFleet).length === 0) {
      history.push('/access-denied');
    } else {
      return FleetList.filter(item => item.nm === selectedFleet)[0].dnm;
    }
  };
  return (
    <div>
      <Row className="FleetListsubtitle">
        <Col className="noLeftpadding noRightpadding"> Fleet list</Col>
        <Col className="noLeftpadding noRightpadding toogle-link">
          <div className="arrowhover">
            <a onClick={toggleFleetList}>
              {sessionStorage.getItem('fleetToggle') === 'true' ? (
                <CaretUpIcon />
              ) : (
                <CaretDown />
              )}
            </a>
          </div>
        </Col>
      </Row>
      {sessionStorage.getItem('fleetToggle') !== 'true' && (
        <div className="sidemenulist-scroll">
          {FleetList !== null ? (
            FleetList.length === 0 ? (
              <Row>
                <div className="nodatanotificationcontainer-fv ml-auto">
                  <Alert
                    variant="light"
                    id="nodatanotification-fv"
                    // onClose={this.props.onResetBtnClick}
                  >
                    <p className="nodatanotificationcontent-fv">
                      No result matches the filter. Please reset the filters to
                      view all configured fleets.
                    </p>
                  </Alert>
                </div>
              </Row>
            ) : (
              <Row
                className={
                  selectedView === 'Fleet'
                    ? 'FleetListcontainer fleetlistactive'
                    : 'FleetListcontainer'
                }
                id={selectedFleet !== 'null' ? selectedFleet : FleetList[0].nm}
                onClick={() =>
                  onFleetListClick(
                    selectedFleet !== 'null' ? selectedFleet : FleetList[0].nm,
                    selectedFleet !== 'null' ? selectedSite : FleetList[0].st,
                    selectedFleet !== 'null'
                      ? selectedFleetType
                      : FleetList[0].typ
                  )
                }>
                <Col className="noLeftpadding noRightpadding">
                  <div>
                    <span className="FleetListicon">
                      {selectedFleetType !== 'null' ? (
                        selectedFleetType === 'eBus' ? (
                          <BusIcon />
                        ) : (
                          <FleetIcon />
                        )
                      ) : FleetList[0].typ === 'eBus' ? (
                        <BusIcon />
                      ) : (
                        <FleetIcon />
                      )}
                    </span>
                    {selectedFleet !== 'null'
                      ? getDisplayName(selectedFleet)
                      : FleetList[0].dnm}
                  </div>
                </Col>
                <Col
                  md={3}
                  className="noLeftpadding noRightpadding conwithstar d-flex align-items-center hidewithoutspace">
                  <div
                    className="conditionIcon "
                    id={
                      status === 'ok'
                        ? 'goodcondition'
                        : status === 'trip'
                          ? 'poorcondition'
                          : 'unknowncondition'
                    }></div>
                  <div className="graystar">&#9733;</div>
                </Col>
              </Row>
            )
          ) : (
            ' Loading ...'
          )}
        </div>
      )}
      {sessionStorage.getItem('fleetToggle') === 'true' && (
        <div className="sidemenulist-scroll">
          {FleetList !== null ? (
            FleetList.length === 0 ? (
              <Row>
                <div className="nodatanotificationcontainer-fv ml-auto">
                  <Alert variant="light" id="nodatanotification-fv">
                    <p className="nodatanotificationcontent-fv">
                      No result matches the filter. Please reset the filters to
                      view all configured fleets.
                    </p>
                  </Alert>
                </div>
              </Row>
            ) : (
              FleetList.map((item, index) => (
                <Row
                  key={item.nm + index}
                  className={
                    selectedFleet === item.nm
                      ? 'FleetListcontainer fleetlistactive marginbottomZero'
                      : 'FleetListcontainer marginbottomZero'
                  }
                  id={item.nm}
                  onClick={() => onFleetListClick(item.nm, item.st, item.typ)}>
                  <Col className="noLeftpadding noRightpadding">
                    <div>
                      <span className="FleetListicon">
                        {item.typ === 'eBus' ? <BusIcon /> : <FleetIcon />}
                      </span>
                      {item.dnm}
                    </div>
                  </Col>
                  <Col
                    md={3}
                    className="noLeftpadding noRightpadding conwithstar d-flex align-items-center hidewithoutspace">
                    <div
                      className="conditionIcon"
                      id={
                        item.fc === 'ok'
                          ? 'goodcondition'
                          : item.fc === 'trip'
                            ? 'poorcondition'
                            : 'unknowncondition'
                      }></div>
                    <div className="graystar">&#9733;</div>
                  </Col>
                </Row>
              ))
            )
          ) : (
            ' Loading ...'
          )}
        </div>
      )}
    </div>
  );
};

export const Vehicles = ({
  Vehicles,
  toggleVehiclesList,
  itemsToShowVehicles,
  vehiclesExpanded,
  onVehicleListClick,
  selectedVehicle,
  selectedView,
}) => {
  let status = null;
  if (Vehicles !== null) {
    if (Vehicles.length !== 0)
      if (selectedVehicle !== 'null') {
        status = Vehicles.filter(item => item.vn === selectedVehicle);
        if (status.length !== 0) status = status[0].vc;
      } else {
        status = Vehicles[0].vc;
      }
  }
  return (
    <div>
      <Row className="FleetListsubtitle">
        <Col className="noLeftpadding noRightpadding"> Vehicles</Col>
        <Col className="noLeftpadding noRightpadding toogle-link">
          <a onClick={toggleVehiclesList}>
            {vehiclesExpanded ? 'View less' : 'View all'}
          </a>
        </Col>
      </Row>
      <div className="sidemenulist-scroll">
        {vehiclesExpanded === false &&
          (Vehicles !== null ? (
            Vehicles.length === 0 ? (
              <Row>
                <div className="nodatanotificationcontainer-fv ml-auto">
                  <Alert variant="light" id="nodatanotification-fv">
                    <p className="nodatanotificationcontent-fv">
                      No result matches the filter. Please reset the filters to
                      view all configured vehicles.
                    </p>
                  </Alert>
                </div>
              </Row>
            ) : (
              <OverlayTrigger
                placement="right"
                show={true}
                overlay={
                  <Popover id="popover-basic" className="hidewithoutspace">
                    <div className="medium-font overlay-medium">
                      {selectedVehicle === 'null'
                        ? Vehicles[0].vn
                        : selectedVehicle}
                    </div>
                    <div className="medium-font overlay-medium">
                      Operating hours: 1000h
                    </div>
                    <div className="medium-font overlay-medium">
                      <div className="inline-block">Overall condition:</div>
                      <div
                        className="conditionIcon inline-block"
                        id={'goodcondition'}></div>
                    </div>
                    <div className="normal-font overlay-small" id="ess-icon">
                      ESS
                    </div>
                    <div className="normal-font overlay-small" id="wwo-icon">
                      WWO
                    </div>
                    <div
                      className="normal-font overlay-small"
                      id="product-icon">
                      Vehicle prodcuts name
                    </div>
                  </Popover>
                }>
                <Row
                  className={
                    selectedVehicle === 'null' ||
                    selectedView === 'Fleet' ||
                    selectedView === 'VehicleProduct'
                      ? 'FleetListcontainer'
                      : 'FleetListcontainer fleetlistactive'
                  }
                  onClick={() =>
                    onVehicleListClick(
                      selectedVehicle === 'null'
                        ? Vehicles[0].vn
                        : selectedVehicle
                    )
                  }>
                  <Col className="noLeftpadding noRightpadding">
                    <div>
                      <span className="FleetListicon">
                        <SubwayIcon />
                      </span>
                      {selectedVehicle === 'null'
                        ? Vehicles[0].vn
                        : selectedVehicle}
                    </div>
                  </Col>
                  <Col
                    md={3}
                    className="noLeftpadding noRightpadding conwithstar d-flex align-items-center hidewithoutspace">
                    <div
                      className="conditionIcon "
                      id={
                        status === 'ok'
                          ? 'goodcondition'
                          : status === 'trip'
                            ? 'poorcondition'
                            : 'unknowncondition'
                      }></div>
                    <div className="graystar">&#9733;</div>
                  </Col>
                </Row>
              </OverlayTrigger>
            )
          ) : (
            ' Loading ...'
          ))}
        {vehiclesExpanded === true &&
          (Vehicles !== null ? (
            Vehicles.length === 0 ? (
              <Row>
                <div className="nodatanotificationcontainer-fv ml-auto">
                  <Alert variant="light" id="nodatanotification-fv">
                    <p className="nodatanotificationcontent-fv">
                      No result matches the filter. Please reset the filters to
                      view all configured vehicles.
                    </p>
                  </Alert>
                </div>
              </Row>
            ) : (
              Vehicles.map((item, idx) => (
                <OverlayTrigger
                  key={idx}
                  placement="right"
                  show={true}
                  overlay={
                    <Popover id="popover-basic" className="hidewithoutspace">
                      <div className="medium-font overlay-medium">
                        {item.vn}
                      </div>
                      <div className="medium-font overlay-medium">
                        Operating hours: 1000h
                      </div>
                      <div className="medium-font overlay-medium">
                        <div className="inline-block">Overall condition:</div>
                        <div
                          className="conditionIcon inline-block"
                          id={
                            item.vc === 'ok'
                              ? 'goodcondition'
                              : item.vc === 'trip'
                                ? 'poorcondition'
                                : 'unknowncondition'
                          }></div>
                      </div>
                      <div className="normal-font overlay-small" id="ess-icon">
                        ESS
                      </div>
                      <div className="normal-font overlay-small" id="wwo-icon">
                        WWO
                      </div>
                      <div
                        className="normal-font overlay-small"
                        id="product-icon">
                        Vehicle prodcuts name
                      </div>
                    </Popover>
                  }>
                  <Row
                    className={
                      item.vn === selectedVehicle
                        ? 'FleetListcontainer fleetlistactive'
                        : 'FleetListcontainer'
                    }
                    onClick={() => onVehicleListClick(item.vn)}>
                    <Col className="noLeftpadding noRightpadding">
                      <div>
                        <span className="FleetListicon">
                          <SubwayIcon />
                        </span>
                        {item.vn}
                      </div>
                    </Col>
                    <Col
                      md={3}
                      className="noLeftpadding noRightpadding conwithstar d-flex align-items-center hidewithoutspace">
                      <div
                        className="conditionIcon "
                        id={
                          item.vc === 'ok'
                            ? 'goodcondition'
                            : item.vc === 'trip'
                              ? 'poorcondition'
                              : 'unknowncondition'
                        }></div>
                      <div className="graystar">&#9733;</div>
                    </Col>
                  </Row>
                </OverlayTrigger>
              ))
            )
          ) : (
            ' Loading ...'
          ))}
      </div>
    </div>
  );
};

export const VehicleProducts = ({
  toggleVehicleProductsList,
  itemsToShowVehicleProducts,
  vehicleProductsExpanded,
  onVehicleProductListClick,
  vehicleProductList,
  selectedVehicleProduct,
  selectedView,
}) => {
  if (
    vehicleProductList !== null &&
    selectedVehicleProduct !== 'null' &&
    selectedVehicleProduct !== null
  ) {
    const result = vehicleProductList.filter(obj => {
      return obj.an === selectedVehicleProduct;
    });
    if (result !== undefined) {
      localStorage.setItem('selectedVehicleProductStatus', result[0].con);
    }
  } else if (
    (vehicleProductList !== null && selectedVehicleProduct === 'null') ||
    selectedVehicleProduct === null
  ) {
    localStorage.setItem(
      'selectedVehicleProductStatus',
      vehicleProductList[0].con
    );
  }
  return (
    <div>
      <Row className="FleetListtitle">
        <Col className="noLeftpadding noRightpadding"> Vehicle product</Col>
        <Col className="noLeftpadding noRightpadding toogle-link">
          <a onClick={toggleVehicleProductsList}>
            {vehicleProductsExpanded ? 'View less' : 'View all'}
          </a>
        </Col>
      </Row>
      <div className="sidemenulist-scroll">
        {vehicleProductsExpanded === false &&
          (vehicleProductList !== null ? (
            <Row
              className={
                (selectedVehicleProduct !== 'null' &&
                  selectedVehicleProduct !== null) ||
                selectedView === 'VehicleProduct'
                  ? 'FleetListcontainer fleetlistactive'
                  : 'FleetListcontainer'
              }
              onClick={() =>
                onVehicleProductListClick(
                  selectedVehicleProduct !== 'null' &&
                    selectedVehicleProduct !== null
                    ? selectedVehicleProduct
                    : vehicleProductList[0].an
                )
              }>
              <Col className="noLeftpadding noRightpadding">
                <div>
                  <span className="FleetListicon">
                    <VehicleProductIcon />
                  </span>
                  {selectedVehicleProduct !== 'null' &&
                  selectedVehicleProduct !== null
                    ? vehicleProductList.filter(item => {
                        return item.an === selectedVehicleProduct;
                      })[0].dn
                    : vehicleProductList[0].dn}
                </div>
              </Col>
              <Col
                md={3}
                className="noLeftpadding noRightpadding conwithstar d-flex align-items-center">
                <div
                  className="conditionIcon"
                  id={
                    (localStorage.getItem('selectedVehicleProductStatus') !==
                      'null' &&
                      localStorage.getItem('selectedVehicleProductStatus') ===
                        'ok') ||
                    localStorage.getItem('selectedVehicleProductStatus') ===
                      'normal'
                      ? 'goodcondition'
                      : localStorage.getItem('selectedVehicleProductStatus') ===
                          'trip'
                        ? 'poorcondition'
                        : 'unknowncondition'
                  }></div>
                <div className="graystar hidewithoutspace">&#9733;</div>
              </Col>
            </Row>
          ) : (
            ' Loading ...'
          ))}
        {vehicleProductsExpanded === true &&
          (vehicleProductList !== null
            ? vehicleProductList.map((item, idx) => (
                <Row
                  key={idx}
                  className={
                    selectedVehicleProduct === item.an
                      ? 'FleetListcontainer fleetlistactive'
                      : 'FleetListcontainer'
                  }
                  onClick={() => onVehicleProductListClick(item.an)}>
                  <Col className="noLeftpadding noRightpadding">
                    <div>
                      <span className="FleetListicon">
                        <VehicleProductIcon />
                      </span>
                      {item.dn}
                    </div>
                  </Col>
                  {item.con === 'ok' ? (
                    <Col
                      md={3}
                      className="noLeftpadding noRightpadding conwithstar d-flex align-items-center">
                      <div className="conditionIcon" id="goodcondition"></div>
                      <div className="graystar hidewithoutspace">&#9733;</div>
                    </Col>
                  ) : item.con === 'trip' ? (
                    <Col
                      md={3}
                      className="noLeftpadding noRightpadding conwithstar d-flex align-items-center">
                      <div className="conditionIcon" id="poorcondition"></div>
                      <div className="graystar hidewithoutspace">&#9733;</div>
                    </Col>
                  ) : item.con === 'normal' ? (
                    <Col
                      md={3}
                      className="noLeftpadding noRightpadding conwithstar d-flex align-items-center">
                      <div className="conditionIcon" id="goodcondition"></div>
                      <div className="graystar hidewithoutspace">&#9733;</div>
                    </Col>
                  ) : (
                    <Col
                      md={3}
                      className="noLeftpadding noRightpadding conwithstar d-flex align-items-center">
                      <div
                        className="conditionIcon"
                        id="unknowncondition"></div>
                      <div className="graystar hidewithoutspace">&#9733;</div>
                    </Col>
                  )}
                </Row>
                // </OverlayTrigger>
              ))
            : ' Loading ...')}
      </div>
    </div>
  );
};

export default FleetList;
