import Highcharts from 'highcharts/highstock';
import availLegendIcon from '../../assets/images/avalegend.svg';
import notAvailLegendIcon from '../../assets/images/notavallegend.svg';
import unknowLegendIcon from '../../assets/images/unknowleg.svg';
// pie chart

export function getAvaPieChart(
  seriesData,
  pieChartSelected,
  chartType,
  sideMenuFilter,
  filterAvailability
) {
  let cacheAva = {};
  const options = {
    chart: {
      type: 'pie',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      shadow: false,
      marginTop: 16,
      height: 330,
      events: {
        load: function () {
          if (chartType === 'FLEET_AVA_PIE_TYPE') {
            const element = document.getElementById('ava-con');
            element.classList.remove('active-box');
          }
        },
      },
    },
    title: {
      text: '',
      verticalAlign: 'middle',
      floating: true,
      y: -35,
    },
    credits: {
      enabled: false,
    },

    legend: {
      layout: 'vertical',
      itemMarginBottom: 10,
      floating: false,
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
      labelFormatter: function () {
        let name = this.name;
        let img = 'Icon';
        if (this.name === 'Available') {
          name = this.name;
          img = `<img src = ${availLegendIcon} />`;
        } else if (this.name === 'Not available') {
          name = this.name;
          img = `<img src = ${notAvailLegendIcon} />`;
        } else if (this.name === 'Unknown') {
          name = this.name;
          img = `<img src = ${unknowLegendIcon} />`;
        }
        return img + '	' + name;
      },
      useHTML: true,
    },
    tooltip: {
      formatter: function () {
        return `${this.point.name}: <b>${Highcharts.numberFormat(
          this.point.percentage,
          1
        )}%</b>`;
      },
    },
    plotOptions: {
      pie: {
        minSize: 0,
        cursor: 'pointer',
        allowPointSelect: true,
        slicedOffset: 0,
        innerSize: '70%',
        shadow: false,
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            halo: {
              size: 0,
            },
          },
        },
        point: {
          events: {
            click: function (event) {
              const chart = this.series.chart;
              const isCancelSelect = this.options.name === cacheAva.name;
              if (isCancelSelect) {
                cacheAva = {};
                this.graphic.animate({
                  r: this.shapeArgs.r,
                });
                chart.title.update({
                  text: '',
                });
                this.legendItem.element.firstChild.className =
                  'span-legend-item-inactive';
                pieChartSelected('AVA', false, this.options.name);
                if (chartType === 'FLEET_AVA_PIE_TYPE') {
                  /* for calling left side filter methods---------------- */
                  if (this.options.name === 'Not available') {
                    // window.$onPoorFilterCkick(true);
                    sideMenuFilter('availability', '');
                  } else if (this.options.name === 'Available') {
                    // window.$onGoodFilterCkick(true);
                    sideMenuFilter('availability', '');
                  } else if (this.options.name === 'Unknown') {
                    sideMenuFilter('availability', '');
                  }
                  const element = document.getElementById('ava-con');
                  element.classList.remove('active-box');
                }
              } else {
                cacheAva = this.options;
                this.series.points.forEach(item => {
                  item.graphic.animate({
                    r: this.shapeArgs.r,
                  });
                  item.legendItem.element.firstChild.className =
                    'span-legend-item-inactive';
                });
                this.graphic.animate({
                  r: this.shapeArgs.r + 5,
                });

                this.legendItem.element.firstChild.className =
                  'span-legend-item-active';
                pieChartSelected('AVA', true, this.options.name);
                if (chartType === 'FLEET_AVA_PIE_TYPE') {
                  /* for calling left side filter methods---------------- */
                  sessionStorage.setItem('isAutoClick', true);
                  // window.$handleFilterDropdownChange("Availability", true);
                  if (this.options.name === 'Not available') {
                    // window.$onPoorFilterCkick(true);
                    sideMenuFilter('availability', 'red');
                  } else if (this.options.name === 'Available') {
                    // window.$onGoodFilterCkick(true);
                    sideMenuFilter('availability', 'green');
                  } else if (this.options.name === 'Unknown') {
                    // window.$onUnknownFilterCkick(true);
                    sideMenuFilter('availability', 'unknown');
                  }
                  /* --------------------------------------------------------- */
                  const element = document.getElementById('ava-con');
                  element.classList.add('active-box');
                }
                if (chartType === 'FLEET_AVA_PIE_TYPE') {
                  // if (window.$listSelection === "product") {
                  chart.title.update({
                    text: `<span class="per-val">${this.options.pc} ${
                      this.options.pc > 1 ? 'products' : 'product'
                    } </span>`,
                  });
                } else {
                  chart.title.update({
                    text: `<span class="per-val">${this.options.hrs} hrs </span>`,
                  });
                }
              }
            },
            legendItemClick: function (event) {
              event.preventDefault();
              this.series.points.forEach(item => {
                item.legendItem.element.firstChild.className =
                  'span-legend-item-inactive';
              });

              this.firePointEvent('click', event.target);
              return false;
            },
          },
        },
      },
    },
    series: [
      {
        data: seriesData,
      },
    ],
    exporting: {
      enabled: false,
    },
  };

  return options;
}

export function getRelPieChart(
  seriesData,
  pieChartSelected,
  chartType,
  sideMenuFilter,
  filterReliability
) {
  let cacheRel = {};
  const options = {
    chart: {
      type: 'pie',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      shadow: false,
      marginTop: 16,
      height: 330,
      events: {
        load: function () {
          if (chartType === 'FLEET_REL_PIE_TYPE') {
            const element = document.getElementById('rel-con');
            element.classList.remove('active-box');
          }
        },
        redraw: function (event) {
          if (
            chartType === 'VEH_REL_PIE_TYPE' ||
            chartType === 'BATT_REL_PIE_TYPE'
          ) {
            let index;
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              let selectedValue;
              if (chartType === 'VEH_REL_PIE_TYPE') {
                selectedValue = url.searchParams.get('vrp');
              }
              if (chartType === 'BATT_REL_PIE_TYPE') {
                selectedValue = url.searchParams.get('brp');
              }

              if (selectedValue !== null) {
                if (selectedValue === 'Reliable') {
                  index = 2;
                } else if (selectedValue === 'Not reliable') {
                  index = 1;
                } else if (selectedValue === 'Unknown') {
                  index = 0;
                }

                if (index !== null) {
                  const chart = this;
                  chart.series[0].data[index].graphic.animate({
                    r: chart.series[0].data[index].shapeArgs.r + 5,
                  });
                  chart.series[0].chart.title.update({
                    text: `<span class="per-val">${this.series[0].data[index].hrs} hrs </span>`,
                  });
                  chart.series[0].data[
                    index
                  ].legendItem.element.firstChild.className =
                    'span-legend-item-active';
                  pieChartSelected(
                    'REL',
                    true,
                    chart.series[0].data[index].options.name
                  );
                }
              }
            }
          }
        },
      },
    },
    title: {
      text: '',
      verticalAlign: 'middle',
      floating: true,
      y: -35,
    },
    credits: {
      enabled: false,
    },

    legend: {
      layout: 'vertical',
      itemMarginBottom: 10,
      floating: false,
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
      labelFormatter: function () {
        let name = this.name;
        let img = 'Icon';
        if (this.name === 'Reliable') {
          name = this.name;
          img = `<img src = ${availLegendIcon} />`;
        } else if (this.name === 'Not reliable') {
          name = this.name;
          img = `<img src = ${notAvailLegendIcon} />`;
        } else if (this.name === 'Unknown') {
          name = this.name;
          img = `<img src = ${unknowLegendIcon} />`;
        }
        return img + '	' + name;
      },
      useHTML: true,
    },
    tooltip: {
      formatter: function () {
        return `${this.point.name}: <b>${Highcharts.numberFormat(
          this.point.percentage,
          1
        )}%</b>`;
      },
    },
    plotOptions: {
      pie: {
        minSize: 0,
        cursor: 'pointer',
        allowPointSelect: true,
        slicedOffset: 0,
        innerSize: '70%',
        shadow: false,
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            halo: {
              size: 0,
            },
          },
        },
        point: {
          events: {
            click: function (event) {
              const chart = this.series.chart;
              let isCancelSelect = this.options.name === cacheRel.name;
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                if (chartType === 'VEH_REL_PIE_TYPE') {
                  if (url.searchParams.get('vrp') !== null) {
                    isCancelSelect =
                      this.options.name === url.searchParams.get('vrp');
                  }
                }
                if (chartType === 'BATT_REL_PIE_TYPE') {
                  if (url.searchParams.get('brp') !== null) {
                    isCancelSelect =
                      this.options.name === url.searchParams.get('brp');
                  }
                }
              }
              if (isCancelSelect) {
                const url = new URL(window.location.href);
                if (window.location.href.indexOf('?') > -1) {
                  if (chartType === 'VEH_REL_PIE_TYPE') {
                    url.searchParams.delete('vrp');
                  }
                  if (chartType === 'BATT_REL_PIE_TYPE') {
                    url.searchParams.delete('brp');
                  }
                  window.history.pushState(null, null, url);
                }

                cacheRel = {};
                this.graphic.animate({
                  r: this.shapeArgs.r,
                });
                chart.title.update({
                  text: '',
                });
                this.legendItem.element.firstChild.className =
                  'span-legend-item-inactive';
                pieChartSelected('REL', false, this.options.name);

                if (chartType === 'FLEET_REL_PIE_TYPE') {
                  /* for calling left side filter methods---------------- */
                  if (this.options.name === 'Not reliable') {
                    // window.$onPoorFilterCkick(true);
                    sideMenuFilter('reliability', '');
                  } else if (this.options.name === 'Reliable') {
                    // window.$onGoodFilterCkick(true);
                    sideMenuFilter('reliability', '');
                  } else if (this.options.name === 'Unknown') {
                    // window.$onUnknownFilterCkick(true);
                    sideMenuFilter('reliability', '');
                  }
                  /* --------------------------------------------------------- */
                  const element = document.getElementById('rel-con');
                  element.classList.remove('active-box');
                }
              } else {
                const url = new URL(window.location.href);
                if (window.location.href.indexOf('?') > -1) {
                  if (chartType === 'VEH_REL_PIE_TYPE') {
                    url.searchParams.set('vrp', this.options.name);
                  }
                  if (chartType === 'BATT_REL_PIE_TYPE') {
                    url.searchParams.set('brp', this.options.name);
                  }
                  window.history.pushState(null, null, url);
                }

                cacheRel = this.options;
                this.series.points.forEach(item => {
                  item.graphic.animate({
                    r: this.shapeArgs.r,
                  });
                  item.legendItem.element.firstChild.className =
                    'span-legend-item-inactive';
                });
                this.graphic.animate({
                  r: this.shapeArgs.r + 5,
                });

                this.legendItem.element.firstChild.className =
                  'span-legend-item-active';
                pieChartSelected('REL', true, this.options.name);

                if (chartType === 'FLEET_REL_PIE_TYPE') {
                  /* for calling left side filter methods---------------- */
                  sessionStorage.setItem('isAutoClick', true);
                  // window.$handleFilterDropdownChange("Reliability", true);
                  if (this.options.name === 'Not reliable') {
                    // window.$onPoorFilterCkick(true);
                    sideMenuFilter('reliability', 'red');
                  } else if (this.options.name === 'Reliable') {
                    // window.$onGoodFilterCkick(true);
                    sideMenuFilter('reliability', 'green');
                  } else if (this.options.name === 'Unknown') {
                    // window.$onUnknownFilterCkick(true);
                    sideMenuFilter('reliability', 'unknown');
                  }
                  /* --------------------------------------------------------- */
                  const element = document.getElementById('rel-con');
                  element.classList.add('active-box');
                }
                if (chartType === 'FLEET_REL_PIE_TYPE') {
                  // if (window.$listSelection === "product") {
                  chart.title.update({
                    text: `<span class="per-val">${this.options.pc}  ${
                      this.options.pc > 1 ? 'products' : 'product'
                    } </span>`,
                  });
                } else {
                  chart.title.update({
                    text: `<span class="per-val">${this.options.hrs} hrs </span>`,
                  });
                }
              }
            },
            legendItemClick: function (event) {
              event.preventDefault();
              this.series.points.forEach(item => {
                item.legendItem.element.firstChild.className =
                  'span-legend-item-inactive';
              });

              this.firePointEvent('click', event.target);
              return false;
            },
          },
        },
      },
    },
    series: [
      {
        data: seriesData,
      },
    ],
    exporting: {
      enabled: false,
    },
  };

  return options;
}

export function getStrPieChart(
  seriesData,
  pieChartSelected,
  chartType,
  sideMenuFilter,
  filterUtilization
) {
  let cacheStr = {};
  const options = {
    chart: {
      type: 'pie',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      shadow: false,
      marginTop: 16,
      height: 330,
      events: {
        load: function () {
          if (chartType === 'FLEET_STR_PIE_TYPE') {
            const element = document.getElementById('str-con');
            element.classList.remove('active-box');
          }
        },
        redraw: function (event) {
          if (
            chartType === 'VEH_STR_PIE_TYPE' ||
            chartType === 'BATT_STR_PIE_TYPE'
          ) {
            let index;
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              let selectedValue;
              if (chartType === 'VEH_STR_PIE_TYPE') {
                selectedValue = url.searchParams.get('vsp');
              }
              if (chartType === 'BATT_STR_PIE_TYPE') {
                selectedValue = url.searchParams.get('bsp');
              }

              if (selectedValue !== null) {
                if (selectedValue === 'All thresholds met') {
                  index = 2;
                } else if (
                  selectedValue === 'One or more thresholds exceeded'
                ) {
                  index = 1;
                } else if (selectedValue === 'Unknown') {
                  index = 0;
                }

                if (index !== null) {
                  const chart = this;
                  chart.series[0].data[index].graphic.animate({
                    r: chart.series[0].data[index].shapeArgs.r + 5,
                  });
                  chart.series[0].chart.title.update({
                    text: `<span class="per-val">${this.series[0].data[index].hrs} hrs </span>`,
                  });
                  chart.series[0].data[
                    index
                  ].legendItem.element.firstChild.className =
                    'span-legend-item-active';
                  pieChartSelected(
                    'STR',
                    true,
                    chart.series[0].data[index].options.name
                  );
                }
              }
            }
          }
        },
      },
    },
    title: {
      text: '',
      verticalAlign: 'middle',
      floating: true,
      y: -35,
    },
    credits: {
      enabled: false,
    },

    legend: {
      layout: 'vertical',
      itemMarginBottom: 10,
      floating: false,
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
      labelFormatter: function () {
        let name = this.name;
        let img = 'Icon';
        if (chartType === 'FLEET_STR_PIE_TYPE') {
          if (this.name === 'Normal') {
            name = this.name;
            img = `<img src = ${availLegendIcon} />`;
          } else if (this.name === 'Above normal') {
            name = this.name;
            img = `<img src = ${notAvailLegendIcon} />`;
          } else if (this.name === 'Unknown') {
            name = this.name;
            img = `<img src = ${unknowLegendIcon} />`;
          }
        } else {
          if (this.name === 'All thresholds met') {
            name = this.name;
            img = `<img src = ${availLegendIcon} />`;
          } else if (this.name === 'One or more thresholds exceeded') {
            name = this.name;
            img = `<img src = ${notAvailLegendIcon} />`;
          } else if (this.name === 'Unknown') {
            name = this.name;
            img = `<img src = ${unknowLegendIcon} />`;
          }
        }
        return img + '	' + name;
      },
      useHTML: true,
    },
    tooltip: {
      formatter: function () {
        return `${this.point.name}: <b>${Highcharts.numberFormat(
          this.point.percentage,
          1
        )}%</b>`;
      },
    },
    plotOptions: {
      pie: {
        minSize: 0,
        cursor: 'pointer',
        allowPointSelect: true,
        slicedOffset: 0,
        innerSize: '70%',
        shadow: false,
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            halo: {
              size: 0,
            },
          },
        },
        point: {
          events: {
            click: function (event) {
              const chart = this.series.chart;
              let isCancelSelect = this.options.name === cacheStr.name;
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                if (chartType === 'VEH_STR_PIE_TYPE') {
                  if (url.searchParams.get('vsp') !== null) {
                    isCancelSelect =
                      this.options.name === url.searchParams.get('vsp');
                  }
                }
                if (chartType === 'BATT_STR_PIE_TYPE') {
                  if (url.searchParams.get('bsp') !== null) {
                    isCancelSelect =
                      this.options.name === url.searchParams.get('bsp');
                  }
                }
              }
              if (isCancelSelect) {
                const url = new URL(window.location.href);
                if (window.location.href.indexOf('?') > -1) {
                  if (chartType === 'VEH_STR_PIE_TYPE') {
                    url.searchParams.delete('vsp');
                  }
                  if (chartType === 'BATT_STR_PIE_TYPE') {
                    url.searchParams.delete('bsp');
                  }
                  window.history.pushState(null, null, url);
                }

                cacheStr = {};
                this.graphic.animate({
                  r: this.shapeArgs.r,
                });
                chart.title.update({
                  text: '',
                });
                this.legendItem.element.firstChild.className =
                  'span-legend-item-inactive';
                pieChartSelected('STR', false, this.options.name);

                if (chartType === 'FLEET_STR_PIE_TYPE') {
                  /* for calling left side filter methods---------------- */
                  if (this.options.name === 'Above normal') {
                    // window.$onPoorFilterCkick(true);
                    sideMenuFilter('utilization', '');
                  } else if (this.options.name === 'Normal') {
                    // window.$onGoodFilterCkick(true);
                    sideMenuFilter('utilization', '');
                  } else if (this.options.name === 'Unknown') {
                    // window.$onUnknownFilterCkick(true);
                    sideMenuFilter('utilization', '');
                  }
                  /* --------------------------------------------------------- */
                  const element = document.getElementById('str-con');
                  element.classList.remove('active-box');
                }
              } else {
                const url = new URL(window.location.href);
                if (window.location.href.indexOf('?') > -1) {
                  if (chartType === 'VEH_STR_PIE_TYPE') {
                    url.searchParams.set('vsp', this.options.name);
                  }
                  if (chartType === 'BATT_STR_PIE_TYPE') {
                    url.searchParams.set('bsp', this.options.name);
                  }
                  window.history.pushState(null, null, url);
                }

                cacheStr = this.options;
                this.series.points.forEach(item => {
                  item.graphic.animate({
                    r: this.shapeArgs.r,
                  });
                  item.legendItem.element.firstChild.className =
                    'span-legend-item-inactive';
                });
                this.graphic.animate({
                  r: this.shapeArgs.r + 5,
                });

                this.legendItem.element.firstChild.className =
                  'span-legend-item-active';
                pieChartSelected('STR', true, this.options.name);

                if (chartType === 'FLEET_STR_PIE_TYPE') {
                  /* for calling left side filter methods---------------- */
                  sessionStorage.setItem('isAutoClick', true);
                  if (this.options.name === 'Above normal') {
                    sideMenuFilter('utilization', 'red');
                  } else if (this.options.name === 'Normal') {
                    sideMenuFilter('utilization', 'green');
                  } else if (this.options.name === 'Unknown') {
                    sideMenuFilter('utilization', 'unknown');
                  }
                  const element = document.getElementById('str-con');
                  element.classList.add('active-box');
                }
                if (chartType === 'FLEET_STR_PIE_TYPE') {
                  chart.title.update({
                    text: `<span class="per-val">${this.options.pc}  ${
                      this.options.pc > 1 ? 'products' : 'product'
                    } </span>`,
                  });
                } else {
                  chart.title.update({
                    text: `<span class="per-val">${this.options.hrs} hrs </span>`,
                  });
                }
              }
            },
            legendItemClick: function (event) {
              event.preventDefault();
              this.series.points.forEach(item => {
                item.legendItem.element.firstChild.className =
                  'span-legend-item-inactive';
              });

              this.firePointEvent('click', event.target);
              return false;
            },
          },
        },
      },
    },
    series: [
      {
        data: seriesData,
      },
    ],
    exporting: {
      enabled: false,
    },
  };
  return options;
}
