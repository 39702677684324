import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';
import {
  fetchBoxPlotDataFailure,
  fetchBoxPlotDataSucess,
  fetchHistogramDataFailure,
  fetchHistogramDataSucess,
  fetchOverallConditionDataFailure,
  fetchOverallConditionDataSucess,
  fetchVehicleAvailDataFailure,
  fetchVehicleAvailDataSucess,
  fetchVehicleDetailsFailure,
  fetchVehicleDetailsSuccess,
  fetchVehicleRelDataFailure,
  fetchVehicleRelDataSucess,
  fetchVehicleStrDataFailure,
  fetchVehicleStrDataSucess,
} from './actions';
import {
  FETCH_BOXPLOT_DATA,
  FETCH_HISTOGRAM_DATA,
  FETCH_OVERALL_CONDITION_DATA,
  FETCH_VEHICLE_AVAIL_DATA,
  FETCH_VEHICLE_DETAILS,
  FETCH_VEHICLE_REL_DATA,
  FETCH_VEHICLE_STR_DATA,
} from './constants';

export function* fetchOverallConditionSaga() {
  yield takeLatest(FETCH_OVERALL_CONDITION_DATA, fetchingOverallConditionSaga);
}
export function* fetchingOverallConditionSaga(action) {
  try {
    // const { startDate, endDate } = action.payload;
    const response = yield call(api.fetchOverallConditionData);
    const overallConditionData = response;
    yield put(fetchOverallConditionDataSucess(overallConditionData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchOverallConditionDataFailure));
  }
}

/// //////////////////////////////////////////////////////////////////////

export function* fetchHistogramSaga() {
  yield takeLatest(FETCH_HISTOGRAM_DATA, fetchingHistogramSaga);
}
export default function* fetchingHistogramSaga(action) {
  try {
    // const { startDate, endDate } = action.payload;
    const response = yield call(api.fetchHistogramData);
    const histogramData = response.data_Constants;
    yield put(fetchHistogramDataSucess(histogramData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchHistogramDataFailure));
  }
}

export function* fetachBoxPlotDataSaga() {
  yield takeLatest(FETCH_BOXPLOT_DATA, fetchingBoxPlotDataSaga);
}
export function* fetchingBoxPlotDataSaga(action) {
  try {
    const response = yield call(api.fetchBoxPlotData);
    const BoxPlotData = response;

    // dispatch a success action to the store with the new dog
    yield put(fetchBoxPlotDataSucess(BoxPlotData));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchBoxPlotDataFailure));
  }
}

// Vehicle avail data
export function* fetchVehicleAvailSaga() {
  yield takeLatest(FETCH_VEHICLE_AVAIL_DATA, fetchingVehicleAvailSaga);
}
export function* fetchingVehicleAvailSaga(action) {
  try {
    const { sitename, fleetname, vehicleName } = action.payload;
    const response = yield call(
      api.fetchVehicleAvailData,
      sitename,
      fleetname,
      vehicleName
    );
    const vehicleAvailData = response.dc;
    yield put(fetchVehicleAvailDataSucess(vehicleAvailData));
  } catch (error) {
    yield put(handleError(error, fetchVehicleAvailDataFailure));
  }
}

// Vehicle rel data
export function* fetchVehicleRelSaga() {
  yield takeLatest(FETCH_VEHICLE_REL_DATA, fetchingVehicleRelSaga);
}
export function* fetchingVehicleRelSaga(action) {
  try {
    const { sitename, fleetname, vehicleName } = action.payload;
    const response = yield call(
      api.fetchVehicleRelData,
      sitename,
      fleetname,
      vehicleName
    );
    const vehicleRelData = response.dc;
    yield put(fetchVehicleRelDataSucess(vehicleRelData));
  } catch (error) {
    yield put(handleError(error, fetchVehicleRelDataFailure));
  }
}

// Vehicle str data
export function* fetchVehicleStrSaga() {
  yield takeLatest(FETCH_VEHICLE_STR_DATA, fetchingVehicleStrSaga);
}
export function* fetchingVehicleStrSaga(action) {
  try {
    const { sitename, fleetname, vehicleName } = action.payload;
    const response = yield call(
      api.fetchVehicleStrData,
      sitename,
      fleetname,
      vehicleName
    );
    const vehicleStrData = response.dc;
    yield put(fetchVehicleStrDataSucess(vehicleStrData));
  } catch (error) {
    yield put(handleError(error, fetchVehicleStrDataFailure));
  }
}

// Vehicle details
export function* fetchVehicleDetailsSaga() {
  yield takeLatest(FETCH_VEHICLE_DETAILS, fetchingVehicleDetailsSaga);
}
export function* fetchingVehicleDetailsSaga(action) {
  try {
    const { sitename, fleetname, vehicleName } = action.payload;
    const response = yield call(
      api.fetchVehicleDetails,
      sitename,
      fleetname,
      vehicleName
    );
    const vehicleDetails = response;
    yield put(fetchVehicleDetailsSuccess(vehicleDetails));
  } catch (error) {
    yield put(handleError(error, fetchVehicleDetailsFailure));
  }
}
