export const CREATE_BRORADCAST_MESSAGE = 'CREATE_BROADCAST_MESSAGE';
export const FETCH_ALL_BROADCAST_MESSAGE = 'FETCH_ALL_BROADCAST_MESSAGE';
export const FETCH_ALL_BROADCAST_MESSAGE_SUCCESS =
  'FETCH_ALL_BROADCAST_MESSAGE_SUCCESS';

export const FETCH_ALL_BROADCAST_MESSAGE_FAILURE =
  'FETCH_ALL_BROADCAST_MESSAGE_FAILURE';

export const DELETE_BROADCAST_MESSAGE = 'DELETE_BROADCAST_MESSAGE';

export const FETCH_ACTIVE_MESSAGES = 'FETCH_ACTIVE_MESSAGES';
export const FETCH_ACTIVE_MESSAGES_SUCCESS = 'FETCH_ACTIVE_MESSAGES_SUCCESS';
export const FETCH_ACTIVE_MESSAGES_FAILURE = 'FETCH_ACTIVE_MESSAGES_FAILURE';
export const UPDATE_BROADCAST_MESSAGE = 'UPDATE_BROADCAST_MESSAGE';
