export const overlaySignalOptions = [
  'Cell Voltage',
  'Temperature',
  'State of Charge',
  'Power',
  'Battery Voltage',
  'Battery Current',
  'Battery Peak Chg.',
  'Battery Peak DisChg.',
  'Battery Cont. Chg.',
  'Battery Cont. DisChg.',
  // "Battery Overload Cntr",
];

export const mainSignalTrendOptions = [
  'Cell Voltage',
  'Temperature',
  'State of Charge',
  'Power',
  'Battery Voltage',
  'Battery Current',
  'Battery Peak Chg.',
  'Battery Peak DisChg.',
  'Battery Cont. Chg.',
  'Battery Cont. DisChg.',
  // "Battery Overload Cntr",
];

export const overlaySignalOptionsDemo = [
  'Temperature',
  'State of Charge',
  'Power',
  'Battery Voltage',
  'Battery Current',
  'Reliability',
];

export const mainSignalTrendOptionsDemo = [
  'Temperature',
  'State of Charge',
  'Power',
  'Battery Voltage',
  'Battery Current',
  'Reliability',
];
