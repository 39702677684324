import Moment from 'moment';
import React from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';

import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';

require('./styles.scss');

Moment.locale('en');

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable
        handle=".modal-content"
        enableUserSelectHack={false}
        bounds={'main'}
        // defaultPosition={{ x: 450, y: 40 }}
        axis="both"
        defaultPosition={{ x: 0, y: 0 }}
        position={null}>
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

function EventPopup(props) {
  const [validated, setValidated] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const handleSubmit = e => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else if (form.checkValidity() === true) {
      e.preventDefault();
      const answer = window.confirm(
        'The changes will be implemented. Please confirm to proceed.'
      );
      if (answer) {
        props.handleEventSubmit(e);
        setValidated(false);
        setVisible(true);
        setTimeout(() => {
          setVisible(false);
        }, 9000);
      }
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogAs={DraggableModalDialog}
      autoFocus={false}
      enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <Form.Group as={Row} className="mb-3" controlId="cdate">
            <Form.Label column md="2">
              Date/Time:
            </Form.Label>
            <Col md="10">
              <Form.Control
                plaintext
                readOnly
                name="cdate"
                // onChange={this.props.handleSiteInputChange}
                value={Moment().format('DD:MM:YYYY HH:mm:ss')}
              />
            </Col>
          </Form.Group>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <div className="eventsetupform">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {props.saveEventResponce !== null && visible === true ? (
              <Col md={12} className=" noLeftpadding noRightpadding sucessmsg">
                <Alert
                  variant={
                    props.saveEventResponce[0].sts === false
                      ? 'danger'
                      : 'success'
                  }
                  className="savemsgalert">
                  <p className="nodatanotificationcontent">
                    {props.saveEventResponce[0].msg}
                  </p>
                </Alert>
              </Col>
            ) : (
              ''
            )}

            <div className="frmsection1">
              <Row>
                <Col md={4} className="noLeftpadding noRightpadding topinfo">
                  <Form.Label>Fleet: </Form.Label>{' '}
                  {sessionStorage.getItem('selectedFleet')}
                </Col>
                <Col md={4} className="noLeftpadding noRightpadding topinfo">
                  <Form.Label>Vehicle: </Form.Label>{' '}
                  {sessionStorage.getItem('selectedVehicle') === null ||
                  sessionStorage.getItem('selectedVehicle') === 'null'
                    ? sessionStorage.getItem('eVnum')
                    : sessionStorage.getItem('selectedVehicle')}
                </Col>
                <Col md={4} className="noLeftpadding noRightpadding topinfo">
                  <Form.Label>IP address: </Form.Label>{' '}
                  {props.eventFormValues.pecIP}
                </Col>
              </Row>

              <Row className="frmcontrols">
                <Form.Group
                  as={Col}
                  md={12}
                  controlId="exchangetype"
                  className="radiobtns">
                  <Form.Label>
                    Please choose what has been exchanged:
                  </Form.Label>
                  <Form.Check
                    inline
                    value="ESS"
                    label="ESS"
                    name="exchangetype"
                    type="radio"
                    id={`inline-'radio'-1`}
                    onChange={props.handleEventRadioChange}
                    checked={props.eventFormValues.exchangetype === 'ESS'}
                  />
                  <Form.Check
                    inline
                    value="PEC"
                    label="PEC"
                    name="exchangetype"
                    type="radio"
                    id={`inline-'radio'-2`}
                    onChange={props.handleEventRadioChange}
                    checked={props.eventFormValues.exchangetype === 'PEC'}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="oessan">
                  <Form.Label>Old ESS article number</Form.Label>
                  <Form.Control
                    required
                    readOnly
                    autoComplete="off"
                    type="text"
                    placeholder=""
                    name="oessan"
                    // onChange={props.handleEventInputChange}
                    value={props.eventFormValues.oessan}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide old ESS article number.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="nessan">
                  <Form.Label>New ESS article number </Form.Label>
                  <Form.Control
                    required
                    readOnly={props.eventFormValues.exchangetype === 'PEC'}
                    autoComplete="off"
                    type="text"
                    placeholder=""
                    name="nessan"
                    onChange={
                      props.eventFormValues.exchangetype === 'PEC'
                        ? () => {}
                        : props.handleEventInputChange
                    }
                    value={props.eventFormValues.nessan}
                    minLength="15"
                    maxLength="15"
                    pattern="^3BHE0\d\d\d\d\dR0\d\d\d$"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide new ESS article number in expected pattern
                    3BHE0 followed by 5 integrer R0 followed by 3 intgerer.
                  </Form.Control.Feedback>
                </Form.Group>
                {/*  */}
                <Form.Group as={Col} md={6} controlId="oesssn">
                  <Form.Label>Old ESS serial number</Form.Label>
                  <Form.Control
                    required
                    readOnly
                    autoComplete="off"
                    type="text"
                    placeholder=""
                    name="oesssn"
                    // onChange={props.handleEventInputChange}
                    value={props.eventFormValues.oesssn}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide old ESS serial number.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="nesssn">
                  <Form.Label>New ESS serial number </Form.Label>
                  <Form.Control
                    required
                    readOnly={props.eventFormValues.exchangetype === 'PEC'}
                    autoComplete="off"
                    // type="number"
                    placeholder=""
                    name="nesssn"
                    onChange={
                      props.eventFormValues.exchangetype === 'PEC'
                        ? () => {}
                        : props.handleEventInputChange
                    }
                    value={props.eventFormValues.nesssn}
                    // min="0"
                    // max="9999"
                    pattern="^(?=[0-9]{1,4}$)0*[1-9][0-9]{0,}$"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide new ESS serial number (max four digit).
                  </Form.Control.Feedback>
                </Form.Group>
                {/*  */}
                <Form.Group as={Col} md={6} controlId="opecan">
                  <Form.Label>Old PEC article number</Form.Label>
                  <Form.Control
                    required
                    autoComplete="off"
                    readOnly
                    type="text"
                    placeholder=""
                    name="opecan"
                    // onChange={props.handleEventInputChange}
                    value={props.eventFormValues.opecan}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide old PEC article number.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="npecan">
                  <Form.Label>New PEC article number </Form.Label>
                  <Form.Control
                    required
                    autoComplete="off"
                    type="text"
                    placeholder=""
                    name="npecan"
                    onChange={props.handleEventInputChange}
                    value={props.eventFormValues.npecan}
                    minLength="15"
                    maxLength="15"
                    pattern="^3BHE0\d\d\d\d\dR0\d\d\d$"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide new ESS article number in expected pattern
                    3BHE0 followed by 5 integrer R0 followed by 3 intgerer.
                  </Form.Control.Feedback>
                </Form.Group>
                {/*  */}
                <Form.Group as={Col} md={6} controlId="opecsn">
                  <Form.Label>Old PEC serial number</Form.Label>
                  <Form.Control
                    required
                    readOnly
                    autoComplete="off"
                    type="text"
                    placeholder=""
                    name="opecsn"
                    // onChange={props.handleEventInputChange}
                    value={props.eventFormValues.opecsn}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide old PEC serial number.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="npecsn">
                  <Form.Label>New PEC serial number </Form.Label>
                  <Form.Control
                    required
                    autoComplete="off"
                    // type="number"
                    placeholder=""
                    name="npecsn"
                    onChange={props.handleEventInputChange}
                    value={props.eventFormValues.npecsn}
                    // min="1000"
                    // max="9999999"
                    pattern="^(?=[0-9]{4,8}$)0*[1-9][0-9]{0,}$"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please provide new PEC serial number (min four digit and max
                    eight digit).
                  </Form.Control.Feedback>
                </Form.Group>
                {/*  */}
                <Form.Group as={Col} md={12} controlId="comments">
                  <Form.Label>Comments (Max 250 characters.)</Form.Label>
                  <Form.Control
                    //  required
                    autoComplete="off"
                    as="textarea"
                    rows="5"
                    placeholder=""
                    name="comments"
                    onChange={props.handleEventInputChange}
                    value={props.eventFormValues.comments}
                    maxLength="250"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide comments.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="modal-footer">
                <Button
                  variant="secondary"
                  onClick={props.onHide}
                  className="smallSilverButton btn  btn-sm">
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="smallButton btn btn-primary btn-sm">
                  {props.loadingSaveEvent === true ? 'Inprogress' : 'Submit'}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EventPopup;
