import React, { useState } from 'react';
import './styles.scss';
import { ArrowDownLine } from '../../assets/images';

const CollapsableSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleSection = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="collapsable-section">
      <div className="collapsable-section-title" onClick={toggleSection}>
        {isOpen ? (
          <div className="collapsable-section-icon">
            <ArrowDownLine style={{ transform: 'rotate(180deg)' }} />
          </div>
        ) : (
          <div className="collapsable-section-icon">
            <ArrowDownLine />
          </div>
        )}
        {title}
      </div>
      {isOpen && <div className="collapsable-section-content">{children}</div>}
    </div>
  );
};
export default CollapsableSection;
