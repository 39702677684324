import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';
import {
  getFleetDataFailure,
  getFleetDataSuccess,
  getPrdtInfoSetupValuesFailure,
  getPrdtInfoSetupValuesSucess,
  getPrdtSetupValuesFailure,
  getPrdtSetupValuesSucess,
  getSetupFleetListFailure,
  getSetupFleetListSucess,
  getSetupSidemenuListFailure,
  getSetupSidemenuListSucess,
  getSetupSiteListFailure,
  getSetupSiteListSucess,
  getUsedFleetNameListFailure,
  getUsedFleetNameListSucess,
  getValidateFleetDataPathFailure,
  getValidateFleetDataPathSuccess,
  getVehicleSetupFailure,
  getVehicleSetupSucess,
  saveFleetFailure,
  saveFleetSucess,
  saveSiteFailure,
  saveSiteSucess,
  saveVehicleSetupFailure,
  saveVehicleSetupSucess,
  saveproductInfoSetupFailure,
  saveproductInfoSetupSucess,
  saveproductSetupFailure,
  saveproductSetupSucess,
} from './actions';
import {
  GET_FLEETDATA,
  GET_PRDTINFO_SETUP_VALUES,
  GET_PRDT_SETUP_VALUES,
  GET_SETUP_FLEET_LIST,
  GET_SETUP_SIDEMENULIST,
  GET_SETUP_SITE_LIST,
  GET_USED_FLEET_NAMELIST,
  GET_VALIDATE_FLEETDATAPATH,
  GET_VEHICLESETUP_VALUES,
  SAVE_FLEET_DATA,
  SAVE_PRDTINFO_DATA,
  SAVE_PRDT_DATA,
  SAVE_SITE_DATA,
  SAVE_VEHICLE_DATA,
} from './constants';

//  for save site
export function* saveSiteSaga() {
  yield takeLatest(SAVE_SITE_DATA, savingSiteSaga);
}
export function* savingSiteSaga(action) {
  try {
    const { editSite, siteFormValues } = action.payload;
    const response = yield call(api.saveSite, editSite, siteFormValues);

    const saveSiteResponce = response.dc;
    yield put(saveSiteSucess(saveSiteResponce));
  } catch (error) {
    yield put(handleError(error, saveSiteFailure));
  }
}

//  for save fleet
export function* saveFleetSaga() {
  yield takeLatest(SAVE_FLEET_DATA, savingFleetSaga);
}
export function* savingFleetSaga(action) {
  try {
    const { fleetobj } = action.payload;
    const response = yield call(api.saveFleet, fleetobj);

    const saveFleetResponce = response.dc;
    yield put(saveFleetSucess(saveFleetResponce));
  } catch (error) {
    yield put(handleError(error, saveFleetFailure));
  }
}

//  for get site list
export function* getSetupSiteListSaga() {
  yield takeLatest(GET_SETUP_SITE_LIST, gettingSetupSiteListSaga);
}
export function* gettingSetupSiteListSaga(action) {
  try {
    const { siteName } = action.payload;
    const response = yield call(api.getSetupSiteList, siteName);

    const setupSiteListResponce = response.dc;
    yield put(getSetupSiteListSucess(setupSiteListResponce));
  } catch (error) {
    yield put(handleError(error, getSetupSiteListFailure));
  }
}

//  for get fleet list

export function* getSetupFeetListSaga() {
  yield takeLatest(GET_SETUP_FLEET_LIST, gettingSetupFleetListSaga);
}
export function* gettingSetupFleetListSaga(action) {
  try {
    const { siteName } = action.payload;
    const response = yield call(api.getSetupFleetList, siteName);

    const setupFleetListResponce = response.dc;

    yield put(getSetupFleetListSucess(setupFleetListResponce));
  } catch (error) {
    yield put(handleError(error, getSetupFleetListFailure));
  }
}
//  for save Vehicle Setup
export function* saveVehicleSetupSaga() {
  yield takeLatest(SAVE_VEHICLE_DATA, savingVehicleSetupSaga);
}
export function* savingVehicleSetupSaga(action) {
  try {
    const { obj } = action.payload;
    const response = yield call(api.saveVehicleSetup, obj);

    const saveVehicleSetupResponce = response.dc;
    yield put(saveVehicleSetupSucess(saveVehicleSetupResponce));
  } catch (error) {
    yield put(handleError(error, saveVehicleSetupFailure));
  }
}

export function* getVehicleSetupValuesSaga() {
  yield takeLatest(GET_VEHICLESETUP_VALUES, gettingVehicleSetupValuesSaga);
}
export function* gettingVehicleSetupValuesSaga(action) {
  try {
    const { fleetName } = action.payload;
    const response = yield call(api.getVehicleSetupValues, fleetName);

    const vehicleSetupValuesResponce = response.dc;
    yield put(getVehicleSetupSucess(vehicleSetupValuesResponce));
  } catch (error) {
    yield put(handleError(error, getVehicleSetupFailure));
  }
}

//  for save productSetup
export function* saveProductSetupSaga() {
  yield takeLatest(SAVE_PRDT_DATA, savingProductSetupSaga);
}
export function* savingProductSetupSaga(action) {
  try {
    const { obj } = action.payload;
    const response = yield call(api.saveproductSetup, obj);

    const saveProductSetupResponce = response.dc;
    yield put(saveproductSetupSucess(saveProductSetupResponce));
  } catch (error) {
    yield put(handleError(error, saveproductSetupFailure));
  }
}

export function* saveProductInfoSetupSaga() {
  yield takeLatest(SAVE_PRDTINFO_DATA, savingProductInfoSetupSaga);
}
export function* savingProductInfoSetupSaga(action) {
  try {
    const { obj } = action.payload;
    const response = yield call(api.saveProductInfoSetup, obj);

    const saveProductInfoSetupResponce = response.dc;
    yield put(saveproductInfoSetupSucess(saveProductInfoSetupResponce));
  } catch (error) {
    yield put(handleError(error, saveproductInfoSetupFailure));
  }
}

export function* getPrdtSetupValuesSaga() {
  yield takeLatest(GET_PRDT_SETUP_VALUES, gettingPrdtSetupValuesSaga);
}
export function* gettingPrdtSetupValuesSaga(action) {
  try {
    const { fleetName } = action.payload;
    const response = yield call(api.getPrdtSetupValues, fleetName);

    const prdtSetupValuesResponce = response.dc;
    yield put(getPrdtSetupValuesSucess(prdtSetupValuesResponce));
  } catch (error) {
    yield put(handleError(error, getPrdtSetupValuesFailure));
  }
}

export function* getPrdtInfoSetupValuesSaga() {
  yield takeLatest(GET_PRDTINFO_SETUP_VALUES, gettingPrdtInfoSetupValuesSaga);
}
export function* gettingPrdtInfoSetupValuesSaga(action) {
  try {
    const { fleetName } = action.payload;
    const response = yield call(api.getPrdtInfoSetupValues, fleetName);

    const prdtInfoSetupValuesResponce = response.dc;
    yield put(getPrdtInfoSetupValuesSucess(prdtInfoSetupValuesResponce));
  } catch (error) {
    yield put(handleError(error, getPrdtInfoSetupValuesFailure));
  }
}

//  for get setup sidemenu list
export function* getSetupSidemenuListSaga() {
  yield takeLatest(GET_SETUP_SIDEMENULIST, gettingSetupSidemenuListSaga);
}
export function* gettingSetupSidemenuListSaga(action) {
  try {
    // const { siteName } = action.payload;
    const response = yield call(api.getSetupSidemenuList);
    const setupSidemenuListResponce = response.dc;
    yield put(getSetupSidemenuListSucess(setupSidemenuListResponce));
  } catch (error) {
    yield put(handleError(error, getSetupSidemenuListFailure));
  }
}

//  for get used fleet name list
export function* getUsedFleetNameListSaga() {
  yield takeLatest(GET_USED_FLEET_NAMELIST, getingUsedFleetNameListSaga);
}
export function* getingUsedFleetNameListSaga(action) {
  try {
    const response = yield call(api.getUsedFleetNameList);

    const usedFleetNameListResponce = response.dc;
    yield put(getUsedFleetNameListSucess(usedFleetNameListResponce));
  } catch (error) {
    yield put(handleError(error, getUsedFleetNameListFailure));
  }
}

//  Validate Fleet data path
export function* getValidateFleetDataPathSaga() {
  yield takeLatest(
    GET_VALIDATE_FLEETDATAPATH,
    gettingValidateFleetDataPathSaga
  );
}
export function* gettingValidateFleetDataPathSaga(action) {
  try {
    const { fleetDataPath, connectivityKeyPath, platform, connectivityType } =
      action.payload;
    const response = yield call(
      api.getValidateFleetDataPath,
      fleetDataPath,
      connectivityKeyPath,
      platform,
      connectivityType
    );
    const getValidateFleetDataPathResponce = response.validated;
    yield put(
      getValidateFleetDataPathSuccess(getValidateFleetDataPathResponce)
    );
  } catch (error) {
    yield put(handleError(error, getValidateFleetDataPathFailure));
  }
}

// Load Fleet data - Connectivity key path
export function* getFleetDataSaga() {
  yield takeLatest(GET_FLEETDATA, gettingFleetDataSaga);
}
export function* gettingFleetDataSaga(action) {
  try {
    const { fleetData } = action.payload;
    const response = yield call(api.getFleetDataforSetup, fleetData);
    const getFleetDataResponce = response.dc;
    yield put(getFleetDataSuccess(getFleetDataResponce));
  } catch (error) {
    yield put(handleError(error, getFleetDataFailure));
  }
}
