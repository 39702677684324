import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './styles.scss';

export function Confirmdialog(props) {
  return (
    <Modal
      className="emailalertdialogbox"
      show={props.dialogshow}
      onHide={() => props.handlefunc(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Email alert setting</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.dialogmsg}</Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-sm okbtn"
          onClick={() => props.handlefunc(false)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
