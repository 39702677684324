import { applicationView } from './excel-utils';

const reformattingString = str => {
  const parts = str.split('-');
  const firstPart = parts.shift();
  parts.push(firstPart);
  return parts.join('-');
};
const CamelCaseString = str => {
  const splittedwords = str.split('-');
  const camelCaseWords = splittedwords.map((splitword, index) => {
    if (index === 0) {
      return (
        splitword.charAt(0).toUpperCase() + splitword.slice(1).toLowerCase()
      );
    }
    return splitword.charAt(0).toUpperCase() + splitword.slice(1);
  });
  return camelCaseWords.join('-');
};

export const updateTitle = (location, urlKey) => {
  const pathParts = location.pathname.split('/');
  const fleetView = pathParts[pathParts.length - 1];
  const searchParams = new URLSearchParams(location.search);
  const fleetName = searchParams.get('ft');
  const vehicleName = searchParams.get('vc');
  const ESS = searchParams.get('pdn');
  const tb = searchParams.get('tb');
  const formattedtb = tb && reformattingString(tb);
  const vtb = searchParams.get('vtb');
  const formattedVtb = vtb && reformattingString(vtb);
  const stb = searchParams.get('stb');
  const pkdn = searchParams.get('pkdn');
  let tab =
    (formattedVtb && CamelCaseString(formattedVtb)) ||
    stb ||
    (formattedtb && CamelCaseString(formattedtb));
  if (!tab) {
    tab = '';
  } else {
    tab =
      '-' +
      CamelCaseString(fleetView) +
      '-' +
      tab
        .replace('View-', '')
        .replace('List-', '')
        .replace('Event', 'EventList')
        .replace('list', '')
        .replace('Vehicleprod-', '')
        .replace('-Fleet', '')
        .replace('Vehicle-Overview', 'Overview')
        .replace('EventList-Vehicle', 'EventList');
  }
  let title = ``;
  if (applicationView() === 'ReportView') {
    title = 'Report View';
  } else if (applicationView() === 'Broadcast') {
    title = 'Broadcast';
  } else if (applicationView() === 'FleetConfiguration') {
    title = 'Fleet Configuration';
  } else if (pkdn) {
    title += `${pkdn} `;
  } else if (ESS) {
    title += `${ESS}`;
  } else if (vehicleName) {
    title += `${vehicleName}`;
  } else if (fleetName) {
    title = `${fleetName}`;
  } else {
    title = 'Dashboard';
  }
  const restructureText = tab.replace(/\b\w/g, c => c);
  title += `${restructureText}`;
  document.title = title.trim();
};
