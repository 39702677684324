import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reducers } from './reducers/index';
import { rootSaga } from './sagas/index';
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// create a redux store with our reducer above and middleware
const store = createStore(
  reducers,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(sagaMiddleware))
);

// run the saga
sagaMiddleware.run(rootSaga);

export default store;
