export const formatDesignedBudgetLabel = (label, unit, text) => {
  if (label.startsWith('bin')) {
    return label;
  }

  const regex = /-?\d+(\.\d+)?/g;
  let designedBudgetInputLabel = label.replace(regex, match => {
    return match + unit;
  });

  const positionOfWord = designedBudgetInputLabel.indexOf(unit);
  if (positionOfWord !== -1) {
    const positionToReplace = designedBudgetInputLabel.indexOf(
      '-',
      positionOfWord + unit.length
    );
    if (positionToReplace !== -1) {
      designedBudgetInputLabel =
        designedBudgetInputLabel.slice(0, positionToReplace) +
        ` ${text} ` +
        designedBudgetInputLabel.slice(positionToReplace + 1);
    }
  }

  return designedBudgetInputLabel;
};
