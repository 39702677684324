import React from 'react';
// Import Highcharts
import Highcharts from 'highcharts/highstock';
// import HighchartsReact from "./HighchartsReact.min.js";
import HighchartsReact from 'highcharts-react-official';
import HC_boost from 'highcharts/modules/boost';
import HC_boost_canvas from 'highcharts/modules/boost-canvas';
import HC_exporting from 'highcharts/modules/exporting';
import { getSynchronizedGraph } from './function';
import './styles.scss';

HC_exporting(Highcharts);
HC_boost_canvas(Highcharts);
HC_boost(Highcharts);

(function (H) {
  H.Pointer.prototype.reset = function () {
    return undefined;
  };

  H.syncExtremes = function (e) {
    const thisChart = this.chart;

    if (e.trigger !== 'syncExtremes') {
      // Prevent feedback loop
      Highcharts.each(Highcharts.charts, function (chart) {
        if (chart && chart !== thisChart) {
          if (chart.xAxis[0].setExtremes) {
            // It is null while updating
            chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
              trigger: 'syncExtremes',
            });
          }
        }
      });
    }
  };
})(Highcharts);

export default class SynchronizedGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: getSynchronizedGraph(this.props.data),
    };
  }

  componentDidMount() {
    ['mouseleave'].forEach(function (eventType) {
      document
        .getElementById('container')
        .addEventListener(eventType, function (e) {
          let chart, i, event;

          for (i = 0; i < Highcharts.charts.length; i = i + 1) {
            chart = Highcharts.charts[i];
            if (chart) {
              // Find coordinates within the chart
              event = chart.pointer.normalize(e);
              // Get the hovered point
              chart.series.forEach(series => {
                const point = series.searchPoint(event, true);
                if (point) {
                  try {
                    point.onMouseOut();
                    chart.tooltip.hide(point);
                    chart.xAxis[0].hideCrosshair();
                  } catch (err) {
                    // pass;
                  }
                }
              });
            }
          }
        });
    });
    ['mousemove', 'touchmove', 'touchstart'].forEach(function (eventType) {
      document
        .getElementById('container')
        .addEventListener(eventType, function (e) {
          let chart, i, event;

          for (i = 0; i < Highcharts.charts.length; i = i + 1) {
            chart = Highcharts.charts[i];
            if (chart) {
              // Find coordinates within the chart
              event = chart.pointer.normalize(e);
              // Get the hovered point
              chart.series.forEach(series => {
                const point = series.searchPoint(event, true);
                if (point) {
                  try {
                    point.highlight(e);
                  } catch (err) {
                    // pass;
                  }
                }
              });
            }
          }
        });
    });
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.forceUpdate();
      return true;
    } else {
      return false;
    }
  }

  updateSeries = () => {
    // The chart is updated only with new options.
    const options = getSynchronizedGraph(this.props.data);
    this.setState({
      options,
    });
  };

  componentDidUpdate() {
    this.updateSeries();
  }

  render() {
    return (
      <div id="container" className="customsynchedchart">
        {this.props.data.map((object, i) => (
          <HighchartsReact
            key={i}
            constructorType={'chart'}
            highcharts={Highcharts}
            options={this.state.options[i]}
          />
        ))}
      </div>
    );
  }
}
