export const FETCH_MAP_DATA = 'FETCH_MAP_DATA';
export const FETCH_MAP_DATA_SUCCESS = 'FETCH_MAP_DATA_SUCCESS';
export const FETCH_MAP_DATA_FAILURE = 'FETCH_MAP_DATA_FAILURE';

export const FETCH_FLEET_LIST = 'FETCH_FLEET_LIST';
export const FETCH_FLEET_LIST_SUCCESS = 'FETCH_FLEET_LIST_SUCCESS';
export const FETCH_FLEET_LIST_FAILURE = 'FETCH_FLEET_LIST_FAILURE';

export const FETCH_ALL_FLEET_LIST = 'FETCH_ALL_FLEET_LIST';
export const FETCH_ALL_FLEET_LIST_SUCCESS = 'FETCH_ALL_FLEET_LIST_SUCCESS';
export const FETCH_ALL_FLEET_LIST_FAILURE = 'FETCH_ALL_FLEET_LIST_FAILURE';

export const FETCH_FILTER_LIST = 'FETCH_FILTER_LIST';
export const FETCH_FILTER_LIST_SUCCESS = 'FETCH_FILTER_LIST_SUCCESS';
export const FETCH_FILTER_LIST_FAILURE = 'FETCH_FILTER_LIST_FAILURE';

export const FETCH_MONITORED_COUNT = 'FETCH_MONITORED_COUNT';
export const FETCH_MONITORED_COUNT_SUCCESS = 'FETCH_MONITORED_COUNT_SUCCESS';
export const FETCH_MONITORED_COUNT_FAILURE = 'FETCH_MONITORED_COUNT_FAILURE';

export const SET_CURRENT_FLEET = 'SET_CURRENT_FLEET';
export const SET_DATE = 'SET_DATE';

/// /////////////////////////////////////////////////////////////////////////

export const CHECK_USER = 'CHECK_USER';
export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = 'CHECK_USER_FAILURE';

export const SET_USER_GROUP = 'SET_USER_GROUP';
