import {
  EMERGENCY_STATUS_LOADING,
  FETCH_EMERGENCY_STATUS,
  SET_EMERGENCY_KEY,
  SET_EMERGENCY_KEY_FAILURE,
  SET_EMERGENCY_KEY_SUCCESS,
  SET_EMERGENCY_STATUS,
  SET_EMERGENCY_STATUS_FAILURE,
  SET_EMERGENCY_STATUS_SUCCESS,
} from './constants';

export const emergencyStatusLoading = ({ status }) => ({
  type: EMERGENCY_STATUS_LOADING,
  status,
});

export const getEmergencyStatus = () => ({
  type: FETCH_EMERGENCY_STATUS,
});

export const setEmergencyStatusSuccess = emergencyStatus => {
  return {
    type: SET_EMERGENCY_STATUS_SUCCESS,
    emergencyStatus,
  };
};
export const setEmergencyStatusFailure = error => ({
  type: SET_EMERGENCY_STATUS_FAILURE,
  error,
});
export const setEmergencyStatus = ({ message, secret, enabled }) => {
  return {
    type: SET_EMERGENCY_STATUS,
    payload: { message, secret, enabled },
  };
};

export const setEmergencyKeySuccess = ({ message, enabled }) => {
  return {
    type: SET_EMERGENCY_KEY_SUCCESS,
    emergencyKey: { message, enabled },
  };
};
export const setEmergencyKeyFailure = error => ({
  type: SET_EMERGENCY_KEY_FAILURE,
  error,
});
export const setEmergencyKey = ({ oldSecret, newSecret }) => {
  return {
    type: SET_EMERGENCY_KEY,
    payload: { oldSecret, newSecret },
  };
};
