import React from 'react';
import { ArrowRightLine, UnauthorizedIcon } from '../../assets/images';
import './styles.scss';

function Unauthorized() {
  return (
    <div className="unauth_page">
      <div className="unauth_content">
        <UnauthorizedIcon alt="ABB" />
        <div className="text-center">
          Sorry! You don’t have the required premissions or privilages to access
          the page. <br />
          Please contact portal administrator for access grants.
        </div>
        <button
          className="buttonBack"
          onClick={() => window.location.replace('/Dashboard')}>
          <ArrowRightLine
            style={{ transform: 'rotate(180deg)', marginRight: '10px' }}
          />
          Back to Dashboard
        </button>
      </div>
    </div>
  );
}

export default Unauthorized;
