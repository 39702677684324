import { fromJS } from 'immutable';
import { SET_SIDEBAR_WIDTH } from './constants';

const initialState = fromJS({
  sideBarWidth: 370,
});

export function DraggableLayoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIDEBAR_WIDTH:
      return state.set('sideBarWidth', action.width);
    default:
      return state;
  }
}
