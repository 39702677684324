export const updateState = (arr, stateType) => {
  if (Array.isArray(arr)) {
    let result = '';
    if (stateType === 'uniqueKey') {
      result = arr.map(item => item.id).join('|');
    } else if (stateType === 'uniqueValue') {
      result = arr.map(item => item.value).join('|');
    } else {
      return 'All';
    }
    return result.endsWith('|') ? result.slice(0, -1) : result;
  } else return 'All';
};
