import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './styles.scss';

import { checkUserEntitlements } from '../../userConfig';
import PieGraph from '../PieGraph';

Moment.locale('en');

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAvaactive: false,
      isRelactive: false,
      isStractive: false,
    };
  }

  pieChartSelected = (chartId, flag, status) => {
    /* if (chartId === "AVA" && flag === true) {
      this.setState(
        {
          isAvaactive: flag,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isAvaactive: false,
            });
          }, 0);
        }
      );
    }
    // else if (chartId === "AVA" && flag === false) {
    //   this.setState({
    //     filteredVehicleList: this.state.VehicleList,
    //   });
    // }
    else if (chartId === "REL" && flag === true) {
      this.setState({ isRelactive: flag }, () => {
        setTimeout(() => {
          this.setState({
            isRelactive: false,
          });
        }, 0);
      });
    } else if (chartId === "STR" && flag === true) {
      this.setState({ isStractive: flag }, () => {
        setTimeout(() => {
          this.setState({
            isStractive: false,
          });
        }, 0);
      });
    } */
  };

  render() {
    return (
      <Row className="fleetoverview">
        {checkUserEntitlements('ESS:Fleet-Overview-Availability') && (
          <Col md={4} className=" piecontainer lightbg" id="ava-con">
            <div className="overviewtitle" id="ava">
              Availability
            </div>
            <div className="overviewsubtitle truncate-text">
              Shows the last status of the asset(s) within the last 48 hrs
            </div>
            {this.state.isStractive === true ||
            this.state.isRelactive === true ||
            (this.props.filterItems.isAutoClick === undefined &&
              sessionStorage.getItem('isAutoClick') === 'true') ? (
              'Loading'
            ) : (
              <PieGraph
                chartType="FLEET_AVA_PIE_TYPE"
                data={this.props.vehiclesListData[0].data[0].sDt}
                pieChartSelected={this.pieChartSelected}
                listSelection={this.props.listSelection}
                filterItems={this.props.filterItems}
                sideMenuFilter={this.props.sideMenuFilter}
                filterAvailability={this.props.filterAvailability}
                filterReliability={this.props.filterReliability}
                filterUtilization={this.props.filterUtilization}
              />
            )}
          </Col>
        )}
        {checkUserEntitlements('ESS:Fleet-Overview-Reliability') && (
          <Col
            md={4}
            className="piecontainer lightbg"
            id="rel-con"
            data-intro="str-rel">
            <div className="overviewtitle" id="rel">
              Reliability
            </div>
            <div className="overviewsubtitle truncate-text">
              Shows the reliability of the asset(s) over{' '}
              {sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet'
                ? '30 days'
                : 'the last 30 days'}
            </div>
            {this.state.isAvaactive === true ||
            this.state.isStractive === true ||
            (this.props.filterItems.isAutoClick === undefined &&
              sessionStorage.getItem('isAutoClick') === 'true') ? (
              'Loading'
            ) : (
              <PieGraph
                chartType="FLEET_REL_PIE_TYPE"
                data={this.props.vehiclesListData[0].data[1].sDt}
                pieChartSelected={this.pieChartSelected}
                listSelection={this.props.listSelection}
                filterItems={this.props.filterItems}
                sideMenuFilter={this.props.sideMenuFilter}
                filterAvailability={this.props.filterAvailability}
                filterReliability={this.props.filterReliability}
                filterUtilization={this.props.filterUtilization}
              />
            )}
          </Col>
        )}

        {checkUserEntitlements('ESS:Fleet-Overview-Utilization') && (
          <Col
            md={4}
            className="piecontainer lightbg"
            id="str-con"
            data-intro="str-con">
            <div className="overviewtitle" id="str">
              Utilization
            </div>
            <div className="overviewsubtitle truncate-text">
              Shows the utilization index based on temperature, SoC, C-rates and
              cycles over.{' '}
              {sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet'
                ? '30 days'
                : 'the last 30 days'}
            </div>
            <div className="">
              {this.state.isAvaactive === true ||
              this.state.isRelactive === true ||
              (this.props.filterItems.isAutoClick === undefined &&
                sessionStorage.getItem('isAutoClick') === 'true') ? (
                'Loading'
              ) : (
                <PieGraph
                  chartType="FLEET_STR_PIE_TYPE"
                  data={this.props.vehiclesListData[0].data[2].sDt}
                  pieChartSelected={this.pieChartSelected}
                  listSelection={this.props.listSelection}
                  filterItems={this.props.filterItems}
                  sideMenuFilter={this.props.sideMenuFilter}
                  filterAvailability={this.props.filterAvailability}
                  filterReliability={this.props.filterReliability}
                  filterUtilization={this.props.filterUtilization}
                />
              )}
            </div>
            {this.props.filterItems.isAutoClick === undefined
              ? sessionStorage.setItem('isAutoClick', false)
              : null}
          </Col>
        )}
      </Row>
    );
  }
}

export default Overview;
