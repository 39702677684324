import { createSelector } from 'reselect';

// Excel report data
const ExcelReportData = () => state => state.get('excelReportData');

const loadingExcelReportSelector = () =>
  createSelector(ExcelReportData(), state => state.get('loadingExcelReport'));

const excelReportDataSelector = () =>
  createSelector(ExcelReportData(), state => state.get('excelReportData'));

const loadingExcelReportEmailSelector = () =>
  createSelector(ExcelReportData(), state =>
    state.get('loadingExcelReportEmail')
  );

const excelReportEmailSelector = () =>
  createSelector(ExcelReportData(), state => state.get('excelReportEmail'));

export {
  ExcelReportData,
  excelReportDataSelector,
  excelReportEmailSelector,
  loadingExcelReportEmailSelector,
  loadingExcelReportSelector,
};
