import React, { useRef, useState } from 'react';
import { CloseIcon } from '../../assets/images';
import './modal.scss';

function Modal({ onClose, ...props }) {
  const [isDragging, setIsDragging] = useState(false);
  const overlayBoxRef = useRef(null);
  const dragRef = useRef(null);
  const [pos, setPos] = useState({
    top: 0,
    left: 50,
  });
  const [cursorPos, setCursorPos] = useState({
    x: 0,
    y: 0,
  });

  const handleMouseDown = event => {
    setIsDragging(true);
    setCursorPos({
      x: event.clientX,
      y: event.clientY,
    });
  };
  const handleMouseMove = event => {
    if (isDragging) {
      const dragXPosition = event.clientX - cursorPos.x;
      const dragYPosition = event.clientY - cursorPos.y;
      setPos(prevPos => ({
        top: prevPos.top + dragYPosition,
        left: prevPos.left + dragXPosition,
      }));
      setCursorPos({
        x: event.clientX,
        y: event.clientY,
      });
    }
  };
  const handleMouseUp = event => {
    setIsDragging(false);
  };
  return (
    <div
      className="overlay"
      id="overlayBox"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      ref={dragRef}>
      <div
        className="overlay-content"
        onMouseDown={handleMouseDown}
        ref={overlayBoxRef}
        style={{
          top: `${pos.top}px`,
          left: `${pos.left}px`,
        }}>
        <span className="close-button" onClick={onClose}>
          <CloseIcon className="producticon" alt="Product" />
        </span>
        {props.children}
      </div>
    </div>
  );
}

export default Modal;
