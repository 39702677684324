import moment from 'moment';
import toast from 'react-hot-toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import {
  fetchActiveMessagesSuccess,
  fetchAllBroadcastMessagesSuccess,
} from './actions';
import {
  CREATE_BRORADCAST_MESSAGE,
  DELETE_BROADCAST_MESSAGE,
  FETCH_ACTIVE_MESSAGES,
  FETCH_ALL_BROADCAST_MESSAGE,
  UPDATE_BROADCAST_MESSAGE,
} from './constants';

export function* createBroadcastMessageSaga() {
  yield takeLatest(CREATE_BRORADCAST_MESSAGE, createBroadcastSaga);
}
export function* createBroadcastSaga(action) {
  try {
    const { data } = action.payload;
    // Date in utc format so all users browsers will send same date format
    const utcFormat = 'YYYY-MM-DDTHH:mm:ss';
    const StartDate = moment(`${data.startDate} ${data.startTime}`, utcFormat)
      .utc()
      .format(utcFormat);
    const EndDate = moment(`${data.endDate} ${data.endTime}`, utcFormat)
      .utc()
      .format(utcFormat);

    const Message = data.message;
    const isActive = true;
    const response = yield call(api.createMessage, {
      Message,
      StartDate,
      EndDate,
      isActive,
    });
    if (response.dc[0] && response.dc[0].response.sts === true) {
      toast.success(response.dc[0].response.msg);
      yield call(fetchAllBroadcastSaga);
    } else {
      toast.error(response.dc[0].response.msg);
    }
  } catch (error) {
    toast.error('Something went wrong ! Try again later .');
  }
}

export function* updateBroadcastMessageSaga() {
  yield takeLatest(UPDATE_BROADCAST_MESSAGE, updateBroadcastSaga);
}
export function* updateBroadcastSaga(action) {
  try {
    const { message, status } = action.payload.data;
    const utcFormat = 'YYYY-MM-DDTHH:mm:ss';
    const StartDate = moment(message.startDate, utcFormat).format(utcFormat);
    const EndDate = moment(message.endDate, utcFormat).format(utcFormat);

    const Message = message.message;
    const isActive = status;
    const id = message.id;
    const response = yield call(api.createMessage, {
      Message,
      StartDate,
      EndDate,
      isActive,
      id,
    });
    if (response.dc[0] && response.dc[0].response.sts === true) {
      toast.success(response.dc[0].response.msg);
      yield call(fetchAllBroadcastSaga);
    } else {
      toast.error(response.dc[0].response.msg);
    }
  } catch (error) {
    toast.error('Something went wrong ! Try again later .');
  }
}
export function* fetchAllBroadcastMessageSaga() {
  yield takeLatest(FETCH_ALL_BROADCAST_MESSAGE, fetchAllBroadcastSaga);
}

export function* fetchAllBroadcastSaga() {
  try {
    const response = yield call(api.fetchAllMessages);
    yield put(
      fetchAllBroadcastMessagesSuccess(response.dc[0] && response.dc[0].data)
    );
  } catch (error) {
    toast.error('Something went wrong ! Try again later .');
  }
}

export function* deleteBroadcastMessageSaga() {
  yield takeLatest(DELETE_BROADCAST_MESSAGE, deleteBroadcastSaga);
}

export function* deleteBroadcastSaga(action) {
  try {
    const { id } = action.payload;
    const response = yield call(api.deleteMessage, { id });
    if (response.dc[0] && response.dc[0].response.sts === true) {
      toast.success(response.dc[0].response.msg);
      yield call(fetchAllBroadcastSaga);
    } else {
      toast.error(response.dc[0].response.msg);
    }
  } catch (error) {
    toast.error('Something went wrong ! Try again later .');
  }
}

export function* fetchActiveBroadcastMessagesSaga() {
  yield takeLatest(FETCH_ACTIVE_MESSAGES, fetchActiveMessagesSaga);
}

export function* fetchActiveMessagesSaga() {
  try {
    const response = yield call(api.fetchActiveMessages);
    yield put(
      fetchActiveMessagesSuccess(response.dc[0] && response.dc[0].data)
    );
  } catch (error) {
    // toast.error("Something went wrong ! Try again later .");
  }
}
