import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { checkUserEntitlements } from '../../userConfig';
import './styles.scss';

Moment.locale('en');

class BatteryConditionInfo extends React.Component {
  render() {
    return (
      <div className="battery-overview-info">
        <span className={'sub'}>
          Data updated on{' '}
          {Moment(this.props.batteryOverviewData[0].ts).format(
            'HH:mm \xa0DD.MM.YYYY'
          )}
          &nbsp;
          {/* (
          {getTimezone(
            Moment(this.props.batteryOverviewData[0].ts, "YYYY/MM/DD")
          )}) */}
          {this.props.batteryOverviewData[0].tz}
        </span>
        <span>
          (The data shown in this section is not affected by the time period
          selected above)
        </span>
        <Row>
          <Col xs={12} sm={3} md={3} className="noLeftpadding noRightpadding">
            <div className="mediumText info-title">Capacity counter</div>

            <div className="info-label">Capacity charging: </div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].cc !== -1.999 ? (
                <>{this.props.batteryOverviewData[0].cc} Ah</>
              ) : (
                <>
                  {this.props.batteryOverviewData[0].crg} Ah
                  <span className="mockmsg">(mock data)</span>
                </>
              )}
            </div>
            <div className="info-label">Capacity discharging:</div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].cd !== -1.999 ? (
                <>{this.props.batteryOverviewData[0].cd} Ah</>
              ) : (
                <>
                  {this.props.batteryOverviewData[0].dcg} Ah
                  <span className="mockmsg">(mock data)</span>
                </>
              )}
            </div>
            <div className="info-label">Total:</div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].ct !== -1.999 ? (
                <>{this.props.batteryOverviewData[0].ct} Ah</>
              ) : (
                <>
                  {this.props.batteryOverviewData[0].ett} Ah
                  <span className="mockmsg">(mock data)</span>
                </>
              )}
            </div>
          </Col>
          <Col xs={12} sm={3} md={3} className="left-border  noRightpadding">
            <div className="mediumText info-title">Energy counter</div>
            <div className="info-label">Energy charging: </div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].crg} kWh
            </div>
            <div className="info-label">Energy discharging:</div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].dcg} kWh
            </div>
            <div className="info-label">Total:</div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].ett} kWh
            </div>
          </Col>
          <Col xs={12} sm={3} md={3} className="left-border  noRightpadding">
            <div className="mediumText info-title">Battery voltage</div>
            <div className="info-label">Overall:</div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].bvt} V
            </div>
            {sessionStorage.getItem('selectedFleet') !== 'ABB ESS Demo Fleet' &&
              checkUserEntitlements('ESS:Product-Basicinfo-Cellvoltage') && (
                <>
                  <div className="info-label">Average cell voltage: </div>
                  <div className="mediumText info-value">
                    {this.props.batteryOverviewData[0].avg} V
                  </div>
                  <div className="info-label">Minimum cell voltage:</div>
                  <div className="mediumText info-value">
                    {this.props.batteryOverviewData[0].mnv} V{' '}
                    <span>Cell: {this.props.batteryOverviewData[0].mni}</span>
                  </div>
                  <div className="info-label">Maximum cell voltage:</div>
                  <div className="mediumText info-value">
                    {this.props.batteryOverviewData[0].mxv} V{' '}
                    <span>Cell: {this.props.batteryOverviewData[0].mxi}</span>
                  </div>
                </>
              )}
          </Col>
          <Col xs={12} sm={3} md={3} className="left-border">
            <div className="mediumText info-title">State of charge</div>
            <div className="info-label">Actual:</div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].act}%
            </div>
            <div className="info-label">End of last operational day:</div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].eld}%
            </div>
            <div className="info-label">
              End of operational day, Average (last 7 days):
            </div>
            <div className="mediumText info-value">
              {this.props.batteryOverviewData[0].eav}%
            </div>
          </Col>
          {/* <Col xs={12} sm={3} md={3} className="left-border noRightpadding">
            <div className="mediumText info-title">ESS module</div>
            <div className="info-label">Average: </div>
            <div className="mediumText info-value">
              -  {this.props.batteryOverviewData[0].eaui} kAh 
            </div>
            <div className="info-label">Minimum cell voltage:</div>
            <div className="mediumText info-value">
              -  {this.props.batteryOverviewData[0].eaui} kAh
            </div>
            <div className="info-label">Maximum cell voltage:</div>
            <div className="mediumText info-value">
              -{" "}
               {this.props.batteryOverviewData[0].eaui} kAh{" "}
              <span>Cell: {this.props.batteryOverviewData[0].id}</span> 
            </div>
          </Col> */}
        </Row>
      </div>
    );
  }
}

export default BatteryConditionInfo;
