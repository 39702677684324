import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.Dashboard.header',
    defaultMessage: 'Dashboard/ReportView',
  },
  problem: {
    id: 'app.components.datepicker.problem',
    defaultMessage: 'Please choose date time with in 365 days range',
  },
});
