import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';
import {
  fetchAllEventListSucess,
  fetchEventListSucess,
  fetchVEventListSucess,
  getAllEventListFailure,
  getEventListFailure,
  getVEventListFailure,
  saveEventFailure,
  saveEventSucess,
  undoEventFailure,
  undoEventSucess,
} from './actions';
import {
  FETCH_ALL_EVENT_LIST,
  FETCH_EVENT_LIST,
  FETCH_VEVENT_LIST,
  SAVE_EVENT_DATA,
  UNDO_EVENT_DATA,
} from './constants';

//  for get event list

export function* getEventListSaga() {
  yield takeLatest(FETCH_EVENT_LIST, gettingEventListSaga);
}
export function* gettingEventListSaga(action) {
  try {
    const {
      fleetName,
      vehicleName,
      pecUniqueSerialNumber,
      filterStartDate,
      filterEndDate,
      dateType,
      eventType,
      selectedVehicle,
      selectedProduct,
      searchColumn,
      searchColumnValue,
    } = action.payload;
    const response = yield call(
      api.getEventList,
      fleetName,
      vehicleName,
      pecUniqueSerialNumber,
      filterStartDate,
      filterEndDate,
      dateType,
      eventType,
      selectedVehicle,
      selectedProduct,
      searchColumn,
      searchColumnValue
    );

    const eventListResponce = response.dc;
    yield put(fetchEventListSucess(eventListResponce));
  } catch (error) {
    yield put(handleError(error, getEventListFailure));
  }
}

//  for save event
export function* saveEventSaga() {
  yield takeLatest(SAVE_EVENT_DATA, savingEventSaga);
}
export function* savingEventSaga(action) {
  try {
    const { siteName, fleetName, vehicle, eventFormData } = action.payload;
    const response = yield call(
      api.saveEvent,
      siteName,
      fleetName,
      vehicle,
      eventFormData
    );

    const saveEventResponce = response.dc;
    yield put(saveEventSucess(saveEventResponce));
  } catch (error) {
    yield put(handleError(error, saveEventFailure));
  }
}

//  for undo event
export function* undoEventSaga() {
  yield takeLatest(UNDO_EVENT_DATA, undoingEventSaga);
}
export function* undoingEventSaga(action) {
  try {
    const { siteName, fleetName, vehicle, obj } = action.payload;
    const response = yield call(
      api.undoEvent,
      siteName,
      fleetName,
      vehicle,
      obj
    );

    const undoEventResponce = response.dc;
    yield put(undoEventSucess(undoEventResponce));
  } catch (error) {
    yield put(handleError(error, undoEventFailure));
  }
}

//  for get Vevent list

export function* getVEventListSaga() {
  yield takeLatest(FETCH_VEVENT_LIST, gettingVEventListSaga);
}
export function* gettingVEventListSaga(action) {
  try {
    const {
      site,
      fleetName,
      vehicleName,
      filterStartDate,
      filterEndDate,
      dateType,
      eventType,
      selectedVehicle,
      selectedProduct,
      searchColumn,
      searchColumnValue,
    } = action.payload;
    const response = yield call(
      api.getVEventList,
      site,
      fleetName,
      vehicleName,
      filterStartDate,
      filterEndDate,
      dateType,
      eventType,
      selectedVehicle,
      selectedProduct,
      searchColumn,
      searchColumnValue
    );

    const veventListResponce = response.dc;
    yield put(fetchVEventListSucess(veventListResponce));
  } catch (error) {
    yield put(handleError(error, getVEventListFailure));
  }
}

//  for get Allevent list

export function* getAllEventListSaga() {
  yield takeLatest(FETCH_ALL_EVENT_LIST, gettingAllEventListSaga);
}
export function* gettingAllEventListSaga(action) {
  try {
    const {
      fleetName,
      vehicleName,
      pecUniqueSerialNumber,
      packSerialNumber,
      startDateTime,
      endDateTime,
      dateType,
      filterColumnKey,
      filterColumnKeyValue,
      pageSize,
      pageNumber,
      sortType,
      sortTo,
    } = action.payload;
    const response = yield call(
      api.getAllEventList,
      fleetName,
      vehicleName,
      pecUniqueSerialNumber,
      packSerialNumber,
      startDateTime,
      endDateTime,
      dateType,
      filterColumnKey,
      filterColumnKeyValue,
      pageSize,
      pageNumber,
      sortType,
      sortTo
    );

    const AlleventListResponce = response;
    yield put(fetchAllEventListSucess(AlleventListResponce));
  } catch (error) {
    yield put(handleError(error, getAllEventListFailure));
  }
}
