export const FETCH_OVERALL_CONDITION_DATA = 'FETCH_OVERALL_CONDITION_DATA';
export const FETCH_OVERALL_CONDITION_DATA_SUCCESS =
  'FETCH_OVERALL_CONDITION_DATA_SUCCESS';
export const FETCH_OVERALL_CONDITION_DATA_FAILURE =
  'FETCH_OVERALL_CONDITION_DATA_FAILURE';

export const FETCH_VEHICLE_AVAIL_DATA = 'FETCH_VEHICLE_AVAIL_DATA';
export const FETCH_VEHICLE_AVAIL_DATA_SUCCESS =
  'FETCH_VEHICLE_AVAIL_DATA_SUCCESS';
export const FETCH_VEHICLE_AVAIL_DATA_FAILURE =
  'FETCH_VEHICLE_AVAIL_DATA_FAILURE';

export const FETCH_VEHICLE_REL_DATA = 'FETCH_VEHICLE_REL_DATA';
export const FETCH_VEHICLE_REL_DATA_SUCCESS = 'FETCH_VEHICLE_REL_DATA_SUCCESS';
export const FETCH_VEHICLE_REL_DATA_FAILURE = 'FETCH_VEHICLE_REL_DATA_FAILURE';

export const FETCH_VEHICLE_STR_DATA = 'FETCH_VEHICLE_STR_DATA';
export const FETCH_VEHICLE_STR_DATA_SUCCESS = 'FETCH_VEHICLE_STR_DATA_SUCCESS';
export const FETCH_VEHICLE_STR_DATA_FAILURE = 'FETCH_VEHICLE_STR_DATA_FAILURE';

export const FETCH_HISTOGRAM_DATA = 'FETCH_HISTOGRAM_DATA';
export const FETCH_HISTOGRAM_DATA_SUCCESS = 'FETCH_HISTOGRAM_DATA_SUCCESS';
export const FETCH_HISTOGRAM_DATA_FAILURE = 'FETCH_HISTOGRAM_DATA_FAILURE';

export const FETCH_BOXPLOT_DATA = 'FETCH_BOXPLOT_DATA';
export const FETCH_BOXPLOT_DATA_SUCCESS = 'FETCH_BOXPLOT_DATA_SUCCESS';
export const FETCH_BOXPLOT_DATA_FAILURE = 'FETCH_BOXPLOT_DATA_FAILURE';

export const CLEAR_VEHICLE_VIEW_DATA = 'CLEAR_VEHICLE_VIEW_DATA';

export const FETCH_VEHICLE_DETAILS = 'FETCH_VEHICLE_DETAILS';
export const FETCH_VEHICLE_DETAILS_SUCCESS = 'FETCH_VEHICLE_DETAILS_SUCCESS';
export const FETCH_VEHICLE_DETAILS_FAILURE = 'FETCH_VEHICLE_DETAILS_FAILURE';
