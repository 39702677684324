import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  AvailableStatusIcon,
  AvailableUnknownIcon,
  NotAvailableStatusIcon,
  NotReliableAvailableStatusIcon,
  ReliableAvailableStatusIcon,
  ReliableUnknownStatusIcon,
  StressAvailableStatusIcon,
  StressNotAvailableStatusIcon,
  StressUnknownStatusIcon,
  Warning,
} from '../../assets/images';
import Loading from '../../components/Loading';
import { checkUserEntitlements } from '../../userConfig';
import PieGraph from '../PieGraph';
import StockGraph from '../StockGraph';
import Table from '../Table';
import UtilizationThresholdInfo from '../UtilizationThresholdInfo';
import './styles.scss';
Moment.locale('en');

const columns = [
  {
    Header: 'TYPE',
    accessor: 'typ',
    Cell: props => {
      if (props.value === 'Available') {
        return <img src={require('../../assets/images/info.svg').default} />;
      } else if (props.value === 'Not available') {
        return <Warning />;
      } else {
        return <img src={require('../../assets/images/info.svg').default} />;
      }
    },
  },
  {
    Header: 'EVENT NAME',
    accessor: 'nm',
  },
  {
    Header: 'CODE',
    accessor: 'cod',
  },
  {
    Header: 'PRODUCT',
    accessor: 'pdt',
  },
  {
    Header: 'TIME',
    accessor: 'dt',
    Cell: ({ row }) =>
      Moment.utc(row.original.dt).format('HH:mm:ss \xa0DD.MM.YYYY') +
      ' ' +
      row.original.tz,
  },
  {
    Header: 'STATUS',
    accessor: 'sts',
  },
  {
    Header: 'DESCRIPTION',
    accessor: 'dsn',
  },
];

class VehicleStatusTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colChartData: this.props.vehicleRelData[0],
      filteredcolChartData: this.props.vehicleRelData[0],
      isRelactive: false,
      colChartStrData: this.props.vehicleStrData[0],
      filteredcolChartStrData: this.props.vehicleStrData[0],
      isStractive: false,
      loader: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isResizing !== this.props.isResizing ||
      prevProps.showSidebar !== this.props.showSidebar
    ) {
      this.setState({ loader: true });

      setTimeout(() => {
        this.setState({ loader: false });
      }, 1000);
    }
  }

  pieChartSelected = (chartId, flag, status) => {
    let colChartDataCopy;
    let colChartStrDataCopy;
    if (chartId === 'REL' && flag === true) {
      colChartDataCopy = this.state.colChartData.col.filter(
        item => item.name === status
      );
      this.setState(
        {
          filteredcolChartData: {
            ...this.state.filteredcolChartData,
            col: colChartDataCopy,
          },
          isRelactive: flag,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isRelactive: false,
            });
          }, 0);
        }
      );
    } else if (chartId === 'REL' && flag === false) {
      this.setState(
        {
          filteredcolChartData: this.state.colChartData,
          isRelactive: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isRelactive: false,
            });
          }, 0);
        }
      );
    } else if (chartId === 'STR' && flag === true) {
      colChartStrDataCopy = this.state.colChartStrData.col.filter(
        item => item.name === status
      );
      this.setState(
        {
          filteredcolChartStrData: {
            ...this.state.filteredcolChartStrData,
            col: colChartStrDataCopy,
          },
          isStractive: flag,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isStractive: false,
            });
          }, 0);
        }
      );
    } else if (chartId === 'STR' && flag === false) {
      this.setState(
        {
          filteredcolChartStrData: this.state.colChartStrData,
          isStractive: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isStractive: false,
            });
          }, 0);
        }
      );
    }
  };

  render() {
    return (
      <Tabs
        id="prodststabs"
        activeKey={this.props.selectedOverviewTab}
        onSelect={k => this.props.onVehicleViewStatusTabClick(k)}>
        {checkUserEntitlements(
          'ESS:Vehicle-Overview-Availability',
          'Overview-vehicle-view',
          'avail-vehicle-view'
        ) && (
          <Tab
            eventKey="avail-vehicle-view"
            title={
              <span className="tabIcon">
                {this.props.vehicleAvailData[0].sts === 'Available' ? (
                  <AvailableStatusIcon />
                ) : this.props.vehicleAvailData[0].sts === 'Not available' ? (
                  <NotAvailableStatusIcon />
                ) : (
                  <AvailableUnknownIcon />
                )}

                <span className="tabtitle"> Availability</span>
              </span>
            }>
            <div className="availcon">
              <>
                <div className="stsinfo">
                  <span className="txt">
                    {this.props.vehicleAvailData[0].sts === 'Available' ? (
                      <span id="ok"> Normal operation </span>
                    ) : this.props.vehicleAvailData[0].sts ===
                      'Not available' ? (
                      <span id="notok"> Error </span>
                    ) : (
                      <span id="unknown"> Unknown </span>
                    )}
                  </span>
                  <span className="util-info-smalltxt">
                    {this.props.vehicleAvailData[0].sts === 'Available'
                      ? 'ESS on this vehicle have been sending normal operation signals (within last 48 hours)'
                      : this.props.vehicleAvailData[0].sts === 'Not available'
                        ? 'Minimum one ESS on this vehicle has been sending error mode signals (within last 48 hours)'
                        : 'ESS on this vehicle have not been sending status signals (within last 48 hours)'}
                  </span>
                </div>
                <Table
                  columns={columns}
                  data={this.props.vehicleAvailData[0].dat}
                />
              </>
            </div>
          </Tab>
        )}
        {checkUserEntitlements(
          'ESS:Vehicle-Overview-Reliability',
          'Overview-vehicle-view',
          'reli-vehicle-view'
        ) && (
          <Tab
            eventKey="reli-vehicle-view"
            title={
              <span className="tabIcon" data-intro="p3-rel-tab-title">
                {this.props.vehicleRelData.length > 0 ? (
                  this.props.vehicleRelData[0].sts === 'Reliable' ? (
                    <ReliableAvailableStatusIcon />
                  ) : this.props.vehicleRelData[0].sts === 'Not reliable' ? (
                    <NotReliableAvailableStatusIcon />
                  ) : (
                    <ReliableUnknownStatusIcon />
                  )
                ) : (
                  <ReliableUnknownStatusIcon />
                )}
                <span className="tabtitle"> Reliability</span>
              </span>
            }>
            {this.state.loader ? (
              <Loading loadintText="Loading data" />
            ) : this.props.vehicleRelData.length > 0 ? (
              <Row>
                <Col md={12} className="noLeftpadding noRightpadding">
                  <div className="stsinfo">
                    <span className="txt">
                      {this.props.vehicleRelData[0].sts === 'Reliable' ? (
                        <span id="ok"> Reliable </span>
                      ) : this.props.vehicleRelData[0].sts ===
                        'Not reliable' ? (
                        <span id="notok"> Not reliable </span>
                      ) : (
                        <span id="unknown"> Unknown </span>
                      )}
                    </span>
                    <span className="util-info-smalltxt">
                      Accumulated availability status over &nbsp;
                      {sessionStorage.getItem('selectedFleet') ===
                      'ABB ESS Demo Fleet'
                        ? '30 days '
                        : 'the last 30 days '}
                      (Click on the section of stacked bar chart for details)
                    </span>
                    <br />
                    <span className="util-info-txt">
                      {this.props.vehicleRelData[0].sts === 'Reliable'
                        ? 'All ESS on this vehicle are reliable.'
                        : this.props.vehicleRelData[0].sts === 'Not reliable'
                          ? 'Minimum one ESS on this vehicle has been not reliable.'
                          : 'Indicates unknown when the relavent data is not available.'}
                    </span>
                  </div>
                </Col>
                <Col
                  md={4}
                  data-intro="p3_pie_graph"
                  className="noLeftpadding noRightpadding">
                  <PieGraph
                    chartType="VEH_REL_PIE_TYPE"
                    data={this.props.vehicleRelData[0].pie}
                    pieChartSelected={this.pieChartSelected}
                  />
                </Col>
                <Col
                  md={8}
                  data-intro="p3_stock_graph"
                  className="noLeftpadding noRightpadding">
                  {this.state.isRelactive === true ? (
                    <div className="filtering">Filtering...</div>
                  ) : (
                    <StockGraph
                      chartType="VEH_REL_COL_TYPE"
                      data={this.state.filteredcolChartData}
                      redirectToTimetrend={this.props.redirectToTimetrend}
                    />
                  )}
                </Col>
              </Row>
            ) : (
              <Col md={12} className="addMarginTop nodatafound">
                <div> Sorry...</div>
                <div className="addMarginTop">No data found !</div>
              </Col>
            )}
          </Tab>
        )}
        {checkUserEntitlements(
          'ESS:Vehicle-Overview-Utilization',
          'Overview-vehicle-view',
          'util-vehicle-view'
        ) && (
          <Tab
            eventKey="util-vehicle-view"
            title={
              <span className="tabIcon" data-intro="p3-util-tab-title">
                {this.props.vehicleStrData.length > 0 ? (
                  this.props.vehicleStrData[0].sts === 'Normal' ? (
                    <StressAvailableStatusIcon />
                  ) : this.props.vehicleStrData[0].sts === 'Above normal' ? (
                    <StressNotAvailableStatusIcon />
                  ) : (
                    <StressUnknownStatusIcon />
                  )
                ) : (
                  <StressUnknownStatusIcon />
                )}
                <span className="tabtitle"> Utilization</span>
              </span>
            }>
            {this.state.loader ? (
              <Loading loadintText="Loading data" />
            ) : this.props.vehicleStrData.length > 0 ? (
              <Row>
                <Col md={12} className="noLeftpadding noRightpadding">
                  <div className="stsinfo">
                    <span className="txt">
                      {this.props.vehicleStrData[0].sts === 'Normal' ? (
                        <span id="ok"> Normal utilization </span>
                      ) : this.props.vehicleStrData[0].sts ===
                        'Above normal' ? (
                        <span id="notok"> Above normal utilization </span>
                      ) : (
                        <span id="unknown"> Unknown </span>
                      )}
                    </span>
                    <span className="util-info-smalltxt">
                      for &nbsp;
                      {sessionStorage.getItem('selectedFleet') ===
                      'ABB ESS Demo Fleet'
                        ? '30 days '
                        : ' last 30 days '}
                      (Click on the section of stacked bar chart for details)
                      <br />
                    </span>
                    {this.props.vehicleStrData[0].sts === 'Normal' ? (
                      <span className="util-info-txt">
                        {' '}
                        All ESS on this vehicle are in normal utilization.
                      </span>
                    ) : this.props.vehicleStrData[0].sts === 'Above normal' ? (
                      <span className="util-info-txt">
                        {' '}
                        Mininum one ESS on this vehicle is operated above normal
                        utilization.
                      </span>
                    ) : (
                      <span className="util-info-txt">
                        {' '}
                        Utilization indicates unknown when the relavent data is
                        not available.{' '}
                      </span>
                    )}
                  </div>
                </Col>
                <Col
                  md={4}
                  data-intro="p3_pie_graph"
                  className="noLeftpadding noRightpadding">
                  <PieGraph
                    chartType="VEH_STR_PIE_TYPE"
                    data={this.props.vehicleStrData[0].pie}
                    pieChartSelected={this.pieChartSelected}
                  />
                </Col>
                <Col
                  md={8}
                  data-intro="p3_stock_graph"
                  className="noLeftpadding noRightpadding">
                  {this.state.isStractive === true ? (
                    <div className="filtering">Filtering...</div>
                  ) : (
                    <StockGraph
                      chartType="VEH_STR_COL_TYPE"
                      data={this.state.filteredcolChartStrData}
                      redirectToTimetrend={this.props.redirectToTimetrend}
                    />
                  )}
                </Col>
                <div className="threstable">
                  {' '}
                  <UtilizationThresholdInfo
                    stressData={this.props.vehicleStrData[0].ctv}
                  />
                </div>
              </Row>
            ) : (
              <Col md={12} className="addMarginTop nodatafound">
                <div> Sorry...</div>
                <div className="addMarginTop">No data found !</div>
              </Col>
            )}
          </Tab>
        )}
      </Tabs>
    );
  }
}

export default VehicleStatusTabs;
