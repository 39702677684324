import React, { useState } from 'react';
import { CloseIcon } from '../../assets/images';
import './styles.scss';

function BroadcastBanner({ activeMessages, handleShow }) {
  const [showBanner, setshowBanner] = useState(true);

  return (
    showBanner && (
      <div className="broadcast_header">
        <div className="inner-header">
          <div className="skewdiv" style={{ background: '#004c97' }}></div>
          <div
            className="skewdiv"
            style={{ background: '#0c74da', marginLeft: '2px' }}></div>

          <span style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={require('../../assets/images/info.svg').default}
              style={{ width: '20px', marginLeft: '20px' }}
            />
            <marquee
              style={{ marginLeft: '10px', width: '40vw' }}
              direction="left"
              onMouseOver={e => {
                e.target.stop();
              }}
              onMouseOut={e => {
                e.target.start();
              }}>
              {activeMessages[0].message}
            </marquee>
          </span>
          <span style={{ display: 'flex' }}>
            <button
              className="btn btn-link "
              onClick={() => handleShow()}
              style={{ fontSize: '12px', display: 'bold' }}>
              Details
            </button>
            <button className="btn" onClick={() => setshowBanner(false)}>
              <CloseIcon />
            </button>
          </span>
        </div>
      </div>
    )
  );
}

export default BroadcastBanner;
