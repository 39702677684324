import { fromJS } from 'immutable';
import {
  CLEAR_SAVE_FLEET_RESPONCE,
  CLEAR_SAVE_SITE_RESPONCE,
  GET_FLEETDATA,
  GET_FLEETDATA_FAILURE,
  GET_FLEETDATA_SUCCESS,
  GET_PRDTINFO_SETUP_VALUES,
  GET_PRDTINFO_SETUP_VALUES_FAILURE,
  GET_PRDTINFO_SETUP_VALUES_SUCCESS,
  GET_PRDT_SETUP_VALUES,
  GET_PRDT_SETUP_VALUES_FAILURE,
  GET_PRDT_SETUP_VALUES_SUCCESS,
  GET_SETUP_FLEET_LIST,
  GET_SETUP_FLEET_LIST_FAILURE,
  GET_SETUP_FLEET_LIST_SUCCESS,
  GET_SETUP_SIDEMENULIST,
  GET_SETUP_SIDEMENULIST_FAILURE,
  GET_SETUP_SIDEMENULIST_SUCCESS,
  GET_SETUP_SITE_LIST,
  GET_SETUP_SITE_LIST_FAILURE,
  GET_SETUP_SITE_LIST_SUCCESS,
  GET_USED_FLEET_NAMELIST,
  GET_USED_FLEET_NAMELIST_FAILURE,
  GET_USED_FLEET_NAMELIST_SUCCESS,
  GET_VALIDATE_FLEETDATAPATH,
  GET_VALIDATE_FLEETDATAPATH_FAILURE,
  GET_VALIDATE_FLEETDATAPATH_SUCCESS,
  GET_VEHICLESETUP_VALUES,
  GET_VEHICLESETUP_VALUES_FAILURE,
  GET_VEHICLESETUP_VALUES_SUCCESS,
  SAVE_FLEET_DATA,
  SAVE_FLEET_DATA_FAILURE,
  SAVE_FLEET_DATA_SUCCESS,
  SAVE_PRDTINFO_DATA,
  SAVE_PRDTINFO_DATA_FAILURE,
  SAVE_PRDTINFO_DATA_SUCCESS,
  SAVE_PRDT_DATA,
  SAVE_PRDT_DATA_FAILURE,
  SAVE_PRDT_DATA_SUCCESS,
  SAVE_SITE_DATA,
  SAVE_SITE_DATA_FAILURE,
  SAVE_SITE_DATA_SUCCESS,
  SAVE_VEHICLE_DATA,
  SAVE_VEHICLE_DATA_FAILURE,
  SAVE_VEHICLE_DATA_SUCCESS,
} from './constants';

const initialState = fromJS({
  loadingSaveSite: false,
  saveSiteResponce: null,
  loadingSaveFleet: false,
  saveFleetResponce: null,
  loadingproductSetup: false,
  saveProductSetupResponce: null,
  loadingproductInfoSetup: false,
  saveProductInfoSetupResponce: null,
  loadingSetupSiteList: false,
  setupSiteList: null,
  loadingSetupFleetList: false,
  setupFleetList: null,
  loadingPrdtSetupValues: false,
  prdtSetupValues: null,
  loadingPrdtInfoSetupValues: false,
  prdtInfoSetupValues: null,
  loadingVehicleSetup: false,
  saveVehicleSetupResponce: null,
  loadingVehicleSetupValues: false,
  vehicleSetupValues: null,
  loadingSetupSidemenuList: false,
  setupSidemenuList: null,
  loadingUsedFleetNameList: false,
  usedFleetNameList: null,
  error: null,
  loadingfleetdatapathvalidation: false,
  saveFleetDataPathResponse: null,
  loadingfleetdata: false,
  saveFleetDataResponse: null,
});

export function reducerFleetSetup(state = initialState, action) {
  switch (action.type) {
    case SAVE_SITE_DATA:
      return state.set('loadingSaveSite', true).set('error', null);
    case SAVE_SITE_DATA_SUCCESS:
      return state
        .set('loadingSaveSite', false)
        .set('saveSiteResponce', action.saveSiteResponce);
    case SAVE_SITE_DATA_FAILURE:
      return state
        .set('loadingSaveSite', false)
        .set('saveSiteResponce', null)
        .set('error', action.error);

    // fleet

    case SAVE_FLEET_DATA:
      return state.set('loadingSaveFleet', true).set('error', null);
    case SAVE_FLEET_DATA_SUCCESS:
      return state
        .set('loadingSaveFleet', false)
        .set('saveFleetResponce', action.saveFleetResponce);
    case SAVE_FLEET_DATA_FAILURE:
      return state
        .set('loadingSaveFleet', false)
        .set('saveFleetResponce', null)
        .set('error', action.error);

    case CLEAR_SAVE_FLEET_RESPONCE:
      return state
        .set('saveSiteResponce', null)
        .set('saveFleetResponce', null)
        .set('setupFleetList', null)
        .set('saveProductSetupResponce', null)
        .set('saveProductInfoSetupResponce', null)
        .set('prdtSetupValues', null)
        .set('prdtInfoSetupValues', null)
        .set('loadingSetupFleetList', true);

    case CLEAR_SAVE_SITE_RESPONCE:
      return state.set('saveSiteResponce', null);

    // get site list
    case GET_SETUP_SITE_LIST:
      return state.set('loadingSetupSiteList', true).set('error', null);
    case GET_SETUP_SITE_LIST_SUCCESS:
      return state
        .set('loadingSetupSiteList', false)
        .set('setupSiteList', action.setupSiteList);
    case GET_SETUP_SITE_LIST_FAILURE:
      return state
        .set('loadingSetupSiteList', false)
        .set('setupSiteList', null)
        .set('error', action.error);

    // get fleet list
    case GET_SETUP_FLEET_LIST:
      return state.set('loadingSetupFleetList', true).set('error', null);
    case GET_SETUP_FLEET_LIST_SUCCESS:
      return state
        .set('loadingSetupFleetList', false)
        .set('setupFleetList', action.setupFleetList);
    case GET_SETUP_FLEET_LIST_FAILURE:
      return state
        .set('loadingSetupFleetList', false)
        .set('setupFleetList', null)
        .set('error', action.error);

    // Vehicle setup
    case SAVE_VEHICLE_DATA:
      return state.set('loadingVehicleSetup', true).set('error', null);
    case SAVE_VEHICLE_DATA_SUCCESS:
      return state
        .set('loadingVehicleSetup', false)
        .set('saveVehicleSetupResponce', action.saveVehicleSetupResponce);
    case SAVE_VEHICLE_DATA_FAILURE:
      return state
        .set('loadingVehicleSetup', false)
        .set('saveVehicleSetupResponce', null)
        .set('error', action.error);

    // Get Vehicle setup
    case GET_VEHICLESETUP_VALUES:
      return state.set('loadingVehicleSetupValues', true).set('error', null);
    case GET_VEHICLESETUP_VALUES_SUCCESS:
      return state
        .set('loadingVehicleSetupValues', false)
        .set('vehicleSetupValues', action.getVehicleSetupResponce);
    case GET_VEHICLESETUP_VALUES_FAILURE:
      return state
        .set('loadingVehicleSetupValues', false)
        .set('vehicleSetupValues', null)
        .set('error', action.error);

    // productSetup

    case SAVE_PRDT_DATA:
      return state.set('loadingproductSetup', true).set('error', null);
    case SAVE_PRDT_DATA_SUCCESS:
      return state
        .set('loadingproductSetup', false)
        .set('saveProductSetupResponce', action.saveProductSetupResponce);
    case SAVE_PRDT_DATA_FAILURE:
      return state
        .set('loadingproductSetup', false)
        .set('saveProductSetupResponce', null)
        .set('error', action.error);

    case SAVE_PRDTINFO_DATA:
      return state.set('loadingproductInfoSetup', true).set('error', null);
    case SAVE_PRDTINFO_DATA_SUCCESS:
      return state
        .set('loadingproductInfoSetup', false)
        .set(
          'saveProductInfoSetupResponce',
          action.saveProductInfoSetupResponce
        );
    case SAVE_PRDTINFO_DATA_FAILURE:
      return state
        .set('loadingproductInfoSetup', false)
        .set('saveProductInfoSetupResponce', null)
        .set('error', action.error);

    // get ProdSetupValues
    case GET_PRDT_SETUP_VALUES:
      return state.set('loadingPrdtSetupValues', true).set('error', null);
    case GET_PRDT_SETUP_VALUES_SUCCESS:
      return state
        .set('loadingPrdtSetupValues', false)
        .set('prdtSetupValues', action.prdtSetupValues);
    case GET_PRDT_SETUP_VALUES_FAILURE:
      return state
        .set('loadingPrdtSetupValues', false)
        .set('prdtSetupValues', null)
        .set('error', action.error);

    case GET_PRDTINFO_SETUP_VALUES:
      return state.set('loadingPrdtInfoSetupValues', true).set('error', null);
    case GET_PRDTINFO_SETUP_VALUES_SUCCESS:
      return state
        .set('loadingPrdtInfoSetupValues', false)
        .set('prdtInfoSetupValues', action.prdtInfoSetupValues);
    case GET_PRDTINFO_SETUP_VALUES_FAILURE:
      return state
        .set('loadingPrdtInfoSetupValues', false)
        .set('prdtInfoSetupValues', null)
        .set('error', action.error);

    // setup get sidemenu list
    case GET_SETUP_SIDEMENULIST:
      return state.set('loadingSetupSidemenuList', true).set('error', null);
    case GET_SETUP_SIDEMENULIST_SUCCESS:
      return state
        .set('loadingSetupSidemenuList', false)
        .set('setupSidemenuList', action.setupMenuList);
    case GET_SETUP_SIDEMENULIST_FAILURE:
      return state
        .set('loadingSetupSidemenuList', false)
        .set('setupSidemenuList', null)
        .set('error', action.error);

    // get used fleet name list
    case GET_USED_FLEET_NAMELIST:
      return state.set('loadingUsedFleetNameList', true).set('error', null);
    case GET_USED_FLEET_NAMELIST_SUCCESS:
      return state
        .set('loadingUsedFleetNameList', false)
        .set('usedFleetNameList', action.usedFleetNameList);
    case GET_USED_FLEET_NAMELIST_FAILURE:
      return state
        .set('loadingUsedFleetNameList', false)
        .set('usedFleetNameList', null)
        .set('error', action.error);

    // Fleet data path validation
    case GET_VALIDATE_FLEETDATAPATH:
      return state
        .set('loadingfleetdatapathvalidation', true)
        .set('error', null);
    case GET_VALIDATE_FLEETDATAPATH_SUCCESS:
      return state
        .set('loadingfleetdatapathvalidation', false)
        .set('saveFleetDataPathResponse', action.saveFleetDataPathResponce);
    case GET_VALIDATE_FLEETDATAPATH_FAILURE:
      return state
        .set('loadingfleetdatapathvalidation', false)
        .set('saveFleetDataPathResponse', null)
        .set('error', action.error);

    // Load Fleet data - Connectivity key path
    case GET_FLEETDATA:
      return state.set('loadingfleetdata', true).set('error', null);
    case GET_FLEETDATA_SUCCESS:
      return state
        .set('loadingfleetdata', false)
        .set('saveFleetDataResponse', action.saveFleetDataResponce);
    case GET_FLEETDATA_FAILURE:
      return state
        .set('loadingfleetdata', false)
        .set('saveFleetDataResponse', null)
        .set('error', action.error);

    default:
      return state;
  }
}
