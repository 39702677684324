import { fromJS } from 'immutable';
import { SET_IS_RESIZING, SET_SHOW_SIDEBAR, SET_SIZE } from './constants';

const initialState = fromJS({
  size: 340,
  isResizing: false,
  showSidebar: true,
});

export function SidebarDraggableReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIZE:
      return state.set('size', action.value);
    case SET_IS_RESIZING:
      return state.set('isResizing', action.status);
    case SET_SHOW_SIDEBAR:
      return state.set('showSidebar', action.status);
    default:
      return state;
  }
}
