import { createSelector } from 'reselect';

// ESS change starts here
const vehicleViewData = () => state => state.get('vehicleViewData');

const loadingOverallConditionSelector = () =>
  createSelector(vehicleViewData(), state =>
    state.get('loadingOverallCondition')
  );

const overallConditionDataSelector = () =>
  createSelector(vehicleViewData(), state => state.get('overallConditionData'));

const loadingHistogramSelector = () =>
  createSelector(vehicleViewData(), state => state.get('loadingHistogram'));

const loadingBoxPlotSelector = () =>
  createSelector(vehicleViewData(), state => state.get('loadingBoxPlot'));

const histogramDataSelector = () =>
  createSelector(vehicleViewData(), state => state.get('histogramData'));

const boxPlotDataSelector = () =>
  createSelector(vehicleViewData(), state => state.get('boxPlotData'));

const loadingVehicleAvailSelector = () =>
  createSelector(vehicleViewData(), state => state.get('loadingVehicleAvail'));

const vehicleAvailDataSelector = () =>
  createSelector(vehicleViewData(), state => state.get('vehicleAvailData'));

const loadingVehicleRelSelector = () =>
  createSelector(vehicleViewData(), state => state.get('loadingVehicleRel'));

const vehicleRelDataSelector = () =>
  createSelector(vehicleViewData(), state => state.get('vehicleRelData'));

const loadingVehicleStrSelector = () =>
  createSelector(vehicleViewData(), state => state.get('loadingVehicleStr'));

const vehicleStrDataSelector = () =>
  createSelector(vehicleViewData(), state => state.get('vehicleStrData'));

const loadingVehicleDetailsSelector = () =>
  createSelector(vehicleViewData(), state =>
    state.get('loadingVehicleDetails')
  );

const vehicleDetailsSelector = () =>
  createSelector(vehicleViewData(), state => state.get('vehicleDetails'));

export {
  boxPlotDataSelector,
  histogramDataSelector,
  loadingBoxPlotSelector,
  loadingHistogramSelector,
  loadingOverallConditionSelector,
  loadingVehicleAvailSelector,
  loadingVehicleDetailsSelector,
  loadingVehicleRelSelector,
  loadingVehicleStrSelector,
  overallConditionDataSelector,
  vehicleAvailDataSelector,
  vehicleDetailsSelector,
  vehicleRelDataSelector,
  vehicleStrDataSelector,
};
