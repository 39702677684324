export const envConstant =
  process.env.REACT_APP_ESS_API === 'development'
    ? {
        stsAuthority:
          'https://stage.api.accessmanagement.motion.abb.com/polaris/oidc/',
        clientId: 'KsC3nTym0OyLdm3HfFKxhCEvJTUa',
        clientRoot: window.location.origin,
        clientScope: 'openid profile email phone',
      }
    : process.env.REACT_APP_ESS_API === 'production'
      ? {
          stsAuthority:
            'https://api.accessmanagement.motion.abb.com/polaris/oidc/',
          clientId: 'qyMtePfPb2W4iKffDUbYrmZgh4ca',
          clientRoot: window.location.origin,
          clientScope: 'openid profile email phone',
        }
      : process.env.REACT_APP_ESS_API === 'stage'
        ? {
            stsAuthority:
              'https://api.accessmanagement.motion.abb.com/polaris/oidc/',
            clientId: 'qyMtePfPb2W4iKffDUbYrmZgh4ca',
            clientRoot: window.location.origin,
            clientScope: 'openid profile email phone',
          }
        : process.env.REACT_APP_ESS_API === 'qa'
          ? {
              stsAuthority:
                'https://stage.api.accessmanagement.motion.abb.com/polaris/oidc/',
              clientId: 'KsC3nTym0OyLdm3HfFKxhCEvJTUa',
              clientRoot: window.location.origin,
              clientScope: 'openid profile email phone',
            }
          : {
              stsAuthority:
                'https://stage.api.accessmanagement.motion.abb.com/polaris/oidc/',
              clientId: 'KsC3nTym0OyLdm3HfFKxhCEvJTUa',
              clientRoot: window.location.origin,
              clientScope: 'openid profile email phone',
            };
