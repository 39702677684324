import React from 'react';

require('./styles.scss');

function LoadingIndicator(props) {
  return (
    <div className="sk-circle">
      <div className="sk-child"></div>
      <div className="loadingtext">{props.loadintText}</div>
    </div>
  );
}

export default LoadingIndicator;
