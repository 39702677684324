import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { AbbPlusInCircle, FleetIcon } from '../../assets/images';
import './styles.scss';

class SideMenuFleetSetup extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  handleInputChange = (input, e) => {
    if (input === '') {
      this.props.handleSearchClear();
    }
  };

  render() {
    return (
      <div className="fleetmgmtsidemenu">
        {/* <div className="sitelistcon">
          <DropdownList
            data={
              this.props.setupSiteList !== null
                ? this.props.setupSiteList
                : ['Loading']
            }
            //defaultValue={"Vehicles"}
            // value={this.props.selectedSearchKey}
            // disabled={["Vehicle products", "Module"]}
            className="sidemenufilterdropdown disabled"
            //name="Vehicles"
            // onChange={(e) => {
            //   this.props.handleFilterDropdownChange(e);
            // }}
          />
        </div> */}
        <div
          // className={"addfleet"}
          className={
            this.props.addSiteActive === true ||
            this.props.selectedSite === '' ||
            this.props.selectedSite === null
              ? 'addfleet addfleetactive'
              : 'addfleet'
          }
          onClick={() => {
            this.props.handleSiteClick(null);
          }}>
          <AbbPlusInCircle alt="Add" className="addFleetplus" />
          Add site
        </div>
        <div className="accordians-fleetsetup sidemenusitelist-scroll">
          <Accordion allowZeroExpanded>
            {this.props.loadingSetupSidemenuList !== true &&
              this.props.setupSidemenuList !== null &&
              this.props.setupSidemenuList !== undefined &&
              this.props.setupSidemenuList[0].sites.map((item, index) => (
                <AccordionItem uuid={index} key={index}>
                  <AccordionItemHeading
                    onClick={() => {
                      this.props.handleSiteClick(item);
                    }}
                    className={
                      this.props.selectedSite === item.siteName
                        ? 'active-site'
                        : ''
                    }>
                    <AccordionItemButton>
                      {item.displaySiteName}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Row>
                      <Col className=" noRightpadding">
                        <div
                          className={
                            this.props.selectedFleet === 'New fleet'
                              ? 'FleetListcontainer  fleetlistactive'
                              : 'FleetListcontainer'
                          }
                          onClick={() => {
                            this.props.handleProdFleetClick('New fleet');
                          }}>
                          <AbbPlusInCircle alt="Add" className="addFleetplus" />
                          Add fleet
                        </div>
                        <div className="sidemenulist-scroll" id="fSetuplist">
                          {this.props.loadingSetupSidemenuList !== true &&
                            this.props.setupSidemenuList !== null &&
                            this.props.setupSidemenuList !== undefined &&
                            item.fleets.map((item, index) => (
                              <div key={index}>
                                <Row
                                  className={
                                    this.props.selectedFleet === item.fleetName
                                      ? 'FleetListcontainer  fleetlistactive'
                                      : 'FleetListcontainer'
                                  }>
                                  <Col
                                    md={12}
                                    className="noLeftpadding noRightpadding "
                                    onClick={() => {
                                      this.props.handleProdFleetClick(item);
                                    }}>
                                    <span className="FleetListicon">
                                      <FleetIcon />
                                    </span>
                                    {item.displayFleetName}
                                    {item.isDraft === true && ' (draft)'}
                                  </Col>
                                </Row>
                              </div>
                            ))}
                        </div>
                      </Col>
                    </Row>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
          </Accordion>
        </div>
        {/* {this.props.loadingSetupSiteList !== true ? (
          <div className="accordians-fleetsetup sidemenusitelist-scroll">
           
            <Accordion allowZeroExpanded>
              {this.props.setupSiteList &&
                this.props.setupSiteList.map((item, index) => (
                  <AccordionItem uuid={index}>
                    <AccordionItemHeading
                      onClick={() => {
                        this.props.handleSiteClick(item.siteName, item);
                      }}
                      className={
                        this.props.selectedSite === item.siteName
                          ? "active-site"
                          : ""
                      }
                    >
                      <AccordionItemButton>{item.siteName}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row>
                        <Col className=" noRightpadding">
                          <div
                            className={
                              this.props.addFleetActive === true
                                ? "addfleet addfleetactive"
                                : "addfleet"
                            }
                            onClick={() => {
                              // this.props.handleFleetClick(null, null);
                            }}
                          >
                            <img
                              src={require("../../assets/images/abb_plus-in-circle_24.svg")}
                              alt="Add"
                            />
                            Add fleet
                          </div>
                          <div className="sidemenulist-scroll">
                            {" "}
                            {this.props.loadingSetupFleetList === true ? (
                              "Loading"
                            ) : this.props.setupFleetList !== null &&
                              this.props.setupFleetList.length > 0 ? (
                              this.props.setupFleetList.map((item, index) => (
                                <div>
                                  <Row
                                    className={
                                      this.props.selectedFleet ===
                                      item.fleetName
                                        ? "FleetListcontainer  fleetlistactive"
                                        : "FleetListcontainer"
                                    }
                                  >
                                    <Col
                                      md={12}
                                      className="noLeftpadding noRightpadding "
                                      onClick={() => {
                                        // this.props.handleFleetClick(
                                        //   item.FleetName,
                                        //   item
                                        // );
                                      }}
                                    >
                                      <span className="FleetListicon">
                                        <img
                                          src={require("../../assets/images/fleet16.svg")}
                                        />
                                      </span>
                                      {item.fleetName}
                                    </Col>
                                  </Row>
                                </div>
                              ))
                            ) : (
                              <div class="col-md-12">
                                <div> Sorry...</div>
                                <div>No data found !</div>
                              </div>
                            )}{" "}
                          </div>
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          </div>
        ) : (
          "Loading..."
        )} */}
      </div>
    );
  }
}

export default withRouter(SideMenuFleetSetup);
