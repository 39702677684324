import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';

import toast from 'react-hot-toast';
import {
  setEmergencyStatusFailure,
  setEmergencyStatusSuccess,
} from './actions';
import {
  FETCH_EMERGENCY_STATUS,
  SET_EMERGENCY_KEY,
  SET_EMERGENCY_STATUS,
} from './constants';
export function* getEmergencyStatusSaga() {
  yield takeLatest(FETCH_EMERGENCY_STATUS, gettingEmergencyStatusSaga);
}
export function* gettingEmergencyStatusSaga() {
  try {
    const response = yield call(api.fetchEmergencyStatus);
    yield put(setEmergencyStatusSuccess(response));
  } catch (error) {
    yield put(handleError(error, setEmergencyStatusFailure));
  }
}

export function* setEmergencyStatusSaga() {
  yield takeLatest(SET_EMERGENCY_STATUS, settingEmergencyStatusSaga);
}
export function* settingEmergencyStatusSaga(action) {
  try {
    const { message, secret, enabled } = action.payload;
    const response = yield call(api.setEmergencyStatus, {
      message,
      secret,
      enabled,
    });

    if (response.dc.sts) {
      toast.success(response.dc.msg);
    } else {
      toast.error(response.dc.msg);
    }
    yield put(setEmergencyStatusSuccess(response.dc.data));
  } catch (error) {
    toast.error('Error when enabling Safe Mode !');
    yield put(handleError(error, setEmergencyStatusFailure));
  }
}

export function* setEmergencyKeySaga() {
  yield takeLatest(SET_EMERGENCY_KEY, settingEmergencyKeySaga);
}
export function* settingEmergencyKeySaga(action) {
  try {
    const { oldSecret, newSecret } = action.payload;
    const currentSecret = oldSecret;
    const response = yield call(api.setEmergencyKey, {
      currentSecret,
      newSecret,
    });
    if (response.dc.sts) {
      toast.success(response.dc.msg);
    } else {
      toast.error(response.dc.msg);
    }

    yield put(setEmergencyStatusSuccess(response));
  } catch (error) {
    toast.error('Error when changing SafeMode Secret !');
    yield put(handleError(error, setEmergencyStatusFailure));
  }
}
