import React, { Component } from 'react';
import { connect } from 'react-redux';
import SplitPane from 'react-split-pane';
import { createStructuredSelector } from 'reselect';
import { ExpandLeftIcon, ExpandRightIcon } from '../../assets/images';
import './Draggable.scss';
import { setIsResizing, setShowSidebar, setSize } from './actions';
import { getIsResizing, getShowSidebar, getSize } from './selectors';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleSidebar = e => {
    const { showSidebar } = this.props;
    let size = 100;
    if (!showSidebar) {
      size = 340;
    }
    this.props.setSize(size);
    this.props.setShowSidebar(!showSidebar);
  };

  handleSizeHandler = size => {
    if (size <= 200) {
      this.props.setShowSidebar(false);
    } else if (size > 200) {
      this.props.setShowSidebar(true);
    }
    this.props.setSize(size);
  };

  onDragStarted = () => {
    this.props.setIsResizing(true);
  };

  onDragFinished = () => {
    this.props.setIsResizing(false);
  };

  render() {
    const { left_side_content, right_side_content } = this.props;
    return (
      <>
        <span className="btnClk" onClick={this.toggleSidebar}>
          {this.props.showSidebar ? <ExpandLeftIcon /> : <ExpandRightIcon />}
        </span>

        <div className="sp">
          <SplitPane
            split="vertical"
            minSize={100}
            maxSize={340}
            defaultSize={this.props.showSidebar ? 340 : 100}
            size={this.props.showSidebar ? 340 : 100}
            onChange={size => this.handleSizeHandler(size)}
            onDragStarted={this.onDragStarted}
            onDragFinished={this.onDragFinished}
            className="vert-pane"
            style={{
              display: 'inline-flex',
              height: '100%',
              position: 'relative',
              width: '100%',
            }}>
            <div className="simulationDiv" style={{ width: this.props.size }}>
              {left_side_content}
            </div>
            <div className="statisticsDiv">
              {right_side_content}{' '}
              {/* <footer className="footer mt-auto py-3">
                <VersionInfo />
              </footer> */}
            </div>
          </SplitPane>
        </div>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  showSidebar: getShowSidebar(),
  size: getSize(),
  isResizing: getIsResizing(),
});

const mapDispatchToProps = {
  setShowSidebar,
  setSize,
  setIsResizing,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Sidebar));
