/**
 * https://github.com/HiDeoo/intro.js-react/blob/main/src/helpers/defaultProps.js
 */

/**
 * Intro.js options default proptypes.
 * @type {Object}
 */
export const options = {
  hidePrev: true,
  hideNext: true,
};
