import { envConstant } from '../envConstant';
export const authConfig = {
  authority: envConstant.stsAuthority,
  client_id: envConstant.clientId,
  silent_redirect_uri: `${envConstant.clientRoot}/silent-refresh.html`,
  redirect_uri: `${envConstant.clientRoot}/login_redirect.html`,
  post_logout_redirect_uri: `${envConstant.clientRoot}/logout.html`,
  response_type: 'code',
  scope: envConstant.clientScope,
};
