import {
  FETCH_EMAILALERT_DATA,
  FETCH_EMAILALERT_DATA_FAILURE,
  FETCH_EMAILALERT_DATA_SUCCESS,
  SAVE_EMAILALERT_DATA,
  SAVE_EMAILALERT_DATA_FAILURE,
  SAVE_EMAILALERT_DATA_SUCCESS,
} from './constants';

// Email alert settings
export const fetchEmailalertData = fleetName => ({
  type: FETCH_EMAILALERT_DATA,
  payload: {
    fleetName,
  },
});

export const fetchEmailalertDataSucess = emailalertData => ({
  type: FETCH_EMAILALERT_DATA_SUCCESS,
  emailalertData,
});

export const fetchEmailalertDataFailure = error => ({
  type: FETCH_EMAILALERT_DATA_FAILURE,
  error,
});

//  for Email alert settings

export const saveEmailalert = (
  essName,
  additionalInfo,
  alertType,
  alertFrequency,
  alertValidity,
  status,
  un,
  actions
) => ({
  type: SAVE_EMAILALERT_DATA,
  payload: {
    essName,
    additionalInfo,
    alertType,
    alertFrequency,
    alertValidity,
    status,
    un,
    actions,
  },
});

export const saveEmailalertSucess = saveEmailalertResponce => ({
  type: SAVE_EMAILALERT_DATA_SUCCESS,
  saveEmailalertResponce,
});

export const saveEmailalertFailure = error => ({
  type: SAVE_EMAILALERT_DATA_FAILURE,
  error,
});
