import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SingleGraph from '../SingleGraph';
import './styles.scss';

class ContentWithChart extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="budgetchartcon">
          <Row>
            <Col md={12} className="noLeftpadding noRightpadding">
              {this.props.ActiveRadio === 'Dbudget' && (
                <SingleGraph
                  chartType="DESIGNED_TYPE"
                  data={this.props.usgHistogramData}
                  activeTab={this.props.activeTab}
                  expandSide={this.props.expandSide}
                  sideWidth={this.props.sideWidth}
                />
              )}
              {this.props.ActiveRadio === 'Nbudget' && (
                <SingleGraph
                  chartType="NORMALIZED_TYPE"
                  data={this.props.usgHistogramData}
                  activeTab={this.props.activeTab}
                  expandSide={this.props.expandSide}
                  sideWidth={this.props.sideWidth}
                />
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default ContentWithChart;
