import React, { useState, useRef, useEffect } from 'react';
import './style.scss';
import { ArrowDownLine } from '../../assets/images';

const CustomdropdownList = ({ option, defaultValue, value, onChange }) => {
  const [isOpen, setIsopen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const dropdownRef = useRef(null);
  useEffect(() => {
    if (value !== undefined) {
      setSelectedOption(value);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsopen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleToggle = () => {
    setIsopen(!isOpen);
  };
  const handleSelect = option => {
    const url = new URL(window.location.href);
    if (
      (url.searchParams.get('dfdt') || url.searchParams.get('efdt')) ===
        option &&
      option !== 'Custom range'
    ) {
      return false;
    }
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };
  return (
    <div className="dropdowncustom" ref={dropdownRef} onClick={handleToggle}>
      <span className="dropdowncustom-text">
        {selectedOption} <ArrowDownLine />
      </span>
      {isOpen && (
        <ul className="dropdowncustom-menu">
          {option.map((option, i) => (
            <li
              className={`dropdowncustom-item ${selectedOption === option ? 'selected' : ''}`}
              key={i}
              onClick={() => {
                handleSelect(option);
                setIsopen(!isOpen);
              }}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomdropdownList;
