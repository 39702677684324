export const extractSeriesData = payload => {
  const extractedData = [];
  payload &&
    payload.info.forEach(item => {
      const data = [];
      item.bs.forEach(subitem => {
        data.push(
          subitem.data.map(val => [
            {
              x: parseInt(val[0]),
              y: parseInt(val[1]),
              value: val[2] === null ? '' : parseFloat(val[2]),
              name: val[3],
              hours: val[4] === null ? '' : parseFloat(val[4]),
              overuse: val[5],
              stringData: val[6],
            },
          ])
        );
      });
      const flattenData = data.flatMap(item => item.flat(1));
      extractedData.push({
        name: item.vn,
        borderWidth: 0,
        data: flattenData,
        dataLabels: { enabled: false },
      });
    });
  return extractedData;
};

// export const extractSeriesData = (payload) => {
//   const seriesData = [];
//   const info = payload && payload.info;
//   for (let i = 0; i < info.length; i++) {
//     const vehicle = info[i];
//     const name = vehicle.vn;
//     const data = [];
//     const bs = vehicle.bs;
//     for (let j = 0; j < bs.length; j++) {
//       const sn = bs[j].sn;
//       const snIndex = vehicle.bl.indexOf(sn);
//       const snData = bs[j].data;
//       for (let k = 0; k < snData.length; k++) {
//         const point = snData[k];
//         const x = k;
//         const y = snIndex + i * 2;
//         const z = point[2] === null ? replace(null, "null") : parseFloat(point[2]);
//         data.push([x, y, z]);
//       }
//     }
//     seriesData.push({
//       name,
//       borderWidth: 0,
//       data,
//       dataLabels: {
//         enabled: false,
//       },
//     });
//   }
//   return seriesData;
// };

export const extractData = payload => {
  const vehicles = payload && payload.info;
  const STORE_CATEGORIES =
    vehicles.length > 0 &&
    vehicles.map(vehicle => {
      const name = vehicle.vn;
      const categories = vehicle.bl;
      return { name, categories };
    });
  return STORE_CATEGORIES;
};

export const findMinHeights = data => {
  if (!data) return;
  let max_y = Number.NEGATIVE_INFINITY;
  for (const info of data.info) {
    for (const bs of info.bs) {
      for (const arr of bs.data) {
        max_y = Math.max(max_y, parseFloat(arr[1]));
      }
    }
  }
  return max_y * 35;
};

export const formatNumber = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
