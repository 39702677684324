import 'bootstrap-daterangepicker/daterangepicker.css';
import Moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DebounceInput } from 'react-debounce-input';
import { withRouter } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import {
  CaretDown,
  Error,
  LinkPath,
  Reseteventfilter,
  TimeTrendLink,
  Warning,
} from '../../assets/images';
import { checkUserEntitlements } from '../../userConfig';
import { platformVersion } from '../../utils/excel-utils';
import CustomDropDownList from '../CustomDropdownList';
import Table from '../DiagnosticTable';
import Loading from '../Loading';
import './styles.scss';

class Diagnostic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedoption: 'Past 90 days',
      vehicleList: [],
      faultType: [],
      ipInputValue: '',
      columns: [],
      dateType:
        new URL(window.location.href).searchParams.get('dfdt') !== null
          ? new URL(window.location.href).searchParams.get('dfdt')
          : 'Past 90 days',
      eventType: { info: false, warning: false, error: false },
    };
    this.datePickerRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ columns: this.tableColumns() });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.diagnosticList !== this.props.diagnosticList) {
      this.setState({ columns: this.tableColumns() });
    }
  }

  tableColumns = () => {
    const columns = [
      {
        id: 'ft',
        Header: 'TYPE',
        accessor: 'ft',
        Filter: this.typeFilter,
        // filter: "includes",
        Cell: props => {
          if (props.value === 'Information') {
            return (
              <img src={require('../../assets/images/info.svg').default} />
            );
          } else if (props.value === 'Warning') {
            return <Warning />;
          } else {
            return <Error />;
          }
        },
      },
      {
        id: 'fm',
        Header: 'FAULT TYPE',
        accessor: 'fm',
        Cell: props => <div style={{ marginLeft: '3%' }}>{props.value}</div>,
        Filter: this.faultTypeFilter,
        // filter: "equals",
      },
      {
        id: 'dt',
        Header: 'DATE & TIME',
        accessor: 'dt',
        Cell: props => (
          <div style={{ marginLeft: '3%' }}>
            {Moment.utc(props.value).format('DD.MM.YYYY \xa0HH:mm:ss')}
          </div>
        ),

        Filter: this.dateFilter,
        // filter: "equals",
      },
      {
        id: 'vn',
        Header: 'VEHICLE NUMBER',
        accessor: 'vno',
        Cell: props => <div style={{ marginLeft: '3%' }}>{props.value}</div>,
        Filter: this.vehicleFilter,
        // filter: "equals",
      },

      {
        id: 'bp',
        Header: 'PRODUCT',
        accessor: 'bp',
        Cell: props => <div style={{ marginLeft: '3%' }}>{props.value}</div>,
        Filter: this.diagnosticBatterySystemFilter,
        // filter: "",
      },
      {
        id: 'pc',
        Header: 'PEC SN',
        accessor: 'pc',
        Cell: props => <div style={{ marginLeft: '3%' }}>{props.value}</div>,
        Filter: this.diagnosticSerialNumberFilter,
        // filter: "",
      },
      {
        id: 'ip',
        Header: 'PEC IP',
        accessor: 'ip',
        Filter: this.diagnosticPecFilter,
        Cell: props => <div style={{ marginLeft: '3%' }}>{props.value}</div>,
        // filter: "includes",
      },
      {
        Header: 'ACTION',
        accessor: 'btn',
        Filter: this.diagResetFilter,
        Cell: ({ cell }) => {
          return (
            <>
              {checkUserEntitlements('ESS:diag-down-link') && (
                <Button
                  variant="link"
                  className="btn btn-link btn-sm"
                  onClick={() =>
                    this.props.copyLinkDiagnosticTab(cell.row.values)
                  }
                  disabled={cell.row.values.pt === null}>
                  <LinkPath />
                </Button>
              )}

              <Button
                variant="link"
                className="btn btn-link btn-sm timetrendlink-btn"
                onClick={() =>
                  this.props.redirectToTimetrendFrmDiagnostic(cell.row.values)
                }
                disabled={
                  cell.row.values.cty === 'True' ||
                  cell.row.values.pn === null ||
                  cell.row.values.bp === null ||
                  checkUserEntitlements('ESS:Product-Trend-Diagnosticevent') ===
                    false
                }>
                {/* Undo changes */} <TimeTrendLink />
              </Button>
            </>
          );
        },
      },
      {
        // id: "pt",
        // Header: "",
        accessor: 'pt',
        Filter: '',
        Cell: () => '',
        // filter: "equals",
      },
      {
        Header: 'PK',
        accessor: 'pk',
        Filter: '',
        // filter: "",
      },
      {
        id: 'vno',
        Header: 'vn',
        accessor: 'vn',
        Filter: '',
        // filter: "",
      },
      {
        Header: 'pcf',
        accessor: 'pcf',
        Filter: '',
        // filter: "",
      },
      {
        Header: 'cty',
        accessor: 'cty',
        Filter: '',
      },
    ];
    const newColumn = {
      id: 'pn',
      Header: 'STRING',
      accessor: 'pn',
      Filter: this.diagnosticStringFilter,
      // filter: "equals",
    };

    if (
      platformVersion() === '3' &&
      window.location.pathname !==
        '/FleetView/VehicleView/TractionConverterView'
    ) {
      const position = columns.length - 6;
      const newColumns = [
        ...columns.slice(0, position),
        newColumn,
        ...columns.slice(position),
      ];
      return newColumns;
    } else {
      return columns;
    }
  };

  componentWillUnmount() {
    this.setState({ ipInputValue: '' });
  }

  typeFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    const url = new URL(window.location.href);
    return (
      <div style={{ display: 'flex' }}>
        <button
          title="Error"
          type="button"
          className={
            url.searchParams.get('dfty') === 'Error'
              ? 'btn  btn-sm typefiltertogglebutton activetypefilter'
              : 'btn  btn-sm typefiltertogglebutton'
          }
          // onClick={() => this.setEventFilter("eventtype", "error")}
          onClick={e => {
            if (this.state.eventType.error === false) {
              setFilter('Error' || undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('dfty', 'Error');
                window.history.pushState(null, null, url);
              }
            } else {
              setFilter(undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('dfty');
                window.history.pushState(null, null, url);
              }
            }
            this.setState(prevState => ({
              eventType: {
                error: !this.state.eventType.error,
                warning: false,
                info: false,
              },
            }));
          }}>
          <Error />
        </button>
        <button
          title="Warning"
          type="button"
          className={
            url.searchParams.get('dfty') === 'Warning'
              ? 'btn  btn-sm typefiltertogglebutton activetypefilter'
              : 'btn  btn-sm typefiltertogglebutton'
          }
          // onClick={() => this.setEventFilter("eventtype", "warning")}
          onClick={e => {
            if (this.state.eventType.warning === false) {
              setFilter('Warning' || undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('dfty', 'Warning');
                window.history.pushState(null, null, url);
              }
            } else {
              setFilter(undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('dfty');
                window.history.pushState(null, null, url);
              }
            }
            this.setState(prevState => ({
              eventType: {
                warning: !this.state.eventType.warning,
                error: false,
                info: false,
              },
            }));
          }}>
          <Warning />
        </button>
        <button
          title="Information"
          type="button"
          className={
            url.searchParams.get('dfty') === 'Information'
              ? 'btn  btn-sm typefiltertogglebutton activetypefilter'
              : 'btn  btn-sm typefiltertogglebutton'
          }
          // onClick={() => this.setEventFilter("eventtype", "info")}
          onClick={e => {
            if (this.state.eventType.info === false) {
              setFilter('Information' || undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('dfty', 'Information');
                window.history.pushState(null, null, url);
              }
            } else {
              setFilter(undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('dfty');
                window.history.pushState(null, null, url);
              }
            }
            this.setState(prevState => ({
              eventType: {
                // ...prevState.eventType,
                info: !this.state.eventType.info,
                error: false,
                warning: false,
              },
            }));
          }}>
          <img src={require('../../assets/images/info.svg').default} />
        </button>
      </div>
    );
  };

  vehicleFilter = ({ column: { filterValue, setFilter } }) => {
    const url = new URL(window.location.href);
    const vehicle =
      url.searchParams.get('vc') && url.searchParams.get('vc').split('_');
    return (
      <DropdownList
        data={
          this.props.selectedView === 'Fleet'
            ? [
                'All vehicles',
                ...new Set(
                  this.props.vehiclesListData &&
                    this.props.vehiclesListData[0].vst.map((vehicle, index) => {
                      let tempVehicle = vehicle.vn.split('_');
                      tempVehicle = tempVehicle[tempVehicle.length - 1];
                      return tempVehicle;
                    })
                ),
              ]
            : [vehicle[vehicle.length - 1]]
        }
        defaultValue={
          vehicle && vehicle.length > 0
            ? vehicle[vehicle.length - 1]
            : 'All vehicles'
        }
        value={filterValue}
        className="diagvehicleFilter"
        onChange={e => {
          if (e === 'All vehicles') {
            setFilter('All');
            this.setState({ selectedVehicle: e });
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.delete('vn');
              window.history.pushState(null, null, url);
            }
          } else {
            setFilter(e || undefined);
            this.setState({ selectedVehicle: e });
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.set('vn', e);
              window.history.pushState(null, null, url);
            }
          }
        }}
        selectIcon={<CaretDown />}
      />
    );
  };

  diagnosticPecFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <DebounceInput
        minLength={3}
        debounceTimeout={1000}
        value={filterValue || ''}
        className="searchkeyword"
        onChange={e => {
          setFilter(e.target.value || undefined);
          const url = new URL(window.location.href);
          url.searchParams.set('ip', e.target.value);
          window.history.pushState(null, null, url);
        }}
        placeholder={`Keywords`}
        // disabled={this.props.selectedView !=="Fleet"}
      />
    );
  };

  diagnosticSerialNumberFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <DebounceInput
        minLength={3}
        debounceTimeout={1000}
        value={filterValue || ''}
        className="searchkeyword pecSnFilter"
        onChange={e => {
          setFilter(e.target.value || undefined);
          const url = new URL(window.location.href);
          url.searchParams.set('pcft', e.target.value);
          window.history.pushState(null, null, url);
        }}
        placeholder={`Keywords`}
        // disabled={this.props.selectedView !=="Fleet"}
      />
    );
  };

  diagnosticBatterySystemFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    const url = new URL(window.location.href);
    return (
      <DebounceInput
        minLength={3}
        debounceTimeout={1000}
        value={
          filterValue ||
          (url.searchParams.get('pdn') && url.searchParams.get('pdn'))
        }
        className="searchkeyword"
        onChange={e => {
          setFilter(e.target.value || undefined);
          // let url = new URL(window.location.href);
          url.searchParams.set('bpft', e.target.value);
          window.history.pushState(null, null, url);
        }}
        placeholder={`Keywords`}
        disabled={
          this.props.selectedView !== 'Fleet' &&
          url.searchParams.get('pdn') &&
          url.searchParams.get('pdn')
        }
      />
    );
  };

  diagnosticStringFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    const url = new URL(window.location.href);
    return (
      <DebounceInput
        minLength={3}
        debounceTimeout={1000}
        value={
          filterValue ||
          (url.searchParams.get('pkdn') && url.searchParams.get('pkdn'))
        }
        className="searchkeyword"
        onChange={e => {
          setFilter(e.target.value || undefined);
          url.searchParams.set('pnft', e.target.value);
          window.history.pushState(null, null, url);
        }}
        placeholder={`Keywords`}
        disabled={
          this.props.selectedView !== 'Fleet' &&
          url.searchParams.get('pkdn') &&
          url.searchParams.get('pkdn')
        }
      />
    );
  };

  faultTypeFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <DropdownList
        data={[
          'All',
          ...new Set(
            this.props.diagnosticListConfig.uniqueFaultMessages.map(item => {
              return `${item.fm} (${item.fmc})`;
            })
          ),
        ]}
        defaultValue={'All'}
        value={filterValue}
        className="faulttypeFilter"
        onChange={e => {
          if (e === 'All') {
            setFilter('All');

            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.delete('fm');
              window.history.pushState(null, null, url);
            }
          } else {
            setFilter(e.replace(/\s*\((?=\d+\))[^)]*\)\s*/g, '') || undefined); // Set undefined to remove the filter entirely
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.set(
                'fm',
                e.replace(/\s*\((?=\d+\))[^)]*\)\s*/g, '')
              );
              window.history.pushState(null, null, url);
            }
          }
        }}
        placeholder="Select Type"
        selectIcon={<CaretDown />}
      />
    );
  };

  dateFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    const url = new URL(window.location.href);
    return (
      <>
        <CustomDropDownList
          option={[
            'Any time',
            'Past 48 hours',
            'Past 7 days',
            'Past 30 days',
            'Past 90 days',
            'Past year',
            'Custom range',
          ]}
          defaultValue={'Past 90 days'}
          value={
            new URL(window.location.href).searchParams.get('dfdt') !== null
              ? new URL(window.location.href).searchParams.get('dfdt')
              : 'Past 90 days'
          }
          className="sidemenufilterdropdown"
          onChange={e => {
            if (e === 'Custom range') {
              document.getElementById('DiagdatePickerClick').click();
            } else {
              this.setState({ selectedoption: e });
              this.props.setDiagnosticEventListData({
                pageSize: 20,
                pageIndex: 0,
                uniqueKey: 'All',
                uniqueValue: 'All',
                sortValue: true,
                sortValueKey: 'dt',
              });
              this.props.fetchDiagnosticList(
                url.searchParams.get('ft'),
                url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
                url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
                url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
                Moment(this.state.filterStartDate)
                  .format('YYYY-MM-DDTHH:mm:ss')
                  .replace(/:/g, '%3A'),
                Moment(this.state.filterEndDate)
                  .format('YYYY-MM-DDTHH:mm:ss')
                  .replace(/:/g, '%3A'),
                e || 'Past 90 days',
                this.props.diagnosticEventData.uniqueKey,
                this.props.diagnosticEventData.uniqueValue,
                url.searchParams.get('pkdn')
                  ? url.searchParams.get('pkdn')
                  : '',
                this.props.diagnosticEventData.pageSize,
                this.props.diagnosticEventData.pageIndex,
                !this.props.diagnosticEventData.sortValue &&
                  this.props.diagnosticEventData.sortValueKey
                  ? 1
                  : 0,
                this.props.diagnosticEventData.sortValueKey
                  ? this.props.diagnosticEventData.sortValueKey
                  : ''
              );

              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('dfdt', e);
                url.searchParams.delete('dfsd');
                url.searchParams.delete('dfed');
                window.history.pushState(null, null, url);
              }
            }
          }}
          selectIcon={<CaretDown />}
        />
        <DateRangePicker
          initialSettings={{
            startDate: Moment(
              new URL(window.location.href).searchParams.get('sdt')
            ).format('MM/DD/YYYY'),
            endDate: Moment(
              new URL(window.location.href).searchParams.get('edt')
            ).format('MM/DD/YYYY'),
            maxDate: Moment(
              new Date(Moment().subtract(1, 'days').endOf('day'))
            ).format('MM/DD/YYYY'),
          }}
          onCallback={this.handleDateRangeCallback}>
          <img
            src={require('../../assets/images/edit.svg').default}
            title="Review details"
            className={'daterangepickerimg'}
            id={'DiagdatePickerClick'}
          />
        </DateRangePicker>
      </>
    );
  };

  handleDateRangeCallback = (start, end, label) => {
    this.setState({
      dateType: 'Custom range',
      filterStartDate: Moment(start)._d,
      filterEndDate: Moment(end)._d,
    });
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('dfdt', 'Custom range');
      url.searchParams.set('sdt', Moment(start).format('YYYY-MM-DDTHH:mm:ss'));
      url.searchParams.set('edt', Moment(end).format('YYYY-MM-DDTHH:mm:ss'));
      window.history.pushState(null, null, url);
    }

    this.props.fetchDiagnosticList(
      url.searchParams.get('ft'),
      url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
      url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
      url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
      Moment(start).format('YYYY-MM-DDTHH:mm:ss'),
      Moment(end).format('YYYY-MM-DDTHH:mm:ss'),
      'Custom range',
      this.props.diagnosticEventData.uniqueKey,
      this.props.diagnosticEventData.uniqueValue,
      url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
      this.props.diagnosticEventData.pageSize,
      this.props.diagnosticEventData.pageIndex,
      !this.props.diagnosticEventData.sortValue &&
        this.props.diagnosticEventData.sortValueKey
        ? 1
        : 0,
      this.props.diagnosticEventData.sortValueKey
        ? this.props.diagnosticEventData.sortValueKey
        : ''
    );
  };

  diagResetFilter = ({ column: { filterValue, setFilter, setAllFilters } }) => {
    return (
      <button
        className="btn btn-sm"
        onClick={() => {
          this.setState({ dateType: 'Past 90 days' });
          this.props.diagFilterApply();

          document.getElementById('diagfilterresetClick').click();

          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            url.searchParams.delete('dfdt');
            url.searchParams.delete('vn');
            url.searchParams.delete('ip');
            url.searchParams.delete('fm');
            url.searchParams.delete('pcft');
            url.searchParams.delete('pnft');
            url.searchParams.delete('bpft');
            url.searchParams.delete('dfsd');
            url.searchParams.delete('dfed');
            url.searchParams.delete('pn');
            url.searchParams.delete('dfty');

            window.history.pushState(null, null, url);
          }
        }}>
        <Reseteventfilter />
      </button>
    );
  };

  render() {
    return (
      <div className="diagnostic-container">
        {this.props.diagnosticList === null ? (
          <div>
            <Loading loadintText="Loading data" />
          </div>
        ) : (
          <div>
            <Table
              columns={this.state.columns}
              data={this.props.diagnosticList} // {diagnosticData}
              style={{ maxWidth: window.innerWidth - 340 }}
              pageCount={this.props.diagnosticListConfig}
              diagnosticEventData={this.props.diagnosticEventData}
              setDiagnosticEventListData={this.props.setDiagnosticEventListData}
              loadingDiagnosticList={this.props.loadingDiagnosticList}
            />
          </div>
        )}
        {/* )} */}
      </div>
    );
  }
}

export default withRouter(Diagnostic);
