import React from 'react';

export default class NotFound extends React.Component {
  render() {
    return (
      <div className="page-not-found">
        <h4>Page not found</h4>
      </div>
    );
  }
}
