import Moment from 'moment';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { getCookie, setCookie } from '../../utils/cookie-settings';

require('./styles.scss');

Moment.locale('en');

function CookieConfirmPopup(props) {
  const a_value =
    getCookie('ess_analytics') !== null
      ? getCookie('ess_analytics') === 'true'
      : false;
  const p_value =
    getCookie('ess_preference') !== null
      ? getCookie('ess_preference') === 'true'
      : false;
  const [analytics, setAnalytics] = React.useState(a_value);
  const [preference, setPreference] = React.useState(p_value);

  const btnApplyCheckboxSelection = () => {
    setCookie('ess_analytics=' + analytics);
    setCookie('ess_preference=' + preference);

    if (analytics === false) {
      setCookie('_ga=;');
    }
    if (preference === false) {
      setCookie('_pref=;');
    }

    props.setVisible(false);
  };

  const btnSelectAllCheckboxes = () => {
    setCookie('ess_analytics=' + true);
    setCookie('ess_preference=' + true);
    setAnalytics(true);
    setPreference(true);
    props.setVisible(false);
  };

  return (
    <Modal className="cookieconfirmdialogbox" show={props.show}>
      <Modal.Body>
        <div id="cookiedialog" className="dialogboxcontainer">
          <div>
            <div className="headingcursor"></div>
            <div className="headingtitle">Cookie settings</div>
            <div className="description">
              <p>
                Our website uses cookies which are necessary for running the
                website and for providing the services you request. We would
                also like to set the following optional cookies on your device.
                You can change these settings any time later by clicking &quote;
                Change cookie settings &quote; at the bottom of any page. For
                more information, please read our{' '}
                <a
                  href="https://new.abb.com/privacy-policy#Cookies"
                  target="_blank"
                  className="link"
                  rel="noreferrer">
                  Cookie Information.
                </a>
              </p>
              <p>
                Please select the optional cookies we can set on your device:
              </p>
            </div>

            <div>
              <div className="ckbx_cont">
                <input
                  id="ckbx_analytics"
                  type="checkbox"
                  checked={analytics}
                  onChange={e => {
                    setAnalytics(!analytics);
                  }}
                  className="ckbx"
                />
                <span className="ckbx_label">Analytics</span>
              </div>
              <div className="ckbx_description">
                <p>
                  We collect statistics in order to understand how our visitors
                  interact with the website and how we can improve it. The
                  cookies collect information in a way that does not directly
                  identify anyone.
                </p>
              </div>
              <div>
                <div className="ckbx_cont">
                  <input
                    id="ckbx_preference"
                    type="checkbox"
                    checked={preference}
                    onChange={e => {
                      setPreference(!preference);
                    }}
                    className="ckbx"
                  />
                  <span className="ckbx_label">Preferences</span>
                </div>
                <p className="ckbx_description">
                  We store choices you have made so that they are remembered
                  when you visit our website again in order to provide you a
                  more personalized experience.
                </p>
              </div>
            </div>
          </div>

          <div className="btncontainer">
            <Button
              id="btnApplyCheckboxSelection"
              className="btn btn-sm okbtn1"
              onClick={() => btnApplyCheckboxSelection()}>
              Apply selection and agree
            </Button>

            <Button
              id="btnSelectAllCheckboxes"
              className="btn btn-sm okbtn2"
              onClick={() => btnSelectAllCheckboxes()}>
              Select all and agree
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CookieConfirmPopup;
