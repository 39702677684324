import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import './styles.scss';

class FleetList extends React.Component {
  state = {
    items: Array.from({ length: 20 }),
    hasMore: true,
    bookMarkList: [],
  };

  bookmarkFleet = (e, index) => {
    if (this.state.bookMarkList.indexOf(index) === -1) {
      this.setState({
        bookMarkList: [...this.state.bookMarkList, index],
      });
    } else if (this.state.bookMarkList.indexOf(index) !== -1) {
      const newBookMarkList = [...this.state.bookMarkList];
      newBookMarkList.splice(this.state.bookMarkList.indexOf(index), 1);
      this.setState({ bookMarkList: newBookMarkList });
    }
    e.stopPropagation();
  };

  fetchMoreData = () => {
    if (this.state.items.length >= 500) {
      this.setState({ hasMore: false });
      return;
    }
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(Array.from({ length: 20 })),
      });
    }, 1500);
  };

  render() {
    return (
      <div>
        <InfiniteScroll
          dataLength={this.state.items.length}
          // next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          // loader={
          //   <p style={{ textAlign: "center" }}>
          //     <b>Loading ...</b>
          //   </p>
          // }
          height={'71vh'}
          // endMessage={
          //   <p style={{ textAlign: "center" }}>
          //     <b>Yay You have seen it all</b>
          //   </p>
          // }
        >
          {this.props.fleeetData &&
            this.props.fleeetData.map((fleet, index) => (
              <div
                key={index}
                className={
                  fleet.fc === 'ok' ? 'fleetlistgreen' : 'fleetlistred'
                }>
                <Row className="remove-bottom-margin">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    className={
                      fleet.typ === 'eBus'
                        ? 'noLeftpadding noRightpadding eBuses'
                        : 'noLeftpadding noRightpadding singleFleet'
                    }
                    data-intro={fleet.nm}
                    onClick={() =>
                      this.props.redirectToFleetView(
                        fleet.nm,
                        fleet.st,
                        fleet.typ
                      )
                    }>
                    <Row className="">
                      <Col md={11} className="noLeftpadding noRightpadding">
                        <div className="fleetname"> {fleet.dnm}</div>
                      </Col>
                      <Col
                        md={1}
                        className="graystar  ml-auto noLeftpadding noRightpadding hidewithspace"
                        onClick={e => {
                          this.bookmarkFleet(e, index);
                        }}>
                        &#9733;
                      </Col>
                    </Row>
                    <hr className="borderline"></hr>
                    <div className="includevehicles">
                      <div className="vehicleinfo">
                        Includes {fleet.vc}{' '}
                        {fleet.vc > 1 ? 'vehicles ' : 'vehicle '}
                      </div>
                      {fleet.ok >= 1 && (
                        <React.Fragment>
                          <div
                            className="conditionIcon vehicleinfo hidewithoutspace"
                            id="goodcondition"></div>
                          <div className="conditioncount vehicleinfo hidewithoutspace">
                            {fleet.ok} Ok{' '}
                          </div>
                        </React.Fragment>
                      )}
                      {fleet.trp >= 1 && (
                        <React.Fragment>
                          <div
                            className="conditionIcon vehicleinfo hidewithoutspace"
                            id="poorcondition"></div>
                          <div className="conditioncount vehicleinfo hidewithoutspace">
                            {fleet.trp} Trip{' '}
                          </div>
                        </React.Fragment>
                      )}
                      {fleet.un >= 1 && (
                        <React.Fragment>
                          <div
                            className="conditionIcon vehicleinfo hidewithoutspace"
                            id="unknowncondition"></div>
                          <div className="conditioncount vehicleinfo hidewithoutspace">
                            {fleet.un} Unknown
                          </div>
                        </React.Fragment>
                      )}
                      <Row className="addMarginTop">
                        <Col xs={12} sm={4} md={4} className="noLeftpadding ">
                          <div>Maintenance site:</div>
                          <div className="fleetlistvalues">{fleet.dst}</div>
                        </Col>
                        <Col xs={12} sm={4} md={4} className="noLeftpadding">
                          <div>Type:</div>
                          <div className="fleetlistvalues">{fleet.typ}</div>
                        </Col>
                        <Col xs={12} sm={4} md={4} className="noLeftpadding">
                          <div>Operator:</div>
                          <div className="fleetlistvalues">{fleet.opt}</div>
                          {/* <div className="fleetlistvalues">
                            {fleet.nm === "ABB ESS Demo Fleet"
                              ? "ABB ESS Team"
                              : fleet.nm === "SWISSTROLLEY_FRIBOURG"
                              ? "TPF"
                              : fleet.nm === "AKKUFLIRT"
                              ? "AKKUFLIRT"
                              : "Arriva"}
                          </div> */}
                        </Col>
                      </Row>
                      <Row className="">
                        {/* <Col xs={12} sm={12} md={12} className="noLeftpadding">
                          <span>Maintenance site: </span>
                          <span className="fleetlistvalues">{fleet.lc}</span>
                        </Col>
                        <Col xs={12} sm={4} md={4} className="noLeftpadding">
                          <div>Unit:</div>
                          <div className="fleetlistvalues">{fleet.unt}</div>
                        </Col>
                        <Col xs={12} sm={4} md={4} className="noLeftpadding">
                          <div>Traction services:</div>
                          <div className="fleetlistvalues">{fleet.ts}</div>
                        </Col> */}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
        </InfiniteScroll>
      </div>
    );
  }
}

export default FleetList;
