import {
  CLEAR_EVENT_LIST,
  CLEAR_SAVE_EVENT_RESPONCE,
  CLEAR_UNDO_EVENT_RESPONCE,
  FETCH_ALL_EVENT_LIST,
  FETCH_ALL_EVENT_LIST_FAILURE,
  FETCH_ALL_EVENT_LIST_SUCCESS,
  FETCH_EVENT_LIST,
  FETCH_EVENT_LIST_FAILURE,
  FETCH_EVENT_LIST_SUCCESS,
  FETCH_VEVENT_LIST,
  FETCH_VEVENT_LIST_FAILURE,
  FETCH_VEVENT_LIST_SUCCESS,
  SAVE_EVENT_DATA,
  SAVE_EVENT_DATA_FAILURE,
  SAVE_EVENT_DATA_SUCCESS,
  SET_ALL_EVENT_LIST_DATA,
  UNDO_EVENT_DATA,
  UNDO_EVENT_DATA_FAILURE,
  UNDO_EVENT_DATA_SUCCESS,
} from './constants';

// Get event list
export const fetchEventList = (
  fleetName,
  vehicleName,
  pecUniqueSerialNumber,
  filterStartDate,
  filterEndDate,
  dateType,
  eventType,
  selectedVehicle,
  selectedProduct,
  searchColumn,
  searchColumnValue
) => ({
  type: FETCH_EVENT_LIST,
  payload: {
    fleetName,
    vehicleName,
    pecUniqueSerialNumber,
    filterStartDate,
    filterEndDate,
    dateType,
    eventType,
    selectedVehicle,
    selectedProduct,
    searchColumn,
    searchColumnValue,
  },
});

export const fetchEventListSucess = eventListResponce => ({
  type: FETCH_EVENT_LIST_SUCCESS,
  eventListResponce,
});

export const getEventListFailure = error => ({
  type: FETCH_EVENT_LIST_FAILURE,
  error,
});

export const clearEventList = () => ({
  type: CLEAR_EVENT_LIST,
});

// save event

export const saveEvent = (siteName, fleetName, vehicle, eventFormData) => ({
  type: SAVE_EVENT_DATA,
  payload: {
    siteName,
    fleetName,
    vehicle,
    eventFormData,
  },
});

export const saveEventSucess = saveEventResponce => ({
  type: SAVE_EVENT_DATA_SUCCESS,
  saveEventResponce,
});

export const saveEventFailure = error => ({
  type: SAVE_EVENT_DATA_FAILURE,
  error,
});

export const clearSaveEventResponce = () => ({
  type: CLEAR_SAVE_EVENT_RESPONCE,
});

// Get Vevent list
export const fetchVEventList = (
  site,
  fleetName,
  vehicleName,
  filterStartDate,
  filterEndDate,
  dateType,
  eventType,
  selectedVehicle,
  selectedProduct,
  searchColumn,
  searchColumnValue
) => ({
  type: FETCH_VEVENT_LIST,
  payload: {
    site,
    fleetName,
    vehicleName,
    filterStartDate,
    filterEndDate,
    dateType,
    eventType,
    selectedVehicle,
    selectedProduct,
    searchColumn,
    searchColumnValue,
  },
});

export const fetchVEventListSucess = veventListResponce => ({
  type: FETCH_VEVENT_LIST_SUCCESS,
  veventListResponce,
});

export const getVEventListFailure = error => ({
  type: FETCH_VEVENT_LIST_FAILURE,
  error,
});

// Get Allevent list
export const fetchAllEventList = (
  fleetName,
  vehicleName,
  pecUniqueSerialNumber,
  packSerialNumber,
  startDateTime,
  endDateTime,
  dateType,
  filterColumnKey,
  filterColumnKeyValue,
  pageSize,
  pageNumber,
  sortType,
  sortTo
) => ({
  type: FETCH_ALL_EVENT_LIST,
  payload: {
    fleetName,
    vehicleName,
    pecUniqueSerialNumber,
    packSerialNumber,
    startDateTime,
    endDateTime,
    dateType,
    filterColumnKey,
    filterColumnKeyValue,
    pageSize,
    pageNumber,
    sortType,
    sortTo,
  },
});

export const fetchAllEventListSucess = AlleventListResponce => ({
  type: FETCH_ALL_EVENT_LIST_SUCCESS,
  AlleventListResponce,
});

export const getAllEventListFailure = error => ({
  type: FETCH_ALL_EVENT_LIST_FAILURE,
  error,
});

// SET ALL EVENT LIST DATA
export const setAllEventListData = eventData => ({
  type: SET_ALL_EVENT_LIST_DATA,
  eventData,
});

// undo  event change

export const undoEvent = (siteName, fleetName, vehicle, obj) => ({
  type: UNDO_EVENT_DATA,
  payload: {
    siteName,
    fleetName,
    vehicle,
    obj,
  },
});

export const undoEventSucess = undoEventResponce => ({
  type: UNDO_EVENT_DATA_SUCCESS,
  undoEventResponce,
});

export const undoEventFailure = error => ({
  type: UNDO_EVENT_DATA_FAILURE,
  error,
});

export const clearUndoEventResponce = () => ({
  type: CLEAR_UNDO_EVENT_RESPONCE,
});
