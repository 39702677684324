import { createSelector } from 'reselect';

const broadcastMessageData = () => state => state.get('broadcastMessageData');

export const allMessages = () =>
  createSelector(broadcastMessageData(), state =>
    state.get('allBroadcastMessages')
  );

export const activeMessages = () =>
  createSelector(broadcastMessageData(), state => state.get('activeMessages'));
