import moment from 'moment';

export const getStartEndDate = incomingDate => {
  const [monthName, yearPart] = incomingDate.split(' ');
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames.indexOf(monthName) + 1;
  const currentYear = new Date().getFullYear();
  const year = currentYear.toString().substring(0, 2) + yearPart;

  return {
    startDate: new Date(year, month - 1, 1),
    endDate: new Date(
      year,
      month - 1,
      new Date(year, month, 0).getDate(),
      23,
      59,
      59
    ),
  };
};

export const getStartEnd = (input, startDateInput, endDateInput) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  startDateInput = moment(decodeURIComponent(startDateInput));
  endDateInput = moment(decodeURIComponent(endDateInput));

  function handleDate(input) {
    return {
      start: moment(input).startOf('day').toDate(),
      end: moment(input).endOf('day').toDate(),
    };
  }

  function handleWeekNumber(input, year) {
    if (input < 1 || input > 53) {
      // throw 'Invalid week number. It must be between 1 and 53.';
    }

    return {
      start: moment().year(year).week(input).startOf('week').toDate(),
      end: moment().year(year).week(input).endOf('week').toDate(),
    };
  }

  function handleMonth(input) {
    const monthNumber = monthNames.indexOf(input.split(' ')[0]);
    const currentYear = new Date().getFullYear();
    const year = currentYear.toString().substring(0, 2) + input.split(' ')[1];

    return {
      start: moment([year, monthNumber]).startOf('month').toDate(),
      end: moment([year, monthNumber]).endOf('month').toDate(),
    };
  }

  function handleQuarter(input) {
    const quarterNumber = parseInt(input.split(' ')[0].substring(1));
    if (quarterNumber < 1 || quarterNumber > 4) {
      // throw 'Invalid quarter number. It must be between 1 and 4.';
    }
    const currentYear = new Date().getFullYear();
    const year = currentYear.toString().substring(0, 2) + input.split(' ')[1];

    return {
      start: moment()
        .year(year)
        .quarter(quarterNumber)
        .startOf('quarter')
        .toDate(),
      end: moment().year(year).quarter(quarterNumber).endOf('quarter').toDate(),
    };
  }

  const monthsDifference = endDateInput.diff(startDateInput, 'months');

  if (monthsDifference <= 1 && moment.isDate(input)) {
    return handleDate(input);
  } else if (
    monthsDifference < 6 &&
    typeof input === 'string' &&
    !isNaN(input)
  ) {
    return handleWeekNumber(
      parseInt(input),
      parseInt(input) >= startDateInput.week()
        ? startDateInput.year()
        : endDateInput.year()
    );
  } else if (monthsDifference < 12 && typeof input === 'string') {
    return handleMonth(input);
  } else if (typeof input === 'string' && input.startsWith('Q')) {
    return handleQuarter(input);
  } else if (typeof input === 'string' && !isNaN(input)) {
    return handleWeekNumber(parseInt(input), startDateInput.year());
  }

  // throw 'Invalid input. Please provide a date, week number, month, or quarter.';
};
