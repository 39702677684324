import { fromJS } from 'immutable';
import Moment from 'moment';
import {
  CLEAR_CHARTDATA,
  FETCH_BOXPLOT_DATA,
  FETCH_BOXPLOT_DATA_FAILURE,
  FETCH_BOXPLOT_DATA_SUCCESS,
  FETCH_DIAGNOSTICLIST,
  FETCH_DIAGNOSTICLIST_FAILURE,
  FETCH_DIAGNOSTICLIST_SUCCESS,
  FETCH_FLEETSLIST,
  FETCH_FLEETSLIST_FAILURE,
  FETCH_FLEETSLIST_SUCCESS,
  FETCH_FLEET_STATISTICS_DATA,
  FETCH_FLEET_STATISTICS_DATA_FAILURE,
  FETCH_FLEET_STATISTICS_DATA_SUCCESS,
  FETCH_HISTOGRAM_DATA,
  FETCH_HISTOGRAM_DATA_FAILURE,
  FETCH_HISTOGRAM_DATA_SUCCESS,
  FETCH_MODULELIST,
  FETCH_MODULELIST_FAILURE,
  FETCH_MODULELIST_SUCCESS,
  FETCH_OVERALL_CONDITION_DATA,
  FETCH_OVERALL_CONDITION_DATA_FAILURE,
  FETCH_OVERALL_CONDITION_DATA_SUCCESS,
  FETCH_OVERVIEW_DATA,
  FETCH_OVERVIEW_DATA_FAILURE,
  FETCH_OVERVIEW_DATA_SUCCESS,
  FETCH_UNMOUNTEDESS_LIST,
  FETCH_UNMOUNTEDESS_LIST_FAILURE,
  FETCH_UNMOUNTEDESS_LIST_SUCCESS,
  FETCH_VEHICLEPRODUCTLIST,
  FETCH_VEHICLEPRODUCTLIST_FAILURE,
  FETCH_VEHICLEPRODUCTLIST_SUCCESS,
  FETCH_VEHICLESLIST,
  FETCH_VEHICLESLIST_FAILURE,
  FETCH_VEHICLESLIST_SUCCESS,
  SET_DATE,
  SET_DIAGNOSTIC_EVENT_LIST_DATA,
  SET_FLEETNAME,
  SET_LIST,
  SET_PLATFORM_VERSION_NUMBER,
  SET_VEHICLENAME,
} from './constants';

const initialState = fromJS({
  loadingOverallCondition: false,
  loadingOverview: false,
  loadingHistogram: false,
  loadingBoxPlot: false,
  loadingFleets: false,
  loadingVehicles: false,
  // selectedTrain: "Re 460 117-5",
  overallConditionData: null,
  overviewData: null,
  histogrampData: null,
  boxPlotData: null,
  fleetsList: null,
  vehiclesList: null,
  selectedFleet: null,
  selectedVehicle: null,
  error: null,
  loadingVehicleProducts: null,
  loadingModules: null,
  vehicleProductsList: null,
  modulesList: null,
  startDate: Moment().subtract(30, 'days').startOf('day'),
  endDate: Moment().subtract(1, 'days').endOf('day'),
  selectedList: 'vehicleproduct',
  loadingUnmountedESSList: false,
  unmountedESSList: null,
  fleetStatistics: null,
  loadingFleetStatistics: false,
  loadingDiagnosticList: false,
  diagnosticList: null,
  diagnosticEventData: {},
  diagnosticEventListDataConfig: null,
  platformVersionNumber: null,
});

export function reducerSingleGraph(state = initialState, action) {
  switch (action.type) {
    case FETCH_OVERALL_CONDITION_DATA:
      return state.set('loadingOverallCondition', true).set('error', null);
    case FETCH_OVERALL_CONDITION_DATA_SUCCESS:
      return state
        .set('loadingOverallCondition', false)
        .set('overallConditionData', action.overallConditionData);
    case FETCH_OVERALL_CONDITION_DATA_FAILURE:
      return state
        .set('loadingOverallCondition', false)
        .set('overallConditionData', null)
        .set('error', action.error);

    /// ////////////////////////////////////
    case FETCH_OVERVIEW_DATA:
      return state.set('loadingOverview', true).set('error', null);
    case FETCH_OVERVIEW_DATA_SUCCESS:
      return state
        .set('loadingOverview', false)
        .set('overviewData', action.overviewData);
    case FETCH_OVERVIEW_DATA_FAILURE:
      return state
        .set('loadingOverview', false)
        .set('overviewData', null)
        .set('error', action.error);
    /// ///////////////////////////////////////////////////
    case FETCH_HISTOGRAM_DATA:
      return state.set('loadingHistogram', true).set('error', null);
    case FETCH_HISTOGRAM_DATA_SUCCESS:
      return state
        .set('loadingHistogram', false)
        .set('histogramData', action.histogramData);
    case FETCH_HISTOGRAM_DATA_FAILURE:
      return state
        .set('loadingHistogram', false)
        .set('histogramData', null)
        .set('error', action.error);

    case FETCH_BOXPLOT_DATA:
      return state.set('loadingBoxPlot', true).set('error', null);
    case FETCH_BOXPLOT_DATA_SUCCESS:
      return state
        .set('loadingBoxPlot', false)
        .set('boxPlotData', action.boxPlotData);
    case FETCH_BOXPLOT_DATA_FAILURE:
      return state
        .set('loadingBoxPlot', false)
        .set('boxPlotData', null)
        .set('error', action.error);

    case CLEAR_CHARTDATA:
      return state.set('boxPlotData', null);
    /***********************************/
    case FETCH_FLEETSLIST:
      return state
        .set('loadingFleets', true)
        .set('fleetsList', null)
        .set('error', null);
    case FETCH_FLEETSLIST_SUCCESS:
      return state
        .set('loadingFleets', false)
        .set('fleetsList', action.fleetsList);
    case FETCH_FLEETSLIST_FAILURE:
      return state
        .set('loadingFleets', false)
        .set('fleetsList', null)
        .set('error', action.error);
    /***********************************/
    case FETCH_VEHICLESLIST:
      return state
        .set('loadingVehicles', true)
        .set('vehiclesList', null)
        .set('error', null);
    case FETCH_VEHICLESLIST_SUCCESS:
      return state
        .set('loadingVehicles', false)
        .set('vehiclesList', action.vehiclesList);
    case FETCH_VEHICLESLIST_FAILURE:
      return state
        .set('loadingVehicles', false)
        .set('vehiclesList', null)
        .set('error', action.error);
    /***********************************/
    case FETCH_FLEET_STATISTICS_DATA:
      return state
        .set('loadingFleetStatistics', true)
        .set('fleetStatistics', null)
        .set('error', null);
    case FETCH_FLEET_STATISTICS_DATA_SUCCESS:
      return state
        .set('loadingFleetStatistics', false)
        .set('fleetStatistics', action.payload);
    case FETCH_FLEET_STATISTICS_DATA_FAILURE:
      return state
        .set('loadingFleetStatistics', false)
        .set('fleetStatistics', null)
        .set('error', action.error);
    /***********************************/
    case FETCH_VEHICLEPRODUCTLIST:
      return state
        .set('loadingVehicleProducts', true)
        .set('vehicleProductsList', null)
        .set('error', null);
    case FETCH_VEHICLEPRODUCTLIST_SUCCESS:
      return state
        .set('loadingVehicleProducts', false)
        .set('vehicleProductsList', action.vehicleproductList);
    case FETCH_VEHICLEPRODUCTLIST_FAILURE:
      return state
        .set('loadingVehicleProducts', false)
        .set('vehicleProductsList', null)
        .set('error', action.error);
    /***********************************/
    case FETCH_MODULELIST:
      return state
        .set('loadingModules', true)
        .set('modulesList', null)
        .set('error', null);
    case FETCH_MODULELIST_SUCCESS:
      return state
        .set('loadingModules', false)
        .set('modulesList', action.moduleList);
    case FETCH_MODULELIST_FAILURE:
      return state
        .set('loadingModules', false)
        .set('modulesList', null)
        .set('error', action.error);
    /***********************************/
    case SET_FLEETNAME:
      return state.set('selectedFleet', action.fleetName);
    case SET_VEHICLENAME:
      return state.set('selectedVehicle', action.vehicleName);
    /***********************************/
    case SET_DATE:
      return state
        .set('startDate', action.payload.startDate)
        .set('endDate', action.payload.endDate);
    /***********************************/
    case SET_LIST:
      return state.set('selectedList', action.payload.selection);
    /***********************************/
    case FETCH_UNMOUNTEDESS_LIST:
      return state
        .set('loadingUnmountedESSList', true)
        .set('unmountedESSList', null)
        .set('error', null);
    case FETCH_UNMOUNTEDESS_LIST_SUCCESS:
      return state
        .set('loadingUnmountedESSList', false)
        .set('unmountedESSList', action.unmountedESSList);
    case FETCH_UNMOUNTEDESS_LIST_FAILURE:
      return state
        .set('loadingUnmountedESSList', false)
        .set('unmountedESSList', null)
        .set('error', action.error);
    /***********************************/
    case FETCH_DIAGNOSTICLIST:
      return state
        .set('loadingDiagnosticList', true)
        .set('diagnosticList', null)
        .set('error', null);
    case FETCH_DIAGNOSTICLIST_SUCCESS:
      return state
        .set('loadingDiagnosticList', false)
        .set('diagnosticEventListDataConfig', action.diagnosticList.conf)
        .set('diagnosticList', action.diagnosticList.dc);
    case FETCH_DIAGNOSTICLIST_FAILURE:
      return state
        .set('loadingDiagnosticList', false)
        .set('diagnosticList', null)
        .set('diagnosticEventListDataConfig', null)
        .set('error', action.error);
    case SET_DIAGNOSTIC_EVENT_LIST_DATA:
      return state.set('diagnosticEventData', action.eventData);
    case SET_PLATFORM_VERSION_NUMBER:
      return state.set('platformVersionNumber', action.payload);
    /***********************************/
    default:
      return state;
  }
}
