import React, { useRef, useState } from 'react';

export default function Help() {
  const [isLoading, setisLoading] = useState(true);
  const urlLink = window.location.href;
  const url = new URL(urlLink);
  const soh = url.hash.includes('state_of_health');
  let iframeurl = '/help/web.html';
  if (soh) {
    iframeurl = '/help/web.html#state_of_health';
  }
  const isEntitlement = enititlement => {
    const entitlements = JSON.parse(
      sessionStorage.getItem('profile')
    ).entitlements;
    return entitlements.includes(enititlement);
  };

  const iframeRef = useRef();

  function handleIframe() {
    const iframeItem = iframeRef.current;
    if (isEntitlement('ESS:Product-SOH')) {
      iframeItem.contentWindow.document.getElementById(
        'state_of_health'
      ).innerHTML = `
     <div id="state_of_health" class="flex-section">
                     <h2 id="d0e2470" class="flex-title">
                        <p>State of health (Advanced Insights)</p>
                     </h2>
                     <p id="d0e2475" class="flex-paragraph">The <span id="d0e2477" class="flex-bold">State of health</span> tab shows the health status of ESS in a scatter plot. The state of health data is calculated monthly based on the throughput
                        (see formula below). Any stress factors are not considered. A real state of health can only be determined with physical measurements.
                     </p>
                     <div id="d0e2482" class="flex-wrapper flex-class-equation">
                        <p id="d0e2483" class="flex-title">
                           <p>SoH calculation</p>
                        </p>
                        <p id="d0e2486" class="flex-paragraph"><span><img src="images/formula.svg" id="d0e2487" class="flex-formula"></span></p>
                        <p id="d0e2490" class="flex-paragraph">Where:</p>
                        <p id="d0e2493" class="flex-paragraph"><span><img src="images/no1-formula.svg" id="d0e2494" class="flex-formula"></span> = State of Health
                        </p>
                        <p id="d0e2498" class="flex-paragraph"><span><img src="images/no2-formula.svg" id="d0e2499" class="flex-formula"></span>= Expected end of life capacity (value between 0 and 1)
                        </p>
                        <p id="d0e2503" class="flex-paragraph"><span><img src="images/no3-formula.svg" id="d0e2504" class="flex-formula"></span> = Total capacity throughput at instant t<sub id="d0e2507" class="flex-phrase flex-class-subscript">k</sub></p>
                        <p id="d0e2511" class="flex-paragraph"><span><img src="images/no4-formula.svg" id="d0e2512" class="flex-formula"></span> = Total capacity throughput at end of life
                        </p>
                     </div>
                     <p id="d0e2517" class="flex-paragraph">To view the State of health (SoH) scatter plot, do the below steps. See also, below self-run video.</p>
                     <div id="d0e2520" class="flex-wrapper flex-class-figure">
                        <p data-border="Border" data-padding="figureNormalPadding">
                           <p data-border="Border" data-padding="figureNormalPadding" id="d0e2521" class="flex-paragraph"><img src="images/ESS_SoH_video.gif" id="d0e2522" class="flex-image"></p>
                        </p>
                        <p></p>
                     </div>
                     <ol id="d0e2526" class="flex-list flex-class-list-ordered">
                        <li id="d0e2527" class="flex-listitem">
                           <p id="d0e2528" class="flex-paragraph">Click on a product.</p>
                        </li>
                        <li id="d0e2532" class="flex-listitem">
                           <p id="d0e2533" class="flex-paragraph">In the right pane, go to <span id="d0e2535" class="flex-bold">State of health</span>.
                           </p>
                           <p id="d0e2540" class="flex-paragraph">The scatter plot shows the following data points. Mouse hover on a data point to view the details.</p>
                           <ul id="d0e2543" class="flex-list flex-class-list-unordered">
                              <li id="d0e2544" class="flex-listitem">
                                 <p id="d0e2545" class="flex-paragraph"><span id="d0e2546" class="flex-bold">Designed SoH</span></p>
                              </li>
                              <li id="d0e2551" class="flex-listitem">
                                 <p id="d0e2552" class="flex-paragraph"><span id="d0e2553" class="flex-bold">Estimated SoH</span></p>
                              </li>
                              <li id="d0e2558" class="flex-listitem">
                                 <p id="d0e2559" class="flex-paragraph"><span id="d0e2560" class="flex-bold">Missing Data</span></p>
                              </li>
                              <li id="d0e2565" class="flex-listitem">
                                 <p id="d0e2566" class="flex-paragraph"><span id="d0e2567" class="flex-bold">State of operation</span></p>
                              </li>
                              <li id="d0e2572" class="flex-listitem">
                                 <p id="d0e2573" class="flex-paragraph"><span id="d0e2574" class="flex-bold">Current operation</span></p>
                              </li>
                           </ul>
                           <p id="d0e2580" class="flex-paragraph">In the plot, x-axis represents the SoH designed lifetime in date format and y-axis represents the SoH capacity after designed
                              lifetime (%).
                           </p>
                        </li>
                        <li id="d0e2584" class="flex-listitem">
                           <p id="d0e2585" class="flex-paragraph">Next to the plot in the Chart context menu, click <span id="d0e2587" class="flex-bold">Print chart </span>to print or download the chart as image format.
                           </p>
                        </li>
                        <li id="d0e2593" class="flex-listitem">
                           <p id="d0e2594" class="flex-paragraph">Below the Chart content menu, click the info button to view the SoH calculation.</p>
                        </li>
                     </ol>
                  </div>
    `;
    } else {
      iframeItem.contentWindow.document.getElementById(
        'state_of_health'
      ).innerHTML = ``;
    }
    setisLoading(false);
  }

  return (
    <iframe
      title="help"
      ref={iframeRef}
      src={iframeurl}
      style={{
        width: '100%',
        height: window.innerHeight - 120,
        border: 0,
        opacity: isLoading ? 0 : 1,
      }}
      onLoad={handleIframe}></iframe>
  );
}
