import { fromJS } from 'immutable';
import {
  FETCH_EMAILALERT_DATA,
  FETCH_EMAILALERT_DATA_FAILURE,
  FETCH_EMAILALERT_DATA_SUCCESS,
  SAVE_EMAILALERT_DATA,
  SAVE_EMAILALERT_DATA_FAILURE,
  SAVE_EMAILALERT_DATA_SUCCESS,
} from './constants';

const initialState = fromJS({
  loadingEmailalert: false,
  emailalertData: null,
  loadingEmailalertSave: false,
  emailalertSaveData: null,
  error: null,
});

export function reducerEmailalert(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAILALERT_DATA:
      return state.set('loadingEmailalert', true).set('error', null);
    case FETCH_EMAILALERT_DATA_SUCCESS:
      return state
        .set('loadingEmailalert', false)
        .set('emailalertData', action.emailalertData);
    case FETCH_EMAILALERT_DATA_FAILURE:
      return state
        .set('loadingEmailalert', false)
        .set('emailalertData', null)
        .set('error', action.error);

    case SAVE_EMAILALERT_DATA:
      return state
        .set('loadingEmailalertSave', true)
        .set('error', null)
        .set('emailalertSaveData', null);
    case SAVE_EMAILALERT_DATA_SUCCESS:
      return state
        .set('loadingEmailalertSave', false)
        .set('emailalertSaveData', action.saveEmailalertResponce[0].msg);
    case SAVE_EMAILALERT_DATA_FAILURE:
      return state
        .set('loadingEmailalertSave', false)
        .set('emailalertSaveData', null)
        .set('error', action.error);

    default:
      return state;
  }
}
