import React from 'react';
import BTable from 'react-bootstrap/Table';
import {
  useExpanded,
  useGroupBy,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import './styles.scss';

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
      },
      useGroupBy,
      useSortBy,
      useExpanded,
      usePagination
    );

  return (
    <>
      <BTable responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()} d>
              {headerGroup.headers.map((column, jdx) => (
                <th
                  key={jdx}
                  style={{ textAlign: 'center' }}
                  {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => (
                  <td style={{ textAlign: 'center' }} key={j}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
};

export default Table;
