import Moment from 'moment';
import { createSelector } from 'reselect';

const dashboardData = () => state => state.get('dashboardData');

const loadingSelector = () =>
  createSelector(dashboardData(), state => state.get('loading'));

const loadingMapSelector = () =>
  createSelector(dashboardData(), state => state.get('loadingMap'));

const loadingFilterSelector = () =>
  createSelector(dashboardData(), state => state.get('loadingFilter'));

const loadingMonitoredCountSelector = () =>
  createSelector(dashboardData(), state => state.get('loadingMonitoredCount'));

const selectedTrainSelector = () =>
  createSelector(dashboardData(), state => state.get('selectedTrain'));

const mapDataSelector = () =>
  createSelector(dashboardData(), state => state.get('mapData'));

const fleetDataSelector = () =>
  createSelector(dashboardData(), state => state.get('fleetData'));
const allFleetDataSelector = () =>
  createSelector(dashboardData(), state => state.get('allFleetData'));

const loadingUserDataSelector = () =>
  createSelector(dashboardData(), state => state.get('loadingUserData'));

const userDataSelector = () =>
  createSelector(dashboardData(), state => state.get('userData'));

const filterDataSelector = () =>
  createSelector(dashboardData(), state => state.get('filterData'));

const monitoredCountDataSelector = () =>
  createSelector(dashboardData(), state => state.get('monitoredCountData'));

const startDateSelector = () =>
  createSelector(dashboardData(), state => {
    const startDate = state.get('startDate');
    let formatedStartDate = null;
    if (startDate != null) {
      formatedStartDate = Moment(startDate)
        .format('YYYY-MM-DDTHH:mm:ss')
        .replace(/:/g, '%3A');
    }
    return formatedStartDate;
  });

const endDateSelector = () =>
  createSelector(dashboardData(), state => {
    const endDate = state.get('endDate');
    let formatedEndDate = null;
    if (endDate != null) {
      formatedEndDate = Moment(endDate)
        .format('YYYY-MM-DDTHH:mm:ss')
        .replace(/:/g, '%3A');
    }
    return formatedEndDate;
  });

const filerSiteList = () =>
  createSelector(fleetDataSelector(), object => {
    const filterSiteList = [];
    if (object !== null) {
      object.tempData.forEach(fleet => {
        filterSiteList.push(fleet.objectType);
      });
    }
    return filterSiteList;
  });

const userGroupSelector = () =>
  createSelector(dashboardData(), state => state.get('userGroup'));

export {
  allFleetDataSelector,
  endDateSelector,
  filerSiteList,
  filterDataSelector,
  fleetDataSelector,
  loadingFilterSelector,
  loadingMapSelector,
  loadingMonitoredCountSelector,
  loadingSelector,
  loadingUserDataSelector,
  mapDataSelector,
  monitoredCountDataSelector,
  selectedTrainSelector,
  startDateSelector,
  userDataSelector,
  userGroupSelector,
};
