import { fromJS } from 'immutable';
import {
  FETCH_ACTIVE_MESSAGES_SUCCESS,
  FETCH_ALL_BROADCAST_MESSAGE_SUCCESS,
} from './constants';

const initialState = fromJS({
  allBroadcastMessages: [],
});

export function MessageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_BROADCAST_MESSAGE_SUCCESS:
      return state.set('allBroadcastMessages', action.payload.data);
    case FETCH_ACTIVE_MESSAGES_SUCCESS:
      return state.set('activeMessages', action.payload.data);
    default:
      return state;
  }
}
