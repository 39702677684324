import React from 'react';
import BTable from 'react-bootstrap/Table';
import { useExpanded, useTable } from 'react-table';
function MessageTable({ data, columns }) {
  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useExpanded
    );
  if (!data[0]) {
    return <div className="message_text">Loading...</div>;
  }
  if (data.length === 0)
    return <div className="message_text">No messages to display</div>;
  return (
    <div>
      <BTable responsive {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="message_header_label">
                    {header.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr {...row.getRowProps()} className="message_text">
                  {row.cells.map((cell, idx) => {
                    return (
                      <td key={idx} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td
                      className="message_expanded message_text"
                      colSpan={columns.length}>
                      {row.original.message}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </BTable>
    </div>
  );
}

export default MessageTable;
