import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';
import {
  fetchExcelReportDataFailure,
  fetchExcelReportDataSuccess,
  fetchExcelReportEmailSuccess,
} from './actions';
import { FETCH_EXCELREPORT_DATA, FETCH_EXCELREPORT_EMAIL } from './constants';

export function* fetchExcelReportSaga() {
  yield takeLatest(FETCH_EXCELREPORT_DATA, fetchingExcelReportSaga);
}
export function* fetchExcelReportEmailSaga() {
  yield takeLatest(FETCH_EXCELREPORT_EMAIL, fetchingExcelReportSaga);
}
export function* fetchingExcelReportSaga(action) {
  try {
    const { fleetname, startDate, endDate, fleetReportOptions } =
      action.payload;
    const response = yield call(
      api.fetchExcelReportData,
      fleetname,
      startDate,
      endDate,
      fleetReportOptions
    );
    if (response.dc) {
      const excelReportData = response.dc;
      yield put(fetchExcelReportDataSuccess(excelReportData));
    } else {
      const excelReportEmail = response;
      yield put(fetchExcelReportEmailSuccess(excelReportEmail));
    }
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchExcelReportDataFailure));
  }
}
