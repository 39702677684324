import Moment from 'moment';
import React from 'react';
import { Alert, Button, Col, Modal } from 'react-bootstrap';

import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
import Loading from '../Loading';

require('./styles.scss');

Moment.locale('en');

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable
        handle=".modal-content"
        enableUserSelectHack={false}
        bounds={'main'}
        // defaultPosition={{ x: 450, y: 40 }}
      >
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

function EventUndoPopup(props) {
  const [visible, setVisible] = React.useState(false);

  const onConfirm = e => {
    e.preventDefault();
    props.handleUndoEventSubmit(props.clickedObj);
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 9000);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogAs={DraggableModalDialog}
      autoFocus={false}
      enforceFocus={false}>
      {props.undoEventResponce !== null && visible === true ? (
        <Col
          md={12}
          className=" noLeftpadding noRightpadding sucessmsg Undo-popup-con">
          <Alert
            variant={
              props.undoEventResponce[0].sts === false ? 'danger' : 'success'
            }
            className="undoresponcemsg">
            <p className="nodatanotificationcontent">
              {props.undoEventResponce[0].msg}
            </p>
          </Alert>
        </Col>
      ) : (
        ''
      )}
      {props.loadingUndoEvent === true ? (
        <div className="Undo-popup-con">
          <Loading loadintText="Please wait..." />
        </div>
      ) : props.undoEventResponce === null ? (
        <div className="Undo-popup-con">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="undo-title"> Are you sure? </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="undopop-up">
            <div className="">Changes will be reverted! Please confirm.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="smallSilverButton btn  btn-sm"
              onClick={props.onHide}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="smallButton btn btn-primary btn-sm"
              onClick={onConfirm}>
              Confirm
            </Button>
          </Modal.Footer>
        </div>
      ) : (
        ''
      )}
    </Modal>
  );
}

export default EventUndoPopup;
