import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import './styles.scss';
class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
  }

  render() {
    return (
      <Form.Group data-intro="search" as={Col} sm={10} controlId="searchbox">
        <Form.Label className="hidden">Search with Keywords</Form.Label>
        <Form.Control
          className="searchall"
          required
          autoComplete="off"
          type="search"
          placeholder="Search with keywords"
          name="searchall"
          onChange={e => {
            e.preventDefault();
            this.props.handleSearchInputChange(e.target.value.trim());
          }}
          // ref={this.searchRef}
          value={this.props.query}
          disabled={this.props.enableSearch}
        />
      </Form.Group>
    );
  }
}

export default SearchBox;
