import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import 'react-widgets/scss/styles.scss';
import { createStructuredSelector } from 'reselect';
import { checkUser, setUserGroup } from './containers/DashBoard/actions';
import {
  loadingUserDataSelector,
  userDataSelector,
} from './containers/DashBoard/selectors';
import { envConstant } from './envConstant';
import Routes from './router.js';
import './stylesheets/main.scss';

import Loading from './components/Loading';

import { authService } from './AuthService';
import {
  checkUserEntitlements,
  directlinktrendSignalCheck,
} from './userConfig';
import apiConfig from './utils/api-config';
import { pingCheck } from './utils/ping-check-util';
/* eslint-disable import/no-unresolved  */
/* eslint-disable import/no-webpack-loader-syntax */
import '!file-loader?name=[name].[ext]!./web.config';
import CustomToaster from './components/CustomToaster/CustomToaster.jsx';
import Unauthorized from './components/Unauthorized/Unauthorized';
import history from './history.js';
import api from './utils/api.js';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null, // {},
      activeTab: 0,
      checkProfile: false,
      loginCheck: false,
      loaderStatus: false,
      apiStatus: true,
      checkSafeModeStatus: false,
      safeModeEnabled: false,
      loaderMessage:
        'Sorry! The server has an issue. Please refresh or try to visit the portal later.',
    };
  }

  componentDidMount() {
    // check for the api healthcheck and show status accordingly
    pingCheck(`${apiConfig.baseUrl}/api/HealthCheck`)
      .then(response => {
        if (response) {
          const status = JSON.parse(response).payload.status;
          if (status) {
            this.setState({ loaderStatus: false, apiStatus: true });
          } else {
            this.setState({
              apiStatus: false,
              checkProfile: false,
              user: null,
              loginCheck: false,
              loaderStatus: true,
            });
            this.setState({
              loaderMessage:
                'Internal service could not be contacted. Please refresh or try to visit the portal later.',
            });
          }
        }
      })
      .catch(() => {
        this.setState({
          apiStatus: false,
          checkProfile: false,
          user: null,
          loginCheck: false,
          loaderStatus: true,
        });
        this.setState({
          loaderMessage:
            'Internal service could not be contacted. Please refresh or try to visit the portal later.',
        });
      });
    pingCheck(`${envConstant.stsAuthority}.well-known/openid-configuration`)
      .then(response => {
        if (response) {
          this.setState({ loaderStatus: false, loginCheck: true });
        }
      })
      .catch(() => {
        this.setState({
          apiStatus: false,
          loaderStatus: true,
        });
        this.setState({
          loaderMessage:
            'Authentication server could not be contacted. Please refresh or try to visit the portal later.',
        });
      });
    pingCheck(`${apiConfig.baseUrl}/api/IsSafeModeEnabled`)
      .then(response => {
        if (response) {
          const { message, enabled } = JSON.parse(response).payload;
          if (enabled) {
            setTimeout(() => {
              this.setState({
                loaderStatus: true,
                loaderMessage: message,
                checkSafeModeStatus: true,
                safeModeEnabled: !checkUserEntitlements('ESS:Safe-Mode'),
              });
            }, 0);
          } else {
            this.setState({
              loaderStatus: false,
              safeModeEnabled: false,
              checkSafeModeStatus: false,
            });
          }
        }
      })
      .catch(() => {
        this.setState({
          apiStatus: false,
          checkProfile: false,
          user: null,
          loginCheck: false,
          loaderStatus: true,
          safeModeEnabled: false,
        });
        this.setState({
          loaderMessage:
            'Internal service could not be contacted. Please refresh or try to visit the portal later.',
        });
      });

    const urlLink = window.location.href;

    if (urlLink.indexOf('?') > -1) {
      if (sessionStorage.getItem('selectedURL') === null) {
        sessionStorage.setItem('selectedURL', urlLink);
      }
    }
    if (this.state.apiStatus === true && this.state.loaderStatus === false) {
      this.getUser();
    }
  }

  getUser = async () => {
    authService.getUser().then(user => {
      if (user) {
        sessionStorage.setItem('id_token', user.id_token);
        sessionStorage.setItem('access_token', user.access_token);
        sessionStorage.setItem('profile', JSON.stringify(user.profile));
        this.setState({ loginCheck: true });
        const urlLink = window.location.href;
        const url = new URL(urlLink);
        let fleetName;
        let testValue = false;
        let ttmain_signal;
        let ttoverlay_signal;
        let usage_tab;
        let product_tab;
        let fleetstatistics_tab;

        if (urlLink.indexOf('?') > -1) {
          fleetName = url.searchParams.get('ft');
          if (fleetName !== null) {
            api.getAssetGroup(user.access_token).then(assetGroups => {
              if (
                assetGroups?.filter(
                  item =>
                    item.fleetName === fleetName || item.fleetName === 'All'
                ).length >= 0
              ) {
                testValue = true;
              } else {
                console.log('from app access denied');
                history.push('/access-denied');
              }
            });

            fleetstatistics_tab = url.searchParams.get('tb');
            if (fleetstatistics_tab != null) {
              if (fleetstatistics_tab === 'fleet-fleetstatistic') {
                testValue = user.profile.entitlements.includes(
                  'ESS:Fleet-Statistics'
                );
              }
            }

            product_tab = url.searchParams.get('tb');

            if (product_tab != null) {
              if (product_tab === 'Usage') {
                testValue =
                  user.profile.entitlements.includes('ESS:Product-Usage');
                // compare usage subtabs entitlements if usage main entitlement is present
                if (testValue) {
                  usage_tab = url.searchParams.get('utb');
                  if (usage_tab !== null) {
                    let tabValue;
                    if (usage_tab === 'usgoh')
                      tabValue = 'ESS:Product-Usage-OH';
                    if (usage_tab === 'usgtemp')
                      tabValue = 'ESS:Product-Usage-Temperature';
                    if (usage_tab === 'usgsoc')
                      tabValue = 'ESS:Product-Usage-Soc';
                    if (usage_tab === 'usgpow')
                      tabValue = 'ESS:Product-Usage-Power';
                    if (usage_tab === 'usgdod')
                      tabValue = 'ESS:Product-Usage-DOD';
                    testValue = user.profile.entitlements.includes(tabValue);
                  }
                }
              }
              //  SOH entitlement is present for direct link check
              if (product_tab === 'SOH-vehicleprod-view') {
                testValue =
                  user.profile.entitlements.includes('ESS:Product-SOH');
              }
            }

            ttmain_signal = url.searchParams.get('ttm');
            ttoverlay_signal = url.searchParams.get('tto');
            if (ttmain_signal !== null) {
              testValue = directlinktrendSignalCheck(ttmain_signal);
            }
            if (testValue === true && ttoverlay_signal !== null) {
              ttoverlay_signal = ttoverlay_signal.split(',');
              for (let i = 0; i < ttoverlay_signal.length; i++) {
                testValue = directlinktrendSignalCheck(ttoverlay_signal[i]);
                if (testValue === false) break;
              }
            }

            if (testValue === false) {
              this.setState({ loginCheck: false });
            }
          }
        }

        if (urlLink.indexOf('/FleetConfiguration') > -1) {
          !checkUserEntitlements('ESS:common-Fleetsetuppage') &&
            this.setState({ loginCheck: false });
        }
        this.setState({ user });
        window.$userGroup = user.profile.groups;
      } else {
        authService.login();
      }
    });
  };

  render() {
    if (
      this.state.apiStatus &&
      !this.state.safeModeEnabled &&
      this.state.user !== null &&
      this.state.user.access_token.length > 0
    ) {
      if (this.state.loginCheck === true) {
        return (
          <div className="basebackground">
            <CustomToaster />
            <main role="main" className="flex-shrink-0">
              <Container fluid style={{ paddingTop: 60 }}>
                <Routes checkSafeModeStatus={this.state.checkSafeModeStatus} />
              </Container>
            </main>
          </div>
        );
      } else {
        return setTimeout(() => {
          return <Unauthorized />;
        }, 5000);
      }
    } else {
      return (
        <Loading
          loadintText="Loading data"
          status={this.state.loaderStatus}
          message={this.state.loaderMessage}
          apiStatus={this.state.apiStatus}
          safeModeEnabled={this.state.safeModeEnabled}
        />
      );
    }
  }
}

const mapStateToProps = createStructuredSelector({
  userData: userDataSelector(),
  loadingUserData: loadingUserDataSelector(),
});

const mapDispatchToProps = {
  checkUser,
  setUserGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
