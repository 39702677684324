import React from 'react';
import { Col, Nav, NavDropdown } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getEmergencyStatus } from '../../containers/SafeMode/actions';
import { emergencyModeStatusSelector } from '../../containers/SafeMode/selectors';
import {
  checkUserEntitlements,
  userConfig as configUser,
} from '../../userConfig';
import Pulse from '../Pulse/Pulse';

import {
  AbbLogo,
  BellIcon,
  BroadcastMessageIcon,
  FeedbackIcon,
  FleetSetupIcon,
  HelpIcon,
  LockIcon,
  LogoutIcon,
  MenuIcon,
  RedCircleIcon,
  ReportIcon,
  ShareIcon,
  UserGuideIcon,
  UserIcon,
} from '../../assets/images';
import { allFleetDataSelector } from '../../containers/DashBoard/selectors';
import history from '../../history';
import { copyToClipboard } from '../../utils/clipboard';
import Steps from '../IntroJs/Steps';
import UserGuide from '../UserGuide/UserGuide';
import './Header.scss';
class Header extends React.Component {
  constructor(...args) {
    super(...args);
    configUser();
    this.props.fetchEmergencyStatus();
    this.state = {
      showUserGuide: false,
      enableSteps: false,
      initialStep: 0,
      setStepChange: () => {},
      steps: [],
      options: {},
    };
  }

  handleStepChange = step => this.state.setStepChange(step);
  onCopyClick = () => {
    const text = window.location.href;
    copyToClipboard(
      text,
      'URL copied to clipboard',
      'Failed to copy URL to clipboard'
    );
  };

  handleShowGuide = () => {
    history.push('/Dashboard');
    this.setState({ showUserGuide: !this.state.showUserGuide });
  };

  handleEndGuide = () => {
    this.setState({ enableSteps: false });
  };

  handleStartGuide = (steps, stepChange) => {
    this.setState({
      steps,
      setStepChange: stepChange,
    });
    this.setState({ enableSteps: true });
  };

  render() {
    return (
      <React.Fragment>
        <UserGuide
          show={this.state.showUserGuide}
          handleClose={this.handleShowGuide}
          handleStartGuide={this.handleStartGuide}
          fleetData={this.props.fleetData}
        />
        <Steps
          enabled={this.state.enableSteps}
          steps={this.state.steps}
          initialStep={this.state.initialStep}
          onExit={() => this.handleEndGuide()}
          options={this.state.options}
          onChange={this.handleStepChange}
        />
        <Navbar fixed="top">
          <Navbar.Brand onClick={this.props.redirectToHome}>
            <Col className="nav-logo">
              <div>
                <AbbLogo className={'abblogo'} alt="logo" />
              </div>
            </Col>
          </Navbar.Brand>
          <Navbar.Brand onClick={this.props.redirectToHome}>
            <Col className="nav-logo">
              <div>
                <p className="site-name" data-intro="site-name">
                  Condition Monitoring for Traction
                </p>
              </div>
            </Col>
          </Navbar.Brand>
          <Nav className="ml-auto">
            {checkUserEntitlements('ESS:Safe-Mode') &&
              this.props.emergencyModeStatus &&
              this.props.emergencyModeStatus.enabled && (
                <Nav.Item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '20px',
                  }}>
                  <Pulse />
                </Nav.Item>
              )}
            <Nav.Item>
              <Nav.Link>
                <UserIcon
                  className="header-menu-icon"
                  alt="User"
                  title={this.props.userEmail && this.props.userEmail}
                />
                <span className="username">
                  {this.props.userEmail && this.props.userEmail}
                </span>
              </Nav.Link>
            </Nav.Item>
            {checkUserEntitlements('ESS:common-Directlink') && (
              <Nav.Item onClick={this.onCopyClick}>
                <Nav.Link>
                  <ShareIcon
                    alt="Share"
                    title="Copy to clipboard"
                    className="image-icon"
                  />
                </Nav.Link>
              </Nav.Item>
            )}
            {checkUserEntitlements('ESS:Fleet-Report') && (
              <Nav.Item onClick={this.props.redirectToReportpage}>
                <Nav.Link>
                  <ReportIcon
                    alt="Report"
                    title="Report"
                    className="image-icon"
                  />
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link onClick={this.props.showMessages}>
                <div>
                  <BellIcon
                    alt="broadcast messages"
                    title="Broadcast Messages"
                    className="image-icon"
                  />
                  {this.props.messageCount && this.props.messageCount === 1 ? (
                    <RedCircleIcon
                      style={{
                        position: 'relative',
                        top: '-5px',
                        left: '-10px',
                      }}
                      alt="broadcast messages"
                      title="Broadcast Messages"
                    />
                  ) : (
                    ''
                  )}
                  {this.props.messageCount && this.props.messageCount > 1 ? (
                    <span
                      style={{
                        position: 'relative',
                        top: '-5px',
                        left: '-10px',
                        borderRadius: '50%',
                        backgroundColor: 'red',
                        color: 'white',
                        padding: '1px 5px',
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                      alt="broadcast messages"
                      title="Broadcast Messages">
                      {this.props.messageCount}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Nav.Link>
            </Nav.Item>

            {checkUserEntitlements('ESS:common-Menu') && (
              <Nav.Item>
                <Nav.Link className="helpmenu">
                  <NavDropdown
                    id="help"
                    style={{ borderRadius: '8px' }}
                    title={<MenuIcon />}>
                    <div>
                      <NavDropdown.Item
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/helpdoc/`, // ?utm_source=Online_help&utm_medium=help&utm_campaign=online_help
                            '_blank'
                          )
                        }>
                        <HelpIcon className="marginRight" />
                        Help
                      </NavDropdown.Item>
                      {checkUserEntitlements('ESS:User-Guide') && (
                        <NavDropdown.Item
                          onClick={() => this.handleShowGuide()}>
                          <UserGuideIcon className="marginRight" />
                          User Guide
                        </NavDropdown.Item>
                      )}
                      <NavDropdown.Item
                        onClick={() =>
                          window.open(
                            `https://forms.office.com/e/tXftUSy813`,
                            '_blank'
                          )
                        }>
                        <FeedbackIcon className="marginRight" />
                        Feedback
                      </NavDropdown.Item>
                      {checkUserEntitlements('ESS:common-Fleetsetuppage') && (
                        <NavDropdown.Item
                          className="dropdown-item "
                          onClick={this.props.redirectToFleetSetup}>
                          <FleetSetupIcon className="marginRight" />
                          Fleet Setup
                        </NavDropdown.Item>
                      )}
                      {checkUserEntitlements(
                        'ESS:common-broadcast-message'
                      ) && (
                        <NavDropdown.Item
                          className="dropdown-item "
                          onClick={this.props.redirectToBroadcast}>
                          <BroadcastMessageIcon className="marginRight" />
                          Broadcast
                        </NavDropdown.Item>
                      )}
                      {checkUserEntitlements('ESS:Safe-Mode') && (
                        <NavDropdown.Item
                          className="dropdown-item btn_emergency"
                          onClick={this.props.redirectToSafeMode}>
                          <LockIcon className="marginRight" />
                          Safe Mode
                        </NavDropdown.Item>
                      )}

                      <NavDropdown.Item onClick={this.props.handleLogoutClick}>
                        <LogoutIcon className="marginRight" />
                        Logout
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Navbar>
      </React.Fragment>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  emergencyModeStatus: emergencyModeStatusSelector(),
  fleetData: allFleetDataSelector(),
});
const mapDispatchToProps = {
  fetchEmergencyStatus: getEmergencyStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
