import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Error404 } from '../../assets/images';
import './ErrorBoundary.scss';
import { setErrorStatus } from './actions';
import { errorStatusCheckSelector } from './selectors';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.props.setErrorStatus(true);
  }

  backToHome = () => {
    this.props.setErrorStatus(false);
    window.history.pushState(null, null, '/Dashboard');
  };

  render() {
    if (this.props.hasError) {
      return (
        <div className="center-aligned">
          <Error404 alt="404" />
          <h1>Oops!Something went wrong.</h1>
          <h6>
            We apologize for the inconvenience. Please refresh the page and try
            again. If the problem persists, contact your administrator. If
            you&apos;d like to return to the home page, you can click
            <button className="btn btn-link" onClick={this.backToHome}>
              here.
            </button>
          </h6>
        </div>
      );
    }

    return this.props.children;
  }
}
const mapStateToProps = createStructuredSelector({
  hasError: errorStatusCheckSelector(),
});
const mapDispatchToProps = {
  setErrorStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
