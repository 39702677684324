export const setCookie = value => {
  let url = '.' + window.location.hostname;
  if (process.env.REACT_APP_ESS_API === 'development') {
    url = window.location.hostname;
  }
  if (value === '_ga=;') {
    document.cookie =
      value +
      'expires=Thu, 01 Jan 1970 00:00:01 GMT;Path=/;Domain=' +
      url +
      ';';
    document.cookie =
      '_ga_DH974L8DDJ=;expires=Thu, 01 Jan 1970 00:00:01 GMT;Path=/;Domain=' +
      url +
      ';';
  } else if (value === '_pref=;') {
    document.cookie =
      'toggleOverAllInfo=;expires=Thu, 01 Jan 1970 00:00:01 GMT;Path=/;Domain=' +
      url +
      ';';
  } else {
    document.cookie =
      value +
      ';expires=Tue, 21 Jan 2038 03:14:07 GMT;Path=/;Domain=' +
      url +
      ';';
  }
};

export const getCookie = cname => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
