import { toast } from 'react-hot-toast';
/**
 * copyToClipboard
 * @description Copy text to clipboard
 * and show toast message(react-hot-toast) on success or failure
 * @param {string} copyText
 * @param {string} successMsg
 * @param {string} failMsg
 * @returns {void}
 */

export const copyToClipboard = (copyText, successMsg, failMsg) => {
  // eslint-disable-next-line compat/compat
  window.navigator.clipboard.writeText(copyText).then(
    () => toast.success(successMsg),
    () => toast.error(failMsg)
  );
};
