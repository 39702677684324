import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';
import {
  fetchEmailalertDataFailure,
  fetchEmailalertDataSucess,
  saveEmailalertFailure,
  saveEmailalertSucess,
} from './actions';
import { FETCH_EMAILALERT_DATA, SAVE_EMAILALERT_DATA } from './constants';

export function* fetchEmailAlertSettingSaga() {
  yield takeLatest(FETCH_EMAILALERT_DATA, fetchingEmailAlertSettingSaga);
}
export function* fetchingEmailAlertSettingSaga(action) {
  try {
    const { fleetName } = action.payload;
    const response = yield call(api.fetchEmailalertSettingsData, fleetName);
    const emailalertSettingData = response.dc;
    yield put(fetchEmailalertDataSucess(emailalertSettingData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchEmailalertDataFailure));
  }
}

//  for save Emailalert settings
export function* saveEmailalertSettingsSaga() {
  yield takeLatest(SAVE_EMAILALERT_DATA, savingEmailalertSettingsSaga);
}
export function* savingEmailalertSettingsSaga(action) {
  try {
    const {
      essName,
      additionalInfo,
      alertType,
      alertFrequency,
      alertValidity,
      status,
      un,
      actions,
    } = action.payload;
    const response = yield call(
      api.saveEmailalertSettings,
      essName,
      additionalInfo,
      alertType,
      alertFrequency,
      alertValidity,
      status,
      un,
      actions
    );

    const saveEmailalertResponce = response.dc;
    yield put(saveEmailalertSucess(saveEmailalertResponce));
  } catch (error) {
    yield put(handleError(error, saveEmailalertFailure));
  }
}
