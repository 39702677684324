import { all } from 'redux-saga/effects';

import {
  checkUserSaga,
  fetachMapDataSaga,
  fetchAllFleetSaga,
  fetchFilterSaga,
  fetchFleetSaga,
  fetchMonitoredCountSaga,
} from '../containers/DashBoard/sagas';
import {
  fetchEmailAlertSettingSaga,
  saveEmailalertSettingsSaga,
} from '../containers/EmailAlertView/sagas';
import {
  getAllEventListSaga,
  getEventListSaga,
  getVEventListSaga,
  saveEventSaga,
  undoEventSaga,
} from '../containers/Events/sagas';
import {
  getFleetDataSaga,
  getPrdtInfoSetupValuesSaga,
  getPrdtSetupValuesSaga,
  getSetupFeetListSaga,
  getSetupSidemenuListSaga,
  getSetupSiteListSaga,
  getUsedFleetNameListSaga,
  getValidateFleetDataPathSaga,
  getVehicleSetupValuesSaga,
  saveFleetSaga,
  saveProductInfoSetupSaga,
  saveProductSetupSaga,
  saveSiteSaga,
  saveVehicleSetupSaga,
} from '../containers/FleetSetup/sagas';
import {
  fetachBoxPlotDataSaga,
  fetchDiagnosticListSaga,
  fetchFleetStatisticsSaga,
  fetchFleetsListSaga,
  fetchHistogramSaga,
  fetchModuleListSaga,
  fetchOverallConditionSaga,
  fetchOverviewSaga,
  fetchUnmountedESSListSaga,
  fetchVehicleProductListSaga,
  fetchVehiclesListSaga,
} from '../containers/FleetView/sagas';
import {
  getEmergencyStatusSaga,
  setEmergencyKeySaga,
  setEmergencyStatusSaga,
} from '../containers/SafeMode/sagas';
import {
  fetchBatteryOverallConditionSaga,
  fetchBatteryOverviewSaga,
  fetchBatteryRelSaga,
  fetchBatterySOCRangeSaga,
  fetchBatteryStateOfChargeSaga,
  fetchBatteryStrSaga,
  fetchCellVoltageSaga,
  fetchOperatingHoursHistogramSaga,
  fetchSOHSaga,
  fetchSyncGraphSaga,
  fetchTemperatureHistogramSaga,
  fetchUsgDodHeatmapSaga,
  fetchUsgDodHistogramSaga,
  fetchUsgPowHeatmapSaga,
  fetchUsgPowHistogramSaga,
  fetchUsgSocHeatmapSaga,
  fetchUsgSocHistogramSaga,
  fetchUsgTempHeatmapSaga,
  getDetailViewTimetrendSaga,
} from '../containers/VehicleProductsView/sagas';
import {
  fetchVehicleAvailSaga,
  fetchVehicleDetailsSaga,
  fetchVehicleRelSaga,
  fetchVehicleStrSaga,
} from '../containers/VehicleView/sagas';

import {
  createBroadcastMessageSaga,
  deleteBroadcastMessageSaga,
  fetchActiveBroadcastMessagesSaga,
  fetchAllBroadcastMessageSaga,
  updateBroadcastMessageSaga,
} from '../containers/Broadcast/sagas';

import {
  fetchExcelReportEmailSaga,
  fetchExcelReportSaga,
} from '../containers/ReportView/sagas';

export function* rootSaga() {
  yield all([
    fetchAllFleetSaga(),
    fetchFleetSaga(),
    fetachMapDataSaga(),
    fetchFilterSaga(),
    fetchHistogramSaga(),
    fetachBoxPlotDataSaga(),
    fetchOverviewSaga(),
    fetchOverallConditionSaga(),
    fetchFleetsListSaga(),
    fetchVehiclesListSaga(),
    fetchFleetStatisticsSaga(),
    fetchMonitoredCountSaga(),
    fetchVehicleProductListSaga(),
    fetchModuleListSaga(),
    fetchCellVoltageSaga(),
    fetchBatteryOverallConditionSaga(),
    fetchBatteryStateOfChargeSaga(),
    fetchBatterySOCRangeSaga(),
    fetchBatteryOverviewSaga(),
    fetchTemperatureHistogramSaga(),
    fetchUsgSocHistogramSaga(),
    fetchUsgPowHistogramSaga(),
    fetchUsgDodHistogramSaga(),
    fetchUsgDodHeatmapSaga(),
    fetchUsgPowHeatmapSaga(),
    fetchUsgSocHeatmapSaga(),
    fetchUsgTempHeatmapSaga(),
    fetchOperatingHoursHistogramSaga(),
    checkUserSaga(),
    fetchSyncGraphSaga(),
    getDetailViewTimetrendSaga(),
    saveSiteSaga(),
    saveFleetSaga(),
    getSetupSiteListSaga(),
    getSetupFeetListSaga(),
    getEventListSaga(),
    fetchBatteryRelSaga(),
    fetchBatteryStrSaga(),
    fetchVehicleAvailSaga(),
    fetchVehicleRelSaga(),
    fetchVehicleStrSaga(),
    saveEventSaga(),
    getVEventListSaga(),
    getAllEventListSaga(),
    undoEventSaga(),
    fetchUnmountedESSListSaga(),
    saveProductSetupSaga(),
    saveProductInfoSetupSaga(),
    getPrdtSetupValuesSaga(),
    fetchEmailAlertSettingSaga(),
    saveEmailalertSettingsSaga(),
    getPrdtInfoSetupValuesSaga(),
    getVehicleSetupValuesSaga(),
    saveVehicleSetupSaga(),
    getSetupSidemenuListSaga(),
    getUsedFleetNameListSaga(),
    fetchSOHSaga(),
    getValidateFleetDataPathSaga(),
    getFleetDataSaga(),
    fetchDiagnosticListSaga(),
    fetchVehicleDetailsSaga(),
    getEmergencyStatusSaga(),
    setEmergencyStatusSaga(),
    setEmergencyKeySaga(),
    createBroadcastMessageSaga(),
    fetchAllBroadcastMessageSaga(),
    deleteBroadcastMessageSaga(),
    fetchActiveBroadcastMessagesSaga(),
    updateBroadcastMessageSaga(),
    fetchExcelReportSaga(),
    fetchExcelReportEmailSaga(),
  ]);
}
