import React from 'react';
import { AbbLogo, Maintainancemode } from '../../assets/images';

function EmergencyStatus({ message }) {
  return (
    <div className="serviceNotAvailable">
      <div className="header">
        <div>
          <AbbLogo alt="ABB" />
        </div>
        <div className="bar">|</div>
        <div className="title">Condition monitoring for ESS</div>
      </div>
      <div className="content">
        <Maintainancemode alt="ABB" />
        <div className="message">{message}</div>
        <button className="refresh" onClick={() => window.location.reload()}>
          Refresh
        </button>
      </div>
    </div>
  );
}

export default EmergencyStatus;
