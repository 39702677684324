import React from 'react';
import { Modal } from 'react-bootstrap';

function Confirmation({ show, handleClose, handleAction, action }) {
  const proptMessage = new Map([
    ['delete', 'delete'],
    ['enable', 'enable'],
    ['disable', 'disable'],
  ]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure ?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-xl">
        <h6>You are about to {proptMessage.get(action)} this message.</h6>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary rounded-0" onClick={handleClose}>
          Close
        </button>
        <button
          className="btn btn-danger rounded-0"
          onClick={() => handleAction()}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default Confirmation;
