import { fromJS } from 'immutable';
import { HAS_ERROR_CATCH } from './constants';

const initialState = fromJS({
  hasError: false,
});

export function errorStatusCheck(state = initialState, action) {
  switch (action.type) {
    case HAS_ERROR_CATCH:
      return state.set('hasError', action.payload).set('error', action.error);

    default:
      return state;
  }
}
