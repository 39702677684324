import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { checkUserEntitlements } from '../../userConfig';
import { getCookie } from '../../utils/cookie-settings';
import CookieConfirmPopup from '../CookieConfirmPopup';

require('./styles.scss');

function VersionInfo() {
  const visible_value = getCookie('ess_analytics') === '';
  const [visible, setVisible] = React.useState(visible_value);
  const copyRightYear = new Date().getFullYear();
  return (
    <div
      className="version-info-container"
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'centre',
        justifyItems: 'centre',
      }}>
      <Row>
        <Col
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'centre',
            justifyItems: 'centre',
          }}>
          <div> ESS &copy; Copyright {copyRightYear} ABB </div> &nbsp;&nbsp;|
          <div>
            {checkUserEntitlements('ESS:common-Privacynotice') && (
              <a
                href="https://new.abb.com/privacy-notice"
                target="_blank"
                style={{ textDecoration: 'none' }}
                className="privacy-notice"
                rel="noreferrer">
                Privacy Notice
              </a>
            )}
          </div>
          |
          <div>
            <a className="cookiesetting" onClick={() => setVisible(true)}>
              Change cookie setting
            </a>
          </div>
          <div>
            {process.env.REACT_APP_ESS_API !== 'production'
              ? `| ` + `${process.env.REACT_APP_BUILD_NUMBER}`
              : null}
          </div>
        </Col>
      </Row>
      <CookieConfirmPopup show={visible} setVisible={setVisible} />
    </div>
  );
}

export default VersionInfo;
