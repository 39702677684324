import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import ErrorBoundary from './containers/ErrorBoundary';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store from './store';

const root = document.getElementById('root');
createRoot(root).render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>
);

serviceWorker.unregister();
