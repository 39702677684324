import React, { useState } from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { formatDesignedBudgetLabel } from '../../utils/fleetSetup-util';
import Help from '../Help/Help';
import './styles.scss';

export const DesignBudgetInput = ({
  setColor,
  data,
  handleInputChange,
  handleSwitchChange,
  handleReset,
  activeTabName,
}) => {
  const [activeTab, SetActiveTab] = useState(activeTabName);
  const onTabChange = tab => {
    SetActiveTab(tab);
  };
  const textMap = {
    'Usage Temperature': '≤ Temperature <',
    'Usage SoC': '≤ SoC <',
    'Usage Power': 'to',
    'Usage DoD': '≤ SoC <',
  };
  const text = textMap[activeTab] || ' - ';
  return (
    <div>
      <Tabs
        activeKey={activeTab}
        onSelect={tab => onTabChange(tab)}
        id="prodsetupusgstabs">
        {data.map(
          designSetting =>
            designSetting.showDesignTab && (
              <Tab
                key={designSetting.tabName}
                eventKey={designSetting.tabName}
                title={
                  <span className="tab title">
                    {designSetting.tabName}
                    <Help
                      position={'relative'}
                      placement={'right'}
                      styleProps={{ top: 0 }}
                      helpContent={designSetting.helpLabel}
                    />
                  </span>
                }>
                <>
                  <div style={{ display: 'flex', padding: '20px 0px' }}>
                    <Form.Label className="switch-label">
                      Show design budget curve
                    </Form.Label>

                    <Form.Check
                      type="switch"
                      name="showDesignBudgetCurve"
                      onChange={() =>
                        handleSwitchChange(
                          !designSetting.showDesignBudgetCurve,
                          `${designSetting.tabName}`
                        )
                      }
                      checked={designSetting.showDesignBudgetCurve}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                  {activeTab === designSetting.tabName &&
                    designSetting.data.length > 0 &&
                    designSetting.data.map((item, idx) => (
                      <Form.Group as={Row} key={idx} className="frmcontrols">
                        <Form.Label column sm="9" className="removepadding">
                          {formatDesignedBudgetLabel(
                            item.range,
                            designSetting.labelUnit,
                            text
                          )}
                        </Form.Label>
                        <Col
                          sm="3"
                          className=""
                          style={{ display: 'flex', alignItems: 'center' }}>
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="number"
                              className="norightborder"
                              // name="dTempOne"
                              value={item.value}
                              onChange={e =>
                                handleInputChange(
                                  e,
                                  `${designSetting.tabName}_${item.bin}`
                                )
                              }
                              required
                              pattern="^\d*(\.\d{0,2})?$"
                              onWheel={e => e.target.blur()}
                            />
                            <InputGroup.Text>
                              {designSetting.inputUnit}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please provide {item.label}.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    ))}
                </>
              </Tab>
            )
        )}
      </Tabs>
    </div>
  );
};
