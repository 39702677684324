import 'bootstrap-daterangepicker/daterangepicker.css';
import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Table from '../ExcelReportTable';
import Loading from '../Loading';
import './styles.scss';

class ExcelReportPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const columns = [
      {
        id: 'batterySN',
        Header:
          this.props.selectedPlatform === '3'
            ? 'Battery System Number'
            : 'ESS SN',
        accessor: 'batterySN',
      },
      {
        id: 'pecSerialNumber',
        Header: this.props.selectedPlatform === '3' ? 'PEC SN' : 'BMS SN',
        accessor: 'pecSerialNumber',
      },
    ];

    if (this.props.selectedPlatform === '3') {
      columns.push({
        id: 'scuNumber',
        Header: 'SCU SN',
        accessor: 'scuNumber',
      });
    }

    columns.push(
      {
        id: 'vehicleNumber',
        Header: 'Currently fitted in Vehicle',
        accessor: 'vehicleNumber',
      },
      {
        id: 'category',
        Header: 'Category',
        accessor: 'category',
        Cell: props => (
          <span style={{ whiteSpace: 'nowrap' }}>{props.value}</span>
        ),
      },
      {
        id: 'categoryScoring',
        Header: 'Category Scoring',
        accessor: 'categoryScoring',
        Cell: props => props.value && props.value.toFixed(2),
      },
      // {
      //   id: "categoryScoringUnit",
      //   Header: "Category Scoring Unit",
      //   accessor: "categoryScoringUnit",
      //   Cell: (props) => (
      //     <span style={{ whiteSpace: "nowrap" }}>{props.value}</span>
      //   ),
      // },
      {
        id: 'totalEnergyThroughput',
        Header: 'Energy Throughput',
        accessor: 'totalEnergyThroughput',
        Cell: props => props.value && props.value.toFixed(2),
      },

      {
        id: 'totalOperatingHours',
        Header: 'Operating Hours',
        accessor: 'totalOperatingHours',
        Cell: props => props.value && props.value.toFixed(2),
      },
      {
        id: 'firstConnectedDate',
        Header: 'Product sending data since',
        accessor: 'firstConnectedDate',
        Cell: props =>
          props.value ? Moment.utc(props.value).format('DD.MM.YYYY') : 'NA',
      }
    );

    if (this.props.excelReportData !== null) {
      let colm = [];
      this.props.excelReportData[0].socHeaders.forEach(item => {
        colm.push({
          id: item,
          Header: item + ' [h]',
          accessor: 'socRange.' + item,
        });
      });
      columns.push({
        id: 'soc',
        Header: 'Total operation hours for the given Bin of SoC.',
        columns: [...colm],
      });

      colm = [];
      this.props.excelReportData[0].temperatureHeaders.forEach(item => {
        colm.push({
          id: item,
          Header: item + ' [h]',
          accessor: 'temperatureRange.' + item,
        });
      });
      columns.push({
        id: 'temperture',
        Header: 'Total operation hours for the given Bin of Temperature.',
        columns: [...colm],
      });
    }

    columns.push(
      {
        id: 'soH%',
        Header: 'Latest SoH %',
        accessor: 'sohPercentage',
        Cell: props => props.value && props.value.toFixed(2),
      },
      {
        id: 'sohCalculatedDate',
        Header: 'SoH Calculated Date',
        accessor: 'sohCalculatedDate',
        Cell: props =>
          props.value ? Moment.utc(props.value).format('DD.MM.YYYY') : 'NA',
      }
    );

    return (
      <Row className="excelreport-container">
        {this.props.loadingExcelReport === true ||
        this.props.excelReportData === null ? (
          <Col>
            <Loading loadintText="Loading data" />
          </Col>
        ) : (
          <Col>
            <Table
              columns={columns}
              data={this.props.excelReportData}
              style={{ maxWidth: window.innerWidth - 340 }}
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default ExcelReportPreview;
