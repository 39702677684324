import React from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import './styles.scss';

class SoCTable extends React.Component {
  render() {
    if (this.props.pageName === 'fleetSOCRange') {
      return (
        <Table responsive id="soc-table" className="medium-font">
          <thead className="table-header">
            <tr>
              <th></th>
              <th>
                <span className="red-square"></span>0-10%
              </th>
              <th>
                <span className="yellow-square"></span>11-50%
              </th>
              <th>
                <span className="green-square"></span>51-95%
              </th>
              <th>
                <span className="red-square"></span>96-100%
              </th>
              <th className="left-border">Vehicle No.</th>
              <th>Battery No.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Target SoC range</td>
              <td>0%</td>
              <td>&lt;15%</td>
              <td>&gt;75%</td>
              <td>&lt;5%</td>
              <td className="left-border"></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Form.Check
                  custom
                  type="radio"
                  label="In operation,avg"
                  name="formHorizontalRadios"
                  id="operation"
                  value="1"
                  // onChange={onChange}
                  className={'elements'}
                  defaultChecked
                />
              </td>
              <td>0.2%</td>
              <td>15.0%</td>
              <td>81.8%</td>
              <td>3.0%</td>
              <td className="left-border"></td>
              <td></td>
            </tr>
            <tr>
              <td>
                {' '}
                <Form.Check
                  custom
                  type="radio"
                  label="Worst"
                  name="formHorizontalRadios"
                  id="worst"
                  value="2"
                  // onChange={onChange}
                  className={'elements'}
                  // checked={true}
                />
              </td>
              <td>5.0%</td>
              <td>15.0%</td>
              <td>81.8%</td>
              <td>3.0%</td>
              <td className="left-border">RE460 02</td>
              <td>ESS 0201</td>
            </tr>
            <tr>
              <td>
                <Form.Check
                  custom
                  type="radio"
                  label="Best"
                  name="formHorizontalRadios"
                  id="best"
                  value="3"
                  // onChange={onChange}
                  className={'elements'}
                  // checked={true}
                />
              </td>
              <td>0.0%</td>
              <td>3.0%</td>
              <td>95.0%</td>
              <td>2.0%</td>
              <td className="left-border">RE460 10</td>
              <td>ESS 1002</td>
            </tr>
          </tbody>
        </Table>
      );
    } else if (this.props.pageName === 'vehicleSOCRange') {
      return (
        <Table responsive id="soc-table" className="medium-font">
          <thead className="table-header">
            <tr>
              <th></th>
              <th>
                <span className="red-square"></span>0-10%
              </th>
              <th>
                <span className="yellow-square"></span>11-50%
              </th>
              <th>
                <span className="green-square"></span>51-95%
              </th>
              <th>
                <span className="red-square"></span>96-100%
              </th>
              <th className="left-border">Vehicle No.</th>
              <th>Battery No.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Target SoC range</td>
              <td>0%</td>
              <td>&lt;15%</td>
              <td>&gt;75%</td>
              <td>&lt;5%</td>
              <td className="left-border"></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Form.Check
                  custom
                  type="radio"
                  label="In operation,avg"
                  name="formHorizontalRadios"
                  id="operation"
                  value="1"
                  // onChange={onChange}
                  className={'elements'}
                  defaultChecked
                />
              </td>
              <td>0.2%</td>
              <td>15.0%</td>
              <td>81.8%</td>
              <td>3.0%</td>
              <td className="left-border"></td>
              <td></td>
            </tr>
            <tr>
              <td>
                {' '}
                <Form.Check
                  custom
                  type="radio"
                  label="Worst"
                  name="formHorizontalRadios"
                  id="worst"
                  value="2"
                  // onChange={onChange}
                  className={'elements'}
                  // checked={true}
                />
              </td>
              <td>5.0%</td>
              <td>15.0%</td>
              <td>81.8%</td>
              <td>3.0%</td>
              <td className="left-border">RE460 02</td>
              <td>ESS 0201</td>
            </tr>
            <tr>
              <td>
                <Form.Check
                  custom
                  type="radio"
                  label="Best"
                  name="formHorizontalRadios"
                  id="best"
                  value="3"
                  // onChange={onChange}
                  className={'elements'}
                  // checked={true}
                />
              </td>
              <td>0.0%</td>
              <td>3.0%</td>
              <td>95.0%</td>
              <td>2.0%</td>
              <td className="left-border">RE460 10</td>
              <td>ESS 1002</td>
            </tr>
          </tbody>
        </Table>
      );
    } else if (this.props.pageName === 'batterySOCRange') {
      return (
        <Table responsive id="soc-table" className="medium-font">
          <thead className="table-header">
            <tr>
              <th></th>
              <th>
                <span className="red-square"></span>0-10%
              </th>
              <th>
                <span className="yellow-square"></span>11-50%
              </th>
              <th>
                <span className="green-square"></span>51-95%
              </th>
              <th>
                <span className="red-square"></span>96-100%
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Target SoC range</td>
              <td>0%</td>
              <td>&lt;15%</td>
              <td>&gt;75%</td>
              <td>&lt;5%</td>
            </tr>
            <tr>
              <td>In operation,avg</td>
              <td>{this.props.data[0].per}%</td>
              <td>{this.props.data[1].per}%</td>
              <td>{this.props.data[2].per}%</td>
              <td>{this.props.data[3].per}%</td>
            </tr>
          </tbody>
        </Table>
      );
    }
  }
}

export default SoCTable;
