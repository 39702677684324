import {
  CREATE_BRORADCAST_MESSAGE,
  DELETE_BROADCAST_MESSAGE,
  FETCH_ACTIVE_MESSAGES,
  FETCH_ACTIVE_MESSAGES_FAILURE,
  FETCH_ACTIVE_MESSAGES_SUCCESS,
  FETCH_ALL_BROADCAST_MESSAGE,
  FETCH_ALL_BROADCAST_MESSAGE_FAILURE,
  FETCH_ALL_BROADCAST_MESSAGE_SUCCESS,
  UPDATE_BROADCAST_MESSAGE,
} from './constants';

export const createBroadcastMessage = data => ({
  type: CREATE_BRORADCAST_MESSAGE,
  payload: { data },
});

export const updateBroadcastMessage = data => {
  return {
    type: UPDATE_BROADCAST_MESSAGE,
    payload: { data },
  };
};

export const fetchAllBroadcastMessages = () => ({
  type: FETCH_ALL_BROADCAST_MESSAGE,
});

export const fetchAllBroadcastMessagesSuccess = data => ({
  type: FETCH_ALL_BROADCAST_MESSAGE_SUCCESS,
  payload: { data },
});

export const fetchAllBroadcastMessagesFailure = error => ({
  type: FETCH_ALL_BROADCAST_MESSAGE_FAILURE,
  payload: { error },
});

export const deleteBroadcastMessage = id => ({
  type: DELETE_BROADCAST_MESSAGE,
  payload: { id },
});

export const fetchActiveMessages = () => ({
  type: FETCH_ACTIVE_MESSAGES,
});

export const fetchActiveMessagesSuccess = data => ({
  type: FETCH_ACTIVE_MESSAGES_SUCCESS,
  payload: { data },
});

export const fetchActiveMessagesFailure = error => ({
  type: FETCH_ACTIVE_MESSAGES_FAILURE,
  payload: { error },
});
