import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './Help.scss';

export default function Help({ helpContent, placement, position, styleProps }) {
  return (
    <OverlayTrigger
      delay={{ hide: 350, show: 300 }}
      overlay={props => (
        <Tooltip
          {...props}
          className={`tooltip-${'primary'}`}
          id={`tooltip-${'primary'}`}>
          {helpContent}
        </Tooltip>
      )}
      placement={placement}>
      <div className={`help`} style={{ position, ...styleProps }}>
        <div className="question">i</div>
      </div>
    </OverlayTrigger>
  );
}
