// Library
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { authService } from '../../AuthService';
import Header from '../../components/Header/Header';
import { updateTitle } from '../../utils/title-util';

class HeaderPage extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      updateTitle(this.props.location);
    }
  }

  logout = () => {
    localStorage.clear();
    authService.logout();
  };

  redirectToHome = () => {
    this.props.history.push('/Dashboard');
  };

  redirectToFleetSetup = () => {
    this.props.history.push('/Dashboard/FleetConfiguration');
  };

  redirectToReportpage = () => {
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const stParam = urlSearchParams.get('st');
    const ftParam = urlSearchParams.get('ft');
    const vcParam = urlSearchParams.get('vc');
    const vpParam = urlSearchParams.get('pdn');
    const pkParam = urlSearchParams.get('pkdn');

    let tempUrl = '/Dashboard/ReportView?st=' + stParam;
    // if (stParam !== null) tempUrl += "?st=" + stParam;
    if (ftParam !== null) tempUrl += '&ft=' + ftParam;
    if (vcParam !== null) tempUrl += '&vc=' + vcParam;
    if (vpParam !== null) tempUrl += '&pdn=' + vpParam;
    if (pkParam !== null) tempUrl += '&pkdn=' + pkParam;

    this.props.history.push(tempUrl);
  };

  redirectToSafeMode = () => {
    this.props.history.push('/Dashboard/SafeMode');
  };

  redirectToBroadcast = () => {
    this.props.history.push('/Dashboard/Broadcast');
  };

  render() {
    const { location } = this.props;
    if (sessionStorage.getItem('profile')) {
      const profile = JSON.parse(sessionStorage.getItem('profile'));
      const analytics_groups = profile.groups.filter(group =>
        group.includes('Traction_ESS_')
      );
      // user group with Traction_ESS_ prefix is used for analytics
      if (analytics_groups.length > 0) {
        const group = analytics_groups[0].split('_').pop();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_group: group,
        });
      }
    }
    if (
      location.pathname.match('/login') ||
      location.pathname.match('/networkError') ||
      location.pathname.match('/serverError')
    ) {
      return null;
    }
    return (
      <Header
        handleLogoutClick={this.logout}
        redirectToHome={this.redirectToHome}
        userEmail={JSON.parse(sessionStorage.getItem('profile')).email}
        redirectToFleetSetup={this.redirectToFleetSetup}
        redirectToSafeMode={this.redirectToSafeMode}
        redirectToBroadcast={this.redirectToBroadcast}
        redirectToReportpage={this.redirectToReportpage}
        showMessages={this.props.showMessages}
        messageCount={this.props.messageCount}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderPage)
);
