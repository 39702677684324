import Moment from 'moment';
import { createSelector } from 'reselect';

// ESS change starts here
const graphData = () => state => state.get('graphData');

const loadingOverallConditionSelector = () =>
  createSelector(graphData(), state => state.get('loadingOverallCondition'));

const overallConditionDataSelector = () =>
  createSelector(graphData(), state => state.get('overallConditionData'));

const loadingOverviewSelector = () =>
  createSelector(graphData(), state => state.get('loadingOverview'));

const loadingHistogramSelector = () =>
  createSelector(graphData(), state => state.get('loadingHistogram'));

const loadingBoxPlotSelector = () =>
  createSelector(graphData(), state => state.get('loadingBoxPlot'));

const histogramDataSelector = () =>
  createSelector(graphData(), state => state.get('histogramData'));

const boxPlotDataSelector = () =>
  createSelector(graphData(), state => state.get('boxPlotData'));

const overviewDataSelector = () =>
  createSelector(graphData(), state => state.get('overviewData'));

const loadingFleetsSelector = () =>
  createSelector(graphData(), state => state.get('loadingFleets'));

const fleetsListDataSelector = () =>
  createSelector(graphData(), state => state.get('fleetsList'));

const loadingVehiclesSelector = () =>
  createSelector(graphData(), state => state.get('loadingVehicles'));

const vehiclesListDataSelector = () =>
  createSelector(graphData(), state => state.get('vehiclesList'));

const fleetStatisticsDataSelector = () =>
  createSelector(graphData(), state => state.get('fleetStatistics'));

const fleetStatisticsStatusLoader = () =>
  createSelector(graphData(), state => state.get('loadingFleetStatistics'));

// unmounted ESS start

const loadingUnmountedESSListSelector = () =>
  createSelector(graphData(), state => state.get('loadingUnmountedESSList'));

const unmountedESSListDataSelector = () =>
  createSelector(graphData(), state => state.get('unmountedESSList'));

// unmounted ess ends

const loadingVehicleProductSelector = () =>
  createSelector(graphData(), state => state.get('loadingVehicleProducts'));

const vehicleProductListDataSelector = () =>
  createSelector(graphData(), state => state.get('vehicleProductsList'));

const loadingModuleSelector = () =>
  createSelector(graphData(), state => state.get('loadingModules'));

const modulesListDataSelector = () =>
  createSelector(graphData(), state => state.get('modulesList'));

const selectedFleetSelector = () =>
  createSelector(graphData(), state => state.get('selectedFleet'));

const selectedvehicleSelector = () =>
  createSelector(graphData(), state => state.get('selectedVehicle'));

const selectedListSelector = () =>
  createSelector(graphData(), state => state.get('selectedList'));

const selectedStartDateSelector = () =>
  createSelector(graphData(), state => {
    const startDate = state.get('startDate');
    let formatedStartDate = null;
    if (startDate != null) {
      formatedStartDate = Moment(startDate)
        .format('YYYY-MM-DDTHH:mm:ss')
        .replace(/:/g, '%3A');
    }
    return formatedStartDate;
  });

const selectedEndDateSelector = () =>
  createSelector(graphData(), state => {
    const endDate = state.get('endDate');
    let formatedEndDate = null;
    if (endDate != null) {
      formatedEndDate = Moment(endDate)
        .format('YYYY-MM-DDTHH:mm:ss')
        .replace(/:/g, '%3A');
    }
    return formatedEndDate;
  });

// Diagnostic list
const loadingDiagnosticListSelector = () =>
  createSelector(graphData(), state => state.get('loadingDiagnosticList'));

const diagnosticListSelector = () =>
  createSelector(graphData(), state => state.get('diagnosticList'));
const diagnosticEventListSelectorConfig = () =>
  createSelector(graphData(), state =>
    state.get('diagnosticEventListDataConfig')
  );
const diagnosticEventDataSelector = () =>
  createSelector(graphData(), state => state.get('diagnosticEventData'));

const platformNumberSelector = () =>
  createSelector(graphData(), state => state.get('platformVersionNumber'));

// const filerCustomerList = (site ="Bern") =>
//   createSelector(fleetDataSelector(), object => {
//     let filerCustomerList = [];
//     if (object !== null) {
//       object.tempData.filter(fleet => {
//         if(fleet.objectType === site )
//         filerCustomerList.push(fleet.objectType);
//       });
//     }
//     return filerCustomerList;
//   });

// const bretonlySelector = () => createSelector(
//   FleetListSelector,
//   tabledata => {

//     const arr = tabledata.fleetData;
//     if (arr) {
//       var filteredValue = arr.filter(function(item) {
//         return item.username === "Bret";
//       });

//     }
//   }
// );

export {
  boxPlotDataSelector,
  diagnosticEventDataSelector,
  diagnosticEventListSelectorConfig,
  diagnosticListSelector,
  fleetStatisticsDataSelector,
  fleetStatisticsStatusLoader,
  fleetsListDataSelector,
  histogramDataSelector,
  loadingBoxPlotSelector,
  loadingDiagnosticListSelector,
  loadingFleetsSelector,
  loadingHistogramSelector,
  loadingModuleSelector,
  loadingOverallConditionSelector,
  loadingOverviewSelector,
  loadingUnmountedESSListSelector,
  loadingVehicleProductSelector,
  loadingVehiclesSelector,
  modulesListDataSelector,
  overallConditionDataSelector,
  overviewDataSelector,
  platformNumberSelector,
  selectedEndDateSelector,
  selectedFleetSelector,
  selectedListSelector,
  selectedStartDateSelector,
  selectedvehicleSelector,
  unmountedESSListDataSelector,
  vehicleProductListDataSelector,
  vehiclesListDataSelector,
};
