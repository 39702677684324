import { createSelector } from 'reselect';

// ESS change starts here
const SidebarDraggable = () => state => state.get('SidebarDraggable');

const getSize = () =>
  createSelector(SidebarDraggable(), state => state.get('size'));
const getIsResizing = () =>
  createSelector(SidebarDraggable(), state => state.get('isResizing'));
const getShowSidebar = () =>
  createSelector(SidebarDraggable(), state => state.get('showSidebar'));

export { getIsResizing, getShowSidebar, getSize };
