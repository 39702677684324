import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import BTable from 'react-bootstrap/Table';
import { DebounceInput } from 'react-debounce-input';
import { withRouter } from 'react-router-dom';

import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DownArrowIcon,
  RightDirectionArrow,
} from '../../assets/images';

import { updateState } from '../../utils/eventList-util';
import './styles.scss';
const Table = ({
  columns,
  data,
  pageCount: ControlledPageCount,
  diagnosticEventData,
  setDiagnosticEventListData,
  loadingDiagnosticList,
}) => {
  const initialValuesFunc = () => {
    let val = [];
    if (
      new URL(window.location.href).searchParams.get('dfty') !== null &&
      new URL(window.location.href).searchParams.get('dfty') !== ''
    ) {
      val = val.concat([
        {
          id: 'ft',
          value: new URL(window.location.href).searchParams.get('dfty'),
        },
      ]);
    }
    if (new URL(window.location.href).searchParams.get('vn') !== null) {
      val = val.concat([
        {
          id: 'vn',
          value: new URL(window.location.href).searchParams.get('vn'),
        },
      ]);
    }
    if (
      new URL(window.location.href).searchParams.get('pcft') !== null &&
      new URL(window.location.href).searchParams.get('pcft') !== ''
    ) {
      val = val.concat([
        {
          id: 'pc',
          value: new URL(window.location.href).searchParams.get('pcft'),
        },
      ]);
    }
    if (
      new URL(window.location.href).searchParams.get('bpft') !== null &&
      new URL(window.location.href).searchParams.get('bpft') !== ''
    ) {
      val = val.concat([
        {
          id: 'bp',
          value: new URL(window.location.href).searchParams.get('bpft'),
        },
      ]);
    }
    if (
      new URL(window.location.href).searchParams.get('ip') !== null &&
      new URL(window.location.href).searchParams.get('ip') !== ''
    ) {
      val = val.concat([
        {
          id: 'ip',
          value: new URL(window.location.href).searchParams.get('ip'),
        },
      ]);
    }
    if (
      new URL(window.location.href).searchParams.get('pnft') !== null &&
      new URL(window.location.href).searchParams.get('pnft') !== ''
    ) {
      val = val.concat([
        {
          id: 'pn',
          value: new URL(window.location.href).searchParams.get('pnft'),
        },
      ]);
    }
    if (
      new URL(window.location.href).searchParams.get('fm') !== null &&
      new URL(window.location.href).searchParams.get('fm') !== ''
    ) {
      const fil = new URL(window.location.href).searchParams
        .get('fm')
        .replace(/\s*\((?=\d+\))[^)]*\)\s*/g, '');
      val = val.concat([
        {
          id: 'fm',
          value: fil,
        },
      ]);
    }
    return val;
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, filters, sortBy },
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: {
        pageSize: Number(diagnosticEventData.pageSize),
        pageIndex: Number(diagnosticEventData.pageIndex),
        filters: initialValuesFunc(),

        sortBy: [
          {
            id:
              diagnosticEventData.sortValueKey !== undefined
                ? diagnosticEventData.sortValueKey
                : 'dt',
            desc:
              diagnosticEventData.sortValue !== undefined
                ? diagnosticEventData.sortValue
                : false,
          },
        ],
      },
      manualPagination: true,
      pageCount: ControlledPageCount?.tpg,
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );
  const tbSearchParam = new URLSearchParams(window.location.search);
  useEffect(() => {
    setDiagnosticEventListData({
      pageSize,
      pageIndex: 0,
      uniqueKey: updateState(filters, 'uniqueKey') || 'All',
      uniqueValue: updateState(filters, 'uniqueValue') || 'All',
      sortValue: sortBy.length > 0 && sortBy[0].desc,
      sortValueKey: sortBy.length > 0 && sortBy[0].id,
    });
  }, [updateState(filters, 'uniqueValue'), updateState(filters, 'uniqueKey')]);
  useEffect(() => {
    setDiagnosticEventListData({
      pageSize,
      pageIndex,
      uniqueKey: updateState(filters, 'uniqueKey') || 'All',
      uniqueValue: updateState(filters, 'uniqueValue') || 'All',
      sortValue: sortBy.length > 0 && sortBy[0].desc,
      sortValueKey: sortBy.length > 0 && sortBy[0].id,
    });
  }, [pageSize, pageIndex, sortBy]);
  useEffect(() => {
    const addClassToThirdButton = () => {
      const buttons = document.querySelectorAll('.rightSideDiag button');
      const pageIndex = Number(diagnosticEventData.pageIndex);
      if (buttons.length >= 3 && pageIndex !== null) {
        buttons.forEach((button, index) => {
          const pageNumber = parseInt(button.textContent, 10);
          if (pageNumber === pageIndex + 1) {
            button.classList.add('activeButton');
          } else {
            button.classList.remove('activeButton');
          }
        });
      }
    };

    addClassToThirdButton();
  }, [tbSearchParam]);
  const startItemIndexDiag = pageIndex * pageSize + 1;
  const endItemIndexDiag = Math.min(
    (pageIndex + 1) * pageSize,
    ControlledPageCount.ttl
  );
  return (
    <>
      <button
        className={'reset-fill'}
        onClick={() => setAllFilters([])}
        id={'diagfilterresetClick'}>
        Reset
      </button>
      <BTable id={'diagnosticTable'} responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  key={i}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={column.isSorted ? 'active-sort' : 'sortablecon'}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span className={column.isSorted ? '' : 'sortable'}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <td className={'filterrow'} key={i}>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </td>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(data.length > 0 &&
            page.map((row, idx) => {
              prepareRow(row);
              return (
                <tr key={idx} {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return cell.isGrouped
                      ? j === 0 && (
                          <td
                            colSpan={6}
                            {...cell.getCellProps()}
                            style={{
                              background: cell.isGrouped
                                ? 'rgba(0, 0, 0, 0.04)'
                                : 'white',
                            }}>
                            {cell.isGrouped ? (
                              <>
                                <span {...row.getToggleRowExpandedProps()}>
                                  {row.isExpanded ? (
                                    <DownArrowIcon />
                                  ) : (
                                    <RightDirectionArrow />
                                  )}
                                </span>{' '}
                                {cell.render('Cell')} ({row.subRows.length})
                              </>
                            ) : cell.isAggregated ? (
                              ''
                            ) : cell.isPlaceholder ? null : (
                              cell.render('Cell')
                            )}
                          </td>
                        )
                      : !cell.isAggregated && (
                          <td {...cell.getCellProps()}>
                            {cell.isGrouped ? (
                              <></>
                            ) : cell.isAggregated ? (
                              ''
                            ) : cell.isPlaceholder ? null : (
                              cell.render('Cell')
                            )}
                          </td>
                        );
                  })}
                </tr>
              );
            })) || (
            <Col
              className="nodatafound"
              style={{
                position: 'absolute',
                transform: ' translate(0%, 50%)',
              }}>
              <div style={{ marginBottom: '-10px' }}> Sorry...</div>
              <div>No data found !</div>
            </Col>
          )}
        </tbody>
      </BTable>
      <div className="pagination">
        <div className="leftSide">
          <span>
            Showing {startItemIndexDiag} - {endItemIndexDiag} of ~
            {ControlledPageCount.ttl}
          </span>
        </div>
        <div className="rightSideDiag">
          <span className="pagecount">
            Page &nbsp;{' '}
            <span>
              <DebounceInput
                minLength={1}
                debounceTimeout={1000}
                className="pageInput"
                type="text"
                value={pageOptions.length > 0 ? Number(pageIndex + 1) : '0'}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />{' '}
              of {pageOptions.length}
            </span>{' '}
          </span>
          <button onClick={() => gotoPage(0)} disabled={pageIndex === 0}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={pageIndex === 0}>
            {'<'}
          </button>{' '}
          {pageOptions.map((page, index) => {
            if (
              index === pageOptions.length - 1 ||
              (index >= pageIndex && index <= pageIndex + 4)
            ) {
              return (
                <React.Fragment key={index}>
                  {index === pageOptions.length - 1 ? (
                    <span>{index >= pageIndex + 6 ? '...' : ''}</span>
                  ) : (
                    index > 0 && null
                  )}
                  <button onClick={() => gotoPage(index)}>{index + 1}</button>
                </React.Fragment>
              );
            } else if (
              pageIndex >= pageOptions.length - 6 &&
              index >= pageOptions.length - 6
            ) {
              return (
                <React.Fragment key={index}>
                  <button
                    onClick={() => {
                      gotoPage(index);
                    }}>
                    {index + 1}
                  </button>
                </React.Fragment>
              );
            }
            return null;
          })}
          <button
            onClick={() => nextPage()}
            disabled={ControlledPageCount.npg === 'No'}>
            {'>'}
          </button>{' '}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={ControlledPageCount.npg === 'No'}>
            {'>>'}
          </button>{' '}
        </div>
      </div>
    </>
  );
};

export default withRouter(Table);
