/* global google */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import markerIcon from '../../assets/images/map/marker.svg';
import { googleMapURL } from '../Googlemapkey';
import './styles.scss';
let temp = [];
let cond = [];
const DashboardGoogleMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={5}
      zoom={props.mapZoom}
      defaultCenter={{ lat: 49.494360998804716, lng: 11.078338623046875 }}
      {...(props.isHover !== true && {
        center: { lat: props.mapLat, lng: props.mapLng },
      })}
      options={{
        streetViewControl: false,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        fullscreenControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.BOTTOM_RIGHT,
        },
      }}>
      {props.mapData &&
        props.mapData.map((place, i) => {
          temp = [];
          cond = [];

          return (
            <Marker
              id={place.id}
              key={i}
              position={{ lat: Number(place.lat), lng: Number(place.lng) }}
              onMouseOver={() => {
                props.onHover(i);
              }}
              onMouseOut={props.onHoverOut}
              onClick={() => {
                props.handleFilterDropdownChange(
                  place.dsit,
                  'Sites',
                  place.lat,
                  place.lng
                );
              }}
              options={{
                icon: {
                  url: markerIcon,
                  scaledSize: new google.maps.Size(
                    props.mapIconSize === 32
                      ? props.mapIconSize
                      : props.mapIconSize - 5,
                    props.mapIconSize
                  ),
                },
              }}>
              {props.markerID === i && (
                <InfoWindow
                  options={{
                    pixelOffset: new google.maps.Size(0),
                    disableAutoPan: true,
                  }}>
                  <div className="markerinfocontainer">
                    <div className="siteNameDashbordMap">{place.dsit}</div>
                    <hr className="fleetListDashboardMapDivider"></hr>
                    <div className="fleetListDashboardMap">
                      {place.cus.forEach(item1 => {
                        item1.flt.forEach(item2 => {
                          temp.push(item2.fld);
                          cond.push(item2.con);
                        });
                      })}
                      <div className="fleetCountDashboadMap">
                        {temp.length} {temp.length > 1 ? ' fleets' : ' fleet'}
                      </div>
                      {temp.map((item3, j3) => {
                        return (
                          <div className="fleetNameDashboardMap" key={item3}>
                            {item3}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
    </GoogleMap>
  ))
);

class DashboardMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markerID: null,
      isHover: false,
    };
  }

  onHover = markerID => {
    this.setState({ markerID, isHover: true });
  };

  onHoverOut = () => {
    this.setState({ markerID: null });
  };

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ isHover: false });
    }
  }

  render() {
    return (
      <div id="dasboardmap">
        <DashboardGoogleMap
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `70vh` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          onHover={this.onHover}
          onHoverOut={this.onHoverOut}
          markerID={this.state.markerID}
          mapData={this.props.mapdata}
          handleFilterDropdownChange={this.props.handleFilterDropdownChange}
          mapZoom={this.props.mapZoom}
          mapIconSize={this.props.mapIconSize}
          mapLat={this.props.mapLat}
          mapLng={this.props.mapLng}
          isHover={this.state.isHover}
        />
      </div>
    );
  }
}

DashboardMap.defaultProps = {};

DashboardMap.propTypes = {
  mapPath: PropTypes.any,
};

export default DashboardMap;
