export const FETCH_BATTERY_OVERALL_CONDITION_DATA =
  'FETCH_BATTERY_OVERALL_CONDITION_DATA';
export const FETCH_BATTERY_OVERALL_CONDITION_DATA_SUCCESS =
  'FETCH_BATTERY_OVERALL_CONDITION_DATA_SUCCESS';
export const FETCH_BATTERY_OVERALL_CONDITION_DATA_FAILURE =
  'FETCH_BATTERY_OVERALL_CONDITION_DATA_FAILURE';

export const FETCH_CELL_VOLTAGE_DATA = 'FETCH_CELL_VOLTAGE_DATA';
export const FETCH_CELL_VOLTAGE_DATA_SUCCESS =
  'FETCH_CELL_VOLTAGE_DATA_SUCCESS';
export const FETCH_CELL_VOLTAGE_DATA_FAILURE =
  'FETCH_CELL_VOLTAGE_DATA_FAILURE';

export const FETCH_BATTERY_SOC_DATA = 'FETCH_BATTERY_SOC_DATA';
export const FETCH_BATTERY_SOC_DATA_SUCCESS = 'FETCH_BATTERY_SOC_DATA_SUCCESS';
export const FETCH_BATTERY_SOC_DATA_FAILURE = 'FETCH_BATTERY_SOC_DATA_FAILURE';

export const FETCH_HISTOGRAM_DATA = 'FETCH_HISTOGRAM_DATA';
export const FETCH_HISTOGRAM_DATA_SUCCESS = 'FETCH_HISTOGRAM_DATA_SUCCESS';
export const FETCH_HISTOGRAM_DATA_FAILURE = 'FETCH_HISTOGRAM_DATA_FAILURE';

export const FETCH_BOXPLOT_DATA = 'FETCH_BOXPLOT_DATA';
export const FETCH_BOXPLOT_DATA_SUCCESS = 'FETCH_BOXPLOT_DATA_SUCCESS';
export const FETCH_BOXPLOT_DATA_FAILURE = 'FETCH_BOXPLOT_DATA_FAILURE';

export const FETCH_BATTERY_SOC_RANGE_DATA = 'FETCH_BATTERY_SOC_RANGE_DATA';
export const FETCH_BATTERY_SOC_RANGE_DATA_SUCCESS =
  'FETCH_BATTERY_SOC_RANGE_DATA_SUCCESS';
export const FETCH_BATTERY_SOC_RANGE_DATA_FAILURE =
  'FETCH_BATTERY_SOC_RANGE_DATA_FAILURE';

export const CLEAR_CHARTDATA = 'CLEAR_CHARTDATA';
export const CLEAR_BATTERY_OVERVIEW_DATA = 'CLEAR_BATTERY_OVERVIEW_DATA';

export const FETCH_BATTERY_OVERVIEW_DATA = 'FETCH_BATTERY_OVERVIEW_DATA';
export const FETCH_BATTERY_OVERVIEW_DATA_SUCCESS =
  'FETCH_BATTERY_OVERVIEW_DATA_SUCCESS';
export const FETCH_BATTERY_OVERVIEW_DATA_FAILURE =
  'FETCH_BATTERY_OVERVIEW_DATA_FAILURE';

/// //////////////////////////////////////////////////////////////////////////////////////////////////

export const FETCH_TEMPERATURES_HISTOGRAM_DATA =
  'FETCH_TEMPERATURES_HISTOGRAM_DATA';
export const FETCH_TEMPERATURES_HISTOGRAM_DATA_SUCCESS =
  'FETCH_TEMPERATURES_HISTOGRAM_DATA_SUCCESS';
export const FETCH_TEMPERATURES_HISTOGRAM_DATA_FAILURE =
  'FETCH_TEMPERATURES_HISTOGRAM_DATA_FAILURE';

export const FETCH_OPERATING_HOURS_HISTOGRAM_DATA =
  'FETCH_OPERATING_HOURS_HISTOGRAM_DATA';
export const FETCH_OPERATING_HOURS_HISTOGRAM_DATA_SUCCESS =
  'FETCH_OPERATING_HOURS_HISTOGRAM_DATA_SUCCESS';
export const FETCH_OPERATING_HOURS_HISTOGRAM_DATA_FAILURE =
  'FETCH_OPERATING_HOURS_HISTOGRAM_DATA_FAILURE';

/// /////////////////////////////////

export const FETCH_USGSOC_HISTOGRAM_DATA = 'FETCH_USGSOC_HISTOGRAM_DATA';
export const FETCH_USGSOC_HISTOGRAM_DATA_SUCCESS =
  'FETCH_USGSOC_HISTOGRAM_DATA_SUCCESS';
export const FETCH_USGSOC_HISTOGRAM_DATA_FAILURE =
  'FETCH_USGSOC_HISTOGRAM_DATA_FAILURE';

export const FETCH_USGPOW_HISTOGRAM_DATA = 'FETCH_USGPOW_HISTOGRAM_DATA';
export const FETCH_USGPOW_HISTOGRAM_DATA_SUCCESS =
  'FETCH_USGPOW_HISTOGRAM_DATA_SUCCESS';
export const FETCH_USGPOW_HISTOGRAM_DATA_FAILURE =
  'FETCH_USGPOW_HISTOGRAM_DATA_FAILURE';

export const FETCH_USGDOD_HISTOGRAM_DATA = 'FETCH_USGDOD_HISTOGRAM_DATA';
export const FETCH_USGDOD_HISTOGRAM_DATA_SUCCESS =
  'FETCH_USGDOD_HISTOGRAM_DATA_SUCCESS';
export const FETCH_USGDOD_HISTOGRAM_DATA_FAILURE =
  'FETCH_USGDOD_HISTOGRAM_DATA_FAILURE';
// synched chart
/// ///////////////////////////////////////////////////////////////////////
export const FETCH_USGDOD_HEATMAP_DATA = 'FETCH_USGDOD_HEATMAP_DATA';
export const FETCH_USGDOD_HEATMAP_DATA_SUCCESS =
  'FETCH_USGDOD_HEATMAP_DATA_SUCCESS';
export const FETCH_USGDOD_HEATMAP_DATA_FAILURE =
  'FETCH_USGDOD_HEATMAP_DATA_FAILURE';

export const FETCH_USGPOW_HEATMAP_DATA = 'FETCH_USGPOW_HEATMAP_DATA';
export const FETCH_USGPOW_HEATMAP_DATA_SUCCESS =
  'FETCH_USGPOW_HEATMAP_DATA_SUCCESS';
export const FETCH_USGPOW_HEATMAP_DATA_FAILURE =
  'FETCH_USGPOW_HEATMAP_DATA_FAILURE';

export const FETCH_USGSOC_HEATMAP_DATA = 'FETCH_USGSOC_HEATMAP_DATA';
export const FETCH_USGSOC_HEATMAP_DATA_SUCCESS =
  'FETCH_USGSOC_HEATMAP_DATA_SUCCESS';
export const FETCH_USGSOC_HEATMAP_DATA_FAILURE =
  'FETCH_USGSOC_HEATMAP_DATA_FAILURE';

export const FETCH_USGTEMP_HEATMAP_DATA = 'FETCH_USGTEMP_HEATMAP_DATA';
export const FETCH_USGTEMP_HEATMAP_DATA_SUCCESS =
  'FETCH_USGTEMP_HEATMAP_DATA_SUCCESS';
export const FETCH_USGTEMP_HEATMAP_DATA_FAILURE =
  'FETCH_USGTEMP_HEATMAP_DATA_FAILURE';
/// ///////////////////////////////////////////////////////////////////////

export const FETCH_SYNC_GRAPH_DATA = 'FETCH_SYNC_GRAPH_DATA';
export const FETCH_SYNC_GRAPH_DATA_SUCCESS = 'FETCH_SYNC_GRAPH_DATA_SUCCESS';
export const FETCH_SYNC_GRAPH_DATA_FAILURE = 'FETCH_SYNC_GRAPH_DATA_FAILURE';

export const SET_MAINSIGNAL1 = 'SET_MAINSIGNAL1';
export const SET_MAINSIGNAL2 = 'SET_MAINSIGNAL2';
export const SET_MAINSIGNAL3 = 'SET_MAINSIGNAL3';
export const SET_OVERLAYSIGNAL1 = 'SET_OVERLAYSIGNAL1';
export const SET_OVERLAYSIGNAL2 = 'SET_OVERLAYSIGNAL2';
export const SET_OVERLAYSIGNAL3 = 'SET_OVERLAYSIGNAL3';
export const SET_MAINSIGNAL = 'SET_MAINSIGNAL';
export const SET_OVERLAYSIGNAL = 'SET_OVERLAYSIGNAL';
export const SET_SYNCCHART = 'SET_SYNCCHART';
export const SET_SYNCCHARTDATA = 'SET_SYNCCHARTDATA';
export const SET_RADIO_VALUE = 'SET_RADIO_VALUE';
export const GET_RADIO_VALUE = 'GET_RADIO_VALUE';

// TimeTrend
export const GET_DETAILVIEW_TIMETREND = 'GET_DETAILVIEW_TIMETREND';
export const GET_DETAILVIEW_TIMETREND_SUCCESS =
  'GET_DETAILVIEW_TIMETREND_SUCCESS';

export const GET_TT_AVGCELLV_SUCCESS = 'GET_TT_AVGCELLV_SUCCESS';
export const GET_TT_AVGTEMP_SUCCESS = 'GET_TT_AVGTEMP_SUCCESS';
export const GET_TT_SOC_SUCCESS = 'GET_TT_SOC_SUCCESS';
export const GET_TT_POWER_SUCCESS = 'GET_TT_POWER_SUCCESS';
export const GET_TT_BVOLTAGE_SUCCESS = 'GET_TT_BVOLTAGE_SUCCESS';
export const GET_TT_BCURRENT_SUCCESS = 'GET_TT_BCURRENT_SUCCESS';
export const GET_TT_BPCHARGE_SUCCESS = 'GET_TT_BPCHARGE_SUCCESS';
export const GET_TT_BPDISCHARGE_SUCCESS = 'GET_TT_BPDISCHARGE_SUCCESS';
export const GET_TT_BCCHARGE_SUCCESS = 'GET_TT_BCCHARGE_SUCCESS';
export const GET_TT_BCDISCHARGE_SUCCESS = 'GET_TT_BCDISCHARGE_SUCCESS';
export const GET_TT_BOLCOUNTER_SUCCESS = 'GET_TT_BOLCOUNTER_SUCCESS';
export const GET_TT_RELIABILITY_SUCCESS = 'GET_TT_RELIABILITY_SUCCESS';
export const GET_TT_FEEDFLOW_SUCCESS = 'GET_TT_FEEDFLOW_SUCCESS';
export const GET_TT_OVERLOADCOUNTER_SUCCESS = 'GET_TT_OVERLOADCOUNTER_SUCCESS';
export const GET_TT_RETURNFLOW_SUCCESS = 'GET_TT_RETURNFLOW_SUCCESS';
export const GET_TT_DIAGNOSTIC_SUCCESS = 'GET_TT_DIAGNOSTIC_SUCCESS';

export const SET_TIMETREND_LOADING = 'SET_TIMETREND_LOADING';

export const GET_DETAILVIEW_TIMETREND_FAILURE =
  'GET_DETAILVIEW_TIMETREND_FAILURE';
export const CLEAR_DETAILVIEW_TIMETREND = 'CLEAR_DETAILVIEW_TIMETREND';

export const SET_MAINTRENDSIGNAL = 'SET_MAINTRENDSIGNAL';
export const ADD_OVERLAYTRENDSIGNAL = 'ADD_OVERLAYTRENDSIGNAL';

export const FETCH_BATTERY_REL_DATA = 'FETCH_BATTERY_REL_DATA';
export const FETCH_BATTERY_REL_DATA_SUCCESS = 'FETCH_BATTERY_REL_DATA_SUCCESS';
export const FETCH_BATTERY_REL_DATA_FAILURE = 'FETCH_BATTERY_REL_DATA_FAILURE';

export const FETCH_BATTERY_STR_DATA = 'FETCH_BATTERY_STR_DATA';
export const FETCH_BATTERY_STR_DATA_SUCCESS = 'FETCH_BATTERY_STR_DATA_SUCCESS';
export const FETCH_BATTERY_STR_DATA_FAILURE = 'FETCH_BATTERY_STR_DATA_FAILURE';

// SOH
export const FETCH_SOH_DATA = 'FETCH_SOH_DATA';
export const FETCH_SOH_DATA_SUCCESS = 'FETCH_SOH_DATA_SUCCESS';
export const FETCH_SOH_DATA_FAILURE = 'FETCH_SOH_DATA_FAILURE';
