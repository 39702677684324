/*
 * Dashboard Messages
 *
 * This contains all the text for the Dashboard component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.Dashboard.header',
    defaultMessage:
      'Dashboard/FleetView/VehicleView/VehicleProductsView/PackView',
  },
  clickDragText: {
    id: 'app.components.GraphTimeSelector.clickDragText',
    defaultMessage: 'Click and drag to zoom in.',
  },
});
