import moment from 'moment';
import { platformVersion } from './excel-utils';

export const getMonthYearFormatted = inp => {
  const [month, year] = inp.split(' ');
  const startDate = moment(
    `${year}-${month}-01T00:00:00`,
    'YY-MMM-DDTHH:mm:ss'
  );
  const endDate = startDate.clone().endOf('month');
  return {
    startDate: startDate.format('YYYY-MM-DDTHH:mm:ss').replace(/:/g, '%3A'),
    endDate: endDate.format('YYYY-MM-DDTHH:mm:ss').replace(/:/g, '%3A'),
  };
};

export const createUrlFromParams = params => {
  const { origin, pathname } = window.location;
  const url = `${origin}${pathname}/VehicleView/VehicleProductsView${platformVersion() === '3' ? `/PackView` : ``}?`;
  let queryString = '';
  for (const [key, value] of Object.entries(params)) {
    if (value === undefined || value === null) continue;
    const encodedValue = value.toString().replace(/ /g, '+');
    if (queryString === '') {
      queryString += `${encodeURIComponent(key)}=${encodedValue}`;
    } else {
      queryString += `&${encodeURIComponent(key)}=${encodedValue}`;
    }
  }
  return url + queryString;
};

export const findVehicleFromEssHandler = (text, data, newData) => {
  const searchText = text.toLowerCase();
  const result = { vn: '', dn: '', bpu: '', an: '', ip: '' };

  // Search for full word match in data array
  for (const obj of data) {
    if (obj.vst && Array.isArray(obj.vst)) {
      for (const vstObj of obj.vst) {
        if (vstObj.vdat && Array.isArray(vstObj.vdat)) {
          const matchingVDat = vstObj.vdat.find(vdatObj => {
            return vdatObj.dn && vdatObj.dn.toLowerCase() === searchText;
          });
          if (matchingVDat) {
            const { dn, bpu, an, ip } = matchingVDat;
            return { vn: vstObj.vn, dn, bpu, an, ip };
          }
        }
      }
    }
  }

  // Search for full word match in new data array
  for (const obj of newData) {
    if (obj.dn && obj.dn.toLowerCase() === searchText) {
      const { dn, bpu, an, ip } = obj;
      return { vn: obj.vn, dn, bpu, an, ip };
    }
  }

  // Search for partial word match in data array after last '_'
  const lastWord = searchText.split('_').pop();
  const matchingPartialVDat = data
    .flatMap(obj => obj.vst)
    .flatMap(vstObj => vstObj.vdat)
    .find(vdatObj => {
      if (vdatObj.dn) {
        return vdatObj.dn.toLowerCase().endsWith(lastWord);
      }
      return false;
    });
  if (matchingPartialVDat) {
    const { dn, bpu, an, ip } = matchingPartialVDat;
    return { vn: matchingPartialVDat.vn, dn, bpu, an, ip };
  }

  // Searching for partial word match in new data array after last '_'
  const matchingPartialNewData = newData.find(obj => {
    if (obj.dn) {
      return obj.dn.toLowerCase().endsWith(lastWord);
    }
    return false;
  });
  if (matchingPartialNewData) {
    const { dn, bpu, an, ip } = matchingPartialNewData;
    return { vn: matchingPartialNewData.vn, dn, bpu, an, ip };
  }

  return result;
};
