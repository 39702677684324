/**
 * User guide information.
 * @typedef {Object} UserGuideInfo
 * @property {string} name - The name of the user guide.
 * @property {string} entitlement - The entitlement required to access the user guide.
 */

/**
 * Array of user guide information.
 * @type {UserGuideInfo[]}
 */
export const userGuideInfo = [
  { name: 'Basic Navigation', entitlement: '' },
  {
    name: 'Product Utilization',
    entitlement: 'ESS:Product-Overview-Utilization',
  },
  {
    name: 'Product Reliability',
    entitlement: 'ESS:Product-Overview-Reliability',
  },
  { name: 'Traction Battery SoH', entitlement: 'ESS:Product-SOH' },
];
