import React from 'react';
import Form from 'react-bootstrap/Form';
import { usePlacesWidget } from 'react-google-autocomplete';

export default function SearchLocationInput(props) {
  const { ref: bootstrapRef } = usePlacesWidget({
    apiKey: 'AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc',
    onPlaceSelected: place => props.onPlaceSelected(place),
    options: {
      libraries: ['places'],
    },
  });

  return (
    <Form.Control
      ref={bootstrapRef}
      required
      autoComplete="off"
      type="text"
      placeholder=""
      name="sitelocN"
      onChange={props.handleSiteInputChange}
      value={props.siteFormValues.sitelocN}
    />
  );
}
