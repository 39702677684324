import { createSelector } from 'reselect';

// Email alert settings
const EmailalertViewData = () => state => state.get('emailalertData');

const loadingEmailalertSelector = () =>
  createSelector(EmailalertViewData(), state => state.get('loadingEmailalert'));

const emailalertSelector = () =>
  createSelector(EmailalertViewData(), state => state.get('emailalertData'));

const emailalertSaveSelector = () =>
  createSelector(EmailalertViewData(), state =>
    state.get('emailalertSaveData')
  );

export {
  EmailalertViewData,
  emailalertSaveSelector,
  emailalertSelector,
  loadingEmailalertSelector,
};
