import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AbbPlusInCircle, DeleteIcon } from '../../assets/images';
import Help from '../Help/Help';
import './styles.scss';

export const SohstressFactor = ({
  data,
  handleReset,
  sohStressActiveTab,
  onInputChange,
  onDeleteRow,
  onAddRow,
  fleetFormValues,
}) => {
  const [activeTab, SetActiveTab] = useState(sohStressActiveTab);

  const onTabChange = tab => {
    SetActiveTab(tab);
  };
  const renderRow = (row, index, sohStress) => {
    // Options for minCondition and maxCondition dropdowns
    const conditionOptions = ['', '<', '≤', '>', '≥'];

    return (
      <div key={index} className="form-row">
        <Form.Control
          type="text"
          value={row.min}
          onChange={event => onInputChange(event, index, 'min', activeTab)}
          disabled={!!(fleetFormValues.isUpdate && !fleetFormValues.isDraft)}
        />
        <Form.Control
          as="select"
          disabled={
            index === 0 ||
            !!(fleetFormValues.isUpdate && !fleetFormValues.isDraft)
          }
          value={row.minCondition}
          onChange={event =>
            onInputChange(event, index, 'minCondition', activeTab)
          }>
          {conditionOptions.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </Form.Control>
        <span>
          {row.conditionLabel} {sohStress.sohUnit && `(${sohStress.sohUnit})`}
        </span>
        <Form.Control
          as="select"
          disabled={
            index ===
              data.find(tab => tab.sohTabName === activeTab)
                ?.extraStandardSOHConditionData?.length -
                1 || !!(fleetFormValues.isUpdate && !fleetFormValues.isDraft)
          }
          value={row.maxCondition}
          onChange={event =>
            onInputChange(event, index, 'maxCondition', activeTab)
          }>
          {conditionOptions.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </Form.Control>
        <Form.Control
          type="text"
          value={row.max}
          onChange={event => onInputChange(event, index, 'max', activeTab)}
          disabled={!!(fleetFormValues.isUpdate && !fleetFormValues.isDraft)}
        />
        <Form.Control
          type="text"
          value={row.penalty}
          onChange={event => onInputChange(event, index, 'penalty', activeTab)}
          disabled={!!(fleetFormValues.isUpdate && !fleetFormValues.isDraft)}
        />
        {!(fleetFormValues.isUpdate && !fleetFormValues.isDraft) && (
          <button
            type="button"
            style={{
              background: 'none',
              visibility:
                index === 0 ||
                index ===
                  data.find(tab => tab.sohTabName === activeTab)
                    ?.extraStandardSOHConditionData?.length -
                    1
                  ? 'hidden'
                  : 'visible',
            }}
            onClick={() => onDeleteRow(activeTab, index)}>
            <DeleteIcon />
          </button>
        )}
      </div>
    );
  };
  return (
    <div>
      <Tabs
        activeKey={activeTab}
        onSelect={tab => onTabChange(tab)}
        id="prodsetupusgstabs">
        {data.map(
          sohStress =>
            sohStress.showSOHTab && (
              <Tab
                key={sohStress.sohTabName}
                eventKey={sohStress.sohTabName}
                title={
                  <span className="tab title">
                    {sohStress.sohTabName}
                    <Help
                      position={'relative'}
                      placement={'right'}
                      styleProps={{ top: 0, right: 0 }}
                      helpContent={sohStress.sohHelpLabel}
                    />
                  </span>
                }>
                <>
                  <div className="section">
                    <div className="topSection">
                      <div></div>
                      {!(
                        fleetFormValues.isUpdate && !fleetFormValues.isDraft
                      ) && (
                        <button
                          type="button"
                          disabled={
                            !!(
                              fleetFormValues.isUpdate &&
                              !fleetFormValues.isDraft
                            )
                          }
                          onClick={() => onAddRow(sohStress.sohTabName)}
                          style={{
                            background: '#ddedfd',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '5px',
                            padding: '5px 10px',
                          }}>
                          <AbbPlusInCircle />{' '}
                          <span style={{ marginLeft: '5px' }}>Add row</span>
                        </button>
                      )}
                    </div>
                    {/* <h2>{sohStress.tabName}</h2> */}
                    <div className="form-header">
                      <div>Value</div>
                      <div></div>
                      <div>Condition</div>
                      <div></div>
                      <div>Value</div>
                      <div>Throughput penalty</div>
                    </div>
                    {activeTab === sohStress.sohTabName &&
                      sohStress.extraStandardSOHConditionData.length > 0 &&
                      sohStress.extraStandardSOHConditionData.map((item, idx) =>
                        renderRow(item, idx, sohStress)
                      )}
                  </div>
                </>
              </Tab>
            )
        )}
      </Tabs>
    </div>
  );
};
