import Moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { HomeIcon } from '../../assets/images';
import Breadcrumb from '../../components/Breadcrumb';
import { setSidebarWidth } from '../../components/DraggableLayout/actions';
import { getSidebarWidth } from '../../components/DraggableLayout/selectors';
import MinimizedSideMenu from '../../components/MinimizedSideMenu';
import SideMenu from '../../components/SideMenu';
import SideMenuV3 from '../../components/SideMenuV3';
import Draggable from '../../components/SidebarDraggable/Draggable';
import {
  setShowSidebar,
  setSize,
} from '../../components/SidebarDraggable/actions';
import {
  getIsResizing,
  getShowSidebar,
  getSize,
} from '../../components/SidebarDraggable/selectors';
import TitleBar from '../../components/Title/TitleBar';
import TitleBarV3 from '../../components/Title/TitleBarV3';
import VehiclesTabs from '../../components/VehiclesTabs';
import {
  userConfig as configUser,
  directlinktrendSignalCheck,
} from '../../userConfig';
import { copyToClipboard } from '../../utils/clipboard';
import { platformVersion } from '../../utils/excel-utils';
import { getbpu } from '../../utils/other';
import { fetchActiveMessages } from '../Broadcast/actions';
import { fetchMonitoredCount } from '../DashBoard/actions';
import { monitoredCountDataSelector } from '../DashBoard/selectors';
import {
  clearEventList,
  clearSaveEventResponce,
  clearUndoEventResponce,
  fetchAllEventList,
  fetchVEventList,
  saveEvent,
  setAllEventListData,
  undoEvent,
} from '../Events/actions';
import {
  AlleventListSelector,
  alleventListSelectorConfig,
  eventDataSelector,
  loadingAllEventListSelector,
  loadingSaveEventSelector,
  loadingUndoEventSelector,
  saveEventResponceSelector,
  undoEventResponceSelector,
} from '../Events/selectors';
import {
  fetchDiagnosticList,
  fetchFleetsList,
  fetchModuleList,
  fetchUnmountedESSList,
  fetchVehicleProductList,
  fetchVehiclesList,
  setDate,
  setDiagnosticEventListData,
  setListSelection,
} from '../FleetView/actions';
import {
  diagnosticEventDataSelector,
  diagnosticEventListSelectorConfig,
  diagnosticListSelector,
  fleetsListDataSelector,
  loadingDiagnosticListSelector,
  loadingUnmountedESSListSelector,
  selectedEndDateSelector,
  selectedListSelector,
  selectedStartDateSelector,
  unmountedESSListDataSelector,
  vehicleProductListDataSelector,
  vehiclesListDataSelector,
} from '../FleetView/selectors';
import {
  addOverlayTrendSignal,
  clearChartData,
  clearOverviewata,
  fetchBatteryOverallConditionData,
  setMainTrendSignal,
} from '../VehicleProductsView/actions';
import {
  batteryOverallConditionDataSelector,
  loadingBatteryOverallConditionSelector,
} from '../VehicleProductsView/selectors';
import {
  clearVehicleViewData,
  fetchBoxPlotData,
  fetchHistogramData,
  fetchOverallConditionData,
  fetchVehicleAvailData,
  fetchVehicleDetails,
  fetchVehicleRelData,
  fetchVehicleStrData,
} from './actions';
import messages from './messages';
import {
  boxPlotDataSelector,
  histogramDataSelector,
  loadingBoxPlotSelector,
  loadingHistogramSelector,
  loadingOverallConditionSelector,
  loadingVehicleAvailSelector,
  loadingVehicleDetailsSelector,
  loadingVehicleRelSelector,
  loadingVehicleStrSelector,
  overallConditionDataSelector,
  vehicleAvailDataSelector,
  vehicleDetailsSelector,
  vehicleRelDataSelector,
  vehicleStrDataSelector,
} from './selectors';
import './styles.scss';
Moment.locale('en');

let selectedSite;
let selectedFleet;
let selectedFleetType;
let selectedVehicle;
let selectedVehicleProduct;
let selectedPDn;
let selectedTb = null;
let selectedOverviewTb = null;
let srch = null;
let ftr = [null, null, null, null];
let userConfig;
export class VehicleView extends React.Component {
  constructor(props) {
    super(props);
    sessionStorage.removeItem('searchQuery');
    sessionStorage.removeItem('searchResultList');
    sessionStorage.removeItem('selectedPack');
    sessionStorage.removeItem('selectedPackDname');

    const urlLink = window.location.href;
    const url = new URL(urlLink);
    if (urlLink.indexOf('?') > -1) {
      selectedSite = url.searchParams.get('st');
      selectedFleet = url.searchParams.get('ft');
      selectedFleetType = url.searchParams.get('fty');
      selectedVehicle = url.searchParams.get('vc');
      selectedVehicleProduct = url.searchParams.get('vp');
      selectedPDn = url.searchParams.get('pdn');
      selectedOverviewTb = url.searchParams.get('stb');
      selectedTb = url.searchParams.get('vtb');
      ftr = url.searchParams.get('ftr');
      ftr =
        ftr !== null && ftr !== 'null'
          ? ftr.split(',')
          : [null, null, null, null];
      srch = url.searchParams.get('srch');

      sessionStorage.setItem('selectedSite', selectedSite);
      sessionStorage.setItem('selectedFleet', selectedFleet);
      sessionStorage.setItem('selectedFleetType', selectedFleetType);
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
      sessionStorage.setItem('selectedProductDname', selectedPDn);
      // this.props.setDate(selectedSrtDte, selectedEdDte);
      // this.setState({ selectedOverviewTab: selectedOverviewTb });
      // this.setState({ selectedTab: selectedTb });
    } else {
      this.props.history.push(
        '/FleetView/VehicleView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          sessionStorage.getItem('selectedVehicle') +
          '&sdt=' +
          url.searchParams.get('sdt') +
          '&edt=' +
          url.searchParams.get('edt') +
          '&vtb=Overview-vehicle-view'
      );
    }

    this.state = {
      searchResultList:
        sessionStorage.getItem('searchResultList') !== null
          ? JSON.parse(sessionStorage.getItem('searchResultList'))
          : [],
      searchQuery: srch !== null ? srch : '',
      isMonthlyactive: false,
      isWeeklyactive: false,
      isDailyactive: false,
      isVehicleClicked: false,
      isVeicleProductClicked: false,
      filterItems: {
        // isGoodSlected: ftr[1] !== null ? JSON.parse(ftr[1]) : false,
        // isPoorSelected: ftr[2] !== null ? JSON.parse(ftr[2]) : false,
        // isUnknownSelected: ftr[3] !== null ? JSON.parse(ftr[3]) : false,
        // isFavoriteSelected: false,
        // selectedSearchKey: ftr[0] !== null ? ftr[0] : "Select an index",

        availability: ftr[0] !== null ? ftr[0] : '',
        reliability: ftr[1] !== null ? ftr[1] : '',
        utilization: ftr[2] !== null ? ftr[2] : '',
      },
      selectedTab: selectedTb !== null ? selectedTb : 'Overview-vehicle-view',
      selectedOverviewTab:
        selectedOverviewTb !== null
          ? selectedOverviewTb
          : ftr[0] === 'Availability'
            ? 'avail-vehicle-view'
            : ftr[0] === 'Reliability'
              ? 'reli-vehicle-view'
              : ftr[0] === 'Utilization'
                ? 'util-vehicle-view'
                : 'avail-vehicle-view',
      eventFormValues: {
        pecIP: '',
        producttype: '',
        exchangetype: 'ESS',
        oessan: '',
        nessan: '',
        oesssn: '',
        nesssn: '',
        opecan: '',
        npecan: '',
        opecsn: '',
        npecsn: '',
        comments: '',
        prepecan: '',
        prepecsn: '',
      },
      expandSide: true,
      isCopied: false,
    };

    if (
      sessionStorage.getItem('selectedFleet') === null ||
      sessionStorage.getItem('selectedSite') === null
    )
      this.props.history.push('/FleetView');

    userConfig = configUser();
  }

  updateStateFromUrl() {
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const ftrParam = urlSearchParams.get('ftr');
    if (ftrParam) {
      const ftrValues = ftrParam.split(',');
      this.setState({
        filterItems: {
          availability: ftrValues[0] !== '' ? ftrValues[0] : '',
          reliability: ftrValues[1] !== '' ? ftrValues[1] : '',
          utilization: ftrValues[2] !== '' ? ftrValues[2] : '',
        },
      });
    } else {
      this.setState({
        filterItems: {
          availability: ftr[0] !== null ? ftr[0] : '',
          reliability: ftr[1] !== null ? ftr[1] : '',
          utilization: ftr[2] !== null ? ftr[2] : '',
        },
      });
    }
  }

  componentDidMount() {
    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    this.props.setAllEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    this.props.monitoredCountData === null && this.props.getMonitoredCount();
    this.props.getOverallConditionData();
    this.props.fleetsListData === null &&
      this.props.getFleetsList('All', 'All', 'All', true, true, true);
    this.props.vehiclesListData === null &&
      this.props.getVehiclesList(
        sessionStorage.getItem('selectedSite'),
        sessionStorage.getItem('selectedFleet')
      );

    this.props.unmountedESSListData === null &&
      this.props.fetchUnmountedESSList(
        sessionStorage.getItem('selectedSite'),
        sessionStorage.getItem('selectedFleet')
      );

    this.onVehicleViewTabClick(this.state.selectedTab);

    this.props.fetchVehicleDetails(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle')
    );

    if (
      platformVersion() === '3' &&
      sessionStorage.getItem('selectedVehicleProduct') !== 'null' &&
      sessionStorage.getItem('selectedVehicleProduct') !== null
    ) {
      this.props.fetchBatteryOverallConditionData(
        sessionStorage.getItem('selectedSite'),
        sessionStorage.getItem('selectedFleet'),
        sessionStorage.getItem('selectedVehicle'),
        sessionStorage.getItem('selectedVehicleProduct')
      );
    }

    // this.props.vehicleProductListData === null &&
    //   this.props.getVehicleProductList(
    //     sessionStorage.getItem("selectedSite"),
    //     sessionStorage.getItem("selectedFleet"),
    //     sessionStorage.getItem("selectedVehicle"),
    //     true,
    //     true,
    //     true
    //   );

    if (
      sessionStorage.getItem('Sidemenuscroll') !== null &&
      document.getElementById('scrollvplist') != null
    ) {
      document.getElementById('scrollvplist').scrollTop =
        sessionStorage.getItem('Sidemenuscroll');
    }
    this.props.fetchMessages();
  }

  /* componentDidUpdate() {
    //  scroll to active vehicle
    var element = document.querySelector(".active-site");
    if (typeof element != "undefined" && element != null) {
      element.scrollIntoView(false);
    }
  } */
  componentDidUpdate(prevProps, prevState) {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(window.location.search);
    const prevSearchParams = new URLSearchParams(prevProps.location.search);
    if (this.props.diagnosticEventData !== null) {
      if (
        url.searchParams.get('vtb') === 'vehicle-diagnostic' &&
        (prevProps.diagnosticEventData.pageIndex !==
          this.props.diagnosticEventData.pageIndex ||
          prevProps.diagnosticEventData.uniqueValue !==
            this.props.diagnosticEventData.uniqueValue ||
          prevProps.diagnosticEventData.pageSize !==
            this.props.diagnosticEventData.pageSize ||
          prevSearchParams.get('vtb') !== searchParams.get('vtb') ||
          prevSearchParams.get('pdn') !== searchParams.get('pdn'))
      ) {
        this.props.fetchDiagnosticList(
          url.searchParams.get('ft'),
          url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
          url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
          url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
          url.searchParams.get('sdt'),
          url.searchParams.get('edt'),
          new URL(window.location.href).searchParams.get('dfdt') !== null
            ? new URL(window.location.href).searchParams.get('dfdt')
            : 'Past 90 days',
          this.props.diagnosticEventData.uniqueKey,
          this.props.diagnosticEventData.uniqueValue,
          url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
          this.props.diagnosticEventData.pageSize
            ? this.props.diagnosticEventData.pageSize
            : 20,
          this.props.diagnosticEventData.pageIndex
            ? this.props.diagnosticEventData.pageIndex
            : 0,
          !this.props.diagnosticEventData.sortValue &&
            this.props.diagnosticEventData.sortValueKey
            ? 1
            : 0,
          this.props.diagnosticEventData.sortValueKey
            ? this.props.diagnosticEventData.sortValueKey
            : ''
        );
      }
    }
    if (this.props.eventData !== null) {
      if (
        url.searchParams.get('vtb') === 'vehicle-eventlist' &&
        (prevProps.eventData.pageIndex !== this.props.eventData.pageIndex ||
          prevProps.eventData.pageSize !== this.props.eventData.pageSize ||
          prevProps.eventData.uniqueValue !==
            this.props.eventData.uniqueValue ||
          prevProps.eventData.sortValueKey !==
            this.props.eventData.sortValueKey ||
          prevProps.eventData.sortValue !== this.props.eventData.sortValue)
      ) {
        this.props.fetchAllEventList(
          url.searchParams.get('ft'),
          url.searchParams.get('vc'),
          url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
          url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
          new URL(window.location.href).searchParams.get('efsd') !== null
            ? new URL(window.location.href).searchParams.get('efsd')
            : Moment(new Date(Moment().subtract(1, 'days').startOf('day')))
                .format('YYYY-MM-DDTHH:mm:ss')
                .replace(/:/g, '%3A'),
          new URL(window.location.href).searchParams.get('efed') !== null
            ? new URL(window.location.href).searchParams.get('efed')
            : Moment(new Date(Moment().subtract(1, 'days').endOf('day')))
                .format('YYYY-MM-DDTHH:mm:ss')
                .replace(/:/g, '%3A'),
          new URL(window.location.href).searchParams.get('efdt') !== null
            ? new URL(window.location.href).searchParams.get('efdt')
            : 'Any time',
          this.props.eventData.uniqueKey,
          this.props.eventData.uniqueValue,
          this.props.eventData.pageSize,
          this.props.eventData.pageIndex,
          !this.props.eventData.sortValue && this.props.eventData.sortValueKey
            ? 1
            : 0,
          this.props.eventData.sortValueKey
            ? this.props.eventData.sortValueKey
            : 'dt'
        );
      }
    }

    if (prevSearchParams.get('vtb') !== searchParams.get('vtb')) {
      this.onVehicleViewTabClick(searchParams.get('vtb'));

      this.setState({
        selectedTab: searchParams.get('vtb') || 'Overview-vehicle-view',
      });
    }
    if (prevSearchParams.get('stb') !== searchParams.get('stb')) {
      this.setState({
        selectedOverviewTab: searchParams.get('stb') || 'avail-vehicle-view',
      });
    }
    if (prevSearchParams.get('ftr') !== searchParams.get('ftr')) {
      this.updateStateFromUrl();
    }
    if (prevSearchParams.get('vc') !== searchParams.get('vc')) {
      this.onVehicleListClick(searchParams.get('vc'));
      this.onVehicleViewTabClick(searchParams.get('vtb'));
      url.searchParams.delete('pcft');
      url.searchParams.delete('bpft');
      url.searchParams.delete('ip');
      url.searchParams.delete('pnft');
      window.history.pushState(null, null, url);
    }
    // if (prevSearchParams.get("vtb") !== searchParams.get("vtb")) {
    //   this.onVehicleViewTabClick(searchParams.get("vtb"));
    // }
    if (prevSearchParams.get('pdn') !== searchParams.get('pdn')) {
      this.onVehicleListClick(searchParams.get('vc'));
      url.searchParams.delete('pcft');
      url.searchParams.delete('bpft');
      url.searchParams.delete('ip');
      url.searchParams.delete('pnft');
      window.history.pushState(null, null, url);
    }
  }

  componentWillUnmount() {
    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    this.props.setAllEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
  }

  UNSAFE_componentWillReceiveProps(newProps, prevState) {
    if (this.props.fleetsListData !== newProps.fleetsListData) {
      if (
        platformVersion() === '3' &&
        sessionStorage.getItem('selectedVehicleProduct') !== 'null' &&
        sessionStorage.getItem('selectedVehicleProduct') !== null
      ) {
        this.props.fetchBatteryOverallConditionData(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet'),
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct')
        );
      }
    }

    if (this.props.vehiclesListData !== newProps.vehiclesListData) {
      if (sessionStorage.getItem('selectedVehicle') !== 'null') {
        // this.props.getVehicleProductList(
        //   sessionStorage.getItem("selectedSite"),
        //   sessionStorage.getItem("selectedFleet"),
        //   sessionStorage.getItem("selectedVehicle"),
        //   true,
        //   true,
        //   true
        // );
      } else {
        if (newProps.vehiclesListData !== null)
          if (newProps.vehiclesListData.length !== 0) {
            sessionStorage.setItem(
              'selectedVehicle',
              newProps.vehiclesListData[0].vn
            );
          }
      }
    }

    if (
      this.props.batteryOverallConditionData !==
      newProps.batteryOverallConditionData
    ) {
      if (newProps.batteryOverallConditionData !== null) {
        if (
          sessionStorage.getItem('cd') === '0X002' &&
          sessionStorage.getItem('rsn') === 'Change Request' &&
          sessionStorage.getItem('sts') === 'Pending'
        ) {
          this.setState({
            eventFormValues: {
              ...this.state.eventFormValues,
              pecIP: sessionStorage.getItem('ip'),
              producttype: newProps.batteryOverallConditionData[0].typ,
              oessan: '',
              oesssn: '',
              opecan: '',
              opecsn: '',
            },
          });
        } else {
          sessionStorage.setItem(
            'nessan',
            newProps.batteryOverallConditionData[0].bpa
          );
          this.setState({
            eventFormValues: {
              ...this.state.eventFormValues,
              pecIP: newProps.batteryOverallConditionData[0].ip,
              producttype: newProps.batteryOverallConditionData[0].typ,
              oessan: newProps.batteryOverallConditionData[0].bpa,
              nessan: newProps.batteryOverallConditionData[0].bpa,
              oesssn: newProps.batteryOverallConditionData[0].bps,
              opecan: newProps.batteryOverallConditionData[0].pa,
              opecsn: newProps.batteryOverallConditionData[0].ps,
            },
          });
        }
      }
    }
    if (this.props.saveEventResponce !== newProps.saveEventResponce) {
      if (newProps.saveEventResponce !== null) {
        if (newProps.saveEventResponce[0].sts === true) {
          setTimeout(() => {
            this.props.history.push(
              '/FleetView?st=' +
                sessionStorage.getItem('selectedSite') +
                '&ft=' +
                sessionStorage.getItem('selectedFleet') +
                '&sdt=' +
                this.props.selectedStartDate +
                '&edt=' +
                this.props.selectedEndDate
            );
          }, 3000);
        }
      }
    }
    if (this.props.undoEventResponce !== newProps.undoEventResponce) {
      if (newProps.undoEventResponce !== null) {
        if (newProps.undoEventResponce[0].sts === true) {
          setTimeout(() => {
            this.props.clearUndoEventResponce();
            this.props.history.push(
              '/FleetView?st=' +
                sessionStorage.getItem('selectedSite') +
                '&ft=' +
                sessionStorage.getItem('selectedFleet') +
                '&sdt=' +
                this.props.selectedStartDate +
                '&edt=' +
                this.props.selectedEndDate
            );
          }, 3000);
        }
      }
    }

    this.props.vehiclesListData !== null &&
      newProps.vehiclesListData !== null &&
      this.state.searchQuery !== '' &&
      this.handleSearchInputChange(this.state.searchQuery);
  }

  onFleetListClick = async (selectedFleet, selectedSite, selectedFleetType) => {
    // this.props.setFleetName(selectedFleet);
    await this.props.clearEventList();
    sessionStorage.setItem('selectedFleet', selectedFleet);
    sessionStorage.setItem('selectedFleetType', selectedFleetType);
    sessionStorage.setItem('selectedSite', selectedSite);
    sessionStorage.setItem('selectedVehicle', null);
    /* if (this.state.filterItems.selectedSearchKey === "Vehicles") {
      if (
        this.state.filterItems.isGoodSlected == false &&
        this.state.filterItems.isPoorSelected == false &&
        this.state.filterItems.isUnknownSelected == false
      ) {
        this.props.getVehiclesList(
          sessionStorage.getItem("selectedSite"),
          selectedFleet
        );
      } else {
        this.props.getVehiclesList(
          sessionStorage.getItem("selectedSite"),
          selectedFleet
        );
      }
    } else {
      this.props.getVehiclesList(
        sessionStorage.getItem("selectedSite"),
        selectedFleet
      );
    } */
    // this.props.getOverviewData(selectedFleet);
    this.props.getOverallConditionData(
      'All',
      'All',
      selectedFleet,
      true,
      true,
      true
    );

    this.props.history.push(
      '/FleetView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet') +
        '&fty=' +
        selectedFleetType +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate +
        '&tb=Overview'
    );
    // alert("update fleet data");
  };

  onVehicleListClickRedirectionHandler = (
    selectedVehicle,
    selected_Product = null,
    selectedP_Dname = null
  ) => {
    const url = new URL(window.location.href);
    url.searchParams.set('vc', selectedVehicle);
    if (selected_Product !== null) {
      url.searchParams.set('vp', selected_Product);
      url.searchParams.set('pdn', selectedP_Dname);
    } else {
      url.searchParams.delete('vp');
      url.searchParams.delete('pdn');
    }
    url.searchParams.delete('vrp');
    url.searchParams.delete('vsp');
    url.searchParams.delete('vrbrmn');
    url.searchParams.delete('vrbrmx');
    url.searchParams.delete('vsbrmn');
    url.searchParams.delete('vsbrmx');
    // window.history.pushState(null, null, url);
    this.props.history.push(url.pathname + url.search);
  };

  onVehicleListClick = async selectedVehicle => {
    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    this.props.setAllEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
    });
    const url = new URL(window.location.href);
    if (url.searchParams.get('vp') === null) {
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem('selectedVehicleProduct', null);
      sessionStorage.setItem('selectedProductDname', null);
      sessionStorage.setItem('selectedProductIP', null);
      sessionStorage.setItem('selectedPack', null);
      sessionStorage.setItem('selectedPackDname', null);
      await this.props.clearVehicleViewData();
      await this.props.clearEventList();
      this.onVehicleViewTabClick(this.state.selectedTab);
      this.props.fetchVehicleDetails(
        sessionStorage.getItem('selectedSite'),
        sessionStorage.getItem('selectedFleet'),
        selectedVehicle
      );
    } else {
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem(
        'selectedVehicleProduct',
        url.searchParams.get('vp')
      );
      sessionStorage.setItem(
        'selectedProductDname',
        url.searchParams.get('pdn')
      );
      sessionStorage.setItem('selectedProductIP', null);
      sessionStorage.setItem('selectedPack', null);
      sessionStorage.setItem('selectedPackDname', null);
      await this.props.clearVehicleViewData();
      await this.props.clearEventList();
      this.onVehicleViewTabClick(this.state.selectedTab);
      if (
        platformVersion() === '3' &&
        sessionStorage.getItem('selectedVehicleProduct') !== 'null' &&
        sessionStorage.getItem('selectedVehicleProduct') !== null
      ) {
        this.props.fetchBatteryOverallConditionData(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet'),
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct')
        );
      }
    }
  };

  onVehicleProductListClickRedirection = (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedVehicleProductBPU,
    selectedType
  ) => {
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    sessionStorage.setItem(
      'selectedVehicleProductBPU',
      selectedVehicleProductBPU
    );
    const routeTo =
      selectedType === 'converter'
        ? '/FleetView/VehicleView/TractionConverterView?st='
        : '/FleetView/VehicleView/VehicleProductsView?st=';
    this.props.history.push(
      routeTo +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet') +
        '&fty=' +
        sessionStorage.getItem('selectedFleetType') +
        '&vc=' +
        sessionStorage.getItem('selectedVehicle') +
        '&vp=' +
        selectedVehicleProduct +
        '&pip=' +
        selectedProductIP +
        '&pdn=' +
        selectedProductDname +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate +
        '&unmas=' +
        sessionStorage.getItem('unmountedESSclicked') +
        '&ftr=' +
        [
          this.state.filterItems.availability,
          this.state.filterItems.reliability,
          this.state.filterItems.utilization,
        ] +
        '&srch=' +
        this.state.searchQuery +
        '&bpu=' +
        selectedVehicleProductBPU +
        '&tb=Overview-vehicleprod-view' +
        '&tto=' +
        'Power'
    );
    // document.getElementById("scrollvplist") !== null &&
    // sessionStorage.setItem(
    //   "Sidemenuscroll",
    //   document.getElementById("scrollvplist").scrollTop
    // );
    // sessionStorage.setItem("directlink", "false");
    // sessionStorage.setItem("selectedVehicle", selectedVehicle);
    // sessionStorage.setItem("selectedVehicleProduct", selectedVehicleProduct);
    // sessionStorage.setItem("selectedProductIP", selectedProductIP);
    // sessionStorage.setItem("selectedProductDname", selectedProductDname);
    // sessionStorage.setItem(
    //   "selectedVehicleProductBPU",
    //   selectedVehicleProductBPU
    // );
  };

  onVehicleProductListClick = async (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedVehicleProductBPU
  ) => {
    document.getElementById('scrollvplist') !== null &&
      sessionStorage.setItem(
        'Sidemenuscroll',
        document.getElementById('scrollvplist').scrollTop
      );
    sessionStorage.setItem('directlink', 'false');

    // this.props.getModuleList(
    //   sessionStorage.getItem("selectedSite"),
    //   sessionStorage.getItem("selectedFleet"),
    //   sessionStorage.getItem("selectedVehicle"),
    //   selectedVehicleProduct,
    //   true,
    //   true,
    //   true
    // );
    await this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    await this.props.setAllEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
    });
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();
  };

  onVehicleProductPackClick = async (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedPack,
    selectedPackDname
  ) => {
    document.getElementById('scrollvplist') !== null &&
      sessionStorage.setItem(
        'Sidemenuscroll',
        document.getElementById('scrollvplist').scrollTop
      );
    sessionStorage.setItem('directlink', 'false');
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    sessionStorage.setItem('selectedPack', selectedPack);
    sessionStorage.setItem('selectedPackDname', selectedPackDname);
    // this.props.getModuleList(
    //   sessionStorage.getItem("selectedSite"),
    //   sessionStorage.getItem("selectedFleet"),
    //   sessionStorage.getItem("selectedVehicle"),
    //   selectedVehicleProduct,
    //   true,
    //   true,
    //   true
    // );
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();

    this.props.history.push(
      '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet') +
        '&fty=' +
        sessionStorage.getItem('selectedFleetType') +
        '&vc=' +
        sessionStorage.getItem('selectedVehicle') +
        '&vp=' +
        selectedVehicleProduct +
        '&pk=' +
        sessionStorage.getItem('selectedPack') +
        '&pkdn=' +
        sessionStorage.getItem('selectedPackDname') +
        '&pip=' +
        selectedProductIP +
        '&pdn=' +
        selectedProductDname +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate +
        '&unmas=' +
        sessionStorage.getItem('unmountedESSclicked') +
        '&ftr=' +
        [
          this.state.filterItems.availability,
          this.state.filterItems.reliability,
          this.state.filterItems.utilization,
        ] +
        '&srch=' +
        this.state.searchQuery +
        '&tb=Overview-vehicleprod-view'
    );
  };

  onVehicleViewStatusTabClick = tab => {
    this.setState({ selectedOverviewTab: tab }, () => {
      // this.onVehicleViewTabClick(this.state.selectedTab);
    });
    // sessionStorage.setItem("stb", tab);
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('stb', tab);
      this.props.history.push(url.pathname + url.search);
    }
  };

  onVehicleViewTabClickRedirection = tab => {
    const url = new URL(window.location.href);
    url.searchParams.set('vtb', tab);
    this.props.history.push(url.pathname + url.search);
  };

  onVehicleViewTabClick = tab => {
    this.setState({ selectedTab: tab });
    const url = new URL(window.location.href);
    // if (window.location.href.indexOf("?") > -1) {
    //    url.searchParams.set("vtb", tab);
    //   this.props.history.push(url.pathname + url.search);

    // }
    if (tab === 'Overview-vehicle-view') {
      // this.props.getOverviewData();
      this.props.vehicleAvailData === null &&
        this.props.getVehicleAvailData(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet'),
          sessionStorage.getItem('selectedVehicle')
        );
      this.props.vehicleRelData === null &&
        this.props.getVehicleRelData(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet'),
          sessionStorage.getItem('selectedVehicle')
        );
      this.props.vehicleStrData === null &&
        this.props.getVehicleStrData(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet'),
          sessionStorage.getItem('selectedVehicle')
        );
    } else if (tab === 'vehicle-eventlist') {
      // this.props.eventList === null &&
      this.props.fetchAllEventList(
        url.searchParams.get('ft'),
        url.searchParams.get('vc'),
        url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
        url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
        new URL(window.location.href).searchParams.get('efsd') !== null
          ? new URL(window.location.href).searchParams.get('efsd')
          : Moment(new Date(Moment().subtract(1, 'days').startOf('day')))
              .format('YYYY-MM-DDTHH:mm:ss')
              .replace(/:/g, '%3A'),
        new URL(window.location.href).searchParams.get('efed') !== null
          ? new URL(window.location.href).searchParams.get('efed')
          : Moment(new Date(Moment().subtract(1, 'days').endOf('day')))
              .format('YYYY-MM-DDTHH:mm:ss')
              .replace(/:/g, '%3A'),
        new URL(window.location.href).searchParams.get('efdt') !== null
          ? new URL(window.location.href).searchParams.get('efdt')
          : 'Any time',
        'All',
        'All',
        this.props.eventData && this.props.eventData.pageSize,
        this.props.eventData && this.props.eventData.pageIndex,
        !this.props.eventData.sortValue && this.props.eventData.sortValueKey
          ? 1
          : 0,
        this.props.eventData.sortValueKey
          ? this.props.eventData.sortValueKey
          : 'dt'
      );
    } else if (tab === 'vehicle-diagnostic') {
      this.props.setDiagnosticEventListData({
        pageSize: 20,
        pageIndex: 0,
        uniqueKey: 'All',
        uniqueValue: 'All',
        sortValue: true,
        sortValueKey: 'dt',
      });
      // this.props.diagnosticList === null &&
      this.props.fetchDiagnosticList(
        url.searchParams.get('ft'),
        url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
        url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
        url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
        url.searchParams.get('sdt'),
        url.searchParams.get('edt'),
        new URL(window.location.href).searchParams.get('dfdt') !== null
          ? new URL(window.location.href).searchParams.get('dfdt')
          : 'Past 90 days',
        this.props.diagnosticEventData.uniqueKey,
        this.props.diagnosticEventData.uniqueValue,
        url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
        this.props.diagnosticEventData.pageSize
          ? this.props.diagnosticEventData.pageSize
          : 20,
        this.props.diagnosticEventData.pageIndex
          ? this.props.diagnosticEventData.pageIndex
          : 0,
        !this.props.diagnosticEventData.sortValue &&
          this.props.diagnosticEventData.sortValueKey
          ? 1
          : 0,
        this.props.diagnosticEventData.sortValueKey
          ? this.props.diagnosticEventData.sortValueKey
          : ''
      );
    } else if (tab === 'Energy-throughput-vehicle-view') {
      this.props.getBoxPlotData();
    } else if (tab === 'SoC-range-usage-vehicle-view') {
      this.props.getHistogramData();
    } else if (tab === 'State-of-charge-vehicle-view') {
      this.props.getHistogramData();
    }
  };

  updateVehicleData = () => {
    alert('update vehicle data');
  };

  copyLinkDiagnosticTab = async obj => {
    copyToClipboard(
      obj.pt,
      'File location path copied to clipboard',
      'Failed to copy File location path to clipboard'
    );
  };

  redirectToTimetrendFrmDiagnostic = async obj => {
    const { bp, pn, pk } = obj;
    // const url = new URL(window.location.href);

    const formdtdDate = Moment.utc(obj.dt).format('YYYY-MM-DD');
    const time = Moment().startOf('day').format('HH:mm:ss');
    const time1 = Moment().endOf('day').format('HH:mm:ss');
    const startDate = formdtdDate + ' ' + time;
    const endDate = formdtdDate + ' ' + time1;
    const platform = platformVersion();
    const selectedPack = pk;
    const selectedPackDisplayName = pn;
    // let scuID = obj.pn && parseInt(obj.pn.match(/\d+/)[0]);
    // let selectedVehicle = url.searchParams.get("ft") + "_" + obj.vno; //"WINK_ARRIVA_601";
    const scuID = obj.pn && parseInt(obj.pn.match(/\d+/)[0]);
    const selectedVehicle = obj.vno; // "WINK_ARRIVA_601";
    const selectedVehicleProduct = obj.pcf; // "3BHE046683R01012438";
    const selectedProductIP = obj.ip; // "10.1.1.116"; //
    const selectedProductDnameP3 = bp; // obj.pt + "_" + obj.esn; //"ESS_HP_001A02_17"; //
    const selectedProductDname = pn || bp;
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    const bpu = getbpu(obj.pc, this.props.vehiclesListData); // obj.pun//3BHE046683R01012438

    await this.props.setMainTrendSignal('Diagnostic Event');
    directlinktrendSignalCheck('Battery Current')
      ? await this.props.addOverlayTrendSignal(['Battery Current'])
      : await this.props.addOverlayTrendSignal([]);
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();

    let tempParameter = '';
    directlinktrendSignalCheck('Battery Current')
      ? (tempParameter = '&tto=Battery Current')
      : (tempParameter = '');

    if (platform === '3') {
      this.props.history.push(
        '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&vp=' +
          selectedVehicleProduct +
          '&pk=' +
          selectedPack +
          '&pkdn=' +
          selectedPackDisplayName +
          '&pip=' +
          selectedProductIP +
          '&pdn=' +
          selectedProductDnameP3 +
          '&sdt=' +
          startDate +
          '&edt=' +
          endDate +
          '&unmas=false' +
          '&ttm=Diagnostic Event' +
          tempParameter +
          '&tb=Timetrend-vehicleprod-view' +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&cdty=Custom' +
          '&scuID=' +
          scuID +
          '&bpu=' +
          bpu
      );
    } else {
      this.props.history.push(
        '/FleetView/VehicleView/VehicleProductsView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          sessionStorage.getItem('selectedVehicle') +
          '&vp=' +
          selectedVehicleProduct +
          '&pip=' +
          selectedProductIP +
          '&pdn=' +
          selectedProductDname +
          '&sdt=' +
          startDate +
          '&edt=' +
          endDate +
          '&ttm=Diagnostic Event' +
          tempParameter +
          '&tb=Timetrend-vehicleprod-view' +
          '&unmas=false' +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&bpu=' +
          bpu +
          '&cdty=Custom'
      );
    }
  };

  onDatePickerButtonClick = clickedButton => {
    if (clickedButton === 'Monthly') {
      this.setState(prevState => ({
        isMonthlyactive: !prevState.isMonthlyactive,
        isWeeklyactive: false,
        isDailyactive: false,
      }));
    } else if (clickedButton === 'Weekly') {
      this.setState(prevState => ({
        isWeeklyactive: !prevState.isWeeklyactive,
        isMonthlyactive: false,
        isDailyactive: false,
      }));
    } else if (clickedButton === 'Daily') {
      this.setState(prevState => ({
        isDailyactive: !prevState.isDailyactive,
        isMonthlyactive: false,
        isWeeklyactive: false,
      }));
    }
  };

  updateFilteredData = () => {
    if (this.state.filterItems.selectedSearchKey === 'Fleets') {
      // this.props.setFleetName(null);
      sessionStorage.setItem('selectedFleet', 'null');
      /* if (
        this.state.filterItems.isGoodSlected == false &&
        this.state.filterItems.isPoorSelected == false &&
        this.state.filterItems.isUnknownSelected == false
      ) {
        this.props.getFleetsList("All", "All", "All", true, true, true);
      } else {
        this.props.getFleetsList(
          "All",
          "All",
          "All",
          this.state.filterItems.isGoodSlected,
          this.state.filterItems.isPoorSelected,
          this.state.filterItems.isUnknownSelected
        );
      } */
      // this.props.getVehiclesList(null, true, true, true);
      this.props.history.push('/FleetView');
    }
    if (this.state.filterItems.selectedSearchKey === 'Vehicles') {
      sessionStorage.setItem('selectedVehicle', null);
      if (
        this.state.filterItems.isGoodSlected === false &&
        this.state.filterItems.isPoorSelected === false &&
        this.state.filterItems.isUnknownSelected === false
      ) {
        this.props.getVehiclesList(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet')
        );
      } else {
        this.props.getVehiclesList(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet')
        );
      }
    }
  };

  sideMenuFilter = (key, value, isAutoClick = false) => {
    if (key === 'reset') {
      this.setState(
        prevState => ({
          filterItems: {
            ...prevState.filterItems,
            availability: '',
            reliability: '',
            utilization: '',
            // isAutoClick: false,
          },
        }),
        () => {
          this.updateFilteredData();
          setTimeout(() => {
            this.setState(prevState => ({
              filterItems: {
                ...prevState.filterItems,
                // isAutoClick: false,
              },
            }));
          }, 0);
          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            const temp = [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ];
            url.searchParams.set('ftr', temp);
            this.props.history.push(url.pathname + url.search);
          }
        }
      );
    } else {
      this.setState(
        prevState => ({
          filterItems: {
            ...prevState.filterItems,
            [key]: value,
            // isAutoClick: isAutoClick,
          },
        }),
        () => {
          this.updateFilteredData();
          setTimeout(() => {
            this.setState(prevState => ({
              filterItems: {
                ...prevState.filterItems,
                // isAutoClick: false,
              },
            }));
          }, 0);
          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            const temp = [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ];
            url.searchParams.set('ftr', temp);
            this.props.history.push(url.pathname + url.search);
          }
        }
      );
    }
  };

  handleFilterDropdownChange = value => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          selectedSearchKey: value,
          isGoodSlected: false,
          isPoorSelected: false,
          isUnknownSelected: false,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          url.searchParams.set(
            'stb',
            value === 'Availability'
              ? 'avail-vehicle-view'
              : value === 'Reliability'
                ? 'reli-vehicle-view'
                : value === 'Utilization'
                  ? 'util-vehicle-view'
                  : 'avail-vehicle-view'
          );
          this.setState({
            selectedOverviewTab:
              value === 'Availability'
                ? 'avail-vehicle-view'
                : value === 'Reliability'
                  ? 'reli-vehicle-view'
                  : value === 'Utilization'
                    ? 'util-vehicle-view'
                    : 'avail-vehicle-view',
          });
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onGoodFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isGoodSlected: !this.state.filterItems.isGoodSlected,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onPoorFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isPoorSelected: !this.state.filterItems.isPoorSelected,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onUnknownFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isUnknownSelected: !this.state.filterItems.isUnknownSelected,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onFavoriteFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isFavoriteSelected: !this.state.filterItems.isFavoriteSelected,
        },
      }),
      () => {
        // this.filterFleetData();
      }
    );
  };

  onListSelectionClick = (e, selection) => {
    if (selection === 'vehicle') {
      if (e.target.checked === true && this.props.selectedList === 'product')
        this.props.setListSelection('vehicleproduct');
      else if (e.target.checked === true)
        this.props.setListSelection('vehicle');
      else this.props.setListSelection('product');
    } else if (selection === 'product') {
      if (e.target.checked === true && this.props.selectedList === 'vehicle')
        this.props.setListSelection('vehicleproduct');
      else if (e.target.checked === true)
        this.props.setListSelection('product');
      else this.props.setListSelection('vehicle');
    }
  };

  redirectToTimetrend = async (point, signal) => {
    if (platformVersion() === '3') {
      const formdtdDate = Moment(point.category, 'DD.MM.YYYY').format(
        'YYYY-MM-DD'
      );
      const time = Moment().startOf('day').format('HH:mm:ss');
      const time1 = Moment().endOf('day').format('HH:mm:ss');
      const startDate = formdtdDate + ' ' + time;
      const endDate = formdtdDate + ' ' + time1;
      const bpu = getbpu(
        sessionStorage.getItem('selectedVehicleProduct'),
        this.props.vehiclesListData
      );

      const selectedVehicle = sessionStorage.getItem('selectedVehicle');
      const selectedPack = point.series.userOptions.an;

      const selectedPackDname = point.series.userOptions.stack;
      sessionStorage.setItem('selectedVehicle', selectedVehicle);

      sessionStorage.setItem('selectedPack', selectedPack);
      sessionStorage.setItem('selectedPackDname', selectedPackDname);

      if (
        signal === 'nameClicked' ||
        signal === 'dateClicked' ||
        signal === 'pnameClicked'
      ) {
        await this.props.setMainTrendSignal('State of Charge');
        await this.props.addOverlayTrendSignal(['Power']);
        await this.props.clearOverviewata();
        await this.props.clearChartData();
        await this.props.clearEventList();

        await this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            sessionStorage.getItem('selectedVehicleProduct') +
            '&pdn=' +
            sessionStorage.getItem('selectedProductDname') +
            '&pip=' +
            sessionStorage.getItem('selectedProductIP') +
            '&pk=' +
            sessionStorage.getItem('selectedPack') +
            '&pkdn=' +
            sessionStorage.getItem('selectedPackDname') +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=State of Charge' +
            '&tto=Power' +
            '&tb=Timetrend-vehicleprod-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&snav=' +
            this.state.expandSide +
            '&bpu=' +
            bpu
        );
      } else if (signal === 'sRedirect') {
        await this.props.history.push(
          '/FleetView/VehicleView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&fty=' +
            sessionStorage.getItem('selectedFleetType') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&vtb=Overview-vehicle-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&stb=' +
            new URL(window.location.href).searchParams.get('stb') +
            '&vp=' +
            sessionStorage.getItem('selectedPack') +
            '&pdn=' +
            sessionStorage.getItem('selectedPackDname')
        );
      } else {
        await this.props.setMainTrendSignal(signal);
        await this.props.addOverlayTrendSignal([]);
        await this.props.clearOverviewata();
        await this.props.clearChartData();
        await this.props.clearEventList();

        await this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            sessionStorage.getItem('selectedVehicleProduct') +
            '&pdn=' +
            sessionStorage.getItem('selectedProductDname') +
            '&pip=' +
            sessionStorage.getItem('selectedProductIP') +
            '&pk=' +
            sessionStorage.getItem('selectedPack') +
            '&pkdn=' +
            sessionStorage.getItem('selectedPackDname') +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=' +
            signal +
            '&tb=Timetrend-vehicleprod-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&snav=' +
            this.state.expandSide +
            '&bpu=' +
            bpu
        );
      }
    } else {
      const formdtdDate = Moment(point.category, 'DD.MM.YYYY').format(
        'YYYY-MM-DD'
      );
      const time = Moment().startOf('day').format('HH:mm:ss');
      const time1 = Moment().endOf('day').format('HH:mm:ss');
      const startDate = formdtdDate + ' ' + time;
      const endDate = formdtdDate + ' ' + time1;

      const bpu = getbpu(
        point.series.userOptions.an,
        this.props.vehiclesListData
      );

      const selectedVehicle = sessionStorage.getItem('selectedVehicle');
      const selectedVehicleProduct = point.series.userOptions.an;
      const selectedProductIP = point.series.userOptions.ip;
      const selectedProductDname = point.series.userOptions.stack;
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
      sessionStorage.setItem('selectedProductIP', selectedProductIP);
      sessionStorage.setItem('selectedProductDname', selectedProductDname);

      if (
        signal === 'nameClicked' ||
        signal === 'dateClicked' ||
        signal === 'pnameClicked'
      ) {
        await this.props.setMainTrendSignal('State of Charge');
        await this.props.addOverlayTrendSignal(['Power']);
        await this.props.clearOverviewata();
        await this.props.clearChartData();
        await this.props.clearEventList();

        await this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            selectedVehicleProduct +
            '&pdn=' +
            selectedProductDname +
            '&pip=' +
            selectedProductIP +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=State of Charge' +
            '&tto=Power' +
            '&tb=Timetrend-vehicleprod-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&snav=' +
            this.state.expandSide +
            '&bpu=' +
            bpu
        );
      } else {
        await this.props.setMainTrendSignal(signal);
        await this.props.addOverlayTrendSignal([]);
        await this.props.clearOverviewata();
        await this.props.clearChartData();
        await this.props.clearEventList();

        await this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            selectedVehicleProduct +
            '&pdn=' +
            selectedProductDname +
            '&pip=' +
            selectedProductIP +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=' +
            signal +
            '&tb=Timetrend-vehicleprod-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&snav=' +
            this.state.expandSide +
            '&bpu=' +
            bpu
        );
      }
    }
  };

  redirectToTimetdOnStreesEvtClk = async obj => {
    const formdtdDate = Moment.utc(obj.dt).format('YYYY-MM-DD');
    const time = Moment().startOf('day').format('HH:mm:ss');
    const time1 = Moment().endOf('day').format('HH:mm:ss');

    const startDate = formdtdDate + ' ' + time;
    const endDate = formdtdDate + ' ' + time1;
    let evtSignal = 'Cycles';
    if (obj.rsn === 'C-Rate') {
      evtSignal = 'Battery Current';
    } else if (obj.rsn === 'Temperature') {
      evtSignal = 'Temperature';
    } else if (obj.rsn === 'SOC') {
      evtSignal = 'State of Charge';
    }
    const platform = platformVersion();
    const selectedVehicle = sessionStorage.getItem('selectedVehicle');
    const selectedVehicleProduct = obj.pun;
    const selectedProductIP = obj.ip;
    const selectedProductDname = obj.bp;
    const selectedPack = obj.pk;
    const selectedPackDname = obj.pn;
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);

    const bpu = obj.ean + obj.esn; // getbpu(obj.pun, this.props.vehiclesListData);

    if (evtSignal === 'Cycles') {
      await this.props.setMainTrendSignal('State of Charge');
      await this.props.addOverlayTrendSignal(['Power']);
      await this.props.clearOverviewata();
      await this.props.clearChartData();
      await this.props.clearEventList();
      if (platform === '3') {
        await this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&fty=' +
            sessionStorage.getItem('selectedFleetType') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            selectedVehicleProduct +
            '&pk=' +
            selectedPack +
            '&pkdn=' +
            selectedPackDname +
            '&pip=' +
            selectedProductIP +
            '&pdn=' +
            selectedProductDname +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=State of Charge' +
            '&tto=Power' +
            '&tb=Timetrend-vehicleprod-view' +
            '&unmas=false' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&bpu=' +
            bpu
        );
      } else {
        await this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            selectedVehicleProduct +
            '&pdn=' +
            selectedProductDname +
            '&pip=' +
            selectedProductIP +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=State of Charge' +
            '&tto=Power' +
            '&tb=Timetrend-vehicleprod-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&bpu=' +
            bpu
        );
      }
    } else {
      await this.props.setMainTrendSignal(evtSignal);
      await this.props.addOverlayTrendSignal([]);
      await this.props.clearOverviewata();
      await this.props.clearChartData();
      await this.props.clearEventList();
      if (platform === '3') {
        await this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&fty=' +
            sessionStorage.getItem('selectedFleetType') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            selectedVehicleProduct +
            '&pk=' +
            selectedPack +
            '&pkdn=' +
            selectedPackDname +
            '&pdn=' +
            selectedProductDname +
            '&pip=' +
            selectedProductIP +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=' +
            evtSignal +
            '&tb=Timetrend-vehicleprod-view' +
            '&unmas=false' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&bpu=' +
            bpu
        );
      } else {
        await this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            selectedVehicleProduct +
            '&pdn=' +
            selectedProductDname +
            '&pip=' +
            selectedProductIP +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=' +
            evtSignal +
            '&tb=Timetrend-vehicleprod-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&bpu=' +
            bpu
        );
      }
    }
  };

  handleEventInputChange = e => {
    if (e !== undefined) {
      this.setState({
        eventFormValues: {
          ...this.state.eventFormValues,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleClose = () => {
    this.setState(
      {
        eventFormValues: {
          ...this.state.eventFormValues,
          exchangetype: 'ESS',
          nessan:
            sessionStorage.getItem('nessan') === 'null'
              ? ''
              : sessionStorage.getItem('nessan'),
          nesssn: '',
          npecan: '',
          npecsn: '',
          prepecan: '',
          prepecsn: '',
          comments: '',
        },
      },
      () => sessionStorage.setItem('nessan', '')
    );
  };

  handleEventRadioChange = e => {
    if (e !== undefined) {
      if (e.target.value === 'PEC') {
        this.setState({
          eventFormValues: {
            ...this.state.eventFormValues,
            [e.target.name]: e.target.value,
            nessan: '',
            nesssn: '',
          },
        });
      } else {
        this.setState({
          eventFormValues: {
            ...this.state.eventFormValues,
            [e.target.name]: e.target.value,
            nessan:
              sessionStorage.getItem('nessan') === 'null'
                ? ''
                : sessionStorage.getItem('nessan'),
          },
        });
      }
    }
  };

  setEventUpdateDate = date => {
    // this.setState({
    //   eventFormValues: {
    //     ...this.state.eventFormValues,
    //     cdate: Moment.utc(date, "DD.MM.YYYY HH:mm:ss").toISOString(),
    //   },
    // });
  };

  setEventNewPec = obj => {
    let npecan = '';
    let npecsn = '';

    if (obj.npun !== null) {
      npecan = obj.npun.slice(0, 15);
      npecsn = obj.npun.slice(15);
    }

    if (
      obj.cd === '0X002' &&
      obj.rsn === 'Change Request' &&
      obj.sts === 'Pending'
    ) {
      sessionStorage.setItem('cd', obj.cd);
      sessionStorage.setItem('rsn', obj.rsn);
      sessionStorage.setItem('sts', obj.sts);
      sessionStorage.setItem('ip', obj.ip);
    } else {
      sessionStorage.setItem('cd', '');
      sessionStorage.setItem('rsn', '');
      sessionStorage.setItem('sts', '');
      sessionStorage.setItem('ip', '');
    }

    this.props.fetchBatteryOverallConditionData(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      obj.pun
    );
    this.setState({
      eventFormValues: {
        ...this.state.eventFormValues,
        npecan,
        npecsn,
        prepecan: npecan,
        prepecsn: npecsn,
      },
    });
  };

  handleEventSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    await this.props.clearSaveEventResponce();
    this.props.saveEvent(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      this.state.eventFormValues
    );
    // this.setState({
    //   eventFormValues: {
    //     ...this.state.eventFormValues,
    //     nessan: "",
    //     nesssn: "",
    //     npecan: "",
    //     npecsn: "",
    //     prepecan: "",
    //     prepecsn: "",
    //     comments: "",
    //   },
    // });
  };

  handleUndoSubmit = async obj => {
    // e.preventDefault();
    // e.stopPropagation();
    await this.props.clearUndoEventResponce();
    this.props.undoEvent(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      obj
    );
  };

  eventFilterApply = (filterStartDate, filterEndDate) => {
    const url = new URL(window.location.href);
    this.props.fetchAllEventList(
      url.searchParams.get('ft'),
      url.searchParams.get('vc'),
      url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
      url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
      Moment(filterStartDate)
        .format('YYYY-MM-DDTHH:mm:ss')
        .replace(/:/g, '%3A'),
      Moment(filterEndDate).format('YYYY-MM-DDTHH:mm:ss').replace(/:/g, '%3A'),
      new URL(window.location.href).searchParams.get('efdt') !== null
        ? new URL(window.location.href).searchParams.get('efdt')
        : 'Any time',
      'All',
      'All',
      this.props.eventData.pageSize,
      this.props.eventData.pageIndex,
      !this.props.eventData.sortValue && this.props.eventData.sortValueKey
        ? 1
        : 0,
      this.props.eventData.sortValueKey
        ? this.props.eventData.sortValueKey
        : 'dt'
    );
  };

  redirectToEmailAlert = () => {
    this.props.history.push(
      '/FleetView/EmailAlertView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet')
    );
  };

  handleSearchInputChange = value => {
    if (value === null || value === '') {
      sessionStorage.removeItem('searchResultList');
    }
    this.setState({ searchQuery: value });
    sessionStorage.setItem('searchQuery', value);
    value = value && value.toLowerCase();
    let results = [];
    // let startprof = performance.now();
    this.props.vehiclesListData[0].vst.forEach(item => {
      // if the vehicle name matches
      item.vdat.forEach(subitem => {
        // if the query matches battery name or ip address or pec serial number
        if (
          (subitem.dn && subitem.dn.toLowerCase().includes(value)) ||
          (subitem.ip && subitem.ip.toLowerCase().includes(value)) ||
          (subitem.an && subitem.an.toLowerCase().includes(value)) ||
          (subitem.bpu && subitem.bpu.toLowerCase().includes(value)) ||
          (subitem.rcm && subitem.rcm.includes(value)) ||
          (subitem.swv && subitem.swv.includes(value)) ||
          (subitem.bwvr && subitem.bwvr.includes(value))
        ) {
          results.push(item);
        }
        if (subitem.pdat.length > 0) {
          let matchFound = false;
          subitem.pdat.forEach(subitemlvl2 => {
            if (
              (subitemlvl2.dn &&
                subitemlvl2.dn.toLowerCase().includes(value)) ||
              (subitemlvl2.ip &&
                subitemlvl2.ip.toLowerCase().includes(value)) ||
              (subitemlvl2.an &&
                subitemlvl2.an.toLowerCase().includes(value)) ||
              (subitemlvl2.bpu &&
                subitemlvl2.bpu.toLowerCase().includes(value)) ||
              (subitemlvl2.rcm && subitemlvl2.rcm.includes(value)) ||
              (subitemlvl2.swv && subitemlvl2.swv.includes(value)) ||
              (subitemlvl2.bwvr && subitemlvl2.bwvr.includes(value))
            ) {
              matchFound = true;
              // results.push(item);
            }
          });
          if (matchFound) results.push(item);
        }
      });
      if (
        (item.vn && item.vn.toLowerCase().includes(value)) ||
        (item.dvn && item.dvn.toLowerCase().includes(value))
      ) {
        results.push(item);
      }
    });
    const uniqueResults = results.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        results.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    const searchResultList = [
      { vst: uniqueResults, data: this.props.vehiclesListData[0].data },
    ];

    this.setState({
      searchResultList,
    });
    sessionStorage.setItem(
      'searchResultList',
      JSON.stringify(searchResultList)
    );
    results = [];

    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('srch', value);
      window.history.pushState(null, null, url);
    }
  };

  expandSide = () => {
    this.setState({ expandSide: !this.state.expandSide }, () =>
      this.props.setWidth(this.state.expandSide ? 370 : 120)
    );
  };

  diagFilterApply = () => {
    const url = new URL(window.location.href);
    this.props.fetchDiagnosticList(
      url.searchParams.get('ft'),
      url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
      url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
      url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
      url.searchParams.get('sdt'),
      url.searchParams.get('edt'),
      'Past 90 days',
      this.props.diagnosticEventData.uniqueKey,
      this.props.diagnosticEventData.uniqueValue,
      url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
      20,
      0,
      !this.props.diagnosticEventData.sortValue &&
        this.props.diagnosticEventData.sortValueKey
        ? 1
        : 0,
      this.props.diagnosticEventData.sortValueKey
        ? this.props.diagnosticEventData.sortValueKey
        : ''
    );
  };

  render() {
    return (
      <React.Fragment>
        <Breadcrumb
          BreadcrumbIcon={<HomeIcon className={''} alt="Home" />}
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          MonitoredCount={this.props.monitoredCountData}
          userConfig={userConfig}
        />
        <Draggable
          left_side_content={
            this.props.showSidebar ? (
              <div className="sideMenucon noLeftpadding noRightpadding">
                {platformVersion() !== '3' ? (
                  <SideMenu
                    screenWidth={this.props.sideWidth}
                    size={this.props.size}
                    handleSearchInputChange={value =>
                      this.handleSearchInputChange(value)
                    }
                    searchQuery={this.state.searchQuery}
                    fleetList={this.props.fleetsListData} // {fleetList}
                    vehicles={
                      this.state.searchResultList.length === 0
                        ? this.props.vehiclesListData
                        : this.state.searchResultList
                    } // {vehicles}
                    onFleetListClick={this.onFleetListClick}
                    selectedFleet={sessionStorage.getItem('selectedFleet')}
                    selectedFleetType={sessionStorage.getItem(
                      'selectedFleetType'
                    )}
                    isGoodSlected={this.state.filterItems.isGoodSlected}
                    isPoorSelected={this.state.filterItems.isPoorSelected}
                    isUnknownSelected={this.state.filterItems.isUnknownSelected}
                    isFavoriteSelected={
                      this.state.filterItems.isFavoriteSelected
                    }
                    selectedSearchKey={this.state.filterItems.selectedSearchKey}
                    onGoodFilterCkick={this.onGoodFilterCkick}
                    onPoorFilterCkick={this.onPoorFilterCkick}
                    onUnknownFilterCkick={this.onUnknownFilterCkick}
                    onFavoriteFilterCkick={this.onFavoriteFilterCkick}
                    handleFilterDropdownChange={this.handleFilterDropdownChange}
                    filterWithFleetName={this.filterWithFleetName}
                    fleettoSearch={this.state.fleettoSearch}
                    filterFleetNameUpdate={this.filterFleetNameUpdate}
                    filterFleetNameEnter={this.filterFleetNameEnter}
                    onVehicleListClick={
                      this.onVehicleListClickRedirectionHandler
                    }
                    selectedView={'Fleet'}
                    onVehicleProductListClick={
                      this.onVehicleProductListClickRedirection
                    }
                    onListSelectionClick={this.onListSelectionClick}
                    listSelection={this.props.selectedList}
                    loadingUnmountedESSList={this.props.loadingUnmountedESSList}
                    unmountedESSListData={this.props.unmountedESSListData}
                    sideMenuFilter={this.sideMenuFilter}
                    filterAvailability={this.state.filterItems.availability}
                    filterReliability={this.state.filterItems.reliability}
                    filterUtilization={this.state.filterItems.utilization}
                    expandSide={this.expandSide}
                  />
                ) : (
                  // show this for side menu v3
                  <SideMenuV3
                    // trainno={selectedTrain}
                    screenWidth={this.props.sideWidth}
                    size={this.props.size}
                    handleSearchInputChange={value =>
                      this.handleSearchInputChange(value)
                    }
                    searchQuery={this.state.searchQuery}
                    fleetList={this.props.fleetsListData} // {fleetList}
                    vehicles={
                      this.state.searchResultList.length === 0
                        ? this.props.vehiclesListData // sideMenuV3Data //
                        : this.state.searchResultList
                    } // {vehicles}
                    onFleetListClick={this.onFleetListClick}
                    onVehicleProductPackClick={this.onVehicleProductPackClick}
                    selectedFleet={sessionStorage.getItem('selectedFleet')}
                    selectedFleetType={sessionStorage.getItem(
                      'selectedFleetType'
                    )}
                    isGoodSlected={this.state.filterItems.isGoodSlected}
                    isPoorSelected={this.state.filterItems.isPoorSelected}
                    isUnknownSelected={this.state.filterItems.isUnknownSelected}
                    isFavoriteSelected={
                      this.state.filterItems.isFavoriteSelected
                    }
                    selectedSearchKey={this.state.filterItems.selectedSearchKey}
                    onGoodFilterCkick={this.onGoodFilterCkick}
                    onPoorFilterCkick={this.onPoorFilterCkick}
                    onUnknownFilterCkick={this.onUnknownFilterCkick}
                    onFavoriteFilterCkick={this.onFavoriteFilterCkick}
                    handleFilterDropdownChange={this.handleFilterDropdownChange}
                    filterWithFleetName={this.filterWithFleetName}
                    fleettoSearch={this.state.fleettoSearch}
                    filterFleetNameUpdate={this.filterFleetNameUpdate}
                    filterFleetNameEnter={this.filterFleetNameEnter}
                    onVehicleListClick={
                      this.onVehicleListClickRedirectionHandler
                    }
                    selectedView={'Fleet'}
                    onVehicleProductListClick={
                      this.onVehicleProductListClickRedirection
                    }
                    onListSelectionClick={this.onListSelectionClick}
                    listSelection={this.props.selectedList}
                    loadingUnmountedESSList={this.props.loadingUnmountedESSList}
                    unmountedESSListData={this.props.unmountedESSListData}
                    sideMenuFilter={this.sideMenuFilter}
                    filterAvailability={this.state.filterItems.availability}
                    filterReliability={this.state.filterItems.reliability}
                    filterUtilization={this.state.filterItems.utilization}
                    expandSide={this.expandSide}
                  />
                )}
                {/* <Loading loadintText="Loading list" /> */}
              </div>
            ) : (
              <div className="sideMenucon noLeftpadding noRightpadding">
                <MinimizedSideMenu
                  expandSide={this.expandSide}
                  setShowSidebar={this.props.setShowSidebar}
                  selectedView={'Vehicle'}
                  onFleetListClick={this.onFleetListClick}
                  onVehicleListClick={this.onVehicleListClickRedirectionHandler}
                  setSize={this.props.setSize}
                />
              </div>
            )
          }
          right_side_content={
            <div className="detailViewRightinner noRightpadding">
              {platformVersion() !== '3' ? (
                <TitleBar
                  overallConditionData={this.props.overallConditionData}
                  pageName="Vehicleview"
                  userConfig={userConfig}
                  redirectToEmailAlert={this.redirectToEmailAlert}
                  searchQuery={this.state.searchQuery}
                  pf={platformVersion()}
                  loadingBatteryOverallCondition={
                    this.props.loadingBatteryOverallCondition
                  }
                  batteryOverallConditionData={
                    this.props.batteryOverallConditionData
                  }
                  loadingVehicleDetails={this.props.loadingVehicleDetails}
                  vehicleDetails={this.props.vehicleDetails}
                />
              ) : (
                <TitleBarV3
                  overallConditionData={this.props.overallConditionData}
                  pageName="Vehicleview"
                  userConfig={userConfig}
                  redirectToEmailAlert={this.redirectToEmailAlert}
                  searchQuery={this.state.searchQuery}
                  pf={platformVersion()}
                  loadingBatteryOverallCondition={
                    this.props.loadingBatteryOverallCondition
                  }
                  batteryOverallConditionData={
                    this.props.batteryOverallConditionData
                  }
                  loadingVehicleDetails={this.props.loadingVehicleDetails}
                  vehicleDetails={this.props.vehicleDetails}
                />
              )}
              {/* ) : (
              <Loading loadintText="Loading data" />
            )} */}
              <div
                className="date-tabs-container vehicle_view_event"
                data-intro="vehicle_overview">
                <VehiclesTabs
                  diagFilterApply={this.diagFilterApply}
                  fetchDiagnosticList={this.props.fetchDiagnosticList}
                  loadingDiagnosticList={this.props.loadingDiagnosticList}
                  diagnosticList={this.props.diagnosticList}
                  redirectToTimetrendFrmDiagnostic={
                    this.redirectToTimetrendFrmDiagnostic
                  }
                  copyLinkDiagnosticTab={this.copyLinkDiagnosticTab}
                  diagnosticListConfig={this.props.diagnosticListConfig}
                  diagnosticEventData={this.props.diagnosticEventData}
                  setDiagnosticEventListData={
                    this.props.setDiagnosticEventListData
                  }
                  eventListConfig={
                    this.props.alleventListConfig &&
                    this.props.alleventListConfig
                  }
                  eventData={this.props.eventData}
                  setAllEventListData={this.props.setAllEventListData}
                  fetchAllEventList={this.props.fetchAllEventList}
                  onVehicleViewTabClick={this.onVehicleViewTabClickRedirection}
                  onVehicleViewStatusTabClick={this.onVehicleViewStatusTabClick}
                  redirectToTimetrend={this.redirectToTimetrend}
                  redirectToTimetdOnStreesEvtClk={
                    this.redirectToTimetdOnStreesEvtClk
                  }
                  vehicleRelData={this.props.vehicleRelData}
                  loadingVehicleRel={this.props.loadingVehicleRel}
                  vehicleStrData={this.props.vehicleStrData}
                  loadingVehicleStr={this.props.loadingVehicleStr}
                  vehicleAvailData={this.props.vehicleAvailData}
                  loadingVehicleAvail={this.props.loadingVehicleAvail}
                  selectedOverviewTab={this.state.selectedOverviewTab}
                  userConfig={userConfig}
                  loadingEventList={this.props.loadingEventList}
                  eventList={this.props.eventList}
                  handleEventInputChange={this.handleEventInputChange}
                  handleEventRadioChange={this.handleEventRadioChange}
                  setEventUpdateDate={this.setEventUpdateDate}
                  setEventNewPec={this.setEventNewPec}
                  eventFormValues={this.state.eventFormValues}
                  handleClose={this.handleClose}
                  handleEventSubmit={this.handleEventSubmit}
                  loadingSaveEvent={this.props.loadingSaveEvent}
                  saveEventResponce={this.props.saveEventResponce}
                  selectedTab={this.state.selectedTab}
                  selectedView={'Vehicle'}
                  vehiclesListData={this.props.vehiclesListData}
                  eventFilterApply={this.eventFilterApply}
                  handleUndoSubmit={this.handleUndoSubmit}
                  loadingUndoEvent={this.props.loadingUndoEvent}
                  undoEventResponce={this.props.undoEventResponce}
                  selectedVehicle={sessionStorage.getItem('selectedVehicle')}
                  sideMenuFilter={this.sideMenuFilter}
                  filterAvailability={this.state.filterItems.availability}
                  filterReliability={this.state.filterItems.reliability}
                  filterUtilization={this.state.filterItems.utilization}
                  isResizing={this.props.isResizing}
                  showSidebar={this.props.showSidebar}
                  heatmap_min_width={
                    window.innerWidth - (this.props.size + 60)
                  }></VehiclesTabs>
              </div>
            </div>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loadingEventList: loadingAllEventListSelector(),
  eventList: AlleventListSelector(),
  alleventListConfig: alleventListSelectorConfig(),
  eventData: eventDataSelector(),
  loadingHistogram: loadingHistogramSelector(),
  loadingBoxPlot: loadingBoxPlotSelector(),
  histogramData: histogramDataSelector(),
  boxPlotData: boxPlotDataSelector(),
  loadingOverallCondition: loadingOverallConditionSelector(),
  overallConditionData: overallConditionDataSelector(),
  fleetsListData: fleetsListDataSelector(),
  vehiclesListData: vehiclesListDataSelector(),
  vehicleProductListData: vehicleProductListDataSelector(),
  monitoredCountData: monitoredCountDataSelector(),
  selectedStartDate: selectedStartDateSelector(),
  selectedEndDate: selectedEndDateSelector(),
  selectedList: selectedListSelector(),
  loadingVehicleAvail: loadingVehicleAvailSelector(),
  vehicleAvailData: vehicleAvailDataSelector(),
  loadingVehicleRel: loadingVehicleRelSelector(),
  vehicleRelData: vehicleRelDataSelector(),
  loadingVehicleStr: loadingVehicleStrSelector(),
  vehicleStrData: vehicleStrDataSelector(),
  loadingSaveEvent: loadingSaveEventSelector(),
  saveEventResponce: saveEventResponceSelector(),
  loadingBatteryOverallCondition: loadingBatteryOverallConditionSelector(),
  batteryOverallConditionData: batteryOverallConditionDataSelector(),
  loadingUndoEvent: loadingUndoEventSelector(),
  undoEventResponce: undoEventResponceSelector(),
  loadingUnmountedESSList: loadingUnmountedESSListSelector(),
  unmountedESSListData: unmountedESSListDataSelector(),
  sideWidth: getSidebarWidth(),
  showSidebar: getShowSidebar(),
  size: getSize(),
  isResizing: getIsResizing(),
  loadingVehicleDetails: loadingVehicleDetailsSelector(),
  vehicleDetails: vehicleDetailsSelector(),
  loadingDiagnosticList: loadingDiagnosticListSelector(),
  diagnosticList: diagnosticListSelector(),
  diagnosticListConfig: diagnosticEventListSelectorConfig(),
  diagnosticEventData: diagnosticEventDataSelector(),
});

const mapDispatchToProps = {
  fetchAllEventList,
  setAllEventListData,
  getOverallConditionData: fetchOverallConditionData,
  getHistogramData: fetchHistogramData,
  getBoxPlotData: fetchBoxPlotData,
  getFleetsList: fetchFleetsList,
  getVehiclesList: fetchVehiclesList,
  getVehicleProductList: fetchVehicleProductList,
  getModuleList: fetchModuleList,
  getMonitoredCount: fetchMonitoredCount,
  clearChartData,
  clearOverviewata,
  setDate,
  clearEventList,
  setListSelection,
  setMainTrendSignal,
  addOverlayTrendSignal,
  clearVehicleViewData,
  getVehicleAvailData: fetchVehicleAvailData,
  getVehicleRelData: fetchVehicleRelData,
  getVehicleStrData: fetchVehicleStrData,
  fetchVEventList,
  saveEvent,
  clearSaveEventResponce,
  fetchBatteryOverallConditionData,
  undoEvent,
  clearUndoEventResponce,
  fetchUnmountedESSList,
  setWidth: setSidebarWidth,
  setShowSidebar,
  fetchVehicleDetails,
  fetchMessages: fetchActiveMessages,
  setDiagnosticEventListData,
  fetchDiagnosticList,
  setSize,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleView);
