import {
  CLEAR_SAVE_FLEET_RESPONCE,
  CLEAR_SAVE_SITE_RESPONCE,
  GET_FLEETDATA,
  GET_FLEETDATA_FAILURE,
  GET_FLEETDATA_SUCCESS,
  GET_PRDTINFO_SETUP_VALUES,
  GET_PRDTINFO_SETUP_VALUES_FAILURE,
  GET_PRDTINFO_SETUP_VALUES_SUCCESS,
  GET_PRDT_SETUP_VALUES,
  GET_PRDT_SETUP_VALUES_FAILURE,
  GET_PRDT_SETUP_VALUES_SUCCESS,
  GET_SETUP_FLEET_LIST,
  GET_SETUP_FLEET_LIST_FAILURE,
  GET_SETUP_FLEET_LIST_SUCCESS,
  GET_SETUP_SIDEMENULIST,
  GET_SETUP_SIDEMENULIST_FAILURE,
  GET_SETUP_SIDEMENULIST_SUCCESS,
  GET_SETUP_SITE_LIST,
  GET_SETUP_SITE_LIST_FAILURE,
  GET_SETUP_SITE_LIST_SUCCESS,
  GET_USED_FLEET_NAMELIST,
  GET_USED_FLEET_NAMELIST_FAILURE,
  GET_USED_FLEET_NAMELIST_SUCCESS,
  GET_VALIDATE_FLEETDATAPATH,
  GET_VALIDATE_FLEETDATAPATH_FAILURE,
  GET_VALIDATE_FLEETDATAPATH_SUCCESS,
  GET_VEHICLESETUP_VALUES,
  GET_VEHICLESETUP_VALUES_FAILURE,
  GET_VEHICLESETUP_VALUES_SUCCESS,
  SAVE_FLEET_DATA,
  SAVE_FLEET_DATA_FAILURE,
  SAVE_FLEET_DATA_SUCCESS,
  SAVE_PRDTINFO_DATA,
  SAVE_PRDTINFO_DATA_FAILURE,
  SAVE_PRDTINFO_DATA_SUCCESS,
  SAVE_PRDT_DATA,
  SAVE_PRDT_DATA_FAILURE,
  SAVE_PRDT_DATA_SUCCESS,
  SAVE_SITE_DATA,
  SAVE_SITE_DATA_FAILURE,
  SAVE_SITE_DATA_SUCCESS,
  SAVE_VEHICLE_DATA,
  SAVE_VEHICLE_DATA_FAILURE,
  SAVE_VEHICLE_DATA_SUCCESS,
} from './constants';

//  for save site

export const saveSite = (editSite, siteFormValues) => ({
  type: SAVE_SITE_DATA,
  payload: { editSite, siteFormValues },
});

export const saveSiteSucess = saveSiteResponce => ({
  type: SAVE_SITE_DATA_SUCCESS,
  saveSiteResponce,
});

export const saveSiteFailure = error => ({
  type: SAVE_SITE_DATA_FAILURE,
  error,
});

//  for save fleet

export const saveFleet = fleetobj => ({
  type: SAVE_FLEET_DATA,
  payload: {
    fleetobj,
  },
});

export const saveFleetSucess = saveFleetResponce => ({
  type: SAVE_FLEET_DATA_SUCCESS,
  saveFleetResponce,
});

export const saveFleetFailure = error => ({
  type: SAVE_FLEET_DATA_FAILURE,
  error,
});

export const clearSaveFleetResponce = () => ({
  type: CLEAR_SAVE_FLEET_RESPONCE,
});

export const clearSaveSiteResponce = () => ({
  type: CLEAR_SAVE_SITE_RESPONCE,
});

//  for GET site list

export const getSetupSiteList = siteName => ({
  type: GET_SETUP_SITE_LIST,
  payload: { siteName },
});

export const getSetupSiteListSucess = setupSiteList => ({
  type: GET_SETUP_SITE_LIST_SUCCESS,
  setupSiteList,
});

export const getSetupSiteListFailure = error => ({
  type: GET_SETUP_SITE_LIST_FAILURE,
  error,
});

//  for GET fleet list

export const getSetupFleetList = siteName => ({
  type: GET_SETUP_FLEET_LIST,
  payload: { siteName },
});

export const getSetupFleetListSucess = setupFleetList => ({
  type: GET_SETUP_FLEET_LIST_SUCCESS,
  setupFleetList,
});

export const getSetupFleetListFailure = error => ({
  type: GET_SETUP_FLEET_LIST_FAILURE,
  error,
});

// VEHICLE setup
export const saveVehicleSetup = obj => ({
  type: SAVE_VEHICLE_DATA,
  payload: {
    obj,
  },
});

export const saveVehicleSetupSucess = saveVehicleSetupResponce => ({
  type: SAVE_VEHICLE_DATA_SUCCESS,
  saveVehicleSetupResponce,
});

export const saveVehicleSetupFailure = error => ({
  type: SAVE_VEHICLE_DATA_FAILURE,
  error,
});

// VEHICLE setup get
export const getVehicleSetup = fleetName => ({
  type: GET_VEHICLESETUP_VALUES,
  payload: {
    fleetName,
  },
});

export const getVehicleSetupSucess = getVehicleSetupResponce => ({
  type: GET_VEHICLESETUP_VALUES_SUCCESS,
  getVehicleSetupResponce,
});

export const getVehicleSetupFailure = error => ({
  type: GET_VEHICLESETUP_VALUES_FAILURE,
  error,
});

// PRODUCT

export const saveproductSetup = obj => ({
  type: SAVE_PRDT_DATA,
  payload: {
    obj,
  },
});

export const saveproductSetupSucess = saveProductSetupResponce => ({
  type: SAVE_PRDT_DATA_SUCCESS,
  saveProductSetupResponce,
});

export const saveproductSetupFailure = error => ({
  type: SAVE_PRDT_DATA_FAILURE,
  error,
});

export const saveproductInfoSetup = obj => ({
  type: SAVE_PRDTINFO_DATA,
  payload: {
    obj,
  },
});

export const saveproductInfoSetupSucess = saveProductInfoSetupResponce => ({
  type: SAVE_PRDTINFO_DATA_SUCCESS,
  saveProductInfoSetupResponce,
});

export const saveproductInfoSetupFailure = error => ({
  type: SAVE_PRDTINFO_DATA_FAILURE,
  error,
});

//  for GET ProdSetupValues

export const getPrdtSetupValues = fleetName => ({
  type: GET_PRDT_SETUP_VALUES,
  payload: { fleetName },
});

export const getPrdtSetupValuesSucess = prdtSetupValues => ({
  type: GET_PRDT_SETUP_VALUES_SUCCESS,
  prdtSetupValues,
});

export const getPrdtSetupValuesFailure = error => ({
  type: GET_PRDT_SETUP_VALUES_FAILURE,
  error,
});

export const getPrdtInfoSetupValues = fleetName => ({
  type: GET_PRDTINFO_SETUP_VALUES,
  payload: { fleetName },
});

export const getPrdtInfoSetupValuesSucess = prdtInfoSetupValues => ({
  type: GET_PRDTINFO_SETUP_VALUES_SUCCESS,
  prdtInfoSetupValues,
});

export const getPrdtInfoSetupValuesFailure = error => ({
  type: GET_PRDTINFO_SETUP_VALUES_FAILURE,
  error,
});

//  for GET sidemenu list
export const getSetupSidemenuList = () => ({
  type: GET_SETUP_SIDEMENULIST,
});

export const getSetupSidemenuListSucess = setupMenuList => ({
  type: GET_SETUP_SIDEMENULIST_SUCCESS,
  setupMenuList,
});

export const getSetupSidemenuListFailure = error => ({
  type: GET_SETUP_SIDEMENULIST_FAILURE,
  error,
});

//  for GET used fleet name list
export const getUsedFleetNameList = () => ({
  type: GET_USED_FLEET_NAMELIST,
});

export const getUsedFleetNameListSucess = usedFleetNameList => ({
  type: GET_USED_FLEET_NAMELIST_SUCCESS,
  usedFleetNameList,
});

export const getUsedFleetNameListFailure = error => ({
  type: GET_USED_FLEET_NAMELIST_FAILURE,
  error,
});

// Validate Fleet data path
export const getValidateFleetDataPath = (
  fleetDataPath,
  connectivityKeyPath,
  platform,
  connectivityType
) => ({
  type: GET_VALIDATE_FLEETDATAPATH,
  payload: { fleetDataPath, connectivityKeyPath, platform, connectivityType },
});

export const getValidateFleetDataPathSuccess = saveFleetDataPathResponce => ({
  type: GET_VALIDATE_FLEETDATAPATH_SUCCESS,
  saveFleetDataPathResponce,
});

export const getValidateFleetDataPathFailure = error => ({
  type: GET_VALIDATE_FLEETDATAPATH_FAILURE,
  error,
});

// Load Fleet data - Connectivity key path
export const getFleetData = fleetData => ({
  type: GET_FLEETDATA,
  payload: { fleetData },
});

export const getFleetDataSuccess = saveFleetDataResponce => ({
  type: GET_FLEETDATA_SUCCESS,
  saveFleetDataResponce,
});

export const getFleetDataFailure = error => ({
  type: GET_FLEETDATA_FAILURE,
  error,
});
