import moment from 'moment';
import React from 'react';
import {
  ArrowDownLine,
  ArrowRightLine,
  SyncIcon,
  TrashIcon,
} from '../../assets/images';
import MessageTable from './MessageTable';
import './styles.scss';
function Messages({ data, deleteMessage, enableMessage, renewMessage }) {
  const default_message_lenth = 30;

  const columns = [
    {
      id: 'expander',
      Header: '',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <ArrowDownLine /> : <ArrowRightLine />}
        </span>
      ),
    },
    {
      Header: 'MESSAGE',
      style: { color: ' #696969' },
      accessor: 'message',
      Cell: ({ row }) => (
        <span>
          {row.original.message &&
          row.original.message.length > default_message_lenth
            ? row.original.message.substring(0, default_message_lenth) + '...'
            : row.original.message}
        </span>
      ),
    },
    {
      Header: 'START DATE AND TIME',
      accessor: 'startDate',
      Cell: ({ row }) => (
        <span>
          {moment
            .utc(row.original.startDate)
            .local()
            .format('DD.MM.YYYY HH:mm')}
        </span>
      ),
    },
    {
      Header: 'END DATE AND TIME',
      accessor: 'endDate',
      Cell: ({ row }) => (
        <span>
          {moment.utc(row.original.endDate).local().format('DD.MM.YYYY HH:mm')}
        </span>
      ),
    },
    {
      Header: 'ACTIONS',
      accessor: '',
      Cell: ({ row }) => (
        <>
          {row.original.isExpired ? (
            <span>
              <button
                className="btn   message_btn_rect"
                onClick={() => renewMessage(row.original)}>
                <SyncIcon /> Renew
              </button>
            </span>
          ) : (
            <button
              className="btn   message_btn_rect"
              alt={row.original.isActive ? 'Disable' : 'Enable'}
              onClick={() =>
                enableMessage(
                  row.original.id,
                  row.original,
                  row.original.isActive ? 'disable' : 'enable'
                )
              }>
              <span className="">
                {row.original.isActive ? 'Disable' : 'Enable'}
              </span>
            </button>
          )}
          <button
            className="btn"
            aria-label="delete"
            onClick={() => deleteMessage(row.original.id, row.original)}>
            <TrashIcon className="btn_delete" alt="delete" />
          </button>
        </>
      ),
    },
  ];
  return (
    <div className="broadcast_messages">
      <h6 className="mb-4">Messages</h6>
      <MessageTable data={data} columns={columns} />
    </div>
  );
}

export default Messages;
