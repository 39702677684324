import Moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Breadcrumb from '../../components/Breadcrumb';
import DateRangePicker from '../../components/DateRangePicker';
import { setSidebarWidth } from '../../components/DraggableLayout/actions';
import Loading from '../../components/Loading';
import SideMenu from '../../components/SideMenu';
import Draggable from '../../components/SidebarDraggable/Draggable';
import TitleBar from '../../components/Title/TitleBar';
import TractionConverterTabs from '../../components/TractionConverterTab';
import { fetchMonitoredCount } from '../DashBoard/actions';
import { monitoredCountDataSelector } from '../DashBoard/selectors';
import messages from './messages';
import './styles.scss';

import { HomeIcon } from '../../assets/images';
import { getSidebarWidth } from '../../components/DraggableLayout/selectors';
import MinimizedSideMenu from '../../components/MinimizedSideMenu';
import SideMenuV3 from '../../components/SideMenuV3';
import {
  setShowSidebar,
  setSize,
} from '../../components/SidebarDraggable/actions';
import {
  getIsResizing,
  getShowSidebar,
  getSize,
} from '../../components/SidebarDraggable/selectors';
import { userConfig as configUser } from '../../userConfig';
import { copyToClipboard } from '../../utils/clipboard';
import { platformVersion } from '../../utils/excel-utils';
import { getbpu } from '../../utils/other';
import { fetchActiveMessages } from '../Broadcast/actions';
import {
  clearEventList,
  clearSaveEventResponce,
  clearUndoEventResponce,
  saveEvent,
  setAllEventListData,
  undoEvent,
} from '../Events/actions';
import {
  fetchDiagnosticList,
  fetchFleetsList,
  fetchModuleList,
  fetchUnmountedESSList,
  fetchVehicleProductList,
  fetchVehiclesList,
  setDate,
  setDiagnosticEventListData,
  setListSelection,
} from '../FleetView/actions';
import {
  diagnosticEventDataSelector,
  diagnosticEventListSelectorConfig,
  diagnosticListSelector,
  fleetsListDataSelector,
  loadingDiagnosticListSelector,
  loadingUnmountedESSListSelector,
  selectedEndDateSelector,
  selectedListSelector,
  selectedStartDateSelector,
  unmountedESSListDataSelector,
  vehicleProductListDataSelector,
  vehiclesListDataSelector,
} from '../FleetView/selectors';
import {
  clearChartData,
  clearDetailViewTimetrend,
  clearOverviewata,
  fetchBatteryOverallConditionData,
  fetchBatteryOverviewData,
  fetchBatteryRelData,
  fetchBatterySOCRangeData,
  fetchBatteryStateOfChargeData,
  fetchBatteryStrData,
  fetchDetailViewTimetrend,
  fetchOperatingHoursHistogramData,
  setRadioButtonValue,
} from '../VehicleProductsView/actions';
import {
  RadioValueSelectionData,
  batteryOverallConditionDataSelector,
  batteryOverviewDataSelector,
  batteryRelDataSelector,
  batterySOCRangeDataSelector,
  batteryStateOfChargeDataSelector,
  batteryStrDataSelector,
  loadingBatteryOverallConditionSelector,
} from '../VehicleProductsView/selectors';
import { clearVehicleViewData } from '../VehicleView/actions';

Moment.locale('en');

let selectedSite;
let selectedFleet;
let selectedFleetType;
let selectedVehicle;
let selectedVehicleProduct;
let selectedPIp;
let selectedPDn;
let unmountedESSclicked;
let selectedSrtDte = null;
let selectedEdDte = null;
let selectedCDateType = null;
let selectedTb = null;
let selectedOverviewTb = null;
let selectedUsgRbtn = null;
// let urlLCmin;
// let urlLCmax;
// let urlSCmin;
// let urlSCmax;
// let urlSYCmin;
// let urlSYCmax;
let srch = null;
let ftr = [null, null, null, null];
let bpu = null;

let userConfig;
export class TractionConverterView extends React.Component {
  constructor(props) {
    super(props);
    sessionStorage.removeItem('searchQuery');
    sessionStorage.removeItem('searchResultList');
    sessionStorage.removeItem('selectedPack');
    sessionStorage.removeItem('selectedPackDname');
    const urlLink = window.location.href;
    const url = new URL(urlLink);
    if (urlLink.indexOf('?') > -1) {
      selectedSite = url.searchParams.get('st');
      selectedFleet = url.searchParams.get('ft');
      selectedFleetType = url.searchParams.get('fty');
      selectedVehicle = url.searchParams.get('vc');
      selectedVehicleProduct = url.searchParams.get('vp');
      if (urlLink.indexOf('?') > -1) {
        selectedSite = url.searchParams.get('st');
        selectedFleet = url.searchParams.get('ft');
        selectedFleetType = url.searchParams.get('fty');
        selectedVehicle = url.searchParams.get('vc');
        selectedVehicleProduct = url.searchParams.get('vp');

        selectedPIp = url.searchParams.get('pip');
        selectedPDn = url.searchParams.get('pdn');
        selectedSrtDte = url.searchParams.get('sdt');
        selectedEdDte = url.searchParams.get('edt');
        selectedCDateType = url.searchParams.get('cdty');
        unmountedESSclicked = url.searchParams.get('unmas');
        selectedTb = 'diagnostic-vehicleprod-view'; // url.searchParams.get('tb');
        selectedOverviewTb = url.searchParams.get('otb');
        selectedUsgRbtn = url.searchParams.get('urbt');
        // urlLCmin = url.searchParams.get('lmn');
        // urlLCmax = url.searchParams.get('lmx');
        // urlSCmin = url.searchParams.get('smn');
        // urlSCmax = url.searchParams.get('smx');
        // urlSYCmin = url.searchParams.get('symn');
        // urlSYCmax = url.searchParams.get('symx');

        ftr = url.searchParams.get('ftr');
        ftr =
          ftr !== null && ftr !== 'null'
            ? ftr.split(',')
            : [null, null, null, null];
        srch = url.searchParams.get('srch');
        bpu =
          url.searchParams.get('bpu') === null
            ? getbpu(selectedVehicleProduct, this.props.vehiclesListData)
            : url.searchParams.get('bpu');
        url.searchParams.set('bpu', bpu);

        sessionStorage.setItem('selectedSite', selectedSite);
        sessionStorage.setItem('selectedFleet', selectedFleet);
        sessionStorage.setItem('selectedFleetType', selectedFleetType);
        sessionStorage.setItem('selectedVehicle', selectedVehicle);
        sessionStorage.setItem(
          'selectedVehicleProduct',
          selectedVehicleProduct
        );

        sessionStorage.setItem('selectedProductIP', selectedPIp);
        sessionStorage.setItem('selectedProductDname', selectedPDn);
        sessionStorage.setItem('selectedVehicleProductBPU', bpu);
        sessionStorage.setItem(
          'unmountedESSclicked',
          sessionStorage.getItem('unmountedESSclicked') === 'true'
            ? unmountedESSclicked
            : 'false'
        );
        // localStorage.setItem('urlLCmin', urlLCmin);
        // localStorage.setItem('urlLCmax', urlLCmax);
        // localStorage.setItem('urlSCmin', urlSCmin);
        // localStorage.setItem('urlSCmax', urlSCmax);
        // localStorage.setItem('urlSYCmin', urlSYCmin);
        // localStorage.setItem('urlSYCmax', urlSYCmax);
        this.props.setDate(selectedSrtDte, selectedEdDte);

        this.state = {
          isRedirectUsageHeatmap: false,
          leftrightBtnDisable: false,
          searchResultList:
            sessionStorage.getItem('searchResultList') !== null
              ? JSON.parse(sessionStorage.getItem('searchResultList'))
              : [],
          searchQuery: srch !== null ? srch : '',
          comnDateType:
            selectedCDateType !== null ? selectedCDateType : 'Monthly',
          isMonthlyactive: false,
          isWeeklyactive: false,
          isDailyactive: true,
          isVehicleClicked: false,
          isVeicleProductClicked: false,

          selectedUsageRbtn: selectedUsgRbtn !== null && selectedUsgRbtn,
          selectedTab:
            selectedTb !== null ? selectedTb : 'diagnostic-vehicleprod-view',
          selectedOverviewTab:
            selectedOverviewTb !== null
              ? selectedOverviewTb
              : ftr[0] === 'Availability'
                ? 'avail'
                : ftr[0] === 'Reliability'
                  ? 'reli'
                  : ftr[0] === 'Utilization'
                    ? 'util'
                    : 'binfo',
          filterItems: {
            availability: ftr[0] !== null ? ftr[0] : '',
            reliability: ftr[1] !== null ? ftr[1] : '',
            utilization: ftr[2] !== null ? ftr[2] : '',
          },

          isButtonDisabled: false,
          /* eventFormValues: {
            pecIP: '',
            producttype: '',
            exchangetype: 'ESS',
            oessan: '',
            nessan: '',
            oesssn: '',
            nesssn: '',
            opecan: '',
            npecan: '',
            opecsn: '',
            npecsn: '',
            comments: '',
            prepecan: '',
            prepecsn: '',
          }, */
          // expandSide: snav !== null && snav === "false" ? false : true,
          expandSide: true,
          isCopied: false,
        };

        if (
          sessionStorage.getItem('selectedFleet') === null ||
          sessionStorage.getItem('selectedVehicle') === 'null' ||
          sessionStorage.getItem('selectedSite') === null
        )
          this.props.history.push('/FleetView');

        userConfig = configUser();
      }
    }
    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
  }

  updateStateFromUrl() {
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const ftrParam = urlSearchParams.get('ftr');
    if (ftrParam) {
      const ftrValues = ftrParam.split(',');
      this.setState({
        filterItems: {
          availability: ftrValues[0] !== '' ? ftrValues[0] : '',
          reliability: ftrValues[1] !== '' ? ftrValues[1] : '',
          utilization: ftrValues[2] !== '' ? ftrValues[2] : '',
        },
      });
    } else {
      this.setState({
        filterItems: {
          availability: ftr[0] !== null ? ftr[0] : '',
          reliability: ftr[1] !== null ? ftr[1] : '',
          utilization: ftr[2] !== null ? ftr[2] : '',
        },
      });
    }
  }

  async componentDidMount() {
    if (this.state.comnDateType === 'Monthly') {
      const oStartDate = Moment().subtract(30, 'days').startOf('day');
      const oEndDate = Moment().subtract(1, 'days').endOf('day');
      await this.props.setDate(oStartDate, oEndDate);

      const url = new URL(window.location.href);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set(
          'sdt',
          Moment(oStartDate).format('YYYY-MM-DDTHH:mm:ss')
        );
        url.searchParams.set(
          'edt',
          Moment(oEndDate).format('YYYY-MM-DDTHH:mm:ss')
        );
      }
    }

    this.props.monitoredCountData === null && this.props.getMonitoredCount();

    this.props.fleetsListData === null &&
      this.props.getFleetsList('All', 'All', 'All', true, true, true);
    this.props.vehiclesListData === null &&
      this.props.getVehiclesList(
        sessionStorage.getItem('selectedSite'),
        sessionStorage.getItem('selectedFleet')
      );

    this.props.unmountedESSListData === null &&
      this.props.fetchUnmountedESSList(
        sessionStorage.getItem('selectedSite'),
        sessionStorage.getItem('selectedFleet')
      );

    this.props.getBatteryOverallConditionData(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      selectedVehicleProduct
    );

    const urlLink = window.location.href;
    const url = new URL(urlLink);
    url.searchParams.get('pdn') &&
      this.onVehicleProductListClick(
        url.searchParams.get('vc'),
        url.searchParams.get('vp'),
        url.searchParams.get('pip'),
        url.searchParams.get('pdn'),
        url.searchParams.get('bpu')
      );

    sessionStorage.setItem('zoomIntervalStartDate', null);
    sessionStorage.setItem('zoomIntervalEndDate', null);
    sessionStorage.setItem('isTrendZoomed', null);

    this.onVehicleProductsViewTabClick(
      selectedTb !== null ? selectedTb : this.state.selectedTab,
      selectedSrtDte !== null ? selectedSrtDte : this.state.selectedStartDate,
      selectedEdDte !== null ? selectedEdDte : this.state.selectedEndDate
    );

    if (
      sessionStorage.getItem('Sidemenuscroll') !== null &&
      document.getElementById('scrollvplist') != null
    ) {
      document.getElementById('scrollvplist').scrollTop =
        sessionStorage.getItem('Sidemenuscroll');
    }
    this.props.fetchMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    const urlLink = window.location.href;
    const url = new URL(urlLink);
    const searchParams = new URLSearchParams(window.location.search);
    const prevSearchParams = new URLSearchParams(prevProps.location.search);
    if (this.props.diagnosticEventData !== null) {
      if (
        url.searchParams.get('tb') === 'diagnostic-vehicleprod-view' &&
        (prevProps.diagnosticEventData.pageIndex !==
          this.props.diagnosticEventData.pageIndex ||
          prevProps.diagnosticEventData.uniqueValue !==
            this.props.diagnosticEventData.uniqueValue ||
          prevProps.diagnosticEventData.pageSize !==
            this.props.diagnosticEventData.pageSize ||
          prevSearchParams.get('tb') !== searchParams.get('tb') ||
          prevSearchParams.get('pdn') !== searchParams.get('pdn'))
      ) {
        this.props.fetchDiagnosticList(
          url.searchParams.get('ft'),
          url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
          url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
          url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
          url.searchParams.get('sdt'),
          url.searchParams.get('edt'),
          new URL(window.location.href).searchParams.get('dfdt') !== null
            ? new URL(window.location.href).searchParams.get('dfdt')
            : 'Past 90 days',
          this.props.diagnosticEventData.uniqueKey,
          this.props.diagnosticEventData.uniqueValue,
          url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
          this.props.diagnosticEventData.pageSize,
          this.props.diagnosticEventData.pageIndex,
          !this.props.diagnosticEventData.sortValue &&
            this.props.diagnosticEventData.sortValueKey
            ? 1
            : 0,
          this.props.diagnosticEventData.sortValueKey
            ? this.props.diagnosticEventData.sortValueKey
            : ''
        );
      }
    }

    if (prevSearchParams.get('tb') !== searchParams.get('tb')) {
      this.setState({
        selectedTab: searchParams.get('tb'),
      });
    }
    // if (prevSearchParams.get('otb') !== searchParams.get('otb')) {
    //   const otbTab = searchParams.get('otb');
    //   this.setState({
    //     selectedOverviewTab: otbTab || 'binfo',
    //   });
    // }
    if (prevSearchParams.get('ftr') !== searchParams.get('ftr')) {
      this.updateStateFromUrl();
    }
    if (prevSearchParams.get('pdn') !== searchParams.get('pdn')) {
      this.onVehicleProductListClick(
        searchParams.get('vc'),
        searchParams.get('vp'),
        searchParams.get('pip'),
        searchParams.get('pdn'),
        searchParams.get('bpu')
      );
    }
    if (prevSearchParams.get('tb') !== searchParams.get('tb')) {
      if (this.state.isRedirectUsageHeatmap !== true)
        this.onVehicleProductsViewTabClick(searchParams.get('tb'));
    }
  }

  componentWillUnmount() {
    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });

    this.props.setWidth(370);
    this.props.setRadioButtonValue('Dbudget');
    this.props.clearChartData();
  }

  UNSAFE_componentWillReceiveProps(newProps, prevState) {
    if (this.props.vehicleProductListData !== newProps.vehicleProductListData) {
      if (sessionStorage.getItem('selectedVehicleProduct') !== 'null') {
        // spacer
      } else {
        if (newProps.vehicleProductListData !== null)
          if (newProps.vehicleProductListData.length !== 0) {
            sessionStorage.setItem(
              'selectedVehicleProduct',
              newProps.vehicleProductListData[0].bt
            );
          }
      }
    }
    /* if (
      this.props.batteryOverallConditionData !==
      newProps.batteryOverallConditionData
    ) {
      if (
        newProps.batteryOverallConditionData !== null &&
        newProps.batteryOverallConditionData !== undefined
      ) {
        sessionStorage.setItem(
          'nessan',
          newProps.batteryOverallConditionData[0].bpa
        );
        this.setState({
          eventFormValues: {
            ...this.state.eventFormValues,
            pecIP: newProps.batteryOverallConditionData[0].ip,
            producttype: newProps.batteryOverallConditionData[0].typ,
            oessan: newProps.batteryOverallConditionData[0].bpa,
            nessan: newProps.batteryOverallConditionData[0].bpa,
            oesssn: newProps.batteryOverallConditionData[0].bps,
            opecan: newProps.batteryOverallConditionData[0].pa,
            opecsn: newProps.batteryOverallConditionData[0].ps,
          },
        });
      }
    }

    if (this.props.saveEventResponce !== newProps.saveEventResponce) {
      if (newProps.saveEventResponce !== null) {
        if (newProps.saveEventResponce[0].sts === true) {
          setTimeout(() => {
            this.props.history.push(
              '/FleetView?st=' +
                sessionStorage.getItem('selectedSite') +
                '&ft=' +
                sessionStorage.getItem('selectedFleet') +
                '&sdt=' +
                this.props.selectedStartDate +
                '&edt=' +
                this.props.selectedEndDate
            );
          }, 3000);
        }
      }
    }
    if (this.props.undoEventResponce !== newProps.undoEventResponce) {
      if (newProps.undoEventResponce !== null) {
        if (newProps.undoEventResponce[0].sts === true) {
          setTimeout(() => {
            this.props.clearUndoEventResponce();
            this.props.history.push(
              '/FleetView?st=' +
                sessionStorage.getItem('selectedSite') +
                '&ft=' +
                sessionStorage.getItem('selectedFleet') +
                '&sdt=' +
                this.props.selectedStartDate +
                '&edt=' +
                this.props.selectedEndDate
            );
          }, 3000);
        }
      }
    } */

    this.props.vehiclesListData !== null &&
      newProps.vehiclesListData !== null &&
      this.state.searchQuery !== '' &&
      this.handleSearchInputChange(this.state.searchQuery);
  }

  onFleetListClick = (selectedFleet, selectedSite, selectedFleetType) => {
    sessionStorage.setItem('selectedFleet', selectedFleet);
    sessionStorage.setItem('selectedFleetType', selectedFleetType);
    sessionStorage.setItem('selectedSite', selectedSite);
    sessionStorage.setItem('selectedVehicle', null);
    sessionStorage.setItem('selectedVehicleProduct', null);
    sessionStorage.setItem('selectedProductIP', null);

    this.props.history.push(
      '/FleetView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet') +
        '&fty=' +
        sessionStorage.getItem('selectedFleetType') +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate
    );
  };

  onVehicleListClick = async (
    selectedVehicle,
    selected_Product = null,
    selectedP_Dname = null
  ) => {
    document.getElementById('scrollvplist') !== null &&
      sessionStorage.setItem(
        'Sidemenuscroll',
        document.getElementById('scrollvplist').scrollTop
      );
    if (selected_Product === null) {
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem('selectedVehicleProduct', null);
      sessionStorage.setItem('selectedProductDname', null);
      sessionStorage.setItem('selectedProductIP', null);
      sessionStorage.setItem('selectedPack', null);
      sessionStorage.setItem('selectedPackDname', null);
      await this.props.clearVehicleViewData();

      this.props.history.push(
        '/FleetView/VehicleView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&sdt=' +
          this.props.selectedStartDate +
          '&edt=' +
          this.props.selectedEndDate +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&vtb=Overview-vehicle-view'
      );
    } else {
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem('selectedVehicleProduct', selected_Product);
      sessionStorage.setItem('selectedProductDname', selectedP_Dname);
      sessionStorage.setItem('selectedProductIP', null);
      sessionStorage.setItem('selectedPack', null);
      sessionStorage.setItem('selectedPackDname', null);
      await this.props.clearVehicleViewData();

      this.props.history.push(
        '/FleetView/VehicleView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&vp=' +
          selected_Product +
          '&pdn=' +
          selectedP_Dname +
          '&sdt=' +
          this.props.selectedStartDate +
          '&edt=' +
          this.props.selectedEndDate +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&vtb=Overview-vehicle-view'
      );
    }
  };

  onVehicleProductListClickRedirectionHandler = (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedVehicleProductBPU,
    selectedType
  ) => {
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('vc', selectedVehicle);
      url.searchParams.set('vp', selectedVehicleProduct);
      url.searchParams.set('pip', selectedProductIP);
      url.searchParams.set('pdn', selectedProductDname);
      url.searchParams.set(
        'unmas',
        sessionStorage.getItem('unmountedESSclicked')
      );
      url.searchParams.set('bpu', selectedVehicleProductBPU);
      url.searchParams.delete('brp');
      url.searchParams.delete('bsp');
      url.searchParams.delete('lmn');
      url.searchParams.delete('lmx');
      url.searchParams.delete('smn');
      url.searchParams.delete('smx');
      url.searchParams.delete('prbrmn');
      url.searchParams.delete('prbrmx');
      url.searchParams.delete('psbrmn');
      url.searchParams.delete('psbrmx');
      const routeTo =
        selectedType === 'converter'
          ? '/FleetView/VehicleView/TractionConverterView'
          : '/FleetView/VehicleView/VehicleProductsView';
      this.props.history.push(routeTo + url.search);
    }
  };

  onVehicleProductListClick = async (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedVehicleProductBPU
  ) => {
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    sessionStorage.setItem(
      'selectedVehicleProductBPU',
      selectedVehicleProductBPU
    );

    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    // this.props.setAllEventListData({
    //   pageSize: 20,
    //   pageIndex: 0,
    //   uniqueKey: 'All',
    //   uniqueValue: 'All',
    // });
    this.props.getBatteryOverallConditionData(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      selectedVehicleProduct
    );
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();
    this.onVehicleProductsViewTabClick(this.state.selectedTab);
  };

  onVehicleProductPackClick = async (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedPack,
    selectedPackDname
  ) => {
    document.getElementById('scrollvplist') !== null &&
      sessionStorage.setItem(
        'Sidemenuscroll',
        document.getElementById('scrollvplist').scrollTop
      );
    sessionStorage.setItem('directlink', 'false');
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    sessionStorage.setItem('selectedPack', selectedPack);
    sessionStorage.setItem('selectedPackDname', selectedPackDname);

    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();
    this.props.history.push(
      '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet') +
        '&fty=' +
        sessionStorage.getItem('selectedFleetType') +
        '&vc=' +
        sessionStorage.getItem('selectedVehicle') +
        '&vp=' +
        selectedVehicleProduct +
        '&pk=' +
        sessionStorage.getItem('selectedPack') +
        '&pkdn=' +
        sessionStorage.getItem('selectedPackDname') +
        '&pip=' +
        selectedProductIP +
        '&pdn=' +
        selectedProductDname +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate +
        '&unmas=' +
        sessionStorage.getItem('unmountedESSclicked') +
        '&ftr=' +
        [
          this.state.filterItems.availability,
          this.state.filterItems.reliability,
          this.state.filterItems.utilization,
        ] +
        '&srch=' +
        this.state.searchQuery +
        '&tb=Overview-vehicleprod-view'
    );
  };

  onDatePickerButtonClick = async clickedButton => {
    this.setState({ comnDateType: clickedButton });
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('cdty', clickedButton);
      this.props.history.push(url.pathname + url.search);
    }

    let oStartDate, oEndDate;
    if (clickedButton === 'Monthly') {
      oStartDate = Moment().subtract(30, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    } else if (clickedButton === 'Weekly') {
      oStartDate = Moment().subtract(7, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    } else if (clickedButton === 'Daily') {
      oStartDate = Moment().subtract(1, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    } else if (clickedButton === 'Year') {
      oStartDate = Moment().subtract(365, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    } else {
      oStartDate = Moment().subtract(30, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    }
    this.onApplyClick(oStartDate, oEndDate);
  };

  onLeftRightBtnClick = async (btn, vals) => {
    let oStartDate;
    let oEndDate;
    const clickedButton = this.state.comnDateType;
    const increment = vals;
    const val = vals - 1;

    if (clickedButton === 'Monthly') {
      const start = 30 * increment;
      const end = 1 + 30 * val;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    } else if (clickedButton === 'Weekly') {
      const start = 7 * increment;
      const end = 1 + 7 * val;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    } else if (clickedButton === 'Daily') {
      const start = 1 * increment;
      const end = 1 + 1 * val;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    } else if (clickedButton === 'Year') {
      const start = 365 * increment;
      const end = 1 + 365 * val;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    } else {
      const start = 30;
      const end = 1;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    }
    this.onApplyClick(oStartDate, oEndDate);
  };

  updateFilteredData = () => {
    if (this.state.filterItems.selectedSearchKey === 'Fleets') {
      // this.props.setFleetName(null);
      sessionStorage.setItem('selectedFleet', 'null');
      sessionStorage.setItem('selectedVehicle', 'null');
      sessionStorage.setItem('selectedVehicleProduct', 'null');
      sessionStorage.setItem('selectedProductIP', 'null');
      /* if (
        this.state.filterItems.isGoodSlected == false &&
        this.state.filterItems.isPoorSelected == false &&
        this.state.filterItems.isUnknownSelected == false
      ) {
        this.props.getFleetsList("All", "All", "All", true, true, true);
      } else {
        this.props.getFleetsList(
          "All",
          "All",
          "All",
          this.state.filterItems.isGoodSlected,
          this.state.filterItems.isPoorSelected,
          this.state.filterItems.isUnknownSelected
        );
      } */
      // this.props.getVehiclesList(null, true, true, true);
      this.props.history.push('/FleetView');
    }
    if (this.state.filterItems.selectedSearchKey === 'Vehicles') {
      sessionStorage.setItem('selectedVehicle', 'null');
      sessionStorage.setItem('selectedVehicleProduct', 'null');
      sessionStorage.setItem('selectedProductIP', 'null');
      /* if (
        this.state.filterItems.isGoodSlected == false &&
        this.state.filterItems.isPoorSelected == false &&
        this.state.filterItems.isUnknownSelected == false
      ) {
        this.props.getVehiclesList(
          sessionStorage.getItem("selectedFleet"),
          true,
          true,
          true
        );
      } else {
        this.props.getVehiclesList(
          sessionStorage.getItem("selectedFleet"),
          this.state.filterItems.isGoodSlected,
          this.state.filterItems.isPoorSelected,
          this.state.filterItems.isUnknownSelected
        );
      } */
      this.props.history.push('/FleetView/VehicleView');
    }
  };

  sideMenuFilter = (key, value, isAutoClick = false) => {
    if (key === 'reset') {
      this.setState(
        prevState => ({
          filterItems: {
            ...prevState.filterItems,
            availability: '',
            reliability: '',
            utilization: '',
            // isAutoClick: false,
          },
        }),
        () => {
          this.updateFilteredData();
          setTimeout(() => {
            this.setState(prevState => ({
              filterItems: {
                ...prevState.filterItems,
                // isAutoClick: false,
              },
            }));
          }, 0);
          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            const temp = [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ];
            url.searchParams.set('ftr', temp);
            this.props.history.push(url.pathname + url.search);
          }
        }
      );
    } else {
      this.setState(
        prevState => ({
          filterItems: {
            ...prevState.filterItems,
            [key]: value,
            // isAutoClick: isAutoClick,
          },
        }),
        () => {
          this.updateFilteredData();
          setTimeout(() => {
            this.setState(prevState => ({
              filterItems: {
                ...prevState.filterItems,
                // isAutoClick: false,
              },
            }));
          }, 0);
          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            const temp = [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ];
            url.searchParams.set('ftr', temp);
            this.props.history.push(url.pathname + url.search);
          }
        }
      );
    }
  };

  handleFilterDropdownChange = value => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          selectedSearchKey: value,
          isGoodSlected: false,
          isPoorSelected: false,
          isUnknownSelected: false,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          url.searchParams.set(
            'otb',
            value === 'Availability'
              ? 'avail'
              : value === 'Reliability'
                ? 'reli'
                : value === 'Utilization'
                  ? 'util'
                  : 'binfo'
          );
          this.setState({
            selectedOverviewTab:
              value === 'Availability'
                ? 'avail'
                : value === 'Reliability'
                  ? 'reli'
                  : value === 'Utilization'
                    ? 'util'
                    : 'binfo',
          });
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onGoodFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isGoodSlected: !this.state.filterItems.isGoodSlected,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onPoorFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isPoorSelected: !this.state.filterItems.isPoorSelected,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onUnknownFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isUnknownSelected: !this.state.filterItems.isUnknownSelected,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onFavoriteFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isFavoriteSelected: !this.state.filterItems.isFavoriteSelected,
        },
      }),
      () => {
        // this.filterFleetData();
      }
    );
  };

  onVehicleProductsViewTabClickRedirection = (
    tab,
    stDte = null,
    edDte = null
  ) => {
    const url = new URL(window.location.href);
    url.searchParams.set('tb', tab);
    this.props.history.push(url.pathname + url.search);
  };

  onVehicleProductsViewTabClick = async (tab, stDte = null, edDte = null) => {
    this.setState({ selectedTab: tab });
    const url = new URL(window.location.href);

    this.props.fetchDiagnosticList(
      url.searchParams.get('ft'),
      url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
      url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
      url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
      url.searchParams.get('sdt'),
      url.searchParams.get('edt'),
      new URL(window.location.href).searchParams.get('dfdt') !== null
        ? new URL(window.location.href).searchParams.get('dfdt')
        : 'Past 90 days',
      this.props.diagnosticEventData.uniqueKey,
      this.props.diagnosticEventData.uniqueValue,
      url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
      this.props.diagnosticEventData.pageSize,
      this.props.diagnosticEventData.pageIndex,
      !this.props.diagnosticEventData.sortValue &&
        this.props.diagnosticEventData.sortValueKey
        ? 1
        : 0,
      this.props.diagnosticEventData.sortValueKey
        ? this.props.diagnosticEventData.sortValueKey
        : ''
    );
  };

  copyLinkDiagnosticTab = async obj => {
    copyToClipboard(
      obj.pt,
      'File location path copied to clipboard',
      'Failed to copy File location path to clipboard'
    );
  };

  redirectToTimetrendFrmDiagnostic = async obj => {};

  onApplyClick = async (startDate, endDate, custom = '') => {};

  getDateDiff = (StartDate, EndDate) => {
    if (StartDate && EndDate) {
      const duration = Moment.duration(Moment(EndDate).diff(Moment(StartDate)));
      const totaldays = duration.asDays();
      const TotalHours = duration.asHours();
      return { totaldays, TotalHours };
    }

    return '';
  };

  getSamplingType = (startDate, endDate) => {
    const { totaldays, TotalHours } = this.getDateDiff(startDate, endDate);
    let samplingType = '';
    // let totaldays = 0;
    // let TotalHours = 22;
    if (totaldays > 60 && totaldays <= 90) {
      samplingType = '30 minute';
    } else if (totaldays > 20 && totaldays <= 60) {
      samplingType = '15 minute';
    } else if (totaldays > 5 && totaldays <= 20) {
      samplingType = '4 minute';
    } else if (totaldays > 1 && totaldays <= 5) {
      samplingType = '1 minute';
    } else if (totaldays <= 1) {
      if (TotalHours > 4) {
        samplingType = '15 second';
      } else if (TotalHours > 1 && TotalHours <= 4) {
        samplingType = '5 second';
      } else {
        samplingType = '1 second';
      }
    }
    return samplingType;
  };

  getDifferenceText = (StartDate, EndDate) => {
    if (StartDate && EndDate) {
      const localStartDate = StartDate;
      const localEndDate = EndDate;
      const diff = Moment(localEndDate).diff(Moment(localStartDate));
      const d = Moment.duration(diff);

      const days = Math.floor(d.asHours() / 24);
      const hours = Math.floor(d.asHours() - days * 24);
      const minutes = Math.floor(d.asMinutes() - (hours * 60 + days * 24 * 60));
      let msg = days > 0 ? ` ${days} days` : '';
      msg += hours > 0 || minutes > 0 ? ` ${hours} hours` : '';
      msg += minutes > 0 ? ` ${minutes} minutes` : '';

      if (days < 0 || hours < 0 || minutes < 0) {
        msg = '';
      }
      return msg;
    }

    return '';
  };

  onListSelectionClick = (e, selection) => {
    if (selection === 'vehicle') {
      if (e.target.checked === true && this.props.selectedList === 'product')
        this.props.setListSelection('vehicleproduct');
      else if (e.target.checked === true)
        this.props.setListSelection('vehicle');
      else this.props.setListSelection('product');
    } else if (selection === 'product') {
      if (e.target.checked === true && this.props.selectedList === 'vehicle')
        this.props.setListSelection('vehicleproduct');
      else if (e.target.checked === true)
        this.props.setListSelection('product');
      else this.props.setListSelection('vehicle');
    }
  };

  redirectToEmailAlert = () => {
    this.props.history.push(
      '/FleetView/EmailAlertView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet')
    );
  };

  checkDateValidation = () => {
    // check the dates
    const diff = Moment(
      new Date(this.props.selectedEndDate.replace(/%3A/g, ':'))
    ).diff(new Date(this.props.selectedStartDate.replace(/%3A/g, ':')));
    const d = Moment.duration(diff);

    const days = Math.floor(d.asHours() / 24);
    const validateDays = d.asHours() / 24;
    const hours = Math.floor(d.asHours() - days * 24);
    const minutes = Math.floor(d.asMinutes() - (hours * 60 + days * 24 * 60));
    // let msg = days > 0 ? ` ${days} days` : '';
    // msg += hours > 0 || minutes > 0 ? ` ${hours} hours` : '';
    // msg += minutes > 0 ? ` ${minutes} minutes` : '';

    if (validateDays === 0 && hours === 0 && minutes === 0) {
      return false;
    } else if (validateDays < 0 || hours < 0 || minutes < 0) {
      return false;
    } else if (validateDays > 90) {
      return false;
    } else {
      return true;
    }
  };

  handleSearchInputChange = value => {
    if (value === null || value === '') {
      sessionStorage.removeItem('searchResultList');
    }
    this.setState({ searchQuery: value });
    sessionStorage.setItem('searchQuery', value);
    value = value && value.toLowerCase();
    let results = [];

    // let startprof = performance.now();
    this.props.vehiclesListData[0].vst.forEach(item => {
      // if the vehicle name matches
      item.vdat.forEach(subitem => {
        // if the query matches battery name or ip address or pec serial number
        if (
          (subitem.dn && subitem.dn.toLowerCase().includes(value)) ||
          (subitem.ip && subitem.ip.toLowerCase().includes(value)) ||
          (subitem.an && subitem.an.toLowerCase().includes(value)) ||
          (subitem.bpu && subitem.bpu.toLowerCase().includes(value)) ||
          (subitem.rcm && subitem.rcm.includes(value)) ||
          (subitem.swv && subitem.swv.includes(value)) ||
          (subitem.bwvr && subitem.bwvr.includes(value))
        ) {
          results.push(item);
        }
        if (subitem.pdat.length > 0) {
          let matchFound = false;
          subitem.pdat.forEach(subitemlvl2 => {
            if (
              (subitemlvl2.dn &&
                subitemlvl2.dn.toLowerCase().includes(value)) ||
              (subitemlvl2.ip &&
                subitemlvl2.ip.toLowerCase().includes(value)) ||
              (subitemlvl2.an &&
                subitemlvl2.an.toLowerCase().includes(value)) ||
              (subitemlvl2.bpu &&
                subitemlvl2.bpu.toLowerCase().includes(value)) ||
              (subitemlvl2.rcm && subitemlvl2.rcm.includes(value)) ||
              (subitemlvl2.swv && subitemlvl2.swv.includes(value)) ||
              (subitemlvl2.bwvr && subitemlvl2.bwvr.includes(value))
            ) {
              matchFound = true;
              // results.push(item);
            }
          });
          if (matchFound) results.push(item);
        }
      });
      if (
        (item.vn && item.vn.toLowerCase().includes(value)) ||
        (item.dvn && item.dvn.toLowerCase().includes(value))
      ) {
        results.push(item);
      }
    });
    const uniqueResults = results.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        results.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    const searchResultList = [
      { vst: uniqueResults, data: this.props.vehiclesListData[0].data },
    ];
    this.setState({
      searchResultList,
    });
    sessionStorage.setItem(
      'searchResultList',
      JSON.stringify(searchResultList)
    );
    results = [];
    if (value === null || value === '') {
      sessionStorage.removeItem('searchResultList');
    }
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('srch', value);
      window.history.pushState(null, null, url);
    }
  };

  expandSide = () => {
    this.setState(
      {
        expandSide: !this.state.expandSide,
      },
      () => {
        this.props.setWidth(this.state.expandSide ? 370 : 120);
        // above is to set the variable width of the sidenav

        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          url.searchParams.set('snav', this.state.expandSide);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  diagFilterApply = () => {
    const url = new URL(window.location.href);
    this.props.fetchDiagnosticList(
      url.searchParams.get('ft'),
      url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
      url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
      url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
      url.searchParams.get('sdt'),
      url.searchParams.get('edt'),
      'Past 90 days',
      this.props.diagnosticEventData.uniqueKey,
      this.props.diagnosticEventData.uniqueValue,
      url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
      20,
      0,
      !this.props.diagnosticEventData.sortValue &&
        this.props.diagnosticEventData.sortValueKey
        ? 1
        : 0,
      this.props.diagnosticEventData.sortValueKey
        ? this.props.diagnosticEventData.sortValueKey
        : ''
    );
  };

  render() {
    return (
      <React.Fragment>
        <Breadcrumb
          BreadcrumbIcon={<HomeIcon className={''} alt="Home" />}
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          MonitoredCount={this.props.monitoredCountData}
          userConfig={userConfig}
        />
        <Draggable
          left_side_content={
            this.props.showSidebar ? (
              <div className="sideMenucon noLeftpadding noRightpadding">
                {platformVersion() !== '3' ? (
                  <SideMenu
                    screenWidth={this.props.sideWidth}
                    size={this.props.size}
                    handleSearchInputChange={value =>
                      this.handleSearchInputChange(value)
                    }
                    searchQuery={this.state.searchQuery}
                    fleetList={this.props.fleetsListData} // {fleetList}
                    vehicles={
                      this.state.searchResultList.length === 0
                        ? this.props.vehiclesListData
                        : this.state.searchResultList
                    } // {vehicles}
                    onFleetListClick={this.onFleetListClick}
                    selectedFleet={sessionStorage.getItem('selectedFleet')}
                    selectedFleetType={sessionStorage.getItem(
                      'selectedFleetType'
                    )}
                    isGoodSlected={this.state.filterItems.isGoodSlected}
                    isPoorSelected={this.state.filterItems.isPoorSelected}
                    isUnknownSelected={this.state.filterItems.isUnknownSelected}
                    isFavoriteSelected={
                      this.state.filterItems.isFavoriteSelected
                    }
                    selectedSearchKey={this.state.filterItems.selectedSearchKey}
                    onGoodFilterCkick={this.onGoodFilterCkick}
                    onPoorFilterCkick={this.onPoorFilterCkick}
                    onUnknownFilterCkick={this.onUnknownFilterCkick}
                    onFavoriteFilterCkick={this.onFavoriteFilterCkick}
                    handleFilterDropdownChange={this.handleFilterDropdownChange}
                    filterWithFleetName={this.filterWithFleetName}
                    fleettoSearch={this.state.fleettoSearch}
                    filterFleetNameUpdate={this.filterFleetNameUpdate}
                    filterFleetNameEnter={this.filterFleetNameEnter}
                    onVehicleListClick={this.onVehicleListClick}
                    selectedView={'Fleet'}
                    onVehicleProductListClick={
                      this.onVehicleProductListClickRedirectionHandler
                    }
                    onListSelectionClick={this.onListSelectionClick}
                    listSelection={this.props.selectedList}
                    loadingUnmountedESSList={this.props.loadingUnmountedESSList}
                    unmountedESSListData={this.props.unmountedESSListData}
                    sideMenuFilter={this.sideMenuFilter}
                    filterAvailability={this.state.filterItems.availability}
                    filterReliability={this.state.filterItems.reliability}
                    filterUtilization={this.state.filterItems.utilization}
                    expandSide={this.expandSide}
                  />
                ) : (
                  // show this for side menu v3
                  <SideMenuV3
                    // trainno={selectedTrain}
                    screenWidth={this.props.sideWidth}
                    size={this.props.size}
                    expandSide={this.expandSide}
                    handleSearchInputChange={value =>
                      this.handleSearchInputChange(value)
                    }
                    searchQuery={this.state.searchQuery}
                    fleetList={this.props.fleetsListData} // {fleetList}
                    vehicles={
                      this.state.searchResultList.length === 0
                        ? this.props.vehiclesListData // sideMenuV3Data //this.props.vehiclesListData
                        : this.state.searchResultList
                    } // {vehicles}
                    onFleetListClick={this.onFleetListClick}
                    onVehicleProductPackClick={this.onVehicleProductPackClick}
                    selectedFleet={sessionStorage.getItem('selectedFleet')}
                    selectedFleetType={sessionStorage.getItem(
                      'selectedFleetType'
                    )}
                    isGoodSlected={this.state.filterItems.isGoodSlected}
                    isPoorSelected={this.state.filterItems.isPoorSelected}
                    isUnknownSelected={this.state.filterItems.isUnknownSelected}
                    isFavoriteSelected={
                      this.state.filterItems.isFavoriteSelected
                    }
                    selectedSearchKey={this.state.filterItems.selectedSearchKey}
                    onGoodFilterCkick={this.onGoodFilterCkick}
                    onPoorFilterCkick={this.onPoorFilterCkick}
                    onUnknownFilterCkick={this.onUnknownFilterCkick}
                    onFavoriteFilterCkick={this.onFavoriteFilterCkick}
                    handleFilterDropdownChange={this.handleFilterDropdownChange}
                    filterWithFleetName={this.filterWithFleetName}
                    fleettoSearch={this.state.fleettoSearch}
                    filterFleetNameUpdate={this.filterFleetNameUpdate}
                    filterFleetNameEnter={this.filterFleetNameEnter}
                    onVehicleListClick={this.onVehicleListClick}
                    selectedView={'Fleet'}
                    onVehicleProductListClick={
                      this.onVehicleProductListClickRedirectionHandler
                    }
                    onListSelectionClick={this.onListSelectionClick}
                    listSelection={this.props.selectedList}
                    loadingUnmountedESSList={this.props.loadingUnmountedESSList}
                    unmountedESSListData={this.props.unmountedESSListData}
                    sideMenuFilter={this.sideMenuFilter}
                    filterAvailability={this.state.filterItems.availability}
                    filterReliability={this.state.filterItems.reliability}
                    filterUtilization={this.state.filterItems.utilization}
                  />
                )}
              </div>
            ) : (
              <div className="sideMenucon noLeftpadding noRightpadding">
                <MinimizedSideMenu
                  expandSide={this.expandSide}
                  setShowSidebar={this.props.setShowSidebar}
                  selectedView={'Product'}
                  onFleetListClick={this.onFleetListClick}
                  onVehicleListClick={this.onVehicleListClick}
                  onVehicleProductListClick={
                    this.onVehicleProductListClickRedirectionHandler
                  }
                  selectedFleetType={sessionStorage.getItem(
                    'selectedFleetType'
                  )}
                  setSize={this.props.setSize}
                />
              </div>
            )
          }
          right_side_content={
            <div className="detailViewRightinner noRightpadding">
              {this.props.batteryOverallConditionData === null ? (
                <Loading loadintText="Loading data" />
              ) : this.props.batteryOverallConditionData &&
                this.props.batteryOverallConditionData.length > 0 ? (
                <TitleBar
                  loadingBatteryOverallCondition={
                    this.props.loadingBatteryOverallCondition
                  }
                  batteryOverallConditionData={
                    this.props.batteryOverallConditionData
                  }
                  pageName="TractionConverterView"
                  userConfig={userConfig}
                  redirectToEmailAlert={this.redirectToEmailAlert}
                  searchQuery={this.state.searchQuery}
                />
              ) : (
                'No data found'
              )}
              <div className="date-tabs-container vehicle_product_event">
                <DateRangePicker
                  onDatePickerButtonClick={this.onDatePickerButtonClick}
                  isMonthlyactive={this.state.isMonthlyactive}
                  isWeeklyactive={this.state.isWeeklyactive}
                  isDailyactive={this.state.isDailyactive}
                  onApplyClick={this.onApplyClick}
                  selectedStartDate={this.props.selectedStartDate}
                  selectedEndDate={this.props.selectedEndDate}
                  selectedTab={this.state.selectedTab}
                  comnDateType={this.state.comnDateType}
                  onLeftRightBtnClick={this.onLeftRightBtnClick}
                  leftrightBtnDisable={this.state.leftrightBtnDisable}
                />
                <TractionConverterTabs
                  fetchDiagnosticList={this.props.fetchDiagnosticList}
                  loadingDiagnosticList={this.props.loadingDiagnosticList}
                  diagnosticList={this.props.diagnosticList}
                  redirectToTimetrendFrmDiagnostic={
                    this.redirectToTimetrendFrmDiagnostic
                  }
                  copyLinkDiagnosticTab={this.copyLinkDiagnosticTab}
                  diagnosticListConfig={this.props.diagnosticListConfig}
                  diagnosticEventData={this.props.diagnosticEventData}
                  setDiagnosticEventListData={
                    this.props.setDiagnosticEventListData
                  }
                  onVehicleProductsViewTabClick={
                    this.onVehicleProductsViewTabClickRedirection
                  }
                  selectedTab={this.state.selectedTab}
                  userConfig={userConfig}
                  selectedView={'VehicleProduct'}
                  vehiclesListData={this.props.vehiclesListData}
                  showSidebar={this.props.showSidebar}
                  isResizing={this.props.isResizing}
                  heatmap_min_width={window.innerWidth - (this.props.size + 60)}
                  diagFilterApply={
                    this.diagFilterApply
                  }></TractionConverterTabs>
              </div>
            </div>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loadingBatteryOverallCondition: loadingBatteryOverallConditionSelector(),
  batteryOverallConditionData: batteryOverallConditionDataSelector(),
  fleetsListData: fleetsListDataSelector(),
  vehiclesListData: vehiclesListDataSelector(),
  vehicleProductListData: vehicleProductListDataSelector(),
  batteryStateOfChargeData: batteryStateOfChargeDataSelector(),
  monitoredCountData: monitoredCountDataSelector(),
  selectedStartDate: selectedStartDateSelector(),
  selectedEndDate: selectedEndDateSelector(),
  batterySOCRangeData: batterySOCRangeDataSelector(),
  batteryOverviewData: batteryOverviewDataSelector(),
  batteryRelData: batteryRelDataSelector(),
  selectedList: selectedListSelector(),
  batteryStrData: batteryStrDataSelector(),
  loadingUnmountedESSList: loadingUnmountedESSListSelector(),
  unmountedESSListData: unmountedESSListDataSelector(),
  sideWidth: getSidebarWidth(),
  showSidebar: getShowSidebar(),
  isResizing: getIsResizing(),
  size: getSize(),
  radioValue: RadioValueSelectionData(),
  loadingDiagnosticList: loadingDiagnosticListSelector(),
  diagnosticList: diagnosticListSelector(),
  diagnosticListConfig: diagnosticEventListSelectorConfig(),
  diagnosticEventData: diagnosticEventDataSelector(),
});

const mapDispatchToProps = {
  setAllEventListData,
  getBatteryOverallConditionData: fetchBatteryOverallConditionData,
  getFleetsList: fetchFleetsList,
  getVehiclesList: fetchVehiclesList,
  getVehicleProductList: fetchVehicleProductList,
  getModuleList: fetchModuleList,
  getBatteryStateOfChargeData: fetchBatteryStateOfChargeData,
  getMonitoredCount: fetchMonitoredCount,
  setDate,
  getBatterySOCRangeData: fetchBatterySOCRangeData,
  clearChartData,
  clearOverviewata,
  getBatteryOverviewData: fetchBatteryOverviewData,
  getOperatingHoursHistogramData: fetchOperatingHoursHistogramData,
  getDetailViewTimetrend: fetchDetailViewTimetrend,
  clearDetailViewTimetrend,
  clearEventList,
  clearVehicleViewData,
  getBatteryRelData: fetchBatteryRelData,
  setListSelection,
  getBatteryStrData: fetchBatteryStrData,
  saveEvent,
  undoEvent,
  clearSaveEventResponce,
  clearUndoEventResponce,
  fetchUnmountedESSList,
  setWidth: setSidebarWidth,
  setShowSidebar,
  setRadioButtonValue,
  fetchMessages: fetchActiveMessages,
  setDiagnosticEventListData,
  fetchDiagnosticList,
  setSize,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TractionConverterView)
);
