import React from 'react';
import { Col } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { checkUserEntitlements } from '../../userConfig';
import Diagnostic from '../Diagnostic';
import EventList from '../Events';
import Loading from '../Loading';
import VehicleStatusTabs from '../VehicleStatusTabs';
import './styles.scss';

class VehiclesTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFixed: false,
    };
  }

  handleScroll = () => {
    const isFixed = window.scrollY > 195;
    if (isFixed !== this.state.isFixed) {
      this.setState({ isFixed });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { isFixed } = this.state;
    return (
      <div className={`tabfleetview`} id="vehicleviewtabs">
        <Tabs
          id="fleet"
          style={{ width: this.props.heatmap_min_width - 5 }}
          className={`main-tab-content${isFixed ? ' fixed' : ''}`}
          activeKey={this.props.selectedTab}
          onSelect={k => this.props.onVehicleViewTabClick(k)}>
          {checkUserEntitlements(
            'ESS:Vehicle-Overview',
            'Overview-vehicle-view'
          ) && (
            <Tab
              eventKey="Overview-vehicle-view"
              title={<span className="tabtitle">Overview</span>}>
              <div className="custom-tab-content haveinnertabs">
                {this.props.loadingVehicleRel === true ||
                this.props.vehicleRelData === null ||
                this.props.loadingVehicleStr === true ||
                this.props.vehicleStrData === null ||
                this.props.loadingVehicleAvail === true ||
                this.props.vehicleAvailData === null ? (
                  <Loading loadintText="Loading data" />
                ) : this.props.vehicleAvailData.length > 0 ? (
                  <VehicleStatusTabs
                    onVehicleViewStatusTabClick={
                      this.props.onVehicleViewStatusTabClick
                    }
                    redirectToTimetrend={this.props.redirectToTimetrend}
                    vehicleRelData={this.props.vehicleRelData}
                    vehicleStrData={this.props.vehicleStrData}
                    vehicleAvailData={this.props.vehicleAvailData}
                    selectedOverviewTab={this.props.selectedOverviewTab}
                    userConfig={this.props.userConfig}
                    isResizing={this.props.isResizing}
                    showSidebar={this.props.showSidebar}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </Tab>
          )}
          {this.props.userConfig !== undefined &&
            (sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet'
              ? checkUserEntitlements('ESS:Demo-Events', 'vehicle-eventlist')
              : checkUserEntitlements(
                  'ESS:Vehicle-Events',
                  'vehicle-eventlist'
                )) && (
              <Tab
                eventKey="vehicle-eventlist"
                title={<span className="tabtitle">Events List</span>}>
                <div
                  className="custom-tab-content"
                  id="vehicle-evnt-list"
                  style={{
                    // position: "absolute",
                    width: this.props.heatmap_min_width - 5,
                  }}>
                  <>
                    <EventList
                      eventListConfig={
                        this.props.eventListConfig && this.props.eventListConfig
                      }
                      eventData={this.props.eventData}
                      setAllEventListData={this.props.setAllEventListData}
                      fetchAllEventList={this.props.fetchAllEventList}
                      eventList={this.props.eventList}
                      handleEventInputChange={this.props.handleEventInputChange}
                      handleEventRadioChange={this.props.handleEventRadioChange}
                      setEventUpdateDate={this.props.setEventUpdateDate}
                      setEventNewPec={this.props.setEventNewPec}
                      eventFormValues={this.props.eventFormValues}
                      handleClose={this.props.handleClose}
                      handleEventSubmit={this.props.handleEventSubmit}
                      loadingSaveEvent={this.props.loadingSaveEvent}
                      saveEventResponce={this.props.saveEventResponce}
                      selectedView={this.props.selectedView}
                      vehiclesListData={this.props.vehiclesListData}
                      eventFilterApply={this.props.eventFilterApply}
                      loadingEventList={this.props.loadingEventList}
                      handleUndoSubmit={this.props.handleUndoSubmit}
                      loadingUndoEvent={this.props.loadingUndoEvent}
                      undoEventResponce={this.props.undoEventResponce}
                      selectedVehicle={this.props.selectedVehicle}
                      userConfig={this.props.userConfig}
                      redirectToTimetdOnStreesEvtClk={
                        this.props.redirectToTimetdOnStreesEvtClk
                      }
                    />
                  </>
                </div>
              </Tab>
            )}
          {checkUserEntitlements(
            'ESS:Diagnostic-event-tab',
            'vehicle-diagnostic'
          ) && (
            <Tab
              eventKey="vehicle-diagnostic"
              title={<span className="tabtitle">Diagnostics</span>}>
              <div
                className="custom-tab-content"
                id="fleet-diag-list"
                style={{
                  // position: "absolute",
                  width: this.props.heatmap_min_width - 5,
                }}>
                <Diagnostic
                  entitlements={this.props.userConfig.entitlements}
                  selectedView={this.props.selectedView}
                  vehiclesListData={this.props.vehiclesListData}
                  fetchDiagnosticList={this.props.fetchDiagnosticList}
                  loadingDiagnosticList={this.props.loadingDiagnosticList}
                  diagnosticList={
                    this.props.diagnosticList && this.props.diagnosticList
                  }
                  redirectToTimetrendFrmDiagnostic={
                    this.props.redirectToTimetrendFrmDiagnostic
                  }
                  copyLinkDiagnosticTab={this.props.copyLinkDiagnosticTab}
                  diagnosticListConfig={
                    this.props.diagnosticListConfig &&
                    this.props.diagnosticListConfig
                  }
                  diagnosticEventData={
                    this.props.diagnosticEventData &&
                    this.props.diagnosticEventData
                  }
                  setDiagnosticEventListData={
                    this.props.setDiagnosticEventListData
                  }
                  diagFilterApply={this.props.diagFilterApply}
                />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

export default VehiclesTabs;
