import { fromJS } from 'immutable';
import {
  CLEAR_EVENT_LIST,
  CLEAR_SAVE_EVENT_RESPONCE,
  CLEAR_UNDO_EVENT_RESPONCE,
  FETCH_ALL_EVENT_LIST,
  FETCH_ALL_EVENT_LIST_FAILURE,
  FETCH_ALL_EVENT_LIST_SUCCESS,
  FETCH_EVENT_LIST,
  FETCH_EVENT_LIST_FAILURE,
  FETCH_EVENT_LIST_SUCCESS,
  FETCH_VEVENT_LIST,
  FETCH_VEVENT_LIST_FAILURE,
  FETCH_VEVENT_LIST_SUCCESS,
  SAVE_EVENT_DATA,
  SAVE_EVENT_DATA_FAILURE,
  SAVE_EVENT_DATA_SUCCESS,
  SET_ALL_EVENT_LIST_DATA,
  UNDO_EVENT_DATA,
  UNDO_EVENT_DATA_FAILURE,
  UNDO_EVENT_DATA_SUCCESS,
} from './constants';

const initialState = fromJS({
  loadingSaveEvent: false,
  saveEventResponce: null,
  loadingUndoEvent: false,
  undoEventResponce: null,
  loadingEventList: false,
  eventListData: null,
  loadingVEventList: false,
  veventListData: null,
  loadingAllEventList: null,
  AlleventListData: null,
  eventData: {},
  allEventListDataConfig: null,
  error: null,
});

export function reducerEvents(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENT_LIST:
      return state.set('loadingEventList', true).set('error', null);
    case FETCH_EVENT_LIST_SUCCESS:
      return state
        .set('loadingEventList', false)
        .set('eventListData', action.eventListResponce);
    case FETCH_EVENT_LIST_FAILURE:
      return state
        .set('loadingEventList', false)
        .set('eventListData', null)
        .set('error', action.error);
    case CLEAR_EVENT_LIST:
      return state
        .set('eventListData', null)
        .set('veventListData', null)
        .set('AlleventListData', null);

    // save event

    case SAVE_EVENT_DATA:
      return state.set('loadingSaveEvent', true).set('error', null);
    case SAVE_EVENT_DATA_SUCCESS:
      return state
        .set('loadingSaveEvent', false)
        .set('saveEventResponce', action.saveEventResponce);
    case SAVE_EVENT_DATA_FAILURE:
      return state
        .set('loadingSaveEvent', false)
        .set('saveEventResponce', null)
        .set('error', action.error);

    case CLEAR_SAVE_EVENT_RESPONCE:
      return state.set('saveEventResponce', null);

    // undo event

    case UNDO_EVENT_DATA:
      return state.set('loadingUndoEvent', true).set('error', null);
    case UNDO_EVENT_DATA_SUCCESS:
      return state
        .set('loadingUndoEvent', false)
        .set('undoEventResponce', action.undoEventResponce);
    case UNDO_EVENT_DATA_FAILURE:
      return state
        .set('loadingUndoEvent', false)
        .set('undoEventResponce', null)
        .set('error', action.error);

    case CLEAR_UNDO_EVENT_RESPONCE:
      return state.set('undoEventResponce', null);

    //* *** */

    case FETCH_VEVENT_LIST:
      return state.set('loadingVEventList', true).set('error', null);
    case FETCH_VEVENT_LIST_SUCCESS:
      return state
        .set('loadingVEventList', false)
        .set('veventListData', action.veventListResponce);
    case FETCH_VEVENT_LIST_FAILURE:
      return state
        .set('loadingVEventList', false)
        .set('veventListData', null)
        .set('error', action.error);

    case FETCH_ALL_EVENT_LIST:
      return state.set('loadingAllEventList', true).set('error', null);
    case FETCH_ALL_EVENT_LIST_SUCCESS:
      return state
        .set('loadingAllEventList', false)
        .set('AlleventListData', action.AlleventListResponce.dc)
        .set('allEventListDataConfig', action.AlleventListResponce.conf);
    case FETCH_ALL_EVENT_LIST_FAILURE:
      return state
        .set('loadingAllEventList', false)
        .set('AlleventListData', null)
        .set('allEventListDataConfig', null)
        .set('error', action.error);
    case SET_ALL_EVENT_LIST_DATA:
      return state.set('eventData', action.eventData);

    default:
      return state;
  }
}
