import { fromJS } from 'immutable';
import {
  SET_EMERGENCY_KEY_SUCCESS,
  SET_EMERGENCY_STATUS_FAILURE,
  SET_EMERGENCY_STATUS_SUCCESS,
} from './constants';

const initialState = fromJS({
  loadingEmergencyStatus: false,
  emergencyStatus: null,
  error: null,
});

export function emergencyModeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EMERGENCY_STATUS_SUCCESS:
      return state
        .set('loadingEmergencyStatus', false)
        .set('emergencyStatus', action.emergencyStatus);
    case SET_EMERGENCY_STATUS_FAILURE:
      return state
        .set('loadingEmergencyStatus', false)
        .set('emergencyStatus', null)
        .set('error', action.error);
    case SET_EMERGENCY_KEY_SUCCESS:
      return state
        .set('loadingEmergencyStatus', false)
        .set('emergencyKey', action.emergencyKey);
    default:
      return state;
  }
}
