import Highcharts from 'highcharts/highstock';
import Moment from 'moment';
import exportChart from '../../assets/images/exportChart.svg';

export function getSynchronizedGraph(seriesData) {
  const optionsList = [];
  for (let i = 0; i < seriesData.length; i += 1) {
    const options = {
      chart: {
        type: 'line',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        zoomType: 'x', // i === 0 ? "x" : undefined,
        panning: false,
        panKey: 'shift',
        shadow: false,
        marginTop: 70,
        height: 300,
        events: {
          load: function () {
            const minValue = localStorage.getItem('urlSYCmin');
            const maxValue = localStorage.getItem('urlSYCmax');
            const chart = this;
            const xAxis = chart.xAxis[0];

            if (minValue !== 'null' && maxValue !== 'null') {
              xAxis.setExtremes(Number(minValue), Number(maxValue));
              chart.showResetZoom();
              if (i === seriesData.length - 1) {
                localStorage.setItem('urlSYCmin', null);
                localStorage.setItem('urlSYCmax', null);
              }
            }
          },
        },
      },
      title: {
        text: seriesData[i].title,
        align: 'low',
        margin: 0,
        useHTML: true,
      },
      credits: {
        enabled: false,
      },
      boost: {
        //  useGPUTranslations: true,
        allowForce: false,
        usePreAllocated: true,
        // seriesThreshold: 1,
      },
      xAxis: {
        tickAmount: 8,
        padding: 16,
        crosshair: {
          width: 1,
          color: '#0b73c2',
        },
        startOnTick: false,
        endOnTick: false,
        lineWidth: 0,
        tickWidth: 0,
        labels: {
          formatter: function () {
            // return Highcharts.dateFormat("%m-%e-%Y %H:%M:%S", this.value);
            const duration = Moment.duration(
              Moment(this.axis.max).diff(Moment(this.axis.min))
            );
            const totaldays = duration.asDays();
            if (totaldays > 1) {
              return Moment.utc(this.value).format('DD.MM.YYYY');
            } else {
              return Moment.utc(this.value).format('DD.MM.YYYY HH:mm:ss');
            }
            // return Moment.utc(this.value).format("DD.MM.YYYY HH:mm:ss");
          },
        },
        events: {
          setExtremes: function (e) {
            if (e.trigger) {
              const min = e.min;
              const max = e.max;
              if (min !== undefined && max !== undefined) {
                const url = new URL(window.location.href);
                if (window.location.href.indexOf('?') > -1) {
                  url.searchParams.set('symn', min);
                  url.searchParams.set('symx', max);
                  this.props.history.push(url.pathname + url.search);
                }
              } else {
                const url = new URL(window.location.href);
                if (window.location.href.indexOf('?') > -1) {
                  url.searchParams.delete('symn');
                  url.searchParams.delete('symx');
                  this.props.history.push(url.pathname + url.search);
                }
              }
            }
            Highcharts.syncExtremes(e);
          },
        },
      },
      yAxis: [
        {
          title: {
            text: seriesData[i].series[0].yAxisTitle,
            align: 'high',
          },
          tickInterval:
            seriesData[i].series[0].name === 'Avg CellV'
              ? 0.5
              : seriesData[i].series[0].name === 'Avg Temperature'
                ? 3
                : seriesData[i].series[0].name === 'State of Charge'
                  ? 10
                  : seriesData[i].series[0].name === 'Power'
                    ? 50
                    : seriesData[i].series[0].name === 'Battery Voltage'
                      ? 50
                      : seriesData[i].series[0].name === 'Battery Current'
                        ? 40
                        : undefined,
        },
        {
          title: {
            text: seriesData[i].series[1].yAxisTitle,
            align: 'high',
          },
          tickInterval:
            seriesData[i].series[1].name === 'Avg CellV'
              ? 0.5
              : seriesData[i].series[1].name === 'Avg Temperature'
                ? 3
                : seriesData[i].series[1].name === 'State of Charge'
                  ? 10
                  : seriesData[i].series[1].name === 'Power'
                    ? 50
                    : seriesData[i].series[1].name === 'Battery Voltage'
                      ? 50
                      : seriesData[i].series[1].name === 'Battery Current'
                        ? 40
                        : undefined,
          opposite: true,
        },
      ],
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        y: 50,
        symbolRadius: 0,
        itemMarginBottom: 8,
      },
      marker: { enabled: false },
      plotOptions: {
        line: { marker: { enabled: false } },
        series: {
          boostThreshold: 10000,
          turboThreshold: Number.MAX_VALUE,
          stickyTracking: false,
          events: {},
        },
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        shared: true,
        borderRadius: 3,
        shape: 'rectangle',
        shadow: false,
        padding: 20,
        headerFormat: '<b>{point.x:%d.%m.%Y %H:%M:%S}</b><br>',
        pointFormat: '{series.name}: <b>{point.y}</b><br>',
      },
      // seriesData[i].series,
      series: [
        {
          name: seriesData[i].series[0].name,
          tooltip: seriesData[i].series[0].tooltip,
          yAxisTitle: seriesData[i].series[0].yAxisTitle,
          color: seriesData[i].series[0].color,
          data: seriesData[i].series[0].data,
          yAxis: 0,
        },
        {
          name: seriesData[i].series[1].name,
          tooltip: seriesData[i].series[1].tooltip,
          yAxisTitle: seriesData[i].series[1].yAxisTitle,
          color: seriesData[i].series[1].color,
          data: seriesData[i].series[1].data,
          yAxis: 1,
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            _id: 'custom_export',
            symbol: `url(${exportChart})`,
            symbolX: 25,
            symbolY: 30,
            align: 'right',
            verticalAlign: 'top',
            height: 28,
            width: 32,
            symbolSize: 22,
            y: 8,
            x: 10,
            menuItems: ['viewFullscreen', 'printChart'],
            theme: {
              states: {
                hover: {
                  fill: 'transparent',
                },
                pressed: {
                  fill: 'transparent',
                },
              },
            },
          },
        },
      },
    };
    optionsList.push(options);
  }
  return optionsList;
}
