export const SET_SHOW_SIDEBAR = 'SET_SHOW_SIDEBAR';

export const GET_SHOW_SIDEBAR = 'GET_SHOW_SIDEBAR';

export const SET_SIZE = 'SET_SIZE';

export const GET_SIZE = 'GET_SIZE';

export const IS_RESIZING = 'IS_RESIZING';

export const SET_IS_RESIZING = 'SET_IS_RESIZING';
