import React from 'react';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Breadcrumb from '../../components/Breadcrumb';
import Unauthorized from '../../components/Unauthorized/Unauthorized';
import {
  checkUserEntitlements,
  userConfig as configUser,
} from '../../userConfig';
import { fetchActiveMessages } from '../Broadcast/actions';
import { monitoredCountDataSelector } from '../DashBoard/selectors';
import {
  getEmergencyStatus,
  setEmergencyKey,
  setEmergencyStatus,
} from './actions';
import {
  emergencyModeStatusLoadingSelector,
  emergencyModeStatusSelector,
} from './selectors';
import './styles.scss';
class SafeMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issafeMode: false,
      showHidePassword: false,
      data: { emergency_message: '', emergency_secret_key: '' },
      changeData: {
        current_secret: '',
        new_secret: '',
        confirm_new_secret: '',
      },
      secretMatch: true,
    };
    this.props.fetchEmergencyStatus();
  }

  componentDidMount() {
    configUser();

    this.setState({
      issafeMode: checkUserEntitlements('ESS:Safe-Mode'),
      enabled:
        this.props.emergencyModeStatus &&
        this.props.emergencyModeStatus.enabled,
      message:
        this.props.emergencyModeStatus &&
        this.props.emergencyModeStatus.message,
    });
    this.props.fetchMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.emergencyModeStatus !== this.props.emergencyModeStatus) {
      this.setState({
        enabled:
          this.props.emergencyModeStatus &&
          this.props.emergencyModeStatus.enabled,
        message:
          this.props.emergencyModeStatus &&
          this.props.emergencyModeStatus.message,
      });
    }
  }

  handleInput = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  handleChangeSecretInput = e => {
    this.setState({
      changeData: { ...this.state.changeData, [e.target.name]: e.target.value },
    });
  };

  handleSubmit = e => {
    if (
      this.props.emergencyModeStatus &&
      !this.props.emergencyModeStatus.enabled &&
      !this.state.data.emergency_message
    ) {
      // empty check
      toast.error('Please provide a message !');
    } else if (!this.state.data.emergency_secret_key) {
      // empty check
      toast.error('Please provide a secret !');
    } else {
      this.props.setEmergencyStatus({
        message: this.state.data.emergency_message,
        secret: this.state.data.emergency_secret_key,
        enabled: !this.state.enabled,
      });
      // reset state
      this.setState({
        data: { emergency_message: '', emergency_secret_key: '' },
      });
    }
  };

  handleSecretsubmit = e => {
    const pattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
    const validationMsg =
      'Secret must have more that 8 charecters and atleast one special charecter one small case one capital case letter';
    // emply string check
    if (
      this.state.changeData.new_secret === '' ||
      this.state.changeData.current_secret === '' ||
      this.state.changeData.confirm_new_secret === ''
    ) {
      toast.error('Please provide values !');
    } else if (
      // match secrets
      this.state.changeData.confirm_new_secret !==
      this.state.changeData.new_secret
    ) {
      toast.error('New Secret and Confirm Secret does not match !');
    } else if (!this.state.changeData.new_secret.match(pattern)) {
      // regex validation
      toast.error(validationMsg, { duration: 5000 });
    } else {
      this.props.setEmergencyKey({
        oldSecret: this.state.changeData.current_secret,
        newSecret: this.state.changeData.new_secret,
      });
      // reset state
      this.setState({
        changeData: {
          current_secret: '',
          new_secret: '',
          confirm_new_secret: '',
        },
        showHidePassword: false,
      });
    }
  };

  showHidePwd = () => {
    this.setState({
      showHidePassword: !this.state.showHidePassword,
    });
  };

  render() {
    return this.state.issafeMode ? (
      <>
        <Breadcrumb MonitoredCount={this.props.monitoredCountData} />
        <div className="emergency_page">
          <Tabs defaultActiveKey="setEmergency">
            <Tab eventKey="setEmergency" title="Enable / Disable ">
              <div className="emergency_form col-12 col-sm-6 col-lg-4">
                <label className="emergency_label">
                  {this.props.emergencyModeStatus &&
                  this.props.emergencyModeStatus.enabled ? (
                    <>
                      Comments <br />
                      <p className="redInfo">
                        Optional - For Internal use only
                      </p>
                    </>
                  ) : (
                    <>
                      Message <span className="aestrick">*</span>
                      <br />{' '}
                      <p className="redInfo">
                        The entered message is displayed to users when safe mode
                        is enabled
                      </p>
                    </>
                  )}
                </label>

                <textarea
                  rows={5}
                  style={{ fontSize: 'large' }}
                  name="emergency_message"
                  onChange={this.handleInput}
                  // defaultValue={""}
                  value={this.state.data.emergency_message}
                />
                <label className="emergency_label">
                  Secret <span className="aestrick">*</span>
                  <br />{' '}
                  <p className="redInfo">{`Please enter the secret to ${
                    this.props.emergencyModeStatus &&
                    this.props.emergencyModeStatus.enabled
                      ? 'disable'
                      : 'enable'
                  } safe mode`}</p>
                </label>
                <input
                  className="emergency_input"
                  type="password"
                  name="emergency_secret_key"
                  value={this.state.data.emergency_secret_key}
                  // defaultValue={""}
                  onChange={this.handleInput}
                />
                <span className="emergency_btn_container">
                  <button className="emergency_btn" onClick={this.handleSubmit}>
                    {!this.state.enabled ? 'Enable' : 'Disable'}
                  </button>
                </span>
              </div>
            </Tab>
            <Tab eventKey="changeSecret" title="Change Secret">
              <div className="emergency_form col-12 col-sm-6 col-lg-4">
                <label className="emergency_label">
                  Old Secret <span className="aestrick">*</span>
                </label>
                <input
                  className="emergency_input"
                  name="current_secret"
                  type={this.state.showHidePassword ? 'text' : 'password'}
                  // defaultValue={""}
                  value={this.state.changeData.current_secret}
                  onChange={this.handleChangeSecretInput}
                />
                <label className="emergency_label">
                  New Secret <span className="aestrick">*</span>{' '}
                </label>
                <input
                  className="emergency_input"
                  type={this.state.showHidePassword ? 'text' : 'password'}
                  name="new_secret"
                  // defaultValue={""}
                  value={this.state.changeData.new_secret}
                  onChange={this.handleChangeSecretInput}
                />
                <label className="emergency_label">
                  Confirm Secret <span className="aestrick">*</span>{' '}
                </label>
                <input
                  className="emergency_input"
                  type={this.state.showHidePassword ? 'text' : 'password'}
                  name="confirm_new_secret"
                  // defaultValue={""}
                  value={this.state.changeData.confirm_new_secret}
                  onChange={this.handleChangeSecretInput}
                />
                <span className="emergency_btn_container">
                  <Form>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      checked={this.state.showHidePassword}
                      label="Show/Hide"
                      onChange={this.showHidePwd}
                    />
                  </Form>
                </span>
                <span className="emergency_btn_container">
                  <button
                    className="change_btn"
                    onClick={this.handleSecretsubmit}
                    type="submit">
                    Submit
                  </button>
                </span>
              </div>
            </Tab>
          </Tabs>
        </div>
      </>
    ) : (
      <Unauthorized />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  emergencyModeStatusLoading: emergencyModeStatusLoadingSelector(),
  emergencyModeStatus: emergencyModeStatusSelector(),
  monitoredCountData: monitoredCountDataSelector(),
});

const mapDispatchToProps = {
  fetchEmergencyStatus: getEmergencyStatus,
  setEmergencyStatus,
  setEmergencyKey,
  fetchMessages: fetchActiveMessages,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SafeMode)
);
