import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './styles.scss';

import {
  AvailGreenIcon,
  AvailRedIcon,
  AvailableUnknownIcon,
  BusIcon,
  Converter_d,
  FavIcon,
  FilterActiveIcon,
  FilterIcon,
  PackIcon,
  ProductBattery,
  ReliableGreenIcon,
  ReliableRedIcon,
  ReliableUnknownStatusIcon,
  SideMenuResetIcon,
  StressGreenIcon,
  StressRedIcon,
  StressUnknownStatusIcon,
  UnmountedIcon,
  VehicleIcon,
  WarningInfoIcon,
  WarningRedDotIcon,
} from '../../assets/images';
import {
  hasSearchValueVehicle,
  searchQueryProductLevel,
  searchSideMenuItemV3,
} from '../../utils/search_utils';
import {
  highlightSearchTerm,
  refactorBatteryText,
  refactorPackText,
  truncateAccText,
  truncatePackText,
} from '../../utils/textRefactor';
import SearchBox from '../SearchBox';
import FleetList from '../SideMenuItems';

class SideMenuV3 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      itemsToShow: 1,
      expanded: false,
      filterExpand: true,
      vehicleText: '',
      batteryText: '',
    };
  }

  componentDidMount() {
    window.$handleFilterDropdownChange = this.props.handleFilterDropdownChange;
    window.$onGoodFilterCkick = this.props.onGoodFilterCkick;
    window.$onPoorFilterCkick = this.props.onPoorFilterCkick;
    window.$onUnknownFilterCkick = this.props.onUnknownFilterCkick;
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    window.$listSelection = nextProps.listSelection;
  }

  toggleFleetList = () => {
    this.setState({ expanded: !this.state.expanded });

    if (sessionStorage.getItem('fleetToggle') === 'true') {
      sessionStorage.setItem('fleetToggle', 'false');
    } else {
      sessionStorage.setItem('fleetToggle', 'true');
    }
  };

  toggleFilter = () => {
    this.setState({ filterExpand: !this.state.filterExpand });
  };

  filterCheck = (item, index, list_sel = false) => {
    if (
      this.props.filterAvailability === '' &&
      this.props.filterReliability === '' &&
      this.props.filterUtilization === ''
    ) {
      return true;
    }

    let returnValue = false;
    if (list_sel) {
      item.vdat.forEach(plist => {
        if (this.props.filterAvailability === 'red')
          if (plist.a === 'Not available') {
            returnValue = true;
          }

        if (this.props.filterAvailability === 'green')
          if (plist.a === 'Available') {
            returnValue = true;
          }

        if (this.props.filterAvailability === 'unknown')
          if (plist.a === 'Unknown') {
            returnValue = true;
          }

        if (this.props.filterReliability === 'red')
          if (plist.r === 'Not reliable') {
            returnValue = true;
          }

        if (this.props.filterReliability === 'green')
          if (plist.r === 'Reliable') {
            returnValue = true;
          }

        if (this.props.filterReliability === 'unknown')
          if (plist.r === 'Unknown') {
            returnValue = true;
          }

        if (this.props.filterUtilization === 'red')
          if (plist.s === 'Above normal') {
            returnValue = true;
          }

        if (this.props.filterUtilization === 'green')
          if (plist.s === 'Normal') {
            returnValue = true;
          }

        if (this.props.filterUtilization === 'unknown')
          if (plist.s === 'Unknown') {
            returnValue = true;
          }
      });
    } else {
      if (this.props.filterAvailability === 'red')
        if (item.a === 'Not available') {
          returnValue = true;
        }

      if (this.props.filterAvailability === 'green')
        if (item.a === 'Available') {
          returnValue = true;
        }

      if (this.props.filterAvailability === 'unknown')
        if (item.a === 'Unknown') {
          returnValue = true;
        }

      if (this.props.filterReliability === 'red')
        if (item.r === 'Not reliable') {
          returnValue = true;
        }

      if (this.props.filterReliability === 'green')
        if (item.r === 'Reliable') {
          returnValue = true;
        }

      if (this.props.filterReliability === 'unknown')
        if (item.r === 'Unknown') {
          returnValue = true;
        }

      if (this.props.filterUtilization === 'red')
        if (item.s === 'Above normal') {
          returnValue = true;
        }

      if (this.props.filterUtilization === 'green')
        if (item.s === 'Normal') {
          returnValue = true;
        }

      if (this.props.filterUtilization === 'unknown')
        if (item.s === 'Unknown') {
          returnValue = true;
        }
    }

    return returnValue;
  };

  filterCheck_old = (item, index) => {
    if (
      this.props.selectedSearchKey === 'Select an index' ||
      (this.props.isGoodSlected === false &&
        this.props.isPoorSelected === false &&
        this.props.isUnknownSelected === false) ||
      (this.props.isGoodSlected === true &&
        this.props.isPoorSelected === true &&
        this.props.isUnknownSelected === true)
    ) {
      return true;
    }
    let returnValue = false;
    if (this.props.isGoodSlected === true) {
      if (this.props.selectedSearchKey === 'Availability')
        if (item.a === 'Available') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Reliability')
        if (item.r === 'Reliable') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Utilization')
        if (item.s === 'Normal') {
          returnValue = true;
        }
    }

    if (this.props.isPoorSelected === true) {
      if (this.props.selectedSearchKey === 'Availability')
        if (item.a === 'Not available') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Reliability')
        if (item.r === 'Not reliable') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Utilization')
        if (item.s === 'Above normal') {
          returnValue = true;
        }
    }

    if (this.props.isUnknownSelected === true) {
      if (this.props.selectedSearchKey === 'Availability')
        if (item.a === 'Unknown') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Reliability')
        if (item.r === 'Unknown') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Utilization')
        if (item.s === 'Unknown') {
          returnValue = true;
        }
    }

    return returnValue;
  };

  filterCount = () => {
    let count = 0;
    let productCount = 0;

    if (this.props.vehicles != null) {
      this.props.vehicles[0].vst.map(item =>
        item.vdat.forEach((plist, index) => {
          if (searchSideMenuItemV3(plist, this.props.searchQuery)) {
            productCount++;
            this.filterCheck(plist, index) && count++;
          }
        })
      );

      return count > 1
        ? count + '/' + productCount + ' products'
        : count + '/' + productCount + ' product';
    }
  };

  render() {
    const { searchQuery } = this.props;
    return (
      <div className="containersidemenu">
        <div data-intro="fleet-list-toggle" className="containerfleetlist">
          <FleetList
            // onTrainSelect={this.props.onTrainSelect}
            // trainno={this.props.trainno}
            FleetList={this.props.fleetList}
            toggleFleetList={this.toggleFleetList}
            fleetExpanded={this.state.expanded}
            itemsToShow={this.state.itemsToShow}
            // onFleetListClick={this.onFleetListClick}
            onFleetListClick={this.props.onFleetListClick}
            selectedFleet={this.props.selectedFleet}
            selectedFleetType={this.props.selectedFleetType}
            selectedView={this.props.selectedView}
            selectedSite={sessionStorage.getItem('selectedSite')}
          />
        </div>
        <hr />
        <div>
          <div className="frmsection">
            <Form
              noValidate
              onSubmit={event => {
                event.preventDefault();
              }}>
              <Row>
                <SearchBox
                  query={this.props.searchQuery}
                  handleSearchInputChange={this.props.handleSearchInputChange}
                  enableSearch={this.props.vehicles === null}
                />
                <Form.Group as={Col} sm={2}>
                  <Button
                    variant="outline-light"
                    className="filterbtn"
                    onClick={() => this.toggleFilter()}>
                    {this.props.filterAvailability !== '' ||
                    this.props.filterReliability !== '' ||
                    this.props.filterUtilization !== '' ? (
                      <FilterActiveIcon />
                    ) : (
                      <FilterIcon />
                    )}
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </div>
        </div>
        <div className="containersearch" data-intro="filters">
          <div>
            Showing{' '}
            <span className="chkbox">
              <input
                name="vehicle"
                type="checkbox"
                // checked={true}
                checked={
                  !!(
                    this.props.listSelection === 'vehicle' ||
                    this.props.listSelection === 'vehicleproduct'
                  )
                }
                onChange={e => {
                  this.props.onListSelectionClick(e, 'vehicle');
                }}
              />{' '}
              Vehicles{'  '}
              <input
                name="product"
                type="checkbox"
                // checked={true}
                checked={
                  !!(
                    this.props.listSelection === 'product' ||
                    this.props.listSelection === 'vehicleproduct'
                  )
                }
                onChange={e => {
                  this.props.onListSelectionClick(e, 'product');
                }}
              />{' '}
              Products
            </span>
          </div>
          <hr />
          {this.state.filterExpand && (
            <Row className="listfilter noLeftpadding noRightpadding">
              <Col md={12} className="titlerow">
                <span className="filter">Filter</span>

                <button
                  className="reset"
                  onClick={() => {
                    this.props.sideMenuFilter('reset', '');
                  }}>
                  <SideMenuResetIcon />
                  Reset
                </button>
              </Col>
              <Col md={12} className="filterrow">
                <span className="filtertext">Availability</span>
                <span className="filtericons">
                  <button
                    className={
                      this.props.filterAvailability === 'red'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'availability',
                        this.props.filterAvailability === 'red' ? '' : 'red'
                      );
                    }}>
                    <AvailRedIcon />
                  </button>
                  <button
                    className={
                      this.props.filterAvailability === 'green'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'availability',
                        this.props.filterAvailability === 'green' ? '' : 'green'
                      );
                    }}>
                    <AvailGreenIcon />
                  </button>
                  <button
                    className={
                      this.props.filterAvailability === 'unknown'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'availability',
                        this.props.filterAvailability === 'unknown'
                          ? ''
                          : 'unknown'
                      );
                    }}>
                    <AvailableUnknownIcon />
                  </button>
                </span>
              </Col>
              <Col md={12} className="filterrow">
                <span className="filtertext">Reliability</span>
                <span className="filtericons">
                  <button
                    className={
                      this.props.filterReliability === 'red'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'reliability',
                        this.props.filterReliability === 'red' ? '' : 'red'
                      );
                    }}>
                    <ReliableRedIcon />
                  </button>
                  <button
                    className={
                      this.props.filterReliability === 'green'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'reliability',
                        this.props.filterReliability === 'green' ? '' : 'green'
                      );
                    }}>
                    <ReliableGreenIcon />
                  </button>
                  <button
                    className={
                      this.props.filterReliability === 'unknown'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'reliability',
                        this.props.filterReliability === 'unknown'
                          ? ''
                          : 'unknown'
                      );
                    }}>
                    <ReliableUnknownStatusIcon />
                  </button>
                </span>
              </Col>
              <Col md={12} className="filterrow">
                <span className="filtertext">Utilization</span>
                <span className="filtericons">
                  <button
                    className={
                      this.props.filterUtilization === 'red'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'utilization',
                        this.props.filterUtilization === 'red' ? '' : 'red'
                      );
                    }}>
                    <StressRedIcon />
                  </button>
                  <button
                    className={
                      this.props.filterUtilization === 'green'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'utilization',
                        this.props.filterUtilization === 'green' ? '' : 'green'
                      );
                    }}>
                    <StressGreenIcon />
                  </button>
                  <button
                    className={
                      this.props.filterUtilization === 'unknown'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'utilization',
                        this.props.filterUtilization === 'unknown'
                          ? ''
                          : 'unknown'
                      );
                    }}>
                    <StressUnknownStatusIcon />
                  </button>
                </span>
              </Col>
            </Row>
          )}
        </div>
        {/* <hr /> */}
        <div className="totalvehicles">
          {this.props.vehicles != null && this.filterCount()}
        </div>
        {this.props.listSelection === 'vehicle' &&
          (this.props.vehicles !== null ? (
            <div className="containervehiclelist">
              <Accordion
                id="accordion1"
                allowZeroExpanded
                preExpanded={
                  sessionStorage.getItem('unmountedESSclicked') !== 'true'
                    ? [sessionStorage.getItem('selectedVehicle')]
                    : ['']
                }>
                {this.props.vehicles != null &&
                  this.props.vehicles[0].vst.map(
                    (item, index) =>
                      this.filterCheck(item, index) &&
                      hasSearchValueVehicle(item, searchQuery) && (
                        <AccordionItem
                          key={index}
                          uuid={item.vn}
                          onClick={() => {
                            this.setState({ vehicleText: item.vn });
                          }}>
                          <AccordionItemHeading
                            className={
                              sessionStorage.getItem('selectedVehicle') ===
                                item.vn &&
                              sessionStorage.getItem('unmountedESSclicked') !==
                                'true'
                                ? 'active-site'
                                : ''
                            }
                            onClick={() => {
                              sessionStorage.setItem(
                                'unmountedESSclicked',
                                'false'
                              );
                              this.props.onVehicleListClick(item.vn);
                            }}>
                            <AccordionItemButton>
                              <span>
                                <span className="vehicleicon">
                                  {this.props.selectedFleetType === 'eBus' ? (
                                    <BusIcon />
                                  ) : (
                                    <VehicleIcon />
                                  )}
                                </span>
                                {this.props.size < 300
                                  ? highlightSearchTerm(
                                      refactorBatteryText(item.dvn),
                                      searchQuery
                                    )
                                  : highlightSearchTerm(
                                      truncateAccText(item.dvn),
                                      searchQuery
                                    )}
                                <span className="vehiclestatusicon">
                                  {this.state.vehicleText !== item.vn &&
                                  item.rbc > 0 ? (
                                    <span>
                                      <WarningInfoIcon />
                                      <span>
                                        {item.rbc > 0 ? '+' + item.rbc : ''}
                                      </span>
                                    </span>
                                  ) : this.state.vehicleText === item.vn &&
                                    item.rbc > 0 ? (
                                    <span>
                                      <WarningRedDotIcon />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                        </AccordionItem>
                      )
                  )}
              </Accordion>
            </div>
          ) : (
            <div className="containervehiclelist">
              <span className="loading">Loading ...</span>
            </div>
          ))}
        {this.props.listSelection === 'product' &&
          (this.props.vehicles !== null ? (
            <div className="containerproductlist">
              <Accordion
                id="accordion1"
                allowZeroExpanded
                preExpanded={
                  sessionStorage.getItem('unmountedESSclicked') !== 'true'
                    ? [sessionStorage.getItem('selectedVehicle')]
                    : ['']
                }>
                {this.props.vehicles != null &&
                  this.props.vehicles[0].vst.map(item =>
                    item.vdat.map(
                      (plist, index) =>
                        this.filterCheck(plist, index) &&
                        searchQueryProductLevel(plist, searchQuery) &&
                        (plist.type === 'converter' &&
                        (this.props.filterAvailability !== '' ||
                          this.props.filterReliability !== '' ||
                          this.props.filterUtilization !== '') ? (
                          ''
                        ) : (
                          <AccordionItem
                            uuid={item.vn + '_' + plist.dn}
                            key={index}>
                            <AccordionItemHeading
                              onClick={() => {
                                sessionStorage.setItem(
                                  'unmountedESSclicked',
                                  'false'
                                );
                                plist.type === 'converter'
                                  ? this.props.onVehicleProductListClick(
                                      item.vn,
                                      plist.an,
                                      plist.ip,
                                      plist.dn,
                                      plist.bpu,
                                      plist.type
                                    )
                                  : this.props.onVehicleListClick(
                                      item.vn,
                                      plist.an,
                                      plist.dn
                                    );
                              }}
                              className={
                                sessionStorage.getItem(
                                  'selectedVehicleProduct'
                                ) === plist.an &&
                                sessionStorage.getItem(
                                  'unmountedESSclicked'
                                ) !== 'true'
                                  ? 'active-site'
                                  : ''
                              }>
                              <AccordionItemButton>
                                <span className="productlisticon">
                                  {plist.type === 'converter' ? (
                                    <Converter_d />
                                  ) : (
                                    <ProductBattery />
                                  )}
                                </span>
                                {this.props.size < 300
                                  ? highlightSearchTerm(
                                      refactorBatteryText(plist.dn),
                                      searchQuery
                                    )
                                  : highlightSearchTerm(
                                      truncateAccText(plist.dn),
                                      searchQuery
                                    )}
                                <span className="productstatusicon">
                                  {plist.rbc > 0 ? (
                                    <span>
                                      <WarningRedDotIcon />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          </AccordionItem>
                        ))
                    )
                  )}
              </Accordion>
            </div>
          ) : (
            <div className="containerproductlist">
              <span className="loading">Loading ...</span>
            </div>
          ))}
        {this.props.listSelection === 'vehicleproduct' &&
          (this.props.vehicles !== null ? (
            <div
              data-intro="vehicle_product_list"
              className="containervehicleproductlist"
              id="scrollvplist">
              <Accordion
                id="accordion1"
                allowZeroExpanded
                preExpanded={
                  sessionStorage.getItem('unmountedESSclicked') !== 'true'
                    ? [sessionStorage.getItem('selectedVehicle')]
                    : ['']
                }>
                {this.props.vehicles != null &&
                  this.props.vehicles[0].vst.map(
                    (item, index) =>
                      this.filterCheck(item, index, true) &&
                      searchSideMenuItemV3(item, searchQuery) && (
                        // list the vehicles
                        <AccordionItem
                          key={index}
                          uuid={item.vn}
                          onClick={() => {
                            this.setState({ vehicleText: item.vn });
                          }}
                          {...(searchQuery && {
                            dangerouslySetExpanded: true,
                          })}>
                          <AccordionItemHeading
                            data-intro={`vehicle_num_${index}`}
                            className={
                              sessionStorage.getItem('selectedVehicle') ===
                                item.vn &&
                              sessionStorage.getItem('unmountedESSclicked') !==
                                'true'
                                ? 'active-site'
                                : ''
                            }>
                            <AccordionItemButton>
                              <span
                                data-intro={`vehicle_num_click_${index}`}
                                className="headingcontainer"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    'unmountedESSclicked',
                                    'false'
                                  );
                                  this.props.onVehicleListClick(item.vn);
                                }}>
                                <span className="vehicleicon">
                                  {this.props.selectedFleetType === 'eBus' ? (
                                    <BusIcon />
                                  ) : (
                                    <VehicleIcon />
                                  )}
                                </span>
                                <span className="vehicletext">
                                  {this.props.size < 300
                                    ? highlightSearchTerm(
                                        refactorBatteryText(item.dvn),
                                        searchQuery
                                      )
                                    : highlightSearchTerm(
                                        truncateAccText(item.dvn),
                                        searchQuery
                                      )}
                                </span>
                                <div className="vehiclestatusicon">
                                  {this.state.vehicleText !== item.vn &&
                                  item.rbc > 0 ? (
                                    <span>
                                      <WarningInfoIcon />
                                      <span>
                                        {item.rbc > 0 ? '+' + item.rbc : ''}
                                      </span>
                                    </span>
                                  ) : this.state.vehicleText === item.vn &&
                                    item.rbc > 0 ? (
                                    <span>
                                      <WarningRedDotIcon />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel
                            onClick={() => {
                              sessionStorage.setItem(
                                'unmountedESSclicked',
                                'false'
                              );
                            }}
                            className={'v3_accordion__panel'}>
                            {item.vdat.map(
                              (blist, i) =>
                                searchSideMenuItemV3(blist, searchQuery) &&
                                // List the Battery Units
                                (blist.type === 'converter' &&
                                (this.props.filterAvailability !== '' ||
                                  this.props.filterReliability !== '' ||
                                  this.props.filterUtilization !== '') ? (
                                  ''
                                ) : (
                                  <Accordion
                                    key={i}
                                    allowZeroExpanded
                                    className="accordion2  dxc"
                                    preExpanded={
                                      sessionStorage.getItem(
                                        'unmountedESSclicked'
                                      ) !== 'true'
                                        ? [
                                            sessionStorage.getItem(
                                              'selectedVehicleProduct'
                                            ),
                                          ]
                                        : ['']
                                    }>
                                    <AccordionItem
                                      uuid={blist.an}
                                      onClick={() => {
                                        this.setState({
                                          batteryText: blist.dn,
                                        });
                                      }}>
                                      <AccordionItemHeading
                                        data-intro={`battery_num_${index}`}
                                        className={
                                          sessionStorage.getItem(
                                            'selectedVehicleProduct'
                                          ) === blist.an &&
                                          sessionStorage.getItem(
                                            'unmountedESSclicked'
                                          ) !== 'true'
                                            ? blist.type === 'converter'
                                              ? 'converter-active'
                                              : 'active-site'
                                            : blist.type === 'converter'
                                              ? 'converter'
                                              : ''
                                        }>
                                        <AccordionItemButton>
                                          <span
                                            data-intro={`battery_num_click_${index}`}
                                            className="headingcontainer"
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                'unmountedESSclicked',
                                                'false'
                                              );
                                              blist.type === 'converter'
                                                ? this.props.onVehicleProductListClick(
                                                    item.vn,
                                                    blist.an,
                                                    blist.ip,
                                                    blist.dn,
                                                    blist.bpu,
                                                    blist.type
                                                  )
                                                : this.props.onVehicleListClick(
                                                    item.vn,
                                                    blist.an,
                                                    blist.dn
                                                  );
                                            }}>
                                            <span className="vehicleicon">
                                              {blist.type === 'converter' ? (
                                                <Converter_d />
                                              ) : (
                                                <ProductBattery />
                                              )}
                                            </span>

                                            <span className="vehicletext vehicletextV3">
                                              {this.props.size < 300
                                                ? highlightSearchTerm(
                                                    refactorBatteryText(
                                                      blist.dn
                                                    ),
                                                    searchQuery
                                                  )
                                                : highlightSearchTerm(
                                                    truncateAccText(blist.dn),
                                                    searchQuery
                                                  )}
                                            </span>
                                            <div className="vehiclestatusicon">
                                              {this.state.batteryText !==
                                                blist.dn && blist.rpc > 0 ? (
                                                <span>
                                                  <WarningInfoIcon />
                                                  <span>
                                                    {blist.rpc > 0
                                                      ? '+' + blist.rpc
                                                      : ''}
                                                  </span>
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                              {this.state.batteryText ===
                                                blist.dn && blist.rpc > 0 ? (
                                                <span>
                                                  <WarningRedDotIcon />
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </span>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>

                                      {blist.pdat.length > 0 &&
                                        blist.pdat.map(
                                          (plist, index) =>
                                            this.filterCheck(plist, index) && (
                                              // List the packs
                                              <AccordionItemPanel
                                                data-intro={`string_num_${index}`}
                                                key={index}
                                                onClick={() => {
                                                  sessionStorage.setItem(
                                                    'unmountedESSclicked',
                                                    'false'
                                                  );
                                                  this.props.onVehicleProductPackClick(
                                                    item.vn,
                                                    blist.an,
                                                    blist.ip,
                                                    blist.dn,
                                                    plist.an,
                                                    plist.dn,
                                                    plist.scuID,
                                                    blist.bpu
                                                  );
                                                }}
                                                className={
                                                  sessionStorage.getItem(
                                                    'selectedPack'
                                                  ) === plist.an
                                                    ? 'accordion__panel active-product'
                                                    : 'accordion__panel'
                                                }>
                                                <span className="headingcontainer">
                                                  <span className="productlisticon">
                                                    <PackIcon />
                                                  </span>
                                                  <span
                                                    className="producttext"
                                                    style={
                                                      this.props.size < 300
                                                        ? { maxWidth: '70%' }
                                                        : null
                                                    }>
                                                    {this.props.size < 300
                                                      ? highlightSearchTerm(
                                                          refactorPackText(
                                                            plist.dn
                                                          ),
                                                          searchQuery
                                                        )
                                                      : highlightSearchTerm(
                                                          truncatePackText(
                                                            plist.dn
                                                          ),
                                                          searchQuery
                                                        )}
                                                  </span>
                                                  <span className="productstatusicon">
                                                    {plist.a === 'Available' ? (
                                                      <AvailGreenIcon className="statusicon" />
                                                    ) : plist.a ===
                                                      'Not available' ? (
                                                      <AvailRedIcon className="statusicon" />
                                                    ) : (
                                                      <AvailableUnknownIcon className="statusicon" />
                                                    )}

                                                    {plist.r === 'Reliable' ? (
                                                      <ReliableGreenIcon className="statusicon" />
                                                    ) : plist.r ===
                                                      'Not reliable' ? (
                                                      <ReliableRedIcon className="statusicon" />
                                                    ) : (
                                                      <ReliableUnknownStatusIcon className="statusicon" />
                                                    )}

                                                    {plist.s ===
                                                    'Above normal' ? (
                                                      <StressRedIcon />
                                                    ) : plist.s === 'Normal' ? (
                                                      <StressGreenIcon />
                                                    ) : (
                                                      <StressUnknownStatusIcon />
                                                    )}

                                                    <FavIcon className="hidewithoutspace" />
                                                  </span>
                                                </span>
                                              </AccordionItemPanel>
                                            )
                                        )}
                                    </AccordionItem>
                                  </Accordion>
                                ))
                            )}
                          </AccordionItemPanel>
                        </AccordionItem>
                      )
                  )}
              </Accordion>
            </div>
          ) : (
            <div className="containervehicleproductlist">
              <span className="loading">Loading ...</span>
            </div>
          ))}
        {/* Unmounted ESS starts from here */}
        {sessionStorage.getItem('selectedFleet') !== 'ABB ESS Demo Fleet' && (
          <div className="unmountedesscon">
            <div className="totalvehicles">
              {this.props.unmountedESSListData != null &&
                this.props.unmountedESSListData.length + ' ' + 'unmounted ESS'}
            </div>
            <div className="unmounted-ess containervehicleproductlist">
              {this.props.loadingUnmountedESSList === true ||
              this.props.unmountedESSListData === null ? (
                'Loading ...'
              ) : this.props.unmountedESSListData.length > 0 ? (
                <Accordion
                  id="accordion2"
                  allowZeroExpanded
                  preExpanded={['Unmounted_ESS']}>
                  <AccordionItem uuid="Unmounted_ESS">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="headingcontainer">
                          <span className="vehicleicon">
                            <UnmountedIcon />
                          </span>
                          Unmounted ESS
                        </span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <div className="unmountedlist">
                      {this.props.unmountedESSListData.map((item, index) => (
                        <AccordionItemPanel
                          key={index}
                          onClick={e => {
                            sessionStorage.setItem(
                              'unmountedESSclicked',
                              'true'
                            );
                            this.props.onVehicleProductListClick(
                              item.vn,
                              item.an,
                              item.ip,
                              item.dn
                            );
                          }}
                          className={
                            sessionStorage.getItem('selectedProductIP') ===
                              item.ip &&
                            sessionStorage.getItem('selectedVehicleProduct') ===
                              item.an
                              ? 'accordion__panel active-product'
                              : 'accordion__panel'
                          }>
                          <span className="productlisticon">
                            <ProductBattery />
                          </span>
                          {item.dn}
                        </AccordionItemPanel>
                      ))}
                    </div>
                  </AccordionItem>
                </Accordion>
              ) : this.props.unmountedESSListData.length === 0 ? (
                <Accordion
                  id="accordion2"
                  allowZeroExpanded
                  preExpanded={['Unmounted_ESS']}>
                  <AccordionItem uuid="Unmounted_ESS">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="headingcontainer">
                          <span className="vehicleicon">
                            <UnmountedIcon />
                          </span>
                          Unmounted ESS
                        </span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel
                      className={'accordion__panel unmounted_nodata '}>
                      <div> No unmounted ESS registered.</div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
        <hr />
      </div>
    );
  }
}

export default withRouter(SideMenuV3);
