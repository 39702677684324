import Moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { HomeIcon } from '../../assets/images';
import Breadcrumb from '../../components/Breadcrumb';
import DateRangePicker from '../../components/DateRangePicker';
import { setSidebarWidth } from '../../components/DraggableLayout/actions';
import { getSidebarWidth } from '../../components/DraggableLayout/selectors';
import Loading from '../../components/Loading';
import MinimizedSideMenu from '../../components/MinimizedSideMenu';
import SideMenu from '../../components/SideMenu';
import SideMenuV3 from '../../components/SideMenuV3';
import Draggable from '../../components/SidebarDraggable/Draggable';
import {
  setShowSidebar,
  setSize,
} from '../../components/SidebarDraggable/actions';
import {
  getShowSidebar,
  getSize,
} from '../../components/SidebarDraggable/selectors';
import TitleBar from '../../components/Title/TitleBar';
import TitleBarV3 from '../../components/Title/TitleBarV3';
import VehicleProductsTabs from '../../components/VehicleProductsTab';
import { mainSignalTrendOptionsDemo } from '../../components/VehicleProductsTab/constants';
import {
  checkUserEntitlements,
  userConfig as configUser,
  directlinktrendSignalCheck,
} from '../../userConfig';
import { copyToClipboard } from '../../utils/clipboard';
import { getStartEnd } from '../../utils/date-startend';
import { platformVersion } from '../../utils/excel-utils';
import { getbpu, getscuID } from '../../utils/other';
import { fetchActiveMessages } from '../Broadcast/actions';
import { fetchMonitoredCount } from '../DashBoard/actions';
import { monitoredCountDataSelector } from '../DashBoard/selectors';
import {
  clearEventList,
  clearSaveEventResponce,
  clearUndoEventResponce,
  fetchAllEventList,
  saveEvent,
  setAllEventListData,
  undoEvent,
} from '../Events/actions';
import {
  AlleventListSelector,
  alleventListSelectorConfig,
  eventDataSelector,
  loadingAllEventListSelector,
  loadingSaveEventSelector,
  loadingUndoEventSelector,
  saveEventResponceSelector,
  undoEventResponceSelector,
} from '../Events/selectors';
import {
  fetchDiagnosticList,
  fetchFleetsList,
  fetchModuleList,
  fetchUnmountedESSList,
  fetchVehicleProductList,
  fetchVehiclesList,
  setDate,
  setDiagnosticEventListData,
  setListSelection,
} from '../FleetView/actions';
import {
  diagnosticEventDataSelector,
  diagnosticEventListSelectorConfig,
  diagnosticListSelector,
  fleetsListDataSelector,
  loadingDiagnosticListSelector,
  loadingUnmountedESSListSelector,
  modulesListDataSelector,
  selectedEndDateSelector,
  selectedListSelector,
  selectedStartDateSelector,
  unmountedESSListDataSelector,
  vehicleProductListDataSelector,
  vehiclesListDataSelector,
} from '../FleetView/selectors';
import { setRadioButtonValue } from '../VehicleProductsView/actions';
import {
  RadioValueSelectionData,
  loadingUsgDodHeatmapSelector,
  loadingUsgPowHeatmapSelector,
  loadingUsgSocHeatmapSelector,
  loadingUsgTempHeatmapSelector,
  usgDodHeatmapDataSelector,
  usgPowHeatmapDataSelector,
  usgSocHeatmapDataSelector,
  usgTempHeatmapDataSelector,
} from '../VehicleProductsView/selectors';
import { clearVehicleViewData } from '../VehicleView/actions';
import {
  addOverlayTrendSignal,
  clearChartData,
  clearDetailViewTimetrend,
  clearOverviewata,
  fetchBatteryOverallConditionData,
  fetchBatteryOverviewData,
  fetchBatteryRelData,
  fetchBatterySOCRangeData,
  fetchBatteryStateOfChargeData,
  fetchBatteryStrData,
  fetchBoxPlotData,
  fetchCellVoltageData,
  fetchDetailViewTimetrend,
  fetchHistogramData,
  fetchOperatingHoursHistogramData,
  fetchSOHData,
  fetchSyncGraphData,
  fetchTemperatureHistogramData,
  fetchUsgDodHeatmapData,
  fetchUsgDodHistogramData,
  fetchUsgPowHeatmapData,
  fetchUsgPowHistogramData,
  fetchUsgSocHeatmapData,
  fetchUsgSocHistogramData,
  fetchUsgTempHeatmapData,
  setMainSignal,
  setMainSignal1,
  setMainSignal2,
  setMainSignal3,
  setMainTrendSignal,
  setOverlaySignal,
  setOverlaySignal1,
  setOverlaySignal2,
  setOverlaySignal3,
  setSyncChart,
  setSyncChartData,
  setTimetrendLoading,
} from './actions';
import messages from './messages';
import {
  SOHDataSelector,
  batteryOverallConditionDataSelector,
  batteryOverviewDataSelector,
  batteryRelDataSelector,
  batterySOCRangeDataSelector,
  batteryStateOfChargeDataSelector,
  batteryStrDataSelector,
  boxPlotDataSelector,
  cellVoltageDataSelector,
  histogramDataSelector,
  loadingBatteryOverallConditionSelector,
  loadingBatteryOverviewSelector,
  loadingBatteryRelSelector,
  loadingBatterySOCRangeSelector,
  loadingBatteryStateOfChargeSelector,
  loadingBatteryStrSelector,
  loadingBoxPlotSelector,
  loadingCellVoltageSelector,
  loadingHistogramSelector,
  loadingOperatingHoursHistogramSelector,
  loadingSOHSelector,
  loadingSyncGraphSelector,
  loadingTemperatureHistogramSelector,
  loadingTimetrendSelector,
  loadingUsgDodHistogramSelector,
  loadingUsgPowHistogramSelector,
  loadingUsgSocHistogramSelector,
  operatingHoursHistogramDataSelector,
  selectedMainTrendSelector,
  selectedOverlayTrendSelector,
  syncGraphChartSelector,
  syncGraphDatResSelector,
  syncGraphDatSelector,
  syncGraphDataSelector,
  syncGraphMain1Selector,
  syncGraphMain2Selector,
  syncGraphMain3Selector,
  syncGraphMainSelector,
  syncGraphOverlay1Selector,
  syncGraphOverlay2Selector,
  syncGraphOverlay3Selector,
  syncGraphOverlaySelector,
  temperatureHistogramDataSelector,
  timetrendDataSelector,
  ttAvgCellVSelector,
  ttAvgTemperatureSelector,
  ttBConstChgSelector,
  ttBConstDischgSelector,
  ttBCurrentSelector,
  ttBOverloadCntrSelector,
  ttBPeakChgSelector,
  ttBPeakDischgSelector,
  ttBVoltageSelector,
  ttDiagnosticSelector,
  ttFeedFlowSelector,
  ttOverloadCounterSelector,
  ttPowerSelector,
  ttReliabilitySelector,
  ttReturnFlowSelector,
  ttSOCSelector,
  usgDodHistogramDataSelector,
  usgPowHistogramDataSelector,
  usgSocHistogramDataSelector,
} from './selectors';

Moment.locale('en');

let selectedSite;
let selectedFleet;
let selectedFleetType;
let selectedVehicle;
let selectedVehicleProduct;
let selectedPIp;
let selectedPDn;
let selectedPack;
let selectedPackDname;
let unmountedESSclicked;
let selectedSrtDte = null;
let selectedEdDte = null;
let selectedCDateType = null;
let selectedTb = null;
let selectedOverviewTb = null;
let selectedUsgTab = null;
let selectedUsgRbtn = null;
let urlLCmin;
let urlLCmax;
let urlSCmin;
let urlSCmax;
let urlSYCmin;
let urlSYCmax;
let ms1;
let ms2;
let ms3;
let os1;
let os2;
let os3;
let ttm = null;
let tto = null;
let zsd = null;
let zed = null;
let srch = null;
let ftr = [null, null, null, null];
let snav = null;
let bpu = null;
let scuID = null;

let zoomHistory = [];
let userConfig;
export class PackView extends React.Component {
  constructor(props) {
    super(props);
    sessionStorage.removeItem('searchQuery');
    sessionStorage.removeItem('searchResultList');
    const urlLink = window.location.href;
    const url = new URL(urlLink);
    let formatedStartDte = null;
    let formatedEndDte = null;
    let lockedZoomDuration = null;
    if (urlLink.indexOf('?') > -1) {
      selectedSite = url.searchParams.get('st');
      selectedFleet = url.searchParams.get('ft');
      selectedFleetType = url.searchParams.get('fty');
      selectedVehicle = url.searchParams.get('vc');
      selectedVehicleProduct = url.searchParams.get('vp');

      selectedPIp = url.searchParams.get('pip');
      selectedPDn = url.searchParams.get('pdn');
      selectedPack = url.searchParams.get('pk');
      selectedPackDname = url.searchParams.get('pkdn');
      selectedSrtDte = url.searchParams.get('sdt');
      selectedEdDte = url.searchParams.get('edt');
      selectedCDateType = url.searchParams.get('cdty');
      unmountedESSclicked = url.searchParams.get('unmas');
      selectedTb = url.searchParams.get('tb') || 'Overview-vehicleprod-view';
      selectedOverviewTb = url.searchParams.get('otb') || 'binfo';
      selectedUsgTab = url.searchParams.get('utb') || 'usgtemp';
      selectedUsgRbtn = url.searchParams.get('urbt');
      urlLCmin = url.searchParams.get('lmn');
      urlLCmax = url.searchParams.get('lmx');
      urlSCmin = url.searchParams.get('smn');
      urlSCmax = url.searchParams.get('smx');
      urlSYCmin = url.searchParams.get('symn');
      urlSYCmax = url.searchParams.get('symx');
      ms1 = url.searchParams.get('ms1');
      ms2 = url.searchParams.get('ms2');
      ms3 = url.searchParams.get('ms3');
      os1 = url.searchParams.get('os1');
      os2 = url.searchParams.get('os2');
      os3 = url.searchParams.get('os3');
      ttm = url.searchParams.get('ttm');
      tto = url.searchParams.get('tto');
      tto = tto !== null && tto !== 'null' ? tto.split(',') : null;
      zsd = url.searchParams.get('zsd');
      zed = url.searchParams.get('zed');
      ftr = url.searchParams.get('ftr');
      ftr =
        ftr !== null && ftr !== 'null'
          ? ftr.split(',')
          : [null, null, null, null];
      srch = url.searchParams.get('srch');
      snav = url.searchParams.get('snav');
      bpu =
        url.searchParams.get('bpu') === null
          ? getbpu(selectedVehicleProduct, this.props.vehiclesListData)
          : url.searchParams.get('bpu');
      url.searchParams.set('bpu', bpu);
      scuID =
        url.searchParams.get('scuID') === null
          ? getscuID(selectedPack, this.props.vehiclesListData)
          : url.searchParams.get('scuID');
      url.searchParams.set('scuID', scuID);

      sessionStorage.setItem('selectedSite', selectedSite);
      sessionStorage.setItem('selectedFleet', selectedFleet);
      sessionStorage.setItem('selectedFleetType', selectedFleetType);
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);

      sessionStorage.setItem('selectedProductIP', selectedPIp);
      sessionStorage.setItem('selectedProductDname', selectedPDn);
      sessionStorage.setItem('selectedVehicleProductBPU', bpu);
      sessionStorage.setItem('selectedPack', selectedPack);
      sessionStorage.setItem('selectedPackDname', selectedPackDname);
      sessionStorage.setItem(
        'unmountedESSclicked',
        sessionStorage.getItem('unmountedESSclicked') === 'true'
          ? unmountedESSclicked
          : 'false'
      );
      localStorage.setItem('urlLCmin', urlLCmin);
      localStorage.setItem('urlLCmax', urlLCmax);
      localStorage.setItem('urlSCmin', urlSCmin);
      localStorage.setItem('urlSCmax', urlSCmax);
      localStorage.setItem('urlSYCmin', urlSYCmin);
      localStorage.setItem('urlSYCmax', urlSYCmax);
      this.props.setDate(selectedSrtDte, selectedEdDte);
      this.setState({ selectedTab: selectedTb });
      this.setState({ selectedOverviewTab: selectedOverviewTb });
      this.props.setMainSignal1(ms1 !== null ? ms1 : 'Avg CellV');
      this.props.setMainSignal2(ms2 !== null ? ms2 : 'State of Charge');
      this.props.setMainSignal3(ms3 !== null ? ms3 : 'Battery Voltage');
      this.props.setMainSignal(ms1 !== null ? ms1 : 'Avg CellV');
      this.props.setOverlaySignal1(os1 !== null ? os1 : 'Avg Temperature');
      this.props.setOverlaySignal2(os2 !== null ? os2 : 'Power');
      this.props.setOverlaySignal3(os3 !== null ? os3 : 'Battery Current');
      this.props.setOverlaySignal(os1 !== null ? os1 : 'Avg Temperature');
      this.props.setMainTrendSignal(ttm !== null ? ttm : 'State of Charge');
      if (
        sessionStorage.getItem('directlink') === null ||
        sessionStorage.getItem('directlink') === 'null'
      ) {
        this.props.addOverlayTrendSignal(tto);
      } else {
        const tempVal =
          tto !== null
            ? ttm === 'Power' && tto.includes('Power')
              ? null
              : tto
            : ttm === 'Power'
              ? null
              : ['Power'];
        if (tempVal !== 'null' || tempVal !== null) {
          this.props.addOverlayTrendSignal(tempVal);
          url.searchParams.set('tto', tempVal);
          this.props.history.push(url.pathname + url.search);
        }

        sessionStorage.setItem('directlink', null);
      }

      if (zsd !== null && zed !== null) {
        formatedStartDte = Moment(new Date(zsd.replace(/%3A/g, ':'))).format(
          'DD.MM.YYYY, HH:mm'
        );
        formatedEndDte = Moment(new Date(zed.replace(/%3A/g, ':'))).format(
          'DD.MM.YYYY, HH:mm'
        );
        lockedZoomDuration = this.getDifferenceText(
          new Date(zsd.replace(/%3A/g, ':')),
          new Date(zed.replace(/%3A/g, ':'))
        );
      } else {
        formatedStartDte = Moment(
          new Date(selectedSrtDte.replace(/%3A/g, ':'))
        ).format('DD.MM.YYYY, HH:mm');
        formatedEndDte = Moment(
          new Date(selectedEdDte.replace(/%3A/g, ':'))
        ).format('DD.MM.YYYY, HH:mm');
        lockedZoomDuration = this.getDifferenceText(
          new Date(selectedSrtDte.replace('/:/g', '%3A')),
          new Date(selectedEdDte.replace('/:/g', '%3A'))
        );
      }

      this.state = {
        startZoomInterval: formatedStartDte,
        endZoomInterval: formatedEndDte,
        lockedZoomDuration,
      };
      // }
      this.state = {
        isRedirectUsageHeatmap: false,
        usgsocShowHeatmap: false,
        usgtempShowHeatmap: false,
        usgpowShowHeatmap: false,
        usgdodShowHeatmap: false,
        searchResultList:
          sessionStorage.getItem('searchResultList') !== null
            ? JSON.parse(sessionStorage.getItem('searchResultList'))
            : [],
        searchQuery: srch !== null ? srch : '',
        comnDateType:
          selectedCDateType !== null ? selectedCDateType : 'Monthly',
        isMonthlyactive: false,
        isWeeklyactive: false,
        isDailyactive: true,
        isVehicleClicked: false,
        isVeicleProductClicked: false,
        // selectedUsageTab: selectedUsgTab !== null ? selectedUsgTab : "usgtemp",
        selectedUsageTab:
          selectedUsgTab !== null
            ? selectedUsgTab
            : this.checkUsageTabAvailabilty(),
        selectedUsageRbtn:
          selectedUsgRbtn !== null ? selectedUsgRbtn : 'Dbudget',
        selectedTab:
          selectedTb !== null ? selectedTb : 'Overview-vehicleprod-view',
        selectedOverviewTab:
          selectedOverviewTb !== null
            ? selectedOverviewTb
            : ftr[0] === 'Availability'
              ? 'avail'
              : ftr[0] === 'Reliability'
                ? 'reli'
                : ftr[0] === 'Utilization'
                  ? 'util'
                  : 'binfo',
        filterItems: {
          availability: ftr[0] !== null ? ftr[0] : '',
          reliability: ftr[1] !== null ? ftr[1] : '',
          utilization: ftr[2] !== null ? ftr[2] : '',
        },

        synchedChartData: null,
        startZoomInterval: formatedStartDte,
        endZoomInterval: formatedEndDte,
        lockedZoomDuration,
        isButtonDisabled: false,
        eventFormValues: {
          pecIP: '',
          producttype: '',
          exchangetype: 'ESS',
          oessan: '',
          nessan: '',
          oesssn: '',
          nesssn: '',
          opecan: '',
          npecan: '',
          opecsn: '',
          npecsn: '',
          comments: '',
          prepecan: '',
          prepecsn: '',
        },
        expandSide: !(snav !== null && snav === 'false'),
        isCopied: false,
      };
      const currentSamplingType = this.getSamplingType(
        Moment.utc(formatedStartDte, 'DD.MM.YYYY HH:mm:ss').toISOString(),
        Moment.utc(formatedEndDte, 'DD.MM.YYYY HH:mm:ss').toISOString()
      );
      sessionStorage.setItem('samplingType', currentSamplingType);
      if (
        sessionStorage.getItem('selectedFleet') === null ||
        sessionStorage.getItem('selectedVehicle') === 'null' ||
        sessionStorage.getItem('selectedSite') === null
      )
        this.props.history.push('/FleetView');

      userConfig = configUser();
    }
    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
  }

  updateStateFromUrl() {
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const ftrParam = urlSearchParams.get('ftr');
    if (ftrParam) {
      const ftrValues = ftrParam.split(',');
      this.setState({
        filterItems: {
          availability: ftrValues[0] !== '' ? ftrValues[0] : '',
          reliability: ftrValues[1] !== '' ? ftrValues[1] : '',
          utilization: ftrValues[2] !== '' ? ftrValues[2] : '',
        },
      });
    } else {
      this.setState({
        filterItems: {
          availability: ftr[0] !== null ? ftr[0] : '',
          reliability: ftr[1] !== null ? ftr[1] : '',
          utilization: ftr[2] !== null ? ftr[2] : '',
        },
      });
    }
  }

  handleSwitchChange = tab => {
    const stateKey = `${tab}ShowHeatmap`;
    this.setState(prevState => ({
      [stateKey]: !prevState[stateKey],
    }));
  };

  async componentDidMount() {
    await this.props.setAllEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    if (this.state.comnDateType === 'Monthly') {
      const oStartDate = Moment().subtract(30, 'days').startOf('day');
      const oEndDate = Moment().subtract(1, 'days').endOf('day');
      await this.props.setDate(oStartDate, oEndDate);

      const url = new URL(window.location.href);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set(
          'sdt',
          Moment(oStartDate).format('YYYY-MM-DDTHH:mm:ss')
        );
        url.searchParams.set(
          'edt',
          Moment(oEndDate).format('YYYY-MM-DDTHH:mm:ss')
        );
        this.props.history.push(url.pathname + url.search);
      }
    }
    this.props.monitoredCountData === null && this.props.getMonitoredCount();

    this.props.fleetsListData === null &&
      this.props.getFleetsList('All', 'All', 'All', true, true, true);
    this.props.vehiclesListData === null &&
      this.props.getVehiclesList(
        sessionStorage.getItem('selectedSite'),
        sessionStorage.getItem('selectedFleet')
      );

    this.props.unmountedESSListData === null &&
      this.props.fetchUnmountedESSList(
        sessionStorage.getItem('selectedSite'),
        sessionStorage.getItem('selectedFleet')
      );
    /* this.props.vehicleProductListData === null &&
      this.props.getVehicleProductList(
        sessionStorage.getItem("selectedSite"),
        sessionStorage.getItem("selectedFleet"),
        sessionStorage.getItem("selectedVehicle"),
        true,
        true,
        true
      ); */
    // this.props.modulesListData === null &&
    //   this.props.getModuleList(
    //     sessionStorage.getItem("selectedSite"),
    //     sessionStorage.getItem("selectedFleet"),
    //     sessionStorage.getItem("selectedVehicle"),
    //     sessionStorage.getItem("selectedVehicleProduct"),
    //     true,
    //     true,
    //     true
    //   );
    this.props.getBatteryOverallConditionData(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      selectedVehicleProduct
    );

    const urlLink = window.location.href;
    const url = new URL(urlLink);
    if (urlLink.indexOf('?') > -1) {
      zoomHistory = [];
      zoomHistory.push([
        url.searchParams.get('sdt'),
        url.searchParams.get('edt'),
      ]);
      if (zsd !== null && zed !== null) {
        zoomHistory.push([
          url.searchParams.get('zsd'),
          url.searchParams.get('zed'),
        ]);
      }
    } else {
      zoomHistory = [];
      zoomHistory.push([
        this.props.selectedStartDate,
        this.props.selectedEndDate,
      ]);
    }

    if (
      zsd !== null &&
      zed !== null &&
      selectedTb === 'Timetrend-vehicleprod-view'
    ) {
      sessionStorage.setItem('zoomIntervalStartDate', zsd);
      sessionStorage.setItem('zoomIntervalEndDate', zed);
      sessionStorage.setItem('isTrendZoomed', true);

      this.onVehicleProductsViewTabClick(
        selectedTb !== null ? selectedTb : this.state.selectedTab,
        zsd,
        zed
      );
    } else {
      sessionStorage.setItem('zoomIntervalStartDate', null);
      sessionStorage.setItem('zoomIntervalEndDate', null);
      sessionStorage.setItem('isTrendZoomed', null);

      this.onVehicleProductsViewTabClick(
        selectedTb !== null ? selectedTb : this.state.selectedTab,
        selectedSrtDte !== null ? selectedSrtDte : this.state.selectedStartDate,
        selectedEdDte !== null ? selectedEdDte : this.state.selectedEndDate
      );
    }
    if (
      sessionStorage.getItem('Sidemenuscroll') !== null &&
      document.getElementById('scrollvplist') != null
    ) {
      document.getElementById('scrollvplist').scrollTop =
        sessionStorage.getItem('Sidemenuscroll');
    }
    this.props.fetchMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(window.location.search);
    const prevSearchParams = new URLSearchParams(prevProps.location.search);

    if (this.props.eventData !== null) {
      if (
        url.searchParams.get('tb') === 'Event-list-vehicleprod-view' &&
        (prevProps.eventData.pageIndex !== this.props.eventData.pageIndex ||
          prevProps.eventData.pageSize !== this.props.eventData.pageSize ||
          prevProps.eventData.uniqueValue !==
            this.props.eventData.uniqueValue ||
          prevProps.eventData.sortValueKey !==
            this.props.eventData.sortValueKey ||
          prevProps.eventData.sortValue !== this.props.eventData.sortValue)
      ) {
        this.props.fetchAllEventList(
          url.searchParams.get('ft'),
          url.searchParams.get('vc'),
          url.searchParams.get('vp'),
          url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
          new URL(window.location.href).searchParams.get('efsd') !== null
            ? new URL(window.location.href).searchParams.get('efsd')
            : Moment(new Date(Moment().subtract(1, 'days').startOf('day')))
                .format('YYYY-MM-DDTHH:mm:ss')
                .replace(/:/g, '%3A'),
          new URL(window.location.href).searchParams.get('efed') !== null
            ? new URL(window.location.href).searchParams.get('efed')
            : Moment(new Date(Moment().subtract(1, 'days').endOf('day')))
                .format('YYYY-MM-DDTHH:mm:ss')
                .replace(/:/g, '%3A'),
          new URL(window.location.href).searchParams.get('efdt') !== null
            ? new URL(window.location.href).searchParams.get('efdt')
            : 'Any time',
          this.props.eventData.uniqueKey,
          this.props.eventData.uniqueValue,
          this.props.eventData.pageSize,
          this.props.eventData.pageIndex,
          !this.props.eventData.sortValue && this.props.eventData.sortValueKey
            ? 1
            : 0,
          this.props.eventData.sortValueKey
            ? this.props.eventData.sortValueKey
            : 'dt'
        );
      }
    }
    if (prevState.selectedTab !== searchParams.get('tb')) {
      this.setState({ selectedTab: searchParams.get('tb') });
    }
    if (prevSearchParams.get('otb') !== searchParams.get('otb')) {
      this.setState({
        selectedOverviewTab: searchParams.get('otb') || selectedOverviewTb,
      });
    }
    if (prevSearchParams.get('ftr') !== searchParams.get('ftr')) {
      this.updateStateFromUrl();
    }
    if (prevSearchParams.get('tb') !== searchParams.get('tb')) {
      if (this.state.isRedirectUsageHeatmap !== true)
        this.onVehicleProductsViewTabClick(searchParams.get('tb'));
    }
    if (prevSearchParams.get('utb') !== searchParams.get('utb')) {
      this.onUsageTabsClick(searchParams.get('utb') || selectedUsgTab);
    }
    if (prevSearchParams.get('pk') !== searchParams.get('pk')) {
      this.onVehicleProductPackClick(
        searchParams.get('vc'),
        searchParams.get('vp'),
        searchParams.get('pip'),
        searchParams.get('pdn'),
        searchParams.get('pk'),
        searchParams.get('pkdn')
      );
    }
    if (prevSearchParams.get('tto') !== searchParams.get('tto')) {
      const values = searchParams.get('tto');
      let nValues;
      if (values) {
        nValues = values.split(',');
      } else {
        nValues = values;
      }
      this.clickTrendOverlaySignalHandler(nValues);
    }
    if (prevSearchParams.get('ttm') !== searchParams.get('ttm')) {
      this.clickTrendMainSignalRedirection(
        searchParams.get('ttm') !== null
          ? searchParams.get('ttm')
          : 'State of Charge'
      );
    }
    if (this.props.diagnosticEventData !== null) {
      if (
        url.searchParams.get('tb') === 'diagnostic-vehicleprod-view' &&
        (prevProps.diagnosticEventData.pageIndex !==
          this.props.diagnosticEventData.pageIndex ||
          prevProps.diagnosticEventData.uniqueValue !==
            this.props.diagnosticEventData.uniqueValue ||
          prevProps.diagnosticEventData.pageSize !==
            this.props.diagnosticEventData.pageSize ||
          prevSearchParams.get('tb') !== searchParams.get('tb') ||
          prevSearchParams.get('pkdn') !== searchParams.get('pkdn'))
      ) {
        this.props.fetchDiagnosticList(
          url.searchParams.get('ft'),
          url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
          url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
          url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
          url.searchParams.get('sdt'),
          url.searchParams.get('edt'),
          new URL(window.location.href).searchParams.get('dfdt') !== null
            ? new URL(window.location.href).searchParams.get('dfdt')
            : 'Any time',
          this.props.diagnosticEventData.uniqueKey,
          this.props.diagnosticEventData.uniqueValue,
          url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
          this.props.diagnosticEventData.pageSize
            ? this.props.diagnosticEventData.pageSize
            : 20,
          this.props.diagnosticEventData.pageIndex
            ? this.props.diagnosticEventData.pageIndex
            : 0,
          !this.props.diagnosticEventData.sortValue &&
            this.props.diagnosticEventData.sortValueKey
            ? 1
            : 0,
          this.props.diagnosticEventData.sortValueKey
            ? this.props.diagnosticEventData.sortValueKey
            : ''
        );
      }
    }
  }

  /* componentDidUpdate() {
    //  scroll to active vehicle
    var element = document.querySelector(".active-site");
    if (typeof element != "undefined" && element != null) {
      element.scrollIntoView(false);
    }
  } */
  componentWillUnmount() {
    this.props.setWidth(370);
    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    this.props.setAllEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
  }

  UNSAFE_componentWillReceiveProps(newProps, prevState) {
    if (this.props.vehicleProductListData !== newProps.vehicleProductListData) {
      if (sessionStorage.getItem('selectedVehicleProduct') !== 'null') {
        // spacer
      } else {
        if (newProps.vehicleProductListData !== null)
          if (newProps.vehicleProductListData.length !== 0) {
            sessionStorage.setItem(
              'selectedVehicleProduct',
              newProps.vehicleProductListData[0].bt
            );
          }
      }
    }
    if (
      this.props.batteryOverallConditionData !==
      newProps.batteryOverallConditionData
    ) {
      if (
        newProps.batteryOverallConditionData !== null &&
        newProps.batteryOverallConditionData !== undefined
      ) {
        sessionStorage.setItem(
          'nessan',
          newProps.batteryOverallConditionData[0].bpa
        );
        this.setState({
          eventFormValues: {
            ...this.state.eventFormValues,
            pecIP: newProps.batteryOverallConditionData[0].ip,
            producttype: newProps.batteryOverallConditionData[0].typ,
            oessan: newProps.batteryOverallConditionData[0].bpa,
            nessan: newProps.batteryOverallConditionData[0].bpa,
            oesssn: newProps.batteryOverallConditionData[0].bps,
            opecan: newProps.batteryOverallConditionData[0].pa,
            opecsn: newProps.batteryOverallConditionData[0].ps,
          },
        });
      }
    }
    // if (this.props.eventList !== newProps.eventList) {
    //   if (newProps.eventList !== null) {

    //     var r = newProps.eventList[0].msg.split(" ").filter(function (n) {
    //       if (/3BHE/.test(n)) return n;
    //     });

    //   }
    // }

    if (this.props.saveEventResponce !== newProps.saveEventResponce) {
      if (newProps.saveEventResponce !== null) {
        if (newProps.saveEventResponce[0].sts === true) {
          setTimeout(() => {
            this.props.history.push(
              '/FleetView?st=' +
                sessionStorage.getItem('selectedSite') +
                '&ft=' +
                sessionStorage.getItem('selectedFleet') +
                '&sdt=' +
                this.props.selectedStartDate +
                '&edt=' +
                this.props.selectedEndDate
            );
          }, 3000);
        }
      }
    }
    if (this.props.undoEventResponce !== newProps.undoEventResponce) {
      if (newProps.undoEventResponce !== null) {
        if (newProps.undoEventResponce[0].sts === true) {
          setTimeout(() => {
            this.props.clearUndoEventResponce();
            this.props.history.push(
              '/FleetView?st=' +
                sessionStorage.getItem('selectedSite') +
                '&ft=' +
                sessionStorage.getItem('selectedFleet') +
                '&sdt=' +
                this.props.selectedStartDate +
                '&edt=' +
                this.props.selectedEndDate
            );
          }, 3000);
        }
      }
    }

    this.props.vehiclesListData !== null &&
      newProps.vehiclesListData !== null &&
      this.state.searchQuery !== '' &&
      this.handleSearchInputChange(this.state.searchQuery);
  }

  checkUsageTabAvailabilty = () => {
    let returnValue = null;
    if (checkUserEntitlements('ESS:Product-Usage-Temperature')) {
      returnValue = 'usgtemp';
    } else if (checkUserEntitlements('ESS:Product-Usage-OH')) {
      returnValue = 'usgoh';
    } else if (checkUserEntitlements('ESS:Product-Usage-Soc')) {
      returnValue = 'usgsoc';
    } else if (checkUserEntitlements('ESS:Product-Usage-Power')) {
      returnValue = 'usgpow';
    } else if (checkUserEntitlements('ESS:Product-Usage-DOD')) {
      returnValue = 'usgdod';
    }
    return returnValue;
  };

  onFleetListClick = (selectedFleet, selectedSite, selectedFleetType) => {
    // this.props.setFleetName(selectedFleet);
    sessionStorage.setItem('selectedFleet', selectedFleet);
    sessionStorage.setItem('selectedFleetType', selectedFleetType);
    sessionStorage.setItem('selectedSite', selectedSite);
    sessionStorage.setItem('selectedVehicle', null);
    sessionStorage.setItem('selectedVehicleProduct', null);
    sessionStorage.setItem('selectedProductIP', null);
    sessionStorage.setItem('selectedPack', null);
    sessionStorage.setItem('selectedPackDname', null);
    /* if (this.state.filterItems.selectedSearchKey === "Vehicles") {
      if (
        this.state.filterItems.isGoodSlected == false &&
        this.state.filterItems.isPoorSelected == false &&
        this.state.filterItems.isUnknownSelected == false
      ) {
        this.props.getVehiclesList(selectedFleet, true, true, true);
      } else {
        this.props.getVehiclesList(
          selectedFleet,
          this.state.filterItems.isGoodSlected,
          this.state.filterItems.isPoorSelected,
          this.state.filterItems.isUnknownSelected
        );
      }
    } else {
      this.props.getVehiclesList(selectedFleet, true, true, true);
    }
    this.props.getOverviewData(selectedFleet);
    this.props.getBatteryOverallConditionData(
      "All",
      "All",
      selectedFleet,
      true,
      true,
      true
    ); */
    this.props.setSize(340);

    this.props.history.push(
      '/FleetView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet') +
        '&fty=' +
        sessionStorage.getItem('selectedFleetType') +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate
    );
    // alert("update fleet data");
  };

  onVehicleListClick = async (
    selectedVehicle,
    selected_Product = null,
    selectedP_Dname = null
  ) => {
    document.getElementById('scrollvplist') !== null &&
      sessionStorage.setItem(
        'Sidemenuscroll',
        document.getElementById('scrollvplist').scrollTop
      );
    if (selected_Product === null) {
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem('selectedVehicleProduct', null);
      sessionStorage.setItem('selectedProductDname', null);
      sessionStorage.setItem('selectedProductIP', null);
      sessionStorage.setItem('selectedPack', null);
      sessionStorage.setItem('selectedPackDname', null);
      await this.props.clearVehicleViewData();

      this.props.history.push(
        '/FleetView/VehicleView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&sdt=' +
          this.props.selectedStartDate +
          '&edt=' +
          this.props.selectedEndDate +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&vtb=Overview-vehicle-view'
      );
    } else {
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem('selectedVehicleProduct', selected_Product);
      sessionStorage.setItem('selectedProductDname', selectedP_Dname);
      sessionStorage.setItem('selectedProductIP', null);
      sessionStorage.setItem('selectedPack', null);
      sessionStorage.setItem('selectedPackDname', null);
      await this.props.clearVehicleViewData();

      this.props.history.push(
        '/FleetView/VehicleView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&vp=' +
          selected_Product +
          '&pdn=' +
          selectedP_Dname +
          '&sdt=' +
          this.props.selectedStartDate +
          '&edt=' +
          this.props.selectedEndDate +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&vtb=Overview-vehicle-view'
      );
    }
  };

  onVehicleProductListClick = async (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedVehicleProductBPU,
    selectedType
  ) => {
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    sessionStorage.setItem(
      'selectedVehicleProductBPU',
      selectedVehicleProductBPU
    );
    sessionStorage.setItem('selectedPack', null);
    sessionStorage.setItem('selectedPackDname', null);
    // this.props.getModuleList(
    //   sessionStorage.getItem("selectedSite"),
    //   sessionStorage.getItem("selectedFleet"),
    //   sessionStorage.getItem("selectedVehicle"),
    //   selectedVehicleProduct,
    //   true,
    //   true,
    //   true
    // );
    this.props.getBatteryOverallConditionData(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      selectedVehicleProduct
    );
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();
    this.onVehicleProductsViewTabClick(this.state.selectedTab);
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('vc', selectedVehicle);
      url.searchParams.set('vp', selectedVehicleProduct);
      url.searchParams.set('pip', selectedProductIP);
      url.searchParams.set('pdn', selectedProductDname);
      url.searchParams.set(
        'unmas',
        sessionStorage.getItem('unmountedESSclicked')
      );
      url.searchParams.set('bpu', selectedVehicleProductBPU);
      url.searchParams.delete('pkdn');
      url.searchParams.delete('brp');
      url.searchParams.delete('bsp');
      url.searchParams.delete('lmn');
      url.searchParams.delete('lmx');
      url.searchParams.delete('smn');
      url.searchParams.delete('smx');
      url.searchParams.delete('prbrmn');
      url.searchParams.delete('prbrmx');
      url.searchParams.delete('psbrmn');
      url.searchParams.delete('psbrmx');
      const routeTo =
        selectedType === 'converter'
          ? '/FleetView/VehicleView/TractionConverterView'
          : '/FleetView/VehicleView/VehicleProductsView';
      this.props.history.push(routeTo + url.search);
    }

    // this.props.history.push("/FleetView/VehicleView/VehicleProductsView");
  };

  onVehicleProductPackClickRedirectionHandler = async (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedPack,
    selectedPackDname,
    selectedScuID,
    selectedBpu
  ) => {
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('vc', selectedVehicle);
      url.searchParams.set('vp', selectedVehicleProduct);
      url.searchParams.set('pip', selectedProductIP);
      url.searchParams.set('pdn', selectedProductDname);
      url.searchParams.set(
        'unmas',
        sessionStorage.getItem('unmountedESSclicked')
      );
      url.searchParams.set('pk', selectedPack);
      url.searchParams.set('pkdn', selectedPackDname);
      url.searchParams.set('scuID', selectedScuID);
      url.searchParams.set('bpu', selectedBpu);
      url.searchParams.delete('bsp');
      url.searchParams.delete('brp');

      this.props.history.push(url.pathname + url.search);
    }
  };

  onVehicleProductPackClick = async (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedPack,
    selectedPackDname
  ) => {
    document.getElementById('scrollvplist') !== null &&
      sessionStorage.setItem(
        'Sidemenuscroll',
        document.getElementById('scrollvplist').scrollTop
      );
    sessionStorage.setItem('directlink', 'false');
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    sessionStorage.setItem('selectedPack', selectedPack);
    sessionStorage.setItem('selectedPackDname', selectedPackDname);
    this.props.getBatteryOverallConditionData(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      selectedVehicleProduct
    );
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();

    this.onVehicleProductsViewTabClick(this.state.selectedTab);
  };

  onModuleListClick = selectedModule => {
    // localStorage.setItem("selectedModule", selectedModule);
  };

  updateVehicleProductsData = () => {
    alert('update vehicle products data');
  };

  onFleetViewTabClick = tab => {
    if (tab === 'Overview') {
      this.props.getOverviewData();
    } else if (tab === 'Energy-throughput') {
      this.props.getBoxPlotData();
    } else if (tab === 'SoC-range-usage') {
      this.props.getHistogramData();
    }
  };

  onDatePickerButtonClick = async clickedButton => {
    this.setState({ comnDateType: clickedButton });
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('cdty', clickedButton);
      this.props.history.push(url.pathname + url.search);
    }

    let oStartDate, oEndDate;
    if (clickedButton === 'Monthly') {
      oStartDate = Moment().subtract(30, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    } else if (clickedButton === 'Weekly') {
      oStartDate = Moment().subtract(7, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    } else if (clickedButton === 'Daily') {
      oStartDate = Moment().subtract(1, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    } else if (clickedButton === 'Year') {
      oStartDate = Moment().subtract(365, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    } else {
      oStartDate = Moment().subtract(30, 'days').startOf('day');
      oEndDate = Moment().subtract(1, 'days').endOf('day');
    }
    this.onApplyClick(oStartDate, oEndDate);

    // let from_date = "";
    // let to_date = "";
    // if (clickedButton === "Monthly") {
    //   from_date = moment().startOf("month");
    //   to_date = moment().endOf("month");
    //   this.setState((prevState) => ({
    //     isMonthlyactive: !prevState.isMonthlyactive,
    //     isWeeklyactive: false,
    //     isDailyactive: false,
    //   }));
    // } else if (clickedButton === "Weekly") {
    //   from_date = moment().startOf("isoWeek");
    //   to_date = moment().endOf("isoWeek");
    //   this.setState((prevState) => ({
    //     isWeeklyactive: !prevState.isWeeklyactive,
    //     isMonthlyactive: false,
    //     isDailyactive: false,
    //   }));
    // } else if (clickedButton === "Daily") {
    //   from_date = moment().startOf("day");
    //   to_date = moment().endOf("day");
    //   this.setState((prevState) => ({
    //     isDailyactive: !prevState.isDailyactive,
    //     isMonthlyactive: false,
    //     isWeeklyactive: false,
    //   }));
    // }
  };

  onLeftRightBtnClick = async (btn, vals) => {
    let oStartDate;
    let oEndDate;
    const clickedButton = this.state.comnDateType;
    const increment = vals;
    const val = vals - 1;

    if (clickedButton === 'Monthly') {
      const start = 30 * increment;
      const end = 1 + 30 * val;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    } else if (clickedButton === 'Weekly') {
      const start = 7 * increment;
      const end = 1 + 7 * val;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    } else if (clickedButton === 'Daily') {
      const start = 1 * increment;
      const end = 1 + 1 * val;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    } else if (clickedButton === 'Year') {
      const start = 365 * increment;
      const end = 1 + 365 * val;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    } else {
      const start = 30;
      const end = 1;
      oStartDate = Moment().subtract(start, 'days').startOf('day');
      oEndDate = Moment().subtract(end, 'days').endOf('day');
    }
    this.onApplyClick(oStartDate, oEndDate);
  };

  updateFilteredData = () => {
    if (this.state.filterItems.selectedSearchKey === 'Fleets') {
      // this.props.setFleetName(null);
      sessionStorage.setItem('selectedFleet', 'null');
      sessionStorage.setItem('selectedVehicle', 'null');
      sessionStorage.setItem('selectedVehicleProduct', 'null');
      sessionStorage.setItem('selectedProductIP', 'null');
      /* if (
        this.state.filterItems.isGoodSlected == false &&
        this.state.filterItems.isPoorSelected == false &&
        this.state.filterItems.isUnknownSelected == false
      ) {
        this.props.getFleetsList("All", "All", "All", true, true, true);
      } else {
        this.props.getFleetsList(
          "All",
          "All",
          "All",
          this.state.filterItems.isGoodSlected,
          this.state.filterItems.isPoorSelected,
          this.state.filterItems.isUnknownSelected
        );
      } */
      // this.props.getVehiclesList(null, true, true, true);
      this.props.history.push('/FleetView');
    }
    if (this.state.filterItems.selectedSearchKey === 'Vehicles') {
      sessionStorage.setItem('selectedVehicle', 'null');
      sessionStorage.setItem('selectedVehicleProduct', 'null');
      sessionStorage.setItem('selectedProductIP', 'null');
      /* if (
        this.state.filterItems.isGoodSlected == false &&
        this.state.filterItems.isPoorSelected == false &&
        this.state.filterItems.isUnknownSelected == false
      ) {
        this.props.getVehiclesList(
          sessionStorage.getItem("selectedFleet"),
          true,
          true,
          true
        );
      } else {
        this.props.getVehiclesList(
          sessionStorage.getItem("selectedFleet"),
          this.state.filterItems.isGoodSlected,
          this.state.filterItems.isPoorSelected,
          this.state.filterItems.isUnknownSelected
        );
      } */
      this.props.history.push('/FleetView/VehicleView');
    }
  };

  sideMenuFilter = (key, value, isAutoClick = false) => {
    if (key === 'reset') {
      this.setState(
        prevState => ({
          filterItems: {
            ...prevState.filterItems,
            availability: '',
            reliability: '',
            utilization: '',
            // isAutoClick: false,
          },
        }),
        () => {
          this.updateFilteredData();
          setTimeout(() => {
            this.setState(prevState => ({
              filterItems: {
                ...prevState.filterItems,
                // isAutoClick: false,
              },
            }));
          }, 0);
          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            const temp = [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ];
            url.searchParams.set('ftr', temp);
            this.props.history.push(url.pathname + url.search);
          }
        }
      );
    } else {
      this.setState(
        prevState => ({
          filterItems: {
            ...prevState.filterItems,
            [key]: value,
            // isAutoClick: isAutoClick,
          },
        }),
        () => {
          this.updateFilteredData();
          setTimeout(() => {
            this.setState(prevState => ({
              filterItems: {
                ...prevState.filterItems,
                // isAutoClick: false,
              },
            }));
          }, 0);
          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            const temp = [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ];
            url.searchParams.set('ftr', temp);
            this.props.history.push(url.pathname + url.search);
          }
        }
      );
    }
  };

  handleFilterDropdownChange = value => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          selectedSearchKey: value,
          isGoodSlected: false,
          isPoorSelected: false,
          isUnknownSelected: false,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          url.searchParams.set(
            'otb',
            value === 'Availability'
              ? 'avail'
              : value === 'Reliability'
                ? 'reli'
                : value === 'Utilization'
                  ? 'util'
                  : 'binfo'
          );
          this.setState({
            selectedOverviewTab:
              value === 'Availability'
                ? 'avail'
                : value === 'Reliability'
                  ? 'reli'
                  : value === 'Utilization'
                    ? 'util'
                    : 'binfo',
          });
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onGoodFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isGoodSlected: !this.state.filterItems.isGoodSlected,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onPoorFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isPoorSelected: !this.state.filterItems.isPoorSelected,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onUnknownFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isUnknownSelected: !this.state.filterItems.isUnknownSelected,
        },
      }),
      () => {
        this.updateFilteredData();
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onFavoriteFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isFavoriteSelected: !this.state.filterItems.isFavoriteSelected,
        },
      }),
      () => {
        // this.filterFleetData();
      }
    );
  };

  onProductOverviewTabClick = tab => {
    this.setState({ selectedOverviewTab: tab });

    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('otb', tab);
      this.props.history.push(url.pathname + url.search);
    }
  };

  onUsageTabsClickRedirection = (tab, stDte = null, edDte = null) => {
    if (window.location.href.indexOf('?') > -1) {
      const url = new URL(window.location.href);
      sessionStorage.setItem('activeTab', tab);
      url.searchParams.set('utb', tab);
      this.props.history.push(url.pathname + url.search);
    }
  };

  onUsageTabsClick = async (tab, stDte = null, edDte = null) => {
    const srtDte = stDte !== null ? stDte : this.props.selectedStartDate;
    const endDte = edDte !== null ? edDte : this.props.selectedEndDate;
    this.setState({ selectedUsageTab: tab });

    if (tab === 'usgoh') {
      this.props.operatingHoursHistogramData === null &&
        this.props.getOperatingHoursHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.temperatureHistogramData === null &&
        this.props.getTemperatureHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.usgSocHistogramData === null &&
        this.props.fetchUsgSocHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );

      this.props.usgPowHistogramData === null &&
        this.props.fetchUsgPowHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );

      this.props.usgDodHistogramData === null &&
        this.props.fetchUsgDodHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
    } else if (tab === 'usgtemp') {
      this.props.temperatureHistogramData === null &&
        this.props.getTemperatureHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.fetchUsgTempHeatmapData(
        sessionStorage.getItem('selectedVehicle'),
        sessionStorage.getItem('selectedVehicleProduct'),
        srtDte,
        endDte,
        this.state.comnDateType
      );
      this.props.usgSocHistogramData === null &&
        this.props.fetchUsgSocHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );

      this.props.usgPowHistogramData === null &&
        this.props.fetchUsgPowHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.usgDodHistogramData === null &&
        this.props.fetchUsgDodHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
    } else if (tab === 'usgsoc') {
      this.props.usgSocHistogramData === null &&
        this.props.fetchUsgSocHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.fetchUsgSocHeatmapData(
        sessionStorage.getItem('selectedVehicle'),
        sessionStorage.getItem('selectedVehicleProduct'),
        srtDte,
        endDte,
        this.state.comnDateType
      );
      this.props.temperatureHistogramData === null &&
        this.props.getTemperatureHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );

      this.props.usgPowHistogramData === null &&
        this.props.fetchUsgPowHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.usgDodHistogramData === null &&
        this.props.fetchUsgDodHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
    } else if (tab === 'usgpow') {
      // this.props.usgPowHistogramData === null &&
      this.props.fetchUsgPowHistogramData(
        sessionStorage.getItem('selectedVehicle'),
        sessionStorage.getItem('selectedVehicleProduct'),
        srtDte,
        endDte,
        this.state.comnDateType
      );
      this.props.fetchUsgPowHeatmapData(
        sessionStorage.getItem('selectedVehicle'),
        sessionStorage.getItem('selectedVehicleProduct'),
        srtDte,
        endDte,
        this.state.comnDateType
      );
      this.props.temperatureHistogramData === null &&
        this.props.getTemperatureHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.usgSocHistogramData === null &&
        this.props.fetchUsgSocHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );

      this.props.usgDodHistogramData === null &&
        this.props.fetchUsgDodHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
    } else if (tab === 'usgdod') {
      this.props.usgDodHistogramData === null &&
        this.props.fetchUsgDodHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.fetchUsgDodHeatmapData(
        sessionStorage.getItem('selectedVehicle'),
        sessionStorage.getItem('selectedVehicleProduct'),
        srtDte,
        endDte,
        this.state.comnDateType
      );
      this.props.usgPowHistogramData === null &&
        this.props.fetchUsgPowHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.temperatureHistogramData === null &&
        this.props.getTemperatureHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
      this.props.usgSocHistogramData === null &&
        this.props.fetchUsgSocHistogramData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
    }
  };

  onVehicleProductsViewTabClickRedirection = (
    tab,
    stDte = null,
    edDte = null
  ) => {
    const url = new URL(window.location.href);
    url.searchParams.set('tb', tab);
    this.props.history.push(url.pathname + url.search);
  };

  onVehicleProductsViewTabClick = async (tab, stDte = null, edDte = null) => {
    const srtDte = stDte !== null ? stDte : this.props.selectedStartDate;
    const endDte = edDte !== null ? edDte : this.props.selectedEndDate;
    this.setState({ selectedTab: tab });
    const url = new URL(window.location.href);

    if (tab === 'Overview-vehicleprod-view') {
      if (
        sessionStorage.getItem('selectedProductDname') !== null &&
        sessionStorage
          .getItem('selectedProductDname')
          .includes('New_Product') === true
      ) {
        return false;
      }
      this.props.batteryOverviewData === null &&
        this.props.getBatteryOverviewData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct')
        );
      this.props.batteryRelData === null &&
        this.props.getBatteryRelData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct')
        );
      this.props.batteryStrData === null &&
        this.props.getBatteryStrData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct')
        );
    } else if (tab === 'Usage') {
      if (
        sessionStorage.getItem('selectedProductDname') !== null &&
        sessionStorage
          .getItem('selectedProductDname')
          .includes('New_Product') === true
      ) {
        return false;
      }
      this.onUsageTabsClick(this.state.selectedUsageTab, srtDte, endDte);
    } else if (tab === 'Energy-throughput-vehicleprod-view') {
      if (
        sessionStorage.getItem('selectedProductDname') !== null &&
        sessionStorage
          .getItem('selectedProductDname')
          .includes('New_Product') === true
      ) {
        return false;
      }
      const docType =
        this.state.isDailyactive === true
          ? 'day'
          : this.state.isWeeklyactive === true
            ? 'week'
            : 'month';
      this.props.boxPlotData === null &&
        this.props.getBoxPlotData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          docType
        );
    } else if (tab === 'SoC-range-usage-vehicleprod-view') {
      if (
        sessionStorage.getItem('selectedProductDname') !== null &&
        sessionStorage
          .getItem('selectedProductDname')
          .includes('New_Product') === true
      ) {
        return false;
      }
      this.props.batterySOCRangeData === null &&
        this.props.getBatterySOCRangeData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte,
          this.state.comnDateType
        );
    } else if (tab === 'State-of-charge-vehicleprod-view') {
      if (
        sessionStorage.getItem('selectedProductDname') !== null &&
        sessionStorage
          .getItem('selectedProductDname')
          .includes('New_Product') === true
      ) {
        return false;
      }
      this.props.batteryStateOfChargeData === null &&
        this.props.getBatteryStateOfChargeData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte
        );
    } else if (tab === 'Cell-voltage-vehicleprod-view') {
      if (
        sessionStorage.getItem('selectedProductDname') !== null &&
        sessionStorage
          .getItem('selectedProductDname')
          .includes('New_Product') === true
      ) {
        return false;
      }
      this.props.cellVoltageData === null &&
        this.props.getCellVoltageData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte
        );
    } else if (tab === 'synched-chart-vehicleprod-view') {
      if (
        sessionStorage.getItem('selectedProductDname') !== null &&
        sessionStorage
          .getItem('selectedProductDname')
          .includes('New_Product') === true
      ) {
        return false;
      }
      if (this.props.syncGraphDat === null) {
        this.props.getSyncGraphData(
          sessionStorage.getItem('selectedVehicle'),
          sessionStorage.getItem('selectedVehicleProduct'),
          srtDte,
          endDte
        );
      }
    } else if (tab === 'Event-list-vehicleprod-view') {
      this.props.eventList === null &&
        this.props.fetchAllEventList(
          url.searchParams.get('ft'),
          url.searchParams.get('vc'),
          url.searchParams.get('vp'),
          url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
          new URL(window.location.href).searchParams.get('efsd') !== null
            ? new URL(window.location.href).searchParams.get('efsd')
            : Moment(new Date(Moment().subtract(1, 'days').startOf('day')))
                .format('YYYY-MM-DDTHH:mm:ss')
                .replace(/:/g, '%3A'),
          new URL(window.location.href).searchParams.get('efed') !== null
            ? new URL(window.location.href).searchParams.get('efed')
            : Moment(new Date(Moment().subtract(1, 'days').endOf('day')))
                .format('YYYY-MM-DDTHH:mm:ss')
                .replace(/:/g, '%3A'),
          new URL(window.location.href).searchParams.get('efdt') !== null
            ? new URL(window.location.href).searchParams.get('efdt')
            : 'Any time',
          'All',
          'All',
          this.props.eventData && this.props.eventData.pageSize,
          this.props.eventData && this.props.eventData.pageIndex,
          !this.props.eventData.sortValue && this.props.eventData.sortValueKey
            ? 1
            : 0,
          this.props.eventData.sortValueKey
            ? this.props.eventData.sortValueKey
            : 'dt'
        );
    } else if (tab === 'diagnostic-vehicleprod-view') {
      this.props.setDiagnosticEventListData({
        pageSize: 20,
        pageIndex: 0,
        uniqueKey: 'All',
        uniqueValue: 'All',
        sortValue: true,
        sortValueKey: 'dt',
      });
      // this.props.diagnosticList === null &&
      this.props.fetchDiagnosticList(
        url.searchParams.get('ft'),
        url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
        url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
        url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
        url.searchParams.get('sdt'),
        url.searchParams.get('edt'),
        new URL(window.location.href).searchParams.get('dfdt') !== null
          ? new URL(window.location.href).searchParams.get('dfdt')
          : 'Any time',
        this.props.diagnosticEventData.uniqueKey,
        this.props.diagnosticEventData.uniqueValue,
        url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
        this.props.diagnosticEventData.pageSize
          ? this.props.diagnosticEventData.pageSize
          : 20,
        this.props.diagnosticEventData.pageIndex
          ? this.props.diagnosticEventData.pageIndex
          : 0,
        !this.props.diagnosticEventData.sortValue &&
          this.props.diagnosticEventData.sortValueKey
          ? 1
          : 0,
        this.props.diagnosticEventData.sortValueKey
          ? this.props.diagnosticEventData.sortValueKey
          : ''
      );
    } else if (tab === 'Timetrend-vehicleprod-view') {
      if (this.state.comnDateType === 'Year') {
        await this.onDatePickerButtonClick('Monthly');
        return;
      }
      if (
        sessionStorage.getItem('selectedProductDname') !== null &&
        sessionStorage
          .getItem('selectedProductDname')
          .includes('New_Product') === true
      ) {
        return false;
      }

      if (sessionStorage.getItem('isTrendZoomed') === 'true') {
        this.getTimetrendData(
          this.props.selectedMainTrend,
          sessionStorage.getItem('zoomIntervalStartDate'),
          sessionStorage.getItem('zoomIntervalEndDate')
        );
      } else {
        (this.checkDateValidation() || this.state.comnDateType !== 'Custom') &&
          this.getTimetrendData(this.props.selectedMainTrend, srtDte, endDte);
      }
      this.handleLoading();
    } else if (tab === 'SOH-vehicleprod-view') {
      const temppk = url.searchParams.get('pk');
      const tempvp = url.searchParams.get('vp');
      const scuID =
        url.searchParams.get('scuID') !== null
          ? url.searchParams.get('scuID')
          : getscuID(temppk, this.props.vehiclesListData);
      const temp_bpu =
        url.searchParams.get('bpu') === null
          ? getbpu(tempvp, this.props.vehiclesListData)
          : url.searchParams.get('bpu');
      url.searchParams.set('bpu', bpu);
      // this.props.SOHData === null &&
      this.props.fetchSOHData(
        sessionStorage.getItem('selectedFleet'),
        temp_bpu,
        scuID
      );
    }
  };

  getTimetrendData = (selectedSignal, startDate, endDate) => {
    let allSignals = [];
    let ttMainSignal = selectedSignal;
    let ttOverSignal = this.props.selectedOverlayTrend;
    if (ttm !== null) {
      ttMainSignal = ttm;
      if (tto !== null) {
        ttOverSignal = tto;
      }
      ttm = null;
      tto = null;
    }
    allSignals.push(ttMainSignal); // (selectedSignal);
    if (ttOverSignal != null) {
      // if(this.props.selectedOverlayTrend != null)
      allSignals = allSignals.concat(ttOverSignal); // (this.props.selectedOverlayTrend);
    }
    let filterSignals =
      userConfig !== undefined &&
      userConfig.entitlements.product.trendSignals.filter(
        item => !allSignals.includes(item)
      );
    if (sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet') {
      filterSignals = mainSignalTrendOptionsDemo.filter(
        item => !allSignals.includes(item)
      );
    }
    allSignals = allSignals.concat(filterSignals);

    allSignals.forEach(signal => {
      switch (signal) {
        case 'Cell Voltage':
          this.props.ttAvgCellV === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'Avg CellV',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'Temperature':
          this.props.ttAvgTemperature === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'Avg Temperature',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'State of Charge':
          this.props.ttSOC === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'State of Charge',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'Power':
          this.props.ttPower === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'Power',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'Battery Voltage':
          this.props.ttBVoltage === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'Battery Voltage',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'Battery Current':
          this.props.ttBCurrent === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'Battery Current',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'Battery Peak Chg.':
          this.props.ttBPeakChg === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'BatteryPeakCharge',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'Battery Peak DisChg.':
          this.props.ttBPeakDischg === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'BatteryPeakDisCharge',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'Battery Cont. Chg.':
          this.props.ttBConstChg === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'BatteryConstCharge',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'Battery Cont. DisChg.':
          this.props.ttBConstChg === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'BatteryConstDisCharge',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        case 'Battery Overload Cntr':
          this.props.ttBOverloadCntr === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'BatteryOverloadCounter',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;
        case 'Reliability':
          this.props.ttReliability === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'Reliability',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;
        case 'Feed Flow':
          this.props.ttFeedFlow === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'FeedFlow',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;
        case 'Overload Counter':
          this.props.ttOverloadCounter === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'OverloadCounter',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;
        case 'Return Flow':
          this.props.ttReturnFlow === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'ReturnFlow',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;
        case 'Diagnostic Event':
          this.props.ttDiagnostic === null &&
            this.props.getDetailViewTimetrend(
              sessionStorage.getItem('selectedVehicle'),
              startDate,
              endDate,
              'DiagnosticEvent',
              sessionStorage.getItem('selectedVehicleProduct'),
              this.state.comnDateType
            );
          break;

        default:
          break;
      }
    });
    sessionStorage.setItem('xMin', null);
    sessionStorage.setItem('xMax', null);
  };

  redirectToTimetrendFrmDiagnostic = async obj => {
    const { bp, pn, pk } = obj;
    // const url = new URL(window.location.href);

    const formdtdDate = Moment.utc(obj.dt).format('YYYY-MM-DD');
    const time = Moment().startOf('day').format('HH:mm:ss');
    const time1 = Moment().endOf('day').format('HH:mm:ss');
    const startDate = formdtdDate + ' ' + time;
    const endDate = formdtdDate + ' ' + time1;
    const platform = platformVersion();
    const selectedPack = pk;
    const selectedPackDisplayName = obj.pn;
    const scuID = obj.pn && parseInt(obj.pn.match(/\d+/)[0]);
    const selectedVehicle = obj.vno; // "WINK_ARRIVA_601";
    const selectedVehicleProduct = obj.pcf; // "3BHE046683R01012438";
    const selectedProductIP = obj.ip; // "10.1.1.116"; //
    const selectedProductDnameP3 = bp; // obj.pt + "_" + obj.esn; //"ESS_HP_001A02_17"; //
    const selectedProductDname = pn || bp;
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    const bpu = getbpu(obj.pc, this.props.vehiclesListData); // obj.pun//3BHE046683R01012438

    await this.props.setMainTrendSignal('Diagnostic Event');
    directlinktrendSignalCheck('Battery Current')
      ? await this.props.addOverlayTrendSignal(['Battery Current'])
      : await this.props.addOverlayTrendSignal([]);
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();

    let tempParameter = '';
    directlinktrendSignalCheck('Battery Current')
      ? (tempParameter = '&tto=Battery Current')
      : (tempParameter = '');

    if (platform === '3') {
      this.props.history.push(
        '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&vp=' +
          selectedVehicleProduct +
          '&pk=' +
          selectedPack +
          '&pkdn=' +
          selectedPackDisplayName +
          '&pip=' +
          selectedProductIP +
          '&pdn=' +
          selectedProductDnameP3 +
          '&sdt=' +
          startDate +
          '&edt=' +
          endDate +
          '&unmas=false' +
          '&ttm=Diagnostic Event' +
          tempParameter +
          '&tb=Timetrend-vehicleprod-view' +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&cdty=Custom' +
          '&scuID=' +
          scuID +
          '&bpu=' +
          bpu
      );
    } else {
      this.props.history.push(
        '/FleetView/VehicleView/VehicleProductsView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          sessionStorage.getItem('selectedVehicle') +
          '&vp=' +
          selectedVehicleProduct +
          '&pip=' +
          selectedProductIP +
          '&pdn=' +
          selectedProductDname +
          '&sdt=' +
          startDate +
          '&edt=' +
          endDate +
          '&ttm=Diagnostic Event' +
          tempParameter +
          '&tb=Timetrend-vehicleprod-view' +
          '&unmas=false' +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&bpu=' +
          bpu +
          '&cdty=Custom'
      );
    }
  };

  handleLoading = () => {
    switch (this.props.selectedMainTrend) {
      case 'Cell Voltage':
        this.props.ttAvgCellV !== null && this.props.setTimetrendLoading(false);
        break;
      case 'Temperature':
        this.props.ttAvgTemperature !== null &&
          this.props.setTimetrendLoading(false);
        break;
      case 'State of Charge':
        this.props.ttSOC !== null && this.props.setTimetrendLoading(false);
        break;
      case 'Power':
        this.props.ttPower !== null && this.props.setTimetrendLoading(false);
        break;
      case 'Battery Voltage':
        this.props.ttBVoltage !== null && this.props.setTimetrendLoading(false);
        break;
      case 'Battery Current':
        this.props.ttBCurrent !== null && this.props.setTimetrendLoading(false);
        break;
      case 'Battery Peak Chg.':
        this.props.ttBPeakChg !== null && this.props.setTimetrendLoading(false);
        break;
      case 'Battery Peak DisChg.':
        this.props.ttBPeakDischg !== null &&
          this.props.setTimetrendLoading(false);
        break;
      case 'Battery Cont. Chg.':
        this.props.ttBConstChg !== null &&
          this.props.setTimetrendLoading(false);
        break;
      case 'Battery Cont. DisChg.':
        this.props.ttBConstChg !== null &&
          this.props.setTimetrendLoading(false);
        break;
      case 'Battery Overload Cntr':
        this.props.ttBOverloadCntr !== null &&
          this.props.setTimetrendLoading(false);
        break;
      case 'Reliability':
        this.props.ttReliability !== null &&
          this.props.setTimetrendLoading(false);
        break;
      case 'Feed Flow':
        this.props.ttFeedFlow !== null && this.props.setTimetrendLoading(false);
        break;
      case 'Overload Counter':
        this.props.ttOverloadCounter !== null &&
          this.props.setTimetrendLoading(false);
        break;
      case 'Return Flow':
        this.props.ttReturnFlow !== null &&
          this.props.setTimetrendLoading(false);
        break;
      case 'Diagnostic Event':
        this.props.ttDiagnostic !== null &&
          this.props.setTimetrendLoading(false);
        break;

      default:
        break;
    }
  };

  onApplyClick = async (startDate, endDate, custom = '') => {
    if (custom === 'custom') {
      this.setState({ comnDateType: 'Custom' });
      const url = new URL(window.location.href);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('cdty', 'Custom');
        this.props.history.push(url.pathname + url.search);
      }
    }
    // if (
    //   new Date(startDate).valueOf() ==
    //     new Date(this.props.selectedStartDate.replace(/%3A/g, ":")).valueOf() &&
    //   new Date(endDate).valueOf() ==
    //     new Date(this.props.selectedEndDate.replace(/%3A/g, ":")).valueOf()
    // ) {
    //   return false;
    // }
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      if (this.state.isRedirectUsageHeatmap === true) {
        url.searchParams.set('ttm', this.props.selectedMainTrend);
        this.props.selectedOverlayTrend !== null
          ? url.searchParams.set('tto', this.props.selectedOverlayTrend)
          : url.searchParams.delete('tto');
        url.searchParams.set('tb', 'Timetrend-vehicleprod-view');
        url.searchParams.set('cdty', 'Custom');
      }
      url.searchParams.set(
        'sdt',
        Moment(startDate).format('YYYY-MM-DDTHH:mm:ss')
      );
      url.searchParams.set(
        'edt',
        Moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
      );
      url.searchParams.delete('zsd');
      url.searchParams.delete('zed');
      url.searchParams.delete('tmn');
      url.searchParams.delete('tmx');
      this.props.history.push(url.pathname + url.search);
    }

    await this.props.setDate(startDate, endDate);
    await this.props.clearChartData();
    // await this.props.clearEventList();

    const formatedStartDte = Moment(
      new Date(this.props.selectedStartDate.replace(/%3A/g, ':'))
    ).format('DD.MM.YYYY, HH:mm');
    const formatedEndDte = Moment(
      new Date(this.props.selectedEndDate.replace(/%3A/g, ':'))
    ).format('DD.MM.YYYY, HH:mm');
    this.setState({
      startZoomInterval: formatedStartDte,
      endZoomInterval: formatedEndDte,
      lockedZoomDuration: this.getDifferenceText(
        Moment.utc(formatedStartDte, 'DD.MM.YYYY HH:mm:ss').toISOString(),
        Moment.utc(formatedEndDte, 'DD.MM.YYYY HH:mm:ss').toISOString()
      ),
    });
    this.setState({
      isButtonDisabled: false,
      isApplyZoomEnabled: false,
    });
    zoomHistory = [];
    zoomHistory.push([
      this.props.selectedStartDate,
      this.props.selectedEndDate,
    ]);

    const currentSamplingType = this.getSamplingType(
      Moment.utc(formatedStartDte, 'DD.MM.YYYY HH:mm:ss').toISOString(),
      Moment.utc(formatedEndDte, 'DD.MM.YYYY HH:mm:ss').toISOString()
    );

    sessionStorage.setItem('samplingType', currentSamplingType);
    // sessionStorage.setItem("applyZoomInterval", null);
    sessionStorage.setItem('zoomIntervalStartDate', null);
    sessionStorage.setItem('zoomIntervalEndDate', null);
    sessionStorage.setItem('isTrendZoomed', null);
    sessionStorage.setItem('xMin', null);
    sessionStorage.setItem('xMax', null);

    if (
      this.checkDateValidation() ||
      this.props.selectedTab !== 'Timetrend-vehicleprod-view'
    ) {
      this.onVehicleProductsViewTabClick(this.state.selectedTab);
    }
  };

  handleSyncMainDropdownChange = value => {
    const url = new URL(window.location.href);
    if (this.props.syncGraphChart === 'chart1') {
      // this.setState({ selectedMainSignal1: value });
      this.props.setMainSignal1(value);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('ms1', value);
        this.props.history.push(url.pathname + url.search);
      }
    } else if (this.props.syncGraphChart === 'chart2') {
      // this.setState({ selectedMainSignal2: value });
      this.props.setMainSignal2(value);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('ms2', value);
        this.props.history.push(url.pathname + url.search);
      }
    } else {
      // this.setState({ selectedMainSignal3: value });
      this.props.setMainSignal3(value);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('ms3', value);
        this.props.history.push(url.pathname + url.search);
      }
    }
    this.props.setMainSignal(value);
  };

  handleSyncOverlayDropdownChange = value => {
    const url = new URL(window.location.href);
    if (this.props.syncGraphChart === 'chart1') {
      // this.setState({ selectedOverlaySignal1: value });
      this.props.setOverlaySignal1(value);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('os1', value);
        this.props.history.push(url.pathname + url.search);
      }
    } else if (this.props.syncGraphChart === 'chart2') {
      // this.setState({ selectedOverlaySignal2: value });
      this.props.setOverlaySignal2(value);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('os2', value);
        this.props.history.push(url.pathname + url.search);
      }
    } else {
      // this.setState({ selectedOverlaySignal3: value });
      this.props.setOverlaySignal3(value);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('os3', value);
        this.props.history.push(url.pathname + url.search);
      }
    }
    this.props.setOverlaySignal(value);

    // this.handleSyncGraphData();
  };

  handleSyncToggleChange = selection => {
    // this.setState({ selectedSyncChart: selection });
    this.props.setSyncChart(selection);
    if (selection === 'chart1') {
      // this.setState({ selectedMainSignal: this.props.syncGraphMain1 });
      // this.setState({
      //   selectedOverlaySignal: this.props.syncGraphOverlay1,
      // });
      this.props.setMainSignal(this.props.syncGraphMain1);
      this.props.setOverlaySignal(this.props.syncGraphOverlay1);
    } else if (selection === 'chart2') {
      // this.setState({ selectedMainSignal: this.props.syncGraphMain2 });
      // this.setState({
      //   selectedOverlaySignal: this.props.syncGraphOverlay2,
      // });
      this.props.setMainSignal(this.props.syncGraphMain2);
      this.props.setOverlaySignal(this.props.syncGraphOverlay2);
    } else {
      // this.setState({ selectedMainSignal: this.props.syncGraphMain3 });
      // this.setState({
      //   selectedOverlaySignal: this.props.syncGraphOverlay3,
      // });
      this.props.setMainSignal(this.props.syncGraphMain3);
      this.props.setOverlaySignal(this.props.syncGraphOverlay3);
    }
  };

  clickTrendMainSignalRedirection = selectedMainOption => {
    this.props.setMainTrendSignal(selectedMainOption);
    this.props.addOverlayTrendSignal([]);
  };

  clickTrendMainSignal = selectedMainOption => {
    // this.props.setMainTrendSignal(selectedMainOption);
    // this.props.addOverlayTrendSignal([]);

    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('ttm', selectedMainOption);
      url.searchParams.delete('tto');
      url.searchParams.delete('ttl');
      this.props.history.push(url.pathname + url.search);
    }
  };

  clickTrendOverlaySignalHandler = selectedOverlaysTrend => {
    this.props.addOverlayTrendSignal(selectedOverlaysTrend);
  };

  clickTrendOverlaySignal = selectedOverlaysTrend => {
    // this.props.addOverlayTrendSignal(selectedOverlaysTrend);
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      if (selectedOverlaysTrend.length > 0) {
        url.searchParams.set('tto', selectedOverlaysTrend);
        url.searchParams.delete('ttl');
        this.props.history.push(url.pathname + url.search);
      } else {
        url.searchParams.delete('tto');
        url.searchParams.delete('ttl');
        this.props.history.push(url.pathname + url.search);
      }
    }
  };

  getDateDiff = (StartDate, EndDate) => {
    if (StartDate && EndDate) {
      const duration = Moment.duration(Moment(EndDate).diff(Moment(StartDate)));
      const totaldays = duration.asDays();
      const TotalHours = duration.asHours();
      return { totaldays, TotalHours };
    }

    return '';
  };

  getSamplingType = (startDate, endDate) => {
    const { totaldays, TotalHours } = this.getDateDiff(startDate, endDate);
    let samplingType = '';
    // let totaldays = 0;
    // let TotalHours = 22;
    if (totaldays > 60 && totaldays <= 90) {
      samplingType = '30 minute';
    } else if (totaldays > 20 && totaldays <= 60) {
      samplingType = '15 minute';
    } else if (totaldays > 5 && totaldays <= 20) {
      samplingType = '4 minute';
    } else if (totaldays > 1 && totaldays <= 5) {
      samplingType = '1 minute';
    } else if (totaldays <= 1) {
      if (TotalHours > 4) {
        samplingType = '15 second';
      } else if (TotalHours > 1 && TotalHours <= 4) {
        samplingType = '5 second';
      } else {
        samplingType = '1 second';
      }
    }
    return samplingType;
  };

  getDifferenceText = (StartDate, EndDate) => {
    if (StartDate && EndDate) {
      const localStartDate = StartDate;
      const localEndDate = EndDate;
      const diff = Moment(localEndDate).diff(Moment(localStartDate));
      const d = Moment.duration(diff);

      const days = Math.floor(d.asHours() / 24);
      const hours = Math.floor(d.asHours() - days * 24);
      const minutes = Math.floor(d.asMinutes() - (hours * 60 + days * 24 * 60));
      let msg = days > 0 ? ` ${days} days` : '';
      msg += hours > 0 || minutes > 0 ? ` ${hours} hours` : '';
      msg += minutes > 0 ? ` ${minutes} minutes` : '';

      if (days < 0 || hours < 0 || minutes < 0) {
        msg = '';
      }
      return msg;
    }

    return '';
  };

  // -------------------------------------------------
  onLoadMoreGraph = async (start, end, iszoom, iszoomout) => {
    // sessionStorage.removeItem("clickedTimeStampForRunSlider");
    let formatedStartDate, formatedEndDate;
    if (iszoom === true) {
      const currentSamplingType = this.getSamplingType(
        Moment.utc(start).toISOString(),
        Moment.utc(end).toISOString()
      );

      const previousSamplingType =
        sessionStorage.getItem('samplingType') === null
          ? null
          : sessionStorage.getItem('samplingType');
      // ----------------------------------------------------
      if (
        previousSamplingType !== null &&
        currentSamplingType === previousSamplingType
      ) {
        sessionStorage.setItem('xMin', true);
        sessionStorage.setItem('xMax', true);
        return false;
      }

      await this.props.clearDetailViewTimetrend();

      formatedStartDate = Moment.utc(start)
        .toISOString()
        .slice(0, 19)
        .replace(/:/g, '%3A');
      formatedEndDate = Moment.utc(end)
        .toISOString()
        .slice(0, 19)
        .replace(/:/g, '%3A');
      sessionStorage.setItem('zoomIntervalStartDate', formatedStartDate);
      sessionStorage.setItem('zoomIntervalEndDate', formatedEndDate);

      sessionStorage.setItem('samplingType', currentSamplingType);
      sessionStorage.setItem('isTrendZoomed', true);

      this.setState({
        startZoomInterval: Moment.utc(start).format('DD.MM.YYYY, HH:mm'),
        endZoomInterval: Moment.utc(end).format('DD.MM.YYYY, HH:mm'),
        lockedZoomDuration: this.getDifferenceText(
          Moment.utc(start).toISOString(),
          Moment.utc(end).toISOString()
        ),
      });
      zoomHistory.push([formatedStartDate, formatedEndDate]);

      const url = new URL(window.location.href);
      if (window.location.href.indexOf('?') > -1) {
        if (this.state.isRedirectUsageHeatmap === true) {
          url.searchParams.set('ttm', this.props.selectedMainTrend);
          this.props.selectedOverlayTrend !== null
            ? url.searchParams.set('tto', this.props.selectedOverlayTrend)
            : url.searchParams.delete('tto');
          url.searchParams.set('tb', 'Timetrend-vehicleprod-view');
        }
        url.searchParams.set('zsd', formatedStartDate);
        url.searchParams.set('zed', formatedEndDate);
        this.props.history.push(url.pathname + url.search);
      }

      sessionStorage.setItem('xMin', null);
      sessionStorage.setItem('xMax', null);
    } else if (iszoomout === true) {
      if (zoomHistory.length > 1) {
        const backDate = zoomHistory[zoomHistory.length - 2];
        zoomHistory.pop();
        formatedStartDate = backDate[0];
        formatedEndDate = backDate[1];
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          url.searchParams.set('zsd', formatedStartDate);
          url.searchParams.set('zed', formatedEndDate);
          url.searchParams.delete('tmn');
          url.searchParams.delete('tmx');
          this.props.history.push(url.pathname + url.search);
        }
      } else {
        // zoomHistory = [];
        // formatedStartDate = this.props.startDate;
        // formatedEndDate = this.props.endDate;
        this.setState({
          isButtonDisabled: false,
          isApplyZoomEnabled: false,
        });
        sessionStorage.setItem('samplingType', null);
        // sessionStorage.setItem("applyZoomInterval", null);
        sessionStorage.setItem('zoomIntervalStartDate', null);
        sessionStorage.setItem('zoomIntervalEndDate', null);
        sessionStorage.setItem('isTrendZoomed', null);
        return false;
      }

      const formatedStartDte = Moment(
        new Date(formatedStartDate.replace(/%3A/g, ':'))
      ).format('DD.MM.YYYY, HH:mm');
      const formatedEndDte = Moment(
        new Date(formatedEndDate.replace(/%3A/g, ':'))
      ).format('DD.MM.YYYY, HH:mm');
      this.setState({
        startZoomInterval: formatedStartDte,
        endZoomInterval: formatedEndDte,
        lockedZoomDuration: this.getDifferenceText(
          Moment.utc(formatedStartDte, 'DD.MM.YYYY HH:mm:ss').toISOString(),
          Moment.utc(formatedEndDte, 'DD.MM.YYYY HH:mm:ss').toISOString()
        ),
      });
      const currentSamplingType = this.getSamplingType(
        Moment.utc(formatedStartDte, 'DD.MM.YYYY HH:mm:ss').toISOString(),
        Moment.utc(formatedEndDte, 'DD.MM.YYYY HH:mm:ss').toISOString()
      );

      // await this.props.clearTrainViewMap();
      // await this.props.clearDetailViewMap();
      // await this.props.clearDetailViewMapDateSlider();
      await this.props.clearDetailViewTimetrend();
      // await this.props.clearDetailViewScatterplot();
      // await this.props.clearDetailViewHistogram();
      // await this.props.addOverlay(null);
      sessionStorage.setItem('zoomIntervalStartDate', formatedStartDate);
      sessionStorage.setItem('zoomIntervalEndDate', formatedEndDate);
      sessionStorage.setItem('samplingType', currentSamplingType);
      sessionStorage.setItem('isTrendZoomed', true);
      // sessionStorage.setItem("applyZoomInterval", true);

      if (zoomHistory.length === 1) {
        this.setState({
          isButtonDisabled: false,
          isApplyZoomEnabled: false,
        });
        sessionStorage.setItem('samplingType', currentSamplingType);
        // sessionStorage.setItem("applyZoomInterval", null);
        sessionStorage.setItem('zoomIntervalStartDate', null);
        sessionStorage.setItem('zoomIntervalEndDate', null);
        sessionStorage.setItem('isTrendZoomed', null);
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          url.searchParams.delete('zsd');
          url.searchParams.delete('zed');
          url.searchParams.delete('tmn');
          url.searchParams.delete('tmx');
          this.props.history.push(url.pathname + url.search);
        }
      }
      sessionStorage.setItem('xMin', null);
      sessionStorage.setItem('xMax', null);
    } else {
      zoomHistory = [];
      zoomHistory.push([
        this.props.selectedStartDate,
        this.props.selectedEndDate,
      ]);
      const url = new URL(window.location.href);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.delete('zsd');
        url.searchParams.delete('zed');
        url.searchParams.delete('tmn');
        url.searchParams.delete('tmx');
        this.props.history.push(url.pathname + url.search);
      }

      // await this.props.clearTrainViewMap();
      // await this.props.clearDetailViewMap();
      // await this.props.clearDetailViewMapDateSlider();
      await this.props.clearDetailViewTimetrend();
      // await this.props.clearDetailViewScatterplot();
      // await this.props.clearDetailViewHistogram();
      // await this.props.addOverlay(null);
      formatedStartDate = this.props.selectedStartDate;
      formatedEndDate = this.props.selectedEndDate;

      const formatedStartDte = Moment(
        new Date(this.props.selectedStartDate.replace(/%3A/g, ':'))
      ).format('DD.MM.YYYY, HH:mm');
      const formatedEndDte = Moment(
        new Date(this.props.selectedEndDate.replace(/%3A/g, ':'))
      ).format('DD.MM.YYYY, HH:mm');
      const lockedZoomDuration = this.getDifferenceText(
        new Date(this.props.selectedStartDate.replace(/%3A/g, ':')),
        new Date(this.props.selectedEndDate.replace(/%3A/g, ':'))
      );

      const currentSamplingType = this.getSamplingType(
        Moment.utc(formatedStartDte, 'DD.MM.YYYY HH:mm:ss').toISOString(),
        Moment.utc(formatedEndDte, 'DD.MM.YYYY HH:mm:ss').toISOString()
      );

      sessionStorage.setItem('samplingType', currentSamplingType);
      // sessionStorage.setItem("applyZoomInterval", null);
      sessionStorage.setItem('zoomIntervalStartDate', null);
      sessionStorage.setItem('zoomIntervalEndDate', null);
      sessionStorage.setItem('isTrendZoomed', null);
      // this.onClickTab(this.props.selectedTab);

      this.setState({
        startZoomInterval: formatedStartDte,
        endZoomInterval: formatedEndDte,
        lockedZoomDuration,
      });
      // let currentSamplingType = this.getSamplingType(
      //   Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
      //   Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
      // );
      // sessionStorage.setItem("samplingType", currentSamplingType);
      // this.props.setTimetrendLoading(true);
      this.setState({ loadingOverlay: true });
      // this.onClickTab(this.props.selectedTab);
      // this.getTimetrendData(
      //   this.props.selectedMainTrend,
      //   formatedStartDate,
      //   formatedEndDate
      // );
      // return;
      sessionStorage.setItem('xMin', null);
      sessionStorage.setItem('xMax', null);
    }

    this.setState({
      isButtonDisabled: false,
      isApplyZoomEnabled: false,
    });
    this.setState({ loadingOverlay: true });
    this.getTimetrendData(
      this.props.selectedMainTrend,
      formatedStartDate,
      formatedEndDate
    );
  };

  onListSelectionClick = (e, selection) => {
    if (selection === 'vehicle') {
      if (e.target.checked === true && this.props.selectedList === 'product')
        this.props.setListSelection('vehicleproduct');
      else if (e.target.checked === true)
        this.props.setListSelection('vehicle');
      else this.props.setListSelection('product');
    } else if (selection === 'product') {
      if (e.target.checked === true && this.props.selectedList === 'vehicle')
        this.props.setListSelection('vehicleproduct');
      else if (e.target.checked === true)
        this.props.setListSelection('product');
      else this.props.setListSelection('vehicle');
    }
  };

  redirectToTimetrend = async (point, signal) => {
    const formdtdDate = Moment(point.category, 'DD.MM.YYYY').format(
      'YYYY-MM-DD'
    );
    const time = Moment().startOf('day').format('HH:mm:ss');
    const time1 = Moment().endOf('day').format('HH:mm:ss');

    const startDate = formdtdDate + ' ' + time;
    const endDate = formdtdDate + ' ' + time1;
    if (
      signal === 'nameClicked' ||
      signal === 'dateClicked' ||
      signal === 'pnameClicked'
    ) {
      await this.props.setMainTrendSignal('State of Charge');
      await this.props.addOverlayTrendSignal(['Power']);
      await this.onApplyClick(startDate, endDate);
      const url = new URL(window.location.href);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('ttm', 'State of Charge');
        url.searchParams.set('tto', 'Power');
        this.props.history.push(url.pathname + url.search);
      }
    } else {
      await this.props.setMainTrendSignal(signal);
      await this.props.addOverlayTrendSignal([]);
      await this.onApplyClick(startDate, endDate);
      const url = new URL(window.location.href);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('ttm', signal);
        url.searchParams.delete('tto');
        this.props.history.push(url.pathname + url.search);
      }
    }
    document.getElementById('fleet-tab-Timetrend-vehicleprod-view').click();
  };

  redirectToTimetdOnStreesEvtClk = async obj => {
    const formdtdDate = Moment.utc(obj.dt).format('YYYY-MM-DD');
    const time = Moment().startOf('day').format('HH:mm:ss');
    const time1 = Moment().endOf('day').format('HH:mm:ss');

    const startDate = formdtdDate + ' ' + time;
    const endDate = formdtdDate + ' ' + time1;
    let evtSignal = 'Cycles';
    if (obj.rsn === 'C-Rate') {
      evtSignal = 'Battery Current';
    } else if (obj.rsn === 'Temperature') {
      evtSignal = 'Temperature';
    } else if (obj.rsn === 'SOC') {
      evtSignal = 'State of Charge';
    }

    if (evtSignal === 'Cycles') {
      await this.props.setMainTrendSignal('State of Charge');
      await this.props.addOverlayTrendSignal(['Power']);
      await this.onApplyClick(startDate, endDate);
      const url = new URL(window.location.href);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('ttm', 'State of Charge');
        url.searchParams.set('tto', 'Power');
        this.props.history.push(url.pathname + url.search);
      }
    } else {
      await this.props.setMainTrendSignal(evtSignal);
      await this.props.addOverlayTrendSignal([]);
      await this.onApplyClick(startDate, endDate);
      const url = new URL(window.location.href);
      if (window.location.href.indexOf('?') > -1) {
        url.searchParams.set('ttm', evtSignal);
        url.searchParams.delete('tto');
        this.props.history.push(url.pathname + url.search);
      }
    }
    document.getElementById('fleet-tab-Timetrend-vehicleprod-view').click();
  };

  handleEventInputChange = e => {
    if (e !== undefined) {
      this.setState({
        eventFormValues: {
          ...this.state.eventFormValues,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleClose = () => {
    this.setState({
      eventFormValues: {
        ...this.state.eventFormValues,
        exchangetype: 'ESS',
        nessan:
          sessionStorage.getItem('nessan') === 'null'
            ? ''
            : sessionStorage.getItem('nessan'),
        nesssn: '',
        npecan: '',
        npecsn: '',
        prepecan: '',
        prepecsn: '',
        comments: '',
      },
    });
  };

  handleEventRadioChange = e => {
    if (e !== undefined) {
      if (e.target.value === 'PEC') {
        this.setState({
          eventFormValues: {
            ...this.state.eventFormValues,
            [e.target.name]: e.target.value,
            nessan: '',
            nesssn: '',
          },
        });
      } else {
        this.setState({
          eventFormValues: {
            ...this.state.eventFormValues,
            [e.target.name]: e.target.value,
            nessan:
              sessionStorage.getItem('nessan') === 'null'
                ? ''
                : sessionStorage.getItem('nessan'),
          },
        });
      }
    }
  };

  setEventUpdateDate = date => {
    // this.setState({
    //   eventFormValues: {
    //     ...this.state.eventFormValues,
    //     cdate: Moment.utc(date, "DD.MM.YYYY HH:mm:ss").toISOString(),
    //   },
    // });
  };

  setEventNewPec = obj => {
    let npecan = '';
    let npecsn = '';
    if (obj.npun !== null) {
      npecan = obj.npun.slice(0, 15);
      npecsn = obj.npun.slice(15);
    }
    if (
      obj.cd === '0X002' &&
      obj.rsn === 'Change Request' &&
      obj.sts === 'Pending'
    ) {
      this.setState({
        eventFormValues: {
          ...this.state.eventFormValues,
          pecIP: obj.ip,
          npecan,
          npecsn,
          prepecan: npecan,
          prepecsn: npecsn,
          oessan: '',
          oesssn: '',
          opecan: '',
          opecsn: '',
        },
      });
    } else {
      this.setState({
        eventFormValues: {
          ...this.state.eventFormValues,
          npecan,
          npecsn,
          prepecan: npecan,
          prepecsn: npecsn,
        },
      });
    }
  };

  handleEventSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    await this.props.clearSaveEventResponce();
    this.props.saveEvent(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      this.state.eventFormValues
    );
    // this.setState({
    //   eventFormValues: {
    //     ...this.state.eventFormValues,
    //     nessan: "",
    //     nesssn: "",
    //     npecan: "",
    //     npecsn: "",
    //     prepecan: "",
    //     prepecsn: "",
    //     comments: "",
    //   },
    // });
  };

  handleUndoSubmit = async obj => {
    // e.preventDefault();
    // e.stopPropagation();

    await this.props.clearUndoEventResponce();
    this.props.undoEvent(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('selectedVehicle'),
      obj
    );
  };

  eventFilterApply = (filterStartDate, filterEndDate) => {
    const url = new URL(window.location.href);
    this.props.fetchAllEventList(
      url.searchParams.get('ft'),
      url.searchParams.get('vc'),
      url.searchParams.get('vp'),
      url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
      Moment(filterStartDate)
        .format('YYYY-MM-DDTHH:mm:ss')
        .replace(/:/g, '%3A'),
      Moment(filterEndDate).format('YYYY-MM-DDTHH:mm:ss').replace(/:/g, '%3A'),
      new URL(window.location.href).searchParams.get('efdt') !== null
        ? new URL(window.location.href).searchParams.get('efdt')
        : 'Any time',
      'All',
      'All',
      this.props.eventData.pageSize,
      this.props.eventData.pageIndex,
      !this.props.eventData.sortValue && this.props.eventData.sortValueKey
        ? 1
        : 0,
      this.props.eventData.sortValueKey
        ? this.props.eventData.sortValueKey
        : 'dt'
    );
  };

  redirectToEmailAlert = () => {
    this.props.history.push(
      '/FleetView/EmailAlertView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet')
    );
  };

  checkDateValidation = () => {
    // check the dates
    const diff = Moment(
      new Date(this.props.selectedEndDate.replace(/%3A/g, ':'))
    ).diff(new Date(this.props.selectedStartDate.replace(/%3A/g, ':')));
    const d = Moment.duration(diff);

    const days = Math.floor(d.asHours() / 24);
    const validateDays = d.asHours() / 24;
    const hours = Math.floor(d.asHours() - days * 24);
    const minutes = Math.floor(d.asMinutes() - (hours * 60 + days * 24 * 60));
    // let msg = days > 0 ? ` ${days} days` : '';
    // msg += hours > 0 || minutes > 0 ? ` ${hours} hours` : '';
    // msg += minutes > 0 ? ` ${minutes} minutes` : '';

    if (validateDays === 0 && hours === 0 && minutes === 0) {
      return false;
    } else if (validateDays < 0 || hours < 0 || minutes < 0) {
      return false;
    } else if (validateDays > 90) {
      return false;
    } else {
      return true;
    }
  };

  handleSearchInputChange = value => {
    if (value === null || value === '') {
      sessionStorage.removeItem('searchResultList');
    }
    this.setState({ searchQuery: value });
    sessionStorage.setItem('searchQuery', value);
    value = value && value.toLowerCase();
    let results = [];
    // let startprof = performance.now();
    this.props.vehiclesListData[0].vst.forEach(item => {
      // if the vehicle name matches
      item.vdat.forEach(subitem => {
        // if the query matches battery name or ip address or pec serial number
        if (
          (subitem.dn && subitem.dn.toLowerCase().includes(value)) ||
          (subitem.ip && subitem.ip.toLowerCase().includes(value)) ||
          (subitem.an && subitem.an.toLowerCase().includes(value)) ||
          (subitem.bpu && subitem.bpu.toLowerCase().includes(value)) ||
          (subitem.rcm && subitem.rcm.includes(value)) ||
          (subitem.swv && subitem.swv.includes(value)) ||
          (subitem.bwvr && subitem.bwvr.includes(value))
        ) {
          results.push(item);
        }
        if (subitem.pdat.length > 0) {
          let matchFound = false;
          subitem.pdat.forEach(subitemlvl2 => {
            if (
              (subitemlvl2.dn &&
                subitemlvl2.dn.toLowerCase().includes(value)) ||
              (subitemlvl2.ip &&
                subitemlvl2.ip.toLowerCase().includes(value)) ||
              (subitemlvl2.an &&
                subitemlvl2.an.toLowerCase().includes(value)) ||
              (subitemlvl2.bpu &&
                subitemlvl2.bpu.toLowerCase().includes(value)) ||
              (subitemlvl2.rcm && subitemlvl2.rcm.includes(value)) ||
              (subitemlvl2.swv && subitemlvl2.swv.includes(value)) ||
              (subitemlvl2.bwvr && subitemlvl2.bwvr.includes(value))
            ) {
              matchFound = true;
              // results.push(item);
            }
          });
          if (matchFound) results.push(item);
        }
      });
      if (
        (item.vn && item.vn.toLowerCase().includes(value)) ||
        (item.dvn && item.dvn.toLowerCase().includes(value))
      ) {
        results.push(item);
      }
    });
    const uniqueResults = results.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        results.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    const searchResultList = [
      { vst: uniqueResults, data: this.props.vehiclesListData[0].data },
    ];
    this.setState({
      searchResultList,
    });
    sessionStorage.setItem(
      'searchResultList',
      JSON.stringify(searchResultList)
    );
    results = [];
    if (value === null || value === '') {
      sessionStorage.removeItem('searchResultList');
    }
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('srch', value);
      window.history.pushState(null, null, url);
    }
  };

  expandSide = () => {
    this.setState(
      {
        expandSide: !this.state.expandSide,
      },
      () => {
        this.props.setWidth(this.state.expandSide ? 370 : 120);
        // above is to set the variable width of the sidenav

        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          url.searchParams.set('snav', this.state.expandSide);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  copyLinkDiagnosticTab = async obj => {
    copyToClipboard(
      obj.pt,
      'File location path copied to clipboard',
      'Failed to copy File location path to clipboard'
    );
  };

  diagFilterApply = () => {
    const url = new URL(window.location.href);
    this.props.fetchDiagnosticList(
      url.searchParams.get('ft'),
      url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
      url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
      url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
      url.searchParams.get('sdt'),
      url.searchParams.get('edt'),
      'Past 90 days',
      this.props.diagnosticEventData.uniqueKey,
      this.props.diagnosticEventData.uniqueValue,
      url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
      20,
      0,
      !this.props.diagnosticEventData.sortValue &&
        this.props.diagnosticEventData.sortValueKey
        ? 1
        : 0,
      this.props.diagnosticEventData.sortValueKey
        ? this.props.diagnosticEventData.sortValueKey
        : ''
    );
  };

  onHeatmapBlockClickHandler = async event => {
    const url = new URL(window.location.href);
    const selected_Utb = url.searchParams.get('utb');
    let main_signal = '';
    let overlay_signal = '';
    switch (selected_Utb) {
      case 'usgtemp':
        main_signal = 'Temperature';
        overlay_signal = ['Battery Current'];
        break;
      case 'usgsoc':
        main_signal = 'State of Charge';
        overlay_signal = ['Battery Current'];
        break;
      case 'usgpow':
        main_signal = 'Power';
        overlay_signal = null;
        break;
      case 'usgdod':
        main_signal = 'Power';
        overlay_signal = ['State of Charge'];
        break;
      default:
        main_signal = 'Temperature';
        overlay_signal = ['Battery Current'];
        break;
    }

    const incomingDate = event.point.series.yAxis.categories[event.point.y];
    const millisecondsToAdd = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
    const regex = /[a-zA-Z]/;
    if (
      (typeof incomingDate === 'string' && incomingDate.startsWith('Q')) ||
      regex.test(incomingDate) ||
      (typeof incomingDate === 'string' && !isNaN(incomingDate))
    ) {
      const { start: start2, end: end2 } = getStartEnd(
        incomingDate,
        this.props.selectedStartDate,
        this.props.selectedEndDate
      );
      this.setState({ isRedirectUsageHeatmap: true });
      this.setState({ comnDateType: 'Custom' });
      sessionStorage.setItem('samplingType', null);

      await this.props.setMainTrendSignal(main_signal);
      await this.props.addOverlayTrendSignal(overlay_signal);
      const duration = Moment.duration(Moment(end2).diff(Moment(start2)));
      const totaldays = duration.asDays();
      const subNum = totaldays - 90;

      if (incomingDate.startsWith('Q') && totaldays > 90) {
        const tempEndDate = Moment(end2).subtract(subNum, 'days').toDate();
        await this.onApplyClick(start2, tempEndDate, 'custom');
      } else {
        await this.onApplyClick(start2, end2, 'custom');
      }
      this.setState({ isRedirectUsageHeatmap: false });
    } else {
      let tempStartDate =
        event.point.series.yAxis.categories[event.point.y].split('-');
      tempStartDate =
        tempStartDate[2] + '-' + tempStartDate[1] + '-' + tempStartDate[0];

      const { start: start1, end: end1 } = getStartEnd(
        new Date(tempStartDate),
        this.props.selectedStartDate,
        this.props.selectedEndDate
      );

      sessionStorage.setItem('samplingType', null);
      this.setState({ isRedirectUsageHeatmap: true });

      await this.props.setMainTrendSignal(main_signal);
      await this.props.addOverlayTrendSignal(overlay_signal);
      await this.onLoadMoreGraph(
        start1.getTime() + millisecondsToAdd,
        end1.getTime() + millisecondsToAdd,
        true
      );
      this.setState({ isRedirectUsageHeatmap: false });
    }
    overlay_signal && this.props.addOverlayTrendSignal(overlay_signal);
  };

  render() {
    const {
      usgsocShowHeatmap,
      usgtempShowHeatmap,
      usgdodShowHeatmap,
      usgpowShowHeatmap,
    } = this.state;

    this.handleLoading();

    let leftrightBtnDisable = false;
    if (
      this.props.loadingOperatingHoursHistogram === true ||
      this.props.loadingTemperatureHistogram === true ||
      this.props.loadingUsgSocHistogram === true ||
      this.props.loadingUsgPowHistogram === true ||
      this.props.loadingUsgDodHistogram === true ||
      this.props.loadingBatterySOCRange === true ||
      this.props.loadingTimetrend === true
    ) {
      leftrightBtnDisable = true;
    }

    return (
      <React.Fragment>
        <Breadcrumb
          BreadcrumbIcon={<HomeIcon className={''} alt="Home" />}
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          MonitoredCount={this.props.monitoredCountData}
          userConfig={userConfig}
        />
        <Draggable
          left_side_content={
            this.props.showSidebar ? (
              <div className="sideMenucon noLeftpadding noRightpadding">
                {platformVersion() !== '3' ? (
                  <SideMenu
                    screenWidth={this.props.sideWidth}
                    size={this.props.size}
                    handleSearchInputChange={value =>
                      this.handleSearchInputChange(value)
                    }
                    searchQuery={this.state.searchQuery}
                    fleetList={this.props.fleetsListData} // {fleetList}
                    vehicles={
                      this.state.searchResultList.length === 0
                        ? this.props.vehiclesListData
                        : this.state.searchResultList
                    } // {vehicles}
                    onFleetListClick={this.onFleetListClick}
                    selectedFleet={sessionStorage.getItem('selectedFleet')}
                    selectedFleetType={sessionStorage.getItem(
                      'selectedFleetType'
                    )}
                    isGoodSlected={this.state.filterItems.isGoodSlected}
                    isPoorSelected={this.state.filterItems.isPoorSelected}
                    isUnknownSelected={this.state.filterItems.isUnknownSelected}
                    isFavoriteSelected={
                      this.state.filterItems.isFavoriteSelected
                    }
                    selectedSearchKey={this.state.filterItems.selectedSearchKey}
                    onGoodFilterCkick={this.onGoodFilterCkick}
                    onPoorFilterCkick={this.onPoorFilterCkick}
                    onUnknownFilterCkick={this.onUnknownFilterCkick}
                    onFavoriteFilterCkick={this.onFavoriteFilterCkick}
                    handleFilterDropdownChange={this.handleFilterDropdownChange}
                    filterWithFleetName={this.filterWithFleetName}
                    fleettoSearch={this.state.fleettoSearch}
                    filterFleetNameUpdate={this.filterFleetNameUpdate}
                    filterFleetNameEnter={this.filterFleetNameEnter}
                    onVehicleListClick={this.onVehicleListClick}
                    selectedView={'Fleet'}
                    onVehicleProductListClick={this.onVehicleProductListClick}
                    onListSelectionClick={this.onListSelectionClick}
                    listSelection={this.props.selectedList}
                    loadingUnmountedESSList={this.props.loadingUnmountedESSList}
                    unmountedESSListData={this.props.unmountedESSListData}
                    sideMenuFilter={this.sideMenuFilter}
                    filterAvailability={this.state.filterItems.availability}
                    filterReliability={this.state.filterItems.reliability}
                    filterUtilization={this.state.filterItems.utilization}
                    expandSide={this.expandSide}
                  />
                ) : (
                  // show this for side menu v3
                  <SideMenuV3
                    // trainno={selectedTrain}
                    screenWidth={this.props.sideWidth}
                    size={this.props.size}
                    handleSearchInputChange={value =>
                      this.handleSearchInputChange(value)
                    }
                    searchQuery={this.state.searchQuery}
                    fleetList={this.props.fleetsListData} // {fleetList}
                    vehicles={
                      this.state.searchResultList.length === 0
                        ? this.props.vehiclesListData // sideMenuV3Data //this.props.vehiclesListData
                        : this.state.searchResultList
                    } // {vehicles}
                    onFleetListClick={this.onFleetListClick}
                    onVehicleProductPackClick={
                      this.onVehicleProductPackClickRedirectionHandler
                    }
                    selectedFleet={sessionStorage.getItem('selectedFleet')}
                    selectedFleetType={sessionStorage.getItem(
                      'selectedFleetType'
                    )}
                    isGoodSlected={this.state.filterItems.isGoodSlected}
                    isPoorSelected={this.state.filterItems.isPoorSelected}
                    isUnknownSelected={this.state.filterItems.isUnknownSelected}
                    isFavoriteSelected={
                      this.state.filterItems.isFavoriteSelected
                    }
                    selectedSearchKey={this.state.filterItems.selectedSearchKey}
                    onGoodFilterCkick={this.onGoodFilterCkick}
                    onPoorFilterCkick={this.onPoorFilterCkick}
                    onUnknownFilterCkick={this.onUnknownFilterCkick}
                    onFavoriteFilterCkick={this.onFavoriteFilterCkick}
                    handleFilterDropdownChange={this.handleFilterDropdownChange}
                    filterWithFleetName={this.filterWithFleetName}
                    fleettoSearch={this.state.fleettoSearch}
                    filterFleetNameUpdate={this.filterFleetNameUpdate}
                    filterFleetNameEnter={this.filterFleetNameEnter}
                    onVehicleListClick={this.onVehicleListClick}
                    selectedView={'Fleet'}
                    onVehicleProductListClick={this.onVehicleProductListClick}
                    onListSelectionClick={this.onListSelectionClick}
                    listSelection={this.props.selectedList}
                    loadingUnmountedESSList={this.props.loadingUnmountedESSList}
                    unmountedESSListData={this.props.unmountedESSListData}
                    sideMenuFilter={this.sideMenuFilter}
                    filterAvailability={this.state.filterItems.availability}
                    filterReliability={this.state.filterItems.reliability}
                    filterUtilization={this.state.filterItems.utilization}
                    expandSide={this.expandSide}
                  />
                )}
              </div>
            ) : (
              <div className="sideMenucon noLeftpadding noRightpadding">
                <MinimizedSideMenu
                  expandSide={this.expandSide}
                  setShowSidebar={this.props.setShowSidebar}
                  selectedView={'Pack'}
                  onFleetListClick={this.onFleetListClick}
                  onVehicleListClick={this.onVehicleListClick}
                  onVehicleProductListClick={this.onVehicleProductListClick}
                  selectedFleetType={sessionStorage.getItem(
                    'selectedFleetType'
                  )}
                  setSize={this.props.setSize}
                />
              </div>
            )
          }
          right_side_content={
            <div className="detailViewRightinner noRightpadding">
              {this.props.batteryOverallConditionData === null ? (
                <Loading loadintText="Loading data" />
              ) : this.props.batteryOverallConditionData &&
                this.props.batteryOverallConditionData.length > 0 ? (
                platformVersion() !== '3' ? (
                  <TitleBar
                    loadingBatteryOverallCondition={
                      this.props.loadingBatteryOverallCondition
                    }
                    batteryOverallConditionData={
                      this.props.batteryOverallConditionData
                    }
                    pageName="Packview"
                    userConfig={userConfig}
                    redirectToEmailAlert={this.redirectToEmailAlert}
                    searchQuery={this.state.searchQuery}
                  />
                ) : (
                  <TitleBarV3
                    loadingBatteryOverallCondition={
                      this.props.loadingBatteryOverallCondition
                    }
                    batteryOverallConditionData={
                      this.props.batteryOverallConditionData
                    }
                    pageName="Packview"
                    userConfig={userConfig}
                    redirectToEmailAlert={this.redirectToEmailAlert}
                    searchQuery={this.state.searchQuery}
                  />
                )
              ) : (
                'No data found'
              )}
              <div className="date-tabs-container pack_view_event">
                <DateRangePicker
                  onDatePickerButtonClick={this.onDatePickerButtonClick}
                  isMonthlyactive={this.state.isMonthlyactive}
                  isWeeklyactive={this.state.isWeeklyactive}
                  isDailyactive={this.state.isDailyactive}
                  onApplyClick={this.onApplyClick}
                  selectedStartDate={this.props.selectedStartDate}
                  selectedEndDate={this.props.selectedEndDate}
                  selectedTab={this.state.selectedTab}
                  comnDateType={this.state.comnDateType}
                  onLeftRightBtnClick={this.onLeftRightBtnClick}
                  leftrightBtnDisable={leftrightBtnDisable}
                />
                <VehicleProductsTabs
                  redirectToTimetrendFrmDiagnostic={
                    this.redirectToTimetrendFrmDiagnostic
                  }
                  copyLinkDiagnosticTab={this.copyLinkDiagnosticTab}
                  diagFilterApply={this.diagFilterApply}
                  eventListConfig={
                    this.props.alleventListConfig &&
                    this.props.alleventListConfig
                  }
                  eventData={this.props.eventData}
                  setAllEventListData={this.props.setAllEventListData}
                  fetchAllEventList={this.props.fetchAllEventList}
                  usgsocShowHeatmap={usgsocShowHeatmap}
                  usgtempShowHeatmap={usgtempShowHeatmap}
                  usgdodShowHeatmap={usgdodShowHeatmap}
                  usgpowShowHeatmap={usgpowShowHeatmap}
                  handleSwitchChange={this.handleSwitchChange}
                  cellVoltageData={this.props.cellVoltageData}
                  loadingCellvoltage={this.props.loadingCellVoltage}
                  syncGraphData={this.props.syncGraphData}
                  loadingSyncGraph={this.props.loadingSyncGraph}
                  batteryStateOfChargeData={this.props.batteryStateOfChargeData}
                  loadingBatteryStateOfCharge={
                    this.props.loadingBatteryStateOfCharge
                  }
                  batterySOCRangeData={this.props.batterySOCRangeData}
                  loadingBatterySOCRange={this.props.loadingBatterySOCRange}
                  onVehicleProductsViewTabClick={
                    this.onVehicleProductsViewTabClickRedirection
                  }
                  selectedTab={this.state.selectedTab}
                  selectedOverviewTab={this.state.selectedOverviewTab}
                  onProductOverviewTabClick={this.onProductOverviewTabClick}
                  loadingBatteryOverview={this.props.loadingBatteryOverview}
                  batteryOverviewData={this.props.batteryOverviewData}
                  loadingTemperatureHistogram={
                    this.props.loadingTemperatureHistogram
                  }
                  temperatureHistogramData={this.props.temperatureHistogramData}
                  loadingUsgSocHistogram={this.props.loadingUsgSocHistogram}
                  usgSocHistogramData={this.props.usgSocHistogramData}
                  loadingUsgPowHistogram={this.props.loadingUsgPowHistogram}
                  usgPowHistogramData={this.props.usgPowHistogramData}
                  loadingUsgDodHistogram={this.props.loadingUsgDodHistogram}
                  usgDodHistogramData={this.props.usgDodHistogramData}
                  loadingOperatingHoursHistogram={
                    this.props.loadingOperatingHoursHistogram
                  }
                  operatingHoursHistogramData={
                    this.props.operatingHoursHistogramData
                  }
                  usgTempHeatmapData={this.props.usgTempHeatmapData}
                  usgSocHeatmapData={this.props.usgSocHeatmapData}
                  usgDodHeatmapData={this.props.usgDodHeatmapData}
                  usgPowHeatmapData={this.props.usgPowHeatmapData}
                  boxPlotData={this.props.boxPlotData}
                  loadingBoxPlot={this.props.loadingBoxPlot}
                  selectedMainSignal={this.props.syncGraphMain}
                  selectedOverlaySignal={this.props.syncGraphOverlay}
                  selectedMainSignal1={this.props.syncGraphMain1}
                  selectedOverlaySignal1={this.props.syncGraphOverlay1}
                  selectedMainSignal2={this.props.syncGraphMain2}
                  selectedOverlaySignal2={this.props.syncGraphOverlay2}
                  selectedMainSignal3={this.props.syncGraphMain3}
                  selectedOverlaySignal3={this.props.syncGraphOverlay3}
                  handleSyncMainDropdownChange={
                    this.handleSyncMainDropdownChange
                  }
                  handleSyncOverlayDropdownChange={
                    this.handleSyncOverlayDropdownChange
                  }
                  handleSyncToggleChange={this.handleSyncToggleChange}
                  selectedSyncChart={this.props.syncGraphChart}
                  loadingTimetrend={this.props.loadingTimetrend}
                  timetrendData={this.props.timetrendData}
                  clickTrendMainSignal={this.clickTrendMainSignal}
                  clickTrendOverlaySignal={this.clickTrendOverlaySignal}
                  selectedMainTrend={this.props.selectedMainTrend}
                  selectedOverlayTrend={this.props.selectedOverlayTrend}
                  onLoadMoreGraph={this.onLoadMoreGraph}
                  startZoomInterval={this.state.startZoomInterval}
                  endZoomInterval={this.state.endZoomInterval}
                  lockedZoomDuration={this.state.lockedZoomDuration}
                  isButtonDisabled={this.state.isButtonDisabled}
                  loadingEventList={this.props.loadingEventList}
                  eventList={this.props.eventList}
                  loadingBatteryOverallCondition={
                    this.props.loadingBatteryOverallCondition
                  }
                  batteryOverallConditionData={
                    this.props.batteryOverallConditionData
                  }
                  loadingBatteryRel={this.props.loadingBatteryRel}
                  batteryRelData={this.props.batteryRelData}
                  loadingBatteryStr={this.props.loadingBatteryStr}
                  batteryStrData={this.props.batteryStrData}
                  redirectToTimetrend={this.redirectToTimetrend}
                  redirectToTimetdOnStreesEvtClk={
                    this.redirectToTimetdOnStreesEvtClk
                  }
                  handleEventInputChange={this.handleEventInputChange}
                  handleEventRadioChange={this.handleEventRadioChange}
                  setEventUpdateDate={this.setEventUpdateDate}
                  setEventNewPec={this.setEventNewPec}
                  eventFormValues={this.state.eventFormValues}
                  handleClose={this.handleClose}
                  handleEventSubmit={this.handleEventSubmit}
                  loadingSaveEvent={this.props.loadingSaveEvent}
                  saveEventResponce={this.props.saveEventResponce}
                  handleUndoSubmit={this.handleUndoSubmit}
                  loadingUndoEvent={this.props.loadingUndoEvent}
                  undoEventResponce={this.props.undoEventResponce}
                  userConfig={userConfig}
                  selectedView={'VehicleProduct'}
                  vehiclesListData={this.props.vehiclesListData}
                  eventFilterApply={this.eventFilterApply}
                  selectedUsageTab={this.state.selectedUsageTab}
                  selectedUsageRbtn={this.state.selectedUsageRbtn}
                  onUsageTabsClick={this.onUsageTabsClickRedirection}
                  selectedProduct={sessionStorage.getItem(
                    'selectedVehicleProduct'
                  )}
                  sideMenuFilter={this.sideMenuFilter}
                  filterAvailability={this.state.filterItems.availability}
                  filterReliability={this.state.filterItems.reliability}
                  filterUtilization={this.state.filterItems.utilization}
                  expandSide={this.state.expandSide}
                  loadingSOH={this.props.loadingSOH}
                  SOHData={this.props.SOHData}
                  showSidebar={this.props.showSidebar}
                  isResizing={this.props.isResizing}
                  radioValue={this.props.radioValue}
                  setRadioButtonValue={this.props.setRadioButtonValue}
                  heatmap_min_width={window.innerWidth - (this.props.size + 60)}
                  diagnosticList={this.props.diagnosticList}
                  diagnosticListConfig={this.props.diagnosticListConfig}
                  diagnosticEventData={this.props.diagnosticEventData}
                  setDiagnosticEventListData={
                    this.props.setDiagnosticEventListData
                  }
                  fetchDiagnosticList={this.props.fetchDiagnosticList}
                  onHeatmapBlockClickHandler={
                    this.onHeatmapBlockClickHandler
                  }></VehicleProductsTabs>
              </div>
            </div>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loadingEventList: loadingAllEventListSelector(),
  eventList: AlleventListSelector(),
  alleventListConfig: alleventListSelectorConfig(),
  eventData: eventDataSelector(),
  loadingCellVoltage: loadingCellVoltageSelector(),
  loadingHistogram: loadingHistogramSelector(),
  loadingBoxPlot: loadingBoxPlotSelector(),
  cellVoltageData: cellVoltageDataSelector(),
  histogramData: histogramDataSelector(),
  boxPlotData: boxPlotDataSelector(),
  loadingBatteryOverallCondition: loadingBatteryOverallConditionSelector(),
  batteryOverallConditionData: batteryOverallConditionDataSelector(),
  fleetsListData: fleetsListDataSelector(),
  vehiclesListData: vehiclesListDataSelector(),
  vehicleProductListData: vehicleProductListDataSelector(),
  modulesListData: modulesListDataSelector(),
  loadingBatteryStateOfCharge: loadingBatteryStateOfChargeSelector(),
  batteryStateOfChargeData: batteryStateOfChargeDataSelector(),
  monitoredCountData: monitoredCountDataSelector(),
  selectedStartDate: selectedStartDateSelector(),
  selectedEndDate: selectedEndDateSelector(),
  loadingBatterySOCRange: loadingBatterySOCRangeSelector(),
  batterySOCRangeData: batterySOCRangeDataSelector(),
  loadingBatteryOverview: loadingBatteryOverviewSelector(),
  batteryOverviewData: batteryOverviewDataSelector(),
  loadingTemperatureHistogram: loadingTemperatureHistogramSelector(),
  temperatureHistogramData: temperatureHistogramDataSelector(),
  loadingUsgSocHistogram: loadingUsgSocHistogramSelector(),
  usgSocHistogramData: usgSocHistogramDataSelector(),
  loadingUsgPowHistogram: loadingUsgPowHistogramSelector(),
  usgPowHistogramData: usgPowHistogramDataSelector(),
  loadingUsgDodHistogram: loadingUsgDodHistogramSelector(),
  usgDodHistogramData: usgDodHistogramDataSelector(),
  loadingOperatingHoursHistogram: loadingOperatingHoursHistogramSelector(),
  operatingHoursHistogramData: operatingHoursHistogramDataSelector(),
  loadingSyncGraph: loadingSyncGraphSelector(),
  syncGraphData: syncGraphDataSelector(),
  syncGraphMain1: syncGraphMain1Selector(),
  syncGraphMain2: syncGraphMain2Selector(),
  syncGraphMain3: syncGraphMain3Selector(),
  syncGraphOverlay1: syncGraphOverlay1Selector(),
  syncGraphOverlay2: syncGraphOverlay2Selector(),
  syncGraphOverlay3: syncGraphOverlay3Selector(),
  syncGraphMain: syncGraphMainSelector(),
  syncGraphOverlay: syncGraphOverlaySelector(),
  syncGraphChart: syncGraphChartSelector(),
  syncGraphDat: syncGraphDatSelector(),
  syncGraphDatRes: syncGraphDatResSelector(),
  loadingTimetrend: loadingTimetrendSelector(),
  timetrendData: timetrendDataSelector(),
  selectedMainTrend: selectedMainTrendSelector(),
  selectedOverlayTrend: selectedOverlayTrendSelector(),
  ttAvgCellV: ttAvgCellVSelector(),
  ttAvgTemperature: ttAvgTemperatureSelector(),
  ttSOC: ttSOCSelector(),
  ttPower: ttPowerSelector(),
  ttBVoltage: ttBVoltageSelector(),
  ttBCurrent: ttBCurrentSelector(),
  ttBPeakChg: ttBPeakChgSelector(),
  ttBPeakDischg: ttBPeakDischgSelector(),
  ttBConstChg: ttBConstChgSelector(),
  ttBConstDischg: ttBConstDischgSelector(),
  ttBOverloadCntr: ttBOverloadCntrSelector(),
  ttReliability: ttReliabilitySelector(),
  ttFeedFlow: ttFeedFlowSelector(),
  ttOverloadCounter: ttOverloadCounterSelector(),
  ttReturnFlow: ttReturnFlowSelector(),
  ttDiagnostic: ttDiagnosticSelector(),
  loadingBatteryRel: loadingBatteryRelSelector(),
  batteryRelData: batteryRelDataSelector(),
  selectedList: selectedListSelector(),
  loadingBatteryStr: loadingBatteryStrSelector(),
  batteryStrData: batteryStrDataSelector(),
  loadingSaveEvent: loadingSaveEventSelector(),
  saveEventResponce: saveEventResponceSelector(),
  loadingUndoEvent: loadingUndoEventSelector(),
  undoEventResponce: undoEventResponceSelector(),
  loadingUnmountedESSList: loadingUnmountedESSListSelector(),
  unmountedESSListData: unmountedESSListDataSelector(),
  loadingSOH: loadingSOHSelector(),
  SOHData: SOHDataSelector(),
  sideWidth: getSidebarWidth(),
  showSidebar: getShowSidebar(),
  size: getSize(),
  radioValue: RadioValueSelectionData(),
  loadingUsgDodHeatmap: loadingUsgDodHeatmapSelector(),
  usgDodHeatmapData: usgDodHeatmapDataSelector(),
  loadingUsgPowHeatmap: loadingUsgPowHeatmapSelector(),
  usgPowHeatmapData: usgPowHeatmapDataSelector(),
  loadingUsgSocHeatmap: loadingUsgSocHeatmapSelector(),
  usgSocHeatmapData: usgSocHeatmapDataSelector(),
  loadingUsgTempHeatmap: loadingUsgTempHeatmapSelector(),
  usgTempHeatmapData: usgTempHeatmapDataSelector(),
  loadingDiagnosticList: loadingDiagnosticListSelector(),
  diagnosticList: diagnosticListSelector(),
  diagnosticListConfig: diagnosticEventListSelectorConfig(),
  diagnosticEventData: diagnosticEventDataSelector(),
});

const mapDispatchToProps = {
  getBatteryOverallConditionData: fetchBatteryOverallConditionData,
  getCellVoltageData: fetchCellVoltageData,
  getHistogramData: fetchHistogramData,
  getBoxPlotData: fetchBoxPlotData,
  getFleetsList: fetchFleetsList,
  getVehiclesList: fetchVehiclesList,
  getVehicleProductList: fetchVehicleProductList,
  getModuleList: fetchModuleList,
  getBatteryStateOfChargeData: fetchBatteryStateOfChargeData,
  getMonitoredCount: fetchMonitoredCount,
  setDate,
  getBatterySOCRangeData: fetchBatterySOCRangeData,
  clearChartData,
  clearOverviewata,
  getBatteryOverviewData: fetchBatteryOverviewData,
  getTemperatureHistogramData: fetchTemperatureHistogramData,
  fetchUsgSocHistogramData,
  fetchUsgPowHistogramData,
  fetchUsgDodHistogramData,
  fetchUsgDodHeatmapData,
  fetchUsgPowHeatmapData,
  fetchUsgSocHeatmapData,
  fetchUsgTempHeatmapData,
  getOperatingHoursHistogramData: fetchOperatingHoursHistogramData,
  getSyncGraphData: fetchSyncGraphData,
  setMainSignal1,
  setMainSignal2,
  setMainSignal3,
  setOverlaySignal1,
  setOverlaySignal2,
  setOverlaySignal3,
  setMainSignal,
  setOverlaySignal,
  setSyncChart,
  setSyncChartData,
  getDetailViewTimetrend: fetchDetailViewTimetrend,
  setMainTrendSignal,
  addOverlayTrendSignal,
  fetchAllEventList,
  setAllEventListData,
  setTimetrendLoading,
  clearDetailViewTimetrend,
  clearEventList,
  clearVehicleViewData,
  getBatteryRelData: fetchBatteryRelData,
  setListSelection,
  getBatteryStrData: fetchBatteryStrData,
  saveEvent,
  undoEvent,
  clearSaveEventResponce,
  clearUndoEventResponce,
  fetchUnmountedESSList,
  fetchSOHData,
  setWidth: setSidebarWidth,
  setShowSidebar,
  setRadioButtonValue,
  fetchMessages: fetchActiveMessages,
  setDiagnosticEventListData,
  fetchDiagnosticList,
  setSize,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackView);
