import React from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  Converter_d,
  Converter_e,
  Ess_d,
  Ess_e,
  FleetIcon,
} from '../../assets/images';
import { tooltipData } from '../../utils/tooltipData';
import { DesignBudgetInput } from '../DesignBudgetInput';
import { GradientBar } from '../GradientBar/GradientBar';
import Help from '../Help/Help';
import './styles.scss';
import CollapsableSection from '../CollapsableSection';
import { SohstressFactor } from '../SohStressFactor';

class ProductSetupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      visible: false,
      selectedTab: 'fleetsetup-tab',
      isFleetNameUsed: false,
      selectedProductTab: 'product-ess-tab',
    };
  }

  getDesignedTroughput = (lftp, rv, dlft) => {
    let maxTroughput = null;
    // (((lifetimeTroughput*ratedVoltage)/1000))/designedlifetime)/12
    maxTroughput = (lftp * rv) / 1000;
    maxTroughput = maxTroughput / dlft;
    maxTroughput = maxTroughput / 12;
    return maxTroughput;
  };

  checkFleetNameUsed = e => {
    this.props.handleFleetInputChange(e);
    let isUsed = false;

    if (this.props.fleetFormValues.isUpdate === true) {
      isUsed = this.props.setupFleetList[0].fleets.some(
        element =>
          element.displayFleetName.toLowerCase() ===
          e.target.value.toLowerCase()
      );
    } else {
      isUsed = this.props.usedFleetNameList[0].fleets.some(
        element =>
          element.displayFleetName.toLowerCase() ===
          e.target.value.toLowerCase()
      );
    }

    if (isUsed === true) {
      this.setState({ isFleetNameUsed: true });
    } else {
      this.setState({ isFleetNameUsed: false });
    }
  };

  handleSubmit = async e => {
    const form = e.currentTarget;
    if (e.nativeEvent.submitter.id === 'draftsave') {
      // await this.props.handleIsDraftSave();
      this.props.handleProdSubmit(e);
      this.setState({ validated: false, visible: true });
    } else {
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ validated: true });
      } else if (form.checkValidity() === true) {
        this.props.handleProdSubmit(e);
        this.setState({ validated: false, visible: true });
        // setTimeout(() => {
        //   this.setState({
        //     visible: false,
        //   });
        // }, 7000);
      }
    }
  };

  render() {
    const {
      fleetTab: {
        fleetName: { tooltip: fleetName },
        fleetId: { tooltip: fleetId },
        designedThroughput: { tooltip: designedThroughput },
        maxThroughput: { tooltip: maxThroughput },
        conectivityType: { tooltip: conectivityType },
        platform: { tooltip: platform },
        fleetDataPath: { tooltip: fleetDataPath },
      },
      vehicleTab: {
        vehicleType: { tooltip: vehicleType },
        reliabilityThreshold: { tooltip: reliabilityThreshold },
        utilizationThreshold: { tooltip: utilizationThreshold },
      },
      productTab: {
        // basicInformation: {
        //   batterySystemPrefix: { tooltip: batterySystemPrefix },
        //   packPrefix: { tooltip: packPrefix },
        // },
        specificationHeading: {
          designedLifetime: { tooltip: designedLifetimeTooltip },
          lifetimeThroughput: { tooltip: lifetimeThroughputTooltip },
          capacityAfterDesignedLifetime: { tooltip: capacityTooltip },
          maximalCurrent: { tooltip: maximalCurrentTooltip },
        },
        thresholdSettings: {
          reliabilityThresholdForNotReliableState: {
            tooltip: reliabilityThresholdForNotReliableState,
          },
          utilizationThresholdForAboveNormalUtilizationState: {
            tooltip: utilizationThresholdForAboveNormalUtilizationState,
          },
          normalTemperatureRange: { tooltip: normalTemperatureRange },
          thresholdForSoC: { tooltip: thresholdForSoC },
          thresholdForCycles: { tooltip: thresholdForCycles },
          thresholdForCRate: { tooltip: thresholdForCRate },
        },
        converterSettings: {
          converterPrefix: { tooltip: converterPrefix },
          converterEnable: { tooltip: converterEnable },
        },
      },
    } = tooltipData;
    return (
      <>
        <Row className="formfleetsetupview" id="formprductsetup">
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmit}>
            <Row>
              <Col md={12} className="containertitlebar">
                <div>
                  <FleetIcon />
                  <span className="text">
                    {this.props.fleetFormValues.displayFleetName}
                  </span>
                </div>
                <span>
                  <Button
                    id="draftsave"
                    type="submit"
                    className="smallSilverButton btn btn-primary btn-sm"
                    disabled={this.state.isFleetNameUsed}>
                    {this.props.loadingproductSetup === true ||
                    this.props.loadingproductInfoSetup === true
                      ? ' Saving data'
                      : 'Save as draft'}
                  </Button>
                  <button
                    type="button"
                    className="smallSilverButton btn  btn-sm"
                    onClick={this.props.handleProdReset}
                    disabled={this.state.isFleetNameUsed}>
                    Reset
                  </button>
                  <Button
                    id="save"
                    type="submit"
                    className="smallButton btn btn-primary btn-sm"
                    disabled={this.state.isFleetNameUsed}>
                    {this.props.loadingproductSetup === true ||
                    this.props.loadingproductInfoSetup === true
                      ? ' Saving data'
                      : 'Save'}
                  </Button>
                </span>
              </Col>
            </Row>
            {this.state.validated && (
              <Row className="validationfailed">
                <Col md={12}>
                  *One or more manadatory field is blank in fleet or vehicle or
                  products tab.
                </Col>
              </Row>
            )}
            <Col md={12} className="noLeftpadding noRightpadding">
              <Tabs
                id="setup-page"
                className="prodsetup-tab"
                activeKey={this.state.selectedTab}
                onSelect={tab => this.setState({ selectedTab: tab })}>
                <Tab
                  eventKey="fleetsetup-tab"
                  title={<span className="tabtitle">Fleet</span>}>
                  <div className="setup-tab-content" id="fleet-fields">
                    <div className="frmsection1">
                      <div className="frmtitle">Basic information</div>
                      <Row className="frmcontrols">
                        <Form.Group as={Col}>
                          <Form.Label>
                            Fleet name{' '}
                            <Help
                              helpContent={fleetName}
                              placement={'bottom'}
                            />
                          </Form.Label>

                          <Form.Control
                            autoComplete="off"
                            type="text"
                            name="fleetName"
                            // onChange={this.props.handleFleetInputChange}
                            // onChange={this.checkFleetNameUsed}
                            defaultValue={this.props.fleetFormValues.fleetName}
                            hidden
                          />
                          <Form.Control
                            autoComplete="off"
                            required
                            type="text"
                            name="displayFleetName"
                            className="fleetNameinput"
                            // onChange={this.props.handleFleetInputChange}
                            onChange={this.checkFleetNameUsed}
                            value={this.props.fleetFormValues.displayFleetName}
                          />

                          <Form.Control.Feedback type="invalid">
                            Please provide fleet name.
                          </Form.Control.Feedback>
                          <div className="alreadyusedmsg">
                            {this.state.isFleetNameUsed === true
                              ? 'Fleet name already in use. Try another.'
                              : ''}
                          </div>
                        </Form.Group>

                        <Form.Group as={Col} className="plusbtncon">
                          <button
                            type="button"
                            id="plusbtn"
                            className="smallSilverButton btn  btn-sm">
                            +
                          </button>
                        </Form.Group>

                        <Form.Group as={Col} md={6}>
                          <Form.Label>
                            Fleet ID{' '}
                            <Help helpContent={fleetId} placement={'bottom'} />
                          </Form.Label>
                          <Form.Control
                            autoComplete="off"
                            required
                            type="text"
                            name="fleetId"
                            disabled
                            readOnly
                            onChange={this.props.handleFleetInputChange}
                            value={this.props.fleetFormValues.fleetId}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide fleet ID.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="frmcontrols">
                        <Form.Group as={Col} md={3}>
                          <Form.Check
                            type="switch"
                            id="webportallive-switch"
                            label="Web Portal Live"
                            // className="webportalliveswitch"
                            name="webPortalLive"
                            onChange={this.props.handleFleetInputChange}
                            checked={this.props.fleetFormValues.webPortalLive}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                          <Form.Check
                            type="switch"
                            id="dataapilive-switch"
                            label="Data API Live"
                            // className="dataapiliveswitch"
                            name="dataApiLive"
                            onChange={this.props.handleFleetInputChange}
                            checked={this.props.fleetFormValues.dataApiLive}
                          />
                        </Form.Group>
                      </Row>
                      <div className="frmtitle">Fleet Statistics setup</div>
                      <div>
                        <GradientBar
                          height={20}
                          barwidth={50}
                          colorA={
                            this.props.fleetFormValues.firstLineStartColor
                          }
                          colorB={this.props.fleetFormValues.firstLineEndColor}
                          colorC={
                            this.props.fleetFormValues.secondLineStartColor
                          }
                          colorD={this.props.fleetFormValues.secondLineEndColor}
                          leftValue={0 + ' kWh'}
                          centerValue={
                            this.props.fleetFormValues.designedThroughput +
                            ' kWh'
                          }
                          rightValue={
                            this.props.fleetFormValues.maxThroughput + ' kWh'
                          }
                        />
                      </div>

                      <Row className="frmcontrols">
                        <Form.Group as={Col} md={3}>
                          <Form.Label>First Line Start Color</Form.Label>

                          <InputGroup>
                            <Form.Control
                              type="color"
                              className="norightborder"
                              name="firstLineStartColor"
                              value={
                                this.props.fleetFormValues.firstLineStartColor
                              }
                              // defaultValue={
                              //   this.props.fleetFormValues.firstLineStartColor
                              // }
                              onChange={this.props.handleFleetInputChange}
                              required
                            />
                            <InputGroup.Text type="text">
                              {this.props.fleetFormValues.firstLineStartColor}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please provide valid color.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                          <Form.Label>First Line End Color</Form.Label>

                          <InputGroup>
                            <Form.Control
                              type="color"
                              className="norightborder"
                              name="firstLineEndColor"
                              value={
                                this.props.fleetFormValues.firstLineEndColor
                              }
                              onChange={this.props.handleFleetInputChange}
                              required
                            />
                            <InputGroup.Text type="text">
                              {this.props.fleetFormValues.firstLineEndColor}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please provide valid color.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                          <Form.Label>Second Line Start Color</Form.Label>

                          <InputGroup>
                            <Form.Control
                              type="color"
                              className="norightborder"
                              name="secondLineStartColor"
                              value={
                                this.props.fleetFormValues.secondLineStartColor
                              }
                              onChange={this.props.handleFleetInputChange}
                              required
                            />
                            <InputGroup.Text type="text">
                              {this.props.fleetFormValues.secondLineStartColor}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please provide valid color.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                          <Form.Label>Second Line End Color</Form.Label>

                          <InputGroup>
                            <Form.Control
                              type="color"
                              className="norightborder"
                              name="secondLineEndColor"
                              value={
                                this.props.fleetFormValues.secondLineEndColor
                              }
                              onChange={this.props.handleFleetInputChange}
                              required
                            />
                            <InputGroup.Text type="text">
                              {this.props.fleetFormValues.secondLineEndColor}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please provide valid color.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <Row className="frmcontrols">
                        <Form.Group as={Col} md={6}>
                          <Form.Label>
                            Designed Throughput{' '}
                            <Help
                              helpContent={designedThroughput}
                              placement={'left'}
                            />
                          </Form.Label>

                          <InputGroup>
                            <Form.Control
                              type="number"
                              className="norightborder"
                              name="designedThroughput"
                              value={
                                this.props.fleetFormValues.designedThroughput ||
                                0
                              }
                              disabled
                              onChange={this.props.handleFleetInputChange}
                            />
                            <InputGroup.Text>kWh</InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please provide valid designed throughput.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                          <Form.Label>
                            Max Throughput{' '}
                            <Help
                              helpContent={maxThroughput}
                              placement={'left'}
                            />
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              className="norightborder"
                              name="maxThroughput"
                              value={this.props.fleetFormValues.maxThroughput}
                              onChange={this.props.handleFleetInputChange}
                              // min={
                              //   this.props.fleetFormValues.designedThroughput ||
                              //   0
                              // }
                              // max={Number.MAX_SAFE_INTEGER}
                              required
                            />
                            <InputGroup.Text>kWh</InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please provide valid max throughput.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <div className="frmtitle">Connectivity info</div>

                      <Row className="frmcontrols">
                        <Form.Group as={Col} md={6}>
                          <Form.Label>
                            Connectivity type{' '}
                            <Help
                              helpContent={conectivityType}
                              placement={'left'}
                            />
                          </Form.Label>
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            className="custom-select"
                            name="fleetConType"
                            onChange={this.props.handleFleetInputChange}
                            value={this.props.fleetFormValues.fleetConType}
                            disabled={
                              !!(
                                this.props.fleetFormValues.isUpdate &&
                                !this.props.fleetFormValues.isDraft
                              )
                            }>
                            <option value="">Please select</option>
                            {/* {this.props.fleetSetupData.ck.map((item, index) => (
                              <option value={item.ct}>{item.ct}</option>
                            ))} */}

                            {this.props.fleetSetupData &&
                              this.props.fleetSetupData.ck.map(
                                (item, index) => (
                                  <option value={item.vl} key={item.vl}>
                                    {item.vl}
                                  </option>
                                )
                              )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide connectivity type.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                          <Form.Label>
                            Platform{' '}
                            <Help helpContent={platform} placement={'left'} />
                          </Form.Label>
                          <Form.Control
                            disabled={
                              !!(
                                this.props.fleetFormValues.isUpdate &&
                                !this.props.fleetFormValues.isDraft
                              )
                            }
                            required
                            as="select"
                            type="select"
                            className="custom-select"
                            name="platform"
                            onChange={this.props.handleFleetInputChange}
                            value={this.props.fleetFormValues.platform}>
                            {/* <option value="">Please select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option> */}

                            <option value="">Please select</option>
                            {/* {this.props.fleetSetupData.ck.map((item, index) =>
                              item.ct ===
                              this.props.fleetFormValues.fleetConType
                                ? item.cp.map((subitem) => (
                                    <option value={subitem.pt}>
                                      {subitem.pt}
                                    </option>
                                  ))
                                : null
                            )} */}
                            {this.props.fleetSetupData &&
                              this.props.fleetSetupData.pt.map(
                                (item, index) => (
                                  <option value={item.vl} key={item.vl}>
                                    {item.vl}
                                  </option>
                                )
                              )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please select platform version number.
                          </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group as={Col} md={4}>
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            autoComplete="off"
                            required
                            type="text"
                            name="username"
                            onChange={this.props.handleFleetInputChange}
                            value={this.props.fleetFormValues.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide user name.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={4}>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            autoComplete="off"
                            required
                            type="password"
                            name="password"
                            onChange={this.props.handleFleetInputChange}
                            value={this.props.fleetFormValues.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide password.
                          </Form.Control.Feedback>
                        </Form.Group> */}
                      </Row>
                      {/* <Row className="frmcontrols">
                      <Form.Group as={Col}>
                          <Form.Label>Connectivity key path</Form.Label>
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            className="custom-select"
                            name="connectivityKeyPath"
                            onChange={this.props.handleFleetInputChange}
                            value={
                              this.props.fleetFormValues.connectivityKeyPath
                            }
                            // id={
                            //   this.props.fleetFormValues.connectivityKeyPath !==
                            //     "" &&
                            //   this.props.fleetFormValues.connectivityKeyPath !==
                            //     undefined
                            //     ? "validdataPath"
                            //     : "uncheckedPath"
                            // }
                            disabled={
                              this.props.fleetFormValues.isUpdate ? true : false
                            }
                          >
                            <option value="">Please select</option>
                            {this.props.fleetFormValues
                              .connectivityKeyPathList && (
                              <option
                                value={
                                  this.props.fleetFormValues
                                    .connectivityKeyPathList.kp
                                }
                              >
                                {
                                  this.props.fleetFormValues
                                    .connectivityKeyPathList.kp
                                }
                              </option>
                            )}

                            {/* <option value="Key vault path1">
                              Key vault path1
                            </option>
                            <option value="Key vault path2">
                              Key vault path2
                            </option> */}

                      {/* {this.props.fleetFormValues.fleetConType &&
                              dataPathList[
                                this.props.fleetFormValues.fleetConType
                              ].map((dataPath, indx) => {
                                return (
                                  <option key={indx} value={dataPath}>
                                    {dataPath}
                                  </option>
                                );
                              })} */}
                      {/* </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide connectivity key path.
                          </Form.Control.Feedback>
                        </Form.Group>
                        </Row> */}
                      <Row className="frmcontrols">
                        <Form.Group as={Col}>
                          <Form.Label>
                            Fleet data path{' '}
                            <Help
                              helpContent={fleetDataPath}
                              placement={'top'}
                            />
                          </Form.Label>

                          <Form.Control
                            required
                            type="text"
                            name="fleetDPath"
                            onChange={this.props.handleFleetInputChange}
                            autoComplete="off"
                            value={
                              this.props.fleetDataPathValidate === false
                                ? ''
                                : this.props.fleetFormValues.fleetDPath
                            }
                            // id={
                            //   this.props.fleetFormValues.connectivityKeyPath !==
                            //     "" &&
                            //   this.props.fleetFormValues.connectivityKeyPath !==
                            //     undefined
                            //     ? "validfleetdataPath"
                            //     : "uncheckedfleetPath"
                            // }
                            id={
                              this.props.fleetDataPathValidate === false
                                ? 'invalidfleetdataPath'
                                : this.props.fleetFormValues.isUpdate ||
                                    this.props.fleetDataPathValidate
                                  ? 'validfleetdataPath'
                                  : this.props.fleetDataPathValidate === null
                                    ? 'uncheckedfleetPath'
                                    : 'uncheckedfleetPath'
                            }
                            disabled={
                              !!(
                                this.props.fleetFormValues.isUpdate &&
                                !this.props.fleetFormValues.isDraft
                              )
                            }
                            isInvalid={
                              this.props.fleetDataPathValidate === false
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.props.fleetDataPathValidate === false
                              ? 'Invalid fleet data path for selected connectivity type.'
                              : 'Please provide valid fleet data path.'}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="updatebtncon">
                          <button
                            type="button"
                            id="uptadepathbtn"
                            className="smallSilverButton btn  btn-sm"
                            onClick={this.props.handleFleetDataPath}
                            disabled={
                              !!(
                                (!this.props.fleetFormValues.isDraft &&
                                  this.props.fleetFormValues.isUpdate) ||
                                this.props.fleetFormValues.fleetConType ===
                                  '' ||
                                this.props.loadingFleetDataPathValidate === true
                              )
                            }>
                            {this.props.loadingFleetDataPathValidate ===
                            true ? (
                              <Spinner animation="border" />
                            ) : (
                              'Update data'
                            )}
                          </button>
                        </Form.Group>
                      </Row>
                      {/* <Row className="frmcontrols">
                        <Form.Group as={Col} md={12}>
                          <Form.Label className="optional">
                            Link to Traction database
                          </Form.Label>
                          <Form.Control
                            autoComplete="off"
                            disabled
                            // required
                            type="text"
                            placeholder="n/a"
                            name="fleetLinkDb"
                            onChange={this.props.handleFleetInputChange}
                            value={this.props.fleetFormValues.fleetLinkDb}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide link to Traction database.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row> */}
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="vehiclesetup-tab"
                  title={<span className="tabtitle">Vehicle</span>}>
                  <div className="setup-tab-content">
                    <div className="vehiclesection1">
                      <Row className="frmcontrols">
                        <Form.Group as={Col} md={3}>
                          <Form.Label>
                            Vehicle type{' '}
                            <Help
                              helpContent={vehicleType}
                              placement={'bottom'}
                            />
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="select"
                            className="custom-select prodType-select"
                            name="vehType"
                            placeholder="Please select"
                            // disabled
                            onChange={this.props.handleVehicleInputChange}
                            value={this.props.vehicleSetupFormValues.vehType}
                            required>
                            <option value="">Please select</option>
                            <option value="ebuss">eBuss</option>
                            <option value="train">Train</option>
                            <option value="truck">Truck</option>
                          </Form.Control>
                        </Form.Group>
                      </Row>
                    </div>
                    <div className="vehiclesection2">
                      <Form.Group as={Row} className="frmcontrols flextab">
                        <Form.Label column sm="8" className="removepadding">
                          <div className="forteenpx">
                            Vehicle prefix{' '}
                            <Help
                              helpContent={'vehicle prefix is required'}
                              placement={'bottom'}
                            />
                          </div>
                        </Form.Label>
                        <Col sm="4" className="">
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="text"
                              name="vehiclePrefix"
                              value={
                                this.props.vehicleSetupFormValues.vehiclePrefix
                              }
                              // disabled={
                              //   this.props.fleetFormValues.isUpdate &&
                              //   !this.props.fleetFormValues.isDraft
                              //     ? true
                              //     : false
                              // }
                              onChange={this.props.handleVehicleInputChange}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide Vehicle prefix.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="frmcontrols flextab">
                        <Form.Label column sm="8" className="removepadding">
                          <div className="forteenpx">
                            Reliability threshold{' '}
                            <Help
                              helpContent={reliabilityThreshold}
                              placement={'bottom'}
                            />
                          </div>
                          {/* <span className="twelvepx">
                            A threshold &ge;25% means that if the products
                            within the vehicle exceed 25% of failure time based
                            on the total operations time, the vehicle will be
                            shown in a red reliability state.
                          </span> */}
                        </Form.Label>
                        <Col sm="4" className="">
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="number"
                              className="norightborder"
                              name="relThres"
                              value={this.props.vehicleSetupFormValues.relThres}
                              onChange={this.props.handleVehicleInputChange}
                              pattern="^\d*(\.\d{0,2})?$"
                              onWheel={e => e.target.blur()}
                              required
                              disabled={
                                !!(
                                  this.props.fleetFormValues.isUpdate &&
                                  !this.props.fleetFormValues.isDraft
                                )
                              }
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please provide threshold.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="frmcontrols flextab">
                        <Form.Label column sm="8" className="removepadding">
                          <div className="forteenpx">
                            Utilization threshold{' '}
                            <Help
                              helpContent={utilizationThreshold}
                              placement={'bottom'}
                            />
                          </div>
                          {/* <span className="twelvepx">
                            A threshold &ge;25% means that if the products
                            within the vehicle exceed 25% of time above normal
                            utilization, the vehicle will be shown in a red
                            utilization state.
                          </span> */}
                        </Form.Label>
                        <Col sm="4" className="">
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="number"
                              className="norightborder"
                              name="utiThres"
                              value={this.props.vehicleSetupFormValues.utiThres}
                              onChange={this.props.handleVehicleInputChange}
                              pattern="^\d*(\.\d{0,2})?$"
                              onWheel={e => e.target.blur()}
                              required
                              disabled={
                                !!(
                                  this.props.fleetFormValues.isUpdate &&
                                  !this.props.fleetFormValues.isDraft
                                )
                              }
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please provide threshold.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="prodsetup-tab"
                  title={<span className="tabtitle">Products</span>}>
                  <Tabs
                    id="product-tab"
                    className="productessconverter-tab"
                    activeKey={this.state.selectedProductTab}
                    onSelect={tab =>
                      this.setState({ selectedProductTab: tab })
                    }>
                    <Tab
                      eventKey="product-ess-tab"
                      title={
                        <span className="tabIcon" id="product-ess-info">
                          {this.state.selectedProductTab ===
                          'product-ess-tab' ? (
                            <Ess_e />
                          ) : (
                            <Ess_d />
                          )}
                          <span className="tabtitle"> ESS</span>
                        </span>
                      }>
                      <div className="setup-tab-content">
                        {/* <div className="prodsection1">
                      <Row className="frmcontrols">
                        <Form.Group as={Col} md={5}>
                          <Form.Label>Select product </Form.Label>
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            className="custom-select prodType-select"
                            name="prodType"
                            disabled
                            // onChange={this.props.handleFleetInputChange}
                            // value={this.props.fleetFormValues.fleetConType}
                          >
                            <option value="">Please select</option>
                            <option value="SFTP">ESS</option>
                            <option value="HTTP">Converter</option>
                            <option value="MQTT">Other product</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                          <Form.Label className="blank-label"> </Form.Label>
                          <Button
                            variant="secondary"
                            className="smallSilverButton addprodsetupbtn"
                            disabled
                          >
                            Add product
                          </Button>
                        </Form.Group>
                      </Row>
                    </div> */}

                        <div className="prodsection2">
                          {/* <Accordion
                        allowZeroExpanded
                        className="accordians-prodsetup"
                        preExpanded={["ess-setup"]}
                      >
                        <AccordionItem uuid={"ess-setup"}>
                          <AccordionItemHeading className={""}>
                            <AccordionItemButton>
                              {"New ESS"}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel> */}
                          <CollapsableSection title={'Basic information'}>
                            <Form.Group
                              as={Row}
                              className="frmcontrols hidebotmborder">
                              <Form.Label column sm="2">
                                Battery system prefix{' '}
                                {/* <Help helpContent={batterySystemPrefix} placement={"bottom"} /> */}
                              </Form.Label>
                              <Col sm="4">
                                <Form.Control
                                  type="text"
                                  name="batteryPrefix"
                                  value={
                                    this.props.productSetupFormValues
                                      .batteryPrefix === ''
                                      ? this.props.fleetFormValues.platform ===
                                          '3' &&
                                        this.props.productSetupFormValues
                                          .batteryPrefix === ''
                                        ? 'BS'
                                        : 'ESS'
                                      : this.props.productSetupFormValues
                                          .batteryPrefix
                                  }
                                  // disabled={
                                  //   this.props.fleetFormValues.isUpdate &&
                                  //   !this.props.fleetFormValues.isDraft
                                  //     ? true
                                  //     : false
                                  // }
                                  onChange={this.props.handleProdInputChange}
                                  required
                                  // pattern="^\d*(\.\d{0,2})?$"
                                  // onWheel={(e) => e.target.blur()}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please provide battery system prefix.
                                </Form.Control.Feedback>
                              </Col>
                              <Form.Label column sm="2">
                                Rated voltage
                              </Form.Label>
                              <Col sm="4">
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="ratedVoltage"
                                    value={
                                      this.props.productSetupFormValues
                                        .ratedVoltage
                                    }
                                    disabled={
                                      this.props.fleetFormValues.isUpdate
                                    }
                                    onChange={this.props.handleProdInputChange}
                                    required
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                  />
                                  <InputGroup.Text>V</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide rated voltage.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                              {/* <Form.Label column sm="2">
                                Rated capacity
                              </Form.Label> */}
                              {/* <Col sm="4">
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="ratedCapacity"
                                    value={
                                      this.props.productSetupFormValues
                                        .ratedCapacity
                                    }
                                    disabled={
                                      this.props.fleetFormValues.isUpdate
                                        ? true
                                        : false
                                    }
                                    onChange={this.props.handleProdInputChange}
                                    required
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={(e) => e.target.blur()}
                                  />
                                  <InputGroup.Text>Ah</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide rated capacity.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col> */}
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="frmcontrols hidebotmborder">
                              {this.props.fleetFormValues.platform === '3' && (
                                <>
                                  <Form.Label column sm="2">
                                    Battery pack prefix{' '}
                                    {/* <Help
                                helpContent={packPrefix}
                                placement={"bottom"}
                              /> */}
                                  </Form.Label>
                                  <Col sm="4">
                                    <Form.Control
                                      type="text"
                                      name="packPrefix"
                                      value={
                                        this.props.productSetupFormValues
                                          .packPrefix
                                      }
                                      disabled={
                                        !!(
                                          this.props.fleetFormValues.isUpdate &&
                                          !this.props.fleetFormValues.isDraft
                                        )
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      Please provide pack prefix.
                                    </Form.Control.Feedback>
                                  </Col>
                                  <Form.Label column sm="2">
                                    Number of BS per Vehicle{' '}
                                    {/* <Help
                                helpContent={packPrefix}
                                placement={"bottom"}
                              /> */}
                                  </Form.Label>
                                  <Col sm="4">
                                    <Form.Control
                                      type="text"
                                      name="numberOfBatterySystem"
                                      value={
                                        this.props.productSetupFormValues
                                          .numberOfBatterySystem || 1
                                      }
                                      disabled={
                                        !!(
                                          this.props.fleetFormValues.isUpdate &&
                                          !this.props.fleetFormValues.isDraft
                                        )
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      Please provide pack prefix.
                                    </Form.Control.Feedback>
                                  </Col>
                                  <Form.Label column sm="2">
                                    Number of Strings per BS{' '}
                                    {/* <Help
                                helpContent={packPrefix}
                                placement={"bottom"}
                              /> */}
                                  </Form.Label>
                                  <Col sm="4">
                                    <Form.Control
                                      type="text"
                                      name="numberOfStrings"
                                      value={
                                        this.props.productSetupFormValues
                                          .numberOfStrings || 1
                                      }
                                      disabled={
                                        !!(
                                          this.props.fleetFormValues.isUpdate &&
                                          !this.props.fleetFormValues.isDraft
                                        )
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      Please provide pack prefix.
                                    </Form.Control.Feedback>
                                  </Col>
                                </>
                              )}
                              {/* <Form.Label
                                column
                                sm="2"
                                className="addbotmborder"
                              >
                                Rated energy
                              </Form.Label>
                              <Col sm="4" className="addbotmborder">
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="text"
                                    className="norightborder"
                                    name="ratedEnergy"
                                    disabled={
                                      this.props.fleetFormValues.isUpdate
                                        ? true
                                        : false
                                    }
                                    value={
                                      this.props.productSetupFormValues
                                        .ratedEnergy
                                    }
                                    onChange={this.props.handleProdInputChange}
                                    required
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={(e) => e.target.blur()}
                                  />
                                  <InputGroup.Text>kWh</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide rated energy.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col> */}
                            </Form.Group>
                            {/* <Form.Label column sm="2">
                          ESS article number
                        </Form.Label>
                        <Col sm="4">
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="text"
                              name="essArticleNumber"
                              value={
                                this.props.productSetupFormValues
                                  .essArticleNumber
                              }
                              disabled={
                                this.props.fleetFormValues.isUpdate
                              }
                              onChange={this.props.handleProdInputChange}
                              required
                              // pattern="^\d*(\.\d{0,2})?$"
                              // onWheel={(e) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide ess article number.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col> */}
                          </CollapsableSection>
                          <CollapsableSection title="Specification">
                            <Row
                              className="frmcontrols"
                              style={{ marginLeft: '20px' }}>
                              <Form.Group as={Col} md={3}>
                                <Form.Label className="oneline">
                                  Designed lifetime{' '}
                                  <Help
                                    helpContent={designedLifetimeTooltip}
                                    placement={'bottom'}
                                  />
                                </Form.Label>
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="dSpan"
                                    value={
                                      this.props.productSetupFormValues.dSpan
                                    }
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                    onChange={this.props.handleProdInputChange}
                                    required
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                  />
                                  <InputGroup.Text>Years</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide threshold.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md={3}>
                                <Form.Label className="oneline">
                                  Lifetime throughput
                                  <Help
                                    placement={'bottom'}
                                    helpContent={lifetimeThroughputTooltip}
                                  />
                                </Form.Label>
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="throughputLifetime"
                                    value={
                                      this.props.productSetupFormValues
                                        .throughputLifetime
                                    }
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                    onChange={this.props.handleProdInputChange}
                                    required
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                  />
                                  <InputGroup.Text>Ah</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide threshold.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md={3}>
                                <Form.Label className="oneline">
                                  Capacity after designed lifetime{' '}
                                  <Help
                                    placement={'bottom'}
                                    helpContent={capacityTooltip}
                                  />
                                </Form.Label>
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="capacityAfterDesignedLifetime"
                                    value={
                                      this.props.productSetupFormValues
                                        .capacityAfterDesignedLifetime
                                    }
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                    onChange={this.props.handleProdInputChange}
                                    required
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                  />
                                  <InputGroup.Text>%</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide threshold.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md={3}>
                                <Form.Label className="oneline">
                                  Maximal current{' '}
                                  <Help
                                    placement={'left'}
                                    helpContent={maximalCurrentTooltip}
                                  />
                                </Form.Label>
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="maxCurrent"
                                    value={
                                      this.props.productSetupFormValues
                                        .maxCurrent
                                    }
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                    onChange={this.props.handleProdInputChange}
                                    required
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                  />
                                  <InputGroup.Text>A</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide maximal current.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Row>
                            <div
                              className="frmsubtitle"
                              style={{
                                paddingBottom: '10px',
                                paddingTop: '10px',
                              }}>
                              Threshold settings{' '}
                            </div>
                            <div className="twelvepx">
                              The thresholds below can be modified. The
                              modifications become active only for future data.
                              History data is not affected.
                            </div>
                            <Form.Group
                              as={Row}
                              className="frmcontrols flextab"
                              style={{ marginLeft: '30px' }}>
                              <Form.Label
                                column
                                sm="8"
                                className="removepadding">
                                <div className="forteenpx">
                                  Reliability threshold for not reliable state{' '}
                                  <Help
                                    helpContent={
                                      reliabilityThresholdForNotReliableState
                                    }
                                    placement={'left'}
                                  />
                                </div>
                                {/* <span className="twelvepx">
                            Above this defined threshold, the product is marked
                            as "not reliable"; A threshold of "35%" means that
                            if the product shows failure state in more than 35%
                            of the operational time, the product is set to "not
                            reliable". The reliability status includes the last
                            30 days of data.
                          </span> */}
                              </Form.Label>
                              <Col sm="4" className="">
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="relThresNotRelStat"
                                    value={
                                      this.props.productSetupFormValues
                                        .relThresNotRelStat
                                    }
                                    required
                                    onChange={this.props.handleProdInputChange}
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                  />
                                  <InputGroup.Text>%</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide threshold.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="frmcontrols flextab"
                              style={{ marginLeft: '30px' }}>
                              <Form.Label
                                column
                                sm="8"
                                className="removepadding">
                                <div className="forteenpx">
                                  Utilization threshold for above normal
                                  utilization state{' '}
                                  <Help
                                    helpContent={
                                      utilizationThresholdForAboveNormalUtilizationState
                                    }
                                    placement={'left'}
                                  />
                                </div>
                                {/* <span className="twelvepx">
                            Above normal Utilization"; A threshold of "35%"
                            means that if one or more of the KPIs below is
                            exceeding the utilization threshold more than 35% of
                            the operational time, the product is set to "Above
                            normal Utilization". The utilization status includes
                            the last 30 days of data.
                          </span> */}
                              </Form.Label>
                              <Col sm="4" className="">
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="utilThresAbNUtilStat"
                                    value={
                                      this.props.productSetupFormValues
                                        .utilThresAbNUtilStat
                                    }
                                    required
                                    onChange={this.props.handleProdInputChange}
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                  />
                                  <InputGroup.Text>%</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide threshold.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="frmcontrols"
                              style={{ marginLeft: '30px' }}>
                              <Form.Label
                                column
                                sm="6"
                                className="removepadding">
                                <div className="forteenpx">
                                  Normal temperature range (normal utilization){' '}
                                  <Help
                                    helpContent={normalTemperatureRange}
                                    placement={'left'}
                                  />
                                </div>
                              </Form.Label>
                              <Col sm="6" className="">
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="tempThresStrt"
                                    value={
                                      this.props.productSetupFormValues
                                        .tempThresStrt
                                    }
                                    required
                                    onChange={this.props.handleProdInputChange}
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                  />
                                  <InputGroup.Text>℃</InputGroup.Text>
                                  <InputGroup.Text className="greybackground">
                                    ≤ Temp. ≤
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="tempThresEnd"
                                    value={
                                      this.props.productSetupFormValues
                                        .tempThresEnd
                                    }
                                    required
                                    onChange={this.props.handleProdInputChange}
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                  />
                                  <InputGroup.Text>℃</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide threshold.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="frmcontrols"
                              style={{ marginLeft: '30px' }}>
                              <Form.Label
                                column
                                sm="6"
                                className="removepadding">
                                <div className="forteenpx">
                                  Threshold for SoC (normal utilization){' '}
                                  <Help
                                    helpContent={thresholdForSoC}
                                    placement={'left'}
                                  />
                                </div>
                              </Form.Label>
                              <Col sm="6" className="">
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="socThresStrt"
                                    value={
                                      this.props.productSetupFormValues
                                        .socThresStrt
                                    }
                                    required
                                    onChange={this.props.handleProdInputChange}
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                  />
                                  <InputGroup.Text>%</InputGroup.Text>
                                  <InputGroup.Text className="greybackground">
                                    ≤ SoC ≤
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="socThresEnd"
                                    value={
                                      this.props.productSetupFormValues
                                        .socThresEnd
                                    }
                                    required
                                    onChange={this.props.handleProdInputChange}
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                  />
                                  <InputGroup.Text>%</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide threshold.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="frmcontrols"
                              style={{ marginLeft: '30px' }}>
                              <Form.Label
                                column
                                sm="6"
                                className="removepadding">
                                <div className="forteenpx">
                                  Threshold for Cycles (normal utilization){' '}
                                  <Help
                                    helpContent={thresholdForCycles}
                                    placement={'left'}
                                  />
                                </div>
                              </Form.Label>
                              <Col sm="6" className="">
                                <InputGroup className="mb-3">
                                  <InputGroup.Text className="greybackground">
                                    Cycles/day ≤
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    className=""
                                    name="cylThres"
                                    value={
                                      this.props.productSetupFormValues.cylThres
                                    }
                                    required
                                    onChange={this.props.handleProdInputChange}
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide threshold.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="frmcontrols"
                              style={{ marginLeft: '30px' }}>
                              <Form.Label
                                column
                                sm="6"
                                className="removepadding">
                                <div className="forteenpx">
                                  Threshold for C-Rate (normal utilization){' '}
                                  <Help
                                    helpContent={thresholdForCRate}
                                    placement={'left'}
                                  />
                                </div>
                              </Form.Label>
                              <Col sm="6" className="">
                                <InputGroup className="mb-3">
                                  <InputGroup.Text className="greybackground">
                                    C-rate ≤
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    className="norightborder"
                                    name="crateThres"
                                    value={
                                      this.props.productSetupFormValues
                                        .crateThres
                                    }
                                    required
                                    onChange={this.props.handleProdInputChange}
                                    pattern="^\d*(\.\d{0,2})?$"
                                    onWheel={e => e.target.blur()}
                                    disabled={
                                      !!(
                                        this.props.fleetFormValues.isUpdate &&
                                        !this.props.fleetFormValues.isDraft
                                      )
                                    }
                                  />
                                  <InputGroup.Text>C</InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide threshold.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </Form.Group>
                          </CollapsableSection>
                          <CollapsableSection
                            title={'Designed budget settings'}>
                            <div className="productsetupusagestabs secteenpxtopmargin">
                              <DesignBudgetInput
                                data={this.props.data}
                                activeTab={this.props.activeTab}
                                onTabChange={this.props.onTabChange}
                                handleInputChange={this.props.handleInputChange}
                                handleSwitchChange={
                                  this.props.handleSwitchChange
                                }
                                setColor={this.props.setColor}
                                handleReset={this.props.handleReset}
                                activeTabName={this.props.activeTabName}
                              />
                              {/* <Tabs
                          id="prodsetupusgstabs"
                          activeKey={this.props.selectedProdSetupUsgsTab}
                          onSelect={(k) =>
                            this.props.onProdSetupUsgsTabClick(k)
                          }
                        >
                          <Tab
                            eventKey="prodsetupusgtemp"
                            id="prodsetupusgtemp"
                            title={
                              <span className="tabIcon">
                                <span className="tabtitle flextab">
                                  Usage temperature{" "}
                                  <Help
                                    position={"relative"}
                                    placement={"right"}
                                    styleProps={{ top: 0 }}
                                    helpContent={usageTemp}
                                  />
                                </span>
                              </span>
                            }
                          >
                            <div className="prodsetupusage-content">
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  Temperature&lt; -5℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempOne"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempOne
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  -5 ℃ &le; Temperature &lt; 0℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempTwo"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempTwo
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  0 ℃ &le; Temperature &lt; 5℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempThre"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempThre
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  5 ℃ &le; Temperature &lt; 10℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempFor"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempFor
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  10 ℃ &le; Temperature &lt; 15℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempFiv"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempFiv
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  15 ℃ &le; Temperature &lt; 20℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempSix"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempSix
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  20 ℃ &le; Temperature &lt; 25℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempSev"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempSev
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  25 ℃ &le; Temperature &lt; 30℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempEit"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempEit
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  30 ℃ &le; Temperature &lt; 35℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempNin"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempNin
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  35 ℃ &le; Temperature &lt; 40℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempTen"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempTen
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  40 ℃ &le; Temperature &lt; 45℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempEle"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempEle
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  Temperature &gt; 45℃
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dTempTwe"
                                      value={
                                        this.props.productSetupFormValues
                                          .dTempTwe
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                            </div>
                          </Tab>

                          <Tab
                            eventKey="prodsetupusgsoc"
                            title={
                              <span className="tabIcon">
                                <span className="tabtitle flextab">
                                  {" "}
                                  Usage SoC{" "}
                                  <Help
                                    position={"relative"}
                                    placement={"left"}
                                    styleProps={{ top: 0 }}
                                    helpContent={usageSoc}
                                  />
                                </span>
                              </span>
                            }
                          >
                            <div className="prodsetupusage-content">
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  0 &le; SoC &lt; 10%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocOne"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocOne
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  10% &le; SoC &lt; 20%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocTwo"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocTwo
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  20% &le; SoC &lt; 30%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocThre"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocThre
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  30% &le; SoC &lt; 40%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocFor"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocFor
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  40% &le; SoC &lt; 50%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocFiv"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocFiv
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  50% &le; SoC &lt; 60%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocSix"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocSix
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  60% &le; SoC &lt; 70%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocSev"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocSev
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  70% &le; SoC &lt; 80%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocEit"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocEit
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  80% &le; SoC &lt; 90%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocNin"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocNin
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="frmcontrols">
                                <Form.Label
                                  column
                                  sm="9"
                                  className="removepadding"
                                >
                                  90% &le; SoC &le; 100%
                                </Form.Label>
                                <Col sm="3" className="">
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      type="number"
                                      className="norightborder"
                                      name="dSocTen"
                                      value={
                                        this.props.productSetupFormValues
                                          .dSocTen
                                      }
                                      onChange={
                                        this.props.handleProdInputChange
                                      }
                                      required
                                      pattern="^\d*(\.\d{0,2})?$"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    <InputGroup.Text>h</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide threshold.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                            </div>
                          </Tab>

                          <Tab
                            eventKey="prodsetupusgpow"
                            title={
                              <span className="tabIcon">
                                <span className="tabtitle flextab">
                                  {" "}
                                  Usage power{" "}
                                  <Help
                                    position={"relative"}
                                    placement={"left"}
                                    styleProps={{ top: 0 }}
                                    helpContent={usagePower}
                                  />
                                </span>
                              </span>
                            }
                          >
                            {this.props.productSetupFormValues.fleetName ===
                            "GTW_ARRIVA" ? (
                              <div className="prodsetupusage-content">
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    &lt;-165.60 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowOne"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowOne
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -165.60 kW to -132.48 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTwo"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTwo
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -132.48 kW to -99.36 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowThre"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowThre
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -99.36 kW to -66.24 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowFor"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowFor
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -66.24 kW to -33.12 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowFiv"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowFiv
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -33.12 kW to 0 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowSix"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowSix
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    0 kW to 33.12 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowSev"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowSev
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    33.12 kW to 66.24 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowEit"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowEit
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    66.24 kW to 99.36 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowNin"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowNin
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    99.36 kW to 132.48 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTen"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTen
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    132.48 kW to 165.60 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowEle"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowEle
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    &gt;165.60 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTwe"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTwe
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                              </div>
                            ) : this.props.productSetupFormValues.fleetName ===
                              "SWISSTROLLEY_FRIBOURG" ? (
                              <div className="prodsetupusage-content">
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    &lt;-574.4 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowOne"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowOne
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -574.4 kW to -459.52 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTwo"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTwo
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -459.52 kW to -344.64 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowThre"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowThre
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -344.64 kW to -229.76 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowFor"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowFor
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -229.76 kW to -114.88 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowFiv"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowFiv
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -114.88 kW to 0 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowSix"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowSix
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    0 kW to 114.88 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowSev"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowSev
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    114.88 kW to 229.76 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowEit"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowEit
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    229.76 kW to 344.64 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowNin"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowNin
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    344.64 kW to 459.52 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTen"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTen
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    459.52 kW to 574.40 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowEle"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowEle
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    &gt;574.40 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTwe"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTwe
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                              </div>
                            ) : this.props.productSetupFormValues.fleetName ===
                                "AKKUFLIRT" ||
                              this.props.productSetupFormValues.fleetName ===
                                "MERSEYRAIL" ? (
                              <div className="prodsetupusage-content">
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    &lt;-240 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowOne"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowOne
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -240 kW to -192 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTwo"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTwo
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -192 to -144 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowThre"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowThre
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -144 kW to -96 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowFor"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowFor
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -96 kW to -48 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowFiv"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowFiv
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -48 kW to 0 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowSix"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowSix
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    0 kW to 48 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowSev"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowSev
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    48 kW to 96 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowEit"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowEit
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    96 kW to 144 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowNin"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowNin
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    144 kW to 192 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTen"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTen
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    192 kW to 240 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowEle"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowEle
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    &gt;240 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTwe"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTwe
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                              </div>
                            ) : (
                              <div className="prodsetupusage-content">
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    &lt;-243.616 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowOne"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowOne
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -243.616 kW to -194.8928 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTwo"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTwo
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -194.8928 kW to -146.1696 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowThre"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowThre
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    146.1696 kW to -97.4464 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowFor"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowFor
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -97.4464 kW to -48.7232 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowFiv"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowFiv
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    -48.7232 kW to 0 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowSix"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowSix
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    0 kW to 48.7232 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowSev"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowSev
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    48.7232 kW to 97.4464 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowEit"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowEit
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    97.4464 kW to 146.1696 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowNin"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowNin
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    146.1696 kW to 194.8928 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTen"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTen
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    194.8928 kW to 243.616 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowEle"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowEle
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    &gt;243.616 kW
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dPowTwe"
                                        value={
                                          this.props.productSetupFormValues
                                            .dPowTwe
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                              </div>
                            )}
                          </Tab>
                          <Tab
                            eventKey="prodsetupusgdod"
                            title={
                              <span className="tabIcon">
                                <span className="tabtitle flextab">
                                  Usage DoD{" "}
                                  <Help
                                    position={"relative"}
                                    placement={"right"}
                                    styleProps={{ top: 0 }}
                                    helpContent={usageDod}
                                  />{" "}
                                </span>
                              </span>
                            }
                          >
                            {this.props.productSetupFormValues.fleetName ===
                            "GTW_ARRIVA" ? (
                              <div className="prodsetupusage-content">
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    0 kWh to 2.5392 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodOne"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodOne
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    2.5392 kWh to 5.0784 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodTwo"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodTwo
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    5.0784 kWh to 7.6176 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodThre"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodThre
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    7.6176 kWh to 10.1568 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodFor"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodFor
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    10.1568 kWh to 12.696 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodFiv"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodFiv
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    12.696 kWh to 15.2352 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodSix"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodSix
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    15.2352 kWh to 17.7744 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodSev"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodSev
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    17.7744 kWh to 20.3136 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodEit"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodEit
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    20.3136 kWh to 22.8528 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodNin"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodNin
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    22.8528 kWh to 25.392 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodTen"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodTen
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                              </div>
                            ) : this.props.productSetupFormValues.fleetName ===
                              "SWISSTROLLEY_FRIBOURG" ? (
                              <div className="prodsetupusage-content">
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    0 kWh to 6.6056 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodOne"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodOne
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    6.6056 kWh to 13.2112 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodTwo"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodTwo
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    13.2112 kWh to 19.8168 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodThre"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodThre
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    19.8168 kWh to 26.4224 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodFor"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodFor
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    26.4224 kWh to 33.0280 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodFiv"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodFiv
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    33.0280 kWh to 39.6336 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodSix"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodSix
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    39.6336 kWh to 46.2392 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodSev"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodSev
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    46.2392 kWh to 52.8448 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodEit"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodEit
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    52.8448 kWh to 59.4504 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodNin"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodNin
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    59.4504 kWh to 66.0560 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodTen"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodTen
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                              </div>
                            ) : this.props.productSetupFormValues.fleetName ===
                                "AKKUFLIRT" ||
                              this.props.productSetupFormValues.fleetName ===
                                "MERSEYRAIL" ? (
                              <div className="prodsetupusage-content">
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    0 kWh to 5.29 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodOne"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodOne
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    5.29 kWh to 10.58 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodTwo"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodTwo
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    10.58 kWh to 15.87 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodThre"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodThre
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    15.87 kWh to 21.16 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodFor"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodFor
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    21.16 kWh to 26.45 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodFiv"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodFiv
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    26.45 kWh to 31.74 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodSix"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodSix
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    31.74 kWh to 37.03 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodSev"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodSev
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    37.03 kWh to 42.32 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodEit"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodEit
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    42.32 kWh to 47.61 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodNin"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodNin
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    47.61 kWh to 52.90 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodTen"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodTen
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                              </div>
                            ) : (
                              <div className="prodsetupusage-content">
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    0 &le; SoC &lt; 3.0452 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodOne"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodOne
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    3.0452 kWh &le; SoC &lt; 6.0904 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodTwo"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodTwo
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    6.0904 kWh &le; SoC &lt; 9.1356 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodThre"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodThre
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    9.1356 kWh &le; SoC &lt; 12.1808 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodFor"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodFor
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    12.1808 kWh &le; SoC &lt; 15.226 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodFiv"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodFiv
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    15.226 kWh &le; SoC &lt; 18.2712 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodSix"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodSix
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    18.2712 kWh &le; SoC &lt; 21.3164 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodSev"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodSev
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    21.3164 kWh &le; SoC &lt; 24.3616 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodEit"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodEit
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    24.3616 kWh &le; SoC &lt; 27.4068 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodNin"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodNin
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="frmcontrols">
                                  <Form.Label
                                    column
                                    sm="9"
                                    className="removepadding"
                                  >
                                    27.4068 kWh &le; SoC &le; 30.452 kWh
                                  </Form.Label>
                                  <Col sm="3" className="">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        className="norightborder"
                                        name="dDodTen"
                                        value={
                                          this.props.productSetupFormValues
                                            .dDodTen
                                        }
                                        onChange={
                                          this.props.handleProdInputChange
                                        }
                                        required
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                      <InputGroup.Text>kWh</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                        Please provide threshold.
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Col>
                                </Form.Group>
                              </div>
                            )}
                          </Tab>
                        </Tabs> */}
                            </div>
                          </CollapsableSection>
                          <CollapsableSection title={'SoH'}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}>
                              <Form.Label>
                                Displayed SoH model output{' '}
                                {/* <Help
                                  helpContent={vehicleType}
                                  placement={'bottom'}
                                /> */}
                              </Form.Label>
                              <Form.Control
                                style={{ maxWidth: '300px' }}
                                as="select"
                                type="select"
                                className="custom-select prodType-select"
                                name="sohModelOutput"
                                placeholder="Please select"
                                disabled={
                                  !!(
                                    this.props.fleetFormValues.isUpdate &&
                                    !this.props.fleetFormValues.isDraft
                                  )
                                }
                                onChange={this.props.handleSohModelInputChange}
                                value={
                                  this.props.productSetupFormValues
                                    .sohModelOutput
                                }
                                required>
                                <option value="Normal SOH">Normal SOH</option>
                                <option value="Stress Factors SOH - PW">
                                  Stress Factors SOH - PW
                                </option>
                              </Form.Control>
                            </div>
                          </CollapsableSection>
                          <CollapsableSection
                            title={
                              'Parameters  for calculated SoH with stress Factors'
                            }>
                            <div className="productsetupusagestabs">
                              <SohstressFactor
                                data={this.props.data}
                                activeTab={this.props.activeTab}
                                onTabChange={this.props.onTabChange}
                                sohStressActiveTab={
                                  this.props.sohStressActiveTab
                                }
                                onAddRow={this.props.onAddRow}
                                onDeleteRow={this.props.onDeleteRow}
                                onInputChange={this.props.onInputChange}
                                fleetFormValues={this.props.fleetFormValues}
                              />
                            </div>
                          </CollapsableSection>

                          {/* </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion> */}
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="product-converter-tab"
                      title={
                        <span className="tabIcon" id="product-converter-info">
                          {this.state.selectedProductTab ===
                          'product-converter-tab' ? (
                            <Converter_e />
                          ) : (
                            <Converter_d />
                          )}
                          <span className="tabtitle"> Converter</span>
                        </span>
                      }>
                      <div
                        className="setup-tab-content"
                        style={{ height: '60vh' }}>
                        <Row
                          className="frmcontrols"
                          style={{ marginLeft: '20px' }}>
                          <Form.Group as={Col} md={4}>
                            <Form.Label className="">
                              Converter prefix
                              <Help
                                helpContent={converterPrefix}
                                placement={'bottom'}
                              />
                            </Form.Label>
                            <InputGroup className="mb-4">
                              <Form.Control
                                type="text"
                                name="converterPrefix"
                                value={
                                  this.props.productSetupFormValues
                                    .converterPrefix
                                }
                                onChange={this.props.handleProdInputChange}
                                required
                                maxLength={10}
                                style={{ borderRadius: 0 }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide converter prefix.
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group as={Col} md={8}></Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Check
                              type="switch"
                              id="converter-switch"
                              label="Enable"
                              name="converterEnable"
                              onChange={this.props.handleProdInputChange}
                              checked={
                                this.props.productSetupFormValues
                                  .converterEnable
                              }
                              reverse
                            />
                            <Help
                              helpContent={converterEnable}
                              placement={'bottom'}
                            />
                          </Form.Group>
                        </Row>
                      </div>
                    </Tab>
                  </Tabs>
                </Tab>
              </Tabs>
            </Col>
          </Form>
        </Row>
      </>
    );
  }
}

export default ProductSetupForm;
