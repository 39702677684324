import { createSelector } from 'reselect';

const eventsData = () => state => state.get('eventsData');

const loadingEventListSelector = () =>
  createSelector(eventsData(), state => state.get('loadingEventList'));

const eventListSelector = () =>
  createSelector(eventsData(), state => state.get('eventListData'));

// save event
const loadingSaveEventSelector = () =>
  createSelector(eventsData(), state => state.get('loadingSaveEvent'));

const saveEventResponceSelector = () =>
  createSelector(eventsData(), state => state.get('saveEventResponce'));

// undo event
const loadingUndoEventSelector = () =>
  createSelector(eventsData(), state => state.get('loadingUndoEvent'));

const undoEventResponceSelector = () =>
  createSelector(eventsData(), state => state.get('undoEventResponce'));

// ***

const loadingVEventListSelector = () =>
  createSelector(eventsData(), state => state.get('loadingVEventList'));

const veventListSelector = () =>
  createSelector(eventsData(), state => state.get('veventListData'));

const loadingAllEventListSelector = () =>
  createSelector(eventsData(), state => state.get('loadingAllEventList'));

const AlleventListSelector = () =>
  createSelector(eventsData(), state => state.get('AlleventListData'));
const eventDataSelector = () =>
  createSelector(eventsData(), state => state.get('eventData'));

const alleventListSelectorConfig = () =>
  createSelector(eventsData(), state => state.get('allEventListDataConfig'));

export {
  AlleventListSelector,
  alleventListSelectorConfig,
  eventDataSelector,
  eventListSelector,
  loadingAllEventListSelector,
  loadingEventListSelector,
  loadingSaveEventSelector,
  loadingUndoEventSelector,
  loadingVEventListSelector,
  saveEventResponceSelector,
  undoEventResponceSelector,
  veventListSelector,
};
