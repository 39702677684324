import {
  CLEAR_VEHICLE_VIEW_DATA,
  FETCH_BOXPLOT_DATA,
  FETCH_BOXPLOT_DATA_FAILURE,
  FETCH_BOXPLOT_DATA_SUCCESS,
  FETCH_HISTOGRAM_DATA,
  FETCH_HISTOGRAM_DATA_FAILURE,
  FETCH_HISTOGRAM_DATA_SUCCESS,
  FETCH_OVERALL_CONDITION_DATA,
  FETCH_OVERALL_CONDITION_DATA_FAILURE,
  FETCH_OVERALL_CONDITION_DATA_SUCCESS,
  FETCH_VEHICLE_AVAIL_DATA,
  FETCH_VEHICLE_AVAIL_DATA_FAILURE,
  FETCH_VEHICLE_AVAIL_DATA_SUCCESS,
  FETCH_VEHICLE_DETAILS,
  FETCH_VEHICLE_DETAILS_FAILURE,
  FETCH_VEHICLE_DETAILS_SUCCESS,
  FETCH_VEHICLE_REL_DATA,
  FETCH_VEHICLE_REL_DATA_FAILURE,
  FETCH_VEHICLE_REL_DATA_SUCCESS,
  FETCH_VEHICLE_STR_DATA,
  FETCH_VEHICLE_STR_DATA_FAILURE,
  FETCH_VEHICLE_STR_DATA_SUCCESS,
} from './constants';

// fleet overall condition
export const fetchOverallConditionData = () => ({
  type: FETCH_OVERALL_CONDITION_DATA,
});

export const fetchOverallConditionDataSucess = overallConditionData => ({
  type: FETCH_OVERALL_CONDITION_DATA_SUCCESS,
  overallConditionData,
});

export const fetchOverallConditionDataFailure = error => ({
  type: FETCH_OVERALL_CONDITION_DATA_FAILURE,
  error,
});

// Vehicle Availability
export const fetchVehicleAvailData = (sitename, fleetname, vehicleName) => ({
  type: FETCH_VEHICLE_AVAIL_DATA,
  payload: { sitename, fleetname, vehicleName },
});

export const fetchVehicleAvailDataSucess = vehicleAvailData => ({
  type: FETCH_VEHICLE_AVAIL_DATA_SUCCESS,
  vehicleAvailData,
});

export const fetchVehicleAvailDataFailure = error => ({
  type: FETCH_VEHICLE_AVAIL_DATA_FAILURE,
  error,
});

// Vehicle Reliability
export const fetchVehicleRelData = (sitename, fleetname, vehicleName) => ({
  type: FETCH_VEHICLE_REL_DATA,
  payload: { sitename, fleetname, vehicleName },
});

export const fetchVehicleRelDataSucess = vehicleRelData => ({
  type: FETCH_VEHICLE_REL_DATA_SUCCESS,
  vehicleRelData,
});

export const fetchVehicleRelDataFailure = error => ({
  type: FETCH_VEHICLE_REL_DATA_FAILURE,
  error,
});

// Vehicle Stress
export const fetchVehicleStrData = (sitename, fleetname, vehicleName) => ({
  type: FETCH_VEHICLE_STR_DATA,
  payload: { sitename, fleetname, vehicleName },
});

export const fetchVehicleStrDataSucess = vehicleStrData => ({
  type: FETCH_VEHICLE_STR_DATA_SUCCESS,
  vehicleStrData,
});

export const fetchVehicleStrDataFailure = error => ({
  type: FETCH_VEHICLE_STR_DATA_FAILURE,
  error,
});

// Clear vehicle view data

export const clearVehicleViewData = () => ({
  type: CLEAR_VEHICLE_VIEW_DATA,
});

// Histogram
export const fetchHistogramData = () => ({
  type: FETCH_HISTOGRAM_DATA,
});

export const fetchHistogramDataSucess = histogramData => ({
  type: FETCH_HISTOGRAM_DATA_SUCCESS,
  histogramData,
});

export const fetchHistogramDataFailure = error => ({
  type: FETCH_HISTOGRAM_DATA_FAILURE,
  error,
});

// BoxPlot
export const fetchBoxPlotData = () => ({
  type: FETCH_BOXPLOT_DATA,
});

export const fetchBoxPlotDataSucess = boxPlotData => ({
  type: FETCH_BOXPLOT_DATA_SUCCESS,
  boxPlotData,
});

export const fetchBoxPlotDataFailure = error => ({
  type: FETCH_BOXPLOT_DATA_FAILURE,
  error,
});

// Vehicle Details
export const fetchVehicleDetails = (sitename, fleetname, vehicleName) => ({
  type: FETCH_VEHICLE_DETAILS,
  payload: { sitename, fleetname, vehicleName },
});

export const fetchVehicleDetailsSuccess = vehicleDetails => ({
  type: FETCH_VEHICLE_DETAILS_SUCCESS,
  vehicleDetails,
});

export const fetchVehicleDetailsFailure = error => ({
  type: FETCH_VEHICLE_DETAILS_FAILURE,
  error,
});
