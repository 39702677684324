import React from 'react';
import { AbbLogo, ServerError } from '../../assets/images';
import './styles.scss';

export default function ServiceNotAvailable({ message }) {
  return (
    <>
      <div className="serviceNotAvailable">
        <div className="header">
          <div>
            <AbbLogo alt="ABB" />
          </div>
          <div className="bar">|</div>
          <div className="title">Condition Monitoring for Traction</div>
        </div>
        <div className="content">
          <ServerError alt="ABB" />
          <div className="message">{message}</div>
          <button className="refresh" onClick={() => window.location.reload()}>
            Refresh
          </button>
        </div>
      </div>
    </>
  );
}
