import debug from 'debug';
import Highcharts from 'highcharts/highstock';
import Moment from 'moment';
import iconInfo from '../../assets/images/bw_round_info.svg';
import exportChart from '../../assets/images/exportChart.svg';
import { checkUserEntitlements } from '../../userConfig';
import { exportToExcel, formatDataToExcel } from '../../utils/excel-utils';

export let xMin, xMax;
export const colors = [
  '#006DC0', // blue
  '#FF6C01', // orange
  '#66CC00', // green
  '#5BD8FF',
  '#404040', // black
  '#24FA24', // light green
  '#CC1DCB', // darker purple
  '#672E3B', // tawny
  '#EDCDC2', // green
  '#669999', // blue/green
  '#e6e600', // yellow
  '#0099cc', // blue sea
];

export function timeDifference(date1, date2, resampleInterval) {
  let returnValue = false;

  let difference = new Date(date2).getTime() - new Date(date1).getTime();

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  const MultipleValue = 24;
  if (resampleInterval === '30 minute') {
    if (daysDifference === 0 && hoursDifference <= (30 * MultipleValue) / 60)
      returnValue = true;
  } else if (resampleInterval === '15 minute') {
    if (daysDifference === 0 && hoursDifference <= (15 * MultipleValue) / 60)
      returnValue = true;
  } else if (resampleInterval === '4 minute') {
    if (daysDifference === 0 && hoursDifference <= (4 * MultipleValue) / 60)
      returnValue = true;
  } else if (resampleInterval === '1 minute') {
    if (daysDifference === 0 && hoursDifference <= (1 * MultipleValue) / 60)
      returnValue = true;
  } else if (resampleInterval === '15 second') {
    if (
      daysDifference === 0 &&
      hoursDifference === 0 &&
      minutesDifference <= (15 * MultipleValue) / 60
    )
      returnValue = true;
  } else if (resampleInterval === '5 second') {
    if (
      daysDifference === 0 &&
      hoursDifference === 0 &&
      minutesDifference <= (5 * MultipleValue) / 60
    )
      returnValue = true;
  } else if (resampleInterval === '1 second') {
    // spacer
  }

  return returnValue;
}

export function checkChatValues(chartValues) {
  let isVisible = '';
  for (let i = 0; i < chartValues.length - 1; i++) {
    if (
      chartValues[i].config.label.indexOf('B1') !== -1 &&
      chartValues[i + 1].config.label.indexOf('B2') !== -1
    ) {
      isVisible = false;
    } else {
      isVisible = true;
    }
  }
  return isVisible;
}

export function getMinMaxValues(value, signalConfig) {
  let returnValue;
  const signalName = signalConfig.label;
  if (value === 'min') {
    if (sessionStorage.getItem('selectedFleet') === 'GTW_ARRIVA') {
      returnValue =
        signalName.indexOf('State of Charge') !== -1
          ? signalConfig.tmin
          : signalName.indexOf('Power') !== -1
            ? -330
            : signalName.indexOf('Battery Current') !== -1
              ? -330
              : // : signalName.indexOf("Battery Voltage") !== -1
                // ? +300
                signalName.indexOf('Battery Peak Chg.') !== -1
                ? -330
                : signalName.indexOf('Battery Peak DisChg.') !== -1
                  ? -330
                  : signalName.indexOf('Battery Cont. Chg.') !== -1
                    ? -330
                    : signalName.indexOf('Battery Cont. DisChg.') !== -1
                      ? -330
                      : signalName.indexOf('Reliability') !== -1
                        ? -1
                        : signalName.indexOf('Temperature') !== -1
                          ? -5
                          : signalName.indexOf('Feed Flow') !== -1
                            ? -5
                            : signalName.indexOf('Overload Counter') !== -1
                              ? 0
                              : signalName.indexOf('Return Flow') !== -1
                                ? -5
                                : signalName.indexOf('Diagnostic Event') !== -1
                                  ? -1
                                  : null;
    } else if (
      sessionStorage.getItem('selectedFleet') === 'SWISSTROLLEY_FRIBOURG'
    ) {
      returnValue =
        signalName.indexOf('State of Charge') !== -1
          ? signalConfig.tmin
          : signalName.indexOf('Power') !== -1
            ? -600
            : signalName.indexOf('Battery Current') !== -1
              ? -800
              : // : signalName.indexOf("Battery Voltage") !== -1
                // ? +300
                signalName.indexOf('Battery Peak Chg.') !== -1
                ? -800
                : signalName.indexOf('Battery Peak DisChg.') !== -1
                  ? -800
                  : signalName.indexOf('Battery Cont. Chg.') !== -1
                    ? -800
                    : signalName.indexOf('Battery Cont. DisChg.') !== -1
                      ? -800
                      : signalName.indexOf('Reliability') !== -1
                        ? -1
                        : signalName.indexOf('Temperature') !== -1
                          ? -5
                          : signalName.indexOf('Feed Flow') !== -1
                            ? -5
                            : signalName.indexOf('Overload Counter') !== -1
                              ? 0
                              : signalName.indexOf('Return Flow') !== -1
                                ? -5
                                : signalName.indexOf('Diagnostic Event') !== -1
                                  ? -1
                                  : null;
    } else {
      returnValue =
        signalName.indexOf('State of Charge') !== -1
          ? signalConfig.tmin
          : signalName.indexOf('Power') !== -1
            ? -400
            : signalName.indexOf('Battery Current') !== -1
              ? -400
              : // : signalName.indexOf("Battery Voltage") !== -1
                // ? +300
                signalName.indexOf('Battery Peak Chg.') !== -1
                ? -400
                : signalName.indexOf('Battery Peak DisChg.') !== -1
                  ? -400
                  : signalName.indexOf('Battery Cont. Chg.') !== -1
                    ? -400
                    : signalName.indexOf('Battery Cont. DisChg.') !== -1
                      ? -400
                      : signalName.indexOf('Reliability') !== -1
                        ? -1
                        : signalName.indexOf('Temperature') !== -1
                          ? -5
                          : signalName.indexOf('Feed Flow') !== -1
                            ? -5
                            : signalName.indexOf('Overload Counter') !== -1
                              ? 0
                              : signalName.indexOf('Return Flow') !== -1
                                ? -5
                                : signalName.indexOf('Diagnostic Event') !== -1
                                  ? -1
                                  : null;
    }
  } else if (value === 'max') {
    if (sessionStorage.getItem('selectedFleet') === 'GTW_ARRIVA') {
      returnValue =
        signalName.indexOf('State of Charge') !== -1
          ? signalConfig.tmax
          : signalName.indexOf('Power') !== -1
            ? 330
            : signalName.indexOf('Battery Current') !== -1
              ? 330
              : // : signalName.indexOf("Battery Voltage") !== -1
                // ? 700
                signalName.indexOf('Battery Peak Chg.') !== -1
                ? 330
                : signalName.indexOf('Battery Peak DisChg.') !== -1
                  ? 330
                  : signalName.indexOf('Battery Cont. Chg.') !== -1
                    ? 330
                    : signalName.indexOf('Battery Cont. DisChg.') !== -1
                      ? 330
                      : signalName.indexOf('Reliability') !== -1
                        ? 10
                        : signalName.indexOf('Temperature') !== -1
                          ? 60
                          : signalName.indexOf('Feed Flow') !== -1
                            ? 60
                            : signalName.indexOf('Overload Counter') !== -1
                              ? 100
                              : signalName.indexOf('Return Flow') !== -1
                                ? 60
                                : signalName.indexOf('Diagnostic Event') !== -1
                                  ? 10
                                  : null;
    } else if (
      sessionStorage.getItem('selectedFleet') === 'SWISSTROLLEY_FRIBOURG'
    ) {
      returnValue =
        signalName.indexOf('State of Charge') !== -1
          ? signalConfig.tmax
          : signalName.indexOf('Power') !== -1
            ? 600
            : signalName.indexOf('Battery Current') !== -1
              ? 800
              : // : signalName.indexOf("Battery Voltage") !== -1
                // ? 700
                signalName.indexOf('Battery Peak Chg.') !== -1
                ? 800
                : signalName.indexOf('Battery Peak DisChg.') !== -1
                  ? 800
                  : signalName.indexOf('Battery Cont. Chg.') !== -1
                    ? 800
                    : signalName.indexOf('Battery Cont. DisChg.') !== -1
                      ? 800
                      : signalName.indexOf('Reliability') !== -1
                        ? 10
                        : signalName.indexOf('Temperature') !== -1
                          ? 60
                          : signalName.indexOf('Feed Flow') !== -1
                            ? 60
                            : signalName.indexOf('Overload Counter') !== -1
                              ? 100
                              : signalName.indexOf('Return Flow') !== -1
                                ? 60
                                : signalName.indexOf('Diagnostic Event') !== -1
                                  ? 10
                                  : null;
    } else {
      returnValue =
        signalName.indexOf('State of Charge') !== -1
          ? signalConfig.tmax
          : signalName.indexOf('Power') !== -1
            ? 400
            : signalName.indexOf('Battery Current') !== -1
              ? 400
              : // : signalName.indexOf("Battery Voltage") !== -1
                // ? 700
                signalName.indexOf('Battery Peak Chg.') !== -1
                ? 400
                : signalName.indexOf('Battery Peak DisChg.') !== -1
                  ? 400
                  : signalName.indexOf('Battery Cont. Chg.') !== -1
                    ? 400
                    : signalName.indexOf('Battery Cont. DisChg.') !== -1
                      ? 400
                      : signalName.indexOf('Reliability') !== -1
                        ? 10
                        : signalName.indexOf('Temperature') !== -1
                          ? 60
                          : signalName.indexOf('Feed Flow') !== -1
                            ? 60
                            : signalName.indexOf('Overload Counter') !== -1
                              ? 100
                              : signalName.indexOf('Return Flow') !== -1
                                ? 60
                                : signalName.indexOf('Diagnostic Event') !== -1
                                  ? 10
                                  : null;
    }
  } else if (value === 'interval') {
    if (sessionStorage.getItem('selectedFleet') === 'GTW_ARRIVA') {
      returnValue =
        signalName.indexOf('State of Charge') !== -1
          ? 11
          : signalName.indexOf('Power') !== -1
            ? 14
            : signalName.indexOf('Battery Current') !== -1
              ? 14
              : // : signalName.indexOf("Battery Voltage") !== -1
                // ? 14
                signalName.indexOf('Battery Peak Chg.') !== -1
                ? 14
                : signalName.indexOf('Battery Peak DisChg.') !== -1
                  ? 14
                  : signalName.indexOf('Battery Cont. Chg.') !== -1
                    ? 14
                    : signalName.indexOf('Battery Cont. DisChg.') !== -1
                      ? 14
                      : signalName.indexOf('Reliability') !== -1
                        ? 1
                        : signalName.indexOf('Temperature') !== -1
                          ? 12
                          : signalName.indexOf('Feed Flow') !== -1
                            ? 12
                            : signalName.indexOf('Overload Counter') !== -1
                              ? 17
                              : signalName.indexOf('Return Flow') !== -1
                                ? 12
                                : signalName.indexOf('Diagnostic Event') !== -1
                                  ? 1
                                  : undefined;
    } else if (
      sessionStorage.getItem('selectedFleet') === 'SWISSTROLLEY_FRIBOURG'
    ) {
      returnValue =
        signalName.indexOf('State of Charge') !== -1
          ? 11
          : signalName.indexOf('Power') !== -1
            ? 14
            : signalName.indexOf('Battery Current') !== -1
              ? 17
              : // : signalName.indexOf("Battery Voltage") !== -1
                // ? 14
                signalName.indexOf('Battery Peak Chg.') !== -1
                ? 17
                : signalName.indexOf('Battery Peak DisChg.') !== -1
                  ? 14
                  : signalName.indexOf('Battery Cont. Chg.') !== -1
                    ? 17
                    : signalName.indexOf('Battery Cont. DisChg.') !== -1
                      ? 14
                      : signalName.indexOf('Reliability') !== -1
                        ? 1
                        : signalName.indexOf('Temperature') !== -1
                          ? 12
                          : signalName.indexOf('Feed Flow') !== -1
                            ? 12
                            : signalName.indexOf('Overload Counter') !== -1
                              ? 17
                              : signalName.indexOf('Return Flow') !== -1
                                ? 12
                                : signalName.indexOf('Diagnostic Event') !== -1
                                  ? 1
                                  : undefined;
    } else {
      returnValue =
        signalName.indexOf('State of Charge') !== -1
          ? 11
          : signalName.indexOf('Power') !== -1
            ? 17
            : signalName.indexOf('Battery Current') !== -1
              ? 17
              : // : signalName.indexOf("Battery Voltage") !== -1
                // ? 17
                signalName.indexOf('Battery Peak Chg.') !== -1
                ? 17
                : signalName.indexOf('Battery Peak DisChg.') !== -1
                  ? 17
                  : signalName.indexOf('Battery Cont. Chg.') !== -1
                    ? 17
                    : signalName.indexOf('Battery Cont. DisChg.') !== -1
                      ? 17
                      : signalName.indexOf('Reliability') !== -1
                        ? 1
                        : signalName.indexOf('Temperature') !== -1
                          ? 12
                          : signalName.indexOf('Feed Flow') !== -1
                            ? 12
                            : signalName.indexOf('Overload Counter') !== -1
                              ? 17
                              : signalName.indexOf('Return Flow') !== -1
                                ? 12
                                : signalName.indexOf('Diagnostic Event') !== -1
                                  ? 1
                                  : undefined;
    }
  }
  return returnValue;
}
const isEntitlement = enititlement => {
  const entitlements = JSON.parse(
    sessionStorage.getItem('profile')
  ).entitlements;
  return entitlements.includes(enititlement);
};

const drawThreshold = mainSignal => {
  if (['Power', 'Temperature', 'State of Charge'].includes(mainSignal))
    return true;
  return false;
};

export function getSingleTrendData(
  selectedOverlays,
  chart,
  selectedMain,
  filter,
  advanced_filter,
  loadMoreFunc,
  showThreshold
) {
  let rangedata = [];
  let averages = [];
  let xaxisvalue = [];
  const seriesValues = [];
  let xAxis = [];
  const yAxis = [];

  const chartValues = chart;
  /* chartValues.push(...chart.filter(item => item.config.label === selectedMain));
  selectedOverlays &&
    selectedOverlays.map(value =>
      chartValues.push(...chart.filter(item => item.config.label === value))
    ); */

  for (let j = 0; j < chartValues.length; j += 1) {
    rangedata = [];
    averages = [];
    xaxisvalue = [];
    if (chartValues[j].config.label === 'Diagnostic Event') {
      const tempData = chartValues[j].data;
      for (let i = 0; i < tempData.length; i += 1) {
        const date1 = tempData[i];
        for (let k = 1; k < 12 && k + i < tempData.length; k += 1) {
          const date2 = tempData[i + k];
          if (i < tempData.length - 1) {
            if (
              timeDifference(
                date1.x,
                date2.x,
                chartValues[j].config.resampleInterval
              )
            ) {
              const temp_count =
                Number(tempData[i].count) + Number(tempData[i + k].count);
              if (k === 1) {
                tempData[i].label =
                  '<span style="color:' +
                  tempData[i].color +
                  '">\u25CF</span> <span style="font-size: 10px">' +
                  Moment.utc(tempData[i].x).format('DD.MM.YYYY HH:mm:ss') +
                  '</span><br/>' +
                  tempData[i].label;
              }
              tempData[i].count = temp_count;
              if (tempData[i + k].color === 'red') {
                tempData[i].color = 'red';
              }
              tempData[i].label +=
                ',' +
                '<span style="color:' +
                tempData[i + k].color +
                '">\u25CF</span> <span style="font-size: 10px">' +
                Moment.utc(tempData[i + k].x).format('DD.MM.YYYY HH:mm:ss') +
                '</span><br/>' +
                ',' +
                tempData[i + k].label;

              tempData[i + k].x = '';
            } else {
              k = 12;
            }
          }
        }
      }
      seriesValues.push({
        type: 'line',
        name: 'Diagnostic Event',
        data: tempData, // chartValues[j].data,
        color: 'red',
        fillOpacity: 0,
        lineWidth: 0,
        tooltip: {
          valueSuffix: ' ' + chartValues[j].config.unit,
        },
        stickyTracking: false,
        states: {
          hover: {
            enabled: false,
            fillOpacity: 1,
          },
          inactive: {
            enabled: false,
          },
        },
        marker: {
          enabled: true,
          radius: 4,
          symbol: 'circle',
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        yAxis: j,
        boostThreshold: 0,
        turboThreshold: Number.MAX_VALUE,
        allowPointSelect: true,
        step: true,
        dataLabels: {
          // crop: true,
          enabled: true,
          allowOverlap: true,
          borderRadius: 2,
          y: -10,
          className: 'highlight',
          formatter: function () {
            return this.point.count;
          },
        },
      });
    } else {
      for (let i = 0; i < chartValues[j].data.length; i += 1) {
        const item = chartValues[j].data[i];
        // if (!item.value) {
        const formatedDate =
          item.timestamp; /* Moment.utc(item.timestamp).format(
        "DD.MM.YYYY HH:mm:ss"
      ); */

        if (item.min === undefined) {
          averages.push([formatedDate, item.avg]);
        } else {
          xaxisvalue.push([formatedDate]);
          averages.push([formatedDate, item.avg]);
          rangedata.push([formatedDate, item.min, item.max]);
        }
      }
      seriesValues.push({
        name: chartValues[j].config.label,
        type: 'line',
        fillOpacity: 1,
        color: chartValues[j].config.color, // colors[j],
        data: averages, // reliabilityData[0].data ,
        marker: {
          enabled: false,
        },
        tooltip: {
          valueSuffix: ' ' + chartValues[j].config.unit,
        },
        yAxis: j,
        boostThreshold: 1,
        turboThreshold: Number.MAX_VALUE,
        step: chartValues[j].config.label === 'Reliability' ? true : null,
      });
      rangedata &&
        seriesValues.push({
          name: chartValues[j].config.label,
          type: 'arearange',
          data: rangedata,
          lineWidth: 0,
          linkedTo: ':previous',
          color: chartValues[j].config.color, // colors[j],
          fillOpacity: 0.8,
          zIndex: 0,
          marker: {
            enabled: false,
          },
          tooltip: {
            valueSuffix: ' ' + chartValues[j].config.unit,
          },
          yAxis: j,
          boostThreshold: 1,
          turboThreshold: Number.MAX_VALUE,
        });
    }

    yAxis.push({
      opposite: j !== 0,
      tickAmount: 7,
      min: getMinMaxValues('min', chartValues[j].config),
      max: getMinMaxValues('max', chartValues[j].config),
      tickInterval: getMinMaxValues('interval', chartValues[j].config),
      visible: chartValues[j].config.label !== 'Diagnostic Event',
      title: {
        enabled: false,
        text: `${chartValues[j].config.label} ${chartValues[j].config.unit}`,
        style: {
          color: chartValues[j].config.color,
        },
      },
      // show threshold lines only for main signal
      plotLines:
        j === 0 && showThreshold && drawThreshold(chartValues[j].config.label)
          ? [
              {
                id: 'limit-min',
                color: '#858585',
                dashStyle: 'ShortDash',
                width: 2,
                value: chartValues[j].config.tmin?.toFixed(0),
                zIndex: 1,
                label: {
                  useHTML: true,
                  style: {
                    backgroundColor: '#858585',
                    color: 'white',
                    borderRadius: '3px',
                  },
                  align: 'right',
                  text: `${chartValues[j].config.tmin?.toFixed(0)} ${chartValues[j].config.unit}`,
                },
              },
              {
                id: 'limit-max',
                color: '#858585',
                dashStyle: 'ShortDash',
                width: 2,
                value: chartValues[j].config.tmax?.toFixed(0),
                zIndex: 1,
                label: {
                  useHTML: true,
                  style: {
                    backgroundColor: '#858585',
                    color: 'white',
                    borderRadius: '3px',
                  },
                  align: 'right',
                  text: `${chartValues[j].config.tmax?.toFixed(0)} ${chartValues[j].config.unit}`,
                },
              },
            ]
          : [],

      labels: {
        format: `{value} ${chartValues[j].config.unit}`,
        style: {
          color: chartValues[j].config.color,
        },
      },
      id: `${chartValues[j].config.label}`,
    });

    xAxis = [
      {
        padding: 16,
        gridLineWidth: 1,
        gridLineColor: '#e9e9e9',
        crosshair: {
          width: 1,
          color: '#0b73c2',
        },
        events: {
          // setExtremes,afterSetExtremes
          setExtremes: function (event) {
            const currentChart = event.target;
            if (currentChart && currentChart.chart) {
              // const { min, max } = currentChart.chart.xAxis[0].getExtremes();
              if (
                typeof event.trigger !== 'undefined' &&
                event.trigger === 'zoom'
              ) {
                xMin = event.min;
                xMax = event.max;
                if (
                  event.trigger === 'zoom' &&
                  event.min === event.dataMin &&
                  event.max === event.dataMax
                ) {
                  // loadMoreFunc(
                  //   currentChart.chart.xAxis[0].categories[Math.floor(xMin)],
                  //   currentChart.chart.xAxis[0].categories[Math.ceil(xMax)],
                  //   false
                  // );
                } else {
                  loadMoreFunc(xMin, xMax, true);
                  if (xMin !== undefined && xMax !== undefined) {
                    const url = new URL(window.location.href);
                    if (window.location.href.indexOf('?') > -1) {
                      url.searchParams.set('tmn', xMin);
                      url.searchParams.set('tmx', xMax);
                      window.history.replaceState(null, null, url);
                    }
                  } else {
                    const url = new URL(window.location.href);
                    if (window.location.href.indexOf('?') > -1) {
                      url.searchParams.delete('tmn');
                      url.searchParams.delete('tmx');
                      window.history.replaceState(null, null, url);
                    }
                  }
                  // loadMoreFunc(
                  //   currentChart.chart.xAxis[0].categories[Math.floor(xMin)],
                  //   currentChart.chart.xAxis[0].categories[Math.ceil(xMax)],
                  //   true
                  // );
                }
              }

              // if (min != 0) {
              //   xMin = min;
              //   xMax = max;
              // }
            }
            const resetbtn = document.getElementsByClassName(
              'highcharts-reset-zoom'
            );
            if (resetbtn.length > 0) {
              resetbtn[0].style.display = 'none';
            }
          },
        },
        // labels: {
        //   formatter: function () {
        //     return Highcharts.dateFormat("%d.%m.%Y %H:%M:%S", this.value * 1000); // milliseconds not seconds
        //   },
        // },
        labels: {
          formatter: function () {
            // return Highcharts.dateFormat("%m-%e-%Y %H:%M:%S", this.value);
            const duration = Moment.duration(
              Moment(this.axis.dataMax).diff(Moment(this.axis.dataMin))
            );
            const totaldays = duration.asDays();

            if (totaldays > 1) {
              // return Moment(this.value, "DD.MM.YYYY HH:mm:ss")
              //   .utc()
              //   .format("DD.MM.YYYY");
              return Moment.utc(this.value).format('DD.MM.YYYY');
            } else {
              // return Moment(this.value, "DD.MM.YYYY HH:mm:ss")
              //   .utc()
              //   .format("DD.MM.YYYY HH:mm:ss");
              return Moment.utc(this.value).format(
                'DD.MM.YYYY ' + '<br/>' + 'HH:mm:ss'
              );
            }
            // return Moment.utc(this.value).format("DD.MM.YYYY HH:mm:ss");
          },
          // autoRotationLimit: 50,
        },
      },
    ];
  }

  return {
    xAxis,
    yAxis,
    series: seriesValues,
  };
}

export function getTrendChart(
  selectedOverlays,
  chart,
  selectedMain,
  filter,
  advanced_filter,
  loadMoreFunc,
  info,
  showThreshold
) {
  const data = getSingleTrendData(
    selectedOverlays,
    chart,
    selectedMain,
    filter,
    advanced_filter,
    loadMoreFunc,
    showThreshold
  );
  const options = {
    chart: {
      name: 'trendData',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      // borderColor: "#f03040",
      // borderWidth: 1,
      height: 600,
      marginBottom: 100,
      resetZoomButton: {
        theme: {
          display: 'none',
        },
      },
      events: {
        load: function () {
          document.getElementById('chartLoader').style.display = 'none';
          const chart = this;
          const xAxis = chart.xAxis[0];
          const url = new URL(window.location.href);
          const minValue = url.searchParams.get('tmn');
          const maxValue = url.searchParams.get('tmx');
          if (minValue && maxValue && minValue && maxValue) {
            xAxis.setExtremes(Number(minValue), Number(maxValue));
            chart.showResetZoom();
          }
          const legendSelection = url.searchParams.get('ttl');
          let splitArr = [];
          if (
            legendSelection !== undefined &&
            legendSelection !== null &&
            legendSelection !== ''
          ) {
            splitArr = legendSelection.split(',').map(Number);
          }
          splitArr.length > 0 &&
            chart.series.forEach((serie, index) => {
              if (splitArr.includes(index)) {
                serie.hide();
              }
            });
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: false,
    subtitle: {
      text: `<span class="sampleinterval">(${chart[0].config.resampleInterval} sampling)</span>`,
      align: 'right',
      y: 5,
    },
    // subtitle: {
    //   text: ""
    // },
    xAxis: data.xAxis,
    yAxis: data.yAxis,
    // tooltip: {
    //   shared: false,
    // },

    tooltip: {
      shared: true,
      formatter: function () {
        const points = this.points;

        const pointsLength = points.length;
        let tooltipMarkup = pointsLength
          ? '<span style="font-size: 10px">' +
            Moment.utc(points[0].x).format('DD.MM.YYYY HH:mm:ss') +
            '</span><br/>'
          : '';
        let index;
        let y_value1, y_value2;
        for (index = 0; index < pointsLength; index += 1) {
          // y_value_kwh = (points[index].y / 1000).toFixed(2);
          if (points[index].point.low === undefined) {
            y_value1 = points[index].y;
            if (points[index].series.name === 'Reliability') {
              y_value1 = points[index].y === 0 ? 'False' : 'True';
            }
            if (points[index].series.name === 'Diagnostic Event') {
              let temp = points[index].point.label;
              temp = temp.split(',');
              y_value1 = points[index].point.count;
              for (let t = 0; t < temp.length; t++) {
                y_value1 += '<br/>' + temp[t];
              }
              // y_value1 = points[index].point.label;
            }
            tooltipMarkup +=
              '<span style="color:' +
              points[index].point.color +
              '">\u25CF</span> ' +
              points[index].series.name +
              ': <b>' +
              y_value1 +
              ' ' +
              // points[index].series.userOptions.tooltip.valueSuffix +
              '</b><br/>';
          } else {
            y_value1 = points[index].point.low;
            y_value2 = points[index].point.high;

            tooltipMarkup +=
              '<span style="color:' +
              points[index].series.color +
              '">\u25CF</span> ' +
              ' Range: <b>' +
              y_value1 +
              ' ' +
              points[index].series.userOptions.tooltip.valueSuffix +
              ' - ' +
              y_value2 +
              ' ' +
              points[index].series.userOptions.tooltip.valueSuffix +
              '</b><br/>';
          }
        }

        return tooltipMarkup;
      },
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: function (e) {
            const url = new URL(window.location.href);
            const legendSelection = url.searchParams.get('ttl');
            let splitArr = [];
            if (
              legendSelection !== undefined &&
              legendSelection !== null &&
              legendSelection !== ''
            ) {
              splitArr = legendSelection.split(',').map(Number);
            }
            if (!this.visible === false) {
              splitArr.push(e.target.index);
            } else {
              splitArr = splitArr.filter(item => item !== e.target.index);
            }
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.set('ttl', splitArr.join(','));
              window.history.replaceState(null, null, url);
            }
          },
        },
      },
      line: {
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        //  gapSize: 5,
      },
      arearange: {
        cursor: 'pointer',
        fillOpacity: 1,
        opacity: 1,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    legend: {
      enabled: true,
      align: 'left',
      verticalAlign: 'top',
      padding: 0,
      itemMarginTop: 10,
      itemMarginBottom: 10,
      // layout: "horizontal",
      x: 0,
      y: 0,
      // floating: true,
      symbolPadding: 5,
      symbolWidth: 10,
      itemStyle: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '16px',
      },
      backgroundColor:
        (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
        'rgba(255,255,255,0.25)',
    },
    series: data.series,
    exporting: {
      menuItemDefinitions: {
        // export to excel via on click download button
        download: {
          onclick: () => {
            exportToExcel(
              formatDataToExcel(info[0], chart),
              sessionStorage.getItem('selectedProductDname'),
              true
            );
          },
          text: 'Download as .xlsx',
        },
      },
      buttons: {
        contextButton: {
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 0,
          x: 10,
          menuItems: [
            'printChart',
            isEntitlement('Product-Trend-Download') ? 'download' : null,
          ],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return options;
}

export function getSOHChart(chart) {
  chart.estimatedSoH = chart.estimatedSoH.map(item =>
    item.isbf ? Object.assign({}, item, { color: 'gray' }) : item
  );
  const options = {
    lang: {
      infoButtonKey: 'SOH calculations',
    },
    chart: {
      name: 'sohData',
      type: 'scatter',
      marginTop: 70,
      enableMouseTracking: true,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      zoomType: 'xy',
      shadow: false,
      spacing: [0, 40, 0, 0],
      resetZoomButton: {
        position: {
          align: 'right',
          verticalAlign: 'top',
          x: -40,
          y: 5,
        },
        relativeTo: 'chart',
      },
      height: 500,
    },
    credits: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    title: {
      text: null,
    },
    xAxis: {
      min: chart.designedSoH[0].x,
      max: chart.designedSoH[chart.designedSoH.length - 1].x,
      startOnTick: true,
      endOnTick: true,
      title: {
        text: `<b>Date</b>`,
        align: 'middle',
      },
      type: 'datetime',
      labels: {
        format: '{value:%Y}',
      },
      tickInterval: 365 * 24 * 3600 * 1000,
    },
    yAxis: {
      min: 75,
      max: 100,
      title: {
        text: `<b>State of health</b>`,
        align: 'middle',
      },
      labels: {
        format: `{value} %`,
      },
    },
    legend: {
      enabled: true,
      align: 'left',
      verticalAlign: 'top',
      padding: 0,
      itemMarginTop: 10,
      itemMarginBottom: 10,
      x: 0,
      y: 0,
      symbolPadding: 5,
      symbolWidth: 17,
      itemStyle: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '16px',
      },
      backgroundColor:
        (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
        'rgba(255,255,255,0.25)',
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.series.name}</b><br>
      Date: <b>${Moment.utc(this.point.x).format(
        'DD.MM.YYYY'
      )}</b><br />SOH: <b>${Number(this.point.y).toFixed(
        2
      )} % </b><br />Throughput: <b>${
        this.point.series.name === 'Estimated SoH'
          ? Math.round(Number(this.point.th))
          : 0
      } Ah </b><br />`;
      },
    },
    series: checkUserEntitlements('ESS:Product-SOH-DCurve')
      ? [
          {
            type: 'line',
            name: 'Designed SoH',
            data: chart.designedSoH,
            connectNulls: true,
            dashStyle: 'dash',
            lineWidth: 1,
            marker: {
              enabled: false,
            },
          },
          {
            type: 'scatter',
            name: 'Estimated SoH',
            data: chart.estimatedSoH,
            color: '#BF40BF',
            marker: {
              radius: 4,
              symbol: 'circle',
            },
          },
          {
            type: 'scatter',
            name: 'Missing Data',
            data: '',
            color: 'gray',
            marker: {
              radius: 4,
              symbol: 'circle',
            },
            events: {
              legendItemClick: function () {
                return false;
              },
            },
          },
          {
            color: '#21A67A',
            name: 'Start of operation',
            dashStyle: 'dash',
            type: 'line',
            lineWidth: 1,
            marker: {
              enabled: false,
            },
            data: [
              [chart.startofOperation, 75, 0],
              [chart.startofOperation, 100, 0],
            ],
          },
          {
            color: '#000000',
            name: 'Current operation',
            // dashStyle: "dash",
            type: 'line',
            lineWidth: 1,
            marker: {
              enabled: false,
            },
            data: [
              [chart.endofOperation, 75, 0],
              [chart.endofOperation, 100, 0],
            ],
          },
        ]
      : [
          {
            type: 'scatter',
            name: 'Estimated SoH',
            data: chart.estimatedSoH,
            color: '#BF40BF',
            marker: {
              radius: 4,
              symbol: 'circle',
            },
          },
          {
            type: 'scatter',
            name: 'Missing Data',
            data: '',
            color: 'gray',
            marker: {
              radius: 4,
              symbol: 'circle',
            },
            events: {
              legendItemClick: function () {
                return false;
              },
            },
          },
          {
            color: '#21A67A',
            name: 'Start of operation',
            dashStyle: 'dash',
            type: 'line',
            lineWidth: 1,
            marker: {
              enabled: false,
            },
            data: [
              [chart.startofOperation, 75, 0],
              [chart.startofOperation, 100, 0],
            ],
          },
          {
            color: '#000000',
            name: 'Current operation',
            // dashStyle: "dash",
            type: 'line',
            lineWidth: 1,
            marker: {
              enabled: false,
            },
            data: [
              [chart.endofOperation, 75, 0],
              [chart.endofOperation, 100, 0],
            ],
          },
        ],
    exporting: {
      buttons: {
        contextButton: {
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          x: 40,
          y: 50,
          menuItems: ['downloadPNG', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
        customButton: {
          align: 'right',
          x: 37,
          y: 120,
          onclick: () => {
            window.open(
              `${window.location.origin}/helpdoc/#state_of_health`, // ?utm_source=Online_help&utm_medium=help&utm_campaign=online_help
              '_blank'
            );
          },
          symbol: `url(${iconInfo})`,
          _titleKey: 'infoButtonKey',
        },
      },
    },
  };

  return options;
}

export function getScatterSingleSignal(data, trendsParam, props, lang) {
  if (!data || !props || !props.selectedScatter) return null;
  debug('dev')('getScatterSingleSignal', data, trendsParam, props);
  const current = [];

  let color = 0;
  data.forEach(c => {
    current.push({
      name: c.config.bogieName,
      color: colors[color],
      data: c.data,
      boostThreshold: 10000,
      turboThreshold: Number.MAX_VALUE,
      marker: {
        radius: 3,
      },
    });
    color += 1;
  });

  const options = {
    chart: {
      name: 'scatterData',
      type: 'scatter',
      marginTop: 70,
      enableMouseTracking: true,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      zoomType: 'xy',
      shadow: false,
      resetZoomButton: {
        position: {
          align: 'right',
          verticalAlign: 'top',
          x: -40,
          y: 5,
        },
        relativeTo: 'chart',
      },
      // borderColor: "#f03040",
      // borderWidth: 1,
      height: 500,
      events: {
        load: function () {
          document.getElementById('chartLoader').style.display = 'none';
        },
      },
    },
    credits: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    // trends: "Slip vs Speed",
    title: {
      enabled: true,
      text: `<b>${data[0].documentType}</b>`,
    },
    xAxis: {
      className: 'scatter-x-axis',
      title: {
        text: `<b>${data[0].config.xSignalName} [${data[0].config.xSignalUnit}]</b>`,
        align: 'middle',
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        y: 25,
      },
    },
    yAxis: {
      className: 'scatter-y-axis',
      title: {
        text: `<b>${data[0].config.ySignalName} [${data[0].config.ySignalUnit}]</b>`,
        align: 'middle',
      },
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      // x: 150,
      y: 20,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
      borderWidth: 0,
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 0,
          },
        },
        animation: false,
      },
      scatter: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.series.name}</b><br>
      ${data[0].config.xSignalName}: <b>${this.point.x}${
        data[0].config.xSignalUnit
      }</b><br />${data[0].config.ySignalName}: <b>${this.point.y} ${
        data[0].config.ySignalUnit
      }</b><br />${'Date/Time'}:<b>${Moment.utc(this.point.timeStamp).format(
        'DD:MM:YYYY HH:mm:ss'
      )}</b>`;
      },
    },
    series: current,

    exporting: {
      buttons: {
        contextButton: {
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 30,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 0,
          x: 10,
          menuItems: ['viewFullscreen', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return options;
}

export function getHistogramSingleFleet(seriesData, HistogramType) {
  if (HistogramType === 'BATTERY_SOC_RANGE_HISTOGRAM_TYPE') {
    seriesData.forEach(v => {
      v.y = Number((v.y / 60).toFixed(2));
    });
  }

  const options = {
    chart: {
      alignTicks: false,
      type: 'column',
      zoomType: 'x',
      backgroundColor:
        HistogramType === 'OPERATING_HOURS_HISTOGRAM_TYPE' ||
        HistogramType === 'TEMPERATURES_HISTOGRAM_TYPE'
          ? '#ffffff'
          : '#ffffff',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      marginTop: HistogramType === 'OPERATING_HOURS_HISTOGRAM_TYPE' ? 30 : 70,
      height: 400,
    },
    title: {
      text:
        HistogramType === 'OPERATING_HOURS_HISTOGRAM_TYPE'
          ? ''
          : HistogramType === 'TEMPERATURES_HISTOGRAM_TYPE'
            ? 'TEMPERATURES'
            : 'SOC RANGE USAGES',
      align: 'low',
      margin: 0,
      useHTML: true,
    },
    credits: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    xAxis: [
      {
        categories:
          HistogramType === 'OPERATING_HOURS_HISTOGRAM_TYPE'
            ? ['0-5', '5-8', '8-10', '10-12', '12-15', '15-18', '18+']
            : HistogramType === 'TEMPERATURES_HISTOGRAM_TYPE'
              ? [
                  '<-10',
                  '-10',
                  '0-10',
                  '10-15',
                  '15-20',
                  '20-25',
                  '25-30',
                  '30-35',
                  '35-40',
                  '>40',
                ]
              : [
                  '0-10',
                  '11-20',
                  '21-30',
                  '31-40',
                  '41-50',
                  '51-60',
                  '61-70',
                  '71-80',
                  '81-95',
                  '96-100',
                ],
        // minPadding: 0.15,
        // maxPadding: 0.15,
        startOnTick: false,
        endOnTick: false,
        lineWidth: 0,
        //  tickWidth: 0,
        tickWidth: 1,
        tickmarkPlacement: 'on',
        title: {
          text:
            HistogramType === 'OPERATING_HOURS_HISTOGRAM_TYPE'
              ? 'Time (h)'
              : HistogramType === 'TEMPERATURES_HISTOGRAM_TYPE'
                ? 'Temperature(°C)'
                : `Percentage of the  SoC range (%)`,
          // align: "low",
        },
        labels: {
          align: 'center',
        },
      },
    ],
    yAxis: {
      // tickInterval:
      //   HistogramType === "OPERATING_HOURS_HISTOGRAM_TYPE"
      //     ? 10
      //     : HistogramType === "TEMPERATURES_HISTOGRAM_TYPE"
      //     ? undefined
      //     : 10,
      lineWidth: 0,
      tickWidth: 0,
      reversed: false,
      tickAmount: 10,
      title: {
        text:
          HistogramType === 'OPERATING_HOURS_HISTOGRAM_TYPE'
            ? `Percentage (%)`
            : HistogramType === 'TEMPERATURES_HISTOGRAM_TYPE'
              ? `Hours (h)`
              : `Hours (h)`,
      },
      labels: {
        formatter: function () {
          return HistogramType === 'OPERATING_HOURS_HISTOGRAM_TYPE'
            ? this.value
            : HistogramType === 'TEMPERATURES_HISTOGRAM_TYPE'
              ? this.value
              : this.value;
        },
      },
    },
    plotOptions: {
      column: {
        // pointWidth: 60,
        pointPadding: 0,
        groupPadding: 0.2,
        shadow: false,
        colorByPoint: true,
      },
      series: {
        boostThreshold: 10000,
        turboThreshold: Number.MAX_VALUE,
      },
    },
    series: [
      {
        data: seriesData,
      },
    ],
    tooltip: {
      formatter: function () {
        return HistogramType === 'OPERATING_HOURS_HISTOGRAM_TYPE'
          ? ` ${'Percentage'}:<b> ${this.point.y} </b> <br />${'Operating hours'}:<b> ${this.point.hrs} </b>`
          : HistogramType === 'TEMPERATURES_HISTOGRAM_TYPE'
            ? ` ${'Hours'}:<b> ${this.point.y} </b>`
            : ` ${'State of charge'}:<b> ${this.point.y} </b>`;
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      y: 50,
      symbolHeight: 0.001,
      symbolWidth: 0.001,
      symbolRadius: 0.001,
      itemMarginBottom: 8,
      enabled: true,
      labelFormatter: function () {
        return '';
      },
    },

    exporting: {
      buttons: {
        contextButton: {
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 0,
          x: 10,
          menuItems: ['viewFullscreen', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return options;
}

// box plot chart starts from here

export function getBoxPlotSingleFleet(seriesData) {
  const options = {
    chart: {
      alignTicks: false,
      zoomType: 'xy',
      panning: true,
      panKey: 'shift',
      type: 'boxplot',
      height: 500,
      plotShadow: false,
      marginTop: 70,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `ENERGY USAGE BY FLEET`,
      align: 'low',
      margin: 0,
      useHTML: true,
    },
    xAxis: {
      // labels: {
      //   formatter: function () {
      //     // return Highcharts.dateFormat("%m-%e-%Y %H:%M:%S", this.value);
      //     return Moment.utc(this.value).format("DD:MM:YYYY");
      //   },
      // },
      type: 'category',
      min: 0,
      title: {
        text: `<b> Time </b>`,
        align: 'low',
      },
      startOnTick: true,
      endOnTick: true,
      lineWidth: 0,
      tickWidth: 0,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    yAxis: {
      title: {
        text: `<b> kWh </b>`,
        align: 'high',
      },
      plotLines: [
        {
          color: '#1f1f1f',
          width: 1,
          dashStyle: 'Dash',
          zIndex: 1,
        },
      ],
    },
    series: seriesData,

    plotOptions: {
      series: {
        stickyTracking: false,
        animation: false,
        pointWidth: 40,
      },
      scatter: {
        marker: {
          fillColor: 'white',
          lineWidth: 1,
          lineColor: '#1f1f1f',
        },
      },
      boxplot: {
        fillColor: '#00b050',
        medianColor: '#000000',
        stemColor: '#000000',
        whiskerColor: '#000000',
        medianWidth: 2,
        stemWidth: 2,
        whiskerWidth: 3,
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      y: 40,
      symbolRadius: 0,
    },
    tooltip: {
      // valueSuffix: "Volt",
      formatter: function () {
        if (this.point.series.name === 'Observations') {
          return `<b>${this.point.series.name}</b><br/>       
        ${'Maximum'}: <b>${this.point.high}</b><br/>
        ${'Upper quartile'}: <b>${this.point.q3}</b><br/>
        ${'Median'}: <b>${this.point.median}</b><br/>
        ${'Lower quartile'}: <b>${this.point.q1}</b><br/>
        ${'Minimum'}: <b>${this.point.low}</b>`;
        } else {
          return `<b>${this.point.series.name}</b><br/>${'Outlier value'}: <b>${this.point.y}</b>`;
        }
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 0,
          x: 10,
          menuItems: ['viewFullscreen', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return options;
}

// state of charge multicolor trend chart starts from here

export function getMultiColorTrend(seriesData) {
  const options = {
    chart: {
      type: 'line',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      shadow: false,
      marginTop: 70,
      height: 500,
      events: {
        load: function () {
          const minValue = localStorage.getItem('urlSCmin');
          const maxValue = localStorage.getItem('urlSCmax');
          const chart = this;
          const xAxis = chart.xAxis[0];
          const url = new URL(window.location.href);
          const legendSelection = url.searchParams.get('sci');
          let splitArr = [];
          if (
            legendSelection !== undefined &&
            legendSelection !== null &&
            legendSelection !== ''
          ) {
            splitArr = legendSelection.split(',').map(Number);
          }
          splitArr.length > 0 &&
            chart.series.forEach((serie, index) => {
              if (splitArr.includes(index)) {
                serie.hide();
              }
            });
          if (minValue && maxValue) {
            xAxis.setExtremes(Number(minValue), Number(maxValue));
            chart.showResetZoom();
            localStorage.setItem('urlSCmin', null);
            localStorage.setItem('urlSCmax', null);
          }
        },
      },
    },
    title: {
      text: 'STATE OF CHARGE',
      align: 'low',
      margin: 0,
      useHTML: true,
    },
    credits: {
      enabled: false,
    },
    boost: {
      //  useGPUTranslations: true,
      allowForce: false,
      usePreAllocated: true,
      // seriesThreshold: 1,
    },
    xAxis: {
      // minPadding: 0.15,
      // maxPadding: 0.15,
      labels: {
        formatter: function () {
          // return Highcharts.dateFormat("%m-%e-%Y %H:%M:%S", this.value);
          const duration = Moment.duration(
            Moment(this.axis.max).diff(Moment(this.axis.min))
          );

          const totaldays = duration.asDays();
          if (totaldays > 1) {
            return Moment.utc(this.value).format('DD.MM.YYYY');
          } else {
            return Moment.utc(this.value).format('DD.MM.YYYY HH:mm:ss');
          }
        },
        autoRotationLimit: 50,
      },
      // tickPixelInterval: 30,
      tickAmount: 8,
      padding: 16,
      crosshair: {
        width: 1,
        color: '#0b73c2',
      },
      startOnTick: false,
      endOnTick: false,
      lineWidth: 0,
      tickWidth: 0,
      title: {
        text: `<b>Time </b>`,
        align: 'low',
      },
      events: {
        setExtremes: function (e) {
          if (e.trigger) {
            const min = e.min;
            const max = e.max;
            if (min !== undefined && max !== undefined) {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('smn', min);
                url.searchParams.set('smx', max);
                window.history.replaceState(null, null, url);
              }
            } else {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('smn');
                url.searchParams.delete('smx');
                window.history.replaceState(null, null, url);
              }
            }
          }
        },
      },
    },
    yAxis: [
      {
        title: {
          text: 'SOC',
          align: 'high',
        },

        plotLines: [
          {
            value: 0,
            width: 1,
            color: '#808080',
          },
        ],
        labels: {
          formatter: function () {
            return this.value + '%';
          },
        },
      },
      {
        title: {
          text: 'Power [kW]',
          align: 'high',
        },
        opposite: true,
      },
    ],
    tooltip: {
      // valueSuffix: "Volt",
      formatter: function () {
        if (this.point.series.name === 'Power [kW]') {
          return `<b> ${
            this.point.series.name
          }</b><br>${'Date/Time'}:<b> ${Moment.utc(this.x).format(
            'DD.MM.YYYY HH:mm:ss'
          )}</b><br /> ${'Power'}:<b> ${this.point.y} [kW]</b>`;
        } else {
          return `<b> ${
            this.point.series.name
          }</b><br>${'Date/Time'}:<b> ${Moment.utc(this.x).format(
            'DD.MM.YYYY HH:mm:ss'
          )}</b><br /> ${'State of charge'}:<b> ${this.point.y}% </b>`;
        }
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      y: 50,
      symbolRadius: 0,
      itemMarginBottom: 8,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        // curve: false,
        boostThreshold: 1,
        turboThreshold: Number.MAX_VALUE,
        events: {
          legendItemClick: function (e) {
            const url = new URL(window.location.href);
            const legendSelection = url.searchParams.get('sci');
            let splitArr = [];
            if (
              legendSelection !== undefined &&
              legendSelection !== null &&
              legendSelection !== ''
            ) {
              splitArr = legendSelection.split(',').map(Number);
            }
            if (!this.visible === false) {
              splitArr.push(e.target.index);
            } else {
              splitArr = splitArr.filter(item => item !== e.target.index);
            }
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.set('sci', splitArr.join(','));
              window.history.replaceState(null, null, url);
            }
          },
        },
      },
    },
    series: [
      {
        name: 'SOC',
        yAxis: 0,
        showInLegend: false,
        data: seriesData.dc,
        zones: [
          {
            value: 10,
            color: '#ff0028',
          },
          {
            value: 50,
            color: '#fdd702',
          },
          {
            value: 95,
            color: '#0a7f3b',
          },
          {
            color: '#ff0028',
          },
        ],
      },
      {
        name: 'Power [kW]',
        yAxis: 1,
        data: seriesData.dcp,
        color: '#f57c64',
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 0,
          x: 10,
          menuItems: ['viewFullscreen', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return options;
}

// Cell voltage trend chart starts from here

export function getCellVoltageTrend(seriesData) {
  const options = {
    chart: {
      type: 'line',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      shadow: false,
      marginTop: 70,
      height: 500,
      events: {
        load: function () {
          const minValue = localStorage.getItem('urlLCmin');
          const maxValue = localStorage.getItem('urlLCmax');
          const chart = this;
          const xAxis = chart.xAxis[0];
          const url = new URL(window.location.href);
          const legendSelection = url.searchParams.get('lci');
          let splitArr = [];
          if (
            legendSelection !== undefined &&
            legendSelection !== null &&
            legendSelection !== ''
          ) {
            splitArr = legendSelection.split(',').map(Number);
          }
          splitArr.length > 0 &&
            chart.series.forEach((serie, index) => {
              if (splitArr.includes(index)) {
                serie.hide();
              }
            });
          if (minValue && maxValue) {
            xAxis.setExtremes(Number(minValue), Number(maxValue));
            chart.showResetZoom();
            localStorage.setItem('urlLCmin', null);
            localStorage.setItem('urlLCmax', null);
          }
        },
      },
    },
    title: {
      text: 'CELL VOLTAGE AND POWER',
      align: 'low',
      margin: 0,
      useHTML: true,
    },
    credits: {
      enabled: false,
    },
    boost: {
      // useGPUTranslations: true,
      usePreAllocated: true,
      // seriesThreshold: 1,
      // allowForce: true,
    },
    xAxis: {
      labels: {
        formatter: function () {
          const duration = Moment.duration(
            Moment(this.axis.max).diff(Moment(this.axis.min))
          );
          const totaldays = duration.asDays();
          if (totaldays > 1) {
            return Moment.utc(this.value).format('DD.MM.YYYY');
          } else {
            return Moment.utc(this.value).format('DD.MM.YYYY HH:mm:ss');
          }
        },
      },
      tickPixelInterval: 30,
      crosshair: {
        width: 1,
        color: '#0b73c2',
      },
      startOnTick: false,
      endOnTick: false,
      lineWidth: 0,
      tickWidth: 0,
      title: {
        text: `<b>Time </b>`,
        align: 'low',
      },
      events: {
        setExtremes: function (e) {
          if (e.trigger) {
            const min = e.min;
            const max = e.max;
            if (min !== undefined && max !== undefined) {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('lmn', min);
                url.searchParams.set('lmx', max);
                window.history.replaceState(null, null, url);
              }
            } else {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('lmn');
                url.searchParams.delete('lmx');
                window.history.replaceState(null, null, url);
              }
            }
          }
        },
      },
    },

    yAxis: [
      {
        title: {
          text: 'Voltage [V]',
          align: 'high',
        },
      },
      {
        title: {
          enabled: false,
        },
        visible: false,
      },
      {
        title: {
          enabled: false,
        },
        visible: false,
      },
      {
        gridLineWidth: 1,

        endOnTick: false,
        tickInterval: 6,
        tickAmount: 6,
        opposite: true,
        title: {
          text: 'Power [kW]',
          align: 'high',
        },
      },
    ],
    tooltip: {
      // valueSuffix: "Volt",
      formatter: function () {
        if (this.point.series.name === 'Power [kW]') {
          return `<b> ${
            this.point.series.name
          }</b><br>${'Date/Time'}:<b> ${Moment.utc(this.x).format(
            'DD.MM.YYYY HH:mm:ss'
          )}</b><br /> ${'Power'}:<b> ${this.point.y} [kW]</b>`;
        } else {
          return `<b> ${
            this.point.series.name
          }</b><br>${'Date/Time'}:<b> ${Moment.utc(this.x).format(
            'DD.MM.YYYY HH:mm:ss'
          )}</b><br /> ${'Voltage'}:<b> ${this.point.y} V </b>`;
        }
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      y: 50,
      symbolRadius: 0,
      itemMarginBottom: 8,
    },

    plotOptions: {
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        // curve: false,
        boostThreshold: 10000,
        turboThreshold: Number.MAX_VALUE,
        events: {
          legendItemClick: function (e) {
            const url = new URL(window.location.href);
            const legendSelection = url.searchParams.get('lci');
            let splitArr = [];
            if (
              legendSelection !== undefined &&
              legendSelection !== null &&
              legendSelection !== ''
            ) {
              splitArr = legendSelection.split(',').map(Number);
            }
            if (!this.visible === false) {
              splitArr.push(e.target.index);
            } else {
              splitArr = splitArr.filter(item => item !== e.target.index);
            }
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.set('lci', splitArr.join(','));
              window.history.replaceState(null, null, url);
            }
          },
        },
        line: {
          allowPointSelect: false,
          cursor: 'pointer',
        },
      },
    },
    // series: formatedSeriesData,
    series: seriesData,
    exporting: {
      buttons: {
        contextButton: {
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 0,
          x: 10,
          menuItems: ['viewFullscreen', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return options;
}

export function getColomChartWithLine(
  seriesData,
  chartType,
  redirectToTimetrend
) {
  const options = {
    chart: { zoomType: 'y', height: 400, marginLeft: 130 },
    plotOptions: {
      column: {
        pointPlacement: 'between',
        // stickyTracking: true,
        // enableMouseTracking: true,
        // pointWidth: 60,
        pointPadding: 0,
        groupPadding: 0.2,
        stacking: 'normal',
        cursor: 'pointer',
        allowPointSelect: false,
        states: {
          select: { color: null, borderWidth: 0, borderColor: null },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
      line: {
        pointPlacement: 'between',
        stickyTracking: false,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            // enabled: false,
            lineWidth: 2,
          },
          inactive: {
            opacity: 0.4,
          },
        },
      },
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: [
      {
        startOnTick: false,
        categories: seriesData.xAxis,
        tickWidth: 1,
        tickmarkPlacement: 'on',
        labels: {
          align: 'left',
          // rotation: 0,
        },
        crosshair: true,
        title: {
          text: seriesData.xTitle,
          align: 'low',
          style: {
            fontWeight: 'bold',
          },
        },
      },
    ],
    yAxis: [
      {
        // Primary yAxis

        title: {
          text: seriesData.yTitle,
          align: 'high',
          margin: 55,
          style: {
            fontWeight: 'bold',
          },
          /*   style: {
                color: Highcharts.getOptions().colors[1]
            } */
        },
        min: 0,
        tickAmount: 10,
      },
      {
        // Secondary yAxis
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        opposite: true,
      },
      {
        // Secondary yAxis
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        opposite: true,
      },
    ],
    tooltip: {
      useHTML: false,
      style: {
        fontSize: '16px',
        lineHeight: '20px',
      },
      formatter: function () {
        sessionStorage.setItem(
          'hoveredCategory',
          seriesData.xAxis[this.point.index + 1]
        );
        const actb = sessionStorage.getItem('activeTab');
        let tooltipText;
        if (this.point.index < seriesData.xAxis.length - 1) {
          tooltipText = `<strong>${seriesData.xAxis[this.point.index]} to ${
            seriesData.xAxis[this.point.index + 1]
          }</strong> <br/> 
          ${
            (actb === 'usgpow' || actb === 'usgdod') &&
            this.series.name !== 'Threshold'
              ? `<strong> Total used throughput: ${this.point.stackTotal} ${this.series.userOptions.unit} </strong> <br/>`
              : this.series.name !== 'Threshold'
                ? `<strong> Total used hours: ${this.point.stackTotal} ${this.series.userOptions.unit} </strong> <br/>`
                : ''
          }
          <span style="color:${this.series.color}">\u25A0</span> ${
            // &#9632;
            (actb === 'usgpow' || actb === 'usgdod') &&
            this.series.name.includes('hours')
              ? this.series.name.replace('hours', 'throughput')
              : this.series.name
          }: ${this.point.y} ${this.series.userOptions.unit} `;
        } else {
          tooltipText = `<strong>${
            seriesData.xAxis[this.point.index]
          }</strong> <br/>
          ${
            (actb === 'usgpow' || actb === 'usgdod') &&
            this.series.name !== 'Threshold'
              ? `<strong> Total used throughput: ${this.point.stackTotal} ${this.series.userOptions.unit} </strong> <br/>`
              : this.series.name !== 'Threshold'
                ? `<strong> Total used hours: ${this.point.stackTotal} ${this.series.userOptions.unit} </strong> <br/>`
                : ''
          }
          <span style="color:${this.series.color}">\u25A0</span>  ${
            // &#9632;
            (actb === 'usgpow' || actb === 'usgdod') &&
            this.series.name.includes('hours')
              ? this.series.name.replace('hours', 'throughput')
              : this.series.name
          }: ${this.point.y} ${this.series.userOptions.unit} `;
        }
        return tooltipText;
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      y: 50,
      symbolHeight: 0.001,
      symbolWidth: 0.001,
      symbolRadius: 0.001,
      itemMarginBottom: 8,
      enabled: true,
      labelFormatter: function () {
        return '';
      },
    },
    series:
      seriesData.dts === true
        ? [
            {
              name: seriesData.col[0].name,
              unit: seriesData.col[0].unit,
              type: 'column',
              color: '#FFA200',
              yAxis: 0,
              data: seriesData.col[0].data,
            },
            {
              name: seriesData.col[1].name,
              unit: seriesData.col[1].unit,
              type: 'column',
              color: '#3B786E',
              yAxis: 0,
              data: seriesData.col[1].data,
            },
            {
              name: seriesData.col[2].name,
              unit: seriesData.col[2].unit,
              type: 'line',
              color: '#0052FF',
              yAxis: 0,
              data: seriesData.col[2].data,
              step: 'center',
            },
          ]
        : [
            {
              name: seriesData.col[0].name,
              unit: seriesData.col[0].unit,
              type: 'column',
              color: '#FFA200',
              yAxis: 0,
              data: seriesData.col[0].data,
            },
            {
              name: seriesData.col[1].name,
              unit: seriesData.col[1].unit,
              type: 'column',
              color: '#3B786E',
              yAxis: 0,
              data: seriesData.col[1].data,
            },
          ],
    exporting: {
      buttons: {
        contextButton: {
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 20,
          x: 10,
          menuItems: ['viewFullscreen', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
        customButton: {
          align: 'right',
          x: 7,
          y: 3,
          onclick: () => {
            // window.open(
            //   `${window.location.origin}/helpdoc/#state_of_health`,
            //   "_blank"
            // );
          },
          symbol: `url(${iconInfo})`,
          _titleKey: 'infoButtonKey',
        },
      },
    },
  };

  return options;
}

export function getColomChartWithCenterLine(seriesData, chartType) {
  const options = {
    chart: { type: 'column', zoomType: 'y', height: 400, marginLeft: 130 },
    plotOptions: {
      column: {
        pointPlacement: 'between',

        // pointWidth: 60,
        pointPadding: 0,
        groupPadding: 0.2,
        stacking: 'normal',
        states: {
          select: { color: null, borderWidth: 0, borderColor: null },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: [
      {
        tickWidth: 1,
        tickmarkPlacement: 'on',
        labels: {
          align: 'left',
        },
        categories: seriesData.xAxis,
        crosshair: true,
        title: {
          text: seriesData.xTitle,
          align: 'low',
          style: {
            fontWeight: 'bold',
          },
        },
      },
    ],
    yAxis: {
      min: 0,
      // max: 2000,
      // tickInterval: 50,
      tickAmount: 10,
      title: {
        text: seriesData.yTitle,
        align: 'high',
        margin: 55,
        style: {
          fontWeight: 'bold',
        },
      },
      plotLines: [
        {
          color: '#000000',
          width: 2,
          value: 100,
          zIndex: 5,
        },
      ],
      stackLabels: {
        enabled: false,
      },
    },
    tooltip: {
      // shared: true,
      useHTML: false,
      style: {
        fontSize: '16px',
        lineHeight: '20px',
      },
      formatter: function () {
        let tooltipText;
        if (this.point.index < seriesData.xAxis.length - 1) {
          tooltipText = `<strong>${seriesData.xAxis[this.point.index]} to ${
            seriesData.xAxis[this.point.index + 1]
          }</strong> <br/> <strong> Total used: ${
            this.point.stackTotal
          } % </strong> <br/> <span style="color:${
            this.series.color
          }">\u25A0</span> ${this.series.name}: ${this.point.y} % `;
          // &#9632;
        } else {
          tooltipText = `<strong>${
            seriesData.xAxis[this.point.index]
          }</strong> <br/><strong>Total used: ${
            this.point.stackTotal
          } % </strong> <br/><span style="color:${
            this.series.color
          }">\u25A0</span>  ${this.series.name}: ${this.point.y} %`;
          // &#9632;
        }

        return tooltipText;
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      y: 50,
      symbolHeight: 0.001,
      symbolWidth: 0.001,
      symbolRadius: 0.001,
      itemMarginBottom: 8,
      enabled: true,
      labelFormatter: function () {
        return '';
      },
    },
    series: seriesData.col,
    exporting: {
      buttons: {
        contextButton: {
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 20,
          x: 10,
          menuItems: ['viewFullscreen', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
        customButton: {
          align: 'right',
          x: 7,
          y: 3,
          onclick: () => {},
          symbol: `url(${iconInfo})`,
          _titleKey: 'infoButtonKey',
        },
      },
    },
  };

  return options;
}
