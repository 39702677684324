import { saveAs } from 'file-saver';
import lodash from 'lodash';
import Moment from 'moment';
import * as XLSX from 'xlsx';

import store from './../store';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export const exportToExcel = (data, fileName, multipleSheets, author) => {
  const wb = {
    Sheets: {},
    SheetNames: [],
  };

  if (multipleSheets) {
    data.forEach(item => {
      const { sheetName, details } = item;

      const ws = XLSX.utils.json_to_sheet(details);
      wb.Sheets[sheetName] = ws;
      wb.SheetNames.push(sheetName);
    });
  } else {
    const ws = XLSX.utils.json_to_sheet(data);
    wb.Sheets.data = ws;
    wb.SheetNames.push('data');
  }

  if (!wb.Props)
    wb.Props = {
      Author: 'Condition monitoring for ESS - ' + author,
      CreatedDate: new Date(),
    };

  const eb = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([eb], { type: EXCEL_TYPE });
  saveAs(blob, fileName + EXCEL_EXTENSION);
};

export const platformVersion = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const state = store.getState();
  const fleetList = state.get('graphData').get('fleetsList');
  const version =
    fleetList &&
    fleetList.filter(item => item.nm === searchParams.get('ft'))[0]?.pl;

  return version;
};

export const applicationView = () => {
  const url = window.location.href;
  const baseUrl = url.split('?')[0];
  const urlView = baseUrl.split('/');
  const view = urlView[urlView.length - 1];
  return view;
};

export const formatDataToExcel = (info, details) => {
  const platform = platformVersion();
  const sheetMap = new Map();
  const dataToformat = lodash.cloneDeep(details);
  // set the info sheet data
  let infodata = [];

  if (platform < 3) {
    infodata = [
      {
        'ESS Details': 'ESS type',
        ' ': info.typ,
        '': ' ',
      },
      {
        'ESS Details': 'ESS article',
        ' ': info.bpa,
        '': ' ',
      },
      {
        'ESS Details': 'ESS serial no',
        ' ': info.bps,
        '': ' ',
      },
      {
        'ESS Details': 'PEC article no',
        ' ': info.pa,
        '': ' ',
      },
      {
        'ESS Details': 'PEC serial no',
        ' ': info.ip === 'TH922EL33L4512194' ? 'C' + info.ps : info.ps, // changes done to make swisstrolley data correct
        '': ' ',
      },
      {
        'ESS Details': 'PEC software version',
        ' ': info.swv,
        '': ' ',
      },
      {
        'ESS Details':
          info.ip === 'TH922EL33L4512194' // changes done to make swisstrolley data correct
            ? 'Vehicle info number'
            : 'PEC IP address',
        ' ': info.ip,
        '': ' ',
      },
      {
        'ESS Details': 'RCM library version',
        ' ': info.rcm,
        '': ' ',
      },
      // eslint-disable-next-line no-dupe-keys
      { '': '', ' ': '', '': '' },
      { 'ESS Details': 'Specification', ' ': '', '': 'Unit' },
      {
        'ESS Details': 'Rated capacity',
        ' ': info.rc,
        '': ' Ah',
      },
      {
        'ESS Details': 'Rated voltage',
        ' ': info.rv,
        '': ' V',
      },
      {
        'ESS Details': 'Rated energy',
        ' ': info.rp,
        '': ' kWh',
      },
    ];
  } else {
    infodata = [
      {
        'ESS Details': 'Battery Pack Serial no',
        ' ': info.bps,
      },
      {
        'ESS Details': 'SCU firmware version',
        ' ': info.sfv,
      },
      {
        'ESS Details': 'SCU serial no',
        ' ': ' ' + info.scu,
      },
      {
        'ESS Details': 'SCU config version',
        ' ': info.scv,
      },
      {
        'ESS Details': 'String sys no',
        ' ': info.bpa,
      },
      {
        'ESS Details': 'Checksum of SCU config',
        ' ': info.scc,
      },
    ];
  }
  sheetMap.set('Info', infodata);

  const mergedData = [];

  // make each signal data with key lablel added
  dataToformat.forEach(item => {
    item.data.forEach(subitem => {
      Object.keys(subitem).forEach(key => {
        if (key !== 'timestamp') {
          // if (key === "avg") {
          const unit =
            item.config.unit !== ' ' ? ' [' + item.config.unit + ']' : ''; // dont add () when no unit
          subitem[
            `${item.config.label} - ${
              key.charAt(0).toUpperCase() + key.slice(1).toLowerCase() // make max -- Max
            } - ${unit} `
          ] = subitem[key];
          delete subitem[key];
          // } else {
          //   delete subitem[key];
          // }
        } else {
          subitem.Timestamp = subitem[key];
          delete subitem[key];
        }
      });
    });
    mergedData.push(item.data);
  });

  // make the data into a flat array for easy operation and improve performance
  const flatMergeData = mergedData.flat();
  // get all the uniquw time stamps from te array
  const timestamps = new Set([...flatMergeData.map(item => item.Timestamp)]);

  const finaldata = [];
  // for each timestamp pick all the signal values from the array and make a single object
  timestamps.forEach(timestamp => {
    const timed_data = flatMergeData.filter(
      item => item.Timestamp === timestamp
    );
    finaldata.push(
      timed_data.reduce((acc, cur) => (acc = { ...acc, ...cur }), {})
    );
  });

  // sort data based on timestamps
  finaldata.sort((x, y) => x.Timestamp - y.Timestamp);

  finaldata.forEach(element => {
    element.Timestamp = Moment.utc(element.Timestamp).format(
      'YYYY/MM/DD HH:mm:ss'
    );
  });

  // set the Data Sheet
  sheetMap.set('Data', finaldata);

  // rest of the signal data
  details &&
    details.forEach(item => {
      const unit =
        item.config.unit !== ' ' ? ' (' + item.config.unit + ')' : ''; // dont add () when no unit

      const sheetName = item.config.label + unit;
      if (!sheetMap.has(sheetName)) {
        sheetMap.set(sheetName, []);
      }
      item.data.forEach(element => {
        element.timestamp = Moment.utc(element.timestamp).format(
          'YYYY/MM/DD HH:mm:ss'
        );
      });

      sheetMap.get(sheetName).push(...item.data);
    });

  return [...sheetMap].map(([sheetName, details]) => ({
    sheetName,
    details,
  }));

  // sample data format
  // [{
  //   sheetName:"sheet1",
  //   details:[{ name: 'name01', age: '20', sex: 'M' },
  //     { name: 'name02', age: '22', sex: 'F' },
  //     { name: 'name03', age: '20', sex: 'M' }]
  // },{
  //   sheetName:"sheet2",
  //   details:[{ name: 'name01', age: '20', sex: 'M' },
  //     { name: 'name02', age: '22', sex: 'F' },
  //     { name: 'name03', age: '20', sex: 'M' }]
  // }]
};
