import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import Breadcrumb from '../../components/Breadcrumb';
import DashboardFilter from '../../components/DashboardFilter';
import DashboardMap from '../../components/DashboardMap';
import FleetList from '../../components/FleetList';
import Loading from '../../components/Loading';
import {
  checkUserEntitlements,
  userConfig as configUser,
} from '../../userConfig';
import { fetchActiveMessages } from '../Broadcast/actions';
import { setFleetName } from '../FleetView/actions';
import {
  selectedEndDateSelector,
  selectedStartDateSelector,
} from '../FleetView/selectors';
import {
  fetchAllFleetList,
  fetchFilterList,
  fetchFleetList,
  fetchMonitoredCount,
} from './actions';
import {
  allFleetDataSelector,
  filterDataSelector,
  fleetDataSelector,
  loadingFilterSelector,
  loadingMonitoredCountSelector,
  loadingSelector,
  mapDataSelector,
  monitoredCountDataSelector,
} from './selectors';
import './styles.scss';

moment.locale('en');

let userConfig;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    window.dashBoardComponent = this;
    this.state = {
      fleetDataForFilter: props.fleetData,
      allFleetData: props.allFleetData,
      filterItems: {
        isGoodSlected: false,
        isPoorSelected: false,
        isUnknownSelected: false,
        isFavoriteSelected: false,
        Sites: 'All',
        Customers: 'All',
        Fleets: 'All',
        sitesList: [],
        customersList: [],
        fleetsList: [],
        goodCount: '',
        poorCount: '',
        unknownCount: '',
      },
      fleettoSearch: '',
      fleetDataFiltered: null,
      mapZoom: 5,
      mapIconSize: 32,
      mapLat: 47.558395,
      mapLng: 7.573271,
    };
    userConfig = configUser();
  }

  componentDidMount() {
    // this.props.getMapData();
    this.props.getFilterList();
    this.props.getAllFleetList('All', 'All', 'All', true, true, true);
    // await this.props.getFleetList("All", "All", "All", true, true, true);
    this.setFilterData(this.props.filterData);
    this.props.getMonitoredCount();
    this.props.fetchMessages();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.filterData !== nextProps.filterData) {
      this.setFilterData(nextProps.filterData);
    }
  }

  setFilterData = async filterData2 => {
    if (filterData2 !== null) {
      const sitesArray = [];
      const customersArray = [];
      const fleetsArray = [];
      let siteCount = 0;
      let customerCount = 0;
      let fleetCount = 0;
      let goodCount = 0;
      let poorCount = 0;
      let unknownCount = 0;
      siteCount = filterData2.length;
      sitesArray.push('All sites (' + siteCount + ')');
      filterData2.forEach(sites => {
        sitesArray.push(sites.dsit);
        customerCount += sites.cus.length;
      });
      customersArray.push('All customers (' + customerCount + ')');
      fleetsArray.push('All fleets (' + fleetCount + ')');
      filterData2.forEach(sites => {
        sites.cus.forEach(customers => {
          customersArray.push(customers.cu);
          customers.flt.forEach(fleets => {
            fleetsArray.push(fleets.fld);
            if (fleets.con === 'ok') {
              goodCount++;
            }
            if (fleets.con === 'trip') {
              poorCount++;
            }
            if (fleets.con === 'unknown') {
              unknownCount++;
            }
          });
        });
      });
      fleetCount = fleetsArray.length - 1;
      fleetsArray[0] = 'All fleets (' + fleetCount + ')';

      this.setState(
        {
          filterItems: {
            isGoodSlected: false,
            isPoorSelected: false,
            isUnknownSelected: false,
            isFavoriteSelected: false,
            Sites: sitesArray[0],
            Customers: customersArray[0],
            Fleets: fleetsArray[0],
            sitesList: sitesArray,
            customersList: customersArray,
            fleetsList: fleetsArray,
            goodCount,
            poorCount,
            unknownCount,
          },
          fleettoSearch: '',
          mapZoom: 5,
          mapIconSize: 32,
          mapLat: 47.558395,
          mapLng: 7.573271,
        },

        () => {
          this.filterFleetData();
        }
      );
    }
  };

  handleFilterDropdownChange = (value, name, lat, lng) => {
    this.setState(
      prevState => ({
        filterItems: {
          // object that we want to update
          ...prevState.filterItems, // keep all other key-value pairs
          [name]: value, // update the value of specific key
        },
      }),
      () => {
        // this.filterFleetData();
      }
    );

    const customersArray = [];
    let fleetsArray = [];
    let goodCount = 0;
    let poorCount = 0;
    let unknownCount = 0;
    this.props.filterData.forEach((sites, index) => {
      if (name === 'Sites') {
        if (value === sites.dsit) {
          customersArray.push('All customers (' + sites.cus.length + ')');
          fleetsArray.push('All fleets');
          sites.cus.forEach(customers => {
            customersArray.push(customers.cu);
            // fleetsArray.push(...customers.objectSubtype2);
            customers.flt.forEach(fleets => {
              // fleetsArray.push(fleets.fl);
              fleetsArray.push(fleets.fld);
              if (fleets.con === 'ok') {
                goodCount++;
              }
              if (fleets.con === 'trip') {
                poorCount++;
              }
              if (fleets.con === 'unknown') {
                unknownCount++;
              }
            });
          });
          fleetsArray[0] = 'All fleets (' + (fleetsArray.length - 1) + ')';
          this.setState(
            prevState => ({
              filterItems: {
                ...prevState.filterItems,
                Customers: customersArray[0],
                Fleets: fleetsArray[0],
                customersList: customersArray,
                fleetsList: fleetsArray,
                goodCount,
                poorCount,
                unknownCount,
              },
              fleettoSearch: '',
              mapZoom: 8,
              mapIconSize: 48,
              mapLat: sites.lat,
              mapLng: sites.lng,
            }),
            () => {
              this.filterFleetData();
            }
          );
        }
        if (value.includes('All sites') && index === 0) {
          const sitesArray = [];
          const customersArray = [];
          const fleetsArray = [];
          let siteCount = 0;
          let customerCount = 0;
          let fleetCount = 0;

          siteCount = this.props.filterData.length;
          sitesArray.push('All sites (' + siteCount + ')');
          this.props.filterData.forEach(sites => {
            sitesArray.push(sites.dsit);
            customerCount += sites.cus.length;
          });
          customersArray.push('All customers (' + customerCount + ')');
          fleetsArray.push('All fleets (' + fleetCount + ')');
          this.props.filterData.forEach((sites, index) => {
            sites.cus.forEach((customers, index) => {
              customersArray.push(customers.cu);
              customers.flt.forEach((fleets, index) => {
                fleetsArray.push(fleets.fld);
                if (fleets.con === 'ok') {
                  goodCount++;
                }
                if (fleets.con === 'trip') {
                  poorCount++;
                }
                if (fleets.con === 'unknown') {
                  unknownCount++;
                }
              });
            });
          });
          fleetCount = fleetsArray.length - 1;
          fleetsArray[0] = 'All fleets (' + fleetCount + ')';

          this.setState(
            prevState => ({
              filterItems: {
                ...prevState.filterItems,
                Sites: sitesArray[0],
                Customers: customersArray[0],
                Fleets: fleetsArray[0],
                sitesList: sitesArray,
                customersList: customersArray,
                fleetsList: fleetsArray,
                goodCount,
                poorCount,
                unknownCount,
              },
              fleettoSearch: '',
              mapZoom: 5,
              mapIconSize: 32,
              mapLat: 47.558395,
              mapLng: 7.573271,
            }),
            () => {
              this.filterFleetData();
            }
          );
        }
      }
      if (name === 'Customers') {
        sites.cus.forEach((customers, index) => {
          if (customers.cu === value) {
            fleetsArray.push('All fleets (' + customers.flt.length + ')');
            customers.flt.forEach((fleets, index) => {
              fleetsArray.push(fleets.fld);
              if (fleets.con === 'ok') {
                goodCount++;
              }
              if (fleets.con === 'trip') {
                poorCount++;
              }
              if (fleets.con === 'unknown') {
                unknownCount++;
              }
            });

            this.setState(
              prevState => ({
                filterItems: {
                  ...prevState.filterItems,
                  Fleets: fleetsArray[0],
                  fleetsList: fleetsArray,
                  goodCount,
                  poorCount,
                  unknownCount,
                },
                fleettoSearch: '',
              }),
              () => {
                this.filterFleetData();
              }
            );
          }
          if (value.includes('All customers') && index === 0) {
            fleetsArray = [];
            goodCount = 0;
            poorCount = 0;
            unknownCount = 0;
            fleetsArray.push('All fleets');
            this.props.filterData.forEach((sites, index2) => {
              sites.cus.forEach((customers, index3) => {
                customers.flt.forEach((fleets, index) => {
                  fleetsArray.push(fleets.fld);
                  if (fleets.con === 'ok') {
                    goodCount++;
                  }
                  if (fleets.con === 'trip') {
                    poorCount++;
                  }
                  if (fleets.con === 'unknown') {
                    unknownCount++;
                  }
                });
              });
            });
            fleetsArray[0] = 'All fleets (' + (fleetsArray.length - 1) + ')';
            this.setState(
              prevState => ({
                filterItems: {
                  ...prevState.filterItems,
                  Fleets: fleetsArray[0],
                  fleetsList: fleetsArray,
                  goodCount,
                  poorCount,
                  unknownCount,
                },
                fleettoSearch: '',
              }),
              () => {
                this.filterFleetData();
              }
            );
          }
        });
      }
      if (name === 'Fleets') {
        sites.cus.forEach((customers, index) => {
          customers.flt.forEach((fleets, index) => {
            if (fleets.fld === value || value.includes('All fleets')) {
              if (fleets.con === 'ok') {
                goodCount++;
              }
              if (fleets.con === 'trip') {
                poorCount++;
              }
              if (fleets.con === 'unknown') {
                unknownCount++;
              }

              this.setState(
                prevState => ({
                  filterItems: {
                    ...prevState.filterItems,
                    goodCount,
                    poorCount,
                    unknownCount,
                  },
                  fleettoSearch: '',
                }),
                () => {
                  this.filterFleetData();
                }
              );
            }
          });
        });
      }
    });
  };

  onGoodFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isGoodSlected: !this.state.filterItems.isGoodSlected,
        },
      }),
      () => {
        this.filterFleetData();
      }
    );
  };

  onPoorFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isPoorSelected: !this.state.filterItems.isPoorSelected,
        },
      }),
      () => {
        this.filterFleetData();
      }
    );
  };

  onUnknownFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isUnknownSelected: !this.state.filterItems.isUnknownSelected,
        },
      }),
      () => {
        this.filterFleetData();
      }
    );
  };

  onFavoriteFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isFavoriteSelected: !this.state.filterItems.isFavoriteSelected,
        },
      }),
      () => {
        this.filterFleetData();
      }
    );
  };

  onResetBtnClick = () => {
    this.setFilterData(this.props.filterData);
  };

  redirectToFleetView = (selectedFleet, selectedSite, selectedFleetType) => {
    this.props.setFleetName(selectedFleet);
    sessionStorage.setItem('selectedFleet', selectedFleet);
    sessionStorage.setItem('selectedFleetType', selectedFleetType);
    sessionStorage.setItem('selectedSite', selectedSite);
    this.props.history.push(
      '/FleetView?st=' +
        selectedSite +
        '&ft=' +
        selectedFleet +
        '&fty=' +
        selectedFleetType +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate +
        '&tb=' +
        'Overview'
    );
  };

  getActualFleetName = displayFleetName => {
    const ActualFleetName =
      this.props.allFleetData &&
      this.props.allFleetData.filter(item => item.dnm === displayFleetName);
    return ActualFleetName.length !== 0
      ? ActualFleetName[0].nm
      : displayFleetName;
  };

  getActualSiteName = displaySiteName => {
    const ActualSiteName =
      this.props.allFleetData &&
      this.props.allFleetData.filter(item => item.dst === displaySiteName);
    return ActualSiteName.length !== 0 ? ActualSiteName[0].st : displaySiteName;
  };

  filterFleetData = () => {
    const site = this.state.filterItems.Sites.includes('All')
      ? 'All'
      : this.getActualSiteName(this.state.filterItems.Sites);
    const customer = this.state.filterItems.Customers.includes('All')
      ? 'All'
      : this.state.filterItems.Customers;
    const fleet = this.state.filterItems.Fleets.includes('All')
      ? 'All'
      : this.getActualFleetName(this.state.filterItems.Fleets);
    let ok = this.state.filterItems.isGoodSlected;
    let trip = this.state.filterItems.isPoorSelected;
    let unknown = this.state.filterItems.isUnknownSelected;
    if (
      (ok === true && trip === true && unknown === true) ||
      (ok === false && trip === false && unknown === false)
    ) {
      ok = 'true';
      trip = 'true';
      unknown = 'true';
    }
    this.props.getFleetList(
      site,
      customer,
      fleet,
      ok,
      trip,
      unknown,
      this.state.fleetDataForFilter
    );
  };

  filterFleetNameUpdate = e => {
    const { value } = e.target;

    this.setState(
      {
        fleettoSearch: value,
      },
      () => {
        if (value === '' && this.props.fleetData.length === 1) {
          this.onResetBtnClick();
        }
      }
    );
  };

  filterFleetNameEnter = e => {
    if (e.key === 'Enter') {
      if (this.state.fleettoSearch !== '') {
        this.props.getFleetList(
          'All',
          'All',
          this.state.fleettoSearch,
          true,
          true,
          true,
          this.state.fleetDataForFilter
        );
      }
    }
  };

  filterWithFleetName = () => {
    if (this.state.fleettoSearch !== '') {
      this.props.getFleetList(
        'All',
        'All',
        this.state.fleettoSearch,
        true,
        true,
        true,
        this.state.fleetDataForFilter
      );
    }
  };

  render() {
    // this.filterFleetData();
    let mapValue = '';
    if (this.props.filterData != null) {
      if (this.state.filterItems.Sites.includes('All sites')) {
        mapValue = this.props.filterData;
      } else {
        mapValue = this.props.filterData.filter(
          item => item.dsit === this.state.filterItems.Sites
        );
      }
    }

    return (
      <div className="page-home">
        {this.props.loading === true && (
          <div id="overlay" onClick={() => {}}>
            <div id="loader">
              <Loading loadintText="Loading ..." />
            </div>
          </div>
        )}
        <Breadcrumb
          MonitoredCount={this.props.monitoredCountData}
          userConfig={userConfig}
        />
        <React.Fragment>
          <Row>
            <Col md={12} className="noLeftpadding noRightpadding">
              {checkUserEntitlements('ESS:Dashboard-Dropdownselection') && (
                <div className={'filterContainer'}>
                  <DashboardFilter
                    onGoodFilterCkick={this.onGoodFilterCkick}
                    onPoorFilterCkick={this.onPoorFilterCkick}
                    onUnknownFilterCkick={this.onUnknownFilterCkick}
                    onFavoriteFilterCkick={this.onFavoriteFilterCkick}
                    isGoodSlected={this.state.filterItems.isGoodSlected}
                    isPoorSelected={this.state.filterItems.isPoorSelected}
                    isUnknownSelected={this.state.filterItems.isUnknownSelected}
                    isFavoriteSelected={
                      this.state.filterItems.isFavoriteSelected
                    }
                    defaultSite={this.state.filterItems.Sites}
                    defaultCustomer={this.state.filterItems.Customers}
                    defaultFleet={this.state.filterItems.Fleets}
                    handleFilterDropdownChange={this.handleFilterDropdownChange}
                    handleFilterItemChange={this.handleFilterItemChange}
                    onResetBtnClick={this.onResetBtnClick}
                    sitesList={this.state.filterItems.sitesList}
                    customersList={this.state.filterItems.customersList}
                    fleetsList={this.state.filterItems.fleetsList}
                    goodCount={this.state.filterItems.goodCount}
                    poorCount={this.state.filterItems.poorCount}
                    unknownCount={this.state.filterItems.unknownCount}
                    filterWithFleetName={this.filterWithFleetName}
                    fleettoSearch={this.state.fleettoSearch}
                    filterFleetNameUpdate={this.filterFleetNameUpdate}
                    filterFleetNameEnter={this.filterFleetNameEnter}
                    fleetData={
                      this.props.fleetData !== null && this.props.fleetData
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row
            md={12}
            className="noLeftpadding noRightpadding remove-bottom-margin">
            <Col
              sm={12}
              md={3}
              className="full-width noLeftpadding noRightpadding">
              <div data-intro={'fleet-list'} className={'fleetContainer'}>
                <div className="title">Fleet list</div>
                {this.props.loading !== true &&
                this.props.fleetData !== null ? (
                  <FleetList
                    fleeetData={
                      this.props.fleetData // this.state.fleetDataFiltered
                    }
                    redirectToFleetView={this.redirectToFleetView}
                  />
                ) : (
                  ''
                )}
              </div>
            </Col>
            <Col sm={12} md={9} className="full-width noRightpadding">
              {checkUserEntitlements('ESS:Dashboard-Map') && (
                <div className={'mapContainer'} data-intro="map">
                  <div className={'title'}>Site map</div>
                  <div className={'map'}>
                    <DashboardMap
                      mapdata={mapValue}
                      handleFilterDropdownChange={
                        this.handleFilterDropdownChange
                      }
                      mapZoom={this.state.mapZoom}
                      mapIconSize={this.state.mapIconSize}
                      mapLat={this.state.mapLat}
                      mapLng={this.state.mapLng}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </React.Fragment>
      </div>
    );
  }
}

Dashboard.defaultProps = {};

Dashboard.propTypes = {};

const mapStateToProps = createStructuredSelector({
  fleetData: fleetDataSelector(),
  allFleetData: allFleetDataSelector(),
  loading: loadingSelector(),
  mapdata: mapDataSelector(),
  filterData: filterDataSelector(),
  loadingFilter: loadingFilterSelector(),
  loadingMonitoredCount: loadingMonitoredCountSelector(),
  monitoredCountData: monitoredCountDataSelector(),
  selectedStartDate: selectedStartDateSelector(),
  selectedEndDate: selectedEndDateSelector(),
});

const mapDispatchToProps = {
  getFleetList: fetchFleetList,
  getAllFleetList: fetchAllFleetList,
  getFilterList: fetchFilterList,
  getMonitoredCount: fetchMonitoredCount,
  setFleetName,
  fetchMessages: fetchActiveMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
