import Moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';

require('./styles.scss');

Moment.locale('en');

class UtilizationThresholdInfo extends React.Component {
  render() {
    return (
      this.props.stressData && (
        <div className="util-thres-con">
          <Table responsive="sm">
            <thead className="hidewithoutspace">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody className="util-thresould-tbody">
              <tr>
                <td>Utilization factors</td>
                <td>Temperature (°C)</td>
                <td>SoC (%)</td>
                <td>Cycles</td>
                <td> C-rate (C)</td>
              </tr>
              <tr>
                <td>Threshold</td>
                <td>
                  Temp&lt;{this.props.stressData.ltt} or Temp&gt;
                  {this.props.stressData.htt}
                </td>
                <td>
                  SoC&lt;{this.props.stressData.lts} or SoC&gt;
                  {this.props.stressData.hts}
                </td>

                <td>Cycles/day &gt; {this.props.stressData.hcy}</td>

                <td> C-rate &ge; {this.props.stressData.hcr}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )
    );
  }
}

export default UtilizationThresholdInfo;
