import React from 'react';
import { Col, Form, Row, Toast } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Breadcrumb from '../../components/Breadcrumb';
import messages from './messages';
import './styles.scss';

import moment from 'moment';

import { CheckMarkCircle, HomeIcon } from '../../assets/images';
import EmailAlert from '../../components/EmailAlert';
import { userConfig as configUser } from '../../userConfig';
import { fetchActiveMessages } from '../Broadcast/actions';
import { monitoredCountDataSelector } from '../DashBoard/selectors';
import { fetchEmailalertData, saveEmailalert } from '../EmailAlertView/actions';
import {
  emailalertSaveSelector,
  emailalertSelector,
  loadingEmailalertSelector,
} from '../EmailAlertView/selectors';
moment.locale('en');

let selectedSite, selectedFleet, deactivate, un;
export class EmailAlertView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailalertSettingList: [],
      searchKeyword: '',
      enableAll: null,
      showApiresnotification: true,
    };

    const urlLink = window.location.href;
    const url = new URL(urlLink);
    if (urlLink.indexOf('?') > -1) {
      window.history.replaceState(null, null, url);

      selectedSite = url.searchParams.get('st');
      selectedFleet = url.searchParams.get('ft');
      deactivate = url.searchParams.get('disable');
      un = url.searchParams.get('un');

      sessionStorage.setItem('selectedSite', selectedSite);
      sessionStorage.setItem('selectedFleet', selectedFleet);
    }

    configUser();
  }

  componentDidMount() {
    if (deactivate === 'true') {
      this.props.saveEmailalert(
        '',
        '',
        '',
        '',
        '',
        'inactive',
        un,
        'email_disable'
      );
    } else {
      this.props.getEmailalertSettingData(
        sessionStorage.getItem('selectedFleet')
      );
    }
    this.props.fetchMessages();
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    if (this.props.emailalertSave !== nextProps.emailalertSave) {
      this.props.getEmailalertSettingData(
        sessionStorage.getItem('selectedFleet')
      );
    }
    if (this.props.emailalertSettingList !== nextProps.emailalertSettingList) {
      if (this.state.enableAll === null) {
        if (nextProps.emailalertSettingList[0].sta !== null) {
          this.setState({ enableAll: nextProps.emailalertSettingList[0].sta });
        }
      } else {
        if (
          this.props.emailalertSettingList[0].sta !==
          nextProps.emailalertSettingList[0].sta
        ) {
          this.setState({ enableAll: nextProps.emailalertSettingList[0].sta });
        }
      }
    }
  }

  saveEmailalertSetting = async (
    essName,
    additionalInfo,
    alertType,
    alertFrequency,
    alertValidity,
    status,
    un,
    actions
  ) => {
    await this.props.saveEmailalert(
      essName,
      additionalInfo,
      alertType,
      alertFrequency,
      alertValidity,
      status,
      un,
      actions
    );
    // this.props.getEmailalertSettingData(
    //   sessionStorage.getItem("selectedFleet")
    // );
  };

  searchWithESSname = e => {
    this.setState({ searchKeyword: e });
  };

  enableAllSetting = async () => {
    this.setState({ enableAll: !this.state.enableAll });
    await this.props.saveEmailalert(
      '',
      '',
      [],
      '',
      '',
      !this.state.enableAll === true ? 'active' : 'inactive',
      'UpdateAll',
      'enableall'
    );
  };

  filterEmailalertList = () => {
    if (this.state.searchKeyword === '') {
      this.setState({
        emailalertSettingList: this.props.emailalertSettingList,
      });
      return;
    }
    if (this.props.emailalertSettingList !== null) {
      this.setState({
        emailalertSettingList: this.props.emailalertSettingList.filter(val => {
          return val.en.includes(this.state.searchKeyword);
        }),
      });
    }
  };

  onCloseNotfn = async () => {
    this.setState({ showApiresnotification: false }, () => {});
    deactivate = null;
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.delete('disable');
      window.history.replaceState(null, null, url);
    }
  };

  render() {
    // this.filterEmailalertList();
    return (
      <React.Fragment>
        <Breadcrumb
          BreadcrumbIcon={<HomeIcon className={''} alt="Home" />}
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          MonitoredCount={this.props.monitoredCountData}
          // userConfig={userConfig}
        />
        <div
          style={{
            position: 'absolute',
            top: 51,
            right: 10,
          }}>
          {this.props.emailalertSave !== null &&
          this.props.emailalertSave.length > 0 &&
          deactivate === 'true' ? (
            <Toast
              onClose={this.onCloseNotfn}
              show={this.state.showApiresnotification}
              delay={3000}
              autohide
              className="apiresnotification">
              <Toast.Header>
                <CheckMarkCircle className="toasticon" />
                {this.props.emailalertSave}
              </Toast.Header>
            </Toast>
          ) : (
            ''
          )}
        </div>
        <Row className="emailalertcontianer">
          <>
            <Col md={5} className="containertext">
              Email alert settings - {sessionStorage.getItem('selectedFleet')}
            </Col>
            <Col md={6} className="containersearch">
              <Form.Check
                className="alltogglebtn"
                type="switch"
                id={'cust-switch-all'}
                label="Enable all"
                checked={this.state.enableAll}
                onClick={e => {
                  this.enableAllSetting();
                }}
              />
              <input
                className="alertsearch"
                placeholder="Search with ESS name"
                onChange={e => this.searchWithESSname(e.target.value)}
              />
            </Col>
          </>
          <Col>
            <EmailAlert
              emailalertSettingList={this.props.emailalertSettingList}
              loadingEmailalertSetting={this.props.loadingEmailalertSetting}
              saveEmailalertSetting={this.saveEmailalertSetting}
              emailalertSave={this.props.emailalertSave}
              // deactivate={deactivate}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  monitoredCountData: monitoredCountDataSelector(),
  loadingEmailalertSetting: loadingEmailalertSelector(),
  emailalertSettingList: emailalertSelector(),
  emailalertSave: emailalertSaveSelector(),
});

const mapDispatchToProps = {
  getEmailalertSettingData: fetchEmailalertData,
  saveEmailalert,
  fetchMessages: fetchActiveMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailAlertView);
