import PropTypes from 'prop-types';
import React from 'react';
import './gradientbar.css';

export const GradientBar = ({
  height,
  colorA,
  colorB,
  colorC,
  colorD,
  direction,
  primary,
  leftValue,
  rightValue,
  barwidth,
  centerValue,
}) => {
  const mode = primary ? 'designbook-gradientbar--primary' : '';
  return (
    <div className="designbook-gradientbar-wrapper">
      <div className="designbook-gradientbar-barwrapper">
        <div
          className={['designbook-gradientbar', mode].join(' ')}
          style={{
            width: `${barwidth}%`,
            height,
            backgroundImage: `linear-gradient(to ${direction}, ${colorA} , ${colorB})`,
          }}></div>
        <div
          className={['designbook-gradientbar', mode].join(' ')}
          style={{
            width: `${100 - barwidth}%`,
            height,
            backgroundImage: `linear-gradient(to ${direction}, ${colorC} , ${colorD})`,
          }}></div>
      </div>
      <span className="designbook-gradientbar-footer">
        <span
          style={{
            width: `${barwidth}%`,
            minWidth: '80px',
            textAlign: 'left',
          }}>
          {leftValue}
        </span>
        <span
          style={{
            width: `${100 - barwidth}%`,
            minWidth: '80px',
            textAlign: 'left',
          }}>
          {centerValue}
        </span>
        <span style={{ minWidth: '80px', textAlign: 'right' }}>
          {rightValue}
        </span>
      </span>
    </div>
  );
};

GradientBar.propTypes = {
  primary: PropTypes.bool,
  height: PropTypes.number,
  direction: PropTypes.string,
  colorA: PropTypes.string,
  colorB: PropTypes.string,
  colorC: PropTypes.string,
  colorD: PropTypes.string,
  leftValue: PropTypes.string,
  rightValue: PropTypes.string,
  centerValue: PropTypes.string,
  barwidth: PropTypes.number,
};

GradientBar.defaultProps = {
  colorA: '#f5f5f5',
  colorB: '#000000',
  colorC: '#ffda99',
  colorD: '#ffa200',
  primary: true,
  direction: 'right',
  height: 50,
  leftValue: '0 kWh',
  centerValue: '1000 kWh',
  rightValue: '3000 kWh',
  barwidth: 70,
};
