import { SET_IS_RESIZING, SET_SHOW_SIDEBAR, SET_SIZE } from './constants';

export const setShowSidebar = status => ({
  type: SET_SHOW_SIDEBAR,
  status,
});

export const setIsResizing = status => ({
  type: SET_IS_RESIZING,
  status,
});

export const setSize = value => ({
  type: SET_SIZE,
  value,
});
