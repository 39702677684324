let API = null;

if (process.env.REACT_APP_ESS_API === 'development') {
  API = {
    resourceUrl: 'https://api.dev.conditionmonitoring.traction.abb.com',
    baseUrl: 'https://api.dev.conditionmonitoring.traction.abb.com',
  };
} else if (process.env.REACT_APP_ESS_API === 'qa') {
  API = {
    resourceUrl: 'https://api.qa.conditionmonitoring.traction.abb.com',
    baseUrl: 'https://api.qa.conditionmonitoring.traction.abb.com',
  };
} else if (process.env.REACT_APP_ESS_API === 'production') {
  API = {
    resourceUrl: 'https://api.prod.conditionmonitoring.traction.abb.com',
    baseUrl: 'https://api.prod.conditionmonitoring.traction.abb.com',
  };
} else if (process.env.REACT_APP_ESS_API === 'stage') {
  API = {
    resourceUrl: 'https://api.stage.conditionmonitoring.traction.abb.com',
    baseUrl: 'https://api.stage.conditionmonitoring.traction.abb.com',
  };
} else {
  API = {
    resourceUrl: 'https://api.prod.conditionmonitoring.traction.abb.com',
    baseUrl: 'https://api.prod.conditionmonitoring.traction.abb.com',
  };
}

const exportedAPI = API;
export default exportedAPI;
