import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Breadcrumb from '../../components/Breadcrumb';
import Unauthorized from '../../components/Unauthorized/Unauthorized';
import {
  checkUserEntitlements,
  userConfig as configUser,
} from '../../userConfig';
import { monitoredCountDataSelector } from '../DashBoard/selectors';
import Configuration from './Configuration';
import Confirmation from './Confirmation';
import Messages from './Messages';
import RenewMessage from './RenewMessage';
import {
  createBroadcastMessage,
  deleteBroadcastMessage,
  fetchActiveMessages,
  fetchAllBroadcastMessages,
  updateBroadcastMessage,
} from './actions';
import { allMessages } from './selectors';
import './styles.scss';

class Broadcast extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isBroadcast: false };
  }

  componentDidMount() {
    configUser();
    this.setState({
      isBroadcast: checkUserEntitlements('ESS:common-broadcast-message'),
    });
    if (checkUserEntitlements('ESS:common-broadcast-message')) {
      this.props.fetchAllMessages();
    }
    this.props.fetchMessages();
  }

  handleCreateMessage = data => {
    this.props.createBroadcastMessage(data);
  };

  handleDeleteMessage = id => {
    this.props.deleteBroadcastMessage(id);
    this.setState({ show: false });
  };

  handleEnableMessage = (id, status, message) => {
    const utcFormat = 'YYYY-MM-DDTHH:mm:ss';
    const msg = {
      ...message,
      startDate: moment(
        `${message.startDate} ${message.startTime}`,
        utcFormat
      ).format(utcFormat),
      endDate: moment(
        `${message.endDate} ${message.endTime}`,
        utcFormat
      ).format(utcFormat),
      isActive: status,
    };
    this.props.updateBroadcastMessage({ id, status, message: msg });
    this.setState({ show: false });
  };

  confirmAction = (id, message, action) => {
    if (action === 'delete') {
      this.setState({ show: true, id, action, message });
      return;
    }
    if (action === 'enable') {
      this.setState({ show: true, id, action, message });
      return;
    }
    if (action === 'disable') {
      this.setState({ show: true, id, action, message });
    }
  };

  handleAction = () => {
    if (this.state.action === 'delete') {
      this.handleDeleteMessage(this.state.id);
      return;
    }
    if (this.state.action === 'enable') {
      this.handleEnableMessage(this.state.id, true, this.state.message);
      return;
    }
    if (this.state.action === 'disable') {
      this.handleEnableMessage(this.state.id, false, this.state.message);
    }
  };

  handleRenewMessage = message => {
    const utcFormat = 'YYYY-MM-DDTHH:mm:ss';
    const msg = {
      ...message,
      startDate: moment(`${message.startDate} ${message.startTime}`, utcFormat)
        .utc()
        .format(utcFormat),
      endDate: moment(`${message.endDate} ${message.endTime}`, utcFormat)
        .utc()
        .format(utcFormat),
      isActive: true,
    };
    this.props.updateBroadcastMessage({
      status: true,
      message: msg,
    });
    this.setState({ showRenew: false });
  };

  render() {
    return this.state.isBroadcast ? (
      <>
        <Breadcrumb MonitoredCount={this.props.monitoredCountData} />
        <div className="broadcast_page">
          <Confirmation
            show={this.state.show}
            handleClose={() => this.setState({ show: false })}
            action={this.state.action}
            handleAction={() => this.handleAction()}
          />
          <RenewMessage
            show={this.state.showRenew}
            handleClose={() => this.setState({ showRenew: false })}
            updateMessage={message => this.handleRenewMessage(message)}
            message={this.state.message}
          />
          <Configuration
            createMessage={data => this.handleCreateMessage(data)}
          />

          <Messages
            data={this.props.allBroadcastMessages}
            deleteMessage={(id, message) =>
              this.confirmAction(id, message, 'delete')
            }
            enableMessage={(id, message, status) =>
              this.confirmAction(id, message, status)
            }
            renewMessage={message =>
              this.setState({
                showRenew: true,
                action: 'renew',
                message,
              })
            }
          />
        </div>
      </>
    ) : (
      <Unauthorized />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allBroadcastMessages: allMessages(),
  monitoredCountData: monitoredCountDataSelector(),
});

const mapDispatchToProps = {
  createBroadcastMessage,
  fetchAllMessages: fetchAllBroadcastMessages,
  deleteBroadcastMessage,
  updateBroadcastMessage,
  fetchMessages: fetchActiveMessages,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Broadcast)
);
