import {
  CHECK_USER,
  CHECK_USER_FAILURE,
  CHECK_USER_SUCCESS,
  FETCH_ALL_FLEET_LIST,
  FETCH_ALL_FLEET_LIST_FAILURE,
  FETCH_ALL_FLEET_LIST_SUCCESS,
  FETCH_FILTER_LIST,
  FETCH_FILTER_LIST_FAILURE,
  FETCH_FILTER_LIST_SUCCESS,
  FETCH_FLEET_LIST,
  FETCH_FLEET_LIST_FAILURE,
  FETCH_FLEET_LIST_SUCCESS,
  FETCH_MAP_DATA,
  FETCH_MAP_DATA_FAILURE,
  FETCH_MAP_DATA_SUCCESS,
  FETCH_MONITORED_COUNT,
  FETCH_MONITORED_COUNT_FAILURE,
  FETCH_MONITORED_COUNT_SUCCESS,
  SET_CURRENT_FLEET,
  SET_DATE,
  SET_USER_GROUP,
} from './constants';

// Map
export const fetchMapData = (startDate, endDate) => ({
  type: FETCH_MAP_DATA,
});

export const fetchMapDataSucess = Mapdata => ({
  type: FETCH_MAP_DATA_SUCCESS,
  Mapdata,
});

export const fetchMapDataFailure = error => ({
  type: FETCH_MAP_DATA_FAILURE,
  error,
});

// FleetList
export const fetchFleetList = (
  site,
  customer,
  fleet,
  ok,
  trip,
  unknown,
  fleetData
) => ({
  type: FETCH_FLEET_LIST,
  payload: { site, customer, fleet, ok, trip, unknown, fleetData },
});
export const fetchFleetListSucess = FleetList => ({
  type: FETCH_FLEET_LIST_SUCCESS,
  FleetList,
});

export const fetchFleetListFailure = error => ({
  type: FETCH_FLEET_LIST_FAILURE,
  error,
});

export const fetchAllFleetList = (
  site,
  customer,
  fleet,
  ok,
  trip,
  unknown
) => ({
  type: FETCH_ALL_FLEET_LIST,
  payload: { site, customer, fleet, ok, trip, unknown },
});

export const fetchAllFleetListSucess = FleetList => ({
  type: FETCH_ALL_FLEET_LIST_SUCCESS,
  FleetList,
});

export const fetchAllFleetListFailure = error => ({
  type: FETCH_ALL_FLEET_LIST_FAILURE,
  error,
});

// FilterList
export const fetchFilterList = () => ({
  type: FETCH_FILTER_LIST,
});

export const fetchFilterListSucess = FilterList => ({
  type: FETCH_FILTER_LIST_SUCCESS,
  FilterList,
});

export const fetchFilterListFailure = error => ({
  type: FETCH_FILTER_LIST_FAILURE,
  error,
});

// FETCH_MONITORED_COUNT
export const fetchMonitoredCount = () => ({
  type: FETCH_MONITORED_COUNT,
});

export const fetchMonitoredCountSucess = MonitoredCount => ({
  type: FETCH_MONITORED_COUNT_SUCCESS,
  MonitoredCount,
});

export const fetchMonitoredCountFailure = error => ({
  type: FETCH_MONITORED_COUNT_FAILURE,
  error,
});

export const setSelectedTrain = fleetName => ({
  type: SET_CURRENT_FLEET,
  fleetName,
});

export const setDate = (startDate, endDate) => ({
  type: SET_DATE,
  payload: { startDate, endDate },
});

/// ///////////////////////////////////////////////////////////////////////

// Check user
export const checkUser = email => ({
  type: CHECK_USER,
  payload: { email },
});

export const checkUserSucess = userData => ({
  type: CHECK_USER_SUCCESS,
  userData,
});

export const checkUserFailure = error => ({
  type: CHECK_USER_FAILURE,
  error,
});

export const setUserGroup = userGroup => ({
  type: SET_USER_GROUP,
  userGroup,
});
