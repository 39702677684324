import PropTypes from 'prop-types';
import React from 'react';
import EmergencyStatus from '../EmergencyStatus/EmergencyStatus';
import ServiceNotAvailable from '../ErrorComponent/serviceNotAvailable';
import LoadingIndicator from '../LoadingIndicator';
require('./styles.scss');

const Loading = ({
  status,
  message,
  apiStatus,
  loadintText,
  disabled,
  safeModeEnabled,
}) => {
  if (safeModeEnabled === true) {
    return <EmergencyStatus message={message} />;
  } else if (status === true || apiStatus === false) {
    return <ServiceNotAvailable message={message} />;
  } else {
    return (
      <div className={disabled ? 'hidden' : ''}>
        <LoadingIndicator loadintText={loadintText} />
      </div>
    );
  }
};
Loading.defaultProps = {
  disabled: false,
};
Loading.propTypes = {
  disabled: PropTypes.bool,
};

export default Loading;
