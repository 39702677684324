import { Log, UserManager } from 'oidc-client-ts';
import { authConfig } from './config/authConfig';

export const userManager = new UserManager(authConfig);
export class AuthService {
  constructor() {
    Log.setLogger(console);
    Log.setLevel(Log.INFO);
  }

  getUser = async () => {
    const user = await userManager.getUser();
    return user;
  };

  signinRedirectCallback = () => {
    userManager.signinRedirectCallback().then(() => {
      this.navigateToScreen();
    });
  };

  login = async () => {
    return await userManager.signinRedirect();
  };

  renewToken = async () => {
    return await userManager.signinSilent();
  };

  logout = () => {
    userManager.signoutRedirect();
  };

  navigateToScreen = () => {
    if (sessionStorage.getItem('selectedURL') !== null) {
      window.location.replace(sessionStorage.getItem('selectedURL'));
    } else {
      window.location.replace('/Dashboard');
    }
  };
}

export const authService = new AuthService();
