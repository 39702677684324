import React from 'react';
import {
  ProductBattery,
  RightDirectionArrow,
  WarningRound,
} from '../../assets/images';
import { platformVersion } from '../../utils/excel-utils';
import { formatNumber } from '../../utils/fleetStatisticsDataUtil';
import './fleetStatModalContent.scss';
import { checkUserEntitlements } from '../../userConfig';

export const FleetStatModalContent = ({
  options,
  onSubmit,
  heading,
  batteryCount,
  date,
  vehicleName,
}) => {
  const selectedOption = null;

  return (
    <>
      <div className="top_head">
        <span className="overlay-heading">
          {platformVersion() !== '3' ? heading : options.name}
        </span>
      </div>
      {platformVersion() === '3' && (
        <div className="internal-head">
          <span>
            <strong>Date:</strong>{' '}
            <span style={{ fontWeight: 400 }}>{date}</span>
          </span>
          <span>
            <>Vehicle Name:</>{' '}
            <span style={{ fontWeight: 400 }}>{vehicleName}</span>
          </span>
          <span>
            <>Battery system:</>{' '}
            <span style={{ fontWeight: 400 }}>{options.name}</span>
          </span>
          <hr />
          <span>
            <>Throughput of all strings:</>{' '}
            <span style={{ fontWeight: 400 }}>
              {formatNumber(options.value.toFixed(0))} kWh
            </span>
          </span>
          <span>
            <>Total operating hours of all strings:</>{' '}
            <span style={{ fontWeight: 400 }}>
              {formatNumber(options.hours.toFixed(0))} hrs
            </span>
          </span>
          <hr />
        </div>
      )}
      <form
        className={`option-form ${platformVersion() === '3' && 'overflowStyle'}`}>
        {platformVersion() !== '3' ? (
          <ul className="option-list">
            {options.map(option => (
              <li
                key={option}
                className={`option-list-item ${
                  selectedOption === option ? 'selected' : ''
                }`}
                onClick={() => onSubmit(option)}>
                <label className="option-label">
                  <input
                    type="radio"
                    name="option"
                    value={option}
                    className="option-input"
                  />
                  <ProductBattery className="producticon" alt="Product" />
                  <span className="option-text">{option}</span>
                </label>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="option-list">
            {options?.stringData?.map(sData => (
              <div key={sData.scu}>
                {' '}
                <li
                  className="linearList"
                  onClick={() => onSubmit(sData)}
                  style={{
                    cursor:
                      !checkUserEntitlements('ESS:Product-Usage') ||
                      !checkUserEntitlements('ESS:Product-Usage-Power')
                        ? 'default'
                        : 'pointer',
                  }}>
                  <div className="leftColumn">
                    <>{sData.nm}</>
                    <span className="secondaryfont">
                      <>Total throughput:</> {formatNumber(sData.th.toFixed(0))}{' '}
                      kWh
                    </span>
                    <span className="secondaryfont">
                      <>Total operating hours:</>{' '}
                      {formatNumber(sData.oh.toFixed(0))} hrs
                    </span>
                  </div>
                  <div className="rightColumn">
                    {sData.er && (
                      <WarningRound style={{ marginRight: '20px' }} />
                    )}
                    {checkUserEntitlements('ESS:Product-Usage') ||
                      (checkUserEntitlements('ESS:Product-Usage-Power') && (
                        <RightDirectionArrow />
                      ))}
                  </div>
                </li>
                <hr />
              </div>
            ))}
          </ul>
        )}
      </form>
    </>
  );
};
