import { fromJS } from 'immutable';
import {
  CLEAR_VEHICLE_VIEW_DATA,
  FETCH_BOXPLOT_DATA,
  FETCH_BOXPLOT_DATA_FAILURE,
  FETCH_BOXPLOT_DATA_SUCCESS,
  FETCH_HISTOGRAM_DATA,
  FETCH_HISTOGRAM_DATA_FAILURE,
  FETCH_HISTOGRAM_DATA_SUCCESS,
  FETCH_OVERALL_CONDITION_DATA,
  FETCH_OVERALL_CONDITION_DATA_FAILURE,
  FETCH_OVERALL_CONDITION_DATA_SUCCESS,
  FETCH_VEHICLE_AVAIL_DATA,
  FETCH_VEHICLE_AVAIL_DATA_FAILURE,
  FETCH_VEHICLE_AVAIL_DATA_SUCCESS,
  FETCH_VEHICLE_DETAILS,
  FETCH_VEHICLE_DETAILS_FAILURE,
  FETCH_VEHICLE_DETAILS_SUCCESS,
  FETCH_VEHICLE_REL_DATA,
  FETCH_VEHICLE_REL_DATA_FAILURE,
  FETCH_VEHICLE_REL_DATA_SUCCESS,
  FETCH_VEHICLE_STR_DATA,
  FETCH_VEHICLE_STR_DATA_FAILURE,
  FETCH_VEHICLE_STR_DATA_SUCCESS,
} from './constants';

const initialState = fromJS({
  loadingOverallCondition: false,
  loadingHistogram: false,
  loadingBoxPlot: false,
  // selectedTrain: "Re 460 117-5",
  overallConditionData: null,
  histogrampData: null,
  boxPlotData: null,
  error: null,
  loadingVehicleAvail: false,
  vehicleAvailData: null,
  loadingVehicleRel: false,
  vehicleRelData: null,
  loadingVehicleStr: false,
  vehicleStrData: null,
  loadingVehicleDetails: false,
  vehicleDetails: null,
});

export function reducerVehicleView(state = initialState, action) {
  switch (action.type) {
    case FETCH_OVERALL_CONDITION_DATA:
      return state.set('loadingOverallCondition', true).set('error', null);
    case FETCH_OVERALL_CONDITION_DATA_SUCCESS:
      return state
        .set('loadingOverallCondition', false)
        .set('overallConditionData', action.overallConditionData);
    case FETCH_OVERALL_CONDITION_DATA_FAILURE:
      return state
        .set('loadingOverallCondition', false)
        .set('overallConditionData', null)
        .set('error', action.error);

    /// ////////////////////////////////////
    case FETCH_VEHICLE_AVAIL_DATA:
      return state.set('loadingVehicleAvail', true).set('error', null);
    case FETCH_VEHICLE_AVAIL_DATA_SUCCESS:
      return state
        .set('loadingVehicleAvail', false)
        .set('vehicleAvailData', action.vehicleAvailData);
    case FETCH_VEHICLE_AVAIL_DATA_FAILURE:
      return state
        .set('loadingVehicleAvail', false)
        .set('vehicleAvailData', null)
        .set('error', action.error);
    case FETCH_VEHICLE_REL_DATA:
      return state.set('loadingVehicleRel', true).set('error', null);
    case FETCH_VEHICLE_REL_DATA_SUCCESS:
      return state
        .set('loadingVehicleRel', false)
        .set('vehicleRelData', action.vehicleRelData);
    case FETCH_VEHICLE_REL_DATA_FAILURE:
      return state
        .set('loadingVehicleRel', false)
        .set('vehicleRelData', null)
        .set('error', action.error);
    case FETCH_VEHICLE_STR_DATA:
      return state.set('loadingVehicleStr', true).set('error', null);
    case FETCH_VEHICLE_STR_DATA_SUCCESS:
      return state
        .set('loadingVehicleStr', false)
        .set('vehicleStrData', action.vehicleStrData);
    case FETCH_VEHICLE_STR_DATA_FAILURE:
      return state
        .set('loadingVehicleStr', false)
        .set('vehicleStrData', null)
        .set('error', action.error);

    case CLEAR_VEHICLE_VIEW_DATA:
      return state
        .set('vehicleAvailData', null)
        .set('vehicleRelData', null)
        .set('vehicleStrData', null);
    /// ///////////////////////////////////////////////////
    case FETCH_HISTOGRAM_DATA:
      return state.set('loadingHistogram', true).set('error', null);
    case FETCH_HISTOGRAM_DATA_SUCCESS:
      return state
        .set('loadingHistogram', false)
        .set('histogramData', action.histogramData);
    case FETCH_HISTOGRAM_DATA_FAILURE:
      return state
        .set('loadingHistogram', false)
        .set('histogramData', null)
        .set('error', action.error);

    case FETCH_BOXPLOT_DATA:
      return state.set('loadingBoxPlot', true).set('error', null);
    case FETCH_BOXPLOT_DATA_SUCCESS:
      return state
        .set('loadingBoxPlot', false)
        .set('boxPlotData', action.boxPlotData);
    case FETCH_BOXPLOT_DATA_FAILURE:
      return state
        .set('loadingBoxPlot', false)
        .set('boxPlotData', null)
        .set('error', action.error);

    case FETCH_VEHICLE_DETAILS:
      return state.set('loadingVehicleDetails', true).set('error', null);
    case FETCH_VEHICLE_DETAILS_SUCCESS:
      return state
        .set('loadingVehicleDetails', false)
        .set('vehicleDetails', action.vehicleDetails);
    case FETCH_VEHICLE_DETAILS_FAILURE:
      return state
        .set('loadingVehicleDetails', false)
        .set('vehicleDetails', null)
        .set('error', action.error);

    default:
      return state;
  }
}
