import React from 'react';
import './styles.scss';
const Pulse = () => (
  <div className="border">
    <div className="lock" />
    Safe mode active
  </div>
);

export default Pulse;
