import { combineReducers } from 'redux-immutable';
import { DraggableLayoutReducer } from '../components/DraggableLayout/reducers';
import { SidebarDraggableReducer } from '../components/SidebarDraggable/reducers';
import { MessageReducer } from '../containers/Broadcast/reducers';
import { reducerDashboard } from '../containers/DashBoard/reducers';
import { reducerEmailalert } from '../containers/EmailAlertView/reducers';
import { errorStatusCheck } from '../containers/ErrorBoundary/reducers';
import { reducerEvents } from '../containers/Events/reducers';
import { reducerFleetSetup } from '../containers/FleetSetup/reducers';
import { reducerSingleGraph } from '../containers/FleetView/reducers';
import { reducerExcelReport } from '../containers/ReportView/reducers';
import { emergencyModeReducer } from '../containers/SafeMode/reducers';
import { reducerVehicleProductsView } from '../containers/VehicleProductsView/reducers';
import { reducerVehicleView } from '../containers/VehicleView/reducers';

export const reducers = combineReducers({
  dashboardData: reducerDashboard,
  graphData: reducerSingleGraph,
  VehicleProductsViewData: reducerVehicleProductsView,
  fleetSetupData: reducerFleetSetup,
  eventsData: reducerEvents,
  vehicleViewData: reducerVehicleView,
  emailalertData: reducerEmailalert,
  draggableLayout: DraggableLayoutReducer,
  SidebarDraggable: SidebarDraggableReducer,
  emergencyMode: emergencyModeReducer,
  broadcastMessageData: MessageReducer,
  excelReportData: reducerExcelReport,
  errorStatusCheck,
});
