export function getbpu(an, vehiclesListData) {
  let bpu = '';
  if (vehiclesListData[0] !== null) {
    vehiclesListData[0].vst.forEach(vehicle => {
      vehicle.vdat.forEach(battery => {
        if (battery.an === an) {
          bpu = battery.bpu;
        }
      });
    });
  }
  return bpu;
}

export function getscuID(an, vehiclesListData) {
  let scuID = '';
  if (vehiclesListData)
    if (vehiclesListData[0] !== null) {
      vehiclesListData[0].vst.forEach(vehicle => {
        vehicle.vdat.forEach(battery => {
          battery.pdat.forEach(pack => {
            if (pack.an === an) {
              scuID = pack.scuID;
            }
          });
        });
      });
    }
  return scuID;
}
