import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { authService } from './AuthService';
import Loading from './components/Loading';

class RenewToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    authService
      .renewToken()
      .then(user => {
        sessionStorage.setItem('access_token', user.access_token);
        console.log('Token has been sucessfully renewed.');
        sessionStorage.getItem('backupurl')
          ? (window.location.href = sessionStorage.getItem('backupurl'))
          : (window.location.href = '/Dashboard');
      })
      .catch(error => {
        console.log('Token', error);
        authService.login();
      });
  }

  render() {
    return (
      <Row className="noLeftpadding noRightpadding remove-bottom-margin">
        <Col>
          <Loading loadintText="Loading..." />
        </Col>
      </Row>
    );
  }
}

export default RenewToken;
