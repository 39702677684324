import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { DropdownList, Multiselect } from 'react-widgets';
import messages from './messages';
import './styles.scss';

class SignalSelector extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
      selectedSignals: null,
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.props.chartType === 'TREND_TYPE' && (
          <Row className="containerselectsignal">
            <Col md={3}>
              <div className="mainsignal">
                <div className="selectsignal">
                  <FormattedMessage {...messages.mainSignalText} />
                </div>
                <DropdownList
                  data={this.props.mainSignalValues}
                  value={this.props.selectedMainTrend}
                  className="dropdownmainsignal"
                  onChange={this.props.clickTrendMainSignal}
                />
              </div>
            </Col>
            <Col md={8} data-intro="overlay-signal">
              <div className="overlaysignal">
                <div className="addsignal">
                  <FormattedMessage {...messages.addOverlaysText} />
                </div>
                <Multiselect
                  placeholder="None"
                  onChange={this.props.clickTrendOverlaySignal}
                  onKeyDown={e => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  disabled={[this.props.selectedMainTrend]}
                  data={this.props.overlaySignalValues}
                  value={
                    this.props.selectedOverlayTrend &&
                    this.props.selectedOverlayTrend.length > 0 //  !== [] previous comparison here changed to .length > 0
                      ? [...this.props.selectedOverlayTrend]
                      : []
                  }
                  className={'dropdownoverlaysignal'}
                />
              </div>
            </Col>
          </Row>
        )}
        {this.props.chartType === 'SYNC_TYPE' && (
          <Row className="containerselectsignal">
            <Col md={3}>
              <div className="mainsignal">
                <div className="selectsignal">
                  <FormattedMessage {...messages.mainSignalText} />
                </div>
                <DropdownList
                  data={this.props.mainSignalValues}
                  value={this.props.selectedMainSignal}
                  className="dropdownmainsignal"
                  onChange={this.props.handleSyncMainDropdownChange}
                  // disabled={[this.props.selectedOverlaySignal]}
                />
              </div>
            </Col>

            <Col md={3}>
              <div className="overlaysignal">
                <div className="addsignal">
                  <FormattedMessage {...messages.addOverlaysText} />
                </div>
                <DropdownList
                  placeholder="None"
                  // textField="label"
                  // valueField="code"
                  /* onToggle={this.onToggle}
                 open={this.state.open}
                 onChange={items => {
                   this.props.clickOverlaySignal(items);
                   this.numberOfSignalsSelected(items);
                 }} */
                  onChange={this.props.handleSyncOverlayDropdownChange}
                  // disabled={[this.props.selectedMainSignal]}
                  data={this.props.overlaySignalValues}
                  value={this.props.selectedOverlaySignal}
                  className={'dropdownoverlaysignal'}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className={'toggle-button-container'}>
                <button
                  type="button"
                  className={
                    this.props.selectedSyncChart === 'chart1'
                      ? 'toggle-button activeLightBlue'
                      : 'toggle-button'
                  }
                  onClick={() => this.props.handleSyncToggleChange('chart1')}>
                  Chart 1
                </button>
                <button
                  type="button"
                  className={
                    this.props.selectedSyncChart === 'chart2'
                      ? 'toggle-button activeLightBlue'
                      : 'toggle-button'
                  }
                  onClick={() => this.props.handleSyncToggleChange('chart2')}>
                  Chart 2
                </button>
                <button
                  type="button"
                  className={
                    this.props.selectedSyncChart === 'chart3'
                      ? 'toggle-button activeLightBlue'
                      : 'toggle-button'
                  }
                  onClick={() => this.props.handleSyncToggleChange('chart3')}>
                  Chart 3
                </button>
              </div>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(SignalSelector);
