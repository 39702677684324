import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HC_boost from 'highcharts/modules/boost';
import HC_boost_canvas from 'highcharts/modules/boost-canvas';
import HC_exporting from 'highcharts/modules/exporting';
import React from 'react';

import { getAvaPieChart, getRelPieChart, getStrPieChart } from './function';
import './styles.scss';

highchartsMore(Highcharts);
HC_exporting(Highcharts);
HC_boost_canvas(Highcharts);
HC_boost(Highcharts);

export default class PieGraph extends React.Component {
  constructor(props, context) {
    super(props, context);
    const options = this.initGraph(props);
    this.state = { options };
  }

  componentDidMount() {
    this.draw(this.props);
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.data !== nextProps.data ||
      this.props.listSelection !== nextProps.listSelection
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate() {
    const options = this.initGraph();
    this.setState({ options });
    this.draw(this.props);
  }

  componentWillUnmount() {
    setTimeout(() => {
      this.chart.destroy();
    }, 0);
  }

  draw(props) {
    this.chart = Highcharts.chart(this.container, { ...this.state.options });
    this.chart.reflow();
  }

  initGraph = props => {
    let options;
    if (this.props.chartType === 'BATT_REL_PIE_TYPE') {
      options = getRelPieChart(
        this.props.data,
        this.props.pieChartSelected,
        'BATT_REL_PIE_TYPE'
      );
    } else if (this.props.chartType === 'BATT_STR_PIE_TYPE') {
      options = getStrPieChart(
        this.props.data,
        this.props.pieChartSelected,
        'BATT_STR_PIE_TYPE'
      );
    } else if (this.props.chartType === 'VEH_REL_PIE_TYPE') {
      options = getRelPieChart(
        this.props.data,
        this.props.pieChartSelected,
        'VEH_REL_PIE_TYPE'
      );
    } else if (this.props.chartType === 'VEH_STR_PIE_TYPE') {
      options = getStrPieChart(
        this.props.data,
        this.props.pieChartSelected,
        'VEH_STR_PIE_TYPE'
      );
    } else if (this.props.chartType === 'FLEET_AVA_PIE_TYPE') {
      options = getAvaPieChart(
        this.props.data,
        this.props.pieChartSelected,
        'FLEET_AVA_PIE_TYPE',
        this.props.sideMenuFilter,
        this.props.filterAvailability
      );
    } else if (this.props.chartType === 'FLEET_REL_PIE_TYPE') {
      options = getRelPieChart(
        this.props.data,
        this.props.pieChartSelected,
        'FLEET_REL_PIE_TYPE',
        this.props.sideMenuFilter,
        this.props.filterReliability
      );
    } else if (this.props.chartType === 'FLEET_STR_PIE_TYPE') {
      options = getStrPieChart(
        this.props.data,
        this.props.pieChartSelected,
        'FLEET_STR_PIE_TYPE',
        this.props.sideMenuFilter,
        this.props.filterUtilization
      );
    }

    if (Highcharts.getOptions().exporting) {
      const contextButton =
        Highcharts.getOptions().exporting.buttons.contextButton;
      contextButton.menuItems = contextButton.menuItems.filter(item => {
        return item !== 'downloadPDF';
      });
    }

    return options;
  };

  render() {
    return (
      <div
        id="pie_graph"
        className="chart"
        ref={ref => (this.container = ref)}
        // onClick={}
      />
    );
  }
}
