/* global google */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { googleMapURL } from '../Googlemapkey';
import './styles.scss';
const SiteSetupGoogleMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={11}
      zoom={12}
      defaultCenter={{ lat: 53.201309, lng: 5.81963 }}
      center={props.markerPosition[0]}
      options={{
        streetViewControl: false,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        fullscreenControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.BOTTOM_RIGHT,
        },
      }}>
      {props.markerPosition && (
        <Marker
          position={props.markerPosition[0]}
          options={{
            icon: {
              url: require(`../../assets/images/Green-marker.svg`),
              scaledSize: new google.maps.Size(53, 58),
            },
          }}></Marker>
      )}
    </GoogleMap>
  ))
);

class SiteSetupMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markerID: null,
      isHover: false,
    };
  }

  onHover = markerID => {
    this.setState({ markerID, isHover: true });
  };

  onHoverOut = () => {
    this.setState({ markerID: null });
  };

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    if (this.props !== nextProps) {
      this.setState({ isHover: false });
    }
  }

  render() {
    return (
      <div id="SiteSetupMap">
        <SiteSetupGoogleMap
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `40vh` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          markerPosition={this.props.markerpostion}
        />
      </div>
    );
  }
}

SiteSetupMap.defaultProps = {};

SiteSetupMap.propTypes = {
  mapPath: PropTypes.any,
};

export default SiteSetupMap;
