import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import './styles.scss';
import { CloseIcon } from '../../assets/images';
function BroadcastMesssageDetails({ messages, show, handleClose }) {
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      size="lg">
      <Modal.Header>
        <Modal.Title
          style={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <h6
            style={{
              fontWeight: '500',
              fontSize: '16px',
              color: 'black',
              fontFamily: 'mediumFont',
              margin: 0,
              padding: 0,
            }}>
            Messages
          </h6>
          <span onClick={() => handleClose()}>
            <CloseIcon />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-xl">
        {messages && messages.length > 0
          ? messages.map((message, i) => (
              <div className="broadcast_message_box" key={i}>
                <div
                  key={i}
                  className="message_text"
                  style={{ color: 'black', fontSize: '14px' }}>
                  {message.message}
                </div>
                <div
                  className="message_text"
                  style={{ fontSize: '11px', color: '#696969' }}>
                  {moment
                    .utc(message.startDate)
                    .local()
                    .format('DD.MM.YYYY HH:mm')}
                </div>
              </div>
            ))
          : 'No messages!'}
      </Modal.Body>
    </Modal>
  );
}

export default BroadcastMesssageDetails;
