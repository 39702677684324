import Moment from 'moment';
import React from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import Breadcrumb from '../../components/Breadcrumb';
import { setSidebarWidth } from '../../components/DraggableLayout/actions';
import { getSidebarWidth } from '../../components/DraggableLayout/selectors';
import FleetTabs from '../../components/FleetTabs';
import Loading from '../../components/Loading';
import SideMenu from '../../components/SideMenu';
import Draggable from '../../components/SidebarDraggable/Draggable';
import TitleBar from '../../components/Title/TitleBar';
import TitleBarV3 from '../../components/Title/TitleBarV3';
import {
  clearChartData,
  fetchBoxPlotData,
  fetchDiagnosticList,
  fetchFleetStatisticsData,
  fetchFleetsList,
  fetchHistogramData,
  fetchOverallConditionData,
  fetchOverviewData,
  fetchUnmountedESSList,
  fetchVehicleProductList,
  fetchVehiclesList,
  setDate,
  setDiagnosticEventListData,
  setFleetName,
  setListSelection,
  setVehicleName,
  setplatformVersionNumber,
} from './actions';
import messages from './messages';
import {
  boxPlotDataSelector,
  diagnosticEventDataSelector,
  diagnosticEventListSelectorConfig,
  diagnosticListSelector,
  fleetStatisticsDataSelector,
  fleetStatisticsStatusLoader,
  fleetsListDataSelector,
  histogramDataSelector,
  loadingBoxPlotSelector,
  loadingDiagnosticListSelector,
  loadingFleetsSelector,
  loadingHistogramSelector,
  loadingOverallConditionSelector,
  loadingOverviewSelector,
  loadingUnmountedESSListSelector,
  loadingVehiclesSelector,
  overallConditionDataSelector,
  overviewDataSelector,
  platformNumberSelector,
  selectedEndDateSelector,
  selectedFleetSelector,
  selectedListSelector,
  selectedStartDateSelector,
  selectedvehicleSelector,
  unmountedESSListDataSelector,
  vehiclesListDataSelector,
} from './selectors';
import './styles.scss';

import Alert from 'react-bootstrap/Alert';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HomeIcon } from '../../assets/images';
import MinimizedSideMenu from '../../components/MinimizedSideMenu';
import SideMenuV3 from '../../components/SideMenuV3';
import {
  setShowSidebar,
  setSize,
} from '../../components/SidebarDraggable/actions';
import {
  getIsResizing,
  getShowSidebar,
  getSize,
} from '../../components/SidebarDraggable/selectors';
import {
  userConfig as configUser,
  directlinktrendSignalCheck,
} from '../../userConfig';
import { copyToClipboard } from '../../utils/clipboard';
import { platformVersion } from '../../utils/excel-utils';
import { getbpu } from '../../utils/other';
import { fetchActiveMessages } from '../Broadcast/actions';
import { fetchMonitoredCount } from '../DashBoard/actions';
import { monitoredCountDataSelector } from '../DashBoard/selectors';
import {
  clearEventList,
  clearSaveEventResponce,
  clearUndoEventResponce,
  fetchAllEventList,
  saveEvent,
  setAllEventListData,
  undoEvent,
} from '../Events/actions';
import {
  AlleventListSelector,
  alleventListSelectorConfig,
  eventDataSelector,
  loadingAllEventListSelector,
  loadingSaveEventSelector,
  loadingUndoEventSelector,
  saveEventResponceSelector,
  undoEventResponceSelector,
} from '../Events/selectors';
import {
  addOverlayTrendSignal,
  clearOverviewata,
  fetchBatteryOverallConditionData,
  setMainTrendSignal,
} from '../VehicleProductsView/actions';
import {
  batteryOverallConditionDataSelector,
  loadingBatteryOverallConditionSelector,
} from '../VehicleProductsView/selectors';
import { clearVehicleViewData } from '../VehicleView/actions';
Moment.locale('en');

let selectedSite;
let selectedFleet;
let selectedFleetType;
let selectedSrtDte;
let selectedEdDte;
let selectedTb = null;
let srch = null;
let ftr = [null, null, null, null];
let selectedFSDte;
let userConfig;
export class FleetView extends React.Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
    sessionStorage.removeItem('searchQuery');
    sessionStorage.removeItem('searchResultList');
    sessionStorage.removeItem('selectedPack');
    sessionStorage.removeItem('selectedPackDname');
    const urlLink = window.location.href;
    const url = new URL(urlLink);
    if (urlLink.indexOf('?') > -1) {
      selectedSite = url.searchParams.get('st');
      selectedFleet = url.searchParams.get('ft');
      selectedFleetType = url.searchParams.get('fty');
      selectedSrtDte = url.searchParams.get('sdt');
      selectedEdDte = url.searchParams.get('edt');
      selectedTb = url.searchParams.get('tb') || 'Overview';
      ftr = url.searchParams.get('ftr');
      ftr =
        ftr !== null && ftr !== 'null'
          ? ftr.split(',')
          : [null, null, null, null];
      srch = url.searchParams.get('srch');
      selectedFSDte = url.searchParams.get('fsdt');
      // this.setState({ selectedFleetType });
      sessionStorage.setItem('selectedSite', selectedSite);
      sessionStorage.setItem('selectedFleet', selectedFleet);
      sessionStorage.setItem('selectedFleetType', selectedFleetType);
      // this.props.setDate(selectedSrtDte, selectedEdDte);
      // this.setState({ selectedTab: selectedTb });
    } else {
      this.props.history.push(
        '/FleetView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&sdt=' +
          url.searchParams.get('sdt') +
          '&edt=' +
          url.searchParams.get('edt') +
          '&srch=' +
          '&tb=Overview'
      );
    }

    this.state = {
      selectedFleetType,
      divWidth: 0,
      selectedOption: selectedFSDte !== null ? selectedFSDte : '',
      fleetStatisticsData: this.props.fleetStatisticsData,
      txt: '',
      searchResultList:
        sessionStorage.getItem('searchResultList') !== null
          ? JSON.parse(sessionStorage.getItem('searchResultList'))
          : [],
      searchQuery: srch !== null ? srch : '',
      isMonthlyactive: false,
      isWeeklyactive: false,
      isDailyactive: false,
      fleettoSearch: '',
      filterItems: {
        //  isGoodSlected: ftr[1] !== null ? JSON.parse(ftr[1]) : false,
        //  isPoorSelected: ftr[2] !== null ? JSON.parse(ftr[2]) : false,
        //  isUnknownSelected: ftr[3] !== null ? JSON.parse(ftr[3]) : false,
        //  isFavoriteSelected: false,
        //  selectedSearchKey: ftr[0] !== null ? ftr[0] : "Select an index",

        availability: ftr[0] !== null ? ftr[0] : '',
        reliability: ftr[1] !== null ? ftr[1] : '',
        utilization: ftr[2] !== null ? ftr[2] : '',

        isAutoClick: null,
      },
      selectedTab: selectedTb,
      eventFormValues: {
        pecIP: '',
        producttype: '',
        exchangetype: 'ESS',
        oessan: '',
        nessan: '',
        oesssn: '',
        nesssn: '',
        opecan: '',
        npecan: '',
        opecsn: '',
        npecsn: '',
        comments: '',
        prepecan: '',
        prepecsn: '',
      },
      expandSide: true,
      isCopied: false,
    };

    userConfig = configUser();
    this.props.setAllEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
  }

  handleOptionChange = e => {
    e.preventDefault();
    const { getFleetStatisticsData } = this.props;
    const selectedOption = e.target.value;
    this.setState({ selectedOption });
    this.setState({ selectedOption });

    const urlLink = window.location.href;
    const url = new URL(urlLink);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('fsdt', selectedOption);
      this.props.history.push(url.pathname + url.search);
    }

    getFleetStatisticsData(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      selectedOption || ''
    );
  };

  calculateWidth = e => {
    if (this.divRef.current) {
      this.setState({ divWidth: this.divRef.current.offsetWidth });
    }
  };

  updateStateFromUrl() {
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const ftrParam = urlSearchParams.get('ftr');
    if (ftrParam) {
      const ftrValues = ftrParam.split(',');
      this.setState({
        filterItems: {
          availability: ftrValues[0] !== '' ? ftrValues[0] : '',
          reliability: ftrValues[1] !== '' ? ftrValues[1] : '',
          utilization: ftrValues[2] !== '' ? ftrValues[2] : '',
        },
      });
    } else {
      this.setState({
        filterItems: {
          availability: ftr[0] !== null ? ftr[0] : '',
          reliability: ftr[1] !== null ? ftr[1] : '',
          utilization: ftr[2] !== null ? ftr[2] : '',
        },
      });
    }
  }

  componentDidMount() {
    const result = platformVersion();
    this.props.setplatformVersionNumber(result);
    this.calculateWidth();
    this.props.monitoredCountData === null && this.props.getMonitoredCount();

    this.props.getFleetsList('All', 'All', 'All', true, true, true);
    sessionStorage.setItem('selectedVehicle', null);
    sessionStorage.setItem('selectedVehicleProduct', null);
    sessionStorage.setItem('selectedProductIP', null);
    this.props.getFleetsList('All', 'All', 'All', true, true, true);
    sessionStorage.setItem('selectedVehicle', null);
    sessionStorage.setItem('selectedVehicleProduct', null);
    sessionStorage.setItem('selectedProductIP', null);

    this.props.clearEventList();
    this.onFleetViewTabClick(
      selectedTb !== null ? selectedTb : this.state.selectedTab,
      selectedSrtDte !== null ? selectedSrtDte : this.props.selectedStartDate,
      selectedEdDte !== null ? selectedEdDte : this.props.selectedEndDate
    );
    this.props.getFleetStatisticsData(
      selectedSite,
      selectedFleet,
      this.state.selectedOption || ''
    );

    if (
      sessionStorage.getItem('Sidemenuscroll') !== null &&
      document.getElementById('scrollvplist') != null
    ) {
      document.getElementById('scrollvplist').scrollTop =
        sessionStorage.getItem('Sidemenuscroll');
    }
    this.props.fetchMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    const searchParams = new URLSearchParams(window.location.search);
    const prevSearchParams = new URLSearchParams(prevProps.location.search);
    const url = new URL(window.location.href);
    if (
      url.searchParams.get('tb') === 'fleet-diagnostic' &&
      (prevProps.diagnosticEventData.pageIndex !==
        this.props.diagnosticEventData.pageIndex ||
        prevProps.diagnosticEventData.uniqueValue !==
          this.props.diagnosticEventData.uniqueValue ||
        prevProps.diagnosticEventData.pageSize !==
          this.props.diagnosticEventData.pageSize ||
        prevProps.diagnosticEventData.sortValueKey !==
          this.props.diagnosticEventData.sortValueKey ||
        prevProps.diagnosticEventData.sortValue !==
          this.props.diagnosticEventData.sortValue)
    ) {
      this.props.fetchDiagnosticList(
        url.searchParams.get('ft'),
        url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
        url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
        url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
        url.searchParams.get('sdt'),
        url.searchParams.get('edt'),
        new URL(window.location.href).searchParams.get('dfdt') !== null
          ? new URL(window.location.href).searchParams.get('dfdt')
          : 'Past 90 days',
        this.props.diagnosticEventData.uniqueKey,
        this.props.diagnosticEventData.uniqueValue,
        url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
        this.props.diagnosticEventData.pageSize,
        this.props.diagnosticEventData.pageIndex,
        !this.props.diagnosticEventData.sortValue &&
          this.props.diagnosticEventData.sortValueKey
          ? 1
          : 0,
        this.props.diagnosticEventData.sortValueKey
          ? this.props.diagnosticEventData.sortValueKey
          : ''
      );
    }
    if (
      url.searchParams.get('tb') === 'fleet-eventlist' &&
      (prevProps.eventData.pageIndex !== this.props.eventData.pageIndex ||
        prevProps.eventData.pageSize !== this.props.eventData.pageSize ||
        prevProps.eventData.uniqueValue !== this.props.eventData.uniqueValue ||
        prevProps.eventData.sortValueKey !==
          this.props.eventData.sortValueKey ||
        prevProps.eventData.sortValue !== this.props.eventData.sortValue)
    ) {
      this.props.fetchAllEventList(
        url.searchParams.get('ft'),
        '',
        '',
        url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
        new URL(window.location.href).searchParams.get('efsd') !== null
          ? new URL(window.location.href).searchParams.get('efsd')
          : Moment(new Date(Moment().subtract(1, 'days').startOf('day')))
              .format('YYYY-MM-DDTHH:mm:ss')
              .replace(/:/g, '%3A'),
        new URL(window.location.href).searchParams.get('efed') !== null
          ? new URL(window.location.href).searchParams.get('efed')
          : Moment(new Date(Moment().subtract(1, 'days').endOf('day')))
              .format('YYYY-MM-DDTHH:mm:ss')
              .replace(/:/g, '%3A'),
        new URL(window.location.href).searchParams.get('efdt') !== null
          ? new URL(window.location.href).searchParams.get('efdt')
          : 'Any time',
        this.props.eventData.uniqueKey,
        this.props.eventData.uniqueValue,
        this.props.eventData.pageSize,
        this.props.eventData.pageIndex,
        !this.props.eventData.sortValue && this.props.eventData.sortValueKey
          ? 1
          : 0,
        this.props.eventData.sortValueKey
          ? this.props.eventData.sortValueKey
          : 'dt'
      );
    }

    // updating the fleetview tab switch -(back button implementation changes)
    if (
      prevSearchParams.get('ft') === searchParams.get('ft') &&
      prevSearchParams.get('tb') !== searchParams.get('tb')
    ) {
      this.setState({ selectedTab: searchParams.get('tb') || 'Overview' });
    }
    // updating the fleetstatistics date switch -(back button implementation changes)
    if (
      prevSearchParams.get('ft') === searchParams.get('ft') &&
      prevSearchParams.get('fsdt') !== searchParams.get('fsdt')
    ) {
      this.setState({ selectedOption: searchParams.get('fsdt') }, () =>
        this.props.getFleetStatisticsData(
          searchParams.get('st'),
          searchParams.get('ft'),
          this.state.selectedOption || ''
        )
      );
    }
    // updating the sidemenu filter -(back button implementation changes)
    // if (
    //   prevSearchParams.get("ft") === searchParams.get("ft") &&
    //   prevSearchParams.get("ftr") !== searchParams.get("ftr")
    // ) {
    //   this.updateStateFromUrl();
    // }
    if (prevSearchParams.get('ft') !== searchParams.get('ft')) {
      this.onFleetListClick(
        searchParams.get('ft'),
        searchParams.get('st'),
        searchParams.get('fty')
      );
    }
    if (prevSearchParams.get('tb') !== searchParams.get('tb')) {
      this.onFleetViewTabClick(searchParams.get('tb'));
    }
    if (prevSearchParams.get('ft') !== searchParams.get('ft')) {
      this.onFleetViewTabClick(searchParams.get('tb'));
    }
    // searchParams.get("checked") === null && this.props.setListSelection("vehicleproduct");
  }

  UNSAFE_componentWillReceiveProps(newProps, prevState) {
    if (
      this.props.fleetsListData !== newProps.fleetsListData &&
      this.props.fleetsListData === null
    ) {
      if (
        sessionStorage.getItem('selectedFleet') !== 'null' &&
        sessionStorage.getItem('selectedFleet') !== null
      ) {
        //  this.props.getOverviewData(
        //    sessionStorage.getItem("selectedSite"),
        //    sessionStorage.getItem("selectedFleet")
        //  );
        this.props.getOverallConditionData(
          'All',
          'All',
          sessionStorage.getItem('selectedFleet'),
          true,
          true,
          true
        );
        this.props.getVehiclesList(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet')
        );
        this.props.fetchUnmountedESSList(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet')
        );
      } else {
        if (newProps.fleetsListData !== null)
          if (newProps.fleetsListData && newProps.fleetsListData.length !== 0) {
            sessionStorage.setItem(
              'selectedFleet',
              newProps.fleetsListData[0].nm
            );
            sessionStorage.setItem(
              'selectedSite',
              newProps.fleetsListData[0].st
            );
            this.props.setFleetName(newProps.fleetsListData[0].nm);
            //  this.props.getOverviewData(
            //    newProps.fleetsListData[0].st,
            //    newProps.fleetsListData[0].nm
            //  );
            this.props.getOverallConditionData(
              'All',
              'All',
              newProps.fleetsListData[0].nm,
              true,
              true,
              true
            );
            this.props.getVehiclesList(
              selectedSite,
              newProps.fleetsListData[0].nm
            );
            this.props.fetchUnmountedESSList(
              selectedSite,
              newProps.fleetsListData[0].nm
            );
          }
      }
    }

    if (
      this.props.batteryOverallConditionData !==
      newProps.batteryOverallConditionData
    ) {
      if (newProps.batteryOverallConditionData !== null) {
        if (
          sessionStorage.getItem('cd') === '0X002' &&
          sessionStorage.getItem('rsn') === 'Change Request' &&
          sessionStorage.getItem('sts') === 'Pending'
        ) {
          this.setState({
            eventFormValues: {
              ...this.state.eventFormValues,
              pecIP: sessionStorage.getItem('ip'),
              producttype: newProps.batteryOverallConditionData[0]?.typ,
              oessan: '',
              oesssn: '',
              opecan: '',
              opecsn: '',
            },
          });
        } else {
          sessionStorage.setItem(
            'nessan',
            newProps.batteryOverallConditionData[0].bpa
          );
          this.setState({
            eventFormValues: {
              ...this.state.eventFormValues,
              pecIP: newProps.batteryOverallConditionData[0].ip,
              producttype: newProps.batteryOverallConditionData[0]?.typ,
              oessan: newProps.batteryOverallConditionData[0].bpa,
              nessan: newProps.batteryOverallConditionData[0].bpa,
              oesssn: newProps.batteryOverallConditionData[0].bps,
              opecan: newProps.batteryOverallConditionData[0].pa,
              opecsn: newProps.batteryOverallConditionData[0].ps,
            },
          });
        }
      }
    }
    if (this.props.saveEventResponce !== newProps.saveEventResponce) {
      if (newProps.saveEventResponce !== null) {
        if (newProps.saveEventResponce[0].sts === true) {
          setTimeout(() => {
            this.onFleetListClick(
              selectedFleet,
              selectedSite,
              selectedFleetType
            );
          }, 3000);
        }
      }
    }
    if (this.props.undoEventResponce !== newProps.undoEventResponce) {
      if (newProps.undoEventResponce !== null) {
        if (newProps.undoEventResponce[0].sts === true) {
          setTimeout(() => {
            this.onFleetListClick(
              selectedFleet,
              selectedSite,
              selectedFleetType
            );
            this.props.clearUndoEventResponce();
          }, 3000);
        }
      }
    }

    this.props.vehiclesListData !== null &&
      newProps.vehiclesListData !== null &&
      this.state.searchQuery !== '' &&
      this.handleSearchInputChange(this.state.searchQuery);
  }

  componentWillUnmount() {
    this.props.setDiagnosticEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
    this.props.setAllEventListData({
      pageSize: 20,
      pageIndex: 0,
      uniqueKey: 'All',
      uniqueValue: 'All',
      sortValue: true,
      sortValueKey: 'dt',
    });
  }

  //  handleSelectChange = (selectedOption) => {
  //    // this.setState({ selectedOption });
  //    this.props.getFleetStatisticsData(
  //      sessionStorage.getItem("selectedFleet"),
  //      sessionStorage.getItem("selectedOption")
  //    );
  //  };
  onFleetListClick = async (selectedFleet, selectedSite, selectedFleetType) => {
    this.setState({
      searchResultList: [],
      searchQuery: '',
    });
    this.props.getFleetStatisticsData(
      selectedSite,
      selectedFleet,
      this.state.selectedOption || ''
    );
    this.props.setSize(340);

    this.props.setDiagnosticEventListData({
      ...this.props.diagnosticEventData,
      pageIndex: 0,
    });
    this.props.setAllEventListData({
      ...this.props.eventData,
      pageIndex: 0,
    });
    const result = platformVersion();
    this.props.setplatformVersionNumber(result);
    // this.props.setFleetName(selectedFleet);

    sessionStorage.setItem('selectedFleet', selectedFleet);
    sessionStorage.setItem('selectedFleetType', selectedFleetType);
    sessionStorage.setItem('selectedSite', selectedSite);
    sessionStorage.setItem('selectedVehicle', null);
    if (this.state.filterItems.selectedSearchKey === 'Vehicles') {
      if (
        this.state.filterItems.isGoodSlected === false &&
        this.state.filterItems.isPoorSelected === false &&
        this.state.filterItems.isUnknownSelected === false
      ) {
        this.props.getVehiclesList(selectedSite, selectedFleet);
      } else {
        this.props.getVehiclesList(selectedSite, selectedFleet);
      }
    } else {
      this.props.getVehiclesList(selectedSite, selectedFleet);
    }

    this.props.fetchUnmountedESSList(selectedSite, selectedFleet);

    this.props.getOverallConditionData(
      'All',
      'All',
      selectedFleet,
      true,
      true,
      true
    );

    //  await this.props.clearChartData();
    this.onFleetViewTabClick(this.state.selectedTab);
    // alert("update fleet data");
    this.setState(prevState => ({
      filterItems: {
        ...prevState.filterItems,
        isGoodSlected: false,
        isPoorSelected: false,
        isUnknownSelected: false,
        selectedSearchKey: 'Select an index',
      },
    }));
  };

  onFleetListClickRedirectionHandler = async (
    selectedFleet,
    selectedSite,
    selectedFleetType
  ) => {
    await this.props.clearEventList();
    this.props.history.push(
      '/FleetView?st=' +
        selectedSite +
        '&ft=' +
        selectedFleet +
        '&fty=' +
        selectedFleetType +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate +
        '&srch=' +
        '&tb=' +
        this.state.selectedTab
    );
  };

  onVehicleListClick = async (
    selectedVehicle,
    selected_Product = null,
    selectedP_Dname = null
  ) => {
    document.getElementById('scrollvplist') !== null &&
      sessionStorage.setItem(
        'Sidemenuscroll',
        document.getElementById('scrollvplist').scrollTop
      );
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    if (selected_Product === null) {
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      await this.props.clearEventList();
      await this.props.clearVehicleViewData();
      //  this.props.getVehicleProductList(
      //    sessionStorage.getItem("selectedSite"),
      //    sessionStorage.getItem("selectedFleet"),
      //    selectedVehicle,
      //    true,
      //    true,
      //    true
      //  );

      this.props.history.push(
        '/FleetView/VehicleView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&sdt=' +
          this.props.selectedStartDate +
          '&edt=' +
          this.props.selectedEndDate +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&vtb=Overview-vehicle-view'
      );
    } else {
      sessionStorage.setItem('selectedVehicle', selectedVehicle);
      sessionStorage.setItem('selectedVehicleProduct', selected_Product);
      sessionStorage.setItem('selectedProductDname', selectedP_Dname);
      await this.props.clearEventList();
      await this.props.clearVehicleViewData();
      this.props.history.push(
        '/FleetView/VehicleView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&vp=' +
          selected_Product +
          '&pdn=' +
          selectedP_Dname +
          '&sdt=' +
          this.props.selectedStartDate +
          '&edt=' +
          this.props.selectedEndDate +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&vtb=Overview-vehicle-view'
      );
    }
  };

  onVehicleProductListClick = async (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedVehicleProductBPU,
    selectedType
  ) => {
    document.getElementById('scrollvplist') !== null &&
      sessionStorage.setItem(
        'Sidemenuscroll',
        document.getElementById('scrollvplist').scrollTop
      );
    sessionStorage.setItem('directlink', 'false');
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    sessionStorage.setItem(
      'selectedVehicleProductBPU',
      selectedVehicleProductBPU
    );
    //  this.props.getModuleList(
    //    sessionStorage.getItem("selectedSite"),
    //    sessionStorage.getItem("selectedFleet"),
    //    sessionStorage.getItem("selectedVehicle"),
    //    selectedVehicleProduct,
    //    true,
    //    true,
    //    true
    //  );
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();

    const routeTo =
      selectedType === 'converter'
        ? '/FleetView/VehicleView/TractionConverterView?st='
        : '/FleetView/VehicleView/VehicleProductsView?st=';

    const viewSelector =
      selectedType === 'converter'
        ? '&tb=diagnostic-vehicleprod-view'
        : '&tb=Overview-vehicleprod-view';
    this.props.history.push(
      routeTo +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet') +
        '&fty=' +
        sessionStorage.getItem('selectedFleetType') +
        '&vc=' +
        sessionStorage.getItem('selectedVehicle') +
        '&vp=' +
        selectedVehicleProduct +
        '&pip=' +
        selectedProductIP +
        '&pdn=' +
        selectedProductDname +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate +
        '&unmas=' +
        sessionStorage.getItem('unmountedESSclicked') +
        viewSelector +
        '&ftr=' +
        [
          this.state.filterItems.availability,
          this.state.filterItems.reliability,
          this.state.filterItems.utilization,
        ] +
        '&srch=' +
        this.state.searchQuery +
        '&bpu=' +
        selectedVehicleProductBPU +
        '&tto=' +
        'Power'
    );
  };

  onVehicleProductPackClick = async (
    selectedVehicle,
    selectedVehicleProduct,
    selectedProductIP,
    selectedProductDname,
    selectedPack,
    selectedPackDname
  ) => {
    document.getElementById('scrollvplist') !== null &&
      sessionStorage.setItem(
        'Sidemenuscroll',
        document.getElementById('scrollvplist').scrollTop
      );
    sessionStorage.setItem('directlink', 'false');
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    sessionStorage.setItem('selectedPack', selectedPack);
    sessionStorage.setItem('selectedPackDname', selectedPackDname);
    // this.props.getModuleList(
    //   sessionStorage.getItem("selectedSite"),
    //   sessionStorage.getItem("selectedFleet"),
    //   sessionStorage.getItem("selectedVehicle"),
    //   selectedVehicleProduct,
    //   true,
    //   true,
    //   true
    // );
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();
    this.props.history.push(
      '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet') +
        '&fty=' +
        sessionStorage.getItem('selectedFleetType') +
        '&vc=' +
        sessionStorage.getItem('selectedVehicle') +
        '&vp=' +
        selectedVehicleProduct +
        '&pk=' +
        sessionStorage.getItem('selectedPack') +
        '&pkdn=' +
        sessionStorage.getItem('selectedPackDname') +
        '&pip=' +
        selectedProductIP +
        '&pdn=' +
        selectedProductDname +
        '&sdt=' +
        this.props.selectedStartDate +
        '&edt=' +
        this.props.selectedEndDate +
        '&unmas=' +
        sessionStorage.getItem('unmountedESSclicked') +
        '&ftr=' +
        [
          this.state.filterItems.availability,
          this.state.filterItems.reliability,
          this.state.filterItems.utilization,
        ] +
        '&srch=' +
        this.state.searchQuery +
        '&tb=Overview-vehicleprod-view'
    );
  };

  updateFilteredData = () => {
    if (this.state.filterItems.selectedSearchKey === 'Fleets') {
      // this.props.setFleetName(null);
      sessionStorage.setItem('selectedFleet', 'null');
      if (
        this.state.filterItems.isGoodSlected === false &&
        this.state.filterItems.isPoorSelected === false &&
        this.state.filterItems.isUnknownSelected === false
      ) {
        this.props.getFleetsList('All', 'All', 'All', true, true, true);
      } else {
        this.props.getFleetsList(
          'All',
          'All',
          'All',
          this.state.filterItems.isGoodSlected,
          this.state.filterItems.isPoorSelected,
          this.state.filterItems.isUnknownSelected
        );
      }
      // this.props.getVehiclesList(null, true, true, true);
    }
    if (this.state.filterItems.selectedSearchKey === 'Vehicles') {
      if (
        this.state.filterItems.isGoodSlected === false &&
        this.state.filterItems.isPoorSelected === false &&
        this.state.filterItems.isUnknownSelected === false
      ) {
        this.props.getVehiclesList(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet')
        );
      } else {
        this.props.getVehiclesList(
          sessionStorage.getItem('selectedSite'),
          sessionStorage.getItem('selectedFleet')
        );
      }
    }
  };

  sideMenuFilter = (key, value, isAutoClick = false) => {
    if (key === 'reset') {
      this.setState(
        prevState => ({
          filterItems: {
            ...prevState.filterItems,
            availability: '',
            reliability: '',
            utilization: '',
            isAutoClick: false,
          },
        }),
        () => {
          this.updateFilteredData();
          setTimeout(() => {
            this.setState(prevState => ({
              filterItems: {
                ...prevState.filterItems,
                isAutoClick: false,
              },
            }));
          }, 0);
          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            const temp = [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ];
            url.searchParams.set('ftr', temp);
            this.props.history.push(url.pathname + url.search);
          }
        }
      );
    } else {
      this.setState(
        prevState => ({
          filterItems: {
            ...prevState.filterItems,
            [key]: value,
            isAutoClick,
          },
        }),
        () => {
          this.updateFilteredData();
          setTimeout(() => {
            this.setState(prevState => ({
              filterItems: {
                ...prevState.filterItems,
                isAutoClick: false,
              },
            }));
          }, 0);
          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            const temp = [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ];
            url.searchParams.set('ftr', temp);
            this.props.history.push(url.pathname + url.search);
          }
        }
      );
    }
  };

  handleFilterDropdownChange = (value, isAutoClick) => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          selectedSearchKey: value,
          isGoodSlected: false,
          isPoorSelected: false,
          isUnknownSelected: false,
          isAutoClick,
        },
      }),
      () => {
        this.updateFilteredData();
        setTimeout(() => {
          this.setState(prevState => ({
            filterItems: {
              ...prevState.filterItems,
              isAutoClick: false,
            },
          }));
        }, 0);
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onGoodFilterCkick = isAutoClick => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isGoodSlected: !this.state.filterItems.isGoodSlected,
          isAutoClick: isAutoClick === true ? true : undefined,
        },
      }),
      () => {
        this.updateFilteredData();
        setTimeout(() => {
          this.setState(prevState => ({
            filterItems: {
              ...prevState.filterItems,
              isAutoClick: false,
            },
          }));
        }, 0);
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onPoorFilterCkick = isAutoClick => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isPoorSelected: !this.state.filterItems.isPoorSelected,
          isAutoClick: isAutoClick === true ? true : undefined,
        },
      }),
      () => {
        this.updateFilteredData();
        setTimeout(() => {
          this.setState(prevState => ({
            filterItems: {
              ...prevState.filterItems,
              isAutoClick: false,
            },
          }));
        }, 0);
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onUnknownFilterCkick = isAutoClick => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isUnknownSelected: !this.state.filterItems.isUnknownSelected,
          isAutoClick: isAutoClick === true ? true : undefined,
        },
      }),
      () => {
        this.updateFilteredData();
        setTimeout(() => {
          this.setState(prevState => ({
            filterItems: {
              ...prevState.filterItems,
              isAutoClick: false,
            },
          }));
        }, 0);
        const url = new URL(window.location.href);
        if (window.location.href.indexOf('?') > -1) {
          const temp = [
            this.state.filterItems.selectedSearchKey,
            this.state.filterItems.isGoodSlected,
            this.state.filterItems.isPoorSelected,
            this.state.filterItems.isUnknownSelected,
          ];
          url.searchParams.set('ftr', temp);
          this.props.history.push(url.pathname + url.search);
        }
      }
    );
  };

  onFavoriteFilterCkick = () => {
    this.setState(
      prevState => ({
        filterItems: {
          ...prevState.filterItems,
          isFavoriteSelected: !this.state.filterItems.isFavoriteSelected,
        },
      }),
      () => {
        // this.filterFleetData();
      }
    );
  };

  filterFleetNameUpdate = e => {
    const { value } = e.target;

    this.setState({
      fleettoSearch: value,
    });
  };

  filterFleetNameEnter = e => {
    if (e.key === 'Enter') {
      if (this.state.fleettoSearch !== '') {
        // if (this.state.filterItems.selectedSearchKey === "Fleets") {
        //   this.props.setFleetName(null);
        //   if (
        //     this.state.filterItems.isGoodSlected == false &&
        //     this.state.filterItems.isPoorSelected == false &&
        //     this.state.filterItems.isUnknownSelected == false
        //   ) {
        //     this.props.getFleetsList(
        //       "All",
        //       "All",
        //       this.state.fleettoSearch,
        //       true,
        //       true,
        //       true
        //     );
        //   } else {
        //     this.props.getFleetsList(
        //       "All",
        //       "All",
        //       this.state.fleettoSearch,
        //       this.state.filterItems.isGoodSlected,
        //       this.state.filterItems.isPoorSelected,
        //       this.state.filterItems.isUnknownSelected
        //     );
        //   }
        //   //this.props.getVehiclesList(null, true, true, true);
        // }
        // if (this.state.filterItems.selectedSearchKey === "Vehicles") {
        //   if (
        //     this.state.filterItems.isGoodSlected == false &&
        //     this.state.filterItems.isPoorSelected == false &&
        //     this.state.filterItems.isUnknownSelected == false
        //   ) {
        //     //
        //   } else {
        //     //
        //   }
        // }
      }
    }
  };

  onFleetViewTabClickRedirection = (tab, stDte = null, edDte = null) => {
    const url = new URL(window.location.href);
    url.searchParams.set('tb', tab);
    this.props.history.push(url.pathname + url.search);
  };

  onFleetViewTabClick = (tab, stDte = null, edDte = null) => {
    const srtDte = stDte !== null ? stDte : this.props.selectedStartDate;
    const endDte = edDte !== null ? edDte : this.props.selectedEndDate;
    this.setState({ selectedTab: tab });
    const url = new URL(window.location.href);

    if (tab === 'fleet-fleetstatistic') {
      this.setState({ fleetStatisticsData: this.props.fleetStatisticsData });
    } else if (tab === 'fleet-eventlist') {
      this.props.eventData !== null &&
        this.props.fetchAllEventList(
          url.searchParams.get('ft'),
          '',
          '',
          url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
          new URL(window.location.href).searchParams.get('efsd') !== null
            ? new URL(window.location.href).searchParams.get('efsd')
            : Moment(new Date(Moment().subtract(1, 'days').startOf('day')))
                .format('YYYY-MM-DDTHH:mm:ss')
                .replace(/:/g, '%3A'),
          new URL(window.location.href).searchParams.get('efed') !== null
            ? new URL(window.location.href).searchParams.get('efed')
            : Moment(new Date(Moment().subtract(1, 'days').endOf('day')))
                .format('YYYY-MM-DDTHH:mm:ss')
                .replace(/:/g, '%3A'),
          new URL(window.location.href).searchParams.get('efdt') !== null
            ? new URL(window.location.href).searchParams.get('efdt')
            : 'Any time',
          'All',
          'All',
          this.props.eventData.pageSize && this.props.eventData.pageSize,
          this.props.eventData.pageIndex && this.props.eventData.pageIndex,
          !this.props.eventData.sortValue && this.props.eventData.sortValueKey
            ? 1
            : 0,
          this.props.eventData.sortValueKey
            ? this.props.eventData.sortValueKey
            : 'dt'
        );
    } else if (tab === 'fleet-diagnostic') {
      this.props.diagnosticEventData !== null &&
        this.props.fetchDiagnosticList(
          url.searchParams.get('ft'),
          url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
          url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
          url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
          url.searchParams.get('sdt'),
          url.searchParams.get('edt'),
          new URL(window.location.href).searchParams.get('dfdt') !== null
            ? new URL(window.location.href).searchParams.get('dfdt')
            : 'Past 90 days',
          this.props.diagnosticEventData.uniqueKey || 'All',
          this.props.diagnosticEventData.uniqueValue || 'All',
          url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
          this.props.diagnosticEventData.pageSize
            ? this.props.diagnosticEventData.pageSize
            : 20,
          this.props.diagnosticEventData.pageIndex
            ? this.props.diagnosticEventData.pageIndex
            : 0,
          !this.props.diagnosticEventData.sortValue &&
            this.props.diagnosticEventData.sortValueKey
            ? 1
            : 0,
          this.props.diagnosticEventData.sortValueKey
            ? this.props.diagnosticEventData.sortValueKey
            : ''
        );
    } else if (tab === 'Energy-throughput') {
      this.props.boxPlotData === null &&
        this.props.getBoxPlotData(
          sessionStorage.getItem('selectedFleet'),
          srtDte,
          endDte,
          'day'
        );
    } else if (tab === 'SoC-range-usage') {
      this.props.getHistogramData();
    }
  };

  onDatePickerButtonClick = clickedButton => {
    if (clickedButton === 'Monthly') {
      this.setState(prevState => ({
        isMonthlyactive: !prevState.isMonthlyactive,
        isWeeklyactive: false,
        isDailyactive: false,
      }));
    } else if (clickedButton === 'Weekly') {
      this.setState(prevState => ({
        isWeeklyactive: !prevState.isWeeklyactive,
        isMonthlyactive: false,
        isDailyactive: false,
      }));
    } else if (clickedButton === 'Daily') {
      this.setState(prevState => ({
        isDailyactive: !prevState.isDailyactive,
        isMonthlyactive: false,
        isWeeklyactive: false,
      }));
    }
  };

  onApplyClick = async (startDate, endDate) => {
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set(
        'sdt',
        Moment(startDate).format('YYYY-MM-DDTHH:mm:ss')
      );
      url.searchParams.set(
        'edt',
        Moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
      );
      this.props.history.push(url.pathname + url.search);
    }

    await this.props.setDate(startDate, endDate);
    await this.props.clearChartData();
    this.onFleetViewTabClick(this.state.selectedTab);
  };

  onListSelectionClick = (e, selection) => {
    if (selection === 'vehicle') {
      if (e.target.checked === true && this.props.selectedList === 'product')
        this.props.setListSelection('vehicleproduct');
      else if (e.target.checked === true)
        this.props.setListSelection('vehicle');
      else this.props.setListSelection('product');
    } else if (selection === 'product') {
      if (e.target.checked === true && this.props.selectedList === 'vehicle')
        this.props.setListSelection('vehicleproduct');
      else if (e.target.checked === true)
        this.props.setListSelection('product');
      else this.props.setListSelection('vehicle');
    }
  };

  handleEventInputChange = e => {
    if (e !== undefined) {
      this.setState({
        eventFormValues: {
          ...this.state.eventFormValues,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleClose = () => {
    this.setState(
      {
        eventFormValues: {
          ...this.state.eventFormValues,
          exchangetype: 'ESS',
          nessan:
            sessionStorage.getItem('nessan') === 'null'
              ? ''
              : sessionStorage.getItem('nessan'),
          nesssn: '',
          npecan: '',
          npecsn: '',
          prepecan: '',
          prepecsn: '',
          comments: '',
        },
      },
      () => sessionStorage.setItem('nessan', '')
    );
  };

  handleEventRadioChange = e => {
    if (e !== undefined) {
      if (e.target.value === 'PEC') {
        this.setState({
          eventFormValues: {
            ...this.state.eventFormValues,
            [e.target.name]: e.target.value,
            nessan: '',
            nesssn: '',
          },
        });
      } else {
        this.setState({
          eventFormValues: {
            ...this.state.eventFormValues,
            [e.target.name]: e.target.value,
            nessan:
              sessionStorage.getItem('nessan') === 'null'
                ? ''
                : sessionStorage.getItem('nessan'),
          },
        });
      }
    }
  };

  setEventUpdateDate = date => {
    // this.setState({
    //   eventFormValues: {
    //     ...this.state.eventFormValues,
    //     cdate: Moment.utc(date, "DD.MM.YYYY HH:mm:ss").toISOString(),
    //   },
    // });
  };

  setEventNewPec = obj => {
    let npecan = '';
    let npecsn = '';
    let vNum;
    if (sessionStorage.getItem('selectedFleet') !== null && obj.vn !== null) {
      if (sessionStorage.getItem('selectedFleet') === 'GTW_ARRIVA') {
        vNum =
          sessionStorage.getItem('selectedFleet').slice(0, 3) + '_' + obj.vn;
      } else if (
        sessionStorage.getItem('selectedFleet') === 'SWISSTROLLEY_FRIBOURG'
      ) {
        vNum =
          sessionStorage.getItem('selectedFleet').slice(0, 12) + '_' + obj.vn;
      } else {
        vNum = sessionStorage.getItem('selectedFleet') + '_' + obj.vn;
      }

      sessionStorage.setItem('eVnum', vNum);
    }
    if (obj.npun !== null) {
      npecan = obj.npun.slice(0, 15);
      npecsn = obj.npun.slice(15);
    }

    if (
      obj.cd === '0X002' &&
      obj.rsn === 'Change Request' &&
      obj.sts === 'Pending'
    ) {
      sessionStorage.setItem('cd', obj.cd);
      sessionStorage.setItem('rsn', obj.rsn);
      sessionStorage.setItem('sts', obj.sts);
      sessionStorage.setItem('ip', obj.ip);
    } else {
      sessionStorage.setItem('cd', '');
      sessionStorage.setItem('rsn', '');
      sessionStorage.setItem('sts', '');
      sessionStorage.setItem('ip', '');
    }
    this.props.fetchBatteryOverallConditionData(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      vNum,
      obj.pun
    );

    this.setState({
      eventFormValues: {
        ...this.state.eventFormValues,
        npecan,
        npecsn,
        prepecan: npecan,
        prepecsn: npecsn,
      },
    });
  };

  handleEventSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    await this.props.clearSaveEventResponce();
    this.props.saveEvent(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      sessionStorage.getItem('eVnum'),
      // sessionStorage.getItem("selectedVehicle"),
      this.state.eventFormValues
    );
    //  this.setState({
    //    eventFormValues: {
    //      ...this.state.eventFormValues,
    //      nessan: "",
    //      nesssn: "",
    //      npecan: "",
    //      npecsn: "",
    //      prepecan: "",
    //      prepecsn: "",
    //      comments: "",
    //    },
    //  });
  };

  handleUndoSubmit = async obj => {
    // e.preventDefault();
    // e.stopPropagation();
    await this.props.clearUndoEventResponce();
    let vNum;
    if (sessionStorage.getItem('selectedFleet') !== null && obj.vn !== null) {
      if (sessionStorage.getItem('selectedFleet') === 'WINK_ARRIVA') {
        vNum = sessionStorage.getItem('selectedFleet') + '_' + obj.vn;
      } else if (sessionStorage.getItem('selectedFleet') === 'GTW_ARRIVA') {
        vNum =
          sessionStorage.getItem('selectedFleet').slice(0, 3) + '_' + obj.vn;
      } else if (
        sessionStorage.getItem('selectedFleet') === 'SWISSTROLLEY_FRIBOURG'
      ) {
        vNum =
          sessionStorage.getItem('selectedFleet').slice(0, 12) + '_' + obj.vn;
      }
    }
    this.props.undoEvent(
      sessionStorage.getItem('selectedSite'),
      sessionStorage.getItem('selectedFleet'),
      vNum,
      obj
    );
  };

  eventFilterApply = (filterStartDate, filterEndDate, dateType) => {
    const url = new URL(window.location.href);
    this.props.fetchAllEventList(
      url.searchParams.get('ft'),
      '',
      '',
      url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
      Moment(filterStartDate)
        .format('YYYY-MM-DDTHH:mm:ss')
        .replace(/:/g, '%3A'),
      Moment(filterEndDate).format('YYYY-MM-DDTHH:mm:ss').replace(/:/g, '%3A'),
      dateType || 'Any time',
      'All',
      'All',
      this.props.eventData.pageSize,
      this.props.eventData.pageIndex,
      !this.props.eventData.sortValue && this.props.eventData.sortValueKey
        ? 1
        : 0,
      this.props.eventData.sortValueKey
        ? this.props.eventData.sortValueKey
        : 'dt'
    );
  };

  diagFilterApply = () => {
    const url = new URL(window.location.href);
    this.props.fetchDiagnosticList(
      url.searchParams.get('ft'),
      url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
      url.searchParams.get('pip') ? url.searchParams.get('pip') : '',
      url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
      url.searchParams.get('sdt'),
      url.searchParams.get('edt'),
      'Past 90 days',
      this.props.diagnosticEventData.uniqueKey,
      this.props.diagnosticEventData.uniqueValue,
      url.searchParams.get('pkdn') ? url.searchParams.get('pkdn') : '',
      20,
      0,
      !this.props.diagnosticEventData.sortValue &&
        this.props.diagnosticEventData.sortValueKey
        ? 1
        : 0,
      this.props.diagnosticEventData.sortValueKey
        ? this.props.diagnosticEventData.sortValueKey
        : ''
    );
  };

  redirectToTimetdOnStreesEvtClk = async obj => {
    const formdtdDate = Moment.utc(obj.dt).format('YYYY-MM-DD');
    const time = Moment().startOf('day').format('HH:mm:ss');
    const time1 = Moment().endOf('day').format('HH:mm:ss');
    const url = new URL(window.location.href);
    const startDate = formdtdDate + ' ' + time;
    const endDate = formdtdDate + ' ' + time1;

    let evtSignal = 'Cycles';
    if (obj.rsn === 'C-Rate') {
      evtSignal = 'Battery Current';
    } else if (obj.rsn === 'Temperature') {
      evtSignal = 'Temperature';
    } else if (obj.rsn === 'SOC') {
      evtSignal = 'State of Charge';
    }
    let vNum;
    if (url.searchParams.get('ft') !== null && obj.vn !== null) {
      if (url.searchParams.get('ft') === 'GTW_ARRIVA') {
        vNum = url.searchParams.get('ft').slice(0, 3) + '_' + obj.vn;
      } else if (url.searchParams.get('ft') === 'SWISSTROLLEY_FRIBOURG') {
        vNum = url.searchParams.get('ft').slice(0, 12) + '_' + obj.vn;
      } else {
        vNum = url.searchParams.get('ft') + '_' + obj.vn;
      }
    }
    const platform = platformVersion();
    const selectedVehicle = vNum;
    const selectedVehicleProduct = obj.pun;
    const selectedProductIP = obj.ip;
    const selectedProductDname = obj.bp;
    const selectedPack = obj.pk;
    const selectedPackDname = obj.pn;
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    const bpu = obj.ean + obj.esn; // getbpu(obj.pun, this.props.vehiclesListData);
    if (evtSignal === 'Cycles') {
      await this.props.setMainTrendSignal('State of Charge');
      await this.props.addOverlayTrendSignal(['Power']);
      await this.props.clearOverviewata();
      await this.props.clearChartData();
      await this.props.clearEventList();
      await this.props.clearUndoEventResponce();
      if (platform === '3') {
        this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&fty=' +
            sessionStorage.getItem('selectedFleetType') +
            '&vc=' +
            selectedVehicle +
            '&vp=' +
            selectedVehicleProduct +
            '&pk=' +
            selectedPack +
            '&pkdn=' +
            selectedPackDname +
            '&pip=' +
            selectedProductIP +
            '&pdn=' +
            selectedProductDname +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=State of Charge' +
            '&tto=Power' +
            '&tb=Timetrend-vehicleprod-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&bpu=' +
            bpu +
            '&cdty=Custom'
        );
      } else {
        this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&fty=' +
            sessionStorage.getItem('selectedFleetType') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            selectedVehicleProduct +
            '&pip=' +
            selectedProductIP +
            '&pdn=' +
            selectedProductDname +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=State of Charge' +
            '&tto=Power' +
            '&tb=Timetrend-vehicleprod-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&bpu=' +
            bpu +
            '&cdty=Custom'
        );
      }
    } else {
      await this.props.setMainTrendSignal(evtSignal);
      await this.props.addOverlayTrendSignal([]);
      await this.props.clearOverviewata();
      await this.props.clearChartData();
      await this.props.clearEventList();
      await this.props.clearUndoEventResponce();
      if (platform === '3') {
        this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&fty=' +
            sessionStorage.getItem('selectedFleetType') +
            '&vc=' +
            selectedVehicle +
            '&vp=' +
            selectedVehicleProduct +
            '&pk=' +
            selectedPack +
            '&pkdn=' +
            selectedPackDname +
            '&pip=' +
            selectedProductIP +
            '&pdn=' +
            selectedProductDname +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&unmas=false' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&ttm=' +
            evtSignal +
            '&tb=Timetrend-vehicleprod-view' +
            '&bpu=' +
            bpu +
            '&cdty=Custom'
        );
      } else {
        this.props.history.push(
          '/FleetView/VehicleView/VehicleProductsView?st=' +
            sessionStorage.getItem('selectedSite') +
            '&ft=' +
            sessionStorage.getItem('selectedFleet') +
            '&fty=' +
            sessionStorage.getItem('selectedFleetType') +
            '&vc=' +
            sessionStorage.getItem('selectedVehicle') +
            '&vp=' +
            selectedVehicleProduct +
            '&pip=' +
            selectedProductIP +
            '&pdn=' +
            selectedProductDname +
            '&sdt=' +
            startDate +
            '&edt=' +
            endDate +
            '&ttm=' +
            evtSignal +
            '&tb=Timetrend-vehicleprod-view' +
            '&ftr=' +
            [
              this.state.filterItems.availability,
              this.state.filterItems.reliability,
              this.state.filterItems.utilization,
            ] +
            '&srch=' +
            this.state.searchQuery +
            '&bpu=' +
            bpu +
            '&cdty=Custom'
        );
      }
    }
  };

  copyLinkDiagnosticTab = async obj => {
    copyToClipboard(
      obj.pt,
      'File location path copied to clipboard',
      'Failed to copy File location path to clipboard'
    );
  };

  redirectToTimetrendFrmDiagnostic = async obj => {
    const { bp, pn, pk } = obj;
    // const url = new URL(window.location.href);

    const formdtdDate = Moment.utc(obj.dt).format('YYYY-MM-DD');
    const time = Moment().startOf('day').format('HH:mm:ss');
    const time1 = Moment().endOf('day').format('HH:mm:ss');
    const startDate = formdtdDate + ' ' + time;
    const endDate = formdtdDate + ' ' + time1;

    const platform = platformVersion();
    const selectedPack = pk;
    const selectedPackDisplayName = obj.pn;
    const scuID = obj.pn && parseInt(obj.pn.match(/\d+/)[0]);
    const selectedVehicle = obj.vno; // "WINK_ARRIVA_601";
    const selectedVehicleProduct = obj.pcf; // "3BHE046683R01012438";
    const selectedProductIP = obj.ip; // "10.1.1.116"; //
    const selectedProductDnameP3 = bp; // obj.pt + "_" + obj.esn; //"ESS_HP_001A02_17"; //
    const selectedProductDname = pn || bp;
    sessionStorage.setItem('selectedVehicle', selectedVehicle);
    sessionStorage.setItem('selectedVehicleProduct', selectedVehicleProduct);
    sessionStorage.setItem('selectedProductIP', selectedProductIP);
    sessionStorage.setItem('selectedProductDname', selectedProductDname);
    const bpu = getbpu(obj.pc, this.props.vehiclesListData); // obj.pun//3BHE046683R01012438

    await this.props.setMainTrendSignal('Diagnostic Event');
    directlinktrendSignalCheck('Battery Current')
      ? await this.props.addOverlayTrendSignal(['Battery Current'])
      : await this.props.addOverlayTrendSignal([]);
    await this.props.clearOverviewata();
    await this.props.clearChartData();
    await this.props.clearEventList();
    await this.props.clearUndoEventResponce();

    let tempParameter = '';
    directlinktrendSignalCheck('Battery Current')
      ? (tempParameter = '&tto=Battery Current')
      : (tempParameter = '');

    if (platform === '3') {
      this.props.history.push(
        '/FleetView/VehicleView/VehicleProductsView/PackView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&vp=' +
          selectedVehicleProduct +
          '&pk=' +
          selectedPack +
          '&pkdn=' +
          selectedPackDisplayName +
          '&pip=' +
          selectedProductIP +
          '&pdn=' +
          selectedProductDnameP3 +
          '&sdt=' +
          startDate +
          '&edt=' +
          endDate +
          '&unmas=false' +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&ttm=Diagnostic Event' +
          tempParameter +
          '&tb=Timetrend-vehicleprod-view' +
          '&scuID=' +
          scuID +
          '&cdty=Custom' +
          '&bpu=' +
          bpu
      );
    } else {
      this.props.history.push(
        '/FleetView/VehicleView/VehicleProductsView?st=' +
          sessionStorage.getItem('selectedSite') +
          '&ft=' +
          sessionStorage.getItem('selectedFleet') +
          '&fty=' +
          sessionStorage.getItem('selectedFleetType') +
          '&vc=' +
          selectedVehicle +
          '&vp=' +
          selectedVehicleProduct +
          '&pip=' +
          selectedProductIP +
          '&pdn=' +
          selectedProductDname +
          '&sdt=' +
          startDate +
          '&edt=' +
          endDate +
          '&ttm=Diagnostic Event' +
          tempParameter +
          '&tb=Timetrend-vehicleprod-view' +
          '&unmas=false' +
          '&ftr=' +
          [
            this.state.filterItems.availability,
            this.state.filterItems.reliability,
            this.state.filterItems.utilization,
          ] +
          '&srch=' +
          this.state.searchQuery +
          '&bpu=' +
          bpu +
          '&cdty=Custom'
      );
    }
  };

  redirectToEmailAlert = () => {
    this.props.history.push(
      '/FleetView/EmailAlertView?st=' +
        sessionStorage.getItem('selectedSite') +
        '&ft=' +
        sessionStorage.getItem('selectedFleet')
    );
  };

  redirectToReport = () => {
    this.props.history.push('/Dashboard/ReportView');
  };

  handleSearchInputChange = value => {
    if (value === null || value === '') {
      sessionStorage.removeItem('searchResultList');
    }
    this.setState({ searchQuery: value });
    sessionStorage.setItem('searchQuery', value);
    value = value && value.toLowerCase();
    let results = [];

    this.props.vehiclesListData[0].vst.forEach(item => {
      // if the vehicle name matches
      item.vdat.forEach(subitem => {
        // if the query matches battery name or ip address or pec serial number
        if (
          (subitem.dn && subitem.dn.toLowerCase().includes(value)) ||
          (subitem.ip && subitem.ip.toLowerCase().includes(value)) ||
          (subitem.an && subitem.an.toLowerCase().includes(value)) ||
          (subitem.bpu && subitem.bpu.toLowerCase().includes(value)) ||
          (subitem.rcm && subitem.rcm.includes(value)) ||
          (subitem.swv && subitem.swv.includes(value)) ||
          (subitem.bwvr && subitem.bwvr.includes(value))
        ) {
          results.push(item);
        }
        if (subitem.pdat && subitem.pdat.length > 0) {
          let matchFound = false;
          subitem.pdat.forEach(subitemlvl2 => {
            if (
              (subitemlvl2.dn &&
                subitemlvl2.dn.toLowerCase().includes(value)) ||
              (subitemlvl2.ip &&
                subitemlvl2.ip.toLowerCase().includes(value)) ||
              (subitemlvl2.an &&
                subitemlvl2.an.toLowerCase().includes(value)) ||
              (subitemlvl2.bpu &&
                subitemlvl2.bpu.toLowerCase().includes(value)) ||
              (subitemlvl2.rcm && subitemlvl2.rcm.includes(value)) ||
              (subitemlvl2.swv && subitemlvl2.swv.includes(value)) ||
              (subitemlvl2.bwvr && subitemlvl2.bwvr.includes(value))
            ) {
              matchFound = true;
              // results.push(item);
            }
          });
          if (matchFound) results.push(item);
        }
      });
      if (
        (item.vn && item.vn.toLowerCase().includes(value)) ||
        (item.dvn && item.dvn.toLowerCase().includes(value))
      ) {
        results.push(item);
      }
    });
    const uniqueResults = results.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        results.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    const searchResultList = [
      { vst: uniqueResults, data: this.props.vehiclesListData[0].data },
    ];
    this.setState({
      searchResultList,
    });
    sessionStorage.setItem(
      'searchResultList',
      JSON.stringify(searchResultList)
    );
    results = [];
    if (value === null || value === '') {
      sessionStorage.removeItem('searchResultList');
    }
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('srch', value);
      window.history.pushState(null, null, url);
    }
  };

  expandSide = () => {
    this.setState({ expandSide: !this.state.expandSide }, () =>
      this.props.setWidth(this.state.expandSide ? 370 : 120)
    );
  };

  render() {
    const getDisplayName = selectedFleet => {
      if (this.props.fleetsListData && this.props.fleetsListData.length > 0) {
        return this.props.fleetsListData.filter(
          item => item.nm === selectedFleet
        )[0].dnm;
      } else {
        return selectedFleet;
      }
    };
    return (
      <React.Fragment>
        <Breadcrumb
          BreadcrumbIcon={<HomeIcon className={''} alt="Home" />}
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          MonitoredCount={this.props.monitoredCountData}
          userConfig={userConfig}
        />
        <Draggable
          left_side_content={
            this.props.showSidebar ? (
              <div className="sideMenucon noLeftpadding noRightpadding">
                {platformVersion() !== '3' ? (
                  <SideMenu
                    screenWidth={this.props.sideWidth}
                    size={this.props.size}
                    handleSearchInputChange={value =>
                      this.handleSearchInputChange(value)
                    }
                    searchQuery={this.state.searchQuery}
                    fleetList={this.props.fleetsListData} // {fleetList}
                    vehicles={
                      this.state.searchResultList &&
                      this.state.searchResultList.length === 0
                        ? this.props.vehiclesListData
                        : this.state.searchResultList
                    } // {vehicles}
                    onFleetListClick={this.onFleetListClickRedirectionHandler}
                    selectedFleet={sessionStorage.getItem('selectedFleet')}
                    selectedFleetType={
                      this.state.selectedFleetType &&
                      this.state.selectedFleetType
                    }
                    isGoodSlected={this.state.filterItems.isGoodSlected}
                    isPoorSelected={this.state.filterItems.isPoorSelected}
                    isUnknownSelected={this.state.filterItems.isUnknownSelected}
                    isFavoriteSelected={
                      this.state.filterItems.isFavoriteSelected
                    }
                    selectedSearchKey={this.state.filterItems.selectedSearchKey}
                    onGoodFilterCkick={this.onGoodFilterCkick}
                    onPoorFilterCkick={this.onPoorFilterCkick}
                    onUnknownFilterCkick={this.onUnknownFilterCkick}
                    onFavoriteFilterCkick={this.onFavoriteFilterCkick}
                    handleFilterDropdownChange={this.handleFilterDropdownChange}
                    filterWithFleetName={this.filterWithFleetName}
                    fleettoSearch={this.state.fleettoSearch}
                    filterFleetNameUpdate={this.filterFleetNameUpdate}
                    filterFleetNameEnter={this.filterFleetNameEnter}
                    onVehicleListClick={this.onVehicleListClick}
                    selectedView={'Fleet'}
                    onVehicleProductListClick={this.onVehicleProductListClick}
                    onListSelectionClick={this.onListSelectionClick}
                    listSelection={this.props.selectedList}
                    loadingUnmountedESSList={this.props.loadingUnmountedESSList}
                    unmountedESSListData={this.props.unmountedESSListData}
                    sideMenuFilter={this.sideMenuFilter}
                    filterAvailability={this.state.filterItems.availability}
                    filterReliability={this.state.filterItems.reliability}
                    filterUtilization={this.state.filterItems.utilization}
                    expandSide={this.expandSide}
                  />
                ) : (
                  //  show this for side menu v3
                  <SideMenuV3
                    // trainno={selectedTrain}
                    expandSide={this.expandSide}
                    screenWidth={this.props.sideWidth}
                    size={this.props.size}
                    handleSearchInputChange={value =>
                      this.handleSearchInputChange(value)
                    }
                    searchQuery={this.state.searchQuery}
                    fleetList={this.props.fleetsListData} // {fleetList}
                    vehicles={
                      this.state.searchResultList &&
                      this.state.searchResultList.length === 0
                        ? this.props.vehiclesListData // sideMenuV3Data //
                        : this.state.searchResultList
                    } // {vehicles}
                    onVehicleProductPackClick={this.onVehicleProductPackClick}
                    onFleetListClick={this.onFleetListClickRedirectionHandler}
                    selectedFleet={sessionStorage.getItem('selectedFleet')}
                    selectedFleetType={sessionStorage.getItem(
                      'selectedFleetType'
                    )}
                    isGoodSlected={this.state.filterItems.isGoodSlected}
                    isPoorSelected={this.state.filterItems.isPoorSelected}
                    isUnknownSelected={this.state.filterItems.isUnknownSelected}
                    isFavoriteSelected={
                      this.state.filterItems.isFavoriteSelected
                    }
                    selectedSearchKey={this.state.filterItems.selectedSearchKey}
                    onGoodFilterCkick={this.onGoodFilterCkick}
                    onPoorFilterCkick={this.onPoorFilterCkick}
                    onUnknownFilterCkick={this.onUnknownFilterCkick}
                    onFavoriteFilterCkick={this.onFavoriteFilterCkick}
                    handleFilterDropdownChange={this.handleFilterDropdownChange}
                    filterWithFleetName={this.filterWithFleetName}
                    fleettoSearch={this.state.fleettoSearch}
                    filterFleetNameUpdate={this.filterFleetNameUpdate}
                    filterFleetNameEnter={this.filterFleetNameEnter}
                    onVehicleListClick={this.onVehicleListClick}
                    selectedView={'Fleet'}
                    onVehicleProductListClick={this.onVehicleProductListClick}
                    onListSelectionClick={this.onListSelectionClick}
                    listSelection={this.props.selectedList}
                    loadingUnmountedESSList={this.props.loadingUnmountedESSList}
                    unmountedESSListData={this.props.unmountedESSListData}
                    sideMenuFilter={this.sideMenuFilter}
                    filterAvailability={this.state.filterItems.availability}
                    filterReliability={this.state.filterItems.reliability}
                    filterUtilization={this.state.filterItems.utilization}
                  />
                )}
                {/* <Loading loadintText="Loading list" /> */}
              </div>
            ) : (
              <div className="sideMenucon noLeftpadding noRightpadding">
                <MinimizedSideMenu
                  expandSide={this.expandSide}
                  selectedView={'Fleet'}
                  onFleetListClick={this.onFleetListClickRedirectionHandler}
                  setShowSidebar={this.props.setShowSidebar}
                  setSize={this.props.setSize}
                />
              </div>
            )
          }
          right_side_content={
            <div
              className="detailViewRightinner  noRightpadding"
              ref={this.divRef}>
              {this.props.fleetsListData === null ? (
                <div className="borders">
                  <Loading loadintText="Loading data" />
                </div>
              ) : this.props.fleetsListData &&
                this.props.fleetsListData.length !== 0 &&
                this.props.overallConditionData !== null &&
                this.props.overallConditionData &&
                this.props.overallConditionData.length !== 0 ? (
                <React.Fragment>
                  {platformVersion() !== '3' ? (
                    <TitleBar
                      overallConditionData={this.props.overallConditionData}
                      loadingOverallCondition={
                        this.props.loadingOverallCondition
                      }
                      pageName="Fleetview"
                      selectedFleet={getDisplayName(
                        sessionStorage.getItem('selectedFleet')
                      )}
                      userConfig={userConfig}
                      redirectToEmailAlert={this.redirectToEmailAlert}
                      redirectToReport={this.redirectToReport}
                      searchQuery={this.state.searchQuery}
                    />
                  ) : (
                    <TitleBarV3
                      overallConditionData={this.props.overallConditionData}
                      loadingOverallCondition={
                        this.props.loadingOverallCondition
                      }
                      pageName="Fleetview"
                      selectedFleet={getDisplayName(
                        sessionStorage.getItem('selectedFleet')
                      )}
                      userConfig={userConfig}
                      redirectToEmailAlert={this.redirectToEmailAlert}
                      redirectToReport={this.redirectToReport}
                      searchQuery={this.state.searchQuery}
                    />
                  )}

                  <div
                    className="date-tabs-container fleet_view_event"
                    id="fleettabcon">
                    {/* <DateRangePicker
                    onDatePickerButtonClick={this.onDatePickerButtonClick}
                    isMonthlyactive={this.state.isMonthlyactive}
                    isWeeklyactive={this.state.isWeeklyactive}
                    isDailyactive={this.state.isDailyactive}
                    onApplyClick={this.onApplyClick}
                    selectedStartDate={this.props.selectedStartDate}
                    selectedEndDate={this.props.selectedEndDate}
                    selectedTab={this.state.selectedTab}
                  /> */}

                    <FleetTabs
                      eventListConfig={
                        this.props.alleventListConfig &&
                        this.props.alleventListConfig
                      }
                      fetchDiagnosticList={this.props.fetchDiagnosticList}
                      eventData={this.props.eventData}
                      setAllEventListData={this.props.setAllEventListData}
                      fetchAllEventList={this.props.fetchAllEventList}
                      loadingHistogram={this.props.loadingHistogram}
                      loadingBoxPlot={this.props.loadingBoxPlot}
                      loadingOverview={this.props.loadingOverview}
                      overviewData={this.props.overviewData}
                      histogramData={this.props.histogramData}
                      boxPlotData={this.props.boxPlotData}
                      onFleetViewTabClick={this.onFleetViewTabClickRedirection}
                      selectedTab={this.state.selectedTab}
                      onVehicleListClick={this.onVehicleListClick}
                      loadingVehicles={this.props.loadingVehicles}
                      vehiclesListData={this.props.vehiclesListData}
                      unmountedESSListData={this.props.unmountedESSListData}
                      listSelection={this.props.selectedList}
                      filterItems={this.state.filterItems}
                      userConfig={userConfig}
                      loadingEventList={this.props.loadingEventList}
                      eventList={this.props.eventList}
                      handleEventInputChange={this.handleEventInputChange}
                      handleEventRadioChange={this.handleEventRadioChange}
                      setEventUpdateDate={this.setEventUpdateDate}
                      setEventNewPec={this.setEventNewPec}
                      eventFormValues={this.state.eventFormValues}
                      handleClose={this.handleClose}
                      handleEventSubmit={this.handleEventSubmit}
                      loadingSaveEvent={this.props.loadingSaveEvent}
                      saveEventResponce={this.props.saveEventResponce}
                      eventFilterApply={this.eventFilterApply}
                      diagFilterApply={this.diagFilterApply}
                      selectedView={'Fleet'}
                      handleUndoSubmit={this.handleUndoSubmit}
                      loadingUndoEvent={this.props.loadingUndoEvent}
                      undoEventResponce={this.props.undoEventResponce}
                      redirectToTimetdOnStreesEvtClk={
                        this.redirectToTimetdOnStreesEvtClk
                      }
                      sideMenuFilter={this.sideMenuFilter}
                      filterAvailability={this.state.filterItems.availability}
                      filterReliability={this.state.filterItems.reliability}
                      filterUtilization={this.state.filterItems.utilization}
                      fleetStatisticsData={this.props.fleetStatisticsData}
                      fleetStatsDataLoader={this.props.fleetStatsDataLoader}
                      getFleetStatisticsData={this.props.getFleetStatisticsData}
                      handleOptionChange={this.handleOptionChange}
                      selectedOption={this.state.selectedOption}
                      heatmap_min_width={
                        window.innerWidth - (this.props.size + 60)
                      }
                      expandSide={this.state.expandSide}
                      isResizing={this.props.isResizing}
                      showSidebar={this.props.showSidebar}
                      redirectToTimetrendFrmDiagnostic={
                        this.redirectToTimetrendFrmDiagnostic
                      }
                      copyLinkDiagnosticTab={this.copyLinkDiagnosticTab}
                      loadingDiagnosticList={this.props.loadingDiagnosticList}
                      diagnosticList={this.props.diagnosticList}
                      diagnosticListConfig={this.props.diagnosticListConfig}
                      diagnosticEventData={this.props.diagnosticEventData}
                      setDiagnosticEventListData={
                        this.props.setDiagnosticEventListData
                      }
                      sideWidth={this.props.sideWidth}
                      platformNumber={this.props.platformNumber}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <div className="borders"></div>
              )}
            </div>
          }
        />
        {this.state.isCopied === true && (
          <Row>
            <div className="copypathdianotificationcontainer ml-auto">
              <Alert
                variant="light"
                id="copypathnotification"
                // onClose={this.props.onResetBtnClick}
                // dismissible
              >
                <p className="copypathdianotificationcontent">
                  Copied to clipboard
                </p>
              </Alert>
            </div>
          </Row>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  loadingOverview: loadingOverviewSelector(),
  loadingHistogram: loadingHistogramSelector(),
  loadingBoxPlot: loadingBoxPlotSelector(),
  overviewData: overviewDataSelector(),
  histogramData: histogramDataSelector(),
  boxPlotData: boxPlotDataSelector(),
  loadingOverallCondition: loadingOverallConditionSelector(),
  overallConditionData: overallConditionDataSelector(),
  loadingFleets: loadingFleetsSelector(),
  fleetsListData: fleetsListDataSelector(),
  monitoredCountData: monitoredCountDataSelector(),
  loadingVehicles: loadingVehiclesSelector(),
  vehiclesListData: vehiclesListDataSelector(),
  fleetStatisticsData: fleetStatisticsDataSelector(),
  fleetStatsDataLoader: fleetStatisticsStatusLoader(),
  selectedFleet: selectedFleetSelector(),
  selectedvehicle: selectedvehicleSelector(),
  selectedStartDate: selectedStartDateSelector(),
  selectedEndDate: selectedEndDateSelector(),
  selectedList: selectedListSelector(),
  loadingEventList: loadingAllEventListSelector(),
  eventList: AlleventListSelector(),
  alleventListConfig: alleventListSelectorConfig(),
  eventData: eventDataSelector(),
  loadingSaveEvent: loadingSaveEventSelector(),
  saveEventResponce: saveEventResponceSelector(),
  loadingBatteryOverallCondition: loadingBatteryOverallConditionSelector(),
  batteryOverallConditionData: batteryOverallConditionDataSelector(),
  loadingUndoEvent: loadingUndoEventSelector(),
  undoEventResponce: undoEventResponceSelector(),
  loadingUnmountedESSList: loadingUnmountedESSListSelector(),
  unmountedESSListData: unmountedESSListDataSelector(),
  sideWidth: getSidebarWidth(),
  showSidebar: getShowSidebar(),
  isResizing: getIsResizing(),
  size: getSize(),
  loadingDiagnosticList: loadingDiagnosticListSelector(),
  diagnosticList: diagnosticListSelector(),
  diagnosticListConfig: diagnosticEventListSelectorConfig(),
  diagnosticEventData: diagnosticEventDataSelector(),
  platformNumber: platformNumberSelector(),
});

const mapDispatchToProps = {
  getOverallConditionData: fetchOverallConditionData,
  getOverviewData: fetchOverviewData,
  getHistogramData: fetchHistogramData,
  getBoxPlotData: fetchBoxPlotData,
  getFleetsList: fetchFleetsList,
  getVehiclesList: fetchVehiclesList,
  getFleetStatisticsData: fetchFleetStatisticsData,
  getMonitoredCount: fetchMonitoredCount,
  setFleetName,
  setVehicleName,
  getVehicleProductList: fetchVehicleProductList,
  setDate,
  clearChartData,
  clearEventList,
  clearOverviewata,
  setListSelection,
  clearVehicleViewData,
  fetchAllEventList,
  setAllEventListData,
  saveEvent,
  clearSaveEventResponce,
  fetchBatteryOverallConditionData,
  undoEvent,
  clearUndoEventResponce,
  setMainTrendSignal,
  addOverlayTrendSignal,
  fetchUnmountedESSList,
  setWidth: setSidebarWidth,
  setShowSidebar,
  fetchDiagnosticList,
  fetchMessages: fetchActiveMessages,
  setDiagnosticEventListData,
  setplatformVersionNumber,
  setSize,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FleetView)
);
