import Moment from 'moment';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';

import { CaretDown } from '../../assets/images';
import './styles.scss';

Moment.locale('en');

class DashboardFilter extends Component {
  render() {
    const disValue = !(
      !this.props.defaultSite.includes('All') ||
      !this.props.defaultCustomer.includes('All') ||
      !this.props.defaultFleet.includes('All') ||
      this.props.isGoodSlected === true ||
      this.props.isPoorSelected === true ||
      this.props.isUnknownSelected === true ||
      this.props.fleettoSearch !== ''
    );

    return (
      <Container fluid>
        <Row style={{ marginTop: '50px' }}>
          <div className={'title'}>
            Overall condition{' '}
            <span className={'sub'}>
              ( Data updated on {Moment().format('HH:mm \xa0DD.MM.YYYY')})
            </span>
          </div>
        </Row>
        <Row xl={6} lg={12}>
          <Col className="noLeftpadding">
            <DropdownList
              data={this.props.sitesList}
              defaultValue={'All sites'}
              value={this.props.defaultSite}
              className="dropdownsites"
              id="dropdownsites"
              name="Sites"
              onChange={e => {
                this.props.handleFilterDropdownChange(e, 'Sites');
              }}
              busy={this.props.sitesList.length === 0}
              selectIcon={<CaretDown />}
            />
          </Col>

          <Col className="noLeftpadding ">
            <DropdownList
              data={this.props.customersList}
              defaultValue={'All customers'}
              value={this.props.defaultCustomer}
              className="dropdownsites"
              id="dropdowncustomers"
              name="Customers"
              onChange={e => {
                this.props.handleFilterDropdownChange(e, 'Customers');
              }}
              busy={this.props.customersList.length === 0}
              selectIcon={<CaretDown />}
            />
          </Col>
          <Col className="noLeftpadding noRightpadding">
            <DropdownList
              data={this.props.fleetsList}
              defaultValue={'All fleets'}
              value={this.props.defaultFleet}
              className="dropdownsites "
              id="dropdownfleets"
              name="Fleets"
              onChange={e => {
                this.props.handleFilterDropdownChange(e, 'Fleets');
              }}
              busy={this.props.fleetsList.length === 0}
              selectIcon={<CaretDown />}
            />
          </Col>
          <Col md={1} className=" noRightpadding filterResetButton">
            <button
              className="dashboardFilterButton"
              onClick={this.props.onResetBtnClick}
              disabled={disValue}>
              Reset
            </button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DashboardFilter;
